import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import {
  analytics,
  STORAGE_KEY_FEEDBACK_REFERRAL_ID,
} from "@thrive-web/ui-common";
import {
  AsyncRender,
  Avatar,
  BuilderPageHeader,
  ButtonWithIcon,
  CarouselProgress,
  PageContent,
  TraitifySurvey,
  TraitifySurveyResultsButtons,
  useAsyncRender,
} from "@thrive-web/ui-components";
import { useStateIfMounted } from "@thrive-web/ui-hooks";
import { get_guid_from_iri } from "@thrive-web/ui-utils";
import { TraitifyAssessment, User } from "@thrive-web/ui-api";

export const Feedback: Preact.FunctionComponent<{
  assessment: TraitifyAssessment;
  token: string;
}> = ({ assessment, token }) => {
  const { full_name, ...user } = assessment.taken_about as User;

  const already_taken = useMemo(
    // @ts-expect-error:
    () => assessment.assessment?.status === "complete",
    []
  );

  const [show_welcome, set_show_welcome] = useStateIfMounted(false);
  const [show_survey, set_show_survey] = useStateIfMounted(!already_taken);
  const [show_results, set_show_results] = useStateIfMounted(already_taken);
  const [survey_data, set_survey_data] = useStateIfMounted<any>(null);
  const [progress, set_progress] = useStateIfMounted([0, 0]);
  const finishSurvey = useCallback(
    data => {
      set_survey_data(data);
      set_show_results(true);
      analytics.log_event(
        analytics.EVENTS.feedback_survey.survey.finish_survey,
        {
          assessment_id: assessment.id,
        }
      );
      setTimeout(() => set_show_survey(false), 400);
    },
    [set_show_survey, set_show_results, set_survey_data]
  );

  useEffect(() => {
    analytics.set_user_id(get_guid_from_iri(assessment.id)[0]);
    if (show_survey) {
      analytics.log_event(
        analytics.EVENTS.feedback_survey.survey.start_survey,
        {
          assessment_id: assessment.id,
        }
      );
    }
  }, []);

  const [ResultsView, getResults] = useAsyncRender(
    result => (
      <FeedbackResults data={result.data} user_name={user.first_name} />
    ),
    [user.first_name],
    "feedbackSurvey",
    token,
    survey_data
  );

  return (
    <PageContent id="feedback-survey" className="setup-page feedback-survey">
      <div className="card">
        {show_welcome && (
          <div
            className="feedback-survey__container"
            data-show={`${!show_results && !show_survey}`}
            data-part="welcome"
          >
            <BuilderPageHeader />
            <header className="page-header">
              <div className="page-header__container">
                <div className="page-header__content">
                  <div className="page-header__content__main">
                    <div className="page-header__left">
                      <div className="page-header__title">
                        <h1>
                          {user.first_name} has invited you to provide feedback!
                        </h1>
                      </div>
                    </div>
                    <div className="page-header__right">
                      <Avatar user={user} size="xl" isLink={false} />
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="page-body feedback-survey">
              <div className="page-body__content">
                <div className="feedback-survey__content">
                  <p className="info">
                    Welcome to Thread! <strong>{full_name}</strong> values your
                    opinion and has invited you to provide feedback on what you
                    feel their greatest strengths are as an individual.
                  </p>
                  <p className="info">
                    The survey only takes about 5 minutes. Having the
                    perspective of friends, loved ones, and mentors like you can
                    help {user.first_name} discover more about their “super
                    powers.”
                  </p>
                </div>
                <div className="feedback-survey__buttons">
                  <ButtonWithIcon
                    className="button filled"
                    side="left"
                    icon="spark"
                    onClick={() => {
                      set_show_survey(true);
                      analytics.log_event(
                        analytics.EVENTS.feedback_survey.survey.start_survey,
                        { assessment_id: assessment.id }
                      );
                      setTimeout(() => set_show_welcome(false), 400);
                    }}
                  >
                    Help {user.first_name} Identify Strengths
                  </ButtonWithIcon>
                  <a className="plain-link" href="#">
                    Unsure why you've received this survey?
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {!show_welcome && show_survey && (
          <div
            className="feedback-survey__container"
            data-show={`${!show_results && !show_welcome}`}
            data-part="survey"
          >
            <div className="feedback-survey__survey__header">
              <div className="feedback-survey__survey__header__left">
                <Avatar user={user} size="xs" isLink={false} />
                Finding {user.first_name}'s Super Power
              </div>
              {progress[1] > 0 && (
                <div className="feedback-survey__survey__header__right">
                  Question {progress[0] + 1} of {progress[1]}
                </div>
              )}
            </div>
            <TraitifySurvey
              data={assessment}
              onFinish={finishSurvey}
              yesLabel="Yes it does."
              noLabel="No, it doesn't."
              setProgress={set_progress}
              showResults={false}
            >
              {`Based on how well you know ${user.first_name}, does the above sound like something that would describe them?`}
            </TraitifySurvey>
            {progress[1] > 0 && (
              <div className="feedback-survey__survey__bottom">
                <CarouselProgress
                  count={progress[1]}
                  current={progress[0]}
                  maxDots={15}
                />
              </div>
            )}
          </div>
        )}
        {!show_survey && show_results && (
          <div
            className="feedback-survey__container"
            data-show={`${!show_welcome && !show_survey}`}
            data-part="results"
          >
            {already_taken ? (
              <FeedbackResults data={assessment} user_name={user.first_name} />
            ) : (
              <AsyncRender getPromise={getResults}>{ResultsView}</AsyncRender>
            )}
          </div>
        )}
      </div>
    </PageContent>
  );
};

export const FeedbackResults: Preact.FunctionComponent<{
  data: any;
  user_name: User["first_name"];
}> = ({ user_name, data }) => {
  return (
    <Preact.Fragment>
      <BuilderPageHeader />
      <header className="page-header">
        <div className="page-header__container">
          <div className="page-header__content">
            <div className="page-header__content__main">
              <div className="page-header__left">
                <div className="page-header__title">
                  <h1>That’s it — Thank you for participating!</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="page-body feedback-survey-results">
        <div className="page-body__content">
          <div className="feedback-survey__content">
            <p className="info">
              Your feedback helps {user_name} grow and improve. Below are
              personality types that fit {user_name} based on your answers.
            </p>
            <div className="feedback-survey__results">
              <TraitifySurveyResultsButtons data={data} />
            </div>
            <h1>Now it's your turn.</h1>
            <p className="info">
              Everyone needs a coach, and everyone can be a coach. At Thread,
              identifying strengths is the first step. Next, coaches help set
              goals, overcome barriers, and achieve goals.
            </p>
          </div>
          <div className="feedback-survey__results__footer">
            <a
              href="/register/get-started"
              className="button filled"
              target="_blank"
              onClick={() => {
                analytics.log_event(
                  analytics.EVENTS.feedback_survey.survey.click_sign_up
                );
                localStorage.setItem(
                  STORAGE_KEY_FEEDBACK_REFERRAL_ID,
                  get_guid_from_iri(data.id)[0]
                );
              }}
            >
              Sign up and take strengths quiz
            </a>
            <a
              href="https://www.thread.org/get-involved/volunteer/"
              className="plain-link"
              target="_blank"
              onClick={() => {
                analytics.log_event(
                  analytics.EVENTS.feedback_survey.survey.click_learn_more
                );
              }}
            >
              Learn more about Thread
            </a>
          </div>
        </div>
      </div>
    </Preact.Fragment>
  );
};
