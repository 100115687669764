// Utility to cache data for use in another component across page navigation

import { Atom } from "@thi.ng/atom";
import {
  DocWithData,
  map_json_doc_to_record,
  map_json_resource_to_record,
  TYPES,
} from "@thrive-web/core";
import { User } from "@thrive-web/ui-api";
import { is_id_obj, type_iri } from "@thrive-web/ui-common";
import type { MetaInterface } from "@swymbase/metatype-core";

const store = new Atom({});

type Primitive = string | number | boolean | null | undefined;

export interface CachedRecord {
  [k: string]: MaybeArray<Primitive | ObjectOf<Primitive>, boolean, true>;
}

export const set_cache = (
  key: string,
  data: CachedRecord,
  overwrite: boolean = false
) => {
  const view = store.addView([key]);
  const cur = view.deref();
  store.removeWatch(view.id);
  if (cur !== undefined && !overwrite) {
    console.warn(
      `Could not cache data with key ${key}, key already in use.`,
      store
    );
    return;
  }
  store.resetIn([key], data);
};

export const get_cache = (key: string, clear: boolean = false): any => {
  const view = store.addView([key]);
  const value = view.deref();
  store.removeWatch(view.id);
  if (value === undefined) {
    console.debug(`Could not find cached data with key ${key}.`, store);
    return undefined;
  }
  clear && store.resetIn([key], undefined);
  return value;
};

export const cache_record = <T extends { id: string }>(
  record: T,
  overwrite: boolean = false
) => {
  if (typeof record.id === "string") {
    set_cache(record.id, record, overwrite);
  } else {
    console.warn(`Failed to cache record; could not read record id`, record);
  }
};

// min number of props that a user record should have if we don't specify fields in the query
const USER_PROP_COUNT =
  Object.values(TYPES.User.properties).filter(
    p => !(p as any).comment?.includes("/self")
    // + 2 for id and type
  ).length + 2;

// sifts through response doc and caches any User entities it finds
export const maybe_cache_users = (
  response: DocWithData<MetaInterface, any>
) => {
  if (!response.data) {
    return;
  }
  if (Array.isArray(response.data)) {
    response.data.forEach(maybe_cache_user(response));
  } else {
    maybe_cache_user(response)(response.data);
  }
  if (response.included) {
    response.included.forEach(
      maybe_cache_user(response, map_json_resource_to_record)
    );
  }
};

const maybe_cache_user =
  (response, map: any = map_json_doc_to_record) =>
  record => {
    if (record.type === type_iri("User")) {
      const rec = map({ ...response, data: record });
      // if there are missing props, assume we used sparse fieldsets, and merge the
      // data with the existing cached user
      const user =
        Object.keys(rec).length < USER_PROP_COUNT
          ? maybe_merge_user_data(rec, get_cache(record.id)) ?? rec
          : rec;
      set_cache(record.id, user as User, true);
    }
  };

// merge user data so we don't lose any props when we use sparse fieldsets
const maybe_merge_user_data = (user: User, cached?: User) => {
  if (is_id_obj(user)) {
    if (!cached || is_id_obj(cached)) {
      return;
    }
    return cached;
  }
  if (!cached || is_id_obj(cached)) {
    return user as User;
  }
  const merged = { ...(cached as User) };
  for (let key of Object.keys(user as User)) {
    const o_val = merged[key] as any;
    const n_val = user[key] as any;
    // if prop doesn't exist in prev data, or value is a literal, use the new value
    if (o_val == null || typeof n_val !== "object") {
      merged[key] = n_val;
      // if both are arrays
    } else if (Array.isArray(n_val) && Array.isArray(o_val)) {
      if (
        // if the old array has any id objects in it
        o_val.some(i => is_id_obj(i)) ||
        // or if the new one has no id objects in it (i.e. all full records)
        (n_val as any[]).every(i => i.id && !is_id_obj(i))
      ) {
        // overwrite
        merged[key] = n_val;
      }
      // if the old value is an id obj and the new one isn't, overwrite
    } else if (is_id_obj(o_val) || (n_val.id && !is_id_obj(n_val))) {
      merged[key] = n_val;
    }
  }
  return cached;
};
