import * as Preact from "preact";
import { useContext, useEffect } from "preact/hooks";
import api from "@thrive-web/ui-api";
import {
  ExpenseCategory,
  ExpenseReason,
  ExperienceCategory,
  GoalPreset,
  Mood,
  TouchpointAccomplishment,
  TouchpointAction,
  GenderIdentity,
  EthnicIdentity,
  GroupIdentity,
  RaceIdentity,
  CustomIdentity,
  map_api_response,
} from "@thrive-web/ui-api";
import { RequestStatus } from "@thrive-web/ui-model";
import { make_displayable_error } from "@thrive-web/ui-common";
import { createNamedContext } from "@thrive-web/ui-utils";

export type Taxonomies = {
  ExperienceCategory: ExperienceCategory;
  Mood: Mood;
  GoalPreset: GoalPreset;
  TouchpointAction: TouchpointAction;
  TouchpointAccomplishment: TouchpointAccomplishment;
  ExpenseCategory: ExpenseCategory;
  ExpenseReason: ExpenseReason;
  GenderIdentity: GenderIdentity;
  EthnicIdentity: EthnicIdentity;
  GroupIdentity: GroupIdentity;
  RaceIdentity: RaceIdentity;
  CustomIdentity: CustomIdentity;
};

export type TaxonomiesContextSpec<T extends keyof Taxonomies> = {
  // raw array of records
  value: Taxonomies[T][] | null;
  // dict of records keyed on record ids
  map: ObjectOf<Taxonomies[T]> | null;
  // status of the fetch request
  status: RequestStatus;
};

export type TaxonomiesDict = {
  [K in keyof Taxonomies]: TaxonomiesContextSpec<K>;
};

export type TaxonomiesContext = {
  [K in keyof Taxonomies]: Preact.Context<TaxonomiesContextSpec<K>>;
} & {
  fetch: Preact.Context<
    <K extends keyof Taxonomies>(key: K) => TaxonomiesContextSpec<K>
  >;
};

const TAXONOMIES: TaxonomiesDict = {
  ExperienceCategory: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  Mood: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  GoalPreset: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  TouchpointAction: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  TouchpointAccomplishment: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  ExpenseCategory: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  ExpenseReason: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  GenderIdentity: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  EthnicIdentity: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  GroupIdentity: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  RaceIdentity: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
  CustomIdentity: {
    value: null,
    map: null,
    status: {
      pending: false,
      success: false,
      error: undefined,
    },
  },
};

export const TAXONOMIES_CTX: TaxonomiesContext = {
  // @ts-expect-error:
  fetch: createNamedContext<>(() => {}, "TaxonomiesFetch"),
};

Object.entries(TAXONOMIES).forEach(([k, v]) => {
  TAXONOMIES_CTX[k] = createNamedContext(v, `Tx${k}`);
});

export class TaxonomiesProvider extends Preact.Component<{}, TaxonomiesDict> {
  constructor(props) {
    super(props);
    this.state = { ...TAXONOMIES };
    // track pending for each taxonomy individually
    this.pending = {
      ExperienceCategory: false,
      Mood: false,
      GoalPreset: false,
      TouchpointAction: false,
      TouchpointAccomplishment: false,
      ExpenseCategory: false,
      ExpenseReason: false,
      GenderIdentity: false,
      RaceIdentity: false,
      EthnicIdentity: false,
      GroupIdentity: false,
      CustomIdentity: false,
    };
  }
  pending: { [K in keyof Taxonomies]: boolean };

  fetchTaxonomy = <K extends keyof Taxonomies>(key: K) => {
    const { value, status } = this.state[key];
    if (value !== null || this.pending[key] || status.pending) {
      return;
    }
    this.pending[key] = true;
    this.setState({
      [key]: {
        value: null,
        status: {
          pending: true,
          success: false,
          error: undefined,
        },
      },
    });
    api().then(api_ =>
      api_
        .typeMethod(key, "GET", undefined, {
          query: {
            limit: 100,
            // @ts-expect-error:
            sort: [{ by: "display_rank", dir: "asc" }],
          },
        })
        .then(map_api_response)
        .then(({ data }) => {
          this.pending[key] = false;
          // create id mapping
          const map: any = {};
          data.forEach(t => {
            map[t.id] = t;
          });
          this.setState({
            [key]: {
              value: data,
              map,
              status: {
                pending: false,
                success: true,
                error: undefined,
              },
            },
          });
        })
        .catch(err => {
          this.pending[key] = false;
          this.setState({
            [key]: {
              value: null,
              status: {
                pending: false,
                success: false,
                error: make_displayable_error(err),
              },
            },
          });
        })
    );
  };

  render() {
    return Object.entries(TAXONOMIES_CTX).reduce(
      (children, [key, { Provider }]: any) => (
        <Provider
          value={key === "fetch" ? this.fetchTaxonomy : this.state[key]}
        >
          {children}
        </Provider>
      ),
      this.props.children
    );
  }
}

// fetch taxonomies but don't return the data (for pre-fetching)
export const useTaxonomyFetch = <K extends keyof Taxonomies>(key: K) => {
  const fetchTaxonomies = useContext(TAXONOMIES_CTX.fetch);
  useEffect(() => {
    fetchTaxonomies(key);
  }, []);
};

// get a taxonomy, and fetch it if necessary
export const useTaxonomy = <K extends keyof Taxonomies>(
  key: K
): TaxonomiesContextSpec<K> => {
  useTaxonomyFetch(key);
  // @ts-expect-error:
  return useContext(TAXONOMIES_CTX[key]);
};
