import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const PageBody: Preact.FunctionComponent<HTMLDivProps> = ({
  className,
  children,
  ...props
}) => (
  <div
    className={`page-body fake-margins${maybeClassName(className)}`}
    {...props}
  >
    <div className="page-body__content">{children}</div>
  </div>
);
