import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import { Goal, MappedApiResponse, WriteGoal } from "@thrive-web/ui-api";
import {
  DefaultModalContent,
  DeleteModalBody,
  GroupGoalForm,
} from "@thrive-web/ui-components";

export const GroupGoalCreateModal: Preact.FunctionComponent<
  ModalBodyProps & {
    onSubmit: (goal: WriteGoal) => Promise<MappedApiResponse<"createGoal">>;
  }
> = ({ closeButton, ...props }) => (
  <DefaultModalContent title="Add a Goal" closeButton={closeButton}>
    <GroupGoalForm submitText="Add a Goal" submitIcon="add" {...props} />
  </DefaultModalContent>
);

export const GroupGoalEditModal: Preact.FunctionComponent<
  ModalBodyProps & {
    record: Goal;
    onSubmit: (patched: WriteGoal) => Promise<MappedApiResponse<"updateGoal">>;
  }
> = ({ closeButton, ...props }) => {
  return (
    <DefaultModalContent title="Edit Goal" closeButton={closeButton}>
      <GroupGoalForm
        submitText="Save Changes"
        submitIcon="checked"
        {...props}
      />
    </DefaultModalContent>
  );
};

export const GroupGoalDeleteModal: Preact.FunctionComponent<
  ModalBodyProps & {
    record: Goal;
    onSubmit: (deleted: Goal) => Promise<MappedApiResponse<"deleteGoal">>;
  }
> = ({ closeButton, record, onSubmit, ...props }) => {
  const deleteRecord = useCallback(() => onSubmit(record), [record, onSubmit]);
  return (
    <DefaultModalContent title="Delete Goal" closeButton={closeButton}>
      <DeleteModalBody deleteRecord={deleteRecord} {...props}>
        Are you sure you want to delete this goal?
      </DeleteModalBody>
    </DefaultModalContent>
  );
};
