import { DocBase } from "@thrive-web/core";
import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { remove_item_from } from "@thrive-web/ui-common";
import {
  usePreviousValue,
  useRequest,
  useStateObject,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import {
  DefaultPendingView,
  SearchBar,
  EmptyList,
  InfiniteScrollLoader,
  useLazyList,
  LazyListSection,
  DefaultErrorView,
} from "@thrive-web/ui-components";

export type UserSearchFn = (
  search: string,
  offset: number,
  limit?: number
) => Promise<DocBase & { data: User[] }>;

// takes an async function and render function, and fetches/renders a list
// of users
export const SelectUserModal: Preact.FunctionComponent<
  MaybeClass & {
    selected?: readonly User[];
    getUsers: UserSearchFn;
    renderUser: (
      user: User,
      selected: boolean,
      remove_on_select: (user: User) => void
    ) => Preact.VNode | null;
    allowSearch?: boolean;
    removeOnSelect?: boolean;
    emptyLabel?:
      | string
      | Preact.VNode
      | ((search: string) => string | Preact.VNode);
  }
> = ({
  getUsers,
  selected,
  className,
  renderUser,
  allowSearch = true,
  emptyLabel,
  children,
}) => {
  const scroll_container = useRef<HTMLDivElement>();

  const [{ search, offset, total, users }, set_state] = useStateObject({
    search: "",
    offset: 0,
    total: -1,
    users: null as User[] | null,
  });
  const users_ref = useValueRef(users);
  const search_ref = useValueRef(search);
  // store prev search string so that when we have an empty result and change the text,
  // we continue to display the old text in the empty view
  const prev_search = usePreviousValue(search);

  // get users with search string
  const get_filtered_users_bound = useCallback(
    (offset: number, limit?: number) => getUsers(search, offset, limit),
    [search, getUsers]
  );
  const [get_filtered_users, { pending, error }] = useRequest(
    get_filtered_users_bound,
    true
  );

  const on_change_search = useCallback(
    (str: string) => {
      if (str !== search_ref.current && !(!str && !search_ref.current)) {
        set_state({ search: str, offset: 0, total: -1 });
      }
    },
    [set_state]
  );

  const on_load_more = useCallback(
    () =>
      users_ref.current?.length != null &&
      set_state({ offset: users_ref.current?.length || 0 }),
    [set_state]
  );

  // remove the user from the list after selecting them
  const remove_on_select = useCallback(
    (user: User) => {
      set_state({
        users: users_ref.current
          ? remove_item_from(users_ref.current, u => u.id === user.id)
          : users_ref.current,
      });
    },
    [users_ref, set_state]
  );

  const get_filtered_users_paged = useCallback(
    (offset: number, limit?: number) =>
      get_filtered_users(offset, limit).then(result => {
        // scroll to top when starting from 0
        if (offset === 0) {
          scroll_container.current?.scrollTo({ top: 0 });
        }
        prev_search.current = search;
        set_state({
          users:
            offset === 0
              ? result.data
              : (users_ref.current || []).concat(result.data),
          total: (result.meta?.total_result_count as number) ?? -1,
        });
      }),
    [get_filtered_users, set_state]
  );

  // fetch whenever the fetch function or offset change
  useEffect(() => {
    get_filtered_users_paged(offset);
  }, [offset, get_filtered_users_paged]);

  // map of users and their selected status
  const list = useMemo(
    () =>
      users
        ? users.map(
            u =>
              [
                u,
                selected ? selected.some(u_s => u_s.id === u.id) : false,
              ] as const
          )
        : null,
    [users, selected]
  );

  const content = useLazyList(
    list || [],
    ([u, sel]) => renderUser(u, sel, remove_on_select),
    [renderUser, remove_on_select],
    25
  );

  return (
    <div className={`invite-users-modal stack${maybeClassName(className)}`}>
      {allowSearch && (
        <div className="invite-users-modal__search">
          <SearchBar
            placeholder="Search by Name, Phone, or Email"
            onSubmit={on_change_search}
            autoSubmitDelay={750}
            error={error}
            pending={pending && offset === 0 && !!list}
          />
        </div>
      )}
      {children}
      <div
        className="invite-users-modal__people stack__scrolling-content"
        data-empty={`${!list || !list.length}`}
        ref={scroll_container}
      >
        {list ? (
          list.length ? (
            <div className="invite-users-modal__people__list">
              {content.map((s, i) => (
                <LazyListSection key={i}>{s}</LazyListSection>
              ))}
              {total === -1 || (list.length >= total && !pending) ? null : (
                <InfiniteScrollLoader
                  nextPage={on_load_more}
                  pending={pending}
                  error={error}
                />
              )}
            </div>
          ) : (
            <EmptyList>
              {typeof emptyLabel === "function"
                ? emptyLabel(prev_search.current || "")
                : emptyLabel || "No users found"}
            </EmptyList>
          )
        ) : error ? (
          <DefaultErrorView error={error} />
        ) : (
          <DefaultPendingView />
        )}
      </div>
    </div>
  );
};
