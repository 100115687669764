import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { forwardRef } from "preact/compat";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { Connection, User } from "@thrive-web/ui-api";
import { EVENTS, InteractionType } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  useApiMethod,
  useAppUser,
  useMemoRef,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  get_shared_connection_from_user,
  withIcon,
} from "@thrive-web/ui-components";

export interface UserContactLinkProps {
  type: InteractionType;
  user: User;
  connection?: Connection;
  onFinish?: () => void;
  onCreateInteraction?: (health?: number) => void;
  noInteraction?: boolean;
}

export const UserContactLink = forwardRef<
  HTMLAnchorElement | null,
  UserContactLinkProps & HTMLAnchorProps
>(
  (
    {
      type,
      noInteraction,
      user,
      connection,
      onFinish,
      onCreateInteraction,
      ...props
    },
    ref
  ) => {
    const dispatch = useContext(CONTEXTS.dispatch);
    const self = useAppUser();
    // try to get connection record from props (from `connection`, or from either user's
    // `has_connection` property
    const props_connection = useMemo<Connection | undefined>(
      () =>
        connection
          ? connection
          : !!self && !!user && !!user.has_connection
          ? get_shared_connection_from_user(user, self)
          : undefined,
      [user, self]
    );
    const [state_connection, set_state_connection] =
      useStateIfMounted(props_connection);
    const open_on_get_conn = useRef(false);

    const get_user_req = useApiMethod("getUser");

    const int_payload = useMemoRef(
      () => ({
        user,
        connection: state_connection,
        onClose: onFinish,
        onCreateInteraction,
      }),
      [user, state_connection, onFinish, onCreateInteraction]
    );

    const onClickContact = useCallback(
      e => {
        // @ts-expect-error:
        props.onClick?.(e);
        if (!user || !self || user?.id === self?.id) {
          return;
        }

        analytics.log_event(
          analytics.EVENTS.profile.contact,
          undefined,
          undefined,
          true
        );
        // if we don't have their connection record, fetch it
        if (!state_connection) {
          get_user_req(user.id, {
            query: { include: ["has_connection"] },
          }).then(({ data }) => {
            const conn = get_shared_connection_from_user(
              data,
              self || undefined
            );
            set_state_connection(conn);
            // if we've set the flag to open the interaction modal after fetching the
            // connection, open the modal and reset the flag
            if (conn && open_on_get_conn.current) {
              open_on_get_conn.current = false;
              dispatch({
                type: EVENTS.SET_INTERACTION_DATA,
                payload: {
                  user,
                  connection: conn,
                  type,
                  onClose: onFinish,
                },
              });
            }
          });
        }
        // clicking the contact link (mailto: or tel:) will cause the page to lose focus
        // when focus is returned,
        window.addEventListener(
          "focus",
          () => {
            // if we don't have the connection record yet, set the flag and return
            if (
              !int_payload.current?.user ||
              !int_payload.current?.connection
            ) {
              open_on_get_conn.current = true;
              return;
            }
            // otherwise open the interaction modal
            dispatch({
              type: EVENTS.SET_INTERACTION_DATA,
              // @ts-expect-error:
              payload: {
                ...int_payload.current,
                type,
              },
            });
          },
          { once: true }
        );
      },
      [
        user,
        self,
        int_payload,
        state_connection,
        get_user_req,
        set_state_connection,
        dispatch,
      ]
    );

    return (
      <a
        {...props}
        ref={ref}
        onClick={noInteraction ? undefined : onClickContact}
      />
    );
  }
);

export const UserContactLinkWithIcon = withIcon<
  UserContactLinkProps & HTMLAnchorProps,
  HTMLAnchorElement
>((props, ref) => <UserContactLink ref={ref} {...props} />, "LinkWithIcon");
