import * as Preact from "preact";
import { useLinkPreview } from "@thrive-web/ui-hooks";
import { VideoLinkMeta, ZendeskLinkMeta } from "@thrive-web/ui-common";
import { ButtonWithIcon } from "@thrive-web/ui-components";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";

export const PostLinkPreview: Preact.FunctionComponent<{
  link: string;
  onRemove?: () => void;
  onClickLink?: (e) => void;
}> = ({ link, onRemove, onClickLink }) => {
  const [meta, status] = useLinkPreview(link);

  if (status.error) {
    return null;
  }
  if (meta && !status.pending) {
    const Tag = onRemove ? "div" : "a";
    const Preview = (meta as VideoLinkMeta).thumbnail_url
      ? PostLinkVideoPreview
      : PostLinkZendeskPreview;
    return (
      <Tag
        className="post__link-preview post-block"
        href={link}
        target="_blank"
        onMouseDown={onClickLink}
      >
        <Preview {...meta}>
          {onRemove && (
            <ButtonWithIcon
              className="filled gray post__form__photo-preview__remove"
              icon="remove"
              side="right"
              collapse={true}
              onClick={onRemove}
            >
              Remove
            </ButtonWithIcon>
          )}
        </Preview>
      </Tag>
    );
  }
  if (status.pending && meta) {
    return (
      <div className="post__link-preview post-block loading-item">
        <div className="post__link-preview__content post-block__body">
          <div className="post__link-preview__thumbnail">
            <div className="loading-item__image  loading-item__shaded loading-item__shaded--dark" />
          </div>
          <div className="post__link-preview__details">
            <div className="post__link-preview__site">
              <div className="loading-item__text loading-item__shaded loading-item__shaded--dark" />
            </div>
            <h4 className="post__link-preview__title">
              <div className="loading-item__text loading-item__title loading-item__shaded loading-item__shaded--dark" />
            </h4>
            {onRemove && (
              <ButtonWithIcon
                className="filled gray post__form__photo-preview__remove"
                icon="remove"
                side="right"
                collapse={true}
                onClick={onRemove}
              >
                Remove
              </ButtonWithIcon>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const PostLinkVideoPreview: Preact.FunctionComponent<VideoLinkMeta> = ({
  thumbnail_url,
  site,
  title,
  children,
}) => (
  <div className="post__link-preview__content post-block__body post__link-preview__video">
    <div className="post__link-preview__thumbnail">
      <img src={thumbnail_url} />
    </div>
    <div className="post__link-preview__details">
      <div className="post__link-preview__site">{site}</div>
      <h4 className="post__link-preview__title">{title}</h4>
    </div>
    {children}
  </div>
);

export const PostLinkZendeskPreview: Preact.FunctionComponent<ZendeskLinkMeta> =
  ({ url, title, children }) => (
    <div className="post__link-preview__content post-block__body post__link-preview__zendesk">
      <div className="post__link-preview__icon">
        <img
          className="thrive-icon"
          src={`${IMAGES_PREFIX}/logo.svg`}
          data-size="sm"
        />
      </div>
      <div className="post__link-preview__details">
        <h4 className="post__link-preview__title">Thread Guide</h4>
        <div className="post__link-preview__div">{title}</div>
      </div>
      {children}
    </div>
  );
