import { DocBase, map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import {
  Address,
  ApiMethodCaller,
  ApiMethodParameters,
  Event,
  MappedApiResponse,
  WriteAddress,
  WriteEvent,
} from "@thrive-web/ui-api";
import {
  DefaultModalContent,
  ErrorMessage,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import { EventForm } from "~/view/components";
import {
  useApiMethod,
  useAppUser,
  useRequest,
  useRequestChain,
  useStateRef,
  useUntrackedRequestWithMedia,
} from "@thrive-web/ui-hooks";

export interface EventEditProps {
  target: Event | null;
  onFinish: (event: WriteEvent) => void;
}

export const EventEdit: Preact.FunctionComponent<
  ModalBodyProps & EventEditProps
> = ({ onFinish, target, dismiss, closeButton }) => {
  const self = useAppUser();
  // cover image data, before any edits
  const initial_photo = useMemo<FileUploadData | undefined>(
    () =>
      target?.cover_image_url
        ? { data: target.cover_image_url, name: "", mime: "" }
        : undefined,
    [target]
  );

  // cover image file
  const [file, on_change_file, file_ref] = useStateRef<
    FileUploadData | undefined
  >(undefined);

  // track whether there was an image before any edits were made
  const initial_file_set = useRef(false);
  if (initial_photo && !file_ref.current && !initial_file_set.current) {
    file_ref.current = initial_photo;
    initial_file_set.current = true;
  }

  // force the component to rerender to show the updated file
  const [, set_force_update, update_ref] = useStateRef<boolean>(false);
  const on_change_file_ = useCallback(
    (new_file: FileUploadData | undefined) => {
      if (!file && !!file_ref.current && !new_file) {
        set_force_update(!update_ref.current);
      }
      on_change_file(new_file);
    },
    [set_force_update, !file]
  );

  // POST/PATCH/DELETE type/Address
  const update_address_request = useLocationUpdate(target);
  // PATCH type/Event
  const update_event_record_request = useApiMethod("updateEvent");

  // update event and upload cover image
  const [update_event_request, , event_progress] = useUntrackedRequestWithMedia(
    "Event",
    "cover_image",
    update_event_record_request,
    file,
    false,
    true
  );

  // merge create-address result with event data
  const get_event_params_from_location = useCallback(
    (
      location: LocationUpdateResult,
      params: ApiMethodParameters<"updateEvent">
    ) => {
      const data = params[1];
      if (!data?.body?.data?.relationships) {
        data!.body.data.relationships = {};
      }
      data!.body.data.relationships.location = {
        data: location ? { id: location.id } : null,
      };
      // @ts-expect-error:
      data!.body.data.attributes.location_name = location
        ? location.common_name
        : null;

      params[1] = data;

      return params;
    },
    []
  );

  // combine new event and address records
  const combine_event_and_location = useCallback(
    (
      location: LocationUpdateResult,
      event: MappedApiResponse<"updateEvent">
    ): DocBase & { data: Event } => {
      return {
        ...event,
        data: {
          ...event.data,
          // @ts-expect-error:
          location,
        },
      };
    },
    []
  );

  // chain address and event updates
  const update_event_chained = useRequestChain<
    LocationUpdateFunc,
    ApiMethodCaller<"updateEvent">,
    MappedApiResponse<"updateEvent">
  >(
    update_address_request,
    update_event_request,
    combine_event_and_location,
    get_event_params_from_location
  );

  const update_event = useCallback(
    (new_event: Event) => {
      // put in JSON api format
      const { id, type, ...data } = map_record_to_json_resource(
        new_event,
        TYPES.Event
      );
      // this form should never alter has_invitee
      delete data.relationships?.has_invitee;

      // params for updateEvent request
      const params_2: ApiMethodParameters<"updateEvent"> = [
        id || target?.id,
        {
          body: { data },
          query: {
            include: ["location", "has_invitee", "has_rsvp"],
          },
        },
      ];

      // if no location, only update the event record
      if (!("location" in new_event)) {
        return update_event_request(...params_2).then(res => {
          analytics.log_event(analytics.EVENTS.event_edit_saved);
          return res;
        });
      }

      // params for updateAddress request
      const params_1: Parameters<LocationUpdateFunc> = [new_event.location];
      return update_event_chained(params_1, params_2).then(res => {
        analytics.log_event(analytics.EVENTS.event_edit_saved);
        return res;
      });
    },
    [update_event_request, update_event_chained]
  );

  const [submit_form, { pending, success, error }] = useRequest(update_event);

  const on_finish = useCallback(
    (new_event: Event): void => {
      dismiss();
      onFinish({
        ...new_event,
        created_by: self || new_event.created_by,
      });
    },
    [self, onFinish, dismiss]
  );

  if (!target) {
    return null;
  }

  return (
    <DefaultModalContent title="Edit Event" closeButton={closeButton}>
      <EventForm
        initialData={target}
        photoFile={file_ref.current}
        onChangeFile={on_change_file_}
        onSubmit={submit_form}
        onFinish={on_finish}
      >
        <div className="event-form__footer modal__footer">
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          <RequestButtonWithIcon
            className="filled gray"
            icon="checked"
            side="left"
            type="submit"
            pending={pending}
            success={success}
            successText="Success!"
            progress={event_progress}
          >
            Save Changes
          </RequestButtonWithIcon>
        </div>
      </EventForm>
    </DefaultModalContent>
  );
};

type LocationUpdateFunc = (
  new_location?: WriteAddress | null
) => Promise<LocationUpdateResult>;
type LocationUpdateResult = Address | null;
// performs the appropriate operation (create, edit, delete) depending on if there is
// an existing record and a new/updated record
// ensures that any leftover props from the old location that aren't used by the new
// location are deleted
const useLocationUpdate = (initial: Event | null): LocationUpdateFunc => {
  const create = useApiMethod("createAddress");
  const update = useApiMethod("updateAddress");
  const remove = useApiMethod("deleteAddress");
  const prev_addr = useMemo(() => initial?.location, []);
  // the base object to build the new location with
  const updated_base = useMemo(() => {
    if (!prev_addr) {
      return {};
    }
    let base = {};
    // set all old fields to null so that they get deleted when we send the req
    Object.keys(prev_addr).forEach(k => {
      base[k] = null;
    });
    return base;
  }, []);

  return useCallback<LocationUpdateFunc>(
    new_location => {
      if (new_location) {
        const {
          id: _,
          type: __,
          ...address_data
        } = map_record_to_json_resource(
          { ...updated_base, ...new_location, id: "" },
          TYPES.Address
        );
        const params = {
          body: { data: address_data },
        };

        // if there was no existing record, create a new one
        if (!prev_addr) {
          return create(params).then(({ data }) => data);
        } else {
          // if there is an existing record, update it
          return update(prev_addr.id, params).then(({ data }) => data);
        }
      } else if (prev_addr) {
        // if the location is removed, delete the record
        return remove(prev_addr.id).then(() => null);
      }
      return Promise.resolve(null);
    },
    [create, update, remove, prev_addr?.id]
  );
};
