import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { Comment } from "@thrive-web/ui-api";
import {
  useApiCall,
  useAppUser,
  useChildRef,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { useCallback, useEffect, useRef } from "preact/hooks";
import {
  Avatar,
  RequestButtonWithIcon,
  TextAreaWithFormHelpers,
} from "@thrive-web/ui-components";

export const PostAddComment: Preact.FunctionComponent<{
  id?: string;
  onSuccess: (comment: Comment) => void;
  postId: string;
  focused?: boolean;
}> = ({ onSuccess, postId, focused, id }) => {
  const user = useAppUser();
  const [text, _setText] = useStateIfMounted("");
  // force height back to 0 after submission to "un-expand" the textarea
  const [forceHeight, setForceHeight] = useStateIfMounted(false);
  const form = useRef<HTMLFormElement>();
  const setText = useCallback(
    e => {
      _setText(e.target.value);
      if (forceHeight) {
        setForceHeight(false);
      }
    },
    [_setText, forceHeight, setForceHeight]
  );
  const [postComment, { pending, error }] = useApiCall("createComment");
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      postComment({
        body: {
          data: {
            attributes: {
              body: text,
            },
            relationships: {
              posted_to: { data: { id: postId } },
            },
          },
        },
      }).then(({ data }) => {
        analytics.log_event(
          analytics.EVENTS.post_comment,
          undefined,
          undefined,
          true
        );
        _setText("");
        form.current && form.current.reset();
        setForceHeight(true);
        onSuccess(data);
      });
    },
    [_setText, form, setForceHeight, postComment, text]
  );
  const [input_ref, set_input_ref] = useChildRef<HTMLTextAreaElement>();
  useEffect(() => {
    if (focused) {
      input_ref.current?.focus();
      window.history.replaceState(
        {},
        "",
        window.location.href.replace(window.location.hash, "")
      );
    }
  }, []);

  if (!user) {
    return null;
  }
  return (
    <div className="post__comment__add">
      <form ref={form} onSubmit={onSubmit}>
        <div className="post__comment__add__avatar">
          <Avatar user={user} size="btn" />
        </div>
        <TextAreaWithFormHelpers
          id={id}
          inputRef={set_input_ref}
          onChange={setText}
          value={text}
          placeholder="Add a comment..."
          style={forceHeight ? { height: "0" } : undefined}
          controlled={true}
          autoFocus={focused}
        />
        <RequestButtonWithIcon
          pending={pending}
          successText=""
          className="filled post__comment__add__submit"
          icon="paper-airplane"
          type="submit"
          disabled={!text || pending}
          error={error}
          showError={true}
          retryText=""
        />
      </form>
    </div>
  );
};
