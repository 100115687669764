import * as Preact from "preact";
import DayPicker from "react-day-picker";
// @ts-expect-error:
import { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import {
  FakeDateInput,
  Icon,
  InputWithFormHelpers,
  Popover,
  PopoverTriggerProps,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import {
  useCallbackRef,
  useId,
  usePortal,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  data_attrs,
  DATE_PATTERN,
  maybeClassName,
  validate_date,
} from "@thrive-web/ui-utils";
import { moment } from "@thrive-web/ui-common";
import DayPickerInput from "react-day-picker/DayPickerInput";

export interface DatePickerControlProps extends PopoverTriggerProps {
  value?: Date;
  onChange: (value: Date) => void;
}

export interface DatePickerProps {
  value?: Date;
  onChange: (value) => void;
  children: (controlProps: DatePickerControlProps) => Preact.VNode | null;
  popoverProps?: Omit<PopoverProps, "triggerComponent" | "show">;
  popoverTriggerOptions?: PopoverTriggerOptions;
  min?: string;
  max?: string;
}

export type DatePickerTriggerInput = Preact.ComponentType<
  HTMLInputProps & { onChangeValue: (val: string) => void; close?: void }
>;

const renderDay = day => (
  <div className="date-picker__day">{day.getDate()}</div>
);

export class DatePickerInput extends InputWithFormHelpers<
  string,
  HTMLInputProps & {
    type?: "date";
    min?: string;
    max?: string;
    onChangeValue: (value?: string) => void;
    close?: () => void;
  }
> {
  constructor(props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  validate = (value: string) => {
    const { min, max } = this.props;
    return validate_date(
      value,
      this.input.current || undefined,
      min ? moment(min) : undefined,
      max ? moment(max) : undefined
    );
  };

  // upon pressing Esc, close the popover
  onKeyDownEsc = e => {
    if (
      this.props.close &&
      (e.key === "Escape" ||
        (e.key === "Enter" &&
          this.validate(this.value || this.props.value || "")))
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.props.close();
    } else {
      this.onKeyDown(e);
    }
  };

  handleValueChange(value: string) {
    this.props.onChangeValue(value);
    this.updateAttributes({
      value: value,
      required: this.props.required,
    });
  }

  // call props change handler and internal change handler
  handleChange = e => {
    const { value } = e.target;
    this.props.onChangeValue(value);
    this.onChangeBound(e);
    this.updateAttributes({
      value: e.target.value,
      required: this.props.required,
    });
  };

  render() {
    const {
      value,
      type,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      ref,
      ...props
    } = this.props;
    return (
      <div className="input__container datetime__container">
        <FakeDateInput
          {...data_attrs({
            native: "false",
            value: value as string,
            empty: this.empty,
            valid: this.valid,
            dirty: this.dirty,
          })}
          value={value ? moment(value).format("YYYY-MM-DD") : undefined}
          controlled={true}
          {...props}
          pattern={DATE_PATTERN}
          onChangeValue={this.handleValueChange}
          onChange={this.handleChange}
          onKeyDown={this.onKeyDownEsc}
          onChangeDebounce={false}
        />
      </div>
    );
  }
}

// arrows/current month display inside datepicker calendar popover
export class RenderNavBar extends Preact.Component<{
  onPreviousClick;
  onNextClick;
  className;
}> {
  render() {
    const { onPreviousClick, onNextClick, className } = this.props;
    return (
      <div className={className}>
        <span
          role="button"
          aria-label="Previous Month"
          className="DayPicker-NavButton date-picker__nav-button--prev"
          onClick={() => onPreviousClick()}
        >
          <Icon name="chevron-left" />
        </span>
        <span
          role="button"
          aria-label="Next Month"
          className="DayPicker-NavButton date-picker__nav-button--next"
          onClick={() => onNextClick()}
        >
          <Icon name="chevron-right" />
        </span>
      </div>
    );
  }
}

// Date picker without editable text input as the trigger
export const DatePicker: Preact.FunctionComponent<DatePickerProps> = ({
  onChange,
  children,
  value,
  popoverProps,
  popoverTriggerOptions = {
    click: true,
    focus: false,
    hover: false,
  },
  min,
  max,
}) => {
  // popover component id
  const id = useId("datepicker");
  const [open, setOpen, triggerProps] = usePopoverTrigger(
    popoverTriggerOptions,
    id
  );

  const onChangeValue = useCallback(
    (value, mods?) => {
      // close popover upon changing the value
      if (!mods?.disabled) {
        setOpen(false);
        return onChange(value);
      }
    },
    [onChange, setOpen]
  );

  const triggerComponent = useMemo(
    () => children({ ...triggerProps, value, onChange }),
    [triggerProps, value, onChange]
  );

  // disable values outside the allowed range
  const disabled = useMemo(
    () => ({
      before: min ? new Date(`${min}:00:00`) : undefined,
      after: max ? new Date(`${max}:00:00`) : undefined,
    }),
    [min, max]
  );

  return (
    <Popover
      {...popoverProps}
      className={`date-picker__container${maybeClassName(
        popoverProps?.className
      )}`}
      id={id}
      triggerComponent={triggerComponent}
      show={open}
      mountLocal={true}
    >
      {
        // @ts-expect-error:
        <DayPicker
          className="date-picker"
          tabIndex={open ? undefined : -1}
          showOutsideDays={true}
          onDayClick={onChangeValue}
          selectedDays={value}
          renderDay={renderDay}
          navbarElement={RenderNavBar}
          disabledDays={disabled}
        />
      }
    </Popover>
  );
};

const DatePickerWithInput_inner_props = {
  className: "date-picker",
  renderDay,
  navbarElement: RenderNavBar,
};
// Date picker with editable text input as the trigger
export const DatePickerWithInput: Preact.FunctionComponent<
  Omit<DatePickerProps, "children"> & {
    TriggerComponent?: DatePickerTriggerInput;
  }
> = ({
  onChange,
  value,
  popoverProps,
  popoverTriggerOptions = {
    click: true,
    focus: true,
    hover: false,
  },
  TriggerComponent = DatePickerInput,
}) => {
  // popover component id
  const id = useId("datepicker");
  const [mounted, set_mounted] = useStateIfMounted(false);
  const portal = usePortal(`${id}-trigger`);
  const picker = useRef<DayPickerInput>();
  useEffect(() => {
    // set mounted = true once the portal has mounted
    if (!mounted && portal) set_mounted(true);
  }, [portal]);

  const [open, setOpen, triggerProps] = usePopoverTrigger(
    popoverTriggerOptions,
    id
  );

  const cur_value = useRef<Date | undefined>(value);
  cur_value.current = value;

  const close = useCallbackRef(() => setOpen(false), [setOpen]);
  if (!open) {
    // @ts-expect-error:
    close.current = undefined;
  }

  // focus whichever section of the input was last focused before opening the popover
  const re_focus_input = useCallback(
    (clear?: boolean) => {
      const input = document.getElementById(`${id}-input`);
      const last_focused = input?.getAttribute("data-last-focused");
      if (last_focused) {
        if (clear) {
          input?.removeAttribute("data-last-focused");
        } else {
          document.getElementById(`${id}-input-${last_focused}`)?.focus();
        }
      }
    },
    [id]
  );
  // change listener for when the input element is directly changed
  const onChangeTrigger = useCallback(
    (val?: string) => {
      re_focus_input();
      return onChange(val);
    },
    [re_focus_input, onChange]
  );

  // change listener for the DayPicker component
  const onChangeValue = useCallback(
    (val, _, picker_instance) => {
      picker.current = picker_instance;
      if (cur_value.current !== val) {
        return onChangeTrigger(val);
      }
    },
    [onChangeTrigger, setOpen]
  );

  const inputComponent = useRenderPropsFunction(
    ({ onBlur, onKeyDown, ...props }: any) => {
      return portal(
        <TriggerComponent
          {...props}
          id={`${id}-input`}
          onChangeValue={onChangeTrigger}
          close={close.current}
        />
      );
    },
    "DatePickerWithInput-Trigger",
    [portal, onChangeTrigger]
  );

  const onAutoHide = useCallback(() => {
    picker.current?.showDayPicker();
  }, []);

  // upon clicking a day, close the popover and return focus to the input
  const onDayClick = useCallback(() => {
    re_focus_input(true);
    setOpen(false);
  }, [re_focus_input, setOpen]);
  const picker_props = useMemo(
    () => ({
      onDayClick,
      tabIndex: open ? undefined : -1,
      ...DatePickerWithInput_inner_props,
    }),
    [setOpen, open, onDayClick]
  );

  useEffect(() => {
    picker.current?.showDayPicker();
  }, [open]);

  return (
    <Popover
      {...popoverProps}
      className={`date-picker__container${maybeClassName(
        popoverProps?.className
      )}`}
      id={id}
      triggerComponent={
        <div className="date-picker__trigger" id={`${id}-trigger`} />
      }
      show={open}
      mountLocal={true}
    >
      <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="YYYY-MM-DD"
        // @ts-expect-error:
        dayPickerProps={picker_props}
        value={value}
        onDayChange={onChangeValue}
        showOverlay={true}
        placeholder="MM/DD/YYYY"
        inputProps={triggerProps}
        hideOnDayClick={false}
        component={inputComponent}
        onDayPickerHide={onAutoHide}
      />
    </Popover>
  );
};
