import * as Preact from "preact";
import { PureComponent } from "preact/compat";
import {
  DefaultErrorView,
  DefaultPendingView,
  Icon,
} from "@thrive-web/ui-components";

export interface PageLoaderProps {
  nextPage: () => void;
  pending?: boolean;
  error?: DisplayableError;
}

// invokes a callback when the rendered element is scrolled into view
export class InfiniteScrollLoader extends PureComponent<
  PageLoaderProps,
  { initiated: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      initiated: false,
    };
    this.container = Preact.createRef();
    this.observer = new IntersectionObserver(this.obsCallback, {
      threshold: 0.8,
    });
  }
  observer: IntersectionObserver;
  container: Preact.RefObject<HTMLTableSectionElement>;
  try_again: boolean = false;

  componentDidMount() {
    if (this.container.current) {
      this.observer.observe(this.container.current);
      this.obsCallback(this.observer.takeRecords());
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  componentDidUpdate(previousProps, previousState) {
    if (!previousState.initiated && this.state.initiated) {
      this.props.nextPage();
    } else if (
      previousState.initiated &&
      !this.state.initiated &&
      this.try_again
    ) {
      this.obsCallback(this.observer.takeRecords());
    }
    if (previousProps.pending && !this.props.pending) {
      this.setState({ initiated: false });
    }
  }

  obsCallback = (entries: IntersectionObserverEntry[]) => {
    if (entries[0]?.isIntersecting) {
      if (this.state.initiated) {
        this.try_again = true;
      } else {
        this.setState({ initiated: true });
      }
    }
  };

  render() {
    const {
      pending,
      error,
      children = <DefaultInfScrollElem pending={pending} error={error} />,
    } = this.props;
    return (
      <div className="inf-scroll-loader" ref={this.container}>
        {children}
      </div>
    );
  }
}

export const DefaultInfScrollElem: Preact.FunctionComponent<{
  pending?: boolean;
  error?: DisplayableError;
}> = ({ pending, error }) => (
  <div className="inf-scroll-loader__default" data-pending={`${pending}`}>
    <div className="inf-scroll-loader__default__idle">
      <Icon name="chevron-down" />
    </div>
    <div className="inf-scroll-loader__default__pending">
      <DefaultPendingView />
    </div>
    {error && <DefaultErrorView error={error} />}
  </div>
);

// invokes the callback upon clicking the button instead of upon scrolling
export const ViewMoreLoader: Preact.FunctionComponent<PageLoaderProps> = ({
  nextPage,
  pending,
  error,
}) => (
  <div className="view-more-loader" data-pending={`${pending}`}>
    <div className="view-more-loader__idle">
      <button
        className="plain-link blue"
        onClick={pending ? undefined : nextPage}
      >
        View More
      </button>
    </div>
    <div className="view-more-loader__pending">
      <DefaultPendingView />
    </div>
    {error && <DefaultErrorView error={error} />}
  </div>
);
