import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { MetaTypes, RelationshipKeysOf } from "@thrive-web/core";
import { Comment, Group, Post, Reaction, User } from "@thrive-web/ui-api";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  useAppUser,
  useStateIfMounted,
  useStateRef,
  useZendeskReportEntity,
} from "@thrive-web/ui-hooks";
import { get_cache } from "@thrive-web/ui-utils";
import {
  Avatar,
  PageContent,
  PageHeader,
  PostDeleteModal,
  PostEditModal,
  EntityLink,
  PageBody,
  PageSidebar,
  useAsyncRender,
  AvatarLoading,
  AvatarListLoading,
  LoadingMood,
  LoadingParagraph,
  AsyncRender,
  PostMood,
  PostPhoto,
  PostTouchpoint,
  PostExpense,
  CommentList,
  Card,
  ButtonWithIcon,
  PostActions,
  PostMeta,
  ReactionButton,
  Icon,
  TextWithLinks,
  PostTaggedUsers,
  PostHelp,
  isThriveGroup,
  PostEvent,
  userCanInteract,
  PostLinkPreview,
  IS_ADMIN_UI,
} from "@thrive-web/ui-components";
import {
  add_item_to,
  moment,
  remove_item_from,
  replace_item_in,
} from "@thrive-web/ui-common";
import { MODAL_ANIMATION_DELAY, ScreenSize } from "@thrive-web/ui-constants";
import { getScope } from "./utils";

const COMMENT_INPUT_ID = "post-detail-page-comment-add";

export const PostDetailPageContent: Preact.FunctionComponent<{
  data: Post;
}> = ({ data }) => {
  const self = useAppUser();

  const window_size = useContext(CONTEXTS.window_size);
  const [post, set_post, post_ref] = useStateRef(data);

  const scope = useMemo(() => getScope(post.posted_to!), [post.posted_to]);
  const [date_str, date_full] = useMemo(() => {
    // @ts-expect-error:
    const m = moment(post.created_at).tz(moment.tz.guess());
    return [m.format("MMMM D, YYYY"), m.format("dddd, MMMM Do YYYY h:mm A z")];
  }, [post.created_at]);

  const _is_thread_group = isThriveGroup(post?.posted_to);
  // @ts-expect-error:
  const allow_group_updates = _is_thread_group && post?.posted_to?.has_goals;

  const [edit_target, set_edit_target, edit_ref] = useStateRef<Post | null>(
    null
  );

  const is_admin_ui = useContext(IS_ADMIN_UI);
  const eval_can_interact = userCanInteract();
  const can_interact = useMemo(
    () => eval_can_interact?.(data),
    [eval_can_interact, data]
  );
  const onClickEdit = useCallback(
    () => set_edit_target(post_ref.current),
    [set_edit_target]
  );
  const afterEdit = useCallback(result => {
    if (!edit_ref.current) {
      return;
    }
    set_post(result);
  }, []);
  const onCloseEdit = useCallback(
    () => setTimeout(() => set_edit_target(null), MODAL_ANIMATION_DELAY),
    [set_edit_target]
  );

  const [delete_target, set_delete_target] = useStateIfMounted<Post | null>(
    null
  );
  const onClickDelete = useCallback(
    () => set_delete_target(post_ref.current),
    [set_delete_target]
  );
  const afterDelete = useCallback(() => {
    window.history.go(-1);
  }, [delete_target]);
  const onCloseDelete = useCallback(
    () => setTimeout(() => set_delete_target(null), MODAL_ANIMATION_DELAY),
    [set_delete_target]
  );
  const onReact = useCallback(
    (reaction: Reaction, deleted: boolean) => {
      const { has_reaction = [] } = post_ref.current;
      const updated = {
        ...post_ref.current,
        has_reaction: deleted
          ? remove_item_from(has_reaction, i => i.id === reaction.id)
          : add_item_to(has_reaction, reaction),
      };
      set_post(updated);
    },
    [post_ref, set_post]
  );

  const onClickComment = useCallback(() => {
    const input = document.getElementById(COMMENT_INPUT_ID);
    if (input) {
      input.focus();
    }
  }, []);

  // after a comment has been created/edited/deleted
  const onComment = useCallback(
    (comment: Comment, deleted: boolean) => {
      const { has_comment = [] } = post_ref.current;
      const updated = {
        ...post_ref.current,
        has_comment: deleted
          ? remove_item_from(has_comment, i => i.id === comment.id)
          : replace_item_in(
              has_comment,
              c => c.id === comment.id,
              comment,
              "start"
            ),
      };
      set_post(updated);
    },
    [post_ref, set_post]
  );

  const action_menu_props = useMemo(
    () => ({
      button: (
        <Icon name={post.created_by?.id === self?.id ? "edit" : "more"} />
      ),
      buttonClassName: `with-icon icon-only transparent all-gray${
        window_size < ScreenSize.sm ? " pill" : ""
      }`,
    }),
    [window_size, post.created_by?.id === self?.id]
  );
  const on_report_post = useZendeskReportEntity(data);

  const header_props = useMemo(() => {
    const title = <h1>{(post.created_by as User)?.full_name}</h1>;
    const subtitle = <div title={date_full}>{date_str}</div>;
    const icon = (
      <Avatar
        user={post.created_by as User}
        size={window_size < ScreenSize.sm ? "md" : "xl"}
      />
    );
    const button =
      can_interact || is_admin_ui ? (
        <PostActions
          menuProps={action_menu_props}
          post={post}
          editPost={post.created_by?.id === self?.id ? onClickEdit : undefined}
          deletePost={
            is_admin_ui || post.created_by?.id === self?.id
              ? onClickDelete
              : undefined
          }
          reportPost={is_admin_ui ? undefined : on_report_post}
        />
      ) : undefined;
    const breadcrumb = (
      <div className="post-detail__breadcrumb">
        <Icon name="family" />
        <EntityLink entity={post.posted_to as Group}>{scope.name}</EntityLink>
      </div>
    );
    return window_size < ScreenSize.sm && post.created_by?.id === self?.id
      ? {
          children: (
            <Preact.Fragment>
              <div className="page-header__content__breadcrumb">
                {breadcrumb}
              </div>
              <div className="page-header__content__main">
                <div className="page-header__title">
                  <div className="page-header__icon">{icon}</div>
                  {title}
                </div>
                <div className="page-header__subtitle">
                  {subtitle}
                  {button && (
                    <div className="page-header__button">{button}</div>
                  )}
                </div>
              </div>
            </Preact.Fragment>
          ),
        }
      : {
          title,
          subtitle,
          icon,
          button,
          breadcrumb,
        };
  }, [
    window_size,
    _is_thread_group,
    post.created_by?.id,
    post,
    action_menu_props,
    scope,
    onClickEdit,
    onClickDelete,
  ]);

  if (!post.created_by || !self) {
    return null;
  }

  const post_controls = can_interact ? (
    <Preact.Fragment>
      <ReactionButton
        post_id={post.id}
        showCount={false}
        className="filled gray"
        onUpdate={onReact}
        reactions={post.has_reaction as Reaction[]}
      >
        Like
      </ReactionButton>
      <ButtonWithIcon
        className="filled gray"
        icon="comment-solid-chat-bubble"
        side="left"
        onClick={onClickComment}
      >
        Comment
      </ButtonWithIcon>
    </Preact.Fragment>
  ) : null;
  const post_meta = <PostMeta post={post} />;

  const link = post.video || post.zendesk_article;

  return (
    <Preact.Fragment>
      <PageContent
        id="user-profile-page"
        className="post-page detail-page has-sidebar"
      >
        <PageHeader {...header_props} />
        <PageBody>
          <div className="post-detail__page__content">
            {window_size < ScreenSize.lg && (
              <div className="post-detail__page__side-info">
                {post.has_tagged_user && post.has_tagged_user.length > 0 && (
                  <PostTaggedUsers
                    post={post}
                    size={window_size === ScreenSize.sm ? "sm" : "md"}
                    align="left"
                    maxHeight={1}
                    maxWidth={window_size < ScreenSize.sm ? 6 : 5}
                  />
                )}
                <div className="post-detail__page__meta">
                  {post_meta}
                  {window_size < ScreenSize.lg && (
                    <div className="post__controls">
                      <div className="post__controls__left">
                        {post_controls}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(post.body || post.mood) && (
              <div className="post-detail__page__body">
                <div className="post-detail__page__body__text post__text">
                  {post.body && <TextWithLinks>{post.body}</TextWithLinks>}
                </div>
                {post.mood && <PostMood mood={post.mood} />}
              </div>
            )}
            {data.photo_url && <PostPhoto data={data} />}
            <div className="post__blocks">
              {post.is_asking_for_help && <PostHelp user={post.created_by} />}
              {post.event && <PostEvent event={post.event} isLink={true} />}
              {link && <PostLinkPreview link={link.id} />}
              {allow_group_updates && post.has_touchpoint && (
                <PostTouchpoint touchpoint={post.has_touchpoint} />
              )}
              {allow_group_updates && post.has_expense && (
                <PostExpense expense={post.has_expense} />
              )}
            </div>
            <Card className="post__comments">
              <CommentList
                id={COMMENT_INPUT_ID}
                post_id={post.id}
                onUpdate={onComment}
                focused={window.location.hash === "#comment"}
                post={post}
                canInteract={can_interact}
              />
            </Card>
          </div>
          {window_size >= ScreenSize.lg && (
            <PageSidebar className="post-detail__sidebar">
              {post.has_tagged_user && post.has_tagged_user.length > 0 && (
                <PostTaggedUsers
                  post={post}
                  size="md"
                  align="left"
                  maxHeight={1}
                  maxWidth={6}
                />
              )}
              {post_meta}
              <div className="post-detail__sidebar__buttons">
                {post_controls}
              </div>
            </PageSidebar>
          )}
        </PageBody>
      </PageContent>
      {post.created_by?.id === self?.id ? (
        <PostEditModal
          target={edit_target}
          onFinish={afterEdit}
          onClose={onCloseEdit}
        />
      ) : null}
      {post.created_by?.id === self?.id ? (
        <PostDeleteModal
          target={delete_target}
          onFinish={afterDelete}
          onClose={onCloseDelete}
        />
      ) : null}
    </Preact.Fragment>
  );
};

export const PostDetailPageLoading: Preact.FunctionComponent = () => (
  <PageContent
    id="post-detail-page"
    className="post-page detail-page has-sidebar"
  >
    <PageHeader
      breadcrumb={<div className="loading-item__shaded loading-item__text" />}
      icon={<AvatarLoading size="xl" />}
      title={
        <div className="loading-item__shaded loading-item__header loading-item__name" />
      }
      subtitle={<div className="loading-item__shaded loading-item__text" />}
    />
    <PageBody>
      <div className="post-detail__page__content">
        <div className="post-detail__page__body">
          <LoadingParagraph className="loading-item__shaded loading-item__title" />
          <LoadingMood />
        </div>
        <div className="loading-item__image loading-item__shaded loading-item__shaded" />
      </div>
      <PageSidebar className="post-detail__sidebar">
        <AvatarListLoading maxWidth={5} maxHeight={1} size="md" align="left" />
        <div className="loading-item__shaded loading-item__text" />
      </PageSidebar>
    </PageBody>
  </PageContent>
);

export const PostDetailPage: Preact.FunctionComponent<RoutePageProps> = ({
  matches: { post_id } = {},
}) => {
  const params = useMemo(
    () => ({
      query: {
        include: [
          "has_touchpoint",
          "has_reaction",
          "has_expense",
          "mood",
          "photo",
          "event.Event:cover_image",
          "created_by.User:profile_picture",
          "has_tagged_user.User:profile_picture",
          "has_comment.Comment:created_by.User:profile_picture",
          "posted_to",
        ] as RelationshipKeysOf<MetaTypes["Post"]>[],
      },
    }),
    []
  );

  const initial_data = useMemo(() => get_cache(post_id), []);

  const [SuccessView, getPost] = useAsyncRender(
    result => <PostDetailPageContent data={result.data} />,
    [],
    "getPost",
    post_id,
    params
  );

  return (
    <AsyncRender
      getPromise={getPost}
      PendingView={PostDetailPageLoading}
      initialValue={initial_data}
    >
      {SuccessView}
    </AsyncRender>
  );
};
