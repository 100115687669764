import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const GroupScoreIcon: Preact.FunctionComponent<
  MaybeClass & { progress: number; leveled?: boolean; startNextLevel?: boolean }
> = ({ className, progress, leveled, startNextLevel }) => (
  <svg
    className={`inline-svg group-score-icon${maybeClassName(className)}`}
    width="74px"
    height="74px"
    viewBox="0 -1 74 74"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-leveled={`${leveled}`}
    data-next-level={`${startNextLevel}`}
  >
    <defs>
      <polygon
        id="path-1"
        points="37 63.1506966 25.695918 69.5807742 20.3302283 57.7343594 7.40552909 56.2920288 10.0277429 43.5542047 0.419222197 34.7903871 10.0277429 26.0265695 7.40552909 13.2887454 20.3302283 11.8464148 25.695918 0 37 6.43007761 48.304082 0 53.6697717 11.8464148 66.5944709 13.2887454 63.9722571 26.0265695 73.5807778 34.7903871 63.9722571 43.5542047 66.5944709 56.2920288 53.6697717 57.7343594 48.304082 69.5807742"
      />
      <mask id="mask">
        <polygon
          fill="white"
          points="37 63.1506966 25.695918 69.5807742 20.3302283 57.7343594 7.40552909 56.2920288 10.0277429 43.5542047 0.419222197 34.7903871 10.0277429 26.0265695 7.40552909 13.2887454 20.3302283 11.8464148 25.695918 0 37 6.43007761 48.304082 0 53.6697717 11.8464148 66.5944709 13.2887454 63.9722571 26.0265695 73.5807778 34.7903871 63.9722571 43.5542047 66.5944709 56.2920288 53.6697717 57.7343594 48.304082 69.5807742"
        />
      </mask>
    </defs>
    <g stroke="none" fillRule="evenodd">
      <use
        id="Mask"
        fill="#CCCCCC"
        href="#path-1"
        {...{ ["xlink:href"]: "#path-1" }}
      />
      <g id="progress">
        <rect
          className="group-score-icon__progress"
          x={0}
          width="100%"
          y={`calc(${leveled && !startNextLevel ? 100 : 100 - progress}%)`}
          height={`${leveled && !startNextLevel ? 0 : progress}%`}
          mask="url(#mask)"
        />
        {leveled != null && startNextLevel != null ? (
          <Preact.Fragment>
            <rect
              className="group-score-icon__leveled"
              x={0}
              width="100%"
              y={leveled ? "calc(0)" : `calc(${100 - progress}%)`}
              height={leveled ? "100%" : `${progress}%`}
              mask="url(#mask)"
            />
            <rect
              className="group-score-icon__overlay"
              x={0}
              width="100%"
              y={`calc(${leveled ? 0 : 100 - progress}%)`}
              height={leveled ? "100%" : `${progress}%`}
              mask="url(#mask)"
            />
          </Preact.Fragment>
        ) : undefined}
      </g>
    </g>
  </svg>
);
