import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import {
  StateUpdater,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "preact/hooks";
import { AppUser } from "@thrive-web/ui-model";
import { ConnectionRequest, User } from "@thrive-web/ui-api";
import {
  DefaultErrorView,
  LoadingParagraph,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import {
  useApiCall,
  useApiFetch,
  useResettableRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { ConnectionRequestIncomingControls } from "../Requests";
import { USER_DETAIL_CONTEXTS } from "~/view/components";

export const UserProfileConnection: Preact.FunctionComponent<{
  user: User;
  self: AppUser;
  pending: boolean;
}> = ({ pending, user, self }) => {
  const [getConnectionReq, { pending: get_req_pending, error: get_req_error }] =
    useApiCall("getConnectionRequests");

  const [req, set_req] = useStateIfMounted<ConnectionRequest | undefined>(
    undefined
  );

  useEffect(() => {
    getConnectionReq({
      query: {
        filter: [
          ["=", ["this", "ConnectionRequest:accepted"], false],
          [
            "or",
            [
              "and",
              ["=", ["this", "ConnectionRequest:sender"], ["id", user.id]],
              ["=", ["this", "ConnectionRequest:recipient"], ["id", self.id]],
            ],
            [
              "and",
              ["=", ["this", "ConnectionRequest:sender"], ["id", self.id]],
              ["=", ["this", "ConnectionRequest:recipient"], ["id", user.id]],
            ],
          ],
        ],
      },
    }).then(res => set_req(res.data[0]));
  }, [user.id, self.id]);

  if (get_req_pending || pending) {
    return (
      <Preact.Fragment>
        <LoadingParagraph
          maxLines={2}
          className="loading-item__shaded--light"
        />
        <br />
        <div
          className="button loading-item__shaded--light"
          style={{ width: "10rem" }}
        />
      </Preact.Fragment>
    );
  }

  if (get_req_error) {
    return <DefaultErrorView error={get_req_error} />;
  }

  return (
    <UserProfileConnectionButton
      user={user}
      selfId={self.id}
      set_request={set_req}
      request={req}
    />
  );
};

export const UserProfileConnectionButton: Preact.FunctionComponent<{
  user: User;
  selfId: string;
  request?: ConnectionRequest;
  set_request: StateUpdater<ConnectionRequest | undefined>;
}> = ({ selfId, user, request, set_request }) => {
  const refetch_user = useContext(USER_DETAIL_CONTEXTS.fetch);
  const create_params = useMemo(
    () => ({
      body: {
        data: {
          attributes: {},
          relationships: { recipient: { data: { id: user.id } } },
        },
      },
    }),
    [user.id]
  );
  const createReq = useApiFetch("createConnectionRequest", create_params);
  const deleteReq = useApiFetch("declineConnectionRequest", request?.id);

  const action = !request ? createReq : deleteReq;
  const cur_action = useCallback(
    () =>
      action().then(({ data }) => {
        analytics.log_event(
          analytics.EVENTS[
            !request ? "connection_request_sent" : "connection_request_canceled"
          ]
        );
        set_request(data || undefined);
        return data;
      }),
    [action, set_request, !!request]
  );

  const [sendRequest, { pending, error }, resetRequest] =
    useResettableRequest(cur_action);

  const on_respond = useCallback(
    ({ data }: { data: ConnectionRequest | null }) => {
      setTimeout(() => set_request(data || undefined), 800);
      return refetch_user();
    },
    [set_request, refetch_user]
  );

  useEffect(() => {
    resetRequest();
  }, [action]);

  if (!request || request.sender?.id === selfId) {
    const desc = !request
      ? `You are not currently connected with ${user.first_name?.trim()}.`
      : `You have a pending connection request with ${user.first_name?.trim()}.`;
    const button_text = !request ? "Request to Connect" : "Cancel Request";
    return (
      <Preact.Fragment>
        <div className="user-profile__not-connected__text">{desc}</div>
        <RequestButtonWithIcon
          icon={!request ? "connection-request" : "remove-user"}
          side="left"
          className="filled blue"
          pending={pending}
          error={error}
          onClick={sendRequest}
          showError={true}
        >
          {button_text}
        </RequestButtonWithIcon>
      </Preact.Fragment>
    );
  }

  return (
    <Preact.Fragment>
      <div className="user-profile__not-connected__text">
        {user.first_name?.trim()} has requested to connect with you.
      </div>
      <div className="user-profile__not-connected__controls">
        <ConnectionRequestIncomingControls
          onComplete={on_respond}
          request={request}
        />
      </div>
    </Preact.Fragment>
  );
};
