import * as Preact from "preact";
import {
  ButtonWithIcon,
  Carousel,
  CheckList,
  DefaultModalContent,
  DropdownSelectInput,
  DropdownSelectInputDefaultTextInput,
  ErrorMessage,
  InputWithFormHelpers,
  RequestButtonWithIcon,
  Taxonomies,
  TAXONOMIES_CTX,
  TaxonomiesContextSpec,
} from "@thrive-web/ui-components";
import {
  comparator,
  make_displayable_error,
  remove_item_from,
  replace_item_in,
} from "@thrive-web/ui-common";
import {
  useAppUser,
  useDirtyForm,
  useForm,
  useRequest,
  useStateIfMounted,
  useStateObject,
} from "@thrive-web/ui-hooks";
import { escape_regex_str, maybeClassName } from "@thrive-web/ui-utils";
import { User, WriteUser } from "@thrive-web/ui-api";
import { useCallback, useContext, useMemo } from "preact/hooks";
import {
  DocWithData,
  ENTITIES,
  map_record_to_json_doc,
  MetaTypes,
  ResourceIdObj,
  TYPES,
} from "@thrive-web/core";
import { CustomIdMap, UserIdentityProp } from "./Identity";

const getTextFromOption = opt => opt.label;
const is_self_describe_id = (id: string = "") =>
  new RegExp(
    `${escape_regex_str(ENTITIES)}(Gender|Race|Ethnic)Identity\\/Other/`,
    "i"
  ).test(id);
const group_na_id = `${ENTITIES}/GroupIdentity/NA`;

export const useIdentityForm = (
  formData: WriteUser,
  onChange: <K extends keyof User>(prop: K, value: User[K]) => void
) => {
  const { value: groups_tx, map: groups_map } = useContext(
    TAXONOMIES_CTX.GroupIdentity
  );

  const [custom_identities, set_custom_identities] =
    useStateObject<CustomIdMap>({});
  const on_change_custom_identity = useCallback(
    (prop: UserIdentityProp) => e => {
      set_custom_identities({ [prop]: e.target.value });
    },
    [set_custom_identities]
  );

  const onChangeTrans = useCallback(
    value => {
      onChange("is_transgender", value.value);
    },
    [onChange]
  );

  const trans_options = useMemo(
    () => [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
      { value: null, label: "Prefer not to say" },
    ],
    []
  );
  const trans_value = useMemo(
    () => trans_options.find(opt => opt.value === formData.is_transgender),
    [formData]
  );

  const checkListProps = useMemo(() => {
    if (!groups_tx || !groups_map) {
      return undefined;
    }
    const group_identity = formData.has_group_identity || [];
    return {
      options: groups_tx.map(g => ({ value: g.id, label: g.title as string })),
      values: group_identity.map(g => g.id) || [],
      onSelectOption: (opt: InputOption<string>, checked: boolean) => {
        const val = groups_map[opt.value];
        if (!val) {
          return;
        }
        if (val.id === group_na_id && checked) {
          return onChange("has_group_identity", [{ id: val.id }]);
        }
        const new_array = checked
          ? replace_item_in(
              group_identity,
              i => i.id === group_na_id,
              { id: val.id },
              "end"
            ).sort(comparator(e => groups_map[e.id].display_rank || 0))
          : remove_item_from(group_identity, i => i.id === val.id);
        onChange(
          "has_group_identity",
          new_array.length > 0 ? new_array : undefined
        );
      },
    };
  }, [formData.has_group_identity, groups_tx, groups_map]);

  return [
    custom_identities,
    on_change_custom_identity,
    trans_options,
    trans_value,
    onChangeTrans,
    checkListProps,
  ] as const;
};

export const ProfileBuilderIdentityModal: Preact.FunctionComponent<
  ModalBodyProps & {
    finishStep: (
      data: DocWithData<MetaTypes["User"], false, true>,
      custom?: CustomIdMap
    ) => Promise<User>;
  }
> = ({ finishStep, dismiss, closeButton }) => {
  const user = useAppUser();
  const [page, set_page] = useStateIfMounted(0);

  const [formData, onChange] = useForm<WriteUser>({});
  const clearFormDirtyState = useDirtyForm(
    formData,
    "ProfileBuilderIdentity",
    true
  );

  const [
    custom_identities,
    on_change_custom_identity,
    trans_options,
    trans_value,
    onChangeTrans,
    checkListProps,
  ] = useIdentityForm(formData, onChange);

  const [submitForm, { pending, success, error }] = useRequest(finishStep);
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!user) {
        return;
      }

      const body = map_record_to_json_doc<MetaTypes["User"]>(
        // @ts-expect-error:
        formData,
        TYPES["User"]
      );
      if (!body.data.attributes) {
        body.data.attributes = {};
      }
      if (!body.data.relationships) {
        body.data.relationships = {};
      }
      Object.keys(body.data.relationships).forEach(k => {
        if (!formData[k]) {
          // @ts-expect-error:
          delete body.data.relationships[k];
        }
      });

      if ((formData.has_group_identity?.length || 0) > 0) {
        body.data.relationships.has_group_identity = {
          data: formData.has_group_identity!.map(i => ({ id: i.id })),
        };
      }

      // @ts-expect-error:
      body.data.attributes.has_completed_profile = true;
      // @ts-expect-error:
      body.data.attributes.has_completed_identities = true;

      submitForm(body, custom_identities).then(() => {
        clearFormDirtyState();
        setTimeout(() => {
          dismiss();
        }, 800);
      });
    },
    [
      submitForm,
      formData,
      custom_identities,
      dismiss,
      clearFormDirtyState,
      user,
    ]
  );

  const page1 = (
    <DefaultModalContent title="Self-Identification" closeButton={closeButton}>
      <div className="profile-builder__content">
        <div className="profile-builder__content__prompt">
          We recognize and value the rich gender diversity inherent in the
          world. The following list does not include all gender identities such
          as agender, genderqueer, two-spirit, and so many more. Please feel
          free to self-describe to enrich our data and community!
        </div>
        <div className="profile-builder__identity__form">
          <div className="form__input-prompt">I identify my gender as...</div>
          <ProfileBuilderIdentityInput
            context={TAXONOMIES_CTX.GenderIdentity}
            property="has_gender_identity"
            onChange={onChange}
            value={formData.has_gender_identity}
            placeholder="I identify my gender as..."
            onChangeCustom={on_change_custom_identity("has_gender_identity")}
            customValue={custom_identities.has_gender_identity}
          />
          <div className="form__input-prompt">
            Would you consider yourself transgender?
          </div>
          <div className="form__input-row">
            <DropdownSelectInput
              className="input__no-title"
              triggerClassName="i-thrive-disclosure"
              options={trans_options}
              onSelect={onChangeTrans}
              value={trans_value}
              getValueLabel={getTextFromOption}
              Button={DropdownSelectInputDefaultTextInput}
              label=""
            />
          </div>
          <div className="form__input-prompt">I identify my race as...</div>
          <div className="form__input-row">
            <ProfileBuilderIdentityInput
              context={TAXONOMIES_CTX.RaceIdentity}
              property="has_race_identity"
              onChange={onChange}
              value={formData.has_race_identity}
              placeholder="I identify my race as..."
              onChangeCustom={on_change_custom_identity("has_race_identity")}
              customValue={custom_identities.has_race_identity}
            />
          </div>
          <div className="form__input-prompt">
            I identify my ethnicity as...
          </div>
          <ProfileBuilderIdentityInput
            context={TAXONOMIES_CTX.EthnicIdentity}
            property="has_ethnic_identity"
            onChange={onChange}
            value={formData.has_ethnic_identity}
            placeholder="I identify my ethnicity as..."
            onChangeCustom={on_change_custom_identity("has_ethnic_identity")}
            customValue={custom_identities.has_ethnic_identity}
          />
        </div>
        <div className="profile-builder__footer modal__footer">
          <ButtonWithIcon
            icon="chevron-right"
            side="right"
            className="filled gray"
            onClick={() => set_page(1)}
          >
            Continue
          </ButtonWithIcon>
        </div>
      </div>
    </DefaultModalContent>
  );
  const page2 = (
    <DefaultModalContent title="Group Affiliation" closeButton={closeButton}>
      <div className="profile-builder__content profile-builder__content__groups">
        <div className="profile-builder__content__prompt">
          This question helps us form groups with diverse perspectives. (Select
          all that apply)
        </div>
        <div className="profile-builder__identity__form">
          <div className="form__input-prompt">
            Do you identify as belonging to any of the following groups?
          </div>
          {checkListProps && <CheckList {...checkListProps} />}
        </div>
        <div className="profile-builder__footer modal__footer">
          {error && (
            <ErrorMessage>{make_displayable_error(error).message}</ErrorMessage>
          )}
          <RequestButtonWithIcon
            className="button filled gray"
            icon="chevron-right"
            side="right"
            type="submit"
            pending={pending}
            success={success}
            successText="Success!"
          >
            Continue
          </RequestButtonWithIcon>
        </div>
      </div>
    </DefaultModalContent>
  );

  const pages = useMemo(
    () => ({
      "0": page1,
      "1": page2,
    }),
    [page1, page2]
  );

  return (
    <form onSubmit={onSubmit}>
      <Carousel
        className="carousel__fade"
        page={`${page}` as "0" | "1"}
        items={pages}
        trackHeight={true}
      />
    </form>
  );
};

type ProfileBuilderIdentityInputProps<
  P extends "has_gender_identity" | "has_race_identity" | "has_ethnic_identity",
  T extends keyof Taxonomies
> = Omit<HTMLInputProps, "value" | "onChange"> & {
  context: Preact.Context<TaxonomiesContextSpec<T>>;
  property: P;
  onChange: (key: P, value?: ResourceIdObj) => void;
  value?: User[P];
  onChangeCustom: EventListener;
  customValue?: string;
  hideTitle?: boolean;
};

export const ProfileBuilderIdentityInput = <
  P extends "has_gender_identity" | "has_race_identity" | "has_ethnic_identity",
  T extends keyof Taxonomies
>({
  onChange,
  value,
  customValue,
  onChangeCustom,
  property,
  context,
  hideTitle = true,
  ...props
}: Preact.RenderableProps<ProfileBuilderIdentityInputProps<P, T>>) => {
  const { map: tx_map, value: tx } = useContext(context);
  const { map: custom_map } = useContext(TAXONOMIES_CTX.CustomIdentity);
  const options = useMemo(() => {
    if (!tx) {
      return null;
    }
    return tx.map(
      i =>
        ({ value: i.id, label: i["title"] || i["name"] } as InputOption<string>)
    );
  }, [tx]);

  const onChangeOption = useCallback(
    (val?: InputOption<string>) => {
      onChange(property, val ? { id: val.value } : undefined);
    },
    [onChange, property]
  );
  const is_custom =
    !!value &&
    ((!!tx_map && !(value.id in tx_map)) ||
      (!!custom_map && !!custom_map[value.id]));

  const value_option = useMemo(() => {
    if (is_custom) {
      if (!custom_map) {
        return;
      }
      const val = custom_map[value!.id];
      if (!val) {
        return {
          value: value!.id,
          label: value!.id,
        };
      }
      return {
        value: val.id,
        label: val.title,
      };
    } else if (value?.id && tx_map) {
      const val = tx_map[value?.id];
      if (!val) {
        return {
          value: value.id,
          label: value.id,
        };
      }
      return {
        value: val.id,
        label: val["title"] || val["name"],
      };
    }
  }, [tx_map, custom_map, value]);
  props.className = `${hideTitle ? "input__no-title" : ""}${maybeClassName(
    props.className
  )}`;
  if (!hideTitle) {
    props.label = props.label || props.placeholder;
  }
  // @ts-expect-error:
  props.submitOnEnter = false;
  if (value && !value_option) {
    props.className = `loading-item__shaded loading-item__shaded--light${maybeClassName(
      props.className
    )}`;
  }

  return (
    <Preact.Fragment>
      <div className="form__input-row">
        <DropdownSelectInput
          value={value_option}
          options={options}
          onSelect={onChangeOption}
          getValueLabel={getTextFromOption}
          Button={DropdownSelectInputDefaultTextInput}
          buttonProps={props}
          label={props.placeholder || ""}
          triggerClassName="i-thrive-disclosure"
          disabled={!tx_map || (is_custom && !custom_map)}
        />
      </div>

      {is_self_describe_id(value?.id) && (
        <div className="form__input-row">
          <InputWithFormHelpers
            onChange={onChangeCustom}
            value={customValue}
            placeholder="Self-Describe here..."
            className="input__no-title"
            submitOnEnter={false}
          />
        </div>
      )}
    </Preact.Fragment>
  );
};
