import { createNamedContext } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { Post, Reaction } from "@thrive-web/ui-api";
import {
  DynamicListCtxSpec,
  EmptyList,
  IS_ADMIN_UI,
  PostDeleteModal,
  PostDetailModal,
  PostEditModal,
  PostList,
} from "@thrive-web/ui-components";
import { preserveProps, useStateIfMounted } from "@thrive-web/ui-hooks";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { add_item_to, remove_item_from } from "@thrive-web/ui-common";

export const ACTIVITY_FEED_POST_ID = createNamedContext<string | undefined>(
  undefined,
  "ActivityFeedPostId"
);

export const ACTIVITY_FEED_CAN_INTERACT = createNamedContext<
  undefined | ((post: Post) => boolean)
>(undefined, "ActivityFeedCanInteract");

// includes the list of posts, the detail/edit/delete modals, and the ctx provider
// for canInteract
export const ActivityFeed: Preact.FunctionComponent<{
  posts: readonly Post[];
  // path to go to when opening a post detail modal
  detailPath: string;
  // path to return to when closing the detail modal
  detailExitPath: string;
  dynamicListCtx: Preact.Context<DynamicListCtxSpec<Post, boolean, any[]>>;
  // indicate the community/group in the post card
  showScope?: boolean;
  emptyView?: Preact.VNode;
  loadMoreElem?: Preact.VNode | null;
  // returns true if the user can interact with the given post
  canInteract: (post: Post) => boolean;
}> = ({
  posts,
  showScope,
  detailPath,
  detailExitPath,
  dynamicListCtx,
  emptyView = <EmptyList>No activity</EmptyList>,
  loadMoreElem,
  canInteract,
}) => {
  const is_admin_ui = useContext(IS_ADMIN_UI);

  // target post for the detail modal
  const post_id = useContext(ACTIVITY_FEED_POST_ID);
  const id = preserveProps(post_id) || "";
  const post_detail = useMemo(
    () => (id ? posts.find(p => p.id === id) : undefined),
    [id, posts]
  );

  const [edit_target, set_edit_target] = useStateIfMounted<Post | null>(null);
  const updatePosts = useContext(dynamicListCtx).dispatch;

  const afterEdit = useCallback(
    result => {
      if (!edit_target) {
        return;
      }
      // update the dyn list ctx after editing a post
      updatePosts.update(g => g.id === edit_target.id, result);
    },
    [edit_target]
  );
  const onCloseEdit = useCallback(
    // clear the edit target after the modal animation finishes
    () => setTimeout(() => set_edit_target(null), MODAL_ANIMATION_DELAY),
    [set_edit_target]
  );

  const [delete_target, set_delete_target] = useStateIfMounted<Post | null>(
    null
  );
  const afterDelete = useCallback(() => {
    // update the dyn list ctx after deleting a post
    updatePosts.remove(g => g.id === delete_target?.id);
  }, [delete_target]);
  const onCloseDelete = useCallback(
    // clear the delete target after the modal animation finishes
    () => setTimeout(() => set_delete_target(null), MODAL_ANIMATION_DELAY),
    [set_delete_target]
  );

  const onReact = useCallback(
    // update the dyn list ctx after reacting to a post
    (post: Post, reaction: Reaction, deleted: boolean) => {
      const { has_reaction = [] } = post;
      const updated = {
        ...post,
        has_reaction: deleted
          ? remove_item_from(has_reaction, i => i.id === reaction.id)
          : add_item_to(has_reaction, reaction),
      };
      updatePosts.update(p => p.id === post.id, updated);
    },
    [updatePosts]
  );

  const { Provider } = ACTIVITY_FEED_CAN_INTERACT;

  return posts.length > 0 ? (
    <Provider value={canInteract}>
      <PostList
        data={posts}
        editPost={set_edit_target}
        deletePost={set_delete_target}
        showScope={showScope}
        onReact={onReact}
        loadMoreElem={loadMoreElem}
      />
      {(canInteract || is_admin_ui) && (
        <Preact.Fragment>
          <PostDetailModal
            path={detailPath}
            targetId={id}
            target={post_detail}
            exitPath={detailExitPath}
            dynamicListCtx={dynamicListCtx}
            showScope={showScope}
          />
          {canInteract && (
            <PostEditModal
              target={edit_target}
              onFinish={afterEdit}
              onClose={onCloseEdit}
            />
          )}
          <PostDeleteModal
            target={delete_target}
            onFinish={afterDelete}
            onClose={onCloseDelete}
          />
        </Preact.Fragment>
      )}
    </Provider>
  ) : (
    emptyView
  );
};
