import * as Preact from "preact";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { MetaTypes, RelationshipKeysOf } from "@thrive-web/core";
import { Group } from "@thrive-web/ui-api";
import {
  GroupDetailOuter,
  GroupActivityPage,
  GroupMembersPage,
  GroupTodosPage,
} from "~/view/components";
import {
  passPropsToLocalContext,
  DefaultErrorView,
  DefaultPendingView,
  GROUP_DETAIL_CONTEXTS,
  GROUP_DETAIL_LIST_CONTEXTS,
  GroupGoalsPage,
  GroupDetailAbout,
} from "@thrive-web/ui-components";
import { useApiRequest, useRecordForDetailPage } from "@thrive-web/ui-hooks";

import { ensure_id_is_iri } from "@thrive-web/ui-common";

export const GroupDetail: Preact.FunctionComponent<{
  group: Group;
  post_id?: string;
}> = ({ group, post_id }) => {
  passPropsToLocalContext("group", group, GROUP_DETAIL_CONTEXTS.dispatch);
  const updateGroup = useContext(GROUP_DETAIL_CONTEXTS.dispatch);
  const { list: members, dispatch: updateMembers } = useContext(
    GROUP_DETAIL_LIST_CONTEXTS.members
  );
  useEffect(() => {
    if (members !== group.has_member) {
      updateMembers.reset(group.has_member || []);
    }
  }, [group.has_member]);
  useEffect(() => {
    updateGroup("group", {
      ...group,
      // @ts-expect-error:
      has_member: members,
    });
  }, [members]);
  return (
    <Preact.Fragment>
      <GroupActivityPage path="/groups/:id/activity/:post?" post_id={post_id} />
      <GroupMembersPage path="/groups/:id/members" />
      {group.has_goals && (
        <GroupGoalsPage path="/groups/:id/goals/:goal?/:action?" />
      )}
      {group.has_goals && (
        <GroupTodosPage path="/groups/:id/todos/:todo?/:action?" />
      )}
      <GroupDetailAbout path="/groups/:id/about" />
    </Preact.Fragment>
  );
};

export const GroupDetailPage: Preact.FunctionComponent<RoutePageProps> = ({
  matches = {},
}) => {
  // get group id from url
  const id = useMemo(
    () => ensure_id_is_iri(matches.id || "", "Group"),
    [matches.id]
  );
  // get post detail id from url
  const post_id = useMemo(
    () =>
      matches.item ? ensure_id_is_iri(matches.item || "", "Post") : undefined,
    [matches.item]
  );
  const params = useMemo(
    () => ({
      query: {
        include: [
          "has_member.User:profile_picture",
          "has_admin",
        ] as RelationshipKeysOf<MetaTypes["Group"]>[],
      },
    }),
    []
  );

  const [get_group, { error, pending }] = useApiRequest("getGroup", id, params);
  const [group, fetch_group] = useRecordForDetailPage(id, get_group);

  return error ? (
    <DefaultErrorView error={error} />
  ) : (
    <GroupDetailOuter group={group} fetch={fetch_group}>
      {pending || !group ? (
        <DefaultPendingView />
      ) : (
        <GroupDetail group={group} post_id={post_id} />
      )}
    </GroupDetailOuter>
  );
};
