import { maybeClassName } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { ErrorMessage, RequestButtonWithIcon } from "@thrive-web/ui-components";
import { useCallback } from "preact/hooks";
import { useRequest } from "@thrive-web/ui-hooks";
import { make_displayable_error } from "@thrive-web/ui-common";

// Modal with preset layout/controls for deleting things
export const DeleteModalBody: Preact.FunctionComponent<
  ModalBodyProps & {
    deleteRecord: () => Promise<{ meta?: any } | void>;
    afterDelete?: () => void;
    cancel?: () => void;
    hideWarning?: boolean;
    buttonIcon?: FontIconName;
    buttonClassName?: string;
  }
> = ({
  dismiss,
  deleteRecord,
  afterDelete,
  children,
  cancel = dismiss,
  // hide the warning saying "you can't undo this"
  hideWarning,
  buttonIcon = "trash",
  buttonClassName = "negative",
}) => {
  const [sendRequest, { pending, success, error }] = useRequest(deleteRecord);
  const onConfirm = useCallback(() => {
    sendRequest().then(() => {
      setTimeout(() => {
        dismiss();
        afterDelete &&
          setTimeout(() => {
            afterDelete();
          }, 100);
      }, 1000);
    });
  }, [sendRequest, dismiss, afterDelete]);
  return (
    <div className="modal__delete-record">
      <p>{children}</p>
      {!hideWarning && (
        <p>
          <strong>You will not be able to undo this action!</strong>
        </p>
      )}
      <div className="modal__footer">
        {error && (
          <ErrorMessage>{make_displayable_error(error).message}</ErrorMessage>
        )}
        <button className="filled gray" onClick={cancel}>
          Cancel
        </button>
        <RequestButtonWithIcon
          className={`filled${maybeClassName(buttonClassName)}`}
          pending={pending}
          success={success}
          successText="Success!"
          icon={buttonIcon}
          side="left"
          onClick={onConfirm}
        >
          Confirm
        </RequestButtonWithIcon>
      </div>
    </div>
  );
};
