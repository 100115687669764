import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { ApiMethodParameters, FilterSpec, QueryParams } from "@thrive-web/core";
import { Community, Group, MappedApiResponse, User } from "@thrive-web/ui-api";
import { entity_has_type } from "@thrive-web/ui-common";
import { THREAD_COMM_ID } from "@thrive-web/ui-constants";
import {
  useApiFetch,
  useApiFetchPaged,
  useApiMethod,
  useAppUser,
  useDynamicListVariable,
  useResettableRequest,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { filter_options, group_goals_query_params } from "@thrive-web/ui-utils";
import {
  THRIVE_GROUPS,
  UserSearchFn,
  GROUP_DETAIL_LIST_CONTEXTS,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";

export const DEFAULT_GROUP_FIELDS: readonly Exclude<
  keyof Group,
  "id" | "type"
>[] = [
  "name",
  "has_member",
  "has_admin",
  "background_color",
  "background_image",
  "is_private",
  "has_relationship_management",
  "has_goals",
] as const;

export const isThriveGroup = (group?: Group): group is Group => {
  const { list } = useContext(THRIVE_GROUPS);
  return (
    !!group &&
    entity_has_type(group, "Group") &&
    (group.in_community?.id === THREAD_COMM_ID ||
      !!list?.some(g => g.id === group.id))
  );
};

export const useGroupUpdatesAllowed = (group?: Group) => {
  return isThriveGroup(group) && !!group.has_goals;
};

export const useFetchGroups = (
  self: User | null,
  filter: FilterSpec = [],
  sort?: QueryParams<Group>["sort"],
  non_member?: boolean
) => {
  const group_params = useMemo<
    ApiMethodParameters<"GET", "Group", false>
  >(() => {
    const is_member = [
      "=",
      ["this", "Group:has_member"],
      ["id", self!.id as string],
    ] as const;
    return {
      query: {
        filter: [
          non_member ? (["not", is_member] as const) : is_member,
          ...filter,
        ],
        include: ["has_admin", "background_image"],
        // todo: temp sorting
        sort: [...(sort || []), { by: "last_activity_at", dir: "desc" }],
      },
    } as ApiMethodParameters<"GET", "Group", false>;
  }, [self?.id]);
  return useApiFetchPaged("getGroups", group_params);
};

export const useGroupMemberSearch = (
  group?: Group | Community,
  updateScope?: (g: Group | Community) => void
): UserSearchFn => {
  const scope_ref = useValueRef(group);
  const search_ref = useRef<string | undefined>(undefined);
  const members_from_group_record = useMemo(
    () =>
      group &&
      group.has_member &&
      // @ts-expect-error:
      group.has_member.every(u => !!u.email && entity_has_type(u, "User"))
        ? (group.has_member as User[])
        : undefined,
    [group?.id, group?.has_member]
  );
  const user_list = useRef<User[] | undefined>(members_from_group_record);
  const filtered_list = useRef<User[]>();

  let method =
    !!group && !!entity_has_type(group, "Community")
      ? "getCommunity"
      : "getGroup";
  const include_members = useMemo(
    () => ({
      query: {
        include: ["has_member.User:profile_picture"],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
      },
    }),
    []
  );
  // @ts-expect-error:
  const getGroup = useApiFetch(method, group?.id, include_members);

  useLayoutEffect(() => {
    user_list.current = members_from_group_record;
  }, [group?.id, members_from_group_record]);

  return useCallback<UserSearchFn>(
    (search: string | undefined, offset, limit = 25) =>
      new Promise((resolve, reject) => {
        if (!group) {
          resolve([]);
        } else if (!user_list.current) {
          getGroup()
            .then(
              (
                res:
                  | MappedApiResponse<"getCommunity">
                  | MappedApiResponse<"getGroup">
              ) => {
                user_list.current = res.data.has_member as User[];
                updateScope?.({
                  ...(scope_ref.current as Group | Community),
                  has_member: user_list.current,
                });
                resolve(res.data.has_member as User[]);
              }
            )
            .catch(err => reject(err));
        } else {
          resolve(user_list.current);
        }
      }).then((all_users: User[]) => {
        let data = all_users;
        if (search) {
          if (search === search_ref.current && filtered_list.current) {
            data = filtered_list.current;
          } else {
            data = filter_options(search, all_users, (u: User, exp: RegExp) =>
              exp.test(u.full_name as string)
            );
          }
        }
        search_ref.current = search;
        filtered_list.current = data;
        if (offset != null && limit) {
          data = data.slice(offset, offset + limit);
        }
        return {
          data,
          meta: {
            total_result_count: filtered_list.current.length,
          },
        };
      }),
    [group?.id, group?.has_member, getGroup, user_list, updateScope]
  );
};

export const useGroupGoalsList = (group: Group, search: string = "") => {
  const { list: ctx_list, dispatch: ctx_dispatch } = useContext(
    GROUP_DETAIL_LIST_CONTEXTS.goals
  );
  const [list, dispatch] = useDynamicListVariable(ctx_list);

  const goals_params = useMemo<ApiMethodParameters<"GET", "Goal", false>>(
    () => group_goals_query_params(group, search),
    [group?.id, search]
  );
  const get_goals_req = useApiFetchPaged("getGoals", goals_params);

  const [get_goals, status, reset] = useResettableRequest(get_goals_req);
  useEffect(() => {
    return reset;
  }, [search]);

  useEffect(() => {
    if (group?.id) {
      get_goals(0).then(res => {
        dispatch.reset(res.data);
        if (!search && ctx_dispatch) {
          ctx_dispatch.reset(res.data);
        }
      });
    }
  }, [group?.id]);

  return [list, dispatch, get_goals, status] as const;
};

export const useGroupJoin = (
  group: Group | null,
  user?: User | null,
  invitation_id?: string
) => {
  const self = useAppUser();
  const group_join = useApiMethod("groupJoin");
  return useCallback(
    (
      _group: Group | null = group,
      _user: User | null | undefined = user,
      _invitation_id: string | undefined = invitation_id
    ) => {
      if (!self) {
        return Promise.reject("Not logged in");
      }
      if (!_group) {
        return Promise.reject("No group specified for groupJoin");
      }
      return group_join({
        body: {
          data: {
            attributes: {},
            relationships: {
              group: {
                data: {
                  id: _group.id,
                },
              },
              user: {
                data: {
                  id: (_user || self).id,
                },
              },
              invitation: _invitation_id
                ? {
                    data: {
                      id: _invitation_id,
                    },
                  }
                : undefined,
            },
          },
        },
      });
    },
    [group?.id, user?.id, self?.id]
  );
};

export const useGroupLeave = (group: Group | null) => {
  const self = useAppUser();
  const group_leave = useApiMethod("groupLeave");
  return useCallback(
    (_group: Group | null = group) => {
      if (!_group) {
        return Promise.reject("No group specified for groupJoin");
      }
      return group_leave({
        body: {
          data: {
            attributes: {},
            relationships: {
              group: {
                data: {
                  id: _group.id,
                },
              },
            },
          },
        },
      });
    },
    [group?.id, self?.id]
  );
};
