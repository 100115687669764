import * as Preact from "preact";
import { Group, WriteGroup } from "@thrive-web/ui-api";
import {
  useApiMethod,
  useAppUser,
  useCallbackRef,
  useDirtyForm,
  useForm,
  useRequestWithMedia,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { useCallback, useMemo } from "preact/hooks";
import {
  DefaultModalContent,
  ErrorMessage,
  RequestButton,
  GroupForm,
} from "@thrive-web/ui-components";
import { map_record_to_json_resource, TYPES } from "@thrive-web/core";

export const GroupEdit: Preact.FunctionComponent<
  ModalBodyProps & { group: Group; onFinish: (new_group: Group) => void }
> = ({ group, onFinish, closeButton, dismiss }) => {
  const {
    name,
    purpose,
    background_color,
    background_image_url,
    is_private,
    can_members_invite,
    has_goals,
    has_relationship_management,
  } = group;
  const initialPhoto = useMemo<FileUploadData | undefined>(
    () =>
      background_image_url
        ? { data: background_image_url, name: "", mime: "" }
        : undefined,
    []
  );
  const [formData, setField, onInputChange] = useForm<WriteGroup>({
    name,
    purpose,
    background_color,
    is_private,
    can_members_invite,
    has_goals,
    has_relationship_management,
  });

  const [file, onChangeFile, file_ref] = useStateRef<
    FileUploadData | undefined
  >(undefined);
  if (initialPhoto && !file_ref.current && formData.background_image !== null) {
    file_ref.current = initialPhoto;
  }
  const onChangeFile_ = useCallback((data: FileUploadData | undefined) => {
    onChangeFile(data);
    // @ts-expect-error:
    setField("background_image", initialPhoto && !data ? null : undefined);
  }, []);

  const allFormData = useMemo(
    () => ({
      ...formData,
      background_image: file_ref.current,
    }),
    [formData, file]
  );
  const clearFormDirtyState = useDirtyForm(allFormData, "GroupEdit", true);

  const user = useAppUser();
  const onSuccess = useCallbackRef(
    (new_group: Group) => {
      clearFormDirtyState();
      dismiss();
      onFinish(new_group);
    },
    [user, onFinish, dismiss]
  );

  const updateGroup = useApiMethod("updateGroup");
  const [submitForm, { pending, success, error }, media_pending, progress] =
    useRequestWithMedia(
      "Group",
      "background_image",
      updateGroup,
      file,
      false,
      true
    );

  const required_fields_are_valid =
    !!formData.name && !!formData.purpose && !!formData.background_color; /* &&
    !!file_ref.current;*/

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      // cancel if already submitted or missing body text
      if (pending || !required_fields_are_valid) {
        return;
      }

      const {
        id: _,
        type,
        ...data
      } = map_record_to_json_resource(
        {
          ...formData,
          id: "",
        },
        TYPES.Group
      );

      // remove uneditable props from request data
      data.relationships?.in_community &&
        delete data.relationships.in_community;
      data.relationships?.has_admin && delete data.relationships.has_admin;
      data.relationships?.has_member && delete data.relationships.has_member;
      data.relationships?.created_by && delete data.relationships.created_by;

      "is_private" in data.attributes &&
        // @ts-expect-error:
        delete data.attributes.is_private;

      if (initialPhoto && formData.background_image === null) {
        data.relationships = {
          ...(data.relationships || {}),
          background_image: { data: null },
        };
      }

      submitForm(group.id, {
        body: { data },
        query: {
          include: ["created_by", "background_image"],
          fields: {
            User: [
              "first_name",
              "last_name",
              "profile_picture",
              "email",
              "full_name",
            ],
          },
        },
      }).then(({ data: new_group }) => {
        setTimeout(() => {
          onSuccess.current && onSuccess.current(new_group);
        }, 1000);
      });
    },
    [formData, submitForm, onSuccess, required_fields_are_valid]
  );

  return (
    <DefaultModalContent
      title="Group Settings"
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div className="modal__footer__cancel">
            <button className="filled gray" onClick={dismiss}>
              Cancel
            </button>
          </div>
          <div className="post__create__submit">
            <div className="modal__footer__error">
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </div>
            {media_pending && (
              <div className="post__create__status">
                Uploading attachment...
              </div>
            )}
            <RequestButton
              className="filled gray"
              pending={pending}
              success={success && !error}
              successText="Success!"
              disabled={!required_fields_are_valid}
              onClick={onSubmit}
              progress={progress}
            >
              Save Changes
            </RequestButton>
          </div>
        </div>
      }
    >
      <GroupForm
        onInputChange={onInputChange}
        onFieldChange={setField}
        onChangeFile={onChangeFile_}
        onSubmit={onSubmit}
        record={formData}
        photoFile={file_ref.current}
        error={error}
        isEdit={true}
      />
    </DefaultModalContent>
  );
};
