import * as Preact from "preact";
import Match from "preact-router/match";
import { maybeClassName, extract_route_matches } from "@thrive-web/ui-utils";

// A link that is visible across page navigations, and thus needs an active visual state
// and needs to lose focus upon navigation
export const AppLink: Preact.FunctionComponent<
  HTMLAnchorProps & {
    blurOnClick?: boolean;
    activeClassName?: string;
    activePathMatch?: string;
  }
> = ({
  blurOnClick,
  onClick,
  activeClassName,
  className,
  activePathMatch,
  tabIndex,
  ...props
}) => (
  <Match
    path={activePathMatch || props.href}
    children={({ matches, path }) => {
      const does_match = extract_route_matches(
        activePathMatch || props.href || "",
        path,
        props.href
      );
      return (
        <a
          tabIndex={matches ? -1 : tabIndex}
          {...props}
          className={`${className || ""}${maybeClassName(
            matches ? activeClassName : ""
          )}${maybeClassName(does_match && does_match.exact ? "exact" : "")}`}
          onClick={e => {
            // if not a "native" link, preventDefault
            if (
              !e.currentTarget?.getAttribute("native") &&
              // @ts-expect-error:
              !e.target?.parentElement?.getAttribute("native")
            ) {
              e.preventDefault();
            }
            // @ts-expect-error:
            e.isLink = true;
            // @ts-expect-error:
            onClick && onClick(e);
            if (blurOnClick) {
              e.currentTarget.blur();
            }
          }}
        />
      );
    }}
  />
);
