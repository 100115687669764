import { ApiMethodParameters, ResourceIdObj } from "@thrive-web/core";
import type { FilterClause } from "@swymbase/sparql-rest";
import { Group, Todo, User } from "@thrive-web/ui-api";
import { DEFAULT_USER_FIELDS } from "@thrive-web/ui-components";

const DAY = 1000 * 60 * 60 * 24;

export const todo_is_due_soon = (todo: Todo) => {
  return (
    Date.now() >=
    new Date(todo.due_date ? todo.due_date : 0).getTime() - DAY * 3
  );
};

type TodoQueryFilters = {
  assignee?: User;
  completed?: boolean;
  overdue?: boolean;
};

export const todo_query = (
  group: Group | null,
  filters?: TodoQueryFilters
): ApiMethodParameters<"GET", "Todo", false>["query"] => {
  const filter: FilterClause[] = [
    ["=", ["this", "Todo:group"], ["id", group!.id as string]],
  ];
  if (filters?.assignee) {
    filter.push([
      "=",
      ["this", "Todo:assigned_to"],
      ["id", filters.assignee.id],
    ]);
  }
  if (filters?.completed != null) {
    filter.push(["=", ["this", "Todo:completed"], filters.completed]);
  }
  if (filters?.overdue) {
    filter.push(["<=", ["this", "Todo:due_date"], new Date().toISOString()]);
  }

  return {
    filter,
    sort: [
      { by: "completed", dir: "asc" },
      { by: "due_date", dir: "asc" },
    ],
    include: ["assigned_to.User:profile_picture", "goal"],
    fields: {
      User: DEFAULT_USER_FIELDS,
    },
  };
};

export const insert_todo_sorted = (
  todo: Todo,
  list: Todo[] | null,
  set_list?: (list: Todo[] | null) => void
) => {
  if (!list || list.length === 0) {
    const new_list = todo ? [todo] : null;
    set_list?.(new_list);
    return new_list;
  }
  const due_date = todo.due_date ? new Date(todo.due_date).getTime() : Infinity;
  let i = list.length - 1;
  for (i; i >= 0; i--) {
    if (!!list[i].completed !== !!todo.completed) {
      continue;
    }

    if (list[i].id === todo.id) {
      return list;
    }

    const other_due = list[i].due_date
      ? new Date(list[i].due_date as string).getTime()
      : Infinity;
    if (other_due < due_date) {
      break;
    }
  }
  if (i === list.length - 1) {
    return list.concat(todo);
  }
  if (i === -1) {
    const new_list = [todo].concat(list);
    set_list?.(new_list);
    return new_list;
  }

  const new_list = list
    .slice(0, i + 1)
    .concat(todo)
    .concat(list.slice(i + 1));
  set_list?.(new_list);
  return new_list;
};

export const merge_next_page = <T extends ResourceIdObj>(
  list: T[],
  new_page: T[]
) => {
  return list.filter(i => !new_page.find(n => n.id === i.id)).concat(new_page);
};
