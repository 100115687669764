import { ApiMethodParameters } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { ConnectionMapped, User } from "@thrive-web/ui-api";
import { RequestButtonWithIcon } from "@thrive-web/ui-components";
import { useApiRequest } from "@thrive-web/ui-hooks";

export const SetCoachButton: Preact.FunctionComponent<{
  connection: ConnectionMapped;
  // the user that should be the Coach
  coach?: User;
  onUpdate: (c: ConnectionMapped) => void;
}> = ({ connection, coach, onUpdate }) => {
  const params = useMemo<ApiMethodParameters<"PATCH", "Connection">>(
    () => ({
      body: {
        data: {
          relationships: {
            has_coach: {
              data: coach ? { id: coach.id } : null,
            },
          },
        },
      },
      query: {
        include: ["users.User:profile_picture"],
      },
    }),
    [connection, coach]
  );
  const [set_coach, status] = useApiRequest(
    "updateConnection",
    connection.id,
    params
  );

  const op = useMemo<
    "make_coach" | "make_coachee" | "remove_coaching_relationship" | undefined
  >(() => {
    if (!coach) {
      return !!connection.has_coach
        ? "remove_coaching_relationship"
        : undefined;
    }
    if (coach.id === connection.other_user.id) {
      return "make_coach";
    }
    return "make_coachee";
  }, [connection.has_coach, coach]);

  const on_click = useCallback(
    e => {
      e.keepMenuOpen = true;
      set_coach().then(({ data }) => {
        if (op) {
          analytics.log_event(analytics.EVENTS[op]);
        }
        setTimeout(() => {
          const other_user = data.users?.find(
            u => u.id === connection.other_user.id
          );
          if (!other_user) {
            return;
          }
          e.closeMenu?.();
          onUpdate({ ...data, other_user });
        }, 1250);
      });
    },
    [set_coach, onUpdate, connection.other_user.id, op]
  );

  return (
    <RequestButtonWithIcon
      className="filled pill gray full-width"
      icon={coach ? "helping-hands" : "subtract-solid-circle"}
      side="left"
      onClick={on_click}
      successText="Success!"
      {...status}
      showError={true}
    >
      {coach
        ? coach.id === connection.other_user.id
          ? "Make Coach"
          : "Make Coachee"
        : "Remove Coaching Relationship"}
    </RequestButtonWithIcon>
  );
};
