import * as Preact from "preact";
import {
  useCallbackRef,
  useId,
  useStateRef,
  useWindowEventListener,
} from "@thrive-web/ui-hooks";
import {
  closestAncestor,
  maybeClassName,
  node_is_link,
} from "@thrive-web/ui-utils";
import { SvgIcon } from "../icons/base";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { Icon, Popover } from "@thrive-web/ui-components";
import { forwardRef } from "preact/compat";

const defaultDropdownIcon = (
  <SvgIcon
    className="with-icon__icon"
    width="24px"
    height="24px"
    viewBox="0 -16 32 32"
  >
    <circle r="4" cx="4" cy="0" />
    <circle r="4" cx="16" cy="0" />
    <circle r="4" cx="28" cy="0" />
  </SvgIcon>
);

type DefaultDropdownButtonProps = MaybeClass & { icon?: FontIconName };

export const DefaultDropdownButton: Preact.FunctionComponent<DefaultDropdownButtonProps> =
  ({ className, icon }) => (
    <button
      className={`dropdown-menu__button with-icon icon-only${
        maybeClassName(className) || " filled transparent"
      }`}
    >
      {icon ? <Icon name={icon} /> : defaultDropdownIcon}
    </button>
  );

export const DefaultDropdownButtonDiv: Preact.FunctionComponent<DefaultDropdownButtonProps> =
  forwardRef<HTMLDivElement>(
    ({ className, icon }: HTMLDivProps & DefaultDropdownButtonProps, ref) => (
      <div
        ref={ref}
        className={`dropdown-menu__button with-icon icon-only${
          maybeClassName(className) || " filled transparent"
        }`}
      >
        {icon ? <Icon name={icon} /> : defaultDropdownIcon}
      </div>
    )
  );

export const DropdownMenu: Preact.FunctionComponent<DropdownMenuProps> = ({
  id: _id,
  className,
  button,
  buttonClassName,
  listClassName,
  items,
  onOpen,
  onClose,
  allowLinkEventPropagation,
  closeOnClickItem = true,
  popoverProps,
  children,
}) => {
  const list = useRef<HTMLUListElement>(null);
  const id = useId(_id, "dropdown-menu");
  const [open, setOpen, open_ref] = useStateRef(false);

  const closeMenu = useCallbackRef(
    e => {
      if (e.initialToggleFor === id) {
        return;
      }
      if (id && !closestAncestor(e.target, `#${id}`)) {
        e.didCloseMenu = id;
        onClose?.(e);
        setOpen(false);
      }
    },
    [setOpen, id]
  );

  const toggle = useCallback(
    e => {
      e.isLink = e.isLink || node_is_link(e.currentTarget);
      e.isLink && !allowLinkEventPropagation && e.stopPropagation();

      if (e.didCloseMenu === id) {
        return;
      }

      if (!open_ref.current) {
        // if the menu isn't already open, call callback
        e.initialToggleFor = id;
        onOpen && onOpen(e);
      } else {
        if (e.keepMenuOpen) {
          return;
        }
        onClose?.(e);
      }
      // toggle open state
      setOpen(!open_ref.current);
    },
    [allowLinkEventPropagation, onOpen, closeMenu, open_ref]
  );

  // add method to event so child elements can close the menu when clicked, if needed
  const modify_event = useCallback(
    e => {
      e.closeMenu = () => setOpen(false);
    },
    [setOpen]
  );

  const enable_click_listener = useWindowEventListener("click", closeMenu);
  const enable_focus_listener = useWindowEventListener("focus", closeMenu, {
    capture: true,
  });

  useEffect(() => {
    enable_click_listener(open);
    enable_focus_listener(open);
  }, [open]);

  const height =
    list.current && open
      ? `${list.current.getBoundingClientRect().height}px`
      : "0";

  const getContentRef = useCallback(() => list.current, [list.current]);

  const trigger = useMemo(
    () => (
      <div
        className={`dropdown-menu__trigger`}
        onClick={children ? undefined : toggle}
      >
        {children}
        {button ? (
          <button
            id={`${id}-button`}
            onClick={children ? toggle : undefined}
            type="button"
            className={`dropdown-menu__button--custom${maybeClassName(
              buttonClassName
            )}`}
          >
            {button}
          </button>
        ) : (
          <DefaultDropdownButton className={buttonClassName} />
        )}
      </div>
    ),
    [children, toggle, button, buttonClassName, id]
  );

  return (
    <Popover
      id={id}
      animation="fade"
      defaultDirection="bottom"
      defaultOffset="right"
      defaultBody={false}
      getContentRef={getContentRef}
      {...popoverProps}
      className={`dropdown-menu${maybeClassName(className)}`}
      triggerComponent={trigger}
      hideNub={true}
      show={open}
      disableTabIndexOnClose={true}
    >
      <div
        className={`dropdown-menu__links${maybeClassName(listClassName)}`}
        style={{ height }}
      >
        <ul ref={list} className="dropdown-menu__links__list">
          {items.map((item, key) =>
            item ? (
              <li
                onClick={closeOnClickItem && open ? toggle : undefined}
                onClickCapture={modify_event}
                key={key}
              >
                {item}
              </li>
            ) : null
          )}
        </ul>
      </div>
    </Popover>
  );
};
