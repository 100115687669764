import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { analytics, make_displayable_error } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  DefaultModalContent,
  ErrorMessage,
  RequestButtonWithIcon,
  GROUPS,
  useGroupLeave,
} from "@thrive-web/ui-components";
import { useModal, useRequest } from "@thrive-web/ui-hooks";
import { maybe_route } from "@thrive-web/ui-utils";

export const GroupMemberLeaveModalBody: Preact.FunctionComponent<
  ModalBodyProps & { group: Group }
> = ({ group, closeButton, dismiss }) => {
  const { dispatch } = useContext(GROUPS);
  const [leave_group_req, { pending, success, error }] = useRequest(
    useGroupLeave(group)
  );
  const leave_group = useCallback(() => {
    leave_group_req().then(() => {
      analytics.log_event(analytics.EVENTS.group_leave);
      dispatch.remove(g => g.id === group.id);
      setTimeout(() => {
        dismiss();
        setTimeout(() => {
          maybe_route("/groups");
        }, MODAL_ANIMATION_DELAY);
      }, 1200);
    });
  }, [group.id, dispatch, leave_group_req]);

  return (
    <DefaultModalContent title="Leave Group" closeButton={closeButton}>
      <div className="modal__delete-record group-members__modal__leave">
        <p>
          Are you sure you want to leave <strong>{group.name}</strong>?
        </p>
        <div className="modal__footer">
          <button className="filled gray" onClick={dismiss}>
            Cancel
          </button>
          {error && (
            <ErrorMessage>{make_displayable_error(error).message}</ErrorMessage>
          )}
          <RequestButtonWithIcon
            className="filled negative"
            pending={pending}
            success={success}
            successText="Success!"
            icon="leave"
            side="left"
            onClick={leave_group}
          >
            Leave Group
          </RequestButtonWithIcon>
        </div>
      </div>
    </DefaultModalContent>
  );
};

export const GroupMemberLeaveModal: Preact.FunctionComponent<{
  group: Group;
}> = ({ group }) => {
  const body_props = useMemo(() => ({ group }), [group]);

  const [leave_group_modal, set_open] = useModal({
    id: "leave-group-modal",
    innerClassName: "card modal-form",
    body: GroupMemberLeaveModalBody,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
    showCloseButton: true,
    bodyProps: body_props,
  });

  const open_modal = useCallback(() => set_open(true), [set_open]);

  return (
    <ButtonWithIcon
      icon="leave"
      side="left"
      className="pill filled gray"
      onClick={open_modal}
    >
      Leave Group
      {leave_group_modal}
    </ButtonWithIcon>
  );
};
