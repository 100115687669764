import * as Preact from "preact";
import { SvgIcon } from "./base";

// bouncing dots element
export const SvgLoadMore: Preact.FunctionComponent<MaybeClass> = ({
  className,
}) => (
  <SvgIcon className={className} width="32px" height="32px" viewBox="0 0 32 32">
    <circle r="4" cx="4" cy="0" opacity="0.504296875" />
    <circle r="4" cx="16" cy="0" opacity="0.504296875" />
    <circle r="4" cx="28" cy="0" opacity="0.504296875" />
  </SvgIcon>
);
SvgLoadMore.displayName = "SvgLoadMore";
