import * as Preact from "preact";
import { useId, usePopup } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { createImageView } from "@thrive-web/ui-components";
import { useCallback } from "preact/hooks";
import { forwardRef } from "preact/compat";

/** an image that expands to an ImageView when clicked */
export const ExpandableImage = forwardRef<
  HTMLImageElement,
  HTMLImageProps & {
    expandedClassName?: string;
    previewSrc?: string;
  }
>(
  (
    { className, expandedClassName, previewSrc, onClick, style, ...props },
    ref
  ) => {
    const id = useId(props.id);
    const [preview, showPreview] = usePopup(createImageView, {
      id,
      children: (
        <img
          draggable={false}
          className={`expandable-image__expanded${maybeClassName(
            expandedClassName
          )}`}
          {...props}
        />
      ),
    });
    const onClickImage = useCallback(
      e => {
        // @ts-expect-error:
        onClick && onClick(e);
        showPreview(true);
      },
      [showPreview]
    );

    return (
      <Preact.Fragment>
        <img
          className={`expandable-image${maybeClassName(className)}`}
          draggable={false}
          {...props}
          src={previewSrc || props.src}
          style={style}
          onClick={onClickImage}
          ref={ref}
        />
        {preview}
      </Preact.Fragment>
    );
  }
);
