import * as Preact from "preact";
import { DivWithIcon, SvgLoadMore } from "@thrive-web/ui-components";
import { useMemo } from "preact/hooks";
import { URL_REGEX_G } from "@thrive-web/ui-common";
import { maybeClassName } from "@thrive-web/ui-utils";

export const DefaultPendingView: Preact.FunctionComponent = () => (
  <div className="loading-dots">
    <SvgLoadMore />
  </div>
);

export const LoadingParagraph: Preact.FunctionComponent<
  MaybeClass & {
    minLines?: number;
    maxLines?: number;
  }
> = ({ minLines = 2, maxLines = 5, className }) => {
  const lines = useMemo(() => {
    const count = Math.round(Math.random() * (maxLines - minLines)) + minLines;
    return new Array(count).fill(0);
  }, []);
  const last_line_length = useMemo(
    () => Math.round(Math.random() * 33) + 33,
    []
  );

  return (
    <div className="loading-item__paragraph">
      {lines.map((_, i) => (
        <div
          key={i}
          className={`loading-item__text loading-item__shaded${maybeClassName(
            className
          )}`}
          style={
            i === lines.length - 1
              ? { width: `${last_line_length}%` }
              : undefined
          }
        />
      ))}
    </div>
  );
};

export const LoadingMood: Preact.FunctionComponent = () => (
  <DivWithIcon
    className="pill filled gray loading-item loading-item__mood"
    icon={
      <div className="group__dot loading-item__shaded loading-item__shaded--light" />
    }
    side="left"
  >
    <div className="loading-item__shaded loading-item__text" />
  </DivWithIcon>
);

// find urls within a string, convert them into `<a>`s, and return the result as a VNode
export const TextWithLinks = ({
  children,
}: {
  children: string;
}): Preact.VNode => {
  const formatted = useMemo<string | (string | Preact.VNode)[]>(() => {
    if (typeof children !== "string") {
      return children;
    }
    const matches = children.match(URL_REGEX_G);
    if (!matches) {
      return children;
    }
    const output: (string | Preact.VNode)[] = [];
    let rest = children;
    matches.forEach(url => {
      if (!rest) {
        return;
      }
      const [before, after] = rest.split(url);
      before && output.push(before);
      output.push(
        <a
          className="plain-link"
          target="_blank"
          href={/^https?:\/\//i.test(url) ? url : `https://${url}`}
        >
          {url}
        </a>
      );
      rest = after;
    });
    if (rest) {
      output.push(rest);
    }
    return output;
  }, [children]);
  return <Preact.Fragment>{formatted}</Preact.Fragment>;
};

// takes a list of strings as children, formats them as VNodes (makes them bold by default),
// and joins them with proper punctuation
export const JoinStringList = ({
  children,
  render = str => <strong>{str}</strong>,
}: {
  render?: (str: string) => Preact.VNode;
  children: string[];
}) => {
  return useMemo(() => {
    const nodes = children.filter(str => !!str).map(str => render(str));
    if (nodes.length === 0) {
      return null;
    }
    if (nodes.length === 1) {
      return nodes[0];
    }
    if (nodes.length === 2) {
      return (
        <Preact.Fragment>
          {nodes[0]} and {nodes[1]}
        </Preact.Fragment>
      );
    }
    const list = nodes.slice();
    const last = list.pop();
    return (
      <Preact.Fragment>
        {list.map(n => (
          <Preact.Fragment>{n}, </Preact.Fragment>
        ))}{" "}
        and {last}
      </Preact.Fragment>
    );
  }, [children, render]);
};
