import * as Preact from "preact";
import { unrenderAfterDismissed, usePortal } from "@thrive-web/ui-hooks";
import { Icon, Popup, PopupGenerator } from "@thrive-web/ui-components";
import { CONTEXTS } from "@thrive-web/ui-model";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { useContext } from "preact/hooks";

export interface ModalComponentProps<T extends object = {}>
  extends ModalProps<T>,
    Omit<PopupProps, "popupType"> {
  id: string;
  showCloseButton?: boolean;
  bodyProps?: T;
}

export const Modal: Preact.FunctionComponent<ModalComponentProps> = ({
  className,
  body: Body,
  id,
  giveTabFocus,
  dismiss,
  showCloseButton,
  bodyProps,
  ...props
}) => (
  <Popup
    id={id}
    popupType="modal"
    className={className}
    giveTabFocus={giveTabFocus}
    dismiss={dismiss}
    {...props}
  >
    <Body
      open={props.open}
      dismiss={dismiss}
      closeButton={
        showCloseButton || props.dismissOnClickBackdrop ? (
          <div className="modal__close">
            <button
              id={`${id}-close-button`}
              tabIndex={giveTabFocus ? 0 : undefined}
              className="modal__x-button"
              onClick={dismiss}
              type="button"
            >
              <Icon name="remove" />
            </button>
          </div>
        ) : undefined
      }
      {...bodyProps}
    />
  </Popup>
);

export const createModal: PopupGenerator<ModalComponentProps> = (
  props,
  open,
  dismiss,
  unrenderWhenDismissed = true,
  container_id = "modal-container"
) => {
  useContext(CONTEXTS.modal_container_ready);
  const shouldRender = unrenderAfterDismissed(
    open,
    unrenderWhenDismissed,
    MODAL_ANIMATION_DELAY
  );
  const showPopup = usePortal(container_id, shouldRender);
  return !shouldRender
    ? null
    : showPopup(<Modal {...props} open={open} dismiss={dismiss} />);
};

export const DefaultModalContent: Preact.FunctionComponent<{
  title: string | Preact.VNode;
  footer?: Preact.VNode;
  closeButton?: Preact.VNode;
}> = ({ title, footer, closeButton, children }) => (
  <Preact.Fragment>
    <ModalHeader button={closeButton}>
      <ModalTitle>{title}</ModalTitle>
    </ModalHeader>
    <div className="modal__body">{children}</div>
    {footer}
  </Preact.Fragment>
);

export const ModalHeader: Preact.FunctionComponent<{
  button?: Preact.VNode;
}> = ({ children, button }) => (
  <div className="modal__header">
    {children}
    {button}
  </div>
);

export const ModalTitle: Preact.FunctionComponent = ({ children }) => (
  <div className="modal__title">{children}</div>
);
