import * as Preact from "preact";
import { createPortal } from "preact/compat";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import {
  PO_EDGE_BUFFER_LG,
  PO_EDGE_BUFFER_SM,
  ScreenSize,
} from "@thrive-web/ui-constants";
import { CONTEXTS } from "@thrive-web/ui-model";
import { get_object_from_entries } from "@thrive-web/ui-common";
import {
  useCallbackRef,
  useDebounce,
  usePopoverTabFocus,
  useStateIfMounted,
  useStateRef,
  useValueRef,
  useWindowEventListener,
} from "@thrive-web/ui-hooks";
import {
  class_names,
  closestAncestor,
  get_scrollbar_width,
  has_scrollbar,
  maybeClassName,
} from "@thrive-web/ui-utils";

export interface PopoverPositionOptions {
  preferredDirection?: PopoverDirection;
  preferredOffset?: PopoverDirection;
  forceDirection?: PopoverDirection;
  forceOffset?: PopoverDirection;
  altSource?: boolean;
  altContainer?: boolean;
  screenEdgeBuffer: number;
}

const PO_NUB_LENGTH = 10;
const PO_MIN_NUB_OFFSET = 20;

const is_vertical = (dir: PopoverDirection): dir is "top" | "bottom" =>
  dir === "top" || dir === "bottom";
const is_horizontal = (dir: PopoverDirection): dir is "left" | "right" =>
  dir === "left" || dir === "right";
const is_same_axis = (dir1: PopoverDirection, dir2: PopoverDirection) =>
  (is_vertical(dir1) && is_vertical(dir2)) ||
  (is_horizontal(dir1) && is_horizontal(dir2));
const opposite_dir = (dir: PopoverDirection): PopoverDirection =>
  dir === "top"
    ? "bottom"
    : dir === "bottom"
    ? "top"
    : dir === "left"
    ? "right"
    : "left";
const get_source_point = (
  dir: PopoverDirection,
  points: ObjectOf<number[]>
) => {
  const point = points[dir];
  return {
    top: `${point[1]}px`,
    left: `${point[0]}px`,
  };
};

const detect_position = async (
  // entries: IntersectionObserverEntry[],
  source_elem: HTMLElement,
  body_elem: HTMLElement,
  offset_elem: HTMLElement,
  initial_pos_set: boolean,
  first_shown: boolean,
  mount_local: boolean,
  current_direction?: PopoverDirection,
  current_offset?: PopoverDirection | "custom",
  hide_nub?: boolean,
  {
    preferredDirection,
    preferredOffset,
    forceDirection,
    forceOffset,
    screenEdgeBuffer,
    altSource,
  }: PopoverPositionOptions = {
    screenEdgeBuffer: PO_EDGE_BUFFER_LG,
  }
): Promise<Partial<PopoverState> | undefined> => {
  if (!source_elem || !body_elem) {
    return;
  }
  const nub_length = hide_nub ? 0 : PO_NUB_LENGTH;

  const {
    offset_x,
    offset_y,
    width,
    height,
    source_xv,
    source_yv,
    source_w,
    source_h,
    source_x,
    source_y,
    window_w,
    window_h,
    zero_x,
    zero_y,
    scrollbar_x,
    scrollbar_y,
  } = await new Promise((resolve, reject) => {
    requestAnimationFrame(() => {
      const scroll_x = window.scrollX;
      const scroll_y = window.scrollY;

      let scrollbar_x = has_scrollbar("x") ? get_scrollbar_width("y") : 0;
      let scrollbar_y = has_scrollbar("y") ? get_scrollbar_width("x") : 0;

      let header_y = 0;
      let sidebar_x = 0;
      if (mount_local) {
        // the header has a higher z-index, and would hide popovers, so we need to
        // adjust the available space accordingly
        const sidebar = document.getElementById("site-nav__sidebar");
        const header = document.getElementById("site-header");
        const diff_x = sidebar ? sidebar.offsetWidth - scroll_x : 0;
        const diff_y = header ? header.offsetHeight - scroll_y : 0;
        header_y = Math.max(diff_y, 0);
        sidebar_x = Math.max(diff_x, 0);
      }

      const { left: offset_xv_, top: offset_yv_ } =
        offset_elem.getBoundingClientRect();
      const offset_yv = offset_yv_ - header_y;
      const offset_xv = offset_xv_ - sidebar_x;
      const offset_x = scroll_x + offset_xv;
      const offset_y = mount_local ? offset_yv : scroll_y + offset_yv;
      const { width: width_v, height: height_v } =
        body_elem.getBoundingClientRect();
      const {
        x: source_xv_,
        y: source_yv_,
        width: source_w,
        height: source_h,
      } = source_elem.getBoundingClientRect();
      const source_yv = source_yv_ - header_y;
      const source_xv = source_xv_ - sidebar_x;
      const source_x = scroll_x + source_xv;
      const source_y = scroll_y + source_yv;

      const zero_x = mount_local ? -source_xv : scroll_x;
      const zero_y = mount_local ? -source_yv : scroll_y;

      const window_w = window.innerWidth - sidebar_x - scrollbar_x;
      const window_h = window.innerHeight - header_y - scrollbar_y;

      const width = Math.min(width_v, window_w - 2 * screenEdgeBuffer);
      const height = Math.min(height_v, window_h - 2 * screenEdgeBuffer);

      /*console.log(
        `dimensions`,
        { sidebar_x, header_y, scrollbar_x, scrollbar_y },
        {
          offset_x,
          offset_y,
          offset_xv,
          offset_yv,
          width_v,
          height_v,
          width,
          height,
          source_xv,
          source_yv,
          source_w,
          source_h,
          source_x,
          source_y,
          window_w,
          window_h,
          window_w_total,
          window_h_total,
          zero_x,
          zero_y,
          scroll_x,
          scroll_y
        }
      );*/

      resolve({
        offset_x,
        offset_y,
        width,
        height,
        source_xv,
        source_yv,
        source_w,
        source_h,
        source_x,
        source_y,
        window_w,
        window_h,
        zero_x,
        zero_y,
        scrollbar_x,
        scrollbar_y,
      });
    });
  });

  // calculate the source points (midpoint of each side of the source elem)
  const source_points = {
    top: [source_x + source_w / 2 - offset_x, source_y - offset_y],
    left: [source_x - offset_x, source_y + source_h / 2 - offset_y],
    right: [source_x + source_w - offset_x, source_y + source_h / 2 - offset_y],
    bottom: [
      source_x + source_w / 2 - offset_x,
      source_y + source_h - offset_y,
    ],
  };

  if (forceDirection && forceOffset) {
    const opposite_offset = opposite_dir(forceOffset);
    const side_length = is_vertical(opposite_offset) ? source_h : source_w;
    const offset = Math.max(side_length / 2, PO_MIN_NUB_OFFSET);
    const new_state: any = {
      initial_position_set: true,
      direction: forceDirection,
      offset: forceOffset,
      position: get_source_point(forceDirection, source_points),
    };
    if (!mount_local || altSource) {
      new_state.style = {
        [`margin-${opposite_offset}`]: `${(mount_local ? 1 : -1) * offset}px`,
      };
    }
    if (!hide_nub) {
      new_state.nub_style = {
        [`${opposite_offset}`]: `min(${offset}px, 50%)`,
      };
    }
    return new_state;
  }

  // map out the space we have in each direction
  const available_space = {
    top: source_yv - screenEdgeBuffer - nub_length - height,
    left: source_xv - screenEdgeBuffer - nub_length - width,
    right:
      window_w - (source_xv + source_w + screenEdgeBuffer + nub_length + width),
    bottom:
      window_h -
      (source_yv + source_h + screenEdgeBuffer + nub_length + height),
  };

  // map out the space we have in each direction for offset
  const available_space_offsets = {
    top: source_yv - screenEdgeBuffer + source_h - height,
    left: source_xv - screenEdgeBuffer + source_w - width,
    right: window_w - (source_xv + width + screenEdgeBuffer),
    bottom: window_h - (source_yv + height + screenEdgeBuffer),
  };

  const available_directions = get_object_from_entries<
    { [K in PopoverDirection]?: number }
  >(
    Object.entries(available_space).filter(([, l]) => l >= 0) as [
      PopoverDirection,
      number
    ][]
  );

  const available_offsets = get_object_from_entries<
    { [K in PopoverDirection]?: number }
  >(
    Object.entries(available_space_offsets).filter(([, l]) => l >= 0) as [
      PopoverDirection,
      number
    ][]
  );

  // console.log(`available_directions: `, available_directions);
  // console.log(`available_offsets: `, available_offsets);

  // keep the current position if we can
  /*if (
    first_shown &&
    !!current_direction &&
    !!available_directions[current_direction] &&
    !!current_offset &&
    !!available_offsets[current_offset] &&
    (!preferredDirection || current_direction === preferredDirection) &&
    (!preferredOffset || current_offset === preferredOffset)
  ) {
    return;
  }*/

  let new_dir;
  let new_off;

  const default_dir = preferredDirection || "top";
  const default_off =
    !preferredOffset || is_same_axis(preferredOffset, default_dir)
      ? is_vertical(default_dir)
        ? "right"
        : "top"
      : preferredOffset;

  if (forceDirection) {
    new_dir = forceDirection;
  } else if (available_directions[default_dir]) {
    new_dir = default_dir;
  } else if (available_directions[opposite_dir(default_dir)]) {
    new_dir = opposite_dir(default_dir);
  } else if (available_directions[default_off]) {
    new_dir = default_off;
  } else if (available_directions[opposite_dir(default_off)]) {
    new_dir = opposite_dir(default_off);
  } else {
    new_dir = Object.entries(available_space).reduce(
      (cur, next) => (next[1] > cur[1] ? next : cur),
      ["", -9999999]
    )[0];
    if (new_dir === "top") {
    }
  }

  Object.keys(available_offsets).forEach(dir => {
    if (is_same_axis(dir as PopoverDirection, new_dir)) {
      delete available_offsets[dir];
    }
  });

  if (forceOffset) {
    new_off = forceOffset;
  } else if (available_offsets[default_off]) {
    new_off = default_off;
  } else if (available_offsets[opposite_dir(default_off)]) {
    new_off = opposite_dir(default_off);
    // if preferred direction not available, and chosen direction is on opposite axis,
    // use preferred direction as offset
  } else if (available_offsets[default_dir]) {
    new_off = default_dir;
  } else if (available_offsets[opposite_dir(default_dir)]) {
    new_off = opposite_dir(default_dir);
  } else {
    // if there isn't enough space on either side for offset, offset is "custom"
    new_off = "custom";
  }

  const new_state: Partial<PopoverState> = {
    initial_position_set: true,
    direction: new_dir,
    offset: new_off,
    position: get_source_point(new_dir, source_points),
  };

  if (new_off !== "custom") {
    const opposite_offset = opposite_dir(new_off);
    const side_length = is_vertical(opposite_offset) ? source_h : source_w;
    const offset = Math.max(side_length / 2, PO_MIN_NUB_OFFSET);
    if (!mount_local || altSource) {
      new_state.style = {
        [`margin-${opposite_offset}`]: `${(mount_local ? 1 : -1) * offset}px`,
      };
    }
    if (!hide_nub) {
      new_state.nub_style = {
        [`${opposite_offset}`]: `min(${offset}px, 50%)`,
      };
    }
    return new_state;
  }

  const flush = {
    top:
      zero_y + screenEdgeBuffer - (mount_local ? 0 : source_points[new_dir][1]),
    left:
      zero_x + screenEdgeBuffer - (mount_local ? 0 : source_points[new_dir][0]),
    right:
      zero_x +
      window_w -
      width +
      (mount_local
        ? screenEdgeBuffer
        : -(source_points[new_dir][0] + screenEdgeBuffer)),
    bottom:
      zero_y +
      window_h -
      height +
      (mount_local
        ? screenEdgeBuffer
        : -(source_points[new_dir][1] + screenEdgeBuffer)),
  };

  // choose one of the preferred directions, if we can
  const default_edge =
    preferredDirection && !is_same_axis(preferredDirection, new_dir)
      ? preferredDirection
      : preferredOffset && !is_same_axis(preferredOffset, new_dir)
      ? preferredOffset
      : undefined;

  if (is_horizontal(new_dir)) {
    const edge =
      default_edge ||
      (available_space_offsets.top > available_space_offsets.bottom
        ? "top"
        : "bottom");

    let top = flush[edge];
    let bottom;
    if (mount_local) {
      bottom = top + height - source_h / 2;
      if (edge === "bottom") {
        top -= scrollbar_y;
      }
    } else {
      bottom = height + screenEdgeBuffer - (source_y - zero_y + source_h / 2);
      if (edge === "bottom") {
        bottom = height + top;
      }
    }

    new_state.style = {
      top: `${top}px`,
      bottom: `${bottom}px`,
      height: `${height}px`,
    };
  } else {
    const edge =
      default_edge ||
      (available_space_offsets.left > available_space_offsets.right
        ? "left"
        : "right");

    // console.log(`edge: `, edge);

    let left = flush[edge];
    let right;
    if (mount_local) {
      right = left + width - source_w / 2;
      if (edge === "right") {
        left -= scrollbar_x;
      }
    } else {
      right = width + screenEdgeBuffer - (source_x - zero_x + source_w / 2);
      if (edge === "right") {
        right = width + left;
      }
    }

    new_state.style = {
      left: `${left}px`,
      right: `${right}px`,
      width: `${width}px`,
    };
  }

  return new_state;
};

export const Popover: Preact.FunctionComponent<PopoverProps> = ({
  id,
  className,
  children,
  show,
  delay = 0,
  triggerComponent,
  animation = "slide",
  defaultBody = true,
  defaultDirection,
  defaultOffset,
  forceDirection,
  forceOffset,
  getContainerRef,
  getContentRef,
  getSourceRef,
  hideNub,
  disableFocusTrap,
  disableTabIndexOnClose,
  mountLocal,
  windowProps = {},
  portalClassName,
  triggerClassName,
}) => {
  /*useEffect(() => {
    console.log(`popover mounted`);
    return () => console.log(`popover unmounted`);
  }, []);*/

  const ready = useContext(CONTEXTS.modal_container_ready);
  const window_size = useContext(CONTEXTS.window_size);
  const [state, setState] = useStateIfMounted<PopoverState>({
    first_shown: false,
    initial_position_set: false,
  });
  const cur_state = useValueRef(state);

  const body = useRef<HTMLDivElement>();
  const root = useRef<HTMLDivElement>();
  const source = useRef<HTMLDivElement>();

  const container_elem = useCallback(
    () => getContainerRef?.() || (root.current?.offsetParent as HTMLElement),
    [getContainerRef, root, ready]
  );
  const source_elem = useCallback(
    () => getSourceRef?.() || source.current,
    [getSourceRef, source]
  );

  const body_elem = useValueRef(getContentRef?.() || body.current);

  useEffect(() => {
    const source_el = source_elem();
    if (source_el && source_el.parentElement) {
      let container_el = closestAncestor(source_el.parentElement, [
        ".popup",
        ".popover",
      ]);
      if (container_el === root.current) {
        container_el = container_el.parentElement
          ? closestAncestor(container_el.parentElement, [".popup", ".popover"])
          : null;
      }
      setState(cur => ({
        ...cur,
        has_container: container_el?.classList?.contains("popup")
          ? "in-popup"
          : container_el?.classList?.contains("popover")
          ? container_el?.classList?.contains("popup")
            ? "in-popover in-popup"
            : "in-popover"
          : false,
      }));
    }
  }, [!!source_elem()]);

  const update_position = useCallback(
    (resized?: boolean) => {
      const container_el = container_elem();
      const source_el = source_elem();
      const body_el = body_elem.current;
      if (!window || !container_el || !source_el || !body_el) {
        return;
      }

      detect_position(
        source_el,
        body_el,
        container_el,
        !!cur_state.current?.initial_position_set,
        !!cur_state.current?.first_shown && !resized,
        !!mountLocal,
        cur_state.current?.direction,
        cur_state.current?.offset,
        hideNub,
        {
          preferredDirection: defaultDirection,
          preferredOffset: defaultOffset,
          forceDirection,
          forceOffset,
          altSource: !!getSourceRef?.(),
          screenEdgeBuffer:
            window_size > ScreenSize.sm ? PO_EDGE_BUFFER_LG : PO_EDGE_BUFFER_SM,
        }
      ).then(new_state => {
        if (new_state) {
          /*console.log(
          `new position, new offset: `,
          new_state.position,
          new_state.offset
        );
        console.log(
          `cur position, cur offset: `,
          cur_state.current.position,
          cur_state.current.offset
        );*/
          setState(cur => ({
            ...cur,
            nub_style: undefined,
            style: undefined,
            position: undefined,
            ...new_state,
            first_shown:
              (show || cur_state.current.first_shown) &&
              // when the pos/offset change, the classname changes, thus the css animation
              // changes, which will make the animation play. Upon closing the popover,
              // position is recalculated, but we don't want the anim to play when the
              // position changes after closing. This condition prevents that.
              (cur.initial_position_set && !show
                ? new_state.direction === cur.direction &&
                  new_state.offset === cur.offset
                : true),
          }));
        }
      });
    },
    [
      container_elem,
      source_elem,
      body_elem.current,
      root,
      cur_state,
      setState,
      window_size,
      show,
      hideNub,
      defaultDirection,
      defaultOffset,
      forceDirection,
      forceOffset,
      mountLocal,
      getSourceRef,
    ]
  );

  useEffect(() => {
    if (!show && first_shown) {
      setTimeout(update_position, 250);
    } else if (ready && (show || (!state.initial_position_set && mountLocal))) {
      update_position();
    }
  }, [show, ready, update_position, window_size]);

  usePopoverTabFocus(
    body,
    source,
    show,
    !mountLocal,
    disableTabIndexOnClose,
    disableFocusTrap
  );

  const on_resize = useCallback(() => {
    update_position(true);
  }, [update_position]);

  const on_resize_listener = useDebounce(on_resize, 100);
  const enableListener = useWindowEventListener("resize", on_resize_listener);
  useEffect(
    () => enableListener(show || state.first_shown),
    [enableListener, show || state.first_shown]
  );

  const trigger_class = mountLocal ? undefined : className;

  const portal_container =
    getContainerRef?.() ||
    (!mountLocal ? document.getElementById("popover-container") : undefined);

  const {
    direction,
    offset,
    position,
    style,
    first_shown,
    nub_style,
    initial_position_set,
    has_container,
  } = state;

  const trigger = (
    <div
      ref={source}
      className={`popover__trigger${maybeClassName(
        trigger_class
      )}${maybeClassName(triggerClassName)}`}
    >
      {triggerComponent}
    </div>
  );

  const content = (
    <div
      ref={root}
      className={`popover${maybeClassName(className)}${
        !mountLocal ? maybeClassName(portalClassName) : ""
      }${maybeClassName(has_container)}`}
      data-local={mountLocal}
      data-inital-pos-set={`${initial_position_set}`}
      data-first-shown={`${first_shown && initial_position_set}`}
      data-show={`${show && initial_position_set}`}
      onMouseEnter={!show ? () => update_position() : undefined}
      id={id}
      style={mountLocal ? undefined : (position as any)}
      /*style={
        delay > 0
          ? {
              animationDelay: `${delay}ms`,
            }
          : undefined
      }*/
    >
      {mountLocal && trigger}
      <div
        ref={body}
        style={style as any}
        className={class_names(
          {
            [`--${animation}`]: true,
            [`__${direction}`]: !!direction,
            [`__${direction}--${offset}`]: !!direction && !!offset,
          },
          "popover__window"
        )}
        {...windowProps}
      >
        <fieldset
          disabled={!show}
          style={{ display: "contents", ...(nub_style as any) }}
        >
          <div
            className={class_names(
              { "--default": defaultBody },
              "popover__content"
            )}
          >
            {defaultBody ? (
              <div className="popover__background">
                <div className="popover__body">{children}</div>
              </div>
            ) : (
              children
            )}
          </div>
        </fieldset>
      </div>
    </div>
  );

  if (!mountLocal) {
    if (!ready) {
      return null;
    }
    if (!portal_container) {
      return <Preact.Fragment>{triggerComponent}</Preact.Fragment>;
    }
  }

  if (mountLocal) {
    return content;
  }

  return (
    <Preact.Fragment key={id}>
      {trigger}
      {portal_container ? createPortal(content, portal_container) : content}
    </Preact.Fragment>
  );
};

// ======================================================================
// ========================= TRIGGER HOOKS ==============================
// ======================================================================

export type PopoverTriggerProps = MaybeClass & {
  onMouseOver?: EventListener;
  onMouseEnter?: EventListener;
  onMouseLeave?: EventListener;
  onFocus?: EventListener;
  onBlur?: EventListener;
  onClick?: EventListener;
};

// generate listeners for click/focus/hover to open a popover
export const usePopoverTrigger = (
  options: PopoverTriggerOptions,
  id: string, // id of the popover, not the trigger
  show_delay: number = 0,
  default_show: boolean = false
): [
  boolean,
  (open: boolean, immediate?: boolean) => void,
  PopoverTriggerProps,
  PopoverWindowProps
] => {
  const [show, setShow_, show_ref] = useStateRef(default_show);
  const window_hovered = useRef(false);
  const hovered = useRef(false);
  const focused = useRef(false);
  const clicked = useRef(false);
  const trigger = useRef<HTMLElement>();
  const delay = useRef<any>();
  // const onClickOutside = useRef(e => {});
  const setShow = useCallback(
    (
      val: boolean,
      immediate?: boolean,
      action_ref?: Preact.RefObject<boolean>,
      check_focus?: boolean
    ) => {
      if (!val) {
        focused.current = false;
        clicked.current = false;
        hovered.current = false;
      }
      if (!val || show_delay <= 0 || immediate) {
        if (delay.current) {
          clearTimeout(delay.current);
          delay.current = undefined;
        }
        if (immediate) {
          hovered.current = false;
          focused.current = false;
        }
        setShow_(val);
      } else if (!delay.current) {
        delay.current = setTimeout(() => {
          /*console.log(
            `timer expired; value and action?`,
            val,
            action_ref?.current
          );*/
          delay.current = undefined;
          if (
            val &&
            check_focus &&
            trigger.current &&
            !document.activeElement?.isSameNode(trigger.current) &&
            !trigger.current.contains(document.activeElement)
          ) {
            focused.current = false;
            return;
          }
          if (!action_ref || action_ref.current === val) {
            setShow_(val);
          }
        }, show_delay);
      }
    },
    [setShow_, delay]
  );

  const { hover = true, focus = true, click = true } = options;
  const onClick = useMemo(() => {
    if (click) {
      return e => {
        const { current } = clicked;
        hovered.current = false;
        focused.current = e.target === document.activeElement;
        clicked.current = !current;
        setShow(!current, undefined, clicked);
      };
    }
  }, [click, setShow, clicked.current]);
  const onFocus = useMemo(() => {
    if (focus) {
      return e => {
        trigger.current = e.target;
        if (!show_ref.current) {
          focused.current = true;
          hovered.current = false;
          setShow(true, undefined, focused, true);
        }
      };
    }
  }, [focus, setShow, show_ref]);

  const onMouseOver = useMemo(() => {
    if (hover) {
      return e => {
        // console.log(`mouseOver`);
        if (!show_ref.current) {
          hovered.current = true;
          setShow(true, undefined, hovered);
        }
      };
    }
  }, [hover, hovered, setShow, show_ref]);
  const onMouseEnter = useMemo(() => {
    if (hover) {
      return e => {
        // console.log(`mouseEnter`);
        if (!show_ref.current && !focused.current && !clicked.current) {
          hovered.current = true;
          setShow(true, undefined, hovered);
        } else if (window_hovered.current && show_ref.current) {
          hovered.current = true;
        }
      };
    }
  }, [hover, focused, setShow, show_ref]);
  const onMouseLeave = useMemo(() => {
    if (hover) {
      return e => {
        // console.log(`mouseLeave`);
        setTimeout(() => {
          if (
            show_ref.current &&
            hovered.current &&
            !window_hovered.current &&
            !focused.current &&
            !clicked.current
          ) {
            setShow(false, undefined, hovered);
          }
          hovered.current = false;
        }, 100);
      };
    }
  }, [hover, focused, setShow, show_ref]);

  const onMouseEnterWindow = useMemo(() => {
    if (hover) {
      return e => {
        // console.log(`mouseEnter`);
        if (!show_ref.current && !focused.current && !clicked.current) {
          window_hovered.current = true;
          setShow(true, undefined, window_hovered);
        } else if (hovered.current && show_ref.current) {
          window_hovered.current = true;
        }
      };
    }
  }, [hover, focused, setShow, show_ref]);
  const onMouseLeaveWindow = useMemo(() => {
    if (hover) {
      return e => {
        setTimeout(() => {
          // console.log(`mouseLeave`);
          if (
            show_ref.current &&
            window_hovered.current &&
            !hovered.current &&
            !focused.current &&
            !clicked.current
          ) {
            setShow(false, undefined, window_hovered);
          }
          window_hovered.current = false;
        }, 100);
      };
    }
  }, [hover, focused, setShow, show_ref]);

  const onClickOutside = useCallbackRef(
    e => {
      if (!show_ref.current) {
        return;
      }
      if (
        !e.target ||
        !(
          closestAncestor(e.target, `#${id}`) ||
          (e.type === "click" &&
            trigger.current &&
            (e.target === trigger.current ||
              trigger.current.contains(e.target)))
        )
      ) {
        setShow(false);
      }
    },
    [show_ref, setShow, id]
  );

  const setClickListenerActive = useWindowEventListener(
    "click",
    onClickOutside
  );
  const setFocusListenerActive = useWindowEventListener(
    "focusin",
    onClickOutside
  );

  useEffect(() => {
    if (click || focus) {
      setClickListenerActive(show);
      setFocusListenerActive(show);
    }
  }, [show, setFocusListenerActive, setClickListenerActive]);

  const props: PopoverTriggerProps = useMemo(
    () => ({
      className: class_names(
        {
          "--hover": hover,
          "--focus": focus,
        },
        "popover__trigger",
        true
      ),
      onClick,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
    }),
    [hover, focus, onClick, onFocus, onMouseEnter, onMouseLeave, onMouseOver]
  );

  const windowProps = useMemo(
    () => ({
      onMouseEnter: onMouseEnterWindow,
      onMouseLeave: onMouseLeaveWindow,
    }),
    [onMouseEnterWindow, onMouseLeaveWindow]
  );

  return [show, setShow, props, windowProps];
};
