import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { Event } from "@thrive-web/ui-api";
import { EventListItem, EventListItemLoading } from "./EventListItem";

export const EventList: Preact.FunctionComponent<{
  events: readonly Event[];
  loadMoreElem?: Preact.VNode | null;
  // show the rsvp button in each post block
  showRSVP?: boolean;
  emptyListView?: Preact.VNode;
  onClickShare?: (event: Event) => void;
}> = ({ events, showRSVP, loadMoreElem, emptyListView, onClickShare }) => {
  return (
    <div className="event-list">
      {events.length === 0 ? (
        emptyListView
      ) : (
        <Preact.Fragment>
          {events.map(ev => (
            <EventListItem
              key={ev.id}
              event={ev}
              showRSVP={showRSVP}
              onClickShare={onClickShare}
            />
          ))}
          {loadMoreElem && (
            <div className="event-list__item load-more">{loadMoreElem}</div>
          )}
        </Preact.Fragment>
      )}
    </div>
  );
};

export const EventListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <div className="event-list loading-item__list">
      {items.map((_, i) => (
        <EventListItemLoading key={i} />
      ))}
    </div>
  );
};
