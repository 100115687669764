import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { AvatarLoading, LoadingParagraph } from "@thrive-web/ui-components";

export const NotificationListLoading: Preact.FunctionComponent<{
  is_menu?: boolean;
}> = ({ is_menu }) => {
  const items = useMemo(() => new Array(is_menu ? 3 : 5).fill(0), []);
  return (
    <ul
      className={
        is_menu ? "notif-menu__list" : "notif-page__list loading-item__list"
      }
    >
      {items.map((_, i) => (
        <li
          key={i}
          className={`notif-list__item notif-item loading-item${
            is_menu ? "" : " card"
          }`}
        >
          <div className="notif-item__left">
            <AvatarLoading size="md" />
          </div>
          <div className="notif-item__content">
            <LoadingParagraph maxLines={2} />
          </div>
        </li>
      ))}
    </ul>
  );
};

export const NotificationMenuLoading: Preact.FunctionComponent = () => (
  <NotificationListLoading is_menu={true} />
);

export const NotificationPageLoading: Preact.FunctionComponent = () => (
  <NotificationListLoading />
);
