import * as Preact from "preact";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { analytics, capitalize } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  DefaultDropdownButtonDiv,
  DropdownMenu,
  LinkWithIcon,
  MoodDiv,
  SendSkeletonInviteButton,
  SendSkeletonLinkButton,
  SetCoachButton,
  UserListItem,
  UserContactLinkWithIcon,
  IS_ADMIN_UI,
} from "@thrive-web/ui-components";
import { AppUser } from "@thrive-web/ui-model";
import { ConnectionMapped } from "@thrive-web/ui-api";
import {
  useDelayedEvent,
  useStateIfMounted,
  useZendeskReportEntity,
} from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import {
  RemovableListItem,
  RemovableListItemComponentProps,
} from "@thrive-web/ui-components";

interface ConnectionListItemProps {
  connection: ConnectionMapped;
  user: AppUser;
  onChangeCoach: (conn: ConnectionMapped) => void;
  onClickRemove: (conn: ConnectionMapped, after_remove: () => void) => void;
}

const ConnectionListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<ConnectionListItemProps>
> = ({
  connection,
  user,
  onRemoveItem,
  onClickRemove,
  className,
  removed,
  onChangeCoach,
}) => {
  const is_admin_ui = useContext(IS_ADMIN_UI);
  const dropdown_btn_ref = useRef<HTMLElement | null>(null);
  const get_btn_ref = useCallback(
    () => dropdown_btn_ref.current,
    [dropdown_btn_ref]
  );
  const { other_user, has_coach, health_tier } = connection;
  const [health, set_health] = useStateIfMounted(health_tier);

  const on_report_user = useZendeskReportEntity(other_user);

  const popover_props = useMemo(
    () => ({
      getSourceRef: get_btn_ref,
    }),
    [get_btn_ref]
  );

  const [log_event, cancel_event] = useDelayedEvent(
    () => analytics.log_event(analytics.EVENTS.manage_connection),
    undefined,
    500
  );

  const coach = has_coach
    ? has_coach.id === other_user.id
      ? "coach"
      : "coachee"
    : "";

  const items: any[] = [
    coach !== "coach" ? (
      <SetCoachButton
        connection={connection}
        coach={other_user}
        onUpdate={onChangeCoach}
      />
    ) : null,
    coach !== "coachee" ? (
      <SetCoachButton
        connection={connection}
        coach={user}
        onUpdate={onChangeCoach}
      />
    ) : null,
    !!coach ? (
      <SetCoachButton connection={connection} onUpdate={onChangeCoach} />
    ) : null,
    <ButtonWithIcon
      icon="remove-user"
      side="left"
      className="filled pill negative"
      onClick={e => {
        // @ts-expect-error:
        e.closeMenu?.();
        onClickRemove(connection, onRemoveItem);
      }}
    >
      Remove {other_user.first_name} as Connection...
    </ButtonWithIcon>,
  ];

  if (other_user.firebase_uuid && !is_admin_ui) {
    items.unshift(
      <UserContactLinkWithIcon
        icon="email"
        side="left"
        className="filled pill button gray"
        type="email"
        user={other_user}
        onCreateInteraction={set_health}
        href={`mailto:${other_user.email}`}
      >
        Email: {other_user.email}
      </UserContactLinkWithIcon>
    );
    items.push(
      <div className="dropdown__divider" />,
      <ButtonWithIcon
        icon="alert"
        side="left"
        className="filled pill gray"
        onClick={on_report_user}
      >
        Report this Profile
      </ButtonWithIcon>
    );
  }
  if (!other_user.firebase_uuid) {
    items.unshift(
      <SendSkeletonInviteButton user={other_user} />,
      <SendSkeletonLinkButton user={other_user} />,
      <div className="dropdown__divider" />,
      <LinkWithIcon
        icon="email"
        side="left"
        className="filled pill button gray"
        href={`mailto:${other_user.email}`}
      >
        Email: {other_user.email}
      </LinkWithIcon>
    );
  }
  if (coach) {
    items.unshift(
      <div className="people-connections__list__item__coach">
        {other_user.first_name} is your {coach}.
      </div>
    );
  }

  if (removed) {
    return null;
  }

  return (
    <UserListItem
      user={other_user}
      className={`people-connections__list__item${maybeClassName(className)}`}
      linkBox="content"
      size="lg"
      health={has_coach ? health : undefined}
      cardLabel={capitalize(coach)}
      cardSubtext={
        other_user.has_current_mood ? (
          <MoodDiv mood={other_user.has_current_mood} size="xs" />
        ) : undefined
      }
    >
      <DropdownMenu
        listClassName="card pill-card"
        items={items}
        popoverProps={popover_props}
        button={
          <DefaultDropdownButtonDiv
            className="button filled gray all-gray"
            icon="more"
            ref={dropdown_btn_ref}
          />
        }
        buttonClassName="people-connections__list__item__clickable non-button"
        closeOnClickItem={false}
        onOpen={log_event}
        onClose={cancel_event}
      />
    </UserListItem>
  );
};

export const ConnectionListItem = (
  props: Preact.RenderableProps<ConnectionListItemProps>
) => <RemovableListItem {...props} Component={ConnectionListItemBase} />;
