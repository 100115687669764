import * as Preact from "preact";
import { PageHeader, DivWithIcon } from "@thrive-web/ui-components";

export const GroupDetailLoading: Preact.FunctionComponent = () => {
  return (
    <PageHeader className="group__loading">
      <div className="page-header__title">
        <div className="loading-item__shaded loading-item__text loading-item__header" />
      </div>
      <div className="page-header__subtitle">
        <div className="loading-item__shaded loading-item__text loading-item__title" />
      </div>
      <div className="tab-links overflow">
        <ul className="tab-links__list overflow__list loading-item__list">
          <li className="tab-links__list__item loading-item">
            <DivWithIcon
              className="tab-links__link"
              icon="activity"
              side="left"
            >
              <div className="loading-item__shaded loading-item__shaded--light loading-item__text" />
            </DivWithIcon>
          </li>
          <li className="tab-links__list__item loading-item">
            <DivWithIcon className="tab-links__link" icon="people" side="left">
              <div className="loading-item__shaded loading-item__shaded--light loading-item__text" />
            </DivWithIcon>
          </li>
          <li className="tab-links__list__item loading-item">
            <DivWithIcon className="tab-links__link" icon="unreads" side="left">
              <div className="loading-item__shaded loading-item__shaded--light loading-item__text" />
            </DivWithIcon>
          </li>
          <li className="tab-links__list__item loading-item">
            <DivWithIcon className="tab-links__link" icon="unreads" side="left">
              <div className="loading-item__shaded loading-item__shaded--light loading-item__text" />
            </DivWithIcon>
          </li>
        </ul>
      </div>
    </PageHeader>
  );
};
