import * as hooks from "preact/hooks";
import api from "@thrive-web/ui-api";
import {
  ApiMethod,
  ApiMethodParameters as ApiClientMethodParameters,
  MappedApiResponse,
  ApiMethodCaller,
  ApiListMethod,
  ApiListMethodParams,
  map_api_response,
} from "@thrive-web/ui-api";
import {
  HTTPMethod,
  ApiMethodParameters,
  MetaTypes,
  ApiMethodMap,
  RelationshipKeysOf,
  ApiRelationshipMethodParameters,
  MultivaluedKeysOf,
} from "@thrive-web/core";
import { RequestStatus } from "@thrive-web/ui-model";
import { make_displayable_error } from "@thrive-web/ui-common";
import { useCallbackRef, useStateIfMounted, useStateObject } from ".";

export const ERROR_MESSAGE_ALREADY_SENT = {
  code: "api/request-already-sent",
  message: "This request is already in flight.",
};

// takes a function that returns a promise, and adds tracking for pending, success, and error state
export const useResettableRequest = <A extends any[], R>(
  make_request: (...params: A) => Promise<R>,
  method_name?: string,
  reset_on_func_change?: boolean
) => {
  const [state, setState] = useStateObject<RequestStatus>({
    pending: false,
    success: false,
    error: undefined,
  });
  const is_pending = hooks.useRef<{ current: boolean }>({ current: false });
  const is_cancelled = hooks.useRef<{ current: boolean }>({ current: false });

  const sendRequest = hooks.useCallback(
    (...params: A): Promise<R> => {
      // each request needs its own "refs" for pending/cancelled, because two could be in
      // flight at the same time
      const this_pending = is_pending.current;
      const this_cancelled = is_cancelled.current;
      if (this_pending.current) {
        return Promise.reject({
          ...ERROR_MESSAGE_ALREADY_SENT,
          ...(method_name ? { method: method_name } : {}),
        });
      }
      this_pending.current = true;
      setState({ pending: true, success: false });
      return make_request(...params)
        .then(response => {
          this_pending.current = false;
          if (!this_cancelled.current) {
            setState({
              pending: false,
              success: true,
              error: undefined,
            });
          } else {
            console.debug("Cancelled request resolved", method_name);
            // return a new promise that never resolves so we don't trigger any
            // effects at the original callsite for the old request
            return new Promise<R>(() => {});
          }
          this_cancelled.current = false;
          return response;
        })
        .catch(err => {
          this_pending.current = false;
          if (!this_cancelled.current) {
            setState({
              pending: false,
              success: false,
              error: make_displayable_error(err),
            });
          } else {
            console.debug("Cancelled request rejected", method_name, err);
          }
          this_cancelled.current = false;
          return Promise.reject(err);
        });
    },
    [make_request, setState]
  );

  const resetRequest = hooks.useCallback(() => {
    setState({
      pending: false,
      success: false,
      error: undefined,
    });
    if (is_pending.current) {
      is_cancelled.current.current = true;
      is_pending.current.current = false;
    }
    // when the request is reset, create a new set of "ref"s for pending/cancelled
    is_cancelled.current = { current: false };
    is_pending.current = { current: false };
  }, [setState]);

  hooks.useEffect(() => {
    if (reset_on_func_change) {
      resetRequest();
    }
  }, [make_request]);

  return [sendRequest, state, resetRequest] as const;
};

const useRequestWithoutReset = <A extends any[], R>(
  make_request: (...params: A) => Promise<R>,
  method_name?: string
) => {
  const [send, status] = useResettableRequest(make_request, method_name);
  return [send, status] as const;
};

export const useRequest = <A extends any[], R>(
  make_request: (...params: A) => Promise<R>,
  reset_on_func_change?: boolean,
  method_name?: string
) => {
  const [send, status] = useResettableRequest(
    make_request,
    method_name,
    reset_on_func_change
  );
  return [send, status] as const;
};

// same as useRequest, but also stores the result of the request
export const useControlledRequest = <A extends any[], R, T = R>(
  make_request: (...params: A) => Promise<R>,
  // does NOT have to be memoized
  transform_result?: (result: R) => T,
  reset_on_func_change?: boolean
) => {
  const [result, setResult] = useStateIfMounted<T | null>(null);
  const transform_result_ref = useCallbackRef(
    // @ts-expect-error:
    (result: R): T => (transform_result ? transform_result(result) : result),
    [transform_result]
  );

  const sendRequest = hooks.useCallback(
    (...params: A): Promise<R> =>
      make_request(...params).then(response => {
        setResult(
          transform_result_ref.current
            ? transform_result_ref.current(response)
            : null
        );
        return response;
      }),
    [make_request, transform_result, setResult]
  );
  const use_req = useRequest(sendRequest, reset_on_func_change);

  return [result, ...use_req] as const;
};

// shortcut for calling an api method (non-memoized)
export const getApiMethod =
  <M extends ApiMethod>(method: M): ApiMethodCaller<M> =>
  (...params: ApiClientMethodParameters<M>): Promise<MappedApiResponse<M>> => {
    // @ts-ignore
    return api().then(api_ => api_[method](...params).then(map_api_response));
  };

// shortcut for calling an api method
export const useApiMethod = <M extends ApiMethod>(
  method: M
): ApiMethodCaller<M> =>
  hooks.useCallback(
    (
      ...params: ApiClientMethodParameters<M>
    ): Promise<MappedApiResponse<M>> => {
      // @ts-ignore
      return api().then(api_ => api_[method](...params).then(map_api_response));
    },
    [method]
  );

// shortcut for calling an api method with request tracking
export const useApiCall = <M extends ApiMethod>(method: M) =>
  useRequestWithoutReset(useApiMethod(method), method);

// creates an api method call with bound args
export const useApiFetch = <M extends ApiMethod>(
  method: M,
  ...args: ApiClientMethodParameters<M>
): (() => Promise<MappedApiResponse<M>>) => {
  const api_method = useApiMethod(method);
  return hooks.useCallback(() => api_method(...args), [api_method, ...args]);
};

// creates an api method call with bound args and request tracking
export const useApiRequest = <M extends ApiMethod>(
  method: M,
  ...args: ApiClientMethodParameters<M>
) => useRequestWithoutReset(useApiFetch(method, ...args), method);

export const useApiTypeMethod = <
  T extends keyof MetaTypes,
  M extends HTTPMethod,
  WithIRI extends boolean = M extends "PUT" | "PATCH" | "DELETE"
    ? true
    : M extends "POST"
    ? false
    : boolean
>(
  type: T,
  method: M,
  id: WithIRI extends true ? string : never | undefined,
  params: ApiMethodParameters<M, T, WithIRI>
) =>
  hooks.useCallback((): Promise<ApiMethodMap<WithIRI>[T][M]["response"]> => {
    return api().then(api_ =>
      api_
        .typeMethod(type, method, id, params)
        .then(res =>
          map_api_response(
            res as ApiMethodMap<WithIRI>[T][M]["response"] & {
              deep_includes: any[];
            }
          )
        )
        .catch(err => {
          throw err;
        })
    );
  }, [method, id, params]);

export const useApiRelationshipMethod = <
  T extends keyof MetaTypes,
  M extends HTTPMethod,
  R extends RelationshipKeysOf<MetaTypes[T]>,
  Multi = R extends MultivaluedKeysOf<MetaTypes[T]> ? true : false
>(
  type: T,
  method: M
) =>
  hooks.useCallback(
    (
      relationship: R,
      id: string,
      params: ApiRelationshipMethodParameters<M, T, R, Multi>
    ): Promise<ApiMethodMap<true>[T][M]["response"]> => {
      return api().then(api_ =>
        api_
          .relationshipMethod(type, relationship, method, id, params)
          .then(res =>
            map_api_response(
              res as ApiMethodMap<WithIRI>[T][M]["response"] & {
                deep_includes: any[];
              }
            )
          )
          .catch(err => {
            throw err;
          })
      );
    },
    [type, method]
  );

// useApiFetch, but the callback takes offset/limit for paging
export const useApiFetchPaged = <M extends ApiListMethod>(
  method: M,
  params: ApiListMethodParams<M>
) => {
  const request = useApiMethod(method);

  return hooks.useCallback(
    (offset: number, limit?: number) => {
      const paged_params = {
        ...params,
        query: {
          ...(params.query || {}),
          offset: offset,
          limit: limit || params.query?.limit,
          include_count: true,
        },
      };
      // @ts-expect-error:
      return request(paged_params);
    },
    [request, params]
  );
};

// useApiRequest, but the callback takes offset/limit for paging
export const useApiRequestPaged = <M extends ApiListMethod>(
  method: M,
  params: ApiListMethodParams<M>,
  reset_on_func_change?: boolean
) => useRequest(useApiFetchPaged(method, params), reset_on_func_change, method);

// send two independent requests simultaneously, and resolve when both have completed
export const useCombinedRequest = <
  F1 extends (...params: P1) => Promise<R1>,
  F2 extends (...params: P2) => Promise<R2>,
  C,
  P1 extends any[] = any[],
  R1 extends any = any,
  P2 extends any[] = any[],
  R2 extends any = any
>(
  request_1: F1,
  request_2: F2,
  combine_results: (res1: R1, res2: R2) => C
): ((params_1: P1, params_2: P2) => Promise<C>) => {
  const pending_1 = hooks.useRef(false);
  const pending_2 = hooks.useRef(false);
  const success_1 = hooks.useRef(false);
  const success_2 = hooks.useRef(false);

  return hooks.useCallback(
    (params_1, params_2) => {
      if (pending_1.current || pending_2.current) {
        return Promise.reject({
          code: "api/request-already-sent",
          message: "This request is already in flight.",
        });
      }
      const reqs: any[] = [];
      if (!success_1.current) {
        pending_1.current = true;
        reqs.push(
          request_1(...params_1)
            .then(res => {
              pending_1.current = false;
              success_1.current = true;
              return res;
            })
            .catch(err => {
              pending_1.current = false;
              success_1.current = false;
              return Promise.reject(err);
            })
        );
      }
      if (!success_2.current) {
        pending_2.current = true;
        reqs.push(
          request_2(...params_2)
            .then(res => {
              pending_2.current = false;
              success_2.current = true;
              return res;
            })
            .catch(err => {
              pending_2.current = false;
              success_2.current = false;
              return Promise.reject(err);
            })
        );
      }
      return Promise.all(reqs).then((results: [R1, R2]) => {
        return combine_results(...results);
      });
    },
    [request_1, request_2, combine_results]
  );
};

type PromiseType<T extends (...args) => Promise<any>> =
  ReturnType<T> extends Promise<infer R_1> ? R_1 : never;

// send two consecutive requests, with the second one deriving its parameters from
// the results of the first one
export const useRequestChain = <
  F1 extends (...params) => Promise<any>,
  F2 extends (...params) => Promise<any>,
  C,
  P1 extends Parameters<F1> = Parameters<F1>,
  R1 extends PromiseType<F1> = PromiseType<F1>,
  P2 extends Parameters<F2> = Parameters<F2>,
  R2 extends PromiseType<F2> = PromiseType<F2>
>(
  request_1: F1,
  request_2: F2,
  combine_results: (res1: R1, res2: R2) => C,
  get_params_2_from_result_1?: (res1: R1, params_2: P2) => P2
): ((params_1: P1, params_2: P2) => Promise<C>) => {
  const pending = hooks.useRef(false);
  // track if the first req succeeds
  const success_1 = hooks.useRef(false);
  // result of the first request
  const res1 = hooks.useRef<R1>();
  // derived params for the second request
  const p2 = hooks.useRef<P2>();

  return hooks.useCallback(
    (params_1, params_2) => {
      if (pending.current) {
        return Promise.reject({
          code: "api/request-already-sent",
          message: "This request is already in flight.",
        });
      }
      pending.current = true;
      // if the first req already succeeded, we only need to send the
      // second req
      if (success_1.current) {
        if (!p2.current) {
          p2.current = params_2;
        }
        return request_2(...p2.current)
          .then(res_2 => {
            pending.current = false;
            success_1.current = false;
            return combine_results(res1.current, res_2);
          })
          .catch(err => {
            pending.current = false;
            return Promise.reject(make_displayable_error(err));
          });
      }

      return request_1(...params_1)
        .then(res_1 => {
          success_1.current = true;
          // store result so we don't have to re-run req 1 if req 2 fails
          res1.current = res_1;
          p2.current = params_2;
          if (get_params_2_from_result_1) {
            p2.current = get_params_2_from_result_1(res_1, params_2);
          }
          return request_2(...p2.current)
            .then(res_2 => {
              pending.current = false;
              success_1.current = false;
              return combine_results(res_1, res_2);
            })
            .catch(err => {
              pending.current = false;
              return Promise.reject(make_displayable_error(err));
            });
        })
        .catch(err => {
          pending.current = false;
          return Promise.reject(make_displayable_error(err));
        });
    },
    [request_1, request_2, combine_results, get_params_2_from_result_1]
  );
};
