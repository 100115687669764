import * as Preact from "preact";
import { Goal, Group, Post, Todo, User } from "@thrive-web/ui-api";
import {
  ContextSpec,
  LocalDispatch,
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
} from "@thrive-web/ui-components";
import { createNamedContext } from "@thrive-web/ui-utils";

type GroupLocalCtx = {
  group: Group | null;
  fetch: () => Promise<void>;
};
export const GROUP_FETCH = createNamedContext<() => Promise<void>>(
  () => Promise.resolve(),
  "GroupFetch"
);

export const GROUP_GROUP = createNamedContext<null | Group>(
  null,
  "GroupRecord"
);
export const GROUP_DISPATCH = createNamedContext<LocalDispatch<GroupLocalCtx>>(
  () => {},
  "GroupDispatch"
);

export const GROUP_DETAIL_CONTEXTS: ContextSpec<GroupLocalCtx> = {
  group: GROUP_GROUP,
  dispatch: GROUP_DISPATCH,
  fetch: GROUP_FETCH,
};

export const GROUP_TODOS: Preact.Context<DynamicListCtxSpec<Todo, false, []>> =
  createNamedContext(
    {
      list: [],
      dispatch: DynamicListDispatchDefault,
    },
    "GroupTodos"
  );
export const GROUP_GOALS: Preact.Context<DynamicListCtxSpec<Goal, false, []>> =
  createNamedContext(
    {
      list: [],
      dispatch: DynamicListDispatchDefault,
    },
    "GroupGoals"
  );
export const GROUP_MEMBERS: Preact.Context<
  DynamicListCtxSpec<User, false, [string]>
> = createNamedContext(
  {
    list: null,
    dispatch: DynamicListDispatchDefault,
  },
  "GroupMembers"
);
export const GROUP_POSTS: Preact.Context<DynamicListCtxSpec<Post, false, []>> =
  createNamedContext(
    {
      list: [],
      dispatch: DynamicListDispatchDefault,
    },
    "GroupPosts"
  );

export const GROUP_DETAIL_LIST_CONTEXTS = {
  goals: GROUP_GOALS,
  todos: GROUP_TODOS,
  members: GROUP_MEMBERS,
  posts: GROUP_POSTS,
} as const;
