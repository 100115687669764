import {
  DocBase,
  get_url_key_for_media_property,
  MediaPropertiesOf,
  MetaTypes,
  TYPES,
  Types,
} from "@thrive-web/core";
import * as hooks from "preact/hooks";
import {
  ApiMethod,
  ApiMethodParameters,
  upload_media,
  get_upload_url,
} from "@thrive-web/ui-api";
import {
  path_or,
  get_image_blob_url,
  scrape_video_link,
  scrape_zendesk_link,
  ZendeskLinkMeta,
  VideoLinkMeta,
} from "@thrive-web/ui-common";
import {
  useControlledRequest,
  useRequest,
  useStateIfMounted,
  useStateRef,
  useValueRef,
} from ".";

// getter/setter for media upload url; url can be set from the caller (e.g. if we
// get it from a prior POST/PATCH), if it's not set, we fetch it.
// getter can also take an id in case we need to provide it at call time
export const useMediaUploadUrl = <T extends keyof Types>(
  type: T,
  id: string | undefined,
  property: MediaPropertiesOf<MetaTypes[T]>,
  file?: FileUploadData
) => {
  const [, set_url, url_ref] = useStateRef("");

  hooks.useEffect(() => {
    set_url("");
  }, [type, id, property, file]);
  const file_ref = useValueRef(file);
  const [fetch_url, status] = useRequest(get_upload_url);
  const get_url = hooks.useCallback(
    (id_arg?: string): Promise<string> => {
      const file_ = file_ref.current;
      if (!file_) {
        return Promise.reject(new Error("No file selected for upload"));
      }
      if (url_ref.current) {
        return Promise.resolve(url_ref.current);
      }
      if (!id && !id_arg) {
        return Promise.reject(new Error("No record id provided"));
      }
      const target_id = id_arg || id;
      // @ts-expect-error:
      return fetch_url(type, target_id, property, file_.mime).then(new_url => {
        if (new_url) {
          set_url(new_url);
          return new_url;
        }
        return Promise.reject(
          new Error("Fetch URL response did not contain an upload url")
        );
      });
    },
    [type, id, property, file_ref, url_ref, set_url]
  );

  return [get_url, set_url, status] as const;
};

// macro for uploading a file
export const upload_file = (
  url: string,
  file: FileUploadData,
  progress_listener?: (p: number) => void
) => {
  if (!url) {
    return Promise.reject(new Error("No upload url provided"));
  }
  if (!file) {
    return Promise.reject(new Error("No file selected for upload"));
  }

  return upload_media(url, file.data, file.mime, progress_listener);
};

// combines the above hooks, and can prefetch the upload url if needed
export const useMediaUpload = <T extends keyof Types>(
  type: T,
  id: string | undefined,
  property: MediaPropertiesOf<MetaTypes[T]>,
  file?: FileUploadData,
  prefetch_url?: boolean,
  show_progress?: boolean
) => {
  const [get_url, set_url, { pending }] = useMediaUploadUrl(
    type,
    id,
    property,
    file
  );
  const [progress, set_progress] = useStateIfMounted<number | undefined>(
    undefined
  );

  const file_ref = useValueRef(file);
  const upload = hooks.useCallback(
    (id_arg?: string) => {
      const file_ = file_ref.current;
      if (!file_) {
        return Promise.reject(new Error("No file selected for upload"));
      }
      return get_url(id_arg).then(url =>
        upload_file(url, file_, show_progress ? set_progress : undefined)
      );
    },
    [get_url, file_ref, show_progress]
  );

  hooks.useEffect(() => {
    if (prefetch_url) {
      get_url().catch(err => {
        console.error("Failed to get upload url:", err);
      });
    }
  }, [type, id, property, file?.mime, prefetch_url]);

  return [
    upload,
    set_url,
    show_progress ? progress : undefined,
    pending,
  ] as const;
};

// adds meta object to request body
const add_meta_to_args = <M extends ApiMethod>(
  args: ApiMethodParameters<M>,
  meta: object
) => {
  for (let i = 0; i < args.length; i++) {
    // @ts-expect-error:
    if (args[i] != null && typeof args[i] === "object" && args[i]["body"]) {
      // @ts-expect-error:
      if (!args[i].body.meta) {
        // @ts-expect-error:
        args[i].body.meta = meta;
        // @ts-expect-error:
      } else if (!args[i].body.meta.media) {
        // @ts-expect-error:
        args[i].body.meta.media = meta.media;
      } else {
        // @ts-expect-error:
        args[i].body.meta.media = { ...args[i].body.meta.media, ...meta.media };
      }
      return args;
    }
  }
  return args;
};

// provides logic for submitting a form with a media upload; uploads media after the
// form submission succeeds
// returns:
// - callback for changing the upload file
// - callback for submitting the form
// - request tracking
// - pending state for media upload
export const useUntrackedRequestWithMedia = <
  T extends keyof MetaTypes,
  P extends any[],
  R extends DocBase & { data: any }
>(
  type: T,
  property: MediaPropertiesOf<MetaTypes[T]>,
  sendRequest: (...params: P) => Promise<R>,
  file: FileUploadData | undefined,
  media_required?: boolean,
  show_progress?: boolean
) => {
  const [record, setRecord, record_ref] = useStateRef<R | undefined>(undefined);
  const no_media = hooks.useRef(false);
  const [media_pending, set_media_pending, media_pending_ref] =
    useStateRef(false);

  const [upload_file_, set_url, progress] = useMediaUpload(
    type,
    record?.data?.id,
    property,
    file,
    false,
    show_progress
  );
  // upload file with request tracking
  const upload_file = hooks.useCallback(
    (id?: string) => {
      set_media_pending(true);
      return upload_file_(id)
        .then(() => {
          set_media_pending(false);
        })
        .catch(() => {
          set_media_pending(false);
        });
    },
    [upload_file_, set_media_pending]
  );
  const file_ref = useValueRef(file);

  // callback for uploading media
  const onSubmitMedia = hooks.useCallback(
    (res: R): Promise<R> => {
      const file_ = file_ref.current;
      // don't upload if no file is selected
      if (!file_) {
        console.warn(`No media selected for upload.`);
        if (media_required) {
          // reject if media is required
          return Promise.reject({
            message: `Missing media for required field "${property}"`,
          });
        } else {
          return Promise.resolve(res);
        }
        // don't upload if an upload is already in progress
      } else if (media_pending_ref.current) {
        return Promise.reject({
          code: "api/request-already-sent",
          message: "This request is already in flight.",
        });
      }
      return upload_file(res?.data?.id).then(() => {
        const key = get_url_key_for_media_property(TYPES[type], property);
        // this response won't have the media url, so use the file data so the media
        // appears in the UI without having to re-fetch the record
        return {
          ...res,
          data: {
            ...res?.data,
            // create a blob url so we don't have to pass the whole ##MB file through props
            [key]: get_image_blob_url(file_.data, file_.mime),
            [property]: { id: "" },
          },
        };
      });
    },
    [file_ref, upload_file, setRecord, media_pending_ref]
  );

  // on form submit
  const onSubmit = hooks.useCallback(
    (...args: P): Promise<R> => {
      const file_ = file_ref.current;
      if (media_required && !file_) {
        return Promise.reject({
          message: `Missing media for required field "${property}"`,
        });
      }
      // if the form submission hasn't previously succeeded
      if (!record_ref.current) {
        // if a file is selected, add meta to the req body to get the upload url
        if (file_ && file_.mime) {
          args = add_meta_to_args<any>(args, {
            media: {
              [property]: {
                mime_type: file_.mime,
              },
            },
          }) as P;
        } else {
          no_media.current = true;
        }
        return sendRequest(...args).then(response => {
          // if the response has an upload url, store it
          const url = path_or(
            "",
            ["meta", "media", property as string | number, "upload_url"],
            response
          );
          if (url) {
            set_url(url);
          }
          setRecord(response);
          if (no_media.current) {
            return response;
          }

          return onSubmitMedia(response);
        });
      }
      return onSubmitMedia(record_ref.current);
    },
    [
      setRecord,
      set_url,
      onSubmitMedia,
      sendRequest,
      media_required,
      file_ref,
      upload_file,
    ]
  );

  return [onSubmit, media_pending, progress] as const;
};

export const useRequestWithMedia = <
  T extends keyof MetaTypes,
  P extends any[],
  R extends DocBase & { data: any }
>(
  type: T,
  property: MediaPropertiesOf<MetaTypes[T]>,
  sendRequest: (...params: P) => Promise<R>,
  file: FileUploadData | undefined,
  media_required?: boolean,
  show_progress?: boolean
) => {
  const [onSubmit, media_pending, progress] = useUntrackedRequestWithMedia(
    type,
    property,
    sendRequest,
    file,
    media_required,
    show_progress
  );
  const submit = useRequest(onSubmit);
  return [...submit, media_pending, progress] as const;
};

export const useLinkPreview = (link: string) => {
  const get_link_meta = hooks.useCallback<
    () => Promise<ZendeskLinkMeta | VideoLinkMeta | null>
  >(
    async () =>
      (await scrape_video_link(
        link,
        // @ts-expect-error:
        window.thread?.config?.google?.youtubeApiKey
      )) || (await scrape_zendesk_link(link)),
    [link]
  );
  const [result, make_request, status] = useControlledRequest(
    get_link_meta,
    undefined,
    true
  );
  hooks.useEffect(() => {
    make_request();
  }, [make_request]);

  return [result, status] as const;
};
