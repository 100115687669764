import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

/**
 * layout classNames:
 *   - `normal-block`: content inside white block with gray margins
 *   - `full-width-block`: content inside white block that spans entire viewport width
 *   - `no-container`: margins but no background styling for content container
 *   - `no-margins`: no margins or background styling for content container
 *   - `card`: both header and body in limited-width white rounded box
 * */
export const PageContent: Preact.FunctionComponent<
  MaybeClass & {
    id: string;
  }
> = ({ id, className, children }) => (
  <section
    data-page={id}
    className={`page-content${maybeClassName(className)}`}
    id={`page-${id}`}
  >
    {children}
  </section>
);
