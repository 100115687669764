import { ApiMethodParameters } from "@thrive-web/core";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { CommunityCardLoading, CommunityCard } from "~/view/components";
import {
  PageBody,
  PageContent,
  PageHeader,
  useRenderDynamicListWithPagedFetch,
  COMMUNITIES,
} from "@thrive-web/ui-components";
import { Community } from "@thrive-web/ui-api";
import {
  useApiFetchPaged,
  useAppUser,
  useDocumentTitle,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

export const Communities: Preact.FunctionComponent<RoutePageProps> = () => {
  useDocumentTitle(() => make_title(["Communities"]), []);

  const user = useAppUser();
  const { list, dispatch } = useContext(COMMUNITIES);
  const comm_params = useMemo<ApiMethodParameters<"GET", "Community", false>>(
    () => ({
      query: {
        filter: [
          ["=", ["this", "Community:has_member"], ["id", user!.id as string]],
        ],
        include: ["cover_image", "avatar_image"],
      },
    }),
    [user?.id]
  );
  const fetchCommunities = useApiFetchPaged("getCommunities", comm_params);

  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem) => (
      <CommunityList communities={result} loadMoreElem={load_more_elem} />
    ),
    [],
    fetchCommunities,
    undefined,
    { PendingView: CommunityListLoading, limit: 10 }
  );

  return (
    <PageContent
      id="communities"
      data-page="communities-list"
      className="list-page community-page no-separate-header"
    >
      <PageHeader title={<h1>Your Communities</h1>} />
      <PageBody>{content}</PageBody>
    </PageContent>
  );
};

export const CommunityList: Preact.FunctionComponent<{
  communities: readonly Community[];
  loadMoreElem?: Preact.VNode | null;
}> = ({ communities, loadMoreElem }) => (
  <div className="community-list">
    {communities.map(comm => (
      <div key={comm.id} className="community-list__item">
        <CommunityCard community={comm} />
      </div>
    ))}
    {loadMoreElem && (
      <div className="community-list__item load-more">{loadMoreElem}</div>
    )}
  </div>
);

export const CommunityListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <div className="community-list loading-item__list">
      {items.map((_, i) => (
        <div key={i} className="community-list__item loading-item">
          <CommunityCardLoading />
        </div>
      ))}
    </div>
  );
};
