import * as Preact from "preact";
import { Community } from "@thrive-web/ui-api";
import { asSubroute } from "@thrive-web/ui-components";
import { useDocumentTitle } from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

export const CommunityAbout: Preact.FunctionComponent<
  RoutePageProps & {
    community: Community;
  }
> = ({ community }) => {
  useDocumentTitle(
    () => (community.name ? make_title([community.name, "About"]) : null),
    [community.name]
  );

  // todo: make this not hardcoded
  return (
    <div className="card community-about">
      <h4>The Mission</h4>

      <p>
        Thread harnesses the power of relationships to create a new social
        fabric of diverse individuals deeply engaged with young people facing
        the most significant opportunity and achievement gaps. Our community is
        committed to ending social isolation and building a more equitable
        culture in which everyone thrives.
      </p>

      <h4>The Challenge: A Disconnected Community</h4>

      <p>
        Baltimore neighborhoods are dramatically different than they were 60
        years ago. The Supreme Court’s decision in Brown v. Board of Education
        (1954), the Civil Rights Act of 1968, and Baltimore’s loss of
        manufacturing jobs have caused neighborhoods that were once racially
        segregated but socioeconomically diverse to become polarized by both
        race and class. This disconnectedness has resulted in increased crime
        and poor educational and economic outcomes.
      </p>

      <h4>The Solution: Creating Connection</h4>

      <p>
        Thread weaves a new social fabric by connecting students, university and
        community-based volunteers, and collaborators. By radically and
        permanently reconfiguring the social support structure of all involved,
        Thread breaks the cycle of crime, poor educational and economic outcomes
        and replaces it with a new cycle of educational attainment, service and
        social well-being.
      </p>

      <h4>The Success</h4>

      <p>
        Thread is changing the fabric of Baltimore – and the future. Just this
        year, 527 students and alumni, 1200+ volunteers and 400+ collaborators
        have spent over 30,000 hours together, building relationships that
        transcend barriers and enrich the lives of everyone involved. Thread
        students have performed thousands of hours of community service,
        primarily in Baltimore City, but also globally, joining our volunteers
        and collaborators as they become the next generation of agents of
        change. The relationships that Thread has woven have given our students
        an even greater opportunity to defy the odds.
      </p>
    </div>
  );
};

export const CommunityAboutPage = asSubroute(CommunityAbout);
