import * as Preact from "preact";
import { DropdownMenu, Icon } from "@thrive-web/ui-components";
import { maybeClassName } from "@thrive-web/ui-utils";

const popover_props = {
  portalClassName: "with-icon__compound--popover",
  mountLocal: true,
};

/** multiple buttons within a single "pill" ui element */
export const CompoundButton: Preact.FunctionComponent<
  MaybeClass & {
    items: (Preact.VNode | null)[];
    // when true, only one segment of the button is initially visible, the rest expand out
    // of the button when hovered/focused
    collapse?: boolean;
  }
> = ({ items, collapse, className }) => {
  const content = collapse
    ? [
        <DropdownMenu
          className="compound-button__menu"
          items={items.slice(1)}
          button={<Icon name="more" />}
          buttonClassName="button filled gray"
          popoverProps={popover_props}
        >
          {items[0]}
        </DropdownMenu>,
      ]
    : items;
  return (
    <div
      className={`button compound-button${maybeClassName(className)}`}
      data-collapse={`${!!collapse}`}
    >
      {
        // @ts-expect-error:
        content.map((item, i) =>
          item ? (
            <div key={i} className="compound-button__item">
              {item}
            </div>
          ) : null
        )
      }
    </div>
  );
};
