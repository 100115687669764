import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { ApiMethodParameters } from "@thrive-web/core";
import {
  useApiFetchPaged,
  useAppUser,
  usePreviousValue,
} from "@thrive-web/ui-hooks";
import { get_url_for_entity } from "@thrive-web/ui-utils";
import {
  ACTIVITY_FEED_POST_ID,
  ActivityFeed,
  useRenderDynamicListWithPagedFetch,
  PostListLoading,
  userCanInteract,
} from "@thrive-web/ui-components";
import { USER_ACTIVITY, USER_USER } from "~/view/components";

const PROFILE_POST_DETAIL_PATH = "people/:user_id/post/:post_id";
const PostIdProvider = ACTIVITY_FEED_POST_ID.Provider;

export const UserActivity: Preact.FunctionComponent<{
  userId: string;
  postId?: string;
}> = ({ userId, postId }) => {
  const user = useContext(USER_USER);
  const self = useAppUser();

  const id_ref = useRef(userId);
  id_ref.current = userId;
  const prev_id = usePreviousValue(userId);

  const detailExitPath = useMemo(
    () => (user ? `${get_url_for_entity(user)}` : "/"),
    [user]
  );

  const req_params = useMemo<ApiMethodParameters<"GET", "Post", false>>(
    () => ({
      query: {
        filter: [["=", ["this", "Post:created_by"], ["id", userId]]],
        include: [
          "has_touchpoint",
          "has_reaction",
          "has_expense",
          "mood",
          "photo",
          "event.Event:cover_image",
          "created_by.User:profile_picture",
          "has_tagged_user.User:profile_picture",
          "has_comment.Comment:created_by.User:profile_picture",
          "posted_to",
        ],
        sort: [{ by: "created_at", dir: "desc" }],
      },
    }),
    [userId]
  );
  const getPostsReq = useApiFetchPaged("getPosts", req_params);
  const getPosts = useCallback(
    (offset: number, limit?: number) =>
      getPostsReq(offset, limit).then(res => {
        prev_id.current = id_ref.current;
        return res;
      }),
    [getPostsReq]
  );
  const can_interact = userCanInteract();

  const { list, dispatch } = useContext(USER_ACTIVITY);
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more) => (
      <ActivityFeed
        posts={result}
        showScope={true}
        detailPath={PROFILE_POST_DETAIL_PATH}
        detailExitPath={detailExitPath}
        dynamicListCtx={USER_ACTIVITY}
        loadMoreElem={load_more}
        canInteract={can_interact}
      />
    ),
    [detailExitPath, userId],
    getPosts,
    undefined,
    {
      PendingView: PostListLoading,
      keepViewOnUpdate: userId === prev_id.current,
    }
  );

  useLayoutEffect(() => {
    return () => {
      dispatch.reset(null);
    };
  }, [userId]);

  if (!user || !self) {
    return null;
  }

  return (
    <div className="user-profile__activity">
      <div className="user-profile__activity__header">
        <h2>{userId === self.id ? "Your" : `${user.first_name}'s`} Posts</h2>
      </div>
      <div className="user-profile__activity__list">
        <PostIdProvider value={postId}>{content}</PostIdProvider>
      </div>
    </div>
  );
};
