import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { Post, WritePost } from "@thrive-web/ui-api";
import {
  useApiMethod,
  useModal,
  useModalAsRoute,
  useRenderPropsFunction,
} from "@thrive-web/ui-hooks";
import {
  createModal,
  DefaultModalContent,
  DeleteModalBody,
  DynamicListCtxSpec,
  PostDetail,
  PostEdit,
  PostEditProps,
  PostFormBaseProps,
  PostFormPage,
  PostCreate,
} from "@thrive-web/ui-components";

export const PostCreateModal: Preact.FunctionComponent<
  PostFormBaseProps &
    ModalBodyProps & { initialPage?: PostFormPage; initialData?: WritePost }
> = ({ open, dismiss, ...props }) => {
  return createModal(
    {
      id: "create-post",
      body: PostCreate,
      className: "post__create__modal card card-stack",
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      showCloseButton: true,
      bodyProps: props,
    },
    open,
    dismiss,
    true
  );
};

export const PostDetailModal: Preact.FunctionComponent<{
  target?: Post;
  path: string;
  targetId: string;
  exitPath: string;
  showScope?: boolean;
  dynamicListCtx: Preact.Context<DynamicListCtxSpec<Post, boolean, any[]>>;
}> = ({ target, targetId, ...props }) => {
  const bodyProps = useMemo(
    () => ({
      post_id: targetId,
      data: target,
      ...props,
    }),
    [target, targetId, props]
  );

  return useModalAsRoute(
    {
      id: "post-detail-modal",
      innerClassName: "card card-stack modal-form post-detail",
      body: PostDetail,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      overrideScroll: true,
      bodyProps,
    },
    props.path
  );
};

export const PostEditModal: Preact.FunctionComponent<
  PostEditProps & {
    onClose: () => void;
  }
> = ({ target, onFinish, onClose, initialPage }) => {
  const bodyProps = useMemo(
    () => ({ onFinish, initialPage, target }),
    [target, onFinish, initialPage]
  );

  const [editPostModal, openEditPostModal] = useModal(
    {
      id: "update-posts-modal",
      innerClassName: "card card-stack modal-form post-update",
      body: PostEdit,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      bodyProps,
    },
    onClose,
    true
  );

  useEffect(() => {
    if (!!target) {
      analytics.log_event(
        analytics.EVENTS.edit_post,
        undefined,
        undefined,
        true
      );
    }
    openEditPostModal(!!target);
  }, [!!target]);

  return editPostModal;
};

export const PostDeleteModal: Preact.FunctionComponent<{
  target?: Post | null;
  onFinish: (target: Post) => void;
  onClose: () => void;
}> = ({ target, onFinish, onClose }) => {
  const deletePost = useApiMethod("deletePost");
  const submitDeletePost = useCallback(() => {
    if (!target) {
      return Promise.resolve();
    }
    return deletePost(target.id, {}).then(res => {
      onFinish(target);
      return res;
    });
  }, [deletePost, target, onFinish]);

  const DeletePostModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) =>
      target ? (
        <DefaultModalContent title="Delete Post" closeButton={closeButton}>
          <DeleteModalBody deleteRecord={submitDeletePost} {...props}>
            Are you sure you want to delete this post?
          </DeleteModalBody>
        </DefaultModalContent>
      ) : null,
    "PostDelete-ModalBody",
    [target, submitDeletePost]
  );

  const [deletePostModal, openDeletePostModal] = useModal(
    {
      id: "create-posts-modal",
      innerClassName: "card card-stack modal-form",
      body: DeletePostModalBody,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
    },
    onClose
  );

  useEffect(() => {
    openDeletePostModal(!!target);
  }, [!!target]);

  return deletePostModal;
};
