import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import {
  DefaultModalContent,
  ErrorMessage,
  FileUpload,
  MaybePlaceholderAvatar,
  RequestButton,
} from "@thrive-web/ui-components";
import {
  useAppUserPropertyUpdate,
  useMediaUpload,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";

export interface ProfilePhotoFormProps {
  onFinish: (key: string, data: any) => void;
  user: User;
}

// encapsulates logic for the media upload request and updating the user record in app state
export const useProfilePhotoForm = (
  onFinish: (key: string, data: any) => void,
  user: User,
  dismiss?: () => void
) => {
  const [profile_picture, set_file] = useStateIfMounted<
    FileUploadData | undefined
  >(
    user.profile_picture_url
      ? { data: user.profile_picture_url, name: "", mime: "" }
      : undefined
  );

  const updateAppUser = useAppUserPropertyUpdate();

  const [upload_req, , progress, get_url_pending] = useMediaUpload(
    "User",
    user.id,
    "profile_picture",
    profile_picture,
    true,
    true
  );
  const [upload, { pending, success, error }] = useRequest(upload_req);

  const onSubmit = useCallback(
    e => {
      if (e.target.checkValidity() && !get_url_pending) {
        e.preventDefault();
        upload()
          .then(() =>
            // upon successful upload, update the user record in app state
            updateAppUser("profile_picture_url", profile_picture?.data)
          )
          .then(() => {
            setTimeout(() => {
              dismiss?.();
              onFinish("photo", profile_picture);
            }, 800);
          });
      }
    },
    [onFinish, profile_picture, get_url_pending]
  );

  return [
    profile_picture,
    set_file,
    onSubmit,
    progress,
    pending,
    success,
    error,
    get_url_pending,
  ] as const;
};

export const ProfilePhotoModal: Preact.FunctionComponent<
  ModalBodyProps & ProfilePhotoFormProps
> = ({ closeButton, onFinish, user, dismiss }) => {
  const [
    profile_picture,
    set_file,
    onSubmit,
    progress,
    pending,
    success,
    error,
    get_url_pending,
  ] = useProfilePhotoForm(onFinish, user, dismiss);

  // true if the image has changed
  const is_new = user.profile_picture_url !== profile_picture?.data;

  return (
    <DefaultModalContent title="Profile Picture" closeButton={closeButton}>
      <form onSubmit={onSubmit}>
        <div className="register-details-page__profile-picture">
          <FileUpload
            name="profile_picture"
            required={true}
            onChange={set_file}
            Button={ProfilePhotoModalButton}
            value={profile_picture}
            getUrlPending={
              profile_picture?.data !== user.profile_picture_url &&
              get_url_pending
            }
            allowCrop={is_new && !!profile_picture?.data}
          />
        </div>
        <div className="register-details-page__submit modal__footer">
          <div className="modal__footer__left">
            {error && (
              <ErrorMessage>
                {" "}
                Your profile picture failed to upload. Please try again or
                select another image. (Error: {error.message})
              </ErrorMessage>
            )}
          </div>
          <div className="modal__footer__right">
            <RequestButton
              pending={pending}
              success={success}
              className="filled gray"
              type="submit"
              successText="Success!"
              progress={progress}
              disabled={get_url_pending}
            >
              Upload Photo
            </RequestButton>
          </div>
        </div>
      </form>
    </DefaultModalContent>
  );
};

// button used by the file input
export const ProfilePhotoModalButton: Preact.FunctionComponent<FileUploadButtonProps> =
  ({ file, clickInput, className, getUrlPending, children }) => {
    return (
      <div className="profile-picture-upload__modal__content">
        {children}
        <div
          className={`profile-builder__photo__modal__button${maybeClassName(
            className
          )}`}
        >
          {
            // @ts-expect-error: no name
            <MaybePlaceholderAvatar size="xl" url={file?.data} />
          }
          <RequestButton
            className="filled gray"
            type="button"
            onClick={clickInput}
            pending={!!getUrlPending}
          >
            Browse...
          </RequestButton>
          <span>Select an image</span>
        </div>
      </div>
    );
  };
