import * as Moment from "@types/moment";
import { moment } from "@thrive-web/ui-common";

export const DATE_PATTERN = "[12][90][0-9]{2}-[01][0-9]-[0-3][0-9]";
export const TIME_PATTERN = "[012][0-9]:[0-5][0-9]";
export const DATETIME_PATTERN = `${DATE_PATTERN}T${TIME_PATTERN}`;
export const ISO_PATTERN =
  "[12][90][0-9]{2}-[01][0-9]-[0-3][0-9]T[012][0-9](:[0-5][0-9]){2}\\.\\d{3}Z";
export const DATE_REGEX = new RegExp(DATE_PATTERN);
export const TIME_REGEX = new RegExp(TIME_PATTERN);
export const DATETIME_REGEX = new RegExp(DATETIME_PATTERN);
export const ISO_REGEX = new RegExp(ISO_PATTERN);

export const TIME_PARTS_MIN: TimeParts = { hours: 1, minutes: 0, am: "AM" };
export const TIME_PARTS_MAX: TimeParts = { hours: 12, minutes: 59, am: "PM" };
export const DATE_PARTS_MIN: DateParts = { day: 1, month: 1, year: 1900 };
export const DATE_PARTS_MAX: DateParts = {
  day: 31,
  month: 12,
  year: new Date().getFullYear(),
};

export const now = (timezone_offset?: boolean) => {
  return timezone_offset ? with_timezone_offset(new Date()) : new Date();
};
export const round_to_minutes = (date: string): string =>
  date.replace(/:\d{2}\.\d{3}Z/, ":00.000Z");

export const get_timezone_offset = () => new Date().getTimezoneOffset();
export const with_timezone_offset = (d: Date): Date =>
  new Date(d.getTime() + get_timezone_offset() * -60 * 1000);

export const get_days_in_month = (
  month: number = new Date().getMonth(),
  year: number = new Date().getFullYear()
): number | null => {
  const date = new Date(year, month, 0);
  return date.toString() === "Invalid Date" ? null : date.getDate();
};

export const shift_date_by = (
  value: Date,
  offset: number,
  unit: "day" | "hour" | "minute"
): Date => {
  offset *= 1000 * 60;
  if (unit !== "minute") {
    offset *= 60;
  }
  if (unit !== "hour") {
    offset *= 24;
  }
  return new Date(value.getTime() + offset);
};

export const padZeros = (value?: number, count?: number): string | undefined =>
  value != null ? `${value}`.padStart(count || 2, "0") : undefined;
export const padDashes = (
  value?: number,
  count: number = 4
): string | undefined =>
  value != null ? `${value}`.padEnd(count, "-") : undefined;

export const to_local_datetime = (date: string) =>
  moment(date).local().format("YYYY-MM-DDTHH:mm");

export const str_to_input_values = (
  value: string
): { date: string; time: string } => {
  const m = moment(value).local();
  if (!m.isValid() || (!ISO_REGEX.test(value) && !DATETIME_REGEX.test(value))) {
    return { date: "", time: "" };
  }
  return {
    date: m.format("YYYY-MM-DD"),
    time: m.format("HH:mm"),
  };
};

export const input_values_to_local_datetime = (date: string, time: string) =>
  validate_date(date) && validate_time(time) ? `${date}T${time}` : undefined;

export const input_values_to_iso = (date: string, time: string) =>
  validate_date(date) && validate_time(time)
    ? moment(`${date}T${time}`).toISOString()
    : undefined;

/** Value: HH:mm */
export const time_to_comparable = (value?: string): number | null => {
  if (!value || !TIME_REGEX.test(value)) {
    return null;
  }
  const [hours, minutes] = value.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
};

export const validate_date = (
  value: string,
  input?: HTMLInputElement,
  min?: Moment.Moment,
  max?: Moment.Moment
) => {
  try {
    const date = moment(value);
    const valid =
      value.length === 10 &&
      DATE_REGEX.test(value) &&
      date.isValid() &&
      (min ? min.isSameOrBefore(date, "m") : true) &&
      (max ? max.isSameOrAfter(date, "m") : true);
    if (input) {
      let str = "";
      if (!valid) {
        if (min && min.isSameOrBefore(date, "m")) {
          str = `Please enter a date that is greater than or equal to ${min.toLocaleString()}`;
        } else if (max && max.isSameOrAfter(date, "m")) {
          str = `Please enter a date that does not exceed ${max.toLocaleString()}`;
        } else {
          str = "Please enter a valid date.";
        }
      }
      input.setCustomValidity(str);
    }
    return valid;
  } catch {
    input && input.setCustomValidity("Please enter a valid date.");
    return false;
  }
};

export const validate_time = (
  value: string,
  input?: HTMLInputElement,
  min?: string,
  max?: string
) => {
  const min_num = min ? time_to_comparable(min) : null;
  const max_num = max ? time_to_comparable(max) : null;
  try {
    const time = time_to_comparable(value);
    const valid =
      time != null &&
      time < 24 * 60 &&
      (min_num != null ? time >= min_num : true) &&
      (max_num != null ? time <= max_num : true);
    if (input) {
      let str = "";
      if (!valid) {
        if (time != null && min_num != null ? time < min_num : false) {
          str = `Please enter a date and time that is greater than or equal to ${min}`;
        } else if (
          time != null && time < 24 * 60 && max_num != null
            ? time > max_num
            : false
        ) {
          str = `Please enter a time that does not exceed ${max}`;
        } else {
          str = "Please enter a valid time.";
        }
      }
      input.setCustomValidity(str);
    }
    return valid;
  } catch {
    input && input.setCustomValidity("Please enter a valid time.");
    return false;
  }
};

// make sure the day is valid and is between 1901 and the current day
export const validate_datetime = (
  value: string,
  input?: HTMLInputElement,
  min?: Moment.Moment,
  max?: Moment.Moment
) => {
  try {
    const val = moment(value);
    const valid =
      ISO_REGEX.test(value) &&
      val.isValid() &&
      !((min && min.isAfter(val, "m")) || (max && max.isBefore(val, "m")));
    if (input) {
      let str = "";
      if (!valid) {
        if (min && min.isAfter(val, "m")) {
          str = `Please enter a date and time greater than or equal to ${min.format(
            "lll"
          )}`;
        } else if (max && max.isBefore(val, "m")) {
          str = `Please enter a date and time that does not exceed ${max.format(
            "lll"
          )}`;
        } else {
          str = "Please enter a valid date and time.";
        }
      }
      input.setCustomValidity(str);
    }
    return valid;
  } catch {
    input && input.setCustomValidity("Please enter a valid date and time.");
    return false;
  }
};

// compute min/max for each part of the date/time based on limits and current value
export const compute_valid_range = (
  time?: string,
  date?: string,
  min?: string,
  max?: string
): DateTimeRange => {
  const mins: any = min ? str_to_input_values(min) : {};
  const maxs: any = max ? str_to_input_values(max) : {};
  const date_range: any = { min: mins.date, max: maxs.date };
  const time_range: any = {};

  const m_date = moment(date);
  const m_min_date = moment(mins.date);
  const m_max_date = moment(maxs.date);

  if (date && mins.date && mins.time && m_min_date.isSame(m_date)) {
    time_range.min = mins.time;
  } else {
    date_range.min = mins.date;
    const _time = time_to_comparable(time);
    const min_time = time_to_comparable(mins.time);
    if (_time != null && min_time != null && _time <= min_time && mins.date) {
      date_range.min = str_to_input_values(
        m_min_date.clone().add(1, "day").toISOString()
      ).date;
    }
  }
  if (date && maxs.date && maxs.time && m_max_date.isSame(m_date)) {
    time_range.max = maxs.time;
  } else {
    date_range.max = maxs.date;
    const _time = time_to_comparable(time);
    const max_time = time_to_comparable(maxs.time);
    if (_time != null && max_time != null && _time >= max_time && maxs.date) {
      date_range.max = str_to_input_values(
        m_max_date.clone().subtract(1, "day").toISOString()
      ).date;
    }
  }
  return { date_range, time_range };
};

export const format_day_range = (range: Partial<DateRange>): string => {
  let to, from;
  if (range.from) {
    from = moment(range.from);
  }
  if (range.to) {
    to = moment(range.to);
  }
  if (!to && !from) {
    return "--";
  }
  const format_str =
    moment(to).year() !== moment(from).year() ? "MMM D, YYYY" : "MMM D";
  if (to && from) {
    if (to.isSame(from, "day")) {
      return to.format("MMM D, YYYY");
    }
    return `${from.format(format_str)} - ${to.format(format_str)}`;
  }
  if (to) {
    return `Before ${to.format(format_str)}`;
  }
  return `After ${from.format(format_str)}`;
};

// https://stackoverflow.com/a/10199306
export const supportsInputType = (type: "date" | "time" | "datetime-local") => {
  const input = document.createElement("input");
  input.setAttribute("type", type);

  const invalid_value = "invalid";
  input.setAttribute("value", invalid_value);

  return input.value !== invalid_value;
};
