import { useAppUser } from "@thrive-web/ui-hooks";
import { user_is_group_admin } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import {
  DefaultModalContent,
  GROUP_DETAIL_CONTEXTS,
  GROUP_DETAIL_LIST_CONTEXTS,
  GroupMemberInvite,
} from "@thrive-web/ui-components";
import { useContext, useMemo } from "preact/hooks";

export const GroupMemberInviteModalBody: Preact.FunctionComponent<ModalBodyProps> =
  ({ dismiss, closeButton }) => {
    const self = useAppUser();
    const group = useContext(GROUP_DETAIL_CONTEXTS.group);
    const { list: members, dispatch } = useContext(
      GROUP_DETAIL_LIST_CONTEXTS.members
    );
    const is_admin = useMemo(
      () => !!self && !!group && user_is_group_admin(self, group),
      [self?.id, group]
    );

    return group && members ? (
      <DefaultModalContent title="Invite a Member" closeButton={closeButton}>
        <div className="group-members__modal__body">
          <GroupMemberInvite
            onFinish={dismiss}
            members={members}
            addMember={dispatch.add}
            group_id={group.id}
            allowLink={is_admin || !!group.can_members_invite}
            isAdmin={is_admin}
          />
        </div>
      </DefaultModalContent>
    ) : null;
  };
