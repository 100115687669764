import * as Preact from "preact";
import { useCallback, useContext, useEffect } from "preact/hooks";
import { Event, WritePost } from "@thrive-web/ui-api";
import { should_leave } from "@thrive-web/ui-model";
import {
  MODERATED_COMMUNITIES,
  PostCreateModal,
  WithPostScopeCtx,
} from "@thrive-web/ui-components";
import { useStateIfMounted } from "@thrive-web/ui-hooks";

const noop = () => {};
export const EventShareModal: Preact.FunctionComponent<{
  event?: Event;
  onClose: () => void;
}> = ({ onClose, event }) => {
  const { list: mngd_comms } = useContext(MODERATED_COMMUNITIES);
  const [open, set_open] = useStateIfMounted(false);
  const [initial_data, set_initial_data] = useStateIfMounted<
    WritePost | undefined
  >(undefined);

  // const open_modal = useCallback(() => set_open(true), [set_open])
  const close_modal = useCallback(() => {
    if (should_leave()) {
      set_open(false);
      set_initial_data(undefined);
      onClose();
    }
  }, [set_open, onClose]);

  useEffect(() => {
    if (event && !open) {
      set_initial_data({ event });
      set_open(true);
    }
  }, [event]);

  return (
    <WithPostScopeCtx>
      <PostCreateModal
        onFinish={noop}
        dismiss={close_modal}
        open={open}
        initialData={initial_data}
        allowScopeChange={true}
        allowCommunityPosts={true}
        moderatedCommunities={mngd_comms}
      />
    </WithPostScopeCtx>
  );
};
