import { RELATIONSHIP_GROUP_MEMBER_LIMIT } from "@thrive-web/ui-constants";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { GroupConnectionScoreSidebar } from "~/view/components";
import {
  AppLink,
  asSubroute,
  SidebarSection,
  SidebarSectionProps,
  PageSidebar,
  GROUP_GROUP,
  GroupHealthGraph,
} from "@thrive-web/ui-components";
import { get_url_for_entity } from "@thrive-web/ui-utils";
import { GroupSidebarTodos } from "../Todos/GroupSidebarTodos";

const create_group_sidebar_sections = (group: Group): SidebarSectionProps[] => {
  const items: SidebarSectionProps[] = [];
  if (group.has_relationship_management) {
    items.push({
      name: "Group Connection Score",
      children: (
        <GroupConnectionScoreSidebar
          className="card"
          score={group.score || 0}
          showLevelUpAnim={true}
        />
      ),
    });
  }
  if (
    group.has_member?.length &&
    group.has_member.length > 1 &&
    // hide the graph if there are too many members
    group.has_member.length <= RELATIONSHIP_GROUP_MEMBER_LIMIT
  ) {
    items.push({
      name: "Relationship Health",
      // moreLink: <AppLink href={`${url}/members`}>View All</AppLink>,
      children: <GroupHealthGraph group={group} />,
    });
  }
  const url = get_url_for_entity(group);
  if (group.has_goals) {
    items.push({
      name: "Due Soon",
      moreLink: <AppLink href={`${url}/todos`}>View All</AppLink>,
      children: <GroupSidebarTodos group={group} />,
    });
  }
  return items;
};

export const GroupSidebarBase: Preact.FunctionComponent<RoutePageProps> =
  () => {
    const group = useContext(GROUP_GROUP);
    const items = useMemo(
      () => (group ? create_group_sidebar_sections(group) : []),
      [group]
    );
    return (
      <PageSidebar className="group-sidebar">
        {items.map((props, i) => (
          <SidebarSection {...props} key={i} />
        ))}
      </PageSidebar>
    );
  };

export const GroupSidebar = asSubroute(GroupSidebarBase);
