import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const PageSidebar: Preact.FunctionComponent<HTMLDivProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={`page-sidebar${maybeClassName(className)}`} {...props}>
      <div className="page-sidebar__content">{children}</div>
    </div>
  );
};

export interface SidebarSectionProps
  extends preact.RenderableProps<{
      name?: string;
      moreLink?: Preact.VNode;
    }>,
    MaybeClass {}

export const SidebarSection: Preact.FunctionComponent<SidebarSectionProps> = ({
  name,
  moreLink,
  className,
  children,
}) => (
  <div className={`sidebar__section${maybeClassName(className)}`}>
    {(name || moreLink) && (
      <div className="sidebar__section__header">
        {name && <div className="sidebar__section__title">{name}</div>}
        {moreLink && <div className="sidebar__section__link">{moreLink}</div>}
      </div>
    )}
    <div className="sidebar__section__body">{children}</div>
  </div>
);
