import { set_zendesk_report_field_id } from "@thrive-web/ui-components";
import * as config from "~/../site/env.json";

const initialize_zendesk_config = () => {
  set_zendesk_report_field_id(config.zendesk.reportedEntityIdFieldId);
};

export const initialize = () => {
  initialize_zendesk_config();
};
