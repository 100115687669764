import { ApiMethodParameters, media_url } from "@thrive-web/core";
import * as Preact from "preact";
import { Community, Group, RecordType, User } from "@thrive-web/ui-api";
import { RequestStatus } from "@thrive-web/ui-model";
import {
  getApiMethod,
  useApiFetchPaged,
  useAppUser,
  useRequest,
  useStateIfMounted,
  useStateRef,
  useTextFilter,
  useValueRef,
} from "@thrive-web/ui-hooks";
import {
  createNamedContext,
  get_guid_from_iri,
  hex_to_rgb,
} from "@thrive-web/ui-utils";
import {
  StateUpdater,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import {
  ButtonWithIcon,
  DefaultPendingView,
  EmptyList,
  post_scope_group_query,
  OptionsList,
  Popover,
  SearchBar,
  THRIVE_GROUPS,
  Tooltip,
  InfiniteScrollLoader,
  post_scope_comm_query,
  DefaultErrorView,
} from "@thrive-web/ui-components";
import { entity_has_type } from "@thrive-web/ui-common";
import { DEFAULT_GROUP_BACKGROUND_COLOR } from "@thrive-web/ui-constants";

export type ScopeItem = Group | Community;

export interface PostScopeCtx {
  list: ScopeItem[] | null;
  search: string;
  set_search: StateUpdater<string>;
  thread_only: boolean;
  set_thread_only: StateUpdater<boolean>;
  next_page?: {
    fetch: () => void;
    pending: boolean;
    error?: DisplayableError;
  };
  is_new_list: boolean;
  get_members: (g: ScopeItem) => Promise<readonly User[] | null>;
}

export const POST_SCOPE_OPTIONS = createNamedContext<PostScopeCtx>(
  {
    list: null,
    search: "",
    set_search: () => {},
    thread_only: false,
    set_thread_only: () => {},
    is_new_list: true,
    get_members: () => Promise.resolve(null),
  },
  "PostScopeOptions"
);
const Provider = POST_SCOPE_OPTIONS.Provider;

export const POST_SCOPE_FORCE_OPTIONS = createNamedContext<ScopeItem[] | null>(
  null,
  "PostScopeForceOptions"
);

// given a query and potentially a list, return data and controls for the list
// of groups/communities that the user can post to
const usePostScopeFetch = <
  M extends "getGroups" | "getCommunities",
  T extends "Group" | "Community" = M extends "getGroups"
    ? "Group"
    : "Community"
>(
  method: M,
  params: ApiMethodParameters<"GET", T, false>,
  forceList?: ScopeItem[] | null
) => {
  const [list, set_list, list_ref] = useStateRef<RecordType<T>[] | null>(
    // @ts-expect-error:
    forceList || null
  );
  const [total, set_total] = useStateIfMounted(-1);

  // @ts-expect-error:
  const fetch_req = useApiFetchPaged(method, params);
  // fetch, then store the data and total
  const fetch_then = useCallback(
    (offset: number) =>
      fetch_req(offset, params?.query?.limit || 15).then(({ data, meta }) => {
        set_total((meta?.total_result_count as number) ?? -1);
        set_list(
          offset === 0
            ? (data as RecordType<T>[])
            : (list_ref.current || []).concat(data as RecordType<T>[])
        );
        return data;
      }),
    [fetch_req, set_total, set_list]
  );

  const [fetch, { pending, error }] = useRequest(fetch_then, true) as [
    (offset: number) => Promise<RecordType<T>[]>,
    RequestStatus
  ];

  const fetch_next_page = useCallback(() => {
    fetch(list_ref.current?.length || 0);
  }, [fetch, set_list]);

  const next_page_ = useMemo(
    () => ({
      fetch: fetch_next_page,
      pending,
      error,
    }),
    [fetch_next_page, pending, error]
  );

  const reset = useCallback(
    (reset_list?: boolean) => {
      set_total(-1);
      reset_list && set_list(null);
    },
    [set_total, set_list]
  );

  // next page can be fetched if list is started and is not at end
  const next_page = list && list.length < total ? next_page_ : undefined;

  return [list, total, next_page, fetch, reset] as const;
};

export const WithPostScopeCtx = <T extends "Group" | "Community">({
  threadOnlyInitial = false,
  type,
  query,
  children,
}: Preact.RenderableProps<{
  threadOnlyInitial?: boolean;
  type?: T;
  query?: ApiMethodParameters<"GET", T, false>["query"];
}>): Preact.VNode | null => {
  const user = useAppUser();
  // when this list has a value, it will override everything else
  const forceList = useContext(POST_SCOPE_FORCE_OPTIONS);
  const forceListActive = forceList != undefined;
  const [search, set_search] = useStateIfMounted("");

  // FOR GROUPS
  const [thread_only, set_thread_only] = useStateIfMounted(threadOnlyInitial);
  const { list: thread_list, fetch: fetch_thread } = useContext(THRIVE_GROUPS);
  const test_filter = useCallback(
    (item: ScopeItem, regex: RegExp) => !!item.name && regex.test(item.name),
    []
  );
  const filtered_thread = useTextFilter(search, thread_list || [], test_filter);

  const fetch_all_params = useMemo(
    // @ts-expect-error:
    () => post_scope_group_query(user, search, query),
    [user?.id, search, query]
  );
  const [
    all_groups_list,
    all_groups_total,
    next_page_groups,
    fetch_all_groups,
    reset_all_groups,
  ] = usePostScopeFetch("getGroups", fetch_all_params, forceList);

  const get_members = useCallback((g: ScopeItem) => {
    const [, type_] = get_guid_from_iri(g.id);
    const get_members_req = getApiMethod(
      type_ === "Group" ? "getGroup" : "getCommunity"
    );
    return get_members_req(g.id, {
      query: {
        include: ["has_member.User:profile_picture"],
      },
    })
      .then(({ data }) => data.has_member || null)
      .catch(() => null);
  }, []);

  // fetch the appropriate group list
  useEffect(() => {
    if (forceListActive) {
      return;
    }
    if (thread_only) {
      reset_all_groups(true);
      fetch_thread();
    } else {
      reset_all_groups();
      fetch_all_groups(0).catch(err => {
        if (err.code !== "api/request-already-sent") {
          throw err;
        }
      });
    }
  }, [thread_only]);

  // when the fetch_all_groups function changes
  useEffect(() => {
    if (!thread_only && !forceListActive) {
      // refetch group list
      reset_all_groups();
      fetch_all_groups(0).catch(err => {
        if (err.code !== "api/request-already-sent") {
          throw err;
        }
      });
    }
  }, [fetch_all_groups]);

  const groups_list = thread_only
    ? thread_list
      ? filtered_thread
      : null
    : all_groups_list;

  const groups_total = thread_only
    ? thread_list
      ? filtered_thread.length
      : null
    : all_groups_total;

  // COMMUNITIES
  const fetch_comms_params = useMemo<ApiMethodParameters<"GET", "Community">>(
    () => ({
      query: {
        filter: post_scope_comm_query(user, search),
        limit: 10,
        include_count: true,
        include: ["avatar_image", "cover_image"],
      },
    }),
    [user?.id, search]
  );

  const [comms_list, comms_total, next_page_comms, fetch_comms, reset_comms] =
    usePostScopeFetch("getCommunities", fetch_comms_params, forceList);

  useEffect(() => {
    if (!forceListActive) {
      reset_comms();
      fetch_comms(0).catch(err => {
        if (err.code !== "api/request-already-sent") {
          throw err;
        }
      });
    }
  }, [fetch_comms]);

  const next_page = type === "Community" ? next_page_comms : next_page_groups;
  const list = type === "Community" ? comms_list : groups_list;
  const total = type === "Community" ? comms_total : groups_total;

  const value = useMemo(() => {
    if (forceListActive) {
      return {
        list: forceList,
        search: "",
        set_search,
        thread_only: true,
        set_thread_only,
        next_page: undefined,
        is_new_list: false,
        get_members,
      };
    }
    return {
      list,
      search,
      set_search,
      thread_only,
      set_thread_only,
      next_page,
      is_new_list: total === -1 || !list,
      get_members,
    };
  }, [
    forceList,
    list,
    search,
    set_search,
    thread_only,
    set_thread_only,
    next_page,
    total,
  ]);

  return <Provider value={value}>{children}</Provider>;
};

export const PostScopeSelector: Preact.FunctionComponent<{
  value?: ScopeItem;
  onChange: (scope: ScopeItem) => void;
  inputProps?: OptionsListControlProps<HTMLInputElement>;
  useDropdown?: boolean;
  showWarning?: boolean;
}> = ({ value, onChange, inputProps, useDropdown, showWarning }) => {
  const input_ref = useRef<HTMLInputElement | null>(null);
  const [open, setOpen_] = useStateIfMounted(!useDropdown);
  const {
    list: options,
    search,
    set_search,
    next_page,
    is_new_list,
  } = useContext(POST_SCOPE_OPTIONS);
  const search_ref = useValueRef(search);

  const onSearch = useCallback(
    (str: string) => {
      str = str?.trim();
      if (str !== search_ref.current) {
        set_search(str);
      }
    },
    [set_search]
  );

  // prevent scrolling to the top unless we load a new list
  const [no_scroll_list, set_no_scroll_list] = useStateIfMounted(!is_new_list);
  useEffect(() => {
    if (!is_new_list !== no_scroll_list) {
      set_no_scroll_list(!is_new_list);
    }
  }, [is_new_list]);

  // clear text input on open/close
  const setOpen = useCallback(
    new_open => {
      if (!useDropdown) {
        return;
      }
      setOpen_(new_open);
      set_search("");
    },
    [setOpen_, set_search, useDropdown]
  );

  useLayoutEffect(() => {
    if (!input_ref.current) {
      return;
    }
    if (open) {
      setTimeout(() => input_ref.current?.focus(), 50);
    } else {
      input_ref.current.blur();
    }
  }, [open, input_ref.current]);
  const [listeners, setListeners] = useStateIfMounted({});
  const allInputProps = useMemo(
    () => ({ ...inputProps, ...listeners, ref: input_ref }),
    [inputProps, listeners, input_ref]
  );

  const img_url = useMemo(
    () =>
      entity_has_type(value, "Community")
        ? media_url<"Community", "cover_image">(value, "cover_image", "small")
        : undefined,
    [value]
  );

  const content = !options ? (
    <div className="post__scope-selector__content">
      <DefaultPendingView />
    </div>
  ) : next_page?.error ? (
    <div className="post__scope-selector__content">
      <DefaultErrorView error={next_page.error} />
    </div>
  ) : (
    <div className="stack post__scope-selector__content">
      <div className="post__scope-selector__search modal__search-bar">
        <SearchBar
          pending={!!options && is_new_list}
          placeholder="Search"
          onSubmit={onSearch}
          inputProps={allInputProps}
          autoSubmitDelay={250}
          value={search}
        />
      </div>

      <OptionsList
        value={value}
        options={options}
        RenderItem={PostScopeSelectorItem}
        open={!useDropdown ? true : open}
        setOpen={setOpen}
        onSelect={onChange}
        setControlListeners={setListeners}
        className="stack__scrolling-content"
        emptyLabel={<EmptyList>No matching results for "{search}".</EmptyList>}
        noScrollOnListChange={no_scroll_list}
        loadMoreElem={
          next_page ? (
            <InfiniteScrollLoader
              nextPage={next_page.fetch}
              pending={next_page.pending}
              error={next_page.error}
            />
          ) : null
        }
      />
    </div>
  );
  if (!useDropdown) {
    return content;
  }

  // @ts-expect-error:
  const value_color = value?.background_color || value?.accent_color;

  return (
    <Popover
      className={`dropdown-menu post__scope-selector`}
      triggerComponent={
        <Tooltip
          text="Warning: Changing the group will remove the Tagged Users, Group Update, and Reimbursement from the post."
          disabled={!showWarning || open}
          defaultDirection="bottom"
        >
          <ButtonWithIcon
            className="post__form__scope__button pill filled gray"
            icon={
              value ? (
                img_url ? (
                  <img src={img_url} />
                ) : value_color ? (
                  <div
                    className="group__dot"
                    style={{ backgroundColor: value_color }}
                  />
                ) : (
                  <Preact.Fragment />
                )
              ) : (
                "add"
              )
            }
            side="left"
            onClick={() => setOpen(!open)}
            iconOpposite="disclosure"
          >
            {value ? value.name : "Select a Group"}
          </ButtonWithIcon>
        </Tooltip>
      }
      show={open}
      defaultDirection="bottom"
    >
      {content}
    </Popover>
  );
};

export const PostScopeSelectorItem: Preact.FunctionComponent<{
  item: ScopeItem;
  onSelect: (item: ScopeItem) => void;
  selectedItem?: ScopeItem;
}> = ({ item, onSelect, selectedItem }) => {
  const is_community = useMemo(
    () => entity_has_type(item, "Community"),
    [item.id]
  );
  const background_image = useMemo(
    () =>
      media_url(
        item,
        // @ts-expect-error:
        is_community ? "cover_image" : "background_image",
        "small"
      ),
    [item, is_community]
  );

  const style = useMemo(() => {
    const rgb = hex_to_rgb(
      (item as Group).background_color || (item as Community).accent_color
    );
    const backgroundColor =
      rgb === null
        ? DEFAULT_GROUP_BACKGROUND_COLOR
        : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;

    return {
      backgroundImage: background_image
        ? `url(${background_image})`
        : undefined,
      backgroundColor,
      "--color-overlay-background-color": backgroundColor,
    } as any;
  }, [is_community, background_image, (item as Group).background_color]);

  const avatar = useMemo(
    () =>
      // @ts-expect-error:
      is_community ? media_url(item, "avatar_image", "small") : undefined,
    [item, is_community]
  );

  const is_selected = item.id === selectedItem?.id;

  return (
    <button
      data-selected={is_selected}
      data-no-image={!background_image}
      className="post__scope-selector__item color-overlay"
      style={style}
      onClick={() => onSelect(item)}
    >
      <div className="post__scope-selector__item__left">
        {avatar ? (
          <div className="post__scope-selector__avatar">
            <img src={avatar} />
          </div>
        ) : null}
        <div className="post__scope-selector__title">
          <h4>{item.name}</h4>
        </div>
      </div>
      <div className="post__scope-selector__item__right" />
    </button>
  );
};
