import * as Preact from "preact";
import {
  useId,
  useStateIfMounted,
  useStateObject,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { useCallback, useEffect, useLayoutEffect } from "preact/hooks";

// fade transition from one element to another
export const TransitionOneWay: Preact.FunctionComponent<
  {
    from: Preact.VNode | null;
    to: Preact.VNode | null;
    // set to true when the transition should begin.
    // if the initial value is true, the "to" element will be shown, and the transition
    // will never play
    start: boolean;
  } & MaybeClass
> = ({ to, from, start, className }) => {
  const id = useId(undefined, "transition");
  const [started, set_started] = useStateIfMounted(false);
  // if start is initially true, anim is already "finished"
  const [finished, set_finished] = useStateIfMounted(start);
  const on_finish = useCallback(
    e => {
      if (e.target.id === id) {
        set_finished(true);
      }
    },
    [id]
  );

  // if start changes to true and anim isn't started, start the anim
  useEffect(() => {
    if (!started && start) {
      set_started(true);
    }
  }, [started, start]);

  return (
    <div className={`transition__container${maybeClassName(className)}`}>
      {!finished ? (
        <div
          id={id}
          className="transition__from"
          data-started={started}
          onAnimationEnd={started && !finished ? on_finish : undefined}
          onTransitionEnd={started && !finished ? on_finish : undefined}
        >
          {from}
        </div>
      ) : (
        <div
          className="transition__to"
          // don't play anim if anim is "finished" but never "started"
          data-no-anim={start && !started && finished}
        >
          {to}
        </div>
      )}
    </div>
  );
};

// fade transition between two elements in a set
export const Transition = <M extends ObjectOf<preact.VNode | null>>({
  page,
  pages,
  className,
  onFadeOut,
}: preact.RenderableProps<
  MaybeClass & {
    // key of current page
    page: keyof M;
    // dict of all pages
    pages: M;
    // callback invoked when a page finishes fading out (before new page fades in)
    onFadeOut?: () => void;
  }
>): preact.VNode<any> | null => {
  const id = useId(undefined, "transition");
  const prop_page = useValueRef(page);
  const [state, set_state] = useStateObject({
    // the page that is currently visible on the screen
    active_page: page,
    faded_in: true,
    faded_out: false,
  });

  const fade_out_callback = useValueRef(onFadeOut);

  const on_fade_out = useCallback(
    e => {
      // after the current page has become invisible, change active_page to the new page
      if (e.target.id === id) {
        set_state({
          active_page: prop_page.current,
        });
        fade_out_callback.current?.();
      }
    },
    [id, state]
  );
  const on_fade_in = useCallback(
    e => {
      if (e.target.id === id) {
        set_state({
          faded_in: true,
        });
      }
    },
    [id, state]
  );

  useLayoutEffect(() => {
    // reset anim status when the page from props changes
    if (page !== state.active_page) {
      set_state({
        faded_in: false,
        faded_out: false,
      });
    }
  }, [page, set_state]);

  // indicates that a new page is fading in when both anim states are false
  const changing = !state.faded_out && !state.faded_in;
  useLayoutEffect(() => {
    // when a transition is in progress, and the active page is now the page from props
    if (
      prop_page.current === state.active_page &&
      !state.faded_out &&
      !state.faded_in
    ) {
      // the prev page has finished fading out
      set_state({
        faded_out: true,
      });
    }
  }, [changing, state.active_page, set_state]);

  // listener depends on current transition state
  const on_fade = state.faded_in
    ? undefined
    : state.faded_out
    ? on_fade_in
    : on_fade_out;

  return (
    <div className={`transition__container${maybeClassName(className)}`}>
      <div
        id={id}
        className={state.faded_out ? "transition__to" : "transition__from"}
        data-no-anim={state.faded_in && !state.faded_out}
        data-started={!state.faded_out && !state.faded_in}
        onAnimationEnd={on_fade}
        onTransitionEnd={on_fade}
      >
        {pages[state.active_page]}
      </div>
    </div>
  );
};
