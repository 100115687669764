import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { maybeClassName } from "@thrive-web/ui-utils";
import { AvatarLoading, EntityLink, UserCard } from "@thrive-web/ui-components";

export const UserListItem: Preact.FunctionComponent<
  {
    user: User;
    size?: IconSize;
    useDiv?: boolean;
    linkBox?: "all" | "avatar" | "content" | "none";
    onClick?: (e) => void;
    health?: number;
    cardLabel?: string;
    cardSubtext?: Preact.ComponentChildren;
  } & MaybeClass
> = ({
  user,
  size = "sm",
  useDiv,
  className,
  linkBox,
  onClick,
  children,
  health,
  cardLabel,
  cardSubtext,
}) => {
  const left = (
    <div className="user-list__item__left">
      <UserCard
        user={user}
        size={size}
        label={cardLabel}
        avatarIsLink={linkBox === "avatar" || linkBox === "content"}
        nameIsLink={linkBox === "content"}
        health={health}
      >
        {cardSubtext}
      </UserCard>
    </div>
  );

  const Tag = useDiv ? "div" : "li";
  return linkBox === "all" ? (
    <Tag className="user-list__item--link">
      <EntityLink
        entity={user}
        className={`user-list__item${maybeClassName(className)}`}
        onClick={onClick}
      >
        {left}
        <div className="user-list__item__right">{children}</div>
      </EntityLink>
    </Tag>
  ) : (
    <Tag
      className={`user-list__item${maybeClassName(className)}`}
      onClick={onClick}
    >
      {left}
      <div className="user-list__item__right">{children}</div>
    </Tag>
  );
};

export const UserInviteListItem: preact.FunctionComponent<
  MaybeClass & {
    user: User;
    button: Preact.VNode;
    linkBox?: "all" | "avatar" | "none";
  }
> = ({ user, linkBox = "none", button, className }) => (
  <div className={`invite-users-modal__person${maybeClassName(className)}`}>
    <UserCard
      user={user}
      nameIsLink={linkBox === "all"}
      avatarIsLink={linkBox !== "none"}
      className="invite-users-modal__person__left"
      size="sm"
    />
    <div className="invite-users-modal__person__right">{button}</div>
  </div>
);

export const UserListItemLoading: Preact.FunctionComponent<{
  useDiv?: boolean;
  size?: IconSize;
}> = ({ useDiv, size = "md" }) => {
  const Tag = useDiv ? "div" : "li";
  const width = useMemo(() => `${Math.round(Math.random() * 5) + 9}rem`, []);

  return (
    <Tag className="user-list__item loading-item">
      <div className="user-list__item__left">
        <AvatarLoading className="loading-item__shaded--dark" size={size} />
        <span
          className="user-list__item__name loading-item__text loading-item__shaded loading-item__shaded--dark"
          style={{ width }}
        />
      </div>
    </Tag>
  );
};
