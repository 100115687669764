import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import authWrapper from "~/auth/auth";
import { MIN_PASSWORD_LENGTH } from "@thrive-web/ui-constants";
import { set_dirty } from "@thrive-web/ui-model";
import { make_displayable_error } from "@thrive-web/ui-common";
import {
  ErrorMessage,
  InputWithFormHelpers,
  RequestButton,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import { useForm, useRequest, useStateObject } from "@thrive-web/ui-hooks";

const is_valid_password = (value: string) =>
  value.length >= MIN_PASSWORD_LENGTH; //&&
// /\d/.test(value) &&
// /[A-Za-z]/.test(value);

export const UserSettingsPassword: Preact.FunctionComponent = () => {
  const [
    { current_password, new_password, confirm_password },
    ,
    onChangeInput,
    setFormData,
  ] = useForm<any>({});
  const [{ show_new_err, show_confirm_err }, set_show_error] = useStateObject({
    show_new_err: false,
    show_confirm_err: false,
    show_cur_err: false,
  });
  useEffect(() => {
    set_dirty(
      !!current_password || !!new_password || !!confirm_password,
      "user-settings-password"
    );
  }, [!!current_password || !!new_password || !!confirm_password]);

  const form_ref = useRef<HTMLFormElement>();

  const [update_password, { pending, success, error }] = useRequest(
    authWrapper.changePassword
  );
  const d_error = useMemo(
    () => (error ? make_displayable_error(error) : undefined),
    [error]
  );

  const on_submit = useCallback(
    e => {
      if (
        current_password &&
        new_password &&
        is_valid_password(new_password) &&
        new_password !== current_password &&
        new_password === confirm_password
      ) {
        e.preventDefault();
        update_password(current_password, new_password).then(() => {
          form_ref.current?.blur();
          setFormData({
            current_password: undefined,
            new_password: undefined,
            confirm_password: undefined,
          });
          set_show_error({
            show_new_err: false,
            show_confirm_err: false,
            show_cur_err: false,
          });
          form_ref.current?.reset();
        });
      } else {
        if (
          !!new_password &&
          !!confirm_password &&
          !!current_password &&
          (new_password !== confirm_password ||
            new_password === current_password)
        ) {
          e.preventDefault();
        }
        set_show_error({ show_confirm_err: true, show_new_err: true });
      }
    },
    [current_password, new_password, confirm_password]
  );

  const on_blur_new = useCallback(() => {
    if (!show_new_err) {
      set_show_error({ show_new_err: true });
    }
  }, [set_show_error, show_new_err]);

  const on_blur_confirm = useCallback(() => {
    if (!show_confirm_err) {
      set_show_error({ show_confirm_err: true });
    }
  }, [set_show_error, show_confirm_err]);

  const validate_current = useCallback(
    value => d_error?.code !== "auth/wrong-password",
    [d_error]
  );

  const validate_new = useCallback(
    value => !show_new_err || value !== current_password,
    [show_new_err, current_password]
  );

  const validate_confirm = useCallback(
    value => !show_confirm_err || value === new_password,
    [show_confirm_err, new_password]
  );

  return (
    <div className="form__section">
      <form
        ref={form_ref}
        onSubmit={on_submit}
        id="user-settings-password-form"
      >
        <div className="form__section-header">Password</div>
        <div className="form__input-row">
          <WithFloatingTitle title="Current Password">
            <InputWithFormHelpers
              name="current_password"
              placeholder="Current Password"
              type="password"
              required={true}
              minLength={MIN_PASSWORD_LENGTH}
              onChange={onChangeInput("current_password")}
              value={current_password}
              submitOnEnter={true}
              controlled={true}
              validate={validate_current}
            />
          </WithFloatingTitle>
        </div>
        {d_error && d_error.code === "auth/wrong-password" && (
          <ErrorMessage className="info">
            The current password you entered is incorrect.
          </ErrorMessage>
        )}
        <div className="form__input-row">
          <WithFloatingTitle title="New Password">
            <InputWithFormHelpers
              name="new_password"
              placeholder="New Password"
              type="password"
              required={true}
              minLength={MIN_PASSWORD_LENGTH}
              onChange={onChangeInput("new_password")}
              value={new_password}
              submitOnEnter={true}
              controlled={true}
              onBlur={on_blur_new}
              validate={validate_new}
            />
          </WithFloatingTitle>
        </div>
        {show_new_err &&
          !!new_password &&
          !!current_password &&
          new_password === current_password && (
            <ErrorMessage className="info">
              You cannot reuse your current password.
            </ErrorMessage>
          )}
        <div className="form__input-row">
          <WithFloatingTitle title="Confirm Password">
            <InputWithFormHelpers
              name="confirm_password"
              placeholder="Confirm Password"
              type="password"
              required={true}
              minLength={MIN_PASSWORD_LENGTH}
              onChange={onChangeInput("confirm_password")}
              value={confirm_password}
              submitOnEnter={true}
              controlled={true}
              onBlur={on_blur_confirm}
              validate={validate_confirm}
            />
          </WithFloatingTitle>
        </div>
        {show_confirm_err &&
          !!new_password &&
          !!confirm_password &&
          new_password !== confirm_password && (
            <ErrorMessage className="info">
              Passwords do not match.
            </ErrorMessage>
          )}
        <div className="register__password-reqs">
          Passwords must be at least {MIN_PASSWORD_LENGTH} characters long
          {/* and
          contain one number and one symbol*/}
          .
        </div>
        {d_error && d_error.code !== "auth/wrong-password" && (
          <ErrorMessage className="info">{d_error.message}</ErrorMessage>
        )}
        <div className="form__section__footer">
          <RequestButton
            className="filled gray"
            type="submit"
            pending={pending}
            success={success && !current_password}
            successText="Password Changed!"
            onClick={on_submit}
          >
            Change Password
          </RequestButton>
        </div>
      </form>
    </div>
  );
};
