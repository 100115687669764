import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const EmptyList: Preact.FunctionComponent<MaybeClass> = ({
  className,
  children,
}) => (
  <div className={`empty-list${maybeClassName(className)}`}>
    <div className="empty-list__content">{children}</div>
  </div>
);
