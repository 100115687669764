import * as Preact from "preact";
import {
  ButtonWithIcon,
  Carousel,
  DefaultModalContent,
  ErrorMessage,
  FileUpload,
  GroupTouchpointForm,
  RequestButton,
  TextAreaWithFormHelpers,
  useGroupMemberSearch,
  useTaxonomyFetch,
  PostAddPhotoButton,
  PostDisabledFeature,
  PostFormTagUsersPage,
  PostTouchpoint,
  PostTagUsersButton,
  Tooltip,
  PostExpense,
  PostTouchpointRequired,
  GroupExpenseForm,
  PostScopeSelector,
  ScopeItem,
  Avatar,
  PostSetScopeButton,
  MoodSelectorDropdown,
  PostFormMoodButton,
  PostFormTaggedUsers,
  PostFormHelpBlock,
  POST_SCOPE_OPTIONS,
  useGroupUpdatesAllowed,
  PillRadioList,
  PostEvent,
  DropdownMenu,
  PostLinkPreview,
  PostHelpForm,
} from "@thrive-web/ui-components";
import {
  Community,
  Expense,
  ExperienceCategory,
  Mood,
  Post,
  Touchpoint,
  User,
  WritePost,
  WriteTouchpoint,
} from "@thrive-web/ui-api";
import {
  useAppUser,
  useCallbackRef,
  useDirtyFormWithDiff,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { class_names, generateTouchpointText } from "@thrive-web/ui-utils";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import {
  add_item_to,
  analytics,
  extract_link_from_body,
  is_id_obj,
  is_zendesk_article_link,
  remove_item_from,
} from "@thrive-web/ui-common";
import { should_leave } from "@thrive-web/ui-model";
import { DocBase } from "@thrive-web/core";

// keys for carousel pages in post form
export type PostFormPage =
  | "main"
  | "scope"
  | "group"
  | "tag"
  | "touchpoint"
  | "expense"
  | "help";

export interface PostFormBaseProps<Scope extends ScopeItem = any> {
  defaultScope?: Scope; // group/community/etc. id
  allowScopeChange?: boolean;
  onFinish: (new_post: Post) => void;
  onChangeScopeType?: (type: "Group" | "Community") => void;
  allowCommunityPosts?: boolean;
  // list of communities that the current user is a mod/manager/admin of
  moderatedCommunities?: Community[] | null;
}

export interface PostFormMainProps {
  formData: WritePost;
  // allow touchpoints (i.e. is current group in Thread with goals enabled?)
  allowGroupUpdates?: boolean;
  goToPage: (page: PostFormPage) => void;
  onInputChange: (key: keyof WritePost) => (e: any) => void;
  setField: <K extends keyof WritePost>(key: K, value: WritePost[K]) => void;
  // on change post photo
  onChangeFile: (new_file?: FileUploadData) => void;
  taggedUsers?: readonly User[];
  photoFile?: FileUploadData;
  expensePhotoFile?: FileUploadData;
  onChangeExpenseFile: (new_file?: FileUploadData) => void;
  initialPage: PostFormPage;
  removeLinkPreview: () => void;
  linkPreviewHidden: boolean;
}

const SCOPE_TYPE_OPTIONS: InputOptions<"Group" | "Community"> = [
  { label: "Group", value: "Group", icon: "family" },
  { label: "Community", value: "Community", icon: "groups" },
];

// "Main" page of the form, with the body textarea and other controls
export const PostFormMain: Preact.FunctionComponent<PostFormMainProps> = ({
  formData,
  allowGroupUpdates,
  goToPage,
  onInputChange,
  setField,
  onChangeFile,
  taggedUsers,
  photoFile,
  expensePhotoFile,
  onChangeExpenseFile,
  removeLinkPreview,
  linkPreviewHidden,
  initialPage,
}) => {
  const self = useAppUser();
  const [mounted, setMounted] = useStateIfMounted(false);
  // outermost element of this component
  const node_ref = useRef<HTMLDivElement>();
  // tooltip needs to be inserted outside the overflow list due to css overflow rule
  const people_tooltip_source_ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // need to force an update because the <FileUpload> component mounts before
    // <div id="post-form-photo-preview">
    if (!mounted && (photoFile || node_ref.current)) {
      setMounted(true);
    }
  }, []);

  // these items don't have "pages" in the form carousel, so if they're the initial
  // page, click on their controls instead
  useEffect(() => {
    if (!mounted) {
      return;
    }
    // @ts-expect-error:
    if (initialPage === "mood") {
      document.getElementById("post-form-mood-selector")?.click();
    }
    // @ts-expect-error:
    if (initialPage === "photo") {
      document.getElementById("post-form-photo-upload-button")?.click();
    }
  }, [mounted]);

  const people_tooltip_props = useMemo(
    () => ({
      getSourceRef: () => people_tooltip_source_ref.current,
    }),
    [people_tooltip_source_ref]
  );

  const has_existing_touchpoint = !!formData?.has_touchpoint?.id;
  const has_existing_expense = !!formData?.has_expense?.id;

  // when we remove the touchpoint, we must also remove the expense
  const removeTouchpoint = useCallback(() => {
    setField("has_touchpoint", undefined);
    if (has_existing_expense) {
      setField("has_expense", undefined);
      onChangeExpenseFile();
    }
  }, [setField, has_existing_expense]);

  // when we remove the expense, clear the expense receipt image
  const removeExpense = useCallback(() => {
    setField("has_expense", undefined);
    onChangeExpenseFile();
  }, [setField, onChangeExpenseFile]);

  const setMood = useCallback(
    (mood?: Mood) => {
      analytics.log_event(
        analytics.EVENTS.update_mood,
        undefined,
        undefined,
        true
      );
      setField("mood", mood?.id ? mood : undefined);
    },
    [setField]
  );

  // detect and parse a link from the post body
  useEffect(() => {
    if (!formData?.body) {
      if (formData?.video) {
        setField("video", undefined);
      }
      return;
    }
    const link = extract_link_from_body(formData?.body);
    if (
      (!link || is_zendesk_article_link(link)) &&
      link !== formData?.zendesk_article?.id
    ) {
      setField("zendesk_article", link ? { id: link } : undefined);
      setField("video", undefined);
    } else if (
      (!link || !is_zendesk_article_link(link)) &&
      link !== formData?.video?.id
    ) {
      setField("video", link ? { id: link } : undefined);
      setField("zendesk_article", undefined);
    }
  }, [formData?.body]);

  /*const warn_group_change =
    !!allowGroupChange &&
    !!(formData.has_touchpoint || formData.has_expense || taggedUsers?.length);

  const goToGroupSelection = useCallback(() => goToPage("group"), [goToPage]);*/
  return (
    <div className="post__form__modal__body" ref={node_ref}>
      <div className="post__form__top">
        {formData?.mood && (
          <div className="post__form__mood">
            <PostFormMoodButton mood={formData.mood} onClear={setMood} />
          </div>
        )}
      </div>
      <div className="post__form__input">
        <TextAreaWithFormHelpers
          className="card__textarea"
          placeholder="What's up?"
          name="post-text"
          onChange={onInputChange("body")}
          value={formData.body}
          controlled={true}
          autoFocus={true}
          submitOnEnter={false}
        />
      </div>
      <div
        id="post-form-photo-preview"
        className="post__photo post__form__photo__preview"
      />
      <div className="post__form__blocks">
        {formData.is_asking_for_help && self && (
          <PostFormHelpBlock
            user={self}
            remove={() => setField("is_asking_for_help", false)}
          />
        )}
        {taggedUsers?.length ? (
          <PostFormTaggedUsers
            users={taggedUsers}
            allowEdit={!has_existing_touchpoint && !has_existing_expense}
            onClickEdit={() => goToPage("tag")}
          />
        ) : null}
        {formData.event ? <PostEvent event={formData.event} /> : null}
        {formData.video && !linkPreviewHidden ? (
          <PostLinkPreview
            link={formData.video.id}
            onRemove={removeLinkPreview}
          />
        ) : null}
        {formData.zendesk_article && !linkPreviewHidden ? (
          <PostLinkPreview
            link={formData.zendesk_article.id}
            onRemove={removeLinkPreview}
          />
        ) : null}
        {formData.has_touchpoint && (
          <PostTouchpoint
            touchpoint={formData.has_touchpoint}
            removeTouchpoint={removeTouchpoint}
            hasExpense={has_existing_expense}
          />
        )}
        {formData.has_expense && expensePhotoFile && (
          <PostExpense
            expense={formData.has_expense}
            removeExpense={removeExpense}
          />
        )}
        {formData.has_expense && !formData.has_touchpoint && (
          <PostTouchpointRequired
            goToTouchpointPage={() => goToPage("touchpoint")}
          />
        )}
      </div>
      <div className="post__controls">
        <div
          className={`${class_names(
            {
              "--photo-input-hidden": !!photoFile,
            },
            "post__controls__widgets"
          )}`}
        >
          <FileUpload
            id="post-form-photo-upload"
            onChange={onChangeFile}
            Button={PostAddPhotoButton}
            value={photoFile}
          />
          <Tooltip
            text="You may not change the tagged users for this post unless you remove the existing Group Update."
            disabled={!has_existing_touchpoint}
            popoverProps={people_tooltip_props}
          >
            <PostTagUsersButton
              ref={people_tooltip_source_ref}
              onClick={
                has_existing_touchpoint || has_existing_expense
                  ? undefined
                  : () => goToPage("tag")
              }
            />
          </Tooltip>
          <MoodSelectorDropdown
            key="post-form-mood-selector"
            id="post-form-mood-selector"
            className="post__form__mood"
            onSelect={setMood}
            value={formData.mood}
          />
        </div>
        <DropdownMenu
          className="post__controls__widgets__menu"
          buttonClassName="filled gray"
          listClassName="card pill-card"
          allowLinkEventPropagation={false}
          closeOnClickItem={true}
          items={[
            <ButtonWithIcon
              className="filled gray"
              side="left"
              icon="helping-hands"
              onClick={() => goToPage("help")}
            >
              I Need Help
            </ButtonWithIcon>,
            allowGroupUpdates && !has_existing_touchpoint ? (
              <ButtonWithIcon
                icon="amplify"
                side="left"
                className="filled gray"
                onClick={() => goToPage("touchpoint")}
              >
                Update your Group
              </ButtonWithIcon>
            ) : null,
            allowGroupUpdates && !has_existing_expense ? (
              <ButtonWithIcon
                icon="expense"
                side="left"
                className="filled gray"
                onClick={() => goToPage("expense")}
              >
                Get Reimbursement
              </ButtonWithIcon>
            ) : null,
          ]}
        />
      </div>
    </div>
  );
};

export interface PostFormProps<
  M extends "createPost" | "updatePost",
  Scope extends { id: string } = any
> extends PostFormBaseProps<Scope> {
  initialPage?: PostFormPage;
  initialData?: Post;
  photoFile?: FileUploadData;
  onChangeFile: (data: FileUploadData | undefined) => void;
  submitRequest: (new_post: Post) => Promise<DocBase & { data: Post }>;
  mediaProgress?: number;
  expensePhotoFile?: FileUploadData;
  onChangeExpenseFile: (new_file?: FileUploadData) => void;
}

export const PostForm = <M extends "createPost" | "updatePost">({
  initialPage = "main",
  initialData,
  photoFile,
  onChangeFile,
  defaultScope,
  allowScopeChange,
  submitRequest,
  mediaProgress,
  onFinish,
  closeButton,
  dismiss,
  expensePhotoFile,
  onChangeExpenseFile,
  onChangeScopeType,
}: PostFormProps<M> & ModalBodyProps) => {
  useTaxonomyFetch("Mood");
  useTaxonomyFetch("TouchpointAction");
  useTaxonomyFetch("TouchpointAccomplishment");
  useTaxonomyFetch("ExperienceCategory");
  const user = useAppUser();
  const [page, setPage] = useStateIfMounted<PostFormPage>(
    defaultScope
      ? // @ts-expect-error:
        initialPage === "mood" || initialPage === "photo"
        ? "main"
        : initialPage
      : initialPage === "help"
      ? "help"
      : !onChangeScopeType
      ? "group"
      : "scope"
  );

  // set to true when the user has decided whether to post to a group or a comm
  const scope_type_set = useRef(!onChangeScopeType);
  // set to true when the user has picked which group/comm to post to
  const group_set = useRef(!!defaultScope);
  const returnToMain = useCallback(() => {
    if (initialPage !== "main" && !group_set.current) {
      setPage("group");
    } else {
      setPage("main");
    }
  }, [setPage]);
  // data for FileUploadInput
  const initialPhoto = useMemo<FileUploadData | undefined>(
    () =>
      initialData?.photo_url
        ? { data: initialData.photo_url, name: "", mime: "" }
        : undefined,
    []
  );

  const [tagged_users, set_tagged_users] = useStateIfMounted<readonly User[]>(
    (initialData?.has_tagged_user as User[]) || []
  );
  const toggleTaggedUser = useCallback(
    (user: User, is_tagged: boolean) => {
      set_tagged_users(
        is_tagged
          ? remove_item_from(tagged_users, u => u.id === user.id)
          : add_item_to(tagged_users, user)
      );
    },
    [tagged_users, set_tagged_users]
  );

  const onChangeFile_ = useCallback((data: FileUploadData | undefined) => {
    if (data) {
      analytics.log_event(
        analytics.EVENTS.post.photo_attached,
        undefined,
        undefined,
        true
      );
    }
    onChangeFile(data);
    // @ts-expect-error:
    setField("photo", data);
  }, []);

  // transform some data to make it diffable
  const prepare_for_diffing = useCallback(
    (data: WritePost) =>
      // @ts-expect-error:
      ({
        ...data,
        has_tagged_user: tagged_users.map(u => u.id),
        photo: photoFile,
        posted_to: initialData?.posted_to,
      } as WritePost),
    [tagged_users, photoFile]
  );

  const [
    formData,
    updatedFormData,
    deletedFormDataKeys,
    setField,
    onInputChange,
    setFormData,
    resetForm,
  ] = useDirtyFormWithDiff<WritePost>(
    {
      has_touchpoint: undefined,
      has_expense: undefined,
      ...({
        ...initialData,
        // when creating a new post with an event, we want the event to be treated
        // as new/updated form_data, so exclude it from the initial data for diffing
        event: !initialData?.id ? undefined : initialData?.event,
      } ||
        (defaultScope
          ? {
              posted_to: defaultScope,
            }
          : {})),
    },
    "PostForm",
    prepare_for_diffing,
    true
  );
  const posted_to = formData.posted_to || defaultScope;

  // if initialData had an event, and we're creating a new post, and the event is public,
  // add the event to the form data
  useEffect(() => {
    if (
      initialData?.event &&
      !initialData?.id &&
      // @ts-expect-error:
      initialData.event.is_public
    ) {
      setField("event", initialData.event);
    }
  }, []);

  const onSelectScopeType = useCallback(
    (opt: InputOption<"Group" | "Community">) => {
      scope_type_set.current = true;
      setPage("group");
      onChangeScopeType?.(opt.value);
    },
    [onChangeScopeType]
  );

  const scope_ctx = useContext(POST_SCOPE_OPTIONS);
  const onChangeScope = useCallback(
    (value: ScopeItem) => {
      setField("posted_to", value);
      // when scope changes, remove touchpoint, expense, and tagged users
      if (posted_to?.id && value.id !== posted_to.id) {
        setField("has_touchpoint", undefined);
        setField("has_expense", undefined);
        setField("has_tagged_user", undefined);
        set_tagged_users([]);
      }
      // if we change the scope and we're not going straight to the tag-users page
      if (
        (!posted_to || posted_to.id !== value.id) &&
        value.has_member?.some(u => is_id_obj(u)) &&
        (group_set.current || initialPage !== "tag")
      ) {
        // fetch and store the full member list
        scope_ctx.get_members(value).then(has_member => {
          if (has_member) {
            // @ts-expect-error:
            setField("posted_to", { ...value, has_member });
          }
        });
      }
      if (posted_to?.id === value.id) {
        return;
      }
      // if a scope was already selected, return to the main form page
      if (group_set.current) {
        returnToMain();
      } else {
        // when scope changes, remove the filter on the scope list for thread groups only
        // (case: on home feed, user selects "Update a group", initial group list is thread
        // groups only; after selection, the user is taken to the touchpoint form. But from
        // that point forward, they can go back and select a non-thread group (and not post
        // a touchpoint)
        if (scope_ctx && scope_ctx.thread_only) {
          scope_ctx.set_thread_only(false);
        }
        group_set.current = true;
        // initial scope is now selected, proceed to the initial page selected when opening
        // the post form
        setPage(
          ["mood", "photo", "help"].includes(initialPage) ? "main" : initialPage
        );
      }
    },
    [setField, posted_to?.id]
  );

  const [exp, set_exp] = useStateIfMounted<ExperienceCategory | undefined>(
    undefined
  );
  const onFinishHelp = useCallback(
    (
      new_data: WritePost,
      new_scope?: ScopeItem,
      experience?: ExperienceCategory
    ) => {
      setFormData(new_data);
      set_exp(experience);
      if (new_scope) {
        onChangeScope(new_scope);
      }
      analytics.log_event(
        analytics.EVENTS.post.ask_for_help,
        undefined,
        undefined,
        true
      );
      returnToMain();
    },
    [setField, onChangeScope]
  );

  const allow_group_updates = useGroupUpdatesAllowed(posted_to || defaultScope);

  // the touchpoint record in the form data (if it exists)
  const touchpoint_rec: Touchpoint | undefined = formData?.has_touchpoint;
  const touchpoint_edited = useRef(false);
  // inputs for the GroupTouchpointForm component
  const touchpoint_inputs = useMemo(() => {
    return allow_group_updates
      ? {
          touchpoint: touchpoint_rec ? { ...touchpoint_rec } : undefined,
          group: posted_to,
        }
      : {};
  }, [allow_group_updates, posted_to, touchpoint_rec, tagged_users]);
  const onFinishTouchpoint = useCallback(
    (tp?: Touchpoint, scope?: ScopeItem) => {
      if (!allow_group_updates || !tp) {
        returnToMain();
        return;
      }
      analytics.log_event(
        analytics.EVENTS.post.group_update,
        undefined,
        undefined,
        true
      );
      touchpoint_edited.current = true;
      setField("has_touchpoint", tp);
      if (scope && scope.id !== posted_to?.id) {
        setField("posted_to", scope);
      }
      returnToMain();
    },
    [
      allow_group_updates,
      setField,
      set_tagged_users,
      tagged_users,
      returnToMain,
    ]
  );

  const touchpoint = useMemo(() => {
    // if there was no existing touchpoint record, generate a new description
    if (touchpoint_rec && !touchpoint_rec.id) {
      return {
        ...touchpoint_rec,
        description: generateTouchpointText({
          ...touchpoint_rec,
          posted_to: {
            // @ts-expect-error:
            has_tagged_user: tagged_users,
          },
        }),
      } as WriteTouchpoint;
    }
    return touchpoint_rec;
  }, [touchpoint_rec, tagged_users]);

  const expense_edited = useRef(false);
  const onFinishExpense = useCallback(
    (exp: Expense) => {
      analytics.log_event(
        analytics.EVENTS.post.reimbursement,
        undefined,
        undefined,
        true
      );
      expense_edited.current = true;
      setField("has_expense", exp);
      returnToMain();
    },
    [setField]
  );

  const onFinishTaggedUsers = useCallback(() => {
    if (tagged_users.length) {
      analytics.log_event(
        analytics.EVENTS.post.users_tagged,
        undefined,
        undefined,
        true
      );
    }
    returnToMain();
  }, [tagged_users.length, returnToMain]);

  // data to pass to the main form page
  const visibleFormData = useMemo<WritePost>(
    () => ({
      body: "",
      posted_to: defaultScope,
      ...formData,
      has_tagged_user: tagged_users,
      has_touchpoint: touchpoint as Touchpoint,
    }),
    [formData, tagged_users, touchpoint]
  );

  const onSuccess = useCallbackRef(
    (new_post: Post) => {
      resetForm(new_post);
      dismiss();
      onFinish({ ...new_post, created_by: user || new_post.created_by });
    },
    [user, onFinish, dismiss, resetForm]
  );
  const onCancel = useCallback(() => {
    if (should_leave()) {
      initialData &&
        resetForm({
          has_touchpoint: undefined,
          has_expense: undefined,
          ...initialData,
        });
      onChangeFile(initialPhoto);
      dismiss();
    }
  }, [dismiss]);

  const [submitForm, { pending, success, error }] = useRequest(submitRequest);

  const has_required_fields =
    (!!formData.body ||
      !!formData.has_touchpoint ||
      !!formData.mood ||
      !!formData.event ||
      !!photoFile) &&
    (!formData.has_expense || !!formData.has_touchpoint) &&
    (!formData.has_touchpoint || tagged_users.length > 0);

  // track whether the user has dismissed the link block so we don't reshow it when
  // they add other text to the post body
  const initial_link_hidden = useRef(!!initialData && !initialData?.video);
  const [link_preview_hidden, set_link_preview_hidden] = useStateIfMounted(
    initial_link_hidden.current
  );
  const remove_link_preview = useCallback(
    () => set_link_preview_hidden(true),
    []
  );
  useEffect(() => {
    if (initial_link_hidden.current) {
      initial_link_hidden.current = false;
    } else {
      return () => set_link_preview_hidden(false);
    }
  }, [formData.video?.id]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      // cancel if already submitted or missing body text
      if (!has_required_fields) {
        return;
      }

      const data = {
        ...updatedFormData,
        ...(!initialData?.posted_to ? { posted_to } : {}),
        video: link_preview_hidden ? null : updatedFormData?.video,
        has_tagged_user: tagged_users,
        photo: undefined,
        id: "",
      };
      // if the expense hasn't been modified, remove it from the data
      if (!expense_edited.current && !!initialData === !!formData.has_expense) {
        delete data.has_expense;
      }
      // if the touchpoint hasn't been modified, remove it from the data
      if (!touchpoint_edited.current && !!initialData === !!touchpoint_rec) {
        delete data.has_touchpoint;
      } else if (data.has_touchpoint && touchpoint) {
        data.has_touchpoint["description"] = touchpoint["description"];
      }
      // if the post already had a touchpoint, the touchpoint was unmodified, and
      // an expense was added, set the touchpoint relationship in the expense data
      if (
        data.has_expense &&
        initialData?.has_touchpoint &&
        !touchpoint_edited.current
      ) {
        data.has_expense["touchpoint"] = { id: initialData.has_touchpoint.id };
      }
      deletedFormDataKeys?.forEach(k => {
        // @ts-expect-error:
        data[k] = null;
      });

      // @ts-expect-error:
      submitForm(data).then(({ data: new_post }) => {
        setTimeout(() => {
          onSuccess.current &&
            onSuccess.current({
              ...new_post,
              created_by: user || new_post.created_by,
              posted_to: posted_to || new_post.posted_to,
            });
        }, 1000);
      });
    },
    [
      posted_to,
      updatedFormData,
      submitForm,
      onSuccess,
      tagged_users,
      touchpoint_edited,
      has_required_fields,
      deletedFormDataKeys,
      link_preview_hidden,
    ]
  );

  const getUsers = useGroupMemberSearch(posted_to, onChangeScope);

  const has_existing_touchpoint = !!formData?.has_touchpoint?.id;
  const has_existing_expense = !!formData?.has_expense?.id;

  const allow_group_change = !!allowScopeChange && !initialData?.id;

  // send user back to main page when they end up on a page they can't use
  useEffect(() => {
    if (
      (page === "group" && !allow_group_change) ||
      (page === "touchpoint" &&
        (!allow_group_updates || has_existing_touchpoint)) ||
      (page === "expense" && (!allow_group_updates || has_existing_expense))
    ) {
      setPage("main");
    }
  }, [page, setPage, has_existing_expense, has_existing_touchpoint]);

  const goToGroupSelection = useCallback(() => setPage("group"), [setPage]);

  // true when a warning should be shown before changing the post scope
  const warn_group_change =
    allow_group_change &&
    !!(formData.has_touchpoint || formData.has_expense || tagged_users.length);

  if (!user) {
    return null;
  }

  const pages: { [K in PostFormPage]: Preact.VNode } = {
    scope: onChangeScopeType ? (
      <div className="modal__body post__form__scope-type">
        <p>Do you want to post to a Group or a Community?</p>
        <PillRadioList
          name="create-post-scope-type"
          onSelectOption={onSelectScopeType}
          options={SCOPE_TYPE_OPTIONS}
        />
      </div>
    ) : (
      <div />
    ),
    group: (
      <DefaultModalContent
        title={
          <Preact.Fragment>
            {(!!formData?.posted_to || onChangeScopeType) && (
              <ButtonWithIcon
                className="modal-form__back"
                icon="chevron-left"
                onClick={() => setPage(onChangeScopeType ? "scope" : "main")}
              />
            )}
            <div className="post__form__modal__title">
              Where do you want to post to?
            </div>
          </Preact.Fragment>
        }
      >
        <PostScopeSelector onChange={onChangeScope} />
      </DefaultModalContent>
    ),
    main: (
      <DefaultModalContent
        title={
          <Preact.Fragment>
            <div className="post__people">
              <div className="post__people__left">
                <Avatar user={user} size="md" isLink={false} />
                <div className="post__people__creator">
                  <span>{user.full_name}</span>
                  <PostSetScopeButton
                    scope={posted_to}
                    allowGroupChange={allow_group_change}
                    showWarning={warn_group_change}
                    onClick={goToGroupSelection}
                  />
                </div>
              </div>
            </div>
          </Preact.Fragment>
        }
        closeButton={closeButton}
        footer={
          <div className="modal__footer">
            {initialData && (
              <div className="modal__footer__cancel">
                <button className="filled gray" onClick={onCancel}>
                  Cancel
                </button>
              </div>
            )}
            <div className="post__create__submit modal__footer__right">
              <div className="modal__footer__error">
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
              </div>
              <RequestButton
                className="filled gray"
                pending={pending}
                success={success && !error}
                successText="Success!"
                disabled={!has_required_fields}
                onClick={onSubmit}
                progress={mediaProgress}
              >
                {initialData ? "Save Changes" : "Create Post"}
              </RequestButton>
            </div>
          </div>
        }
      >
        <PostFormMain
          formData={visibleFormData}
          allowGroupUpdates={allow_group_updates}
          goToPage={setPage}
          onInputChange={onInputChange}
          setField={setField}
          onChangeFile={onChangeFile_}
          taggedUsers={tagged_users}
          photoFile={photoFile}
          expensePhotoFile={expensePhotoFile}
          onChangeExpenseFile={onChangeExpenseFile}
          initialPage={initialPage}
          removeLinkPreview={remove_link_preview}
          linkPreviewHidden={link_preview_hidden}
        />
      </DefaultModalContent>
    ),
    tag: (
      <PostFormTagUsersPage
        tagged={tagged_users}
        toggleUser={toggleTaggedUser}
        getUsers={getUsers}
        finish={onFinishTaggedUsers}
      />
    ),
    touchpoint: allow_group_updates ? (
      touchpoint?.id ? (
        <div />
      ) : (
        <GroupTouchpointForm
          {...touchpoint_inputs}
          warnOnChangeGroup={warn_group_change}
          getUsers={getUsers}
          onFinish={onFinishTouchpoint}
          taggedUsers={tagged_users}
          setTaggedUsers={set_tagged_users}
          allowScopeChange={allow_group_change}
        />
      )
    ) : (
      <PostDisabledFeature returnToMain={returnToMain} />
    ),
    expense: allow_group_updates ? (
      formData.has_expense?.id ? (
        <div />
      ) : (
        <GroupExpenseForm
          expense={formData.has_expense}
          file={expensePhotoFile}
          onChangeFile={onChangeExpenseFile}
          dismiss={returnToMain}
          onFinish={onFinishExpense}
          taggedUsers={tagged_users}
          setTaggedUsers={
            has_existing_touchpoint ? undefined : set_tagged_users
          }
          getUsers={getUsers}
        />
      )
    ) : (
      <PostDisabledFeature returnToMain={returnToMain} />
    ),
    help: (
      <PostHelpForm
        initialBody={formData.body}
        initialCategory={exp}
        initialScope={posted_to}
        onFinish={onFinishHelp}
        allowScopeChange={allow_group_change}
        shouldWarnScopeChange={warn_group_change}
      />
    ),
  };

  return (
    <Carousel
      className="post__form"
      page={page}
      items={pages}
      trackHeight={true}
      keepPagesMounted={["main", "expense"]}
    />
  );
};
