import * as Preact from "preact";
import { media_url } from "@thrive-web/core";
import { User } from "@thrive-web/ui-api";
import { is_id_obj } from "@thrive-web/ui-common";
import { PLACEHOLDER_AVATAR_URL } from "@thrive-web/ui-constants";
import { useForwardedRef } from "@thrive-web/ui-hooks";
import { get_cache, maybeClassName } from "@thrive-web/ui-utils";
import {
  AvatarHealth,
  EntityLink,
  ExpandableImage,
  Icon,
  Tooltip,
  UserProvider,
  WithUser,
} from "@thrive-web/ui-components";
import { useContext, useMemo } from "preact/hooks";

// always shows the placeholder silhouette icon
export const PlaceholderAvatar: React.FC<
  Omit<HTMLDivProps, "size"> & {
    size: IconSize | "btn";
    icon?: FontIconName | Preact.VNode;
  }
> = ({ size, className, children, icon, ...props }) => {
  let icon_ = icon;
  if (icon && typeof icon === "string") {
    // @ts-expect-error
    icon_ = <Icon name={icon} />;
  }
  const Elem = props.onClick ? "button" : "div";
  return (
    // @ts-expect-error:
    <Elem
      {...props}
      data-size={size}
      className={`user-avatar${
        children ? " avatar-list__more" : ""
      }${maybeClassName(className)}`}
    >
      {<img src={PLACEHOLDER_AVATAR_URL} />}
      {icon_ && <div className="user-avatar__icon">{icon_}</div>}
      {children}
    </Elem>
  );
};

export const MaybePlaceholderAvatar: React.FC<
  Omit<HTMLDivProps, "size"> & {
    name: string;
    url?: string;
    size: IconSize | "btn";
    icon?: FontIconName | Preact.VNode;
  }
> = ({ url, size, name, className, children, icon, ...props }) => {
  let icon_ = icon;
  if (icon && typeof icon === "string") {
    // @ts-expect-error
    icon_ = <Icon name={icon} />;
  }
  const Elem = props.onClick ? "button" : "div";
  return (
    // @ts-expect-error:
    <Elem
      {...props}
      data-size={size}
      className={`user-avatar${
        !url && children ? " avatar-list__more" : ""
      }${maybeClassName(className)}`}
    >
      {url && <img src={url || PLACEHOLDER_AVATAR_URL} alt={name} />}
      {icon_ && <div className="user-avatar__icon">{icon_}</div>}
      {children}
    </Elem>
  );
};

export type AvatarProps<T extends boolean | undefined> = {
  user: User;
  size: IconSize | "btn";
  icon?: FontIconName | Preact.VNode;
  isLink?: T;
  forcePlaceholder?: boolean;
  health?: number;
  showHealthAnim?: boolean;
  tooltip?: boolean;
  expandable?: boolean;
} & ForwardRef<HTMLImageElement> &
  (T extends true ? Omit<HTMLAnchorProps, "size"> : Omit<HTMLDivProps, "size">);

export const Avatar = <T extends boolean | undefined>({
  className,
  user: _user,
  size,
  icon,
  isLink = true,
  children,
  getRef,
  forcePlaceholder,
  health,
  showHealthAnim,
  tooltip,
  expandable,
  ...props
}: Preact.RenderableProps<AvatarProps<T>>): Preact.VNode | null => {
  const user = useMemo<User | null>(() => {
    if (!_user) {
      return null;
    }
    // if the user record passed in props doesn't have an email/avatar, check the cache
    if (!_user.email || !_user.profile_picture_url) {
      const cached = get_cache(_user.id) || null;
      if (
        cached &&
        (cached.profile_picture_url || (cached.first_name && cached.last_name))
      ) {
        return cached;
      }
      // if first/last name are present, we can at least show their initials
      if (_user.first_name && _user.last_name) {
        return _user;
      }
      return null;
    }
    return _user;
  }, [_user]);
  const imgRef = useForwardedRef<HTMLImageElement>(getRef);
  const img_url = useMemo(
    () =>
      user
        ? media_url<"User", "profile_picture">(user, "profile_picture", "small")
        : undefined,
    [user]
  );

  // if no usable data in the user record, use the placeholder avatar
  if (!user) {
    return forcePlaceholder ? <PlaceholderAvatar size={size} /> : null;
  }

  let icon_ = icon;
  if (icon && typeof icon === "string") {
    // @ts-expect-error
    icon_ = <Icon name={icon} />;
  }
  const name = user.full_name;

  /*const health =
    user.health_rating != null && showHealth
      ? {
          rating: user.health_rating,
          date: user.health_rating_created_at
        }
      : undefined;*/

  const content = img_url ? (
    expandable ? (
      <ExpandableImage
        previewSrc={img_url}
        src={user.profile_picture_url}
        aria-label={`User profile picture for ${name}`}
        alt={
          size === "lg" || size === "xl"
            ? `User profile picture for ${name}`
            : undefined
        }
        draggable={!isLink}
        ref={imgRef}
      />
    ) : (
      <img
        src={img_url || PLACEHOLDER_AVATAR_URL}
        aria-label={`User profile picture for ${name}`}
        alt={
          size === "lg" || size === "xl"
            ? `User profile picture for ${name}`
            : undefined
        }
        draggable={!isLink}
        ref={imgRef}
      />
    )
  ) : (
    <div ref={imgRef} className="user-avatar__initials">
      {user.first_name?.[0]}
      {user.last_name?.[0]}
    </div>
  );

  const content_wrapped = tooltip ? (
    <Tooltip
      text={user.full_name || ""}
      disabled={!user.full_name}
      delay={1000}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );

  const Tag = isLink ? EntityLink : "div";
  return (
    <Tag
      {...props}
      data-size={size}
      className={`user-avatar${maybeClassName(className)}`}
      // @ts-expect-error:
      entity={isLink ? user : undefined}
    >
      {health != undefined ? (
        <AvatarHealth rank={health} showAnim={showHealthAnim}>
          {content_wrapped}
        </AvatarHealth>
      ) : (
        content_wrapped
      )}
      {icon_ && (
        <div className="user-avatar__icon" data-icon={icon}>
          {icon_}
        </div>
      )}
      {children}
    </Tag>
  );
};

// Display the avatar of whatever user is provided by UserProvider
export const AvatarFromCtx = <T extends boolean | undefined>(
  props: Preact.RenderableProps<Omit<AvatarProps<T>, "user"> & { user?: User }>
): Preact.VNode | null => {
  const user = useContext(UserProvider);
  return (
    // @ts-expect-error:
    <Avatar
      {...props}
      user={user || (props.user as User)}
      forcePlaceholder={!user || is_id_obj(user) || props.forcePlaceholder}
    />
  );
};

// Display the user's avatar, but if the user record lacks the necessary info,
// refetch the record
export const AvatarWithCtx = <T extends boolean | undefined>({
  user_id,
  user,
  ...props
}: Preact.RenderableProps<
  { user_id: string; user?: User } & Omit<AvatarProps<T>, "user">
>): Preact.VNode | null => {
  return (
    <WithUser id={user_id} user={user}>
      {
        // @ts-expect-error:
        <AvatarFromCtx {...props} />
      }
    </WithUser>
  );
};

export const AvatarLoading: Preact.FunctionComponent<
  { size: IconSize } & MaybeClass
> = ({ size, className }) => (
  <div
    className={`loading-item__avatar user-avatar loading-item__shaded${maybeClassName(
      className
    )}`}
    data-size={size}
  />
);
