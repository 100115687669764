import * as Preact from "preact";
import { User } from "@thrive-web/ui-api";
import {
  UserAbout,
  UserProfileOuter,
  UserSettingsModal,
  UserUpdate,
  UserGroups,
} from "~/view/components";
import {
  DefaultErrorView,
  NotFoundPageRoute,
  passPropsToLocalContext,
  RedirectSubroute,
  TAXONOMIES_CTX,
} from "@thrive-web/ui-components";
import { useContext, useEffect, useMemo, useRef } from "preact/hooks";
import { USER_DETAIL_CONTEXTS } from "./contexts";
import { analytics, ensure_id_is_iri } from "@thrive-web/ui-common";
import {
  useApiRequest,
  useAppUser,
  useRecordForDetailPage,
} from "@thrive-web/ui-hooks";
import { ApiMethodParameters } from "@thrive-web/core";

const user_params: ApiMethodParameters<"GET", "User", true> = {
  query: {
    include: [
      "has_experience",
      "has_connection",
      "has_current_mood",
      "profile_picture",
    ],
  },
};

export const UserProfile: Preact.FunctionComponent<{
  user: User | null;
  userId: string;
  postId?: string;
  tab?: string;
}> = ({ tab, postId, user, userId }) => {
  passPropsToLocalContext("user", user, USER_DETAIL_CONTEXTS.dispatch);
  const self = useAppUser();

  const prev_tab = useRef(tab === "settings" ? "" : tab);
  useEffect(() => {
    if (tab !== "settings") {
      prev_tab.current = tab || "";
    }
  }, [tab]);

  if (!self) {
    return null;
  }

  return (
    <Preact.Fragment>
      {!tab ||
      tab === "post" ||
      (tab === "settings" &&
        (prev_tab.current === "post" || !prev_tab.current)) ? (
        <UserAbout
          userId={userId}
          path="/people/:id/:post?/:post_id?"
          postId={postId}
        />
      ) : null}
      {user &&
        (self.is_adult ? (
          <UserGroups path="/people/:id/groups" user={user} />
        ) : (
          <NotFoundPageRoute path="/people/:id/groups" />
        ))}
      {self?.id === user?.id && <UserUpdate path="/people/:id/edit" />}
      {self?.id === user?.id && (
        <UserSettingsModal prevTab={prev_tab.current} />
      )}
    </Preact.Fragment>
  );
};

export const UserProfilePage: Preact.FunctionComponent<RoutePageProps> = ({
  matches = {},
}) => {
  const self = useAppUser();
  const fetch_tx = useContext(TAXONOMIES_CTX.fetch);
  useEffect(() => {
    fetch_tx("GenderIdentity");
    fetch_tx("EthnicIdentity");
    fetch_tx("GroupIdentity");
    fetch_tx("RaceIdentity");
    fetch_tx("CustomIdentity");
    fetch_tx("ExperienceCategory");
  }, []);

  const id = useMemo(
    () => ensure_id_is_iri(matches.id || "", "User"),
    [matches.id]
  );

  useEffect(() => {
    analytics.log_event(analytics.EVENTS.view_profile);
  }, [id]);

  const post_id = useMemo(
    () =>
      matches.tab === "post" && matches.subtab
        ? ensure_id_is_iri(matches.subtab, "Post")
        : undefined,
    [matches.subtab]
  );
  const [get_user, { error }] = useApiRequest("getUser", id, user_params);

  const [user, fetch_user] = useRecordForDetailPage(id, get_user);
  return error ? (
    <DefaultErrorView error={error} />
  ) : (
    <UserProfileOuter user={user} fetch={fetch_user} tab={matches.tab}>
      <UserProfile user={user} userId={id} postId={post_id} tab={matches.tab} />
      {self?.id !== id && (
        <RedirectSubroute
          path="/people/:id/edit"
          to="/people/:id"
          matches={matches}
        />
      )}
      {self?.id !== id && (
        <RedirectSubroute
          path="/people/:id/settings"
          to="/people/:id"
          matches={matches}
        />
      )}
    </UserProfileOuter>
  );
};
