import * as Preact from "preact";
import { useCallback, useEffect, useRef } from "preact/hooks";
import { HTML_PREFIX } from "@thrive-web/ui-constants";
import { Card, PageBody, PageContent } from "@thrive-web/ui-components";
import {
  useDebounce,
  useDocumentTitle,
  useResizeObserver,
  useStateIfMounted,
  useValueRef,
  useWindowEventListener,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

export const PrivacyPolicy: Preact.FunctionComponent<RoutePageProps> = () => {
  useDocumentTitle(() => make_title(["Privacy Policy"]), []);
  const iframe = useRef<HTMLIFrameElement>();
  const content_window = useValueRef(
    iframe.current?.contentWindow?.document?.documentElement || null
  );
  const [height, set_height] = useStateIfMounted(0);
  const [loaded, set_loaded] = useStateIfMounted(false);

  // track/update height manually to fit the iframe to the page
  const update_height = useCallback(() => {
    const elem = iframe.current?.contentWindow?.document?.body;
    if (!elem) {
      return;
    }
    set_height(elem.offsetHeight);
  }, [set_height]);

  const on_resize_listener = useDebounce(update_height, 150);
  useResizeObserver(content_window, on_resize_listener, loaded);
  const enableListener = useWindowEventListener("resize", on_resize_listener);
  useEffect(() => {
    enableListener(true);
  }, []);

  return (
    <PageContent id="privacy-policy">
      <PageBody>
        <Card>
          <iframe
            style={{ height: height ? `${height}px` : undefined }}
            ref={iframe}
            src={`${HTML_PREFIX}/privacy-policy.html`}
            onLoad={() => {
              set_loaded(true);
              on_resize_listener.current?.();
            }}
          />
        </Card>
      </PageBody>
    </PageContent>
  );
};
