import * as Preact from "preact";
import { EXPERIENCE_CATEGORIES } from "~/view/components";
import {
  CheckList,
  ErrorMessage,
  JoinStringList,
  RequestButtonWithIcon,
  SvgLoadMore,
  TAXONOMIES_CTX,
  Tooltip,
} from "@thrive-web/ui-components";
import {
  useApiCall,
  useAppUser,
  useModal,
  useStateIfMounted,
  useDirtyForm,
} from "@thrive-web/ui-hooks";
import {
  add_item_to,
  analytics,
  deep_equals,
  make_displayable_error,
  remove_item_from,
} from "@thrive-web/ui-common";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import {
  makeProfileBuilderModalBody,
  ProfileBuilderItem,
} from "./ProfileBuilderItem";

export const ProfileBuilderExperiences: Preact.FunctionComponent<{
  step: number;
  name: string;
  description: string;
  button_text: string;
  button_icon: FontIconName;
  finishStep: (key: string, data: any) => void;
  currentStep: number;
}> = ({ button_icon, button_text, finishStep, currentStep, ...props }) => {
  const user = useAppUser();
  const [data, set_data] = useStateIfMounted<string[] | null>(null);
  const { value: exp_tx, map: exp_map } = useContext(
    TAXONOMIES_CTX.ExperienceCategory
  );
  const [experiences, setExperiences] =
    useStateIfMounted<null | InputOptions<string>>(null);

  const onFinish = useCallback(
    (data: string[]) => {
      if (!exp_map) {
        return;
      }
      set_data(data.map(e => exp_map[e]?.name).filter(e => !!e) as string[]);
    },
    [exp_map]
  );

  useEffect(() => {
    if (exp_tx && exp_map) {
      // @ts-expect-error: name is string | undefined
      setExperiences(exp_tx.map(e => ({ value: e.id, label: e.name })));
      if (user?.has_experience && user.has_experience.length > 0) {
        onFinish(user.has_experience?.map(e => e.id));
      }
    }
  }, [exp_tx, exp_map]);

  const description = useMemo(() => {
    if (!data || data.length === 0) {
      return props.description;
    }
    return (
      <Preact.Fragment>
        I have experience with: {<JoinStringList>{data}</JoinStringList>}, and
        can provide support to others in the community.
      </Preact.Fragment>
    );
  }, [props.description, data]);

  useEffect(() => {
    if (data) {
      finishStep("experiences", data);
    }
  }, [finishStep, data]);

  const [communityModal, setCommunityModalOpen] = useModal(
    {
      id: "profile-builder-community",
      className: "profile-builder__modal profile-builder__community",
      dismissOnClickBackdrop: false,
      showCloseButton: true,
      body: makeProfileBuilderModalBody(
        "How can you help?",
        ProfileBuilderExperiencesModal,
        onFinish
      ),
    },
    undefined,
    true
  );
  const openModal = setCommunityModalOpen.bind(this, true);

  const { Provider } = EXPERIENCE_CATEGORIES;

  const complete =
    !!experiences && currentStep >= props.step && !!data && data.length > 0;

  return (
    <ProfileBuilderItem
      {...props}
      complete={complete}
      description={description}
      button={
        <RequestButtonWithIcon
          className={`filled${complete ? " gray" : ""}`}
          disabled={currentStep < props.step || !experiences}
          onClick={openModal}
          icon={button_icon}
          side="left"
          pending={currentStep >= props.step && !experiences}
          successText="Add or Remove Experiences"
        >
          {!complete ? button_text : "Add or Remove Experiences"}
        </RequestButtonWithIcon>
      }
    >
      <Provider value={experiences}>{communityModal}</Provider>
    </ProfileBuilderItem>
  );
};

export const ProfileBuilderExperiencesModal: Preact.FunctionComponent<{
  dismiss: () => void;
  open: boolean;
  finishStep: (data: string[]) => void;
}> = ({ finishStep, dismiss }) => {
  const user = useAppUser();
  const [selected, setSelection] = useStateIfMounted<string[]>(
    user?.has_experience?.map(e => e.id) || []
  );
  const options = useContext(EXPERIENCE_CATEGORIES);
  const onFinish = useCallback(
    data => {
      finishStep(data);
      dismiss();
    },
    [finishStep, dismiss]
  );
  const clearFormDirtyState = useDirtyForm(
    selected,
    "ProfileBuilderExperiences"
  );

  const onChangeCheckbox = (opt, checked) =>
    setSelection(
      checked
        ? add_item_to(selected, opt.value)
        : remove_item_from(selected, v => v === opt.value)
    );

  const [submitGifts, { pending, success, error }] = useApiCall("updateUser");
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!user || selected.length === 0) {
        return;
      }
      if (
        deep_equals(
          selected,
          user?.has_experience?.map(e => e.id)
        )
      ) {
        dismiss();
        return;
      }
      submitGifts(user.id, {
        body: {
          data: {
            relationships: {
              has_experience: { data: selected.map(id => ({ id })) },
            },
          },
        },
      }).then(() => {
        analytics.log_event(analytics.EVENTS.profile_complete);
        clearFormDirtyState();
        onFinish(selected);
      });
    },
    [submitGifts, selected, onFinish]
  );

  return (
    <form id="profile-builder-community" onSubmit={onSubmit}>
      <div className="profile-builder__content stack">
        <div className="profile-builder__content__prompt">
          Share with the Thread community the experiences you've had or helped
          others overcome.
        </div>
        {options ? (
          <div className="profile-builder__community__form stack__scrolling-content">
            <CheckList
              onSelectOption={onChangeCheckbox}
              options={options}
              values={selected}
            />
          </div>
        ) : (
          <div className="profile-builder__community__form__loading">
            <SvgLoadMore />
          </div>
        )}
        <div className="profile-builder__footer modal__footer">
          {error && (
            <ErrorMessage>{make_displayable_error(error).message}</ErrorMessage>
          )}
          <Tooltip
            text="Please select at least one option before continuing."
            disabled={selected.length > 0}
            defaultOffset="left"
            defaultDirection="top"
          >
            <RequestButtonWithIcon
              className="button filled"
              icon="chevron-right"
              side="right"
              type="submit"
              pending={pending}
              success={success}
              successText="Success!"
            >
              Save Selections
            </RequestButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </form>
  );
};
