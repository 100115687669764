import * as Preact from "preact";
import * as hooks from "preact/hooks";
import { AuthWrapper } from "@thrive-web/ui-auth";
import { pick } from "@thrive-web/ui-common";
import { AppModel } from "./Model";
import { INITIAL_APP_STATE } from "./initial-state";
import { track_window_size } from "./track_window_size";
import { AppContexts, Context, Dispatch } from "./types";
import { AppEvent, AppEventName } from "./events/types";

export type AppContextDict = {
  [K in keyof AppContexts]: Preact.Context<AppContexts[K]>;
} & {
  dispatch: Preact.Context<Dispatch>;
};

// @ts-ignore
export const CONTEXTS: AppContextDict = {};
/*CONTEXTS.dispatch = Preact.createContext(async () => {});

Object.entries(INITIAL_APP_STATE).forEach(([key, value]) => {
  CONTEXTS[key] = Preact.createContext(value);
});*/

export class Provider extends Preact.Component<{
  model: AppModel;
  keys?: (keyof AppContexts)[];
  authWrapper: AuthWrapper;
  contexts: AppContextDict;
}> {
  constructor(props) {
    super(props);
    Object.keys(
      props.keys ? pick(props.keys, INITIAL_APP_STATE) : INITIAL_APP_STATE
    ).forEach(key => {
      if (key === "dispatch") {
        this._state[key] = this.dispatch;
      } else {
        this._state[key] = props.model.state.addView(key);
      }
    });

    if (props.keys) {
      this.picked = pick(props.keys, props.contexts);
    } else {
      this.picked = props.contexts;
    }
  }
  _state = {};
  mounted: boolean;
  picked: Partial<AppContextDict>;

  componentDidMount(): void {
    this.mounted = true;
    this.props.authWrapper.registerDispatch(this.dispatch);
    track_window_size(size =>
      this.dispatch({ type: "SET_WINDOW_SIZE", payload: { size } })
    );
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  dispatch: Dispatch = async <T extends AppEventName>({
    delay,
    ...event
  }: AppEvent<T>) => {
    const { dispatch } = this.props.model;
    if (delay) {
      setTimeout(() => {
        // @ts-ignore
        dispatch(event).then(() => {
          if (this.mounted) {
            this.forceUpdate();
          }
        });
      }, delay);
    } else {
      // @ts-ignore
      dispatch(event).then(() => {
        if (this.mounted) {
          this.forceUpdate();
        }
      });
    }
  };

  render() {
    return Object.entries(this.picked).reduce(
      (children, [key, { Provider }]: any) => (
        <Provider
          value={key === "dispatch" ? this.dispatch : this._state[key].deref()}
        >
          {children}
        </Provider>
      ),
      this.props.children
    );
  }
}

// This wraps the given component in a higher order component
export const connect = <
  K extends keyof AppContexts,
  P extends object,
  Q extends boolean
>(
  Component: Preact.ComponentType<
    ExcludeProperties<P, Context<K, Q>> & Context<K, Q>
  >,
  with_dispatch: Q,
  select: K[],
  contexts: AppContextDict
): Preact.FunctionComponent<ExcludeProperties<P, Context<K, Q>>> => {
  if (with_dispatch) {
    // @ts-ignore
    select.push("dispatch");
  }
  const componentName = Component.displayName || Component.name;
  const Connected = select.reduce(
    (WrappedComponent, key: K) => props => {
      // @ts-ignore
      console.log(`key, CONTEXTS[key]: `, key, contexts[key]);
      const value = hooks.useContext(contexts[key]);
      WrappedComponent.displayName = `${componentName}-${key}`;
      // @ts-ignore
      return <WrappedComponent {...props} {...{ [key]: value }} />;
    },
    Component
  );

  Connected.displayName = `${
    Component.displayName || Component.name
  }-Connected`;
  // @ts-ignore
  return Connected as ExcludeProperties<P, Context<K, Q>> & Context<K, Q>;
};
