import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { Event, EventRSVP, User } from "@thrive-web/ui-api";
import { RSVPResponses } from "@thrive-web/ui-constants";
import { analytics, capitalize, is_id_obj } from "@thrive-web/ui-common";
import {
  DEFAULT_USER_FIELDS,
  DefaultModalContent,
  useAsyncRenderResult,
  UserListItem,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useModal,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { get_cache } from "@thrive-web/ui-utils";

export const AttendeeUserList = (users: User[]) => {
  return (
    <div className="stack">
      <ul className="user-list stack__scrolling-content">
        {users.map(c => (
          <UserListItem key={c.id} user={c} linkBox="all" />
        ))}
      </ul>
    </div>
  );
};

export const AttendeeUserListModalBody: Preact.FunctionComponent<
  ModalBodyProps & {
    users: User[];
    category: RSVPResponses | "invited";
    eventId: string;
    needsFetch: boolean;
  }
> = ({ users, eventId, needsFetch, category, closeButton }) => {
  const [fetched, set_fetched] = useStateIfMounted<User[] | undefined>(users);
  const get_rsvps_req = useApiMethod("getEventRSVPs");
  const get_rsvps_then = useCallback(
    () =>
      get_rsvps_req({
        query: {
          filter: [
            ["=", ["this", "EventRSVP:event"], ["id", eventId]],
            ["=", ["this", "EventRSVP:response"], category],
          ],
          include: ["created_by.User:profile_picture"],
          fields: {
            User: DEFAULT_USER_FIELDS,
          },
          limit: users.length,
        },
      }).then(res => {
        // map get list of creators
        set_fetched(res.data.map(r => r.created_by as User));
        return res;
      }),
    [set_fetched, eventId, category, users.length]
  );
  const [get_rsvps, status] = useRequest(get_rsvps_then);
  useEffect(() => {
    if (needsFetch) {
      get_rsvps();
    }
    // clear list on unmount, or on category/event change
    return () => {
      set_fetched(undefined);
    };
  }, [category, eventId]);

  const content = useAsyncRenderResult(
    AttendeeUserList,
    [],
    status,
    fetched,
    false
  );
  return (
    <DefaultModalContent title={capitalize(category)} closeButton={closeButton}>
      {needsFetch ? content : AttendeeUserList(users)}
    </DefaultModalContent>
  );
};

export const AttendeeUserListModal: Preact.FunctionComponent<{
  category?: RSVPResponses | "invited";
  clearCategory: () => void;
  event: Event;
}> = ({ category, clearCategory, event }) => {
  const rsvps = (event.has_rsvp as EventRSVP[]) || [];
  const body_props = useMemo(() => {
    let missing_user = false;
    // check if there are any rsvps created by users that aren't in has_invitee
    const props = {
      category,
      eventId: event.id,
      users:
        category === "invited"
          ? event.has_invitee
          : rsvps
              ?.filter(r => r.response === category)
              .map(r => {
                let user = r.created_by;
                if (user && is_id_obj(user)) {
                  user = get_cache(user.id) || user;
                }
                if (!user || is_id_obj(user)) {
                  missing_user = true;
                }
                return user;
              }),
    };
    // if there are, we need to fetch the rsvp list
    return { ...props, needsFetch: category !== "invited" && missing_user };
  }, [category]);

  const [modal, set_modal_open] = useModal(
    {
      id: `event-users-modal-${event.id}`,
      innerClassName: "stack user-list__modal event-detail__responses__modal",
      body: AttendeeUserListModalBody,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      showCloseButton: true,
      bodyProps: body_props,
    },
    clearCategory
  );

  useEffect(() => {
    if (category && (body_props.users?.length || 0) > 0) {
      analytics.log_event(analytics.EVENTS.event_view_RSVP);
      set_modal_open(true);
    }
  }, [rsvps, category]);

  return modal;
};
