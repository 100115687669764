import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { ApiMethodParameters } from "@thrive-web/core";
import { ExperienceCategory, Group, WritePost } from "@thrive-web/ui-api";
import { analytics } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  Carousel,
  DefaultModalContent,
  PillCheckList,
  PostHelpFormGroup,
  ScopeItem,
  TaxonomiesContextSpec,
  TextAreaWithFormHelpers,
  Tooltip,
  useAsyncRenderResult,
  useTaxonomy,
  WithPostScopeCtx,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useDirtyForm,
  useMemoRef,
  useRequest,
  useStateIfMounted,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { join_string_list } from "@thrive-web/ui-utils";

const scope_query: ApiMethodParameters<"GET", "Group", false>["query"] = {
  limit: 5,
};

export const PostHelpForm: Preact.FunctionComponent<{
  initialCategory?: ExperienceCategory;
  initialScope?: ScopeItem;
  initialBody?: string;
  shouldWarnScopeChange: boolean;
  allowScopeChange: boolean;
  onFinish: (
    data: WritePost,
    scope?: ScopeItem,
    exp?: ExperienceCategory
  ) => void;
}> = ({
  initialBody,
  initialCategory,
  onFinish,
  initialScope,
  shouldWarnScopeChange,
  allowScopeChange,
}) => {
  const [page, set_page, page_ref] = useStateRef(0);
  // initial/minimum page count is 2
  const page_count = useRef(2);
  const [category, set_category] = useStateIfMounted(initialCategory);
  const [scope, set_scope] = useStateIfMounted(initialScope);
  const [text, set_text] = useStateIfMounted(initialBody);

  const all_form_data = useMemo(
    () => ({
      category,
      text,
    }),
    [category?.id, text]
  );
  const clearDirtyFormState = useDirtyForm(all_form_data, "PostHelpForm", true);

  // skip the scope change warning unless a scope change would modify other data in the
  // post besides the help block and body text
  const should_skip_scope_change_warning = useMemoRef(
    () =>
      shouldWarnScopeChange &&
      allowScopeChange &&
      !!scope &&
      !!initialScope &&
      scope.id === initialScope?.id,
    [initialScope?.id, allowScopeChange, shouldWarnScopeChange, scope?.id, page]
  );

  const next_page = useCallback(() => {
    if (page_ref.current === 1 && should_skip_scope_change_warning.current) {
      set_page(page_ref.current + 2);
      return;
    }
    // if on the last page and all fields are filled
    if (page_ref.current === page_count.current - 1 && category && text) {
      analytics.log_event(
        analytics.EVENTS.post.ask_for_help,
        undefined,
        undefined,
        true
      );
      onFinish(
        {
          is_asking_for_help: true,
          body: text,
        },
        scope !== initialScope ? scope : undefined,
        category
      );
      clearDirtyFormState();
      return;
    }
    set_page(page_ref.current + 1);
  }, [category, text, scope, onFinish]);

  const prev_page = useCallback(() => {
    if (page_ref.current === 0) {
      onFinish({}, scope, initialCategory);
      clearDirtyFormState();
      return;
    }
    set_page(page_ref.current - (page_ref.current === 3 ? 2 : 1));
  }, [category, text]);

  const pages_arr = [
    <PostHelpFormTopic
      value={category}
      onChange={set_category}
      nextStep={next_page}
      prevStep={prev_page}
    />,
  ];

  if (allowScopeChange) {
    pages_arr.push(
      <WithPostScopeCtx
        threadOnlyInitial={false}
        type="Group"
        query={scope_query}
      >
        <PostHelpFormGroup
          experience={category}
          initialScope={initialScope}
          onChangeScope={set_scope}
          scope={scope}
          nextStep={next_page}
          prevStep={prev_page}
        />
      </WithPostScopeCtx>
    );
  }

  if (allowScopeChange && shouldWarnScopeChange) {
    pages_arr.push(
      <div className="post-help__section">
        <div className="modal-form__section form__section">
          <p className="info">
            <strong>Warning</strong>: You started making a post to{" "}
            <strong>{initialScope?.name}</strong>. If you change the group
            you're posting to, you will lose any Group Update, Reimbursement, or
            Tagged Users that you've added to the post so far.
          </p>
        </div>
        <div className="modal__footer">
          <div className="modal__footer__left">
            <button className="filled gray" onClick={prev_page}>
              Cancel
            </button>
          </div>
          <div className="modal__footer__right">
            <ButtonWithIcon
              className="filled gray"
              onClick={next_page}
              icon="chevron-right"
              side="right"
            >
              Next
            </ButtonWithIcon>
          </div>
        </div>
      </div>
    );
  }
  pages_arr.push(
    <PostHelpFormText
      value={text}
      onChange={set_text}
      group={scope}
      nextStep={next_page}
      prevStep={prev_page}
    />
  );

  page_count.current = pages_arr.length;
  const pages = Object.entries(pages_arr).reduce((obj, [key, content]) => {
    obj[key] = content;
    return obj;
  }, {});

  return (
    <div className="post-help__form">
      <DefaultModalContent title="Get Help">
        <Carousel
          page={`${page}` as keyof typeof pages}
          items={pages}
          trackHeight={true}
        />
      </DefaultModalContent>
    </div>
  );
};

const PostHelpFormTopicBase: Preact.FunctionComponent<{
  categories: TaxonomiesContextSpec<"ExperienceCategory">;
  onChange: (opt: ExperienceCategory) => void;
  value?: ExperienceCategory;
  nextStep: () => void;
  prevStep: () => void;
}> = ({ onChange, value, nextStep, prevStep }) => {
  const options_raw = useTaxonomy("ExperienceCategory");
  // map taxonomy to InputOptions
  const options = useMemo(
    () =>
      options_raw.value
        ? options_raw.value.map(o => ({
            value: o.id,
            label: o.name || "",
          }))
        : undefined,
    [options_raw]
  );

  const onChangeActions = useCallback(
    (opt: InputOption<string>) =>
      options_raw.map && onChange(options_raw.map[opt.value]),
    [onChange, options_raw.map]
  );

  const passthru_props = useMemo(
    () => ({
      values: value ? [value.id] : [],
    }),
    [value]
  );

  // display the checklist once the taxonomy is fetched
  const checklist = useAsyncRenderResult<InputOption<string>[]>(
    (result, _, pass_props) => (
      <PillCheckList
        options={result}
        onSelectOption={onChangeActions}
        {...pass_props}
      />
    ),
    [onChangeActions],
    options_raw.status,
    options,
    true,
    undefined,
    undefined,
    false,
    passthru_props
  );

  return (
    <div className="post-help__section">
      <div className="modal-form__section form__section">
        <div className="form__section-header">
          I need help from the community with...
        </div>
        {checklist}
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <button className="filled gray" onClick={prevStep}>
            Cancel
          </button>
        </div>
        <div className="modal__footer__right">
          <Tooltip
            text="Please provide some details of what you need help with."
            disabled={!!value}
            defaultDirection="top"
            defaultOffset="left"
          >
            <ButtonWithIcon
              className="filled gray"
              onClick={!!value ? nextStep : undefined}
              icon="chevron-right"
              side="right"
            >
              Next
            </ButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const exp_params = {
  query: {
    filter: [
      [
        "=",
        ["this", ["/", ["^", "Group:provides_help_with"], "Group:is_private"]],
        false,
      ],
    ],
  },
} as const;
const PostHelpFormTopic: Preact.FunctionComponent<{
  onChange: (opt: ExperienceCategory) => void;
  value?: ExperienceCategory;
  nextStep: () => void;
  prevStep: () => void;
}> = props => {
  const [categories, set_categories] = useStateIfMounted<
    Omit<TaxonomiesContextSpec<"ExperienceCategory">, "status">
  >({
    value: null,
    map: null,
  });
  const fetch_tx_req = useApiMethod("getExperiences");
  const fetch_tx_then = useCallback(
    () =>
      fetch_tx_req(exp_params).then(({ data }) => {
        const map: any = {};
        data.forEach(t => {
          map[t.id] = t;
        });
        set_categories({
          value: data,
          map,
        });
      }),
    []
  );
  const [fetch_tx, status] = useRequest(fetch_tx_then);

  const spec = useMemo<TaxonomiesContextSpec<"ExperienceCategory">>(
    () => ({
      ...categories,
      status,
    }),
    [categories, status]
  );

  useEffect(() => {
    fetch_tx();
  }, []);

  return <PostHelpFormTopicBase categories={spec} {...props} />;
};

const PostHelpFormText: Preact.FunctionComponent<{
  value?: string;
  onChange: (value: string) => void;
  group?: Group;
  nextStep: () => void;
  prevStep: () => void;
}> = ({ value, onChange, group, prevStep, nextStep }) => {
  const on_change = useCallback(e => onChange(e.target.value), [onChange]);
  return (
    <div className="post-help__section">
      <div className="modal-form__section form__section">
        <div className="form__section-header">
          Please provide some details of what you need help with.
        </div>
        <TextAreaWithFormHelpers
          className="input__no-title"
          onChange={on_change}
          value={value}
          placeholder="What do you need help with?"
        />
      </div>
      <div className="modal-form__section form__section post-help__disclaimer">
        {!group?.is_private && (
          <p>
            <em>
              CAUTION: What you share to here will be posted to a public group
              {group?.provides_help_with?.length
                ? ` that deals with ${join_string_list(
                    group.provides_help_with.map(
                      e => (e as ExperienceCategory).name || ""
                    )
                  )}`
                : ""}
              . Please use caution when sharing details about yourself and
              details about others.
            </em>
          </p>
        )}
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <button className="filled gray" onClick={prevStep}>
            Back
          </button>
        </div>
        <div className="modal__footer__right">
          <Tooltip
            text="Please provide some details of what you need help with."
            disabled={!!value}
            defaultDirection="top"
            defaultOffset="left"
          >
            <ButtonWithIcon
              className="filled gray"
              onClick={!!value ? nextStep : undefined}
              icon="chevron-right"
              side="right"
            >
              Next
            </ButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
