import { useCallback } from "preact/hooks";
import {
  ApiMethodParameters,
  FilterSpec,
  map_record_to_json_resource,
  TYPES,
} from "@thrive-web/core";
import {
  ApiMethodCaller,
  Community,
  Group,
  MappedApiResponse,
  Post,
  User,
  ApiMethodParameters as ApiCallParameters,
} from "@thrive-web/ui-api";
import { entity_has_type, is_id_obj } from "@thrive-web/ui-common";
import { PostCreateBlocksResponse, ScopeItem } from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useRequestChain,
} from "@thrive-web/ui-hooks";

// get the name and color of the group that the post is posted to
export const getScope = (
  posted_to: Group | Community | Post
): {
  name?: string;
  color?: string;
} => {
  if (entity_has_type(posted_to, "Group")) {
    return {
      name: posted_to.name,
      color: posted_to.background_color,
    };
  }
  if (entity_has_type(posted_to, "Community")) {
    return {
      name: posted_to.name,
      color: posted_to.accent_color,
    };
  }
  return {};
};

// query for groups that the user can post to, with search and extra query params
export const post_scope_group_query = (
  user: User | null,
  search?: string,
  params?: ApiMethodParameters<"GET", "Group", false>
): ApiMethodParameters<"GET", "Group", false> => {
  let filter: FilterSpec = [
    ["=", ["this", "Group:has_member"], ["id", user!.id as string]],
  ];
  if (search) {
    filter = filter.concat([["match", ["this", "Group:name"], search, "i"]]);
  }
  return {
    query: {
      sort: [{ by: "last_activity_at", dir: "desc" }],
      include_count: true,
      include: ["has_admin", "provides_help_with", "background_image"],
      ...params,
      filter,
    },
  };
};

// query for communities that the user can post to, with search
export const post_scope_comm_query = (
  user: User | null,
  search?: string
): FilterSpec => {
  let filter: FilterSpec = [
    [
      "or",
      ["=", ["this", "Community:has_moderator"], ["id", user!.id as string]],
      ["=", ["this", "Community:has_manager"], ["id", user!.id as string]],
      ["=", ["this", "Community:has_admin"], ["id", user!.id as string]],
    ],
  ];
  if (search) {
    filter = filter.concat([["match", ["this", "Group:name"], search, "i"]]);
  }
  return filter;
};

// returns: a callback that returns true if the user is allowed to interact with
// the given post
// if scope is provided, it will check whether the user can interact with any post in
// that scope
export const userCanInteract = (scope?: ScopeItem) => {
  const self = useAppUser();
  return useCallback(
    (post: Post) => {
      if (!self?.id) {
        return false;
      }
      let scope_rec = scope;
      if (!scope_rec || is_id_obj(scope_rec)) {
        scope_rec = post.posted_to as ScopeItem;
      }
      if (!scope_rec || is_id_obj(scope_rec)) {
        return false;
      }

      return !!(scope_rec as ScopeItem).has_member?.some(u => u.id === self.id);
    },
    [scope?.has_member, self?.id]
  );
};

export const useTouchpointAndExpenseCreate = create_expense => {
  // POST type/Touchpoint
  const create_touchpoint = useApiMethod("createTouchpoint");

  // combine resulting touchpoint and expense for Post data
  const combine_expense_and_tp = useCallback(
    (
      tp: MappedApiResponse<"createTouchpoint">,
      expense: MappedApiResponse<"createExpense">
    ): PostCreateBlocksResponse => {
      return {
        has_touchpoint: tp.data,
        has_expense: expense.data,
      };
    },
    []
  );
  // get id from new touchpoint to add to the expense data
  const get_expense_params_from_tp = useCallback(
    (
      tp: MappedApiResponse<"createTouchpoint">,
      params: ApiCallParameters<"createExpense">
    ): ApiCallParameters<"createExpense"> => {
      const data = params[0];
      if (data?.body?.data?.relationships) {
        data.body.data.relationships.touchpoint = {
          data: { id: tp.data.id },
        };
      }
      params[0] = data;
      return params;
    },
    []
  );

  // chain touchpoint/expense requests together
  const createTouchpointAndExpenseChained = useRequestChain<
    ApiMethodCaller<"createTouchpoint">,
    ApiMethodCaller<"createExpense">,
    PostCreateBlocksResponse
  >(
    create_touchpoint,
    create_expense,
    combine_expense_and_tp,
    get_expense_params_from_tp
  );

  // create tp/expense using post data
  return useCallback(
    (new_post: Post): Promise<PostCreateBlocksResponse> => {
      const tp = new_post.has_touchpoint;
      const exp = new_post.has_expense;

      if (!exp && !tp) {
        return Promise.resolve({});
      }

      const {
        id: exp_id,
        type: __,
        ...exp_data
      } = exp ? map_record_to_json_resource(exp, TYPES.Expense) : ({} as any);

      const {
        id: tp_id,
        type: _,
        ...tp_data
      } = tp ? map_record_to_json_resource(tp, TYPES.Touchpoint) : ({} as any);

      const params_2: ApiCallParameters<"createExpense"> = [
        {
          body: { data: exp_data },
          // @ts-expect-error:
          query: {
            include: ["has_category", "has_reason"],
          },
        },
      ];
      const params_1: ApiCallParameters<"createTouchpoint"> = [
        {
          body: { data: tp_data },
          // @ts-expect-error:
          query: {
            include: ["has_action", "has_accomplishment", "has_goal"],
          },
        },
      ];

      if (!tp) {
        return create_expense(...params_2).then(res => ({
          has_expense: res.data,
        }));
      }

      if (!exp) {
        return create_touchpoint(...params_1).then(res => ({
          has_touchpoint: res.data,
        }));
      }

      return createTouchpointAndExpenseChained(params_1, params_2);
    },
    [create_touchpoint, create_expense, createTouchpointAndExpenseChained]
  );
};
