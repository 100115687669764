import * as Preact from "preact";
import { WriteGroup } from "@thrive-web/ui-api";
import { RequestButtonWithIcon, ErrorMessage } from "@thrive-web/ui-components";
import {
  useApiMethod,
  useDirtyForm,
  useDocumentTitle,
  useForm,
  useRequestWithMedia,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import { useCallback, useMemo } from "preact/hooks";
import { map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { GroupForm } from "./GroupForm";

/** group name, color, background, purpose, and features */
export const GroupCreate: Preact.FunctionComponent<{
  onFinish: (formData: WriteGroup) => void;
  doInviteStep?: boolean;
  dismiss: () => void;
}> = ({ onFinish, doInviteStep = true, dismiss }) => {
  useDocumentTitle(() => make_title(["Create a Group"]), []);
  const [formData, setField, onInputChange] = useForm<WriteGroup>({
    has_goals: false,
    has_relationship_management: false,
    can_members_invite: false,
  });
  const [file, onChangeFile] = useStateIfMounted<FileUploadData | undefined>(
    undefined
  );

  const createGroup = useApiMethod("createGroup");

  const [submitForm, { pending, error }, media_pending, progress] =
    useRequestWithMedia(
      "Group",
      "background_image",
      createGroup,
      file,
      false,
      true
    );

  // data used to determine form dirty state
  const allFormData = useMemo(
    () => ({
      ...formData,
      // file is initially `undefined`, so if it has a value, it has changed
      background_image: !!file,
    }),
    [formData, !!file]
  );
  const clearDirtyFormState = useDirtyForm(allFormData, "GroupCreate", true);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      const { id, type, ...data } = map_record_to_json_resource(
        {
          ...formData,
          has_goals: formData.is_private,
          has_relationship_management: formData.is_private,
          can_members_invite: true,
          id: "",
        },
        TYPES.Group
      );
      if (pending || !formData.name || !formData.purpose) {
        return;
      }

      submitForm({
        body: { data, meta: {} },
      }).then(({ data: new_group }) => {
        clearDirtyFormState();
        onFinish(new_group);
      });
    },
    [formData]
  );

  return (
    <GroupForm
      record={formData}
      onInputChange={onInputChange}
      onFieldChange={setField}
      onChangeFile={onChangeFile}
      photoFile={file}
      error={error}
      onSubmit={onSubmit}
    >
      <div className="group-create__footer">
        {error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : doInviteStep ? (
          <span>Next: Inviting Group Members</span>
        ) : (
          <button className="filled gray" type="button" onClick={dismiss}>
            Cancel
          </button>
        )}
        <RequestButtonWithIcon
          className="filled gray"
          // @ts-ignore
          icon="chevron-right"
          side="right"
          type="submit"
          pending={pending || media_pending}
          successText=""
          progress={progress}
        >
          {doInviteStep ? "Save & Continue" : "Create Group"}
        </RequestButtonWithIcon>
      </div>
    </GroupForm>
  );
};
