import * as Preact from "preact";
import { getCurrentUrl } from "preact-router";
import { useContext, useEffect, useLayoutEffect, useMemo } from "preact/hooks";
import { ScreenSize } from "@thrive-web/ui-constants";
import { EVENTS } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  Avatar,
  create_nav_links,
  create_userbar_links,
  NavMenu,
} from "@thrive-web/ui-components";
import { usePortal, useStateRef, useValueRef } from "@thrive-web/ui-hooks";

const SITE_HEADER_BOTTOM_CONTENT_ID = "site-header__bottom__content";

export const SiteHeader: Preact.FunctionComponent<{
  navLinks: NavLinkSpec[];
}> = ({ navLinks }) => {
  const auth = useContext(CONTEXTS.auth);
  const bottom_content_set = useContext(CONTEXTS.site_header_content_set);
  const window_size = useContext(CONTEXTS.window_size);

  const [right_content, center_content] = useMemo(() => {
    if (window_size > ScreenSize.sm || !auth?.user) {
      return [null, null];
    }
    const links = create_nav_links(navLinks);
    const menu_links = create_nav_links(
      navLinks,
      undefined,
      "pill gray filled with-icon"
    );
    const userbar_links = create_userbar_links(
      auth.user,
      "site-nav__link pill gray filled with-icon"
    );
    return [
      <NavMenu
        nav_items={[
          ...(window_size === ScreenSize.sm ? [] : menu_links.slice(4)),
          ...userbar_links,
        ]}
      />,
      <div className="site-nav__links">
        {links.slice(0, window_size === ScreenSize.sm ? undefined : 4)}
      </div>,
    ];
  }, [window_size]);

  return auth &&
    auth.user &&
    auth.userProfileBuilderComplete &&
    auth.hasGroups &&
    getCurrentUrl() !== "/guide" ? (
    <div
      className="site-header"
      id="site-header"
      data-with-content={bottom_content_set}
    >
      <header className="site-header__container">
        <div className="site-header__left">
          <div className="site-header__logo">
            <a href="/">
              <img
                className="site-header__logo__full"
                src={`${IMAGES_PREFIX}/logo_thread.svg`}
                alt="Thread Logo"
              />
              <img
                className="site-header__logo__icon"
                src={`${IMAGES_PREFIX}/logo.svg`}
                alt="Thread Logo"
              />
            </a>
          </div>
        </div>
        <div className="site-header__center">{center_content}</div>
        <div className="site-header__right">{right_content}</div>
      </header>
      <div className="fake-margins site-header__bottom">
        <div
          key={SITE_HEADER_BOTTOM_CONTENT_ID}
          className="site-header__bottom__content"
          id={SITE_HEADER_BOTTOM_CONTENT_ID}
        />
        <Avatar user={auth.user} size="md" isLink={true} />
      </div>
    </div>
  ) : null;
};

// hook that allows content (such as a search bar) to be placed in the center
// of the site header via a portal
export const useSiteHeaderContent = (show: boolean = true) => {
  const dispatch = useContext(CONTEXTS.dispatch);
  // indicates that the portal should be mounted in the SiteHeader component
  const content_set = useContext(CONTEXTS.site_header_content_set);
  const content_set_ref = useValueRef(content_set);
  const [visible, set_visible, visible_ref] = useStateRef(false);
  const render = usePortal(SITE_HEADER_BOTTOM_CONTENT_ID, show);
  const show_ref = useValueRef(show);

  // on Unmount, hide the header content
  useEffect(() => {
    return () => {
      visible_ref.current = false;
      dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: false });
    };
  }, []);

  // when the content becomes hidden, but show is still true
  useLayoutEffect(() => {
    if (!content_set && show_ref.current) {
      // show the header content
      dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: true });
      set_visible(true);
    }
  }, [content_set]);

  useEffect(() => {
    if (show) {
      if (!content_set_ref.current) {
        dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: true });
      }
      set_visible(true);
    } else {
      if (content_set_ref.current && visible_ref.current) {
        content_set_ref.current &&
          dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: false });
      }
      set_visible(false);
    }
  }, [show]);

  return visible ? render : _ => null;
};
