import * as Preact from "preact";
import { class_names, maybeClassName } from "@thrive-web/ui-utils";
import { Icon } from "@thrive-web/ui-components";
import { forwardRef } from "preact/compat";

interface TagProps {
  onRemove?: (e) => void;
  removeButtonProps?: HTMLButtonProps;
  iconSize?: "sm" | "md" | "lg";
  icon?: FontIconName | Preact.VNode;
}
export const Tag = forwardRef<HTMLDivElement, TagProps & HTMLDivProps>(
  (
    {
      className,
      onRemove,
      removeButtonProps,
      iconSize = "sm",
      icon,
      children,
      ...props
    },
    ref
  ) => (
    <div
      ref={ref}
      data-size={iconSize}
      className={`${class_names(
        {
          "__with-icon": !!icon,
          __removable: !!onRemove,
        },
        "tag"
      )}${maybeClassName(className)}`}
      {...props}
    >
      {icon && (
        <div className="tag__icon">
          {typeof icon === "string" ? (
            // @ts-ignore
            <Icon name={icon} />
          ) : (
            icon
          )}
        </div>
      )}
      <div className="tag__content">{children}</div>
      {onRemove && (
        <button
          {...removeButtonProps}
          type="button"
          className="tag__remove non-button color-overlay color-overlay__dark"
          onClick={onRemove}
        >
          <Icon name="remove" iconSize="xs" data-badge="remove-solid-circle" />
        </button>
      )}
    </div>
  )
);
