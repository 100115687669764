import * as Preact from "preact";
import { MappedApiResponse, Todo, WriteTodo } from "@thrive-web/ui-api";
import { GroupTodoForm } from "./GroupTodoForm";
import {
  DefaultModalContent,
  DeleteModalBody,
} from "@thrive-web/ui-components";
import { useCallback } from "preact/hooks";

export const GroupTodoCreateModal: Preact.FunctionComponent<
  ModalBodyProps & {
    onSubmit: (goal: WriteTodo) => Promise<MappedApiResponse<"createTodo">>;
  }
> = ({ closeButton, ...props }) => (
  <DefaultModalContent title="Add a To-Do" closeButton={closeButton}>
    <GroupTodoForm submitText="Add To-Do" submitIcon="add" {...props} />
  </DefaultModalContent>
);
export const GroupTodoEditModal: Preact.FunctionComponent<
  ModalBodyProps & {
    record: Todo;
    onSubmit: (goal: WriteTodo) => Promise<MappedApiResponse<"updateTodo">>;
  }
> = ({ closeButton, ...props }) => (
  <DefaultModalContent title="Edit To-Do" closeButton={closeButton}>
    <GroupTodoForm submitText="Save Changes" submitIcon="checked" {...props} />
  </DefaultModalContent>
);
export const GroupTodoDeleteModal: Preact.FunctionComponent<
  ModalBodyProps & {
    record: Todo;
    onSubmit: (goal: WriteTodo) => Promise<MappedApiResponse<"deleteTodo">>;
  }
> = ({ closeButton, onSubmit, ...props }) => {
  const deleteTodo = useCallback(
    () => onSubmit(props.record),
    [onSubmit, props.record]
  );
  return (
    <DefaultModalContent title="Delete To-Do" closeButton={closeButton}>
      <DeleteModalBody {...props} deleteRecord={deleteTodo}>
        Are you sure you want to delete this To-Do?
      </DeleteModalBody>
    </DefaultModalContent>
  );
};
