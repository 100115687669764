import * as Preact from "preact";
import { User } from "@thrive-web/ui-api";
import { Avatar } from "@thrive-web/ui-components";
import { get_url_for_entity, maybeClassName } from "@thrive-web/ui-utils";

export const UserCard: Preact.FunctionComponent<
  {
    user: User;
    health?: number;
    size?: IconSize;
    label?: string;
    nameIsLink?: boolean;
    avatarIsLink?: boolean;
  } & MaybeClass
> = ({
  avatarIsLink,
  nameIsLink,
  user,
  label,
  health,
  size = "md",
  children,
  className,
}) => {
  const content = nameIsLink ? (
    <a className="user-card__name" href={get_url_for_entity(user)}>
      {user.full_name}
    </a>
  ) : (
    <div className="user-card__name">{user.full_name}</div>
  );
  return (
    <div className={`user-card${maybeClassName(className)}`} data-size={size}>
      <div className="user-card__avatar">
        <Avatar user={user} size={size} health={health} isLink={avatarIsLink} />
      </div>
      <div className="user-card__right">
        {label && <div className="user-card__label">{label}</div>}
        {content}
        {children && <div className="user-card__text">{children}</div>}
      </div>
    </div>
  );
};
