import { getFirstFocusable, getLastFocusable } from "@thrive-web/ui-utils";

export interface TabFocusManagerMeta {
  // the element whose children should be the only focusable elements on the page
  container: Element;
  // the element that focus should be returned to when the focus trap is disabled
  trigger: HTMLElement | null;
  // specifies which actions that disable the focus trap should cause focus to return
  // to the trigger
  return_on_focus_leave?: ReturnFocusOption;
  // if true, focus trap should be disabled when the user clicks outside the container
  // (i.e. a modal)
  auto_clear_on_click_outside?: boolean;
}
declare global {
  interface Window {
    threadAppFocusMeta?: TabFocusManagerMeta;
    threadAppFocusManager: TabFocusManager;
  }
}
export type FocusChangeAction = "tab" | "click";
export type ReturnFocusOption = "all" | FocusChangeAction | "none";

const LISTENER_OPTIONS = {
  capture: true,
};

export class TabFocusManager {
  constructor() {
    this.metaStack = [];
  }
  // stack containing meta for each active focus trap
  metaStack: TabFocusManagerMeta[];
  // indicates whether the previous Tab movement was forward (Tab) or backward (Shift+Tab)
  lastMovement: "forward" | "backward" | undefined;
  // indicates whether the last action that changed focus was a Tab or a click
  lastAction: FocusChangeAction | undefined;

  // set the active focus trap
  setFocusContainer = (
    container: Element,
    trigger: HTMLElement | null,
    return_on_focus_leave?: ReturnFocusOption,
    // force focus trap, even if there are no focusable elements in the container
    force_trap?: boolean,
    auto_clear_on_click_outside: boolean = true
  ) => {
    // if no focusable elems inside container and NOT forcing trap, cancel
    if (!getFirstFocusable(container) && !force_trap) {
      // console.debug(`\tNo focusable elements in this container, cancelling...`);
      return;
    }
    if (window.threadAppFocusMeta) {
      // if the target container is already the current active focus trap, cancel
      if (window.threadAppFocusMeta.container === container) {
        return;
      }
      // move current active focus trap to the stack
      this.metaStack.push(window.threadAppFocusMeta);
    } else {
      // if no current active focus trap, a
      window.addEventListener("keydown", this.onTab, LISTENER_OPTIONS);
      window.addEventListener("focus", this.onFocusChanged, LISTENER_OPTIONS);
    }
    if (
      !window.threadAppFocusMeta?.auto_clear_on_click_outside &&
      auto_clear_on_click_outside
    ) {
      window.addEventListener("click", this.onClick, LISTENER_OPTIONS);
    }
    // set the new active focus trap
    window.threadAppFocusMeta = {
      container,
      trigger: trigger || (document.activeElement as HTMLElement),
      return_on_focus_leave: return_on_focus_leave || "none",
      auto_clear_on_click_outside,
    };
    container.dispatchEvent(new Event("focus-trapped", { bubbles: true }));
  };

  // clear focus trapping in the given container
  clearFocusContainer = (
    container: Element,
    return_focus?: ReturnFocusOption,
    last_action?: FocusChangeAction
  ) => {
    if (!window.threadAppFocusMeta || !container) {
      return;
    }
    // if the target container is not the currently active focus trap,
    // just remove it from the stack and return
    // console.debug(`disabling focus trap for`, container);
    if (window.threadAppFocusMeta.container !== container) {
      const filtered = this.metaStack.filter(m => m.container !== container);
      if (filtered.length !== this.metaStack.length) {
        this.metaStack = filtered;
      }
      return;
    }
    const prev = window.threadAppFocusMeta;
    window.threadAppFocusMeta = this.metaStack.pop();
    // if the last action (tab/click) matches what we specified, return focus to the
    // trigger for the (now inactive) focus trap
    if (return_focus === "all" || return_focus === last_action) {
      prev.trigger?.focus();
      // if the focused element is inside the prev focus trap container, blur it
    } else if (container.contains(document.activeElement)) {
      // @ts-expect-error:
      document.activeElement?.blur?.();
    }
    if (!window.threadAppFocusMeta) {
      window.removeEventListener("focus", this.onTab, LISTENER_OPTIONS);
      window.removeEventListener("click", this.onClick, LISTENER_OPTIONS);
      window.removeEventListener(
        "focus",
        this.onFocusChanged,
        LISTENER_OPTIONS
      );
    }
  };

  focusFirstFocusable = (container: Element) => {
    const first = getFirstFocusable(container);
    if (first instanceof HTMLElement) {
      first.focus();
    }
  };

  focusLastFocusable = (container: Element) => {
    const last = getLastFocusable(container);
    if (last instanceof HTMLElement) {
      last.focus();
    }
  };

  // listener for plain "Tab" or "Shift+Tab" keydown
  onTab = e => {
    if (e.key !== "Tab" || e.metaKey || e.ctrlKey || e.altKey) {
      return;
    }
    this.lastAction = "tab";
    if (!e.shiftKey) {
      this.lastMovement = "forward";
    } else {
      this.lastMovement = "backward";
    }
  };

  onFocusChanged = e => {
    // console.debug(`focus changed: `, e);
    const meta = window.threadAppFocusMeta;
    const { lastMovement, lastAction } = this;
    this.lastMovement = undefined;
    this.lastAction = undefined;
    // if no focus trap, or target is not Window and target is inside container, cancel
    if (!meta || (e.target !== window && meta.container?.contains(e.target))) {
      return;
    }
    // we now know that focus has escaped the trap
    e.preventDefault();
    // if focus is to be returned upon leaving the trap, clear the active trap
    if (meta.return_on_focus_leave !== "none") {
      this.clearFocusContainer(
        meta.container,
        meta.trigger && meta.trigger === e.target
          ? "all"
          : meta.return_on_focus_leave,
        lastAction
      );
      return;
    }
    // otherwise, loop the focus back around to the other end of the container
    if (lastMovement === "backward") {
      this.focusLastFocusable(meta.container);
    } else {
      this.focusFirstFocusable(meta.container);
    }
  };

  onClick = e => {
    const meta = window.threadAppFocusMeta;
    if (
      !meta ||
      !meta.trigger ||
      !meta.auto_clear_on_click_outside ||
      e.ignoreThisClick ||
      (meta.trigger !== e.target &&
        !e.target.contains(meta.trigger) &&
        meta.trigger !== document.activeElement)
    ) {
      return;
    }
    // clear the focus container, because
    //   1. this focus trap is to be cleared when clicking outside the container
    //   2. the click is not ignored
    //   3. the target is not (/a child of) the trigger
    //   4. the trigger is not the focused element
    // console.log(`clicked on trigger`);
    this.clearFocusContainer(meta.container, "none", "click");
  };
}

window.threadAppFocusManager = new TabFocusManager();
