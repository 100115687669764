import * as Preact from "preact";
import { analytics } from "@thrive-web/ui-common";
import { ButtonWithIcon, Tooltip } from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiFetch,
  useAppUser,
  useRequest,
  useStateIfMounted,
  useDelayedEvent,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { get_guid_from_iri, maybeClassName } from "@thrive-web/ui-utils";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { Reaction } from "@thrive-web/ui-api";

export const ReactionButton: Preact.FunctionComponent<
  {
    post_id: string;
    reactions: readonly Reaction[];
    showCount: boolean;
    showUsers?: boolean;
    onUpdate: (reaction: Reaction, deleted: boolean) => void;
  } & MaybeClass
> = ({
  post_id,
  reactions,
  showCount,
  showUsers,
  className,
  onUpdate,
  children,
}) => {
  const user = useAppUser();

  // reaction created by the current user, if it exists
  const self_reacted = useMemo(
    () => reactions?.find(r => r.created_by?.id === user?.id)?.id || null,
    [reactions, user?.id]
  );
  const [log_event, cancel_event] = useDelayedEvent(
    (type: "Post" | "Comment") => {
      analytics.log_event(
        analytics.EVENTS[type === "Post" ? "post_like" : "comment_like"],
        undefined,
        undefined,
        true
      );
    },
    [],
    5000
  );
  const self_reacted_ref = useValueRef<string | null>(self_reacted);

  const create_reaction_params = useMemo(
    () => ({
      body: {
        data: {
          attributes: {},
          relationships: { reacted_to: { data: { id: post_id } } },
        },
      },
    }),
    [post_id]
  );
  const createReaction = useApiFetch("createReaction", create_reaction_params);
  const deleteReaction = useApiMethod("deleteReaction");
  const toggle_react_req = useCallback(() => {
    if (self_reacted_ref.current) {
      // if they quickly unlike after liking (i.e. accidental like), cancel the event
      cancel_event();
      return deleteReaction(self_reacted_ref.current).then(() => {
        onUpdate({ id: self_reacted_ref.current || "" }, true);
        self_reacted_ref.current = null;
      });
    } else {
      return createReaction().then(res => {
        const [, type] = get_guid_from_iri(post_id);
        if (type === "Post" || type === "Comment") {
          log_event(type);
        }
        self_reacted_ref.current = res.data.id;
        onUpdate(res.data, false);
      });
    }
  }, [self_reacted_ref, createReaction, deleteReaction]);
  const [toggle_react, { pending, error }] = useRequest(toggle_react_req);

  const tooltip_text = useMemo(() => {
    if (error) {
      return error.message;
    }
    return "";
  }, [user, reactions, error, showUsers]);

  if (!user) {
    return null;
  }
  return (
    <Tooltip disabled={!tooltip_text} delay={750} text={tooltip_text}>
      <ButtonWithIcon
        className={`reaction-button${maybeClassName(className)}`}
        data-self-react={`${!!self_reacted}`}
        data-pending={pending}
        data-error={error}
        disabled={pending || self_reacted !== self_reacted_ref.current}
        icon={self_reacted || pending ? "heart-solid" : "heart-outline"}
        side="left"
        onClick={toggle_react}
      >
        {showCount ? reactions?.length : children}
      </ButtonWithIcon>
    </Tooltip>
  );
};

// only concerned with whether the active user has reacted, doesn't show count
export const ReactionButtonSimple: Preact.FunctionComponent<
  { reactedTo: string; selfReacted?: boolean } & MaybeClass
> = ({ reactedTo: reacted_to, selfReacted, className }) => {
  const user = useAppUser();
  const [self_reaction, set_self_reaction] = useStateIfMounted<
    Reaction | null | undefined
  >(null);

  const req_params = useMemo(
    () =>
      user
        ? {
            query: {
              filter: [
                ["=", ["this", "Reaction:reacted_to"], ["id", reacted_to]],
                ["=", ["this", "Reaction:created_by"], ["id", user.id]],
              ] as const,
            },
          }
        : {},
    [reacted_to, user?.id]
  );
  const get_self_reaction = useApiFetch("getReactions", req_params);
  useEffect(() => {
    get_self_reaction().then(({ data }) => {
      if (data && data[0]) {
        set_self_reaction(data[0]);
      } else {
        set_self_reaction(undefined);
      }
    });
  }, []);

  const [log_event, cancel_event] = useDelayedEvent(
    (type: "Post" | "Comment") => {
      analytics.log_event(
        analytics.EVENTS[type === "Post" ? "post_like" : "comment_like"],
        undefined,
        undefined,
        true
      );
    }
  );

  const add_params = useMemo(
    () => ({
      body: {
        data: {
          attributes: {},
          relationships: { reacted_to: { data: { id: reacted_to } } },
        },
      },
    }),
    [reacted_to]
  );
  const add_self_react = useApiFetch("createReaction", add_params);
  const remove_self_react = useApiFetch("deleteReaction", self_reaction?.id);

  const toggle_react_request = useCallback(
    self_reaction_ => {
      if (self_reaction_) {
        cancel_event();
        return remove_self_react().then(() => {
          set_self_reaction(undefined);
        });
      } else {
        return add_self_react().then(({ data }) => {
          const [, type] = get_guid_from_iri(reacted_to);
          if (type === "Post" || type === "Comment") {
            log_event(type);
          }
          set_self_reaction(data);
        });
      }
    },
    [remove_self_react, add_self_react, set_self_reaction]
  );
  const [toggle_react, { pending, error }] = useRequest(toggle_react_request);
  if (!user) {
    return null;
  }

  return (
    <Tooltip disabled={!error} delay={750} text={error?.message || ""}>
      <ButtonWithIcon
        className={`reaction-button pill${maybeClassName(className)}`}
        data-self-react={`${!!self_reaction}`}
        data-pending={pending}
        data-error={error}
        disabled={pending || self_reaction === null}
        icon={self_reaction || pending ? "heart-solid" : "heart-outline"}
        side="left"
        onClick={() => toggle_react(self_reaction)}
        onMouseUp={e => {
          // @ts-expect-error:
          e.ignoreLinkClick = true;
        }}
      />
    </Tooltip>
  );
};
