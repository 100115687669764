import * as Preact from "preact";
import { useEffect } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { GroupConnectionScoreSidebar } from "~/view/components";
import { DefaultModalContent } from "@thrive-web/ui-components";

export const GroupScoreModal: Preact.FunctionComponent<
  ModalBodyProps & { group?: Group; updateGroup: () => void }
> = ({ group, updateGroup, closeButton }) => {
  // update the group score after opening the modal so the animation is visible
  useEffect(() => {
    if (!group || !updateGroup) {
      return;
    }
    setTimeout(updateGroup, MODAL_ANIMATION_DELAY);
  }, []);

  if (!group) {
    return null;
  }

  return (
    <DefaultModalContent title={group.name || ""} closeButton={closeButton}>
      <GroupConnectionScoreSidebar
        score={group.score || 0}
        showLevelUpAnim={true}
      />
    </DefaultModalContent>
  );
};
