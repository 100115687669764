import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useEffect, useMemo } from "preact/hooks";
import { RelationshipFulfillmentSurvey } from "@thrive-web/ui-api";
import {
  UserProfilePrivacyIndicator,
  fulfillment_survey_result_description,
  FulfillmentSurveyForm,
  FulfillmentSurveyResults,
} from "~/view/components";
import {
  Card,
  DefaultModalContent,
  LoadingParagraph,
  useAsyncRenderResult,
} from "@thrive-web/ui-components";
import {
  useApiCall,
  useAppUser,
  useModal,
  useStateObject,
} from "@thrive-web/ui-hooks";

export const FulfillmentSurveyModal: Preact.FunctionComponent<
  ModalBodyProps & { onFinish: (data: RelationshipFulfillmentSurvey) => void }
> = ({ onFinish, closeButton, dismiss }) => (
  <DefaultModalContent
    title="Relationship Fulfillment"
    closeButton={closeButton}
  >
    <FulfillmentSurveyForm dismiss={dismiss} onFinish={onFinish} />
  </DefaultModalContent>
);

export const FulfillmentSurveyResultsModal: Preact.FunctionComponent<
  ModalBodyProps & { score: number }
> = ({ score, closeButton }) => (
  <DefaultModalContent
    title="Relationship Fulfillment"
    closeButton={closeButton}
  >
    <FulfillmentSurveyResults score={score} />
  </DefaultModalContent>
);

export const FulfillmentSurveyBlock: Preact.FunctionComponent<{
  survey: RelationshipFulfillmentSurvey | null;
  onFinishSurvey: (data: RelationshipFulfillmentSurvey) => void;
}> = ({ survey, onFinishSurvey }) => {
  const survey_props = useMemo(
    () => ({ onFinish: onFinishSurvey }),
    [onFinishSurvey]
  );
  const [survey_modal, set_survey_open] = useModal(
    {
      id: "fulfillment-survey-modal",
      className: "fulfillment-modal",
      innerClassName: "modal-form",
      body: FulfillmentSurveyModal,
      showCloseButton: true,
      bodyProps: survey_props,
    },
    undefined,
    true
  );

  const results_props = useMemo(
    () => ({ score: survey?.score }),
    [survey?.score]
  );
  const [results_modal, set_results_open] = useModal(
    {
      id: "fulfillment-survey-results-modal",
      className: "fulfillment-modal",
      innerClassName: "fulfillment-modal__results",
      body: FulfillmentSurveyResultsModal,
      showCloseButton: true,
      bodyProps: results_props,
    },
    undefined,
    true
  );

  let content;
  if (!survey || !survey.score) {
    content = (
      <div className="user-profile__card__actions">
        <button
          className="filled gray"
          onClick={() => {
            set_survey_open(true);
            analytics.log_event(analytics.EVENTS.relationship_fulfillment);
          }}
        >
          Take the Relationship Fulfillment Survey
        </button>
      </div>
    );
  } else {
    content = (
      <Preact.Fragment>
        <p>
          <strong>Your Survey Result</strong>
        </p>
        <p>{fulfillment_survey_result_description(survey.score ?? -1)}</p>
        <div className="user-profile__card__actions">
          <button
            className="filled"
            onClick={() => {
              analytics.log_event(analytics.EVENTS.relationship_fulfillment);
              set_survey_open(true);
            }}
          >
            Retake the Survey
          </button>
          <button
            className="filled gray"
            onClick={() => set_results_open(true)}
          >
            See Suggestions
          </button>
        </div>
        {results_modal}
      </Preact.Fragment>
    );
  }

  return (
    <Preact.Fragment>
      {content}
      {survey_modal}
    </Preact.Fragment>
  );
};

export const UserFulfillmentSurvey: Preact.FunctionComponent = () => {
  const self = useAppUser();
  const [{ survey, fetched }, set_state] = useStateObject({
    fetched: false,
    survey: null as RelationshipFulfillmentSurvey | null,
  });

  const [get_survey, status_] = useApiCall("getFulfillmentSurveys");
  useEffect(() => {
    if (!self?.id) {
      return;
    }
    get_survey({
      query: {
        sort: [{ by: "created_at", dir: "desc" }],
        filter: [
          [
            "=",
            ["this", "RelationshipFulfillmentSurvey:created_by"],
            ["id", self.id],
          ],
        ],
        limit: 1,
      },
    }).then(({ data }) => {
      set_state({ fetched: true, survey: data[0] || null });
    });
  }, [self?.id]);

  const status = useMemo(
    () => ({
      ...status_,
      pending: status_.pending || (!status_.error && !fetched),
    }),
    [status_, fetched]
  );

  const passthrough_props = useMemo(
    () => ({
      onFinishSurvey: (data: RelationshipFulfillmentSurvey) =>
        set_state({ survey: data }),
    }),
    [set_state]
  );

  const content = useAsyncRenderResult(
    (result, _, passthrough) => (
      <FulfillmentSurveyBlock survey={result} {...passthrough_props} />
    ),
    [],
    status,
    survey,
    true,
    undefined,
    LoadingParagraph,
    true,
    passthrough_props
  );

  return (
    <Card className="user-profile__card user-profile__passions">
      <UserProfilePrivacyIndicator level="private">
        <h3 className="user-profile__card__title">Relationship Fulfillment</h3>
      </UserProfilePrivacyIndicator>
      <div className="user-profile__card__text">{content}</div>
    </Card>
  );
};
