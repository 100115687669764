import * as Preact from "preact";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import {
  UserStrengths,
  UserUpdateExperiences,
  CustomIdMap,
  ProfileBuilderIdentityInput,
  useIdentityForm,
} from "~/view/components";
import {
  Card,
  CheckList,
  DefaultPendingView,
  DropdownSelectInput,
  DropdownSelectInputDefaultTextInput,
  ErrorMessage,
  InputWithFormHelpers,
  PageBody,
  PageHeader,
  PageSidebar,
  PhoneNumberInput,
  RequestButtonWithIcon,
  TAXONOMIES_CTX,
  TextAreaWithFormHelpers,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useAppUserUpdate,
  useDirtyFormWithDiff,
  useRequest,
} from "@thrive-web/ui-hooks";
import { ExperienceCategory, User, WriteUser } from "@thrive-web/ui-api";
import { pick } from "@thrive-web/ui-common";
import { UserProfilePrivacyIndicator } from "../UserProfilePrivacyIndicator";
import { AppUser } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { ScreenSize } from "@thrive-web/ui-constants";
import { route } from "preact-router";
import {
  DocWithData,
  map_record_to_json_doc,
  MetaTypes,
  TYPES,
} from "@thrive-web/core";

const noop = () => {};
const getTextFromOption = opt => opt.label;
const trans_button_props = {
  placeholder: "Would you consider yourself transgender?",
  label: "Would you consider yourself transgender?",
  submitOnEnter: false,
};

export const UserUpdateForm: Preact.FunctionComponent<{
  submitForm: (
    data: DocWithData<MetaTypes["User"], false, true>,
    custom?: CustomIdMap
  ) => Promise<User>;
}> = ({ submitForm }) => {
  const self = useAppUser();
  const updateAppUser = useAppUserUpdate();

  const custom_ids_ref = useRef<CustomIdMap>({});
  const prepare_for_diffing = useCallback(
    (u: User) => {
      const output: any = {
        ...pick(
          [
            "first_name",
            "last_name",
            "phone_number",
            "email",
            "passions",
            "is_transgender",
          ],
          u
        ),
        has_experience: u.has_experience?.map(e => e.id)?.join(""),
        has_group_identity: u.has_group_identity?.map(e => e.id)?.join(""),
        has_gender_identity: u.has_gender_identity?.id,
        has_ethnic_identity: u.has_ethnic_identity?.id,
        has_race_identity: u.has_race_identity?.id,
      };
      if (custom_ids_ref.current?.has_gender_identity) {
        output.custom_gender = custom_ids_ref.current?.has_gender_identity;
      }
      if (custom_ids_ref.current?.has_race_identity) {
        output.custom_race = custom_ids_ref.current?.has_race_identity;
      }
      if (custom_ids_ref.current?.has_ethnic_identity) {
        output.custom_ethnicity = custom_ids_ref.current?.has_ethnic_identity;
      }
      return output;
    },
    [custom_ids_ref.current]
  );
  const [
    formData,
    updatedFormData,
    deletedKeys,
    setField,
    onChangeInput,
    ,
    resetForm,
  ] = useDirtyFormWithDiff<WriteUser>(
    self || {},
    "UserUpdate",
    prepare_for_diffing,
    false
  );

  const onUpdateExperiences = useCallback(
    (value: ExperienceCategory[]) => setField("has_experience", value),
    [setField]
  );

  const [
    custom_identities,
    on_change_custom_identity,
    trans_options,
    trans_value,
    onChangeTrans,
    checkListProps,
  ] = useIdentityForm(formData, setField);
  custom_ids_ref.current = custom_identities;

  const [sendRequest, { pending, success, error }] = useRequest(submitForm);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!self || !e.target.checkValidity()) {
        return;
      }
      if (!updatedFormData) {
        route("/profile");
        return;
      }

      const req_data: User = {
        id: self.id,
        ...(updatedFormData || {}),
      };

      deletedKeys?.forEach(k => {
        // @ts-expect-error:
        req_data[k] = null;
      });
      const body = map_record_to_json_doc(req_data, TYPES.User);

      if (!body.data.attributes) {
        body.data.attributes = {};
      }
      if (!body.data.relationships) {
        body.data.relationships = {};
      }

      Object.keys(body.data.relationships).forEach(k => {
        if (
          !(k in updatedFormData) &&
          !deletedKeys?.includes(k as keyof User)
        ) {
          delete body.data.relationships![k];
        }
      });

      return sendRequest(body, custom_identities).then(result => {
        const new_user: AppUser = { ...self, ...(updatedFormData || {}) };
        deletedKeys?.forEach(k => {
          delete new_user[k];
        });
        resetForm({ ...new_user, ...result });
        setTimeout(() => {
          updateAppUser({ ...new_user, ...result });
          route("/profile");
        }, 800);
      });
    },
    [
      self,
      updatedFormData,
      deletedKeys,
      sendRequest,
      updateAppUser,
      resetForm,
      custom_identities,
    ]
  );

  const save_button_props = useMemo(
    () => ({
      className: "filled blue",
      icon: "checked" as FontIconName,
      side: "left" as const,
      successText: "Changes Saved!",
      pending,
      success,
    }),
    [pending, success]
  );

  const error_msg = error ? (
    <div className="sidebar__section user-profile__update__error-box">
      <div className="sidebar__section__title">Error</div>
      <ErrorMessage>{error.message}</ErrorMessage>
    </div>
  ) : null;

  if (!self) {
    return null;
  }

  return (
    <div className="user-profile__update">
      <form onSubmit={onSubmit}>
        <PageHeader
          title={<h1>Edit Profile</h1>}
          button={<UserUpdateHeaderSaveButton {...save_button_props} />}
        />
        <PageBody>
          <div className="user-profile__update__form">
            {error_msg}
            <Card>
              <h3 className="user-profile__card__title">Name</h3>
              <div className="form__input-row">
                <WithFloatingTitle title="First Name">
                  <InputWithFormHelpers
                    name="first_name"
                    onChange={onChangeInput("first_name")}
                    value={formData.first_name}
                    required={true}
                    placeholder="First Name"
                    submitOnEnter={false}
                  />
                </WithFloatingTitle>
              </div>
              <div className="form__input-row">
                <WithFloatingTitle title="Last Name">
                  <InputWithFormHelpers
                    name="last_name"
                    onChange={onChangeInput("last_name")}
                    value={formData.last_name}
                    required={true}
                    placeholder="Last Name"
                    submitOnEnter={false}
                  />
                </WithFloatingTitle>
              </div>
            </Card>
            <Card>
              <UserProfilePrivacyIndicator level="connections">
                <h3 className="user-profile__card__title">Contact Info</h3>
              </UserProfilePrivacyIndicator>
              <div className="form__input-row">
                <WithFloatingTitle title="Mobile">
                  <PhoneNumberInput
                    name="phone_number"
                    onChange={onChangeInput("phone_number")}
                    value={formData.phone_number}
                    placeholder="Mobile"
                    submitOnEnter={false}
                    keepFormat={true}
                  />
                </WithFloatingTitle>
              </div>
              <div className="form__input-row">
                <WithFloatingTitle title="Email">
                  <InputWithFormHelpers
                    name="email"
                    readOnly={true}
                    value={formData.email}
                    placeholder="Email"
                    onChange={noop}
                    submitOnEnter={false}
                  />
                </WithFloatingTitle>
              </div>
            </Card>
            <Card>
              <UserProfilePrivacyIndicator level="private">
                <h3 className="user-profile__card__title">Identity</h3>
              </UserProfilePrivacyIndicator>
              <ProfileBuilderIdentityInput
                context={TAXONOMIES_CTX.GenderIdentity}
                property="has_gender_identity"
                onChange={setField}
                value={formData.has_gender_identity}
                placeholder="I identify my gender as..."
                onChangeCustom={on_change_custom_identity(
                  "has_gender_identity"
                )}
                customValue={custom_identities.has_gender_identity}
                hideTitle={false}
              />
              <div className="form__input-row">
                <DropdownSelectInput
                  triggerClassName="i-thrive-disclosure"
                  options={trans_options}
                  onSelect={onChangeTrans}
                  value={trans_value}
                  getValueLabel={getTextFromOption}
                  Button={DropdownSelectInputDefaultTextInput}
                  label=""
                  buttonProps={trans_button_props}
                />
              </div>
              <ProfileBuilderIdentityInput
                context={TAXONOMIES_CTX.RaceIdentity}
                property="has_race_identity"
                onChange={setField}
                value={formData.has_race_identity}
                placeholder="I identify my race as..."
                onChangeCustom={on_change_custom_identity("has_race_identity")}
                customValue={custom_identities.has_race_identity}
                hideTitle={false}
              />
              <ProfileBuilderIdentityInput
                context={TAXONOMIES_CTX.EthnicIdentity}
                property="has_ethnic_identity"
                onChange={setField}
                value={formData.has_ethnic_identity}
                placeholder="I identify my ethnicity as..."
                onChangeCustom={on_change_custom_identity(
                  "has_ethnic_identity"
                )}
                customValue={custom_identities.has_ethnic_identity}
                hideTitle={false}
              />
              <div className="form__input-row">
                <div className="form__input-prompt">
                  Do you identify as belonging to any of the following groups?
                </div>
                {checkListProps ? (
                  <CheckList {...checkListProps} />
                ) : (
                  <DefaultPendingView />
                )}
              </div>
            </Card>
            <UserStrengths userId={self.id} showRetake={true} />
            <Card>
              <UserProfilePrivacyIndicator level="public">
                <h3 className="user-profile__card__title">Passions</h3>
              </UserProfilePrivacyIndicator>
              <div className="form__input-row" data-field="passions">
                <WithFloatingTitle
                  title="Tell us what you’re passionate about."
                  className="textarea__container"
                >
                  <TextAreaWithFormHelpers
                    id="passions"
                    name="passions"
                    onChange={onChangeInput("passions")}
                    value={formData.passions}
                    placeholder="I'm passionate about..."
                    submitOnEnter={false}
                    autoFocus={window.location.hash === "#passions"}
                  />
                </WithFloatingTitle>
              </div>
            </Card>
            <Card className="stack">
              <UserProfilePrivacyIndicator level="private">
                <h3 className="user-profile__card__title">Experiences</h3>
              </UserProfilePrivacyIndicator>
              <div className="profile-builder__content__prompt">
                Share with the Thread community the experiences you've had or
                helped others overcome.
              </div>
              <UserUpdateExperiences
                value={formData.has_experience || []}
                onChange={onUpdateExperiences}
              />
            </Card>
            <div className="user-profile__update__submit">
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
              <RequestButtonWithIcon {...save_button_props}>
                Save Profile Changes
              </RequestButtonWithIcon>
            </div>
          </div>
          <PageSidebar className="user-profile__sidebar">
            <div className="sidebar__section user-profile__update__save-box">
              <div className="sidebar__section__title">Editing Profile</div>
              <RequestButtonWithIcon {...save_button_props}>
                Save Profile Changes
              </RequestButtonWithIcon>
            </div>
            {error_msg}
          </PageSidebar>
        </PageBody>
      </form>
    </div>
  );
};

// isolating this so updates to window_size don't cause the whole form to update
export const UserUpdateHeaderSaveButton: Preact.FunctionComponent<
  HTMLButtonProps &
    WithIconProps & {
      retryIcon?: FontIconName;
      pending: boolean;
      success?: boolean;
      successText?: string | Preact.VNode;
      error?: any;
      retryText?: string;
      showError?: boolean;
      progress?: number;
    }
> = props => {
  const window_size = useContext(CONTEXTS.window_size);
  return (
    <RequestButtonWithIcon {...props} type="submit">
      {window_size > ScreenSize.xs ? "Save Profile Changes" : "Save"}
    </RequestButtonWithIcon>
  );
};
