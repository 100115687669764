import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { Group, User } from "@thrive-web/ui-api";
import {
  DEFAULT_USER_FIELDS,
  DefaultPendingView,
  HealthGraph,
} from "@thrive-web/ui-components";
import { RELATIONSHIP_GROUP_MEMBER_LIMIT } from "@thrive-web/ui-constants";
import {
  useApiCall,
  useAppUser,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";

type ConnectionScoreMapping = ObjectOf<ObjectOf<number>>;

// for each user X, create a dict where the keys are the other users' IDs,
// and the values are the connection score between X and the other user
const build_score_mapping = (users: User[]): ConnectionScoreMapping => {
  const mapping: ConnectionScoreMapping = {};
  users.forEach(user => {
    mapping[user.id] = {};

    users.forEach(ou => {
      mapping[user.id][ou.id] = -1;
    });

    user.has_connection?.forEach(conn => {
      const other_user = conn["users"]?.find(({ id }) => id !== user.id);
      const score = conn["health_tier"] != null ? conn["health_tier"] : -1;
      if (!mapping[other_user?.id]) {
        return;
      }
      mapping[other_user.id][user.id] = score;
      mapping[user.id][other_user.id] = score;
    });
  });

  return mapping;
};

export const GroupHealthGraph: Preact.FunctionComponent<{
  group: Group;
}> = ({ group }) => {
  const self = useAppUser();
  const [mapping, set_mapping] =
    useStateIfMounted<ConnectionScoreMapping | null>(null);
  const [users, set_users] = useStateIfMounted<User[] | null>(null);
  const [get_connections, status] = useApiCall("getUsers");
  const has_member = useMemo(() => group.has_member, group.has_member || []);
  // id of user that is initially in the center of the graph (should always be self)
  const initial_center_id = useMemo(() => {
    if (!self || !group.has_member?.find(u => u.id === self.id)) {
      return;
    }
    return self.id;
  }, [self?.id, group.has_member]);

  useEffect(() => {
    if (!group?.id || !group.has_member?.length) {
      return;
    }
    set_users(null);
    // get users who are in this group, and include connections
    get_connections({
      query: {
        filter: [["=", ["this", ["^", "Group:has_member"]], ["id", group.id]]],
        include: ["has_connection", "profile_picture"],
        // sort: [{ by: "created_at", dir: "desc" }],
        limit: has_member?.length,
        fields: {
          User: [...DEFAULT_USER_FIELDS, "has_connection"],
          Connection: ["users", "health_tier"],
        },
      },
    }).then(({ data }) => {
      set_mapping(build_score_mapping(data));
      set_users(
        data.length > RELATIONSHIP_GROUP_MEMBER_LIMIT
          ? data.slice(0, RELATIONSHIP_GROUP_MEMBER_LIMIT)
          : data
      );
    });
  }, [group.id, group.has_member?.length]);

  const calculate_scores = useCallback(
    (center_id?: string) => {
      if (!self || !mapping || !mapping[center_id || self.id]) {
        return {};
      }
      return mapping[center_id || self.id];
    },
    [self?.id, mapping]
  );

  return !users || status.pending ? (
    <DefaultPendingView />
  ) : (
    <HealthGraph
      nodes={users || []}
      calculateConnections={calculate_scores}
      initialCenterId={initial_center_id}
    />
  );
};
