import * as hooks from "preact/hooks";
// @ts-ignore exec is exported, but not declared in index.d.ts
import { getCurrentUrl, exec } from "preact-router";
import { deep_equals } from "@thrive-web/ui-common";
import { set_dirty } from "@thrive-web/ui-model";
import { maybe_route, set_title } from "@thrive-web/ui-utils";
import { parse, stringify, StringifyOptions } from "query-string";
import { useStateRef } from ".";

// returns true if the current url matches the specified path spec
// path spec uses preact-router format: https://github.com/preactjs/preact-router#handling-urls
export const useRouteMatch = (path: string) => {
  const url = getCurrentUrl();
  return hooks.useMemo(() => {
    const curPath = url.replace(/\?.+$/, "");
    return exec(curPath, path, {}) !== false;
  }, [url, path]);
};

// sets global dirty state based on whether the form data has been modified
// setting dirty = true means the user will get a prompt before being allowed
// to navigate away from the page
// returns callback that resets the dirty state and initial form data
export const useDirtyForm = <Schema extends object>(
  formData: Schema,
  name: string, // for debugging
  // treat undefined and empty string as equal when comparing
  ignore_blank?: boolean
) => {
  const [, setInitialData, initialData_ref] = useStateRef(formData);
  const cur_form_dirty = hooks.useRef(false);
  const cur_data = hooks.useRef(formData);
  cur_data.current = formData;
  hooks.useEffect(() => {
    let newData = formData;
    if (
      ignore_blank &&
      typeof formData === "object" &&
      formData !== null &&
      !Array.isArray(formData)
    ) {
      newData = { ...formData };
      Object.keys(newData).forEach(key => {
        if (
          !(key in initialData_ref.current) &&
          (newData[key] === undefined || newData[key] === "")
        ) {
          delete newData[key];
        } else if (
          (initialData_ref.current[key] === "" && newData[key] === undefined) ||
          (initialData_ref.current[key] === undefined && newData[key] === "")
        ) {
          newData[key] = initialData_ref.current[key];
        }
      });
    }
    if (!deep_equals(newData, initialData_ref.current)) {
      if (!cur_form_dirty.current) {
        console.debug(
          `form ${name} is now dirty`,
          initialData_ref.current,
          "=>",
          newData
        );
        cur_form_dirty.current = true;
        set_dirty(true, name);
      }
    } else {
      if (cur_form_dirty.current) {
        console.debug(`form ${name} changes reverted`);
        cur_form_dirty.current = false;
        set_dirty(false, name);
      }
    }
  }, [formData]);
  // set dirty to false on unmount
  hooks.useEffect(() => {
    //todo: evaluate if this is necessary
    return () => {
      if (cur_form_dirty.current) {
        console.debug(`form ${name} unmounted, clearing dirty state`);
        cur_form_dirty.current = false;
        set_dirty(false, name);
      }
    };
  }, []);
  return hooks.useCallback((new_data?: Schema) => {
    console.debug(`form ${name} state manually cleared`);
    if (cur_form_dirty.current) {
      console.debug(
        `\tclearing dirty state for form ${name}`,
        cur_data.current
      );
      cur_form_dirty.current = false;
      setInitialData(new_data || cur_data.current);
      set_dirty(false, name);
    }
  }, []);
};

// takes a callback to generate the title, and inputs to trigger the hooks,
// returns a callback to manually force a title update
export const useDocumentTitle = (
  callback: () => string | null,
  inputs: any[]
) => {
  hooks.useEffect(() => {
    const document_title = callback();
    if (document_title && document.title !== document_title) {
      set_title(document_title);
    }
  }, inputs);
  return hooks.useCallback(() => {
    const document_title = callback();
    if (document_title && document.title !== document_title) {
      set_title(document_title);
    }
  }, inputs);
};

// returns value and setter for url query params, handles routing
export const useQueryParams = <T extends ObjectOf<any>>(
  transform: (raw: ObjectOf<string | string[] | null | undefined>) => T,
  route = window.location.pathname,
  replace: boolean = true
) => {
  const params = hooks.useMemo<T>(
    () => transform(parse(window.location.search)),
    [window.location.search, transform]
  );
  const set_params = hooks.useCallback(
    (
      params: T,
      options?: StringifyOptions,
      new_route: string = route,
      replace_cur: boolean = replace
    ) => {
      maybe_route(
        `${new_route}?${stringify(params, { ...options, encode: true })}`,
        replace_cur
      );
    },
    [route, replace]
  );

  return [params, set_params] as const;
};
