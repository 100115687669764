import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useEffect } from "preact/hooks";
import {
  UserUpdateForm,
  CustomIdMap,
  useCustomIdentities,
} from "~/view/components";
import { asSubroute } from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useDocumentTitle,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import { DocWithData, MetaTypes } from "@thrive-web/core";

export const UserUpdatePage: Preact.FunctionComponent = () => {
  const user = useAppUser();
  useDocumentTitle(() => make_title(["Edit Your Profile"]), []);
  useEffect(() => {
    analytics.log_event(analytics.EVENTS.edit_profile);
  }, []);

  const updateUser = useApiMethod("updateUser");
  const createIdentityAndUpdateUserChained = useCustomIdentities(updateUser);

  const onSubmit = useCallback(
    (
      data: DocWithData<MetaTypes["User"], false, true>,
      custom_ids?: CustomIdMap
    ) => {
      if (!user) {
        return Promise.reject();
      }
      if (custom_ids) {
        return createIdentityAndUpdateUserChained(
          [custom_ids],
          [user.id, { body: data }]
        ).then(res => {
          analytics.log_event(analytics.EVENTS.edit_profile_saved);
          return res;
        });
      } else {
        return updateUser(user.id, { body: data }).then(({ data }) => {
          analytics.log_event(analytics.EVENTS.edit_profile_saved);
          return data;
        });
      }
    },
    [user, createIdentityAndUpdateUserChained, updateUser]
  );

  if (!user) {
    return null;
  }

  return <UserUpdateForm submitForm={onSubmit} />;
};

export const UserUpdate = asSubroute(UserUpdatePage);
