import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const SvgIcon: Preact.FunctionComponent<
  MaybeClass &
    Preact.JSX.SVGAttributes & {
      height?: string;
      width?: string;
      viewBox?: string;
    }
> = ({ children, className, ...props }) => (
  <svg
    className={`inline-svg${maybeClassName(className)}`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="none" fillRule="evenodd">
      {children}
    </g>
  </svg>
);
SvgIcon.displayName = "SvgIcon";
