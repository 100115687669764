import { ApiMethodParameters } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { Event, WriteEvent } from "@thrive-web/ui-api";
import { useApiFetch, useModal } from "@thrive-web/ui-hooks";
import {
  EventEdit,
  EventEditProps,
  EventCreate,
  EventCancelModalBody,
} from "~/view/components";

import { createModal, ButtonWithIcon } from "@thrive-web/ui-components";
export const EventCreateModal: Preact.FunctionComponent<
  ModalBodyProps & {
    initialData?: WriteEvent;
    onFinish: (event: WriteEvent) => void;
  }
> = ({ open, dismiss, ...props }) => {
  return createModal(
    {
      id: "create-event",
      body: EventCreate,
      className: "card-stack modal-form event-form",
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      showCloseButton: true,
      bodyProps: props,
    },
    open,
    dismiss,
    true
  );
};

export const EventEditModal: Preact.FunctionComponent<
  EventEditProps & {
    onClose?: () => void;
  }
> = ({ target, onFinish, onClose }) => {
  const bodyProps = useMemo(() => ({ onFinish, target }), [target, onFinish]);

  const [editEventModal, openEditEventModal] = useModal(
    {
      id: "update-events-modal",
      innerClassName: "card-stack modal-form event-form",
      body: EventEdit,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      bodyProps,
    },
    onClose,
    true
  );

  return (
    <ButtonWithIcon
      icon="edit"
      side="left"
      className="filled gray"
      onClick={() => {
        analytics.log_event(analytics.EVENTS.event_edit);
        openEditEventModal(true);
      }}
    >
      Edit Event
      {editEventModal}
    </ButtonWithIcon>
  );
};

const cancel_event_params: ApiMethodParameters<"PATCH", "Event"> = {
  body: {
    data: {
      attributes: {
        is_canceled: true,
      },
    },
  },
};

export const EventCancelModal: Preact.FunctionComponent<{
  target?: Event | null;
  onFinish?: (target: Event) => void;
  onClose?: () => void;
}> = ({ target, onFinish, onClose }) => {
  const cancelEvent = useApiFetch(
    "updateEvent",
    target?.id,
    cancel_event_params
  );
  const body_props = useMemo(
    () => ({
      cancelEvent: () => {
        if (!target) {
          return Promise.resolve();
        }
        return cancelEvent().then(res => {
          onFinish?.({ ...target, is_canceled: true });
          return res;
        });
      },
    }),
    [cancelEvent, target, onFinish]
  );

  const [cancelEventModal, openCancelEventModal] = useModal(
    {
      id: "cancel-event-modal",
      innerClassName: "card-stack modal-form",
      body: EventCancelModalBody,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      bodyProps: body_props,
    },
    onClose
  );

  return (
    <ButtonWithIcon
      icon="remove-solid-circle"
      side="left"
      className="filled negative"
      onClick={() => openCancelEventModal(true)}
    >
      Cancel Event
      {cancelEventModal}
    </ButtonWithIcon>
  );
};
