import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { UserSettingsPassword } from "~/view/components";
import { DefaultModalContent } from "@thrive-web/ui-components";
import {
  useAppUser,
  useDocumentTitle,
  useModalAsRoute,
} from "@thrive-web/ui-hooks";
import { get_url_for_entity, make_title } from "@thrive-web/ui-utils";

const UserSettingsModalBody: Preact.FunctionComponent<ModalBodyProps> = ({
  closeButton,
  dismiss,
}) => {
  useDocumentTitle(() => make_title(["Account Settings"]), []);
  return (
    <DefaultModalContent
      closeButton={closeButton}
      title="Account Settings"
      footer={
        <div className="modal__footer">
          <div className="modal__footer__right">
            <button className="filled gray" onClick={dismiss}>
              Done
            </button>
          </div>
        </div>
      }
    >
      <UserSettingsPassword />
    </DefaultModalContent>
  );
};

export const UserSettingsModal: Preact.FunctionComponent<{
  prevTab?: string;
}> = ({ prevTab = "" }) => {
  const user = useAppUser();
  const force_reset_title = useDocumentTitle(
    () => make_title(["Your Profile"]),
    []
  );
  const url = useMemo(() => (user ? get_url_for_entity(user) : ""), [user]);
  return useModalAsRoute(
    {
      id: "user-settings",
      body: UserSettingsModalBody,
      className: "user-profile__settings modal-form",
      showCloseButton: true,
      giveTabFocus: true,
    },
    `${url}/settings`,
    prevTab ? `${url}/${prevTab}` : url,
    force_reset_title
  );
};
