import * as Preact from "preact";
import { useEffect, useLayoutEffect, useMemo, useRef } from "preact/hooks";
import { CAROUSEL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { class_names, maybeClassName } from "@thrive-web/ui-utils";
import {
  useCallbackRef,
  usePreviousValue,
  useResizeObserver,
  useStateObject,
} from "@thrive-web/ui-hooks";

interface CarouselState<M extends ObjectOf<preact.VNode | null>> {
  firstRender: boolean;
  displayedPage: keyof M;
  prevPage: keyof M;
  height: number;
}

export const Carousel = <M extends ObjectOf<preact.VNode | null>>({
  page,
  items,
  transitionTime = CAROUSEL_ANIMATION_DELAY,
  trackHeight,
  keepPagesMounted = true,
  className,
}: preact.RenderableProps<
  MaybeClass & {
    page: keyof M;
    items: M;
    transitionTime?: number;
    trackHeight?: boolean;
    keepPagesMounted?: boolean | (keyof M)[];
  }
>): preact.VNode<any> | null => {
  // ref to the currently displayed item
  const itemRef = useRef<HTMLDivElement>(null);
  const [{ displayedPage, firstRender, prevPage, height }, setState] =
    useStateObject<CarouselState<M>>({
      // page that is currently visible
      displayedPage: page,
      // gets set to false after the height is initialized
      firstRender: true,
      // previously displayed page
      prevPage: page,
      // height of content in current page
      height: -1,
    });

  // dict for tracking which pages have already been rendered; once a page
  // gets rendered, it is left in the dom even after going to a different page
  // this is strictly for optimization, and can be disabled by setting
  // unmountHiddenPages={true}
  const pages_shown = useRef<{ [K in keyof M]?: boolean }>({
    page: true,
  });

  let new_state: Partial<CarouselState<M>> = {};
  // observer for monitoring page content and adjusting height
  const observer_callback = useCallbackRef(() => {
    if (!itemRef.current) {
      return;
    }
    const item_height =
      itemRef.current.offsetHeight || itemRef.current.clientHeight;
    if (item_height > 0 && item_height !== height) {
      setState({ height: item_height });
    }
  }, [itemRef.current, setState, height]);
  useResizeObserver(itemRef, observer_callback, !!trackHeight);

  // update the height whenever the page changes
  useEffect(() => {
    if (
      itemRef.current &&
      itemRef.current.offsetHeight > 0 &&
      (height === -1 || height !== itemRef.current.offsetHeight)
    ) {
      new_state.height = itemRef.current.offsetHeight;
    }
  }, [height, itemRef.current, displayedPage, page]);

  useEffect(() => {
    firstRender && height !== -1 && (new_state.firstRender = false);
  }, [height]);

  // keep the previous page visible until the transition finishes
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (prevPage !== displayedPage) {
        if (
          keepPagesMounted !== true &&
          (!keepPagesMounted || !keepPagesMounted.includes(prevPage))
        ) {
          pages_shown.current[prevPage] = false;
        }
        setState({ prevPage: displayedPage });
      }
    }, transitionTime);
    return () => {
      clearTimeout(timer);
    };
  }, [displayedPage, setState]);

  // trap focus inside the current page of the carousel
  useEffect(() => {
    if (
      itemRef.current &&
      window.threadAppFocusMeta?.container !== itemRef.current
    ) {
      window.threadAppFocusManager.setFocusContainer(
        itemRef.current,
        null,
        "none",
        undefined,
        false
      );
    }
  }, [displayedPage, prevPage]);

  // when the page changes, clear the focus trap for the current page
  useLayoutEffect(() => {
    pages_shown.current[page] = true;

    if (page !== displayedPage && itemRef.current) {
      window.threadAppFocusManager.clearFocusContainer(itemRef.current);
    }

    new_state.displayedPage = page;
  }, [page]);
  useEffect(
    () => () => {
      if (itemRef.current) {
        window.threadAppFocusManager.clearFocusContainer(itemRef.current);
      }
    },
    []
  );

  useEffect(() => {
    if (Object.keys(new_state).length > 0) {
      setState(new_state);
    }
  });

  const prev_height = usePreviousValue(height);
  const window_style: any = trackHeight
    ? {
        height:
          displayedPage !== prevPage
            ? height !== -1
              ? `${height}px`
              : itemRef.current && itemRef.current.offsetHeight > 0
              ? `${itemRef.current.offsetHeight}px`
              : "100%"
            : "auto",

        transitionTimingFunction:
          !prev_height.current || prev_height.current === height
            ? "ease-in-out"
            : prev_height.current < height
            ? "cubic-bezier(0.375, 0, 0.25, 1)"
            : "cubic-bezier(0.75, 0, 0.625, 1)",
        ...(displayedPage !== page || displayedPage !== prevPage
          ? {
              transitionDuration: `${transitionTime / 1.25}ms`,
              transitionDelay: `${transitionTime / 8}ms`,
            }
          : {
              transitionDuration: `0ms`,
              transitionDelay: `0ms`,
            }),
      }
    : undefined;

  const first = Object.keys(items).find(
    k => k === displayedPage || k === prevPage
  );
  const direction =
    prevPage !== displayedPage
      ? first === displayedPage
        ? "right"
        : "left"
      : undefined;

  return (
    <div className={`carousel${maybeClassName(className)}`}>
      <div
        className="carousel__window"
        data-dir={direction}
        data-first-render={`${firstRender}`}
        style={window_style}
      >
        {Object.entries(items).map(([key, content]) => {
          if (!pages_shown.current[key]) {
            return null;
          }
          const cur = displayedPage === key;
          const prev = prevPage === key && prevPage !== displayedPage;
          return (
            <div
              key={key}
              data-page={key}
              className={class_names(
                {
                  "--current": cur,
                  "--previous": prev,
                },
                "carousel__item"
              )}
              style={{
                ...(typeof transitionTime === "number" && transitionTime >= 0
                  ? {
                      transitionDuration: `${transitionTime}ms`,
                      animationDuration: `${transitionTime}ms`,
                    }
                  : {}),
                overflow: cur || prev ? "visible" : "hidden",
              }}
            >
              <div
                ref={cur ? itemRef : undefined}
                className="carousel__item__content"
              >
                {content}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const create_progress_dots = (current, count, maxDots): Preact.VNode[] => {
  const pages = Array(count).fill(null);
  const range = Math.ceil(maxDots / 2 - 1);

  return pages.map((_, i): Preact.VNode => {
    if (
      count < maxDots ||
      (i < maxDots && current <= range) ||
      (i >= count - maxDots && current >= count - range) ||
      (i >= current - range && i <= current + range)
    ) {
      return (
        <div className="carousel-progress__dot" data-current={i === current} />
      );
    }
    return (
      <div
        className="carousel-progress__dot"
        data-current={i === current}
        data-hide={true}
      />
    );
  });
};

export const CarouselProgress: Preact.FunctionComponent<{
  count: number;
  current: number;
  maxDots?: number;
}> = ({ count, current, maxDots = count }) => {
  const dots = useMemo(
    () => create_progress_dots(current, count, maxDots),
    [current, count, maxDots]
  );
  return <div className="carousel-progress">{dots}</div>;
};
