import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { Icon } from "@thrive-web/ui-components";

// Checklist with pill-style items
export const PillCheckList = <T extends string | number, P>({
  label,
  className,
  values,
  onSelectOption,
  options,
  mapToOption,
  loadMoreElem,
  ...props
}: Preact.RenderableProps<CheckListProps<T, P>>): Preact.VNode<any> | null => {
  // ensure all options are InputOption<T>s
  // @ts-expect-error:
  const options_mapped = useMemo<InputOption<T>[]>(() => {
    if (!mapToOption) {
      return options;
    }
    return options.map(opt => {
      if (typeof opt === "object" && "value" in opt && "label" in opt) {
        return opt;
      }
      return mapToOption(opt);
    });
  }, [options, mapToOption]);

  return (
    <div className={`checkbox__list pill-list${maybeClassName(className)}`}>
      {options_mapped.map(opt => (
        <div className="pill-list__item" key={opt.value}>
          <input
            {...props}
            type="checkbox"
            id={`pill-checkbox-${opt.value}`}
            value={opt.value}
            checked={values.includes(opt.value)}
            // @ts-expect-error
            onChange={e => onSelectOption(opt, e.target?.checked)}
          />
          <label
            className="pill-checkbox button filled gray all-gray"
            for={`pill-checkbox-${opt.value}`}
          >
            <div className="pill-checkbox__text">{opt.label}</div>
            <div className="pill-checkbox__check">
              <Icon name="checked" />
            </div>
          </label>
        </div>
      ))}
      {loadMoreElem && (
        <div className="pill-list__load-more">{loadMoreElem}</div>
      )}
    </div>
  );
};

// Radio button list with pill-style items
export const PillRadioList = <T extends string | number>({
  label,
  className,
  value,
  onSelectOption,
  options,
  defaultValue,
  ...props
}: Preact.RenderableProps<RadioListProps<T>>): Preact.VNode<any> | null => {
  const selected = value == null ? defaultValue : value;
  const has_icons = useMemo(() => options.some(opt => !!opt.icon), [options]);

  return (
    <div className={`radio-button__list pill-list${maybeClassName(className)}`}>
      {options.map(opt => (
        <div className="pill-list__item" key={opt.value}>
          <input
            {...props}
            type="radio"
            id={`pill-radio-${props.name}-${opt.value}`}
            value={opt.value}
            checked={selected === opt.value}
            onChange={() => onSelectOption(opt)}
          />
          {has_icons ? (
            <label
              className="pill-checkbox with-icon button pill filled gray"
              for={`pill-radio-${props.name}-${opt.value}`}
            >
              {opt.icon && (
                <div className="with-icon__icon">
                  <Icon name={opt.icon} />
                </div>
              )}
              <div className="pill-checkbox__text with-icon__text">
                {opt.label}
              </div>
            </label>
          ) : (
            <label
              className="pill-checkbox button filled gray"
              for={`pill-radio-${props.name}-${opt.value}`}
            >
              <div className="pill-checkbox__text">{opt.label}</div>
              <div className="pill-checkbox__check">
                <Icon name="checked" />
              </div>
            </label>
          )}
        </div>
      ))}
    </div>
  );
};

// Boolean radio button list with pill-style items
export const BooleanPillRadioList: Preact.FunctionComponent<
  Omit<HTMLInputProps, "onChange" | "value"> & {
    name: string;
    label?: preact.ComponentChildren;
    value?: boolean;
    trueLabel?: preact.ComponentChildren;
    falseLabel?: preact.ComponentChildren;
    onChange: (value: boolean) => void;
  }
> = ({
  label,
  className,
  value,
  trueLabel = "Yes",
  falseLabel = "No",
  onChange,
  name,
  ...props
}): Preact.VNode<any> | null => {
  return (
    <div className={`radio-button__list pill-list${maybeClassName(className)}`}>
      <div className="pill-list__item">
        <input
          {...props}
          name={name}
          type="radio"
          id={`pill-radio-${name}-true`}
          value="true"
          checked={value === true}
          onChange={() => onChange(true)}
        />
        <label
          className="pill-checkbox button pill filled gray all-gray"
          for={`pill-radio-${name}-true`}
        >
          <div className="pill-checkbox__text">{trueLabel}</div>
          <div className="pill-checkbox__check">
            <Icon name="checked" />
          </div>
        </label>
      </div>
      <div className="pill-list__item">
        <input
          {...props}
          name={name}
          type="radio"
          id={`pill-radio-${name}-false`}
          value="false"
          checked={value === false}
          onChange={() => onChange(false)}
        />
        <label
          className="pill-checkbox button pill filled gray all-gray"
          for={`pill-radio-${name}-false`}
        >
          <div className="pill-checkbox__text">{falseLabel}</div>
          <div className="pill-checkbox__check">
            <Icon name="checked" />
          </div>
        </label>
      </div>
    </div>
  );
};
