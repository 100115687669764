import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { AssessmentFeedbackInvitation } from "@thrive-web/ui-api";
import {
  useApiCall,
  useAppUser,
  useDynamicListVariable,
  useModal,
} from "@thrive-web/ui-hooks";
import { data_attrs } from "@thrive-web/ui-utils";
import { RequestButtonWithIcon } from "@thrive-web/ui-components";
import {
  makeProfileBuilderModalBody,
  ProfileBuilderItem,
} from "../ProfileBuilderItem";
import { ProfileBuilderFeedbackModal } from "./FeedbackModal";

export const ProfileBuilderFeedback: Preact.FunctionComponent<{
  step: number;
  name: string;
  description: string;
  button_text: string;
  button_icon: FontIconName;
  finishStep: (key: string, data: any) => void;
  currentStep: number;
}> = ({ button_text, button_icon, finishStep, currentStep, ...props }) => {
  const [invitations, updateInvitations] =
    useDynamicListVariable<AssessmentFeedbackInvitation>([]);

  const [getInvitations, { pending }] = useApiCall("getFeedbackInvitations");

  const user = useAppUser();

  const onFinish = useCallback(
    (data: any) => {
      const result = data.concat(invitations);
      updateInvitations.reset(result);
      finishStep("invites", result);
    },
    [finishStep, invitations]
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    getInvitations({
      query: {
        filter: [
          [
            "=",
            ["this", "AssessmentFeedbackInvitation:sender"],
            ["id", user.id],
          ],
        ],
        sort: [{ by: "created_at", dir: "asc" }],
      },
    })
      .then(result => {
        updateInvitations.reset(result.data);
        if (result.data.length >= 3) {
          onFinish(result.data);
        }
      })
      .catch(err =>
        console.error(`Error occurred while creating feedback invitation`, err)
      );
  }, []);

  const body_props = useMemo(() => ({ invited: invitations }), [invitations]);

  const [feedbackModal, setFeedbackModalOpen] = useModal(
    {
      id: "profile-builder-feedback",
      className:
        "profile-builder__modal profile-builder__feedback invite-users-modal",
      dismissOnClickBackdrop: !!invitations && invitations.length >= 3,
      showCloseButton: true,
      overrideScroll: false,
      body: makeProfileBuilderModalBody(
        "Get Feedback",
        ProfileBuilderFeedbackModal,
        onFinish,
        body_props
      ),
    },
    undefined,
    true
  );

  const openModal = setFeedbackModalOpen.bind(this, true);

  const active = currentStep >= props.step;
  const complete = !!invitations && invitations.length >= 3;
  return !user ? null : (
    <ProfileBuilderItem
      {...props}
      complete={complete}
      button={
        complete ? null : (
          <RequestButtonWithIcon
            className={`filled${currentStep > props.step ? " gray" : ""}`}
            disabled={!active}
            onClick={openModal}
            icon={button_icon}
            side="left"
            pending={pending && active}
          >
            {button_text}
          </RequestButtonWithIcon>
        )
      }
    >
      {feedbackModal}
      {invitations && invitations.length > 0 && (
        <ProfileBuilderFeedbackList invitations={invitations} />
      )}
    </ProfileBuilderItem>
  );
};

export const ProfileBuilderFeedbackList: Preact.FunctionComponent<{
  invitations: readonly AssessmentFeedbackInvitation[];
}> = ({ invitations }) => {
  const attrs = data_attrs({
    "count-md": Math.max(0, invitations.length - 8),
    "count-sm": Math.max(0, invitations.length - 5),
    "count-xs": Math.max(0, invitations.length - 2),
  });
  return (
    <div className="profile-builder__feedback__list">
      {invitations.map((inv, i) => (
        <div
          key={inv.id}
          data-last={`${i === invitations.length - 1}`}
          className="profile-builder__feedback__list__item"
        >
          <div className="profile-builder__feedback__list__item__text">
            {inv.contact}
          </div>
        </div>
      ))}
      <div
        className="profile-builder__feedback__list__item profile-builder__feedback__list__item__more"
        {...attrs}
      >
        <div className="profile-builder__feedback__list__item__text">
          <span {...attrs} />
          {" others"}
        </div>
      </div>
    </div>
  );
};
