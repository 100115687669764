import * as Preact from "preact";
import { useContext } from "preact/hooks";
import { Post } from "@thrive-web/ui-api";
import { ExpandableImage } from "@thrive-web/ui-components";
import { useMedia } from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";

export const PostPhoto: Preact.FunctionComponent<{
  data: Post;
}> = ({ data }) => {
  const window_size = useContext(CONTEXTS.window_size);
  const img_url = useMedia<"Post", "photo">(data, "photo", 1, window_size);
  return (
    <div className="post__photo">
      <ExpandableImage
        className="post__photo__image"
        src={data.photo_url}
        previewSrc={img_url}
      />
    </div>
  );
};
