import * as Preact from "preact";
import { getCurrentUrl } from "preact-router";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { ApiMethodParameters } from "@thrive-web/core";
import { Notification } from "@thrive-web/ui-api";
import { NOTIFICATIONS } from "@thrive-web/ui-components";
import { EVENTS, CONTEXTS } from "@thrive-web/ui-model";
import {
  useApiFetchPaged,
  useApiMethod,
  useAppUser,
  useCallbackRef,
  useDynamicListVariable,
  useMemoRef,
  useRequest,
  useStateIfMounted,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { NOTIF_ICON_ACTION_MAP } from "~/view/components";

export const NOTIF_REFRESH_RATE = 45000;

export const NOTIFICATION_QUERY_PARAMS: ApiMethodParameters<
  "GET",
  "Notification",
  false
> = {
  query: {
    filter: [["not", ["=", ["this", "Notification:hidden"], true]]],
    sort: [{ by: "created_at", dir: "desc" }],
    include: [
      "initiator",
      "connection_request.ConnectionRequest:sender.User:profile_picture",
      "connection_request.ConnectionRequest:recipient.User:profile_picture",
    ],
    include_count: true,
  },
};

export const old_notification_query_params = (
  time?: string
): ApiMethodParameters<"GET", "Notification", false> => {
  const params: ApiMethodParameters<"GET", "Notification", false> = {
    query: {
      ...NOTIFICATION_QUERY_PARAMS.query,
      limit: 15,
    },
  };
  if (time) {
    // @ts-expect-error:
    params.query.filter = params.query.filter.slice();
    // @ts-expect-error:
    params.query.filter.push(["<=", ["this", "Notification:created_at"], time]);
  }

  return params;
};

export const new_notification_query_params = (
  latest?: string
): ApiMethodParameters<"GET", "Notification", false> => {
  const params: ApiMethodParameters<"GET", "Notification", false> = {
    query: {
      ...NOTIFICATION_QUERY_PARAMS.query,
      sort: [{ by: "created_at", dir: "asc" }],
      limit: 15,
    },
  };
  if (latest) {
    // @ts-expect-error:
    params.query.filter = params.query.filter.slice();
    // @ts-expect-error:
    params.query.filter.push([
      ">",
      ["this", "Notification:created_at"],
      latest,
    ]);
  }

  return params;
};

const invalid = [<Preact.Fragment />, () => {}] as [
  preact.VNode,
  () => boolean
];

export const useHideNotif = (id: string, on_remove: () => Promise<void>) => {
  const update_notif = useApiMethod("updateNotification");
  return useCallback(() => {
    update_notif(id, {
      body: {
        data: {
          attributes: {
            read: true,
            hidden: true,
          },
        },
      },
    });
    return on_remove();
  }, [id, update_notif]);
};

export const useNotifIconAndAction = (
  notif: Notification,
  on_update: (id: string, notif?: Notification | null) => void,
  is_menu: boolean,
  on_remove: () => Promise<void>
): [preact.VNode, () => void, preact.VNode?, preact.VNode?] => {
  if (!NOTIF_ICON_ACTION_MAP[notif.custom_type as string]) {
    return invalid;
  }
  const on_update_and_remove = useCallback(
    (id: string, notif?: Notification | null) =>
      on_remove().then(() => on_update(id, notif)),
    [on_update, on_remove]
  );
  return NOTIF_ICON_ACTION_MAP[notif.custom_type as string](
    notif,
    on_update,
    is_menu,
    on_update_and_remove
  );
};

// handles fetching notification list, marking as read, and polling for updates
export const useNotificationList = (
  is_menu?: boolean,
  open?: boolean,
  onRead?: () => void
) => {
  const dispatch = useContext(CONTEXTS.dispatch);
  const user = useAppUser();

  const { list, dispatch: update_list } = useContext(NOTIFICATIONS);
  const notifs = useMemo(() => (is_menu ? list?.slice(0, 3) : list), [list]);
  const [new_notifs, set_new_notifs] =
    useDynamicListVariable<Notification>(null);
  const [total_new, set_total_new] = useStateIfMounted(0);

  const last_read_time = useMemo(
    () =>
      user?.notifications_last_seen_at
        ? new Date(user.notifications_last_seen_at).getTime()
        : 0,
    [user?.notifications_last_seen_at]
  );

  // creation date/time of the most recent notification
  const [last_notif_time, last_notif_time_str] = useMemo(() => {
    const time = notifs?.[0]?.created_at;
    return time ? [new Date(time).getTime(), time] : [-1];
  }, [notifs?.[0]?.created_at]);
  const updateNotifReq = useApiMethod("updateNotification");

  const on_update = useCallback((id: string, notif?: Notification | null) => {
    if (notif) {
      update_list.update(n => n.id === id, notif);
    } else {
      update_list.remove(n => n.id === id);
    }
  }, []);

  // update the user record property for notifs last seen time
  const updateUserReq = useApiMethod("updateUser");
  const updateUser = useCallbackRef(
    (time: string) => {
      if (!user) {
        return;
      }
      updateUserReq(user?.id, {
        body: { data: { attributes: { notifications_last_seen_at: time } } },
      }).then(() => {
        dispatch({
          type: EVENTS.UPDATE_AUTH_USER,
          payload: { value: time, path: ["notifications_last_seen_at"] },
        });
      });
    },
    [updateUserReq]
  );

  // set read status to true for individual notif
  const read_notif = useCallback(
    (notif: Notification) => {
      if (!notif.read) {
        updateNotifReq(notif.id, {
          body: { data: { attributes: { read: true } } },
          query: { include: ["initiator"] },
        }).then(({ data }) => {
          update_list.update(n => n.id === notif.id, data);
        });
      }
      onRead?.();
    },
    [onRead]
  );

  const get_notifs_params = useMemo(
    () => old_notification_query_params(new Date().toISOString()),
    []
  );
  const get_notifs_req = useApiFetchPaged(
    "getNotifications",
    get_notifs_params
  );
  const get_notifs_and_store = useCallback(
    (offset: number, limit?: number) =>
      get_notifs_req(offset, limit).then(res => {
        if (is_menu) {
          update_list.reset(res.data);
        }
        return res;
      }),
    [get_notifs_req, update_list]
  );
  const [get_notifs, status] = useRequest(get_notifs_and_store);

  /* const list = useAsyncRenderResult(
    (results: Notification[]) => (
      <ul className={is_menu ? "notif-menu__list" : "notif-page__list"}>
        {results.map(n => (
          <NotificationListItem
            className={is_menu ? undefined : "card"}
            key={n.id}
            item={n}
            onClick={read_notif}
            onUpdate={on_update}
            visible={is_menu ? open : true}
            isMenu={is_menu}
          />
        ))}
      </ul>
    ),
    [open, read_notif, on_update],
    status,
    first_success.current ? notifs : undefined,
    true,
    undefined,
    is_menu ? NotificationMenuLoading : NotificationPageLoading
  );*/

  const update_timer = useRef<any>();
  // upon viewing the notif list
  useEffect(() => {
    if (
      // if new notif exists, update user notif read time
      (!is_menu || open) &&
      last_notif_time_str &&
      last_notif_time > last_read_time
    ) {
      // todo: temp
      update_timer.current = setTimeout(
        () => updateUser.current?.(last_notif_time_str),
        1000
      );
      return () => {
        update_timer.current && clearTimeout(update_timer.current);
      };
    }
  }, [open, last_notif_time, last_read_time]);

  // if menu is closed, automatically update the list with new notifs
  // if menu is open, display the button to show new notifs
  const auto_add_new = useValueRef(!!is_menu && !open);
  const latest_ref = useValueRef(last_notif_time_str);
  const new_notifs_str = useMemoRef(
    () => JSON.stringify(new_notifs),
    [new_notifs]
  );
  const get_new_notifs_req = useApiMethod("getNotifications");
  const get_new_notifs = useCallback(
    (latest_notif: string | undefined = latest_ref.current) =>
      get_new_notifs_req(new_notification_query_params(latest_notif)).then(
        res => {
          const total = (res.meta?.total_result_count as number) || 0;
          res.data.reverse();
          if (auto_add_new.current && total > 0) {
            update_list.concat(res.data, true);
            if (total > res.data.length) {
              set_total_new(total - res.data.length);
              const new_latest = res.data[0]?.created_at;
              if (new_latest) {
                get_new_notifs(new_latest);
              }
            }
          } else {
            if (JSON.stringify(res.data) !== new_notifs_str.current) {
              set_new_notifs.reset(res.data);
            }
            set_total_new((res.meta?.total_result_count as number) || 0);
          }
          return res;
        }
      ),
    [get_new_notifs_req]
  );

  const show_new = useCallback(() => {
    if (new_notifs && new_notifs.length > 0) {
      update_list.concat(new_notifs, true);
      if (total_new > new_notifs.length) {
        const new_latest = new_notifs[0]?.created_at;
        get_new_notifs(new_latest);
      }
      set_total_new(total_new - new_notifs.length);
      set_new_notifs.reset(null);
    }
  }, [update_list, set_new_notifs, new_notifs, total_new]);

  useEffect(() => {
    if (!is_menu || getCurrentUrl() !== "/notifications") {
      const timer = setInterval(() => get_new_notifs(), NOTIF_REFRESH_RATE);
      return () => {
        clearInterval(timer);
      };
    }
  }, [list, new_notifs, getCurrentUrl()]);

  return [
    list,
    update_list,
    get_notifs,
    status,
    read_notif,
    on_update,
    last_notif_time > last_read_time,
    new_notifs && new_notifs.length > 0 ? show_new : undefined,
  ] as const;
};
