import { DocBase, FilterSpec } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultModalContent,
  RemovableListItem,
  RemovableListItemComponentProps,
  RequestButton,
  SelectUserModal,
  UserInviteListItem,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiRequest,
  useAppUser,
  useDynamicListVariable,
  useRenderPropsFunction,
} from "@thrive-web/ui-hooks";

// searchable user list for inviting users to an event
export const EventInviteModal: Preact.FunctionComponent<
  ModalBodyProps & { eventId: string; onSuccess: (user: User) => void }
> = ({ dismiss, closeButton, eventId, onSuccess }) => {
  const self = useAppUser();
  const searchRequest = useApiMethod("getUsers");
  const sendSearchRequest = useCallback(
    (
      search: string,
      offset: number,
      limit?: number
    ): Promise<DocBase & { data: User[] }> =>
      searchRequest({
        query: {
          filter: event_invite_search_filter(self, eventId, search),
          // sort is needed so that paging won't break when we invite a user
          // then load a new page
          sort: [{ by: "full_name", dir: "asc" }],
          include: ["profile_picture"],
          limit,
          offset,
          include_count: true,
        },
      }),
    [searchRequest, eventId, self?.id]
  );

  const [new_invites, set_new_invites] = useDynamicListVariable<User>([]);
  const onInvite = useCallback(
    (user: User) => {
      set_new_invites.add(user);
      onSuccess(user);
    },
    [set_new_invites, onSuccess]
  );

  const renderUser = useRenderPropsFunction(
    (u: User, _, remove_on_select?: (user: User) => void) => (
      <EventInviteListItem
        eventId={eventId}
        onSuccess={onInvite}
        user={u}
        removeOnInvite={remove_on_select}
        key={u.id}
        invited={!!new_invites?.find(i => i.id === u.id)}
      />
    ),
    "EventInviteListItem-User",
    [onInvite, eventId, new_invites]
  );

  return (
    <DefaultModalContent
      title="Invite Someone From Your Connections"
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div />
          <ButtonWithIcon
            className="filled gray"
            icon="checked"
            side="left"
            type="submit"
            onClick={dismiss}
          >
            Done
          </ButtonWithIcon>
        </div>
      }
    >
      <div className="event-detail__invite__modal__body">
        <SelectUserModal
          selected={new_invites || []}
          getUsers={sendSearchRequest}
          renderUser={renderUser}
          emptyLabel="No users available to invite"
        />
      </div>
    </DefaultModalContent>
  );
};

interface EventInviteProps {
  user: User;
  eventId: string;
  onSuccess: (user: User) => void;
  removeOnInvite?: (user: User) => void;
  invited: boolean;
}
export const EventInviteListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<EventInviteProps>
> = ({ user, eventId, invited, onRemoveItem }) => {
  const req_params = useMemo(
    () => ({
      body: { data: [{ id: user.id }] },
    }),
    [user?.id]
  );
  const [send_request, { pending, success, error }] = useApiRequest(
    "createEventInvite",
    eventId,
    req_params
  );
  const addMember = useCallback(() => {
    if (invited) {
      return;
    }
    send_request().then(res => {
      analytics.log_event(analytics.EVENTS.event_invite);
      // @ts-expect-error:
      onRemoveItem(res);
    });
  }, [send_request, user, onRemoveItem, invited]);

  return (
    <UserInviteListItem
      user={user}
      button={
        <RequestButton
          className={`filled ${success ? "success" : "gray"}`}
          pending={pending}
          success={success || invited}
          successText="Invited"
          error={error}
          showError={true}
          onClick={addMember}
        >
          Invite
        </RequestButton>
      }
    />
  );
};

export const EventInviteListItem: Preact.FunctionComponent<EventInviteProps> =
  props => {
    const onRemoveItem = useCallback(() => {
      props.removeOnInvite?.(props.user);
      props.onSuccess(props.user);
      return Promise.resolve();
    }, [props.onSuccess, props.user, props.removeOnInvite]);
    return (
      <RemovableListItem<
        RemovableListItemComponentProps<EventInviteProps>,
        User
      >
        {...props}
        delay={1000}
        Component={EventInviteListItemBase}
        onRemoveItem={onRemoveItem}
      />
    );
  };

const event_invite_search_filter = (
  user: User | undefined | null,
  event_id: string | undefined,
  search?: string
): FilterSpec | undefined => {
  if (!user || !event_id) {
    return;
  }
  // get connected users that are not skeleton users and do not already have
  // an invite/rsvp to this event
  let filter_clause: FilterSpec = [
    [
      "=",
      ["this", ["/", "User:has_connection", "Connection:users"]],
      ["id", user.id],
    ],
    ["not", ["=", ["this", ["^", "Event:has_invitee"]], ["id", event_id]]],
    [
      "not",
      [
        "=",
        ["this", ["/", ["^", "EventRSVP:created_by"], "EventRSVP:event"]],
        ["id", event_id],
      ],
    ],
    ["not", ["=", ["this", "User:firebase_uuid"], user.firebase_uuid || ""]],
  ];
  if (search) {
    filter_clause = [
      ...filter_clause,
      ["match", ["this", "User:full_name"], search, "i"],
    ];
  }
  return filter_clause;
};
