import { ProfileBuilderStrengths } from "./Strengths";
import { ProfileBuilderExperiences } from "./Experiences";
import { ProfileBuilderFeedback } from "./Feedback";

export const PROFILE_BUILDER_STEPS = [
  {
    name: "Strengths",
    description: "Answer a few questions to help identify your strengths.",
    button_text: "Take Strengths Quiz",
    button_icon: "star" as FontIconName,
    Component: ProfileBuilderStrengths,
  },
  {
    name: "Feedback",
    description: "Get feedback on your strengths from people you trust.",
    button_text: "Get Feedback From Others",
    button_icon: "question-solid-chat-bubble" as FontIconName,
    Component: ProfileBuilderFeedback,
  },
  {
    name: "Experiences",
    description:
      "Identify experiences you’ve had that may help you support others in the community.",
    button_text: "Share Your Experiences",
    button_icon: "gift" as FontIconName,
    Component: ProfileBuilderExperiences,
  },
] as const;
