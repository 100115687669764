import { MediaRecord } from "@thrive-web/ui-api";
import * as Preact from "preact";
import { useAppUser, useModal } from "@thrive-web/ui-hooks";
import {
  Icon,
  Tooltip,
  ProfilePhotoModal,
  ExpandableImage,
} from "@thrive-web/ui-components";
import { useMemo } from "preact/hooks";

export const UserUpdatePhoto: Preact.FunctionComponent = () => {
  const user = useAppUser();
  const modal_props = useMemo(
    () => ({
      user,
      onFinish: () => {},
    }),
    [user]
  );

  const [modal, set_open] = useModal({
    id: "profile-picture-upload-modal",
    className: "profile-picture-upload__modal profile-builder__photo__modal",
    body: ProfilePhotoModal,
    bodyProps: modal_props,
    showCloseButton: true,
  });

  if (!user) {
    return null;
  }

  return (
    <Preact.Fragment>
      <div className="user-avatar user-profile__avatar" data-size="xl">
        {user.profile_picture ? (
          <ExpandableImage
            src={user.profile_picture_url}
            previewSrc={(user.profile_picture as MediaRecord)?.medium_url}
          />
        ) : (
          <div className="user-avatar__initials">
            {user.first_name?.[0]}
            {user.last_name?.[0]}
          </div>
        )}
        <Tooltip
          className="user-avatar__icon profile-picture-upload__icon"
          text="Change your profile picture"
          defaultOffset="left"
          animation="fade"
          delay={1000}
        >
          <button
            className="profile-picture-upload__edit filled"
            type="button"
            onClick={() => set_open(true)}
          >
            <Icon name="edit" />
          </button>
        </Tooltip>
      </div>
      {modal}
    </Preact.Fragment>
  );
};
