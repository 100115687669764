import { ApiError } from "@thrive-web/core";

/** Provides a utility to make the error metadata directly accessible
 *  to the exception handler */
export class JsonApiError extends Error implements ApiError {
  constructor(error: ApiError) {
    super(`${error.code}: ${error.title}`);
    Object.entries(error).forEach(([key, value]) => {
      this[key] = value;
    });
  }
  status?: string; // might not use this
  code: string; // traceable error code, e.g. "api/invalid-field-value", "db/user-already-exists"
  title: string; // Human readable string
  detail?: string; // Human readable, more descriptive than title, not always necessary
  source?: {
    pointer?: string;
    parameter?: string;
  };

  toJSON() {
    return {
      status: this.status,
      code: this.code,
      title: this.title,
      detail: this.detail,
      source: this.source,
    };
  }
}
