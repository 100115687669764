import * as Preact from "preact";
import { getCurrentUrl } from "preact-router";
import * as hooks from "preact/hooks";
import { ScreenSize } from "@thrive-web/ui-constants";
import { CONTEXTS } from "@thrive-web/ui-model";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import { useAppUser, useStateIfMounted } from "@thrive-web/ui-hooks";
import { AppLink, Icon, NavLinks } from "@thrive-web/ui-components";

export const NavSidebar: Preact.FunctionComponent<{
  navLinks: NavLinkSpec[];
}> = ({ navLinks, children }) => {
  const [expanded, setExpanded] = useStateIfMounted(false);
  const ref = hooks.useRef<HTMLDivElement>(null);
  const window_size = hooks.useContext(CONTEXTS.window_size);
  const auth = hooks.useContext(CONTEXTS.auth);
  const user = useAppUser();
  const expandProps: any = {};
  const closeMenu = () => setExpanded(false);
  // sidebar is only collapsed at this screen size
  if (window_size === ScreenSize.md) {
    if (!window["is_touch_screen"]) {
      expandProps.onMouseEnter = () => setExpanded(true);
      expandProps.onMouseLeave = closeMenu;
    }
    expandProps["data-expanded"] = `${expanded}`;
  }

  if (
    !user ||
    !auth ||
    !auth.userProfileBuilderComplete ||
    !auth.hasGroups ||
    getCurrentUrl() === "/guide"
  ) {
    return null;
  }
  /*let _,
    user_links: any[] = [];
  if (user) {
    [_, ...user_links] = create_userbar_links(user, "site-nav__link");
  }*/
  return (
    <div
      ref={ref}
      id="site-nav__sidebar"
      className="site-nav__sidebar"
      tabIndex={window["is_touch_screen"] ? 0 : undefined}
      {...expandProps}
    >
      <div className="site-nav__sidebar__content">
        <div className="site-nav__sidebar__top">
          <AppLink
            href="/"
            className="site-nav__logo"
            onClick={closeMenu}
            blurOnClick={true}
          >
            <img
              className="site-nav__logo__image"
              src={`${IMAGES_PREFIX}/logo.svg`}
            />
            <img
              className="site-nav__logo__text"
              src={`${IMAGES_PREFIX}/thread.svg`}
            />
          </AppLink>

          {/*<AppLink href="/profile" class="site-userbar__profile site-nav__link">*/}
          {/*  <UserAvatar user={user} size="sm" />*/}
          {/*  <span>My Profile</span>*/}
          {/*</AppLink>*/}
          {/*{user_links}*/}
        </div>
        <NavLinks links={navLinks} onClick={closeMenu} />
        <div className="site-nav__sidebar__bottom site-nav__legal-links">
          <Icon name="more" />
          <AppLink
            className="plain-link"
            href="/privacy-policy"
            blurOnClick={true}
            activePathMatch="/privacy-policy"
            activeClassName="active"
          >
            Privacy Policy
          </AppLink>
          <span>|</span>
          <AppLink
            className="plain-link"
            href="/terms-of-use"
            blurOnClick={true}
            activePathMatch="/terms-of-use"
            activeClassName="active"
          >
            Terms of Use
          </AppLink>
        </div>
      </div>
    </div>
  );
};
