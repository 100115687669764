import * as Preact from "preact";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";

export const SiteFooter: Preact.FunctionComponent<{}> = () => (
  <div className="site-footer">
    <div className="site-footer__content">
      <div className="site-footer__logo">
        <img src={`${IMAGES_PREFIX}/logo_white_thread.svg`} alt="Thread Logo" />
      </div>
      <div className="site-footer__text">
        <a className="plain-link" href="/privacy-policy">
          Privacy Policy
        </a>
        <span> | </span>
        <a className="plain-link" href="/terms-of-service">
          Terms of Service
        </a>
        <span> | </span>
        <a
          className="plain-link"
          href="mailto:info@bmore.com,support@thread.com"
        >
          Contact Us
        </a>
      </div>
    </div>
  </div>
);
SiteFooter.displayName = "SiteFooter";
