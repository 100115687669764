import * as Preact from "preact";
import {
  Taxonomies,
  TAXONOMIES_CTX,
  TaxonomiesContextSpec,
} from "@thrive-web/ui-components";
import { useContext, useEffect, useMemo } from "preact/hooks";

type TaxonomyStringsProps<
  T extends keyof Taxonomies,
  IsArray extends boolean
> = Omit<Preact.RenderableProps<{}>, "children"> & {
  type: T;
} & (IsArray extends true
    ? {
        value?: readonly Taxonomies[T][];
        children: (
          value?: Taxonomies[T][],
          loading?: boolean
        ) => Preact.VNode | null;
      }
    : {
        value?: Taxonomies[T];
        children: (
          value?: Taxonomies[T],
          loading?: boolean
        ) => Preact.VNode | null;
      });

// renders children with the type/value of taxonomy given, but fetches the taxonomy
// record(s) if the value from props is missing or an id obj
export const WithTaxonomy = <
  T extends keyof Taxonomies,
  IsArray extends boolean
>({
  value,
  type,
  children,
}: TaxonomyStringsProps<T, IsArray>) => {
  // context is needed if value is empty or an id obj(s)
  const need_ctx = useMemo(
    () =>
      value != null &&
      !(
        (Array.isArray(value) &&
          value.some(
            v =>
              Object.keys(v).filter(k => k !== "id" && k !== "type").length > 0
          )) ||
        (!Array.isArray(value) &&
          Object.keys(value).filter(k => k !== "id" && k !== "type").length > 0)
      ),
    [value]
  );

  if (need_ctx) {
    return (
      // @ts-expect-error:
      <WithTaxonomyCtx value={value} type={type}>
        {children}
      </WithTaxonomyCtx>
    );
  } else {
    // @ts-expect-error:
    return children(value);
  }
};

const WithTaxonomyCtx = <T extends keyof Taxonomies, IsArray extends boolean>({
  value,
  children,
  type,
}: Preact.RenderableProps<TaxonomyStringsProps<T, IsArray>>) => {
  const {
    map: tx_map,
    status: { pending },
  } = useContext(
    TAXONOMIES_CTX[type] as Preact.Context<TaxonomiesContextSpec<T>>
  );
  const fetch = useContext(TAXONOMIES_CTX.fetch);
  useEffect(() => {
    if (!tx_map) {
      fetch(type);
    }
  }, [type, tx_map]);

  // extract the value(s) from the fetch result that match the value(s) from props
  const mapped = useMemo(() => {
    if (!value) {
      return null;
    }
    if (!tx_map) {
      return value;
    }
    if (Array.isArray(value)) {
      return value.map(v => tx_map[v.id]).filter(v => !!v);
    }
    return tx_map[(value as Taxonomies[T]).id];
  }, [tx_map, value]);

  // @ts-expect-error:
  return children(mapped, pending);
};
