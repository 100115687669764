import * as Preact from "preact";
import {
  DATE_PATTERN,
  padZeros,
  supportsInputType,
  validate_date,
  DATE_PARTS_MAX,
  DATE_PARTS_MIN,
  get_days_in_month,
  data_attrs,
  maybeClassName,
  padDashes,
} from "@thrive-web/ui-utils";
import { moment } from "@thrive-web/ui-common";
// no idea why, but the alias path ("~/view/components") wouldn't work, so using rel path
import { InputWithFormHelpers } from "./inputs";
import { FakeDateOrTimeInput } from "./fake-date-or-time-input";

/** Value is a string in the format of an ISO date string */
export class DateInput extends InputWithFormHelpers<
  string,
  HTMLInputProps & {
    type?: "date";
    min?: string;
    max?: string;
    onClear?: (e) => void;
  }
> {
  constructor(props) {
    super(props);
    this.supportsNativeInput = supportsInputType("date");
  }
  supportsNativeInput: boolean;

  validate = (value: string) => {
    const { min, max } = this.props;
    return validate_date(
      value,
      this.input.current || undefined,
      min ? moment(min) : undefined,
      max ? moment(max) : undefined
    );
  };

  handleChange = e => {
    this.onChangeBound(e);
    this.updateAttributes({
      value: e.target.value,
      required: this.props.required,
    });
    if (!e.target.value && this.props.onClear) {
      this.props.onClear(e);
    }
  };

  render() {
    const {
      value,
      type,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      onClear,
      ref,
      ...props
    } = this.props;
    // use native input[type="date"] if supported by the browser
    return this.supportsNativeInput ? (
      <input
        ref={this.input}
        type="date"
        {...data_attrs({
          native: "true",
          value: value as string,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        defaultValue={this.initialValue}
        {...props}
        pattern={DATE_PATTERN}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    ) : (
      // otherwise use our component
      <FakeDateInput
        {...data_attrs({
          native: "false",
          value: value as string,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        value={value}
        {...props}
        pattern={DATE_PATTERN}
        onChangeValue={v => this.handleChange(v)}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

type ThisState = FakeDateOrTimeState<"date">;
export class FakeDateInput extends FakeDateOrTimeInput<"date"> {
  constructor(props) {
    super(props);
    let state: any = {
      // if the initial value is valid, store its parts
      value: validate_date(props.value) ? this.stringToParts(props.value) : {},
      cur_value: props.value,
    };
    this.state = state;
    ["min", "max"].forEach((k: "min" | "max") => {
      if (props[k]) {
        let val = this.calcMinMax(k, props[k], state);
        if (val !== null) {
          state[k] = val;
        }
      }
    });
    this.state = state;
    this.inputs = {
      month: Preact.createRef(),
      day: Preact.createRef(),
      year: Preact.createRef(),
    };
    this.input = Preact.createRef();
    this.container = Preact.createRef();
  }
  input: Preact.RefObject<HTMLInputElement>;

  // check that the value is a valid date and is within the allowed range
  validateParts = (value?: Partial<DateParts>): boolean => {
    const { min = {}, max = {} } = this.state;
    const min_ = this.partsToString(min);
    const max_ = this.partsToString(max);
    return validate_date(
      this.partsToString(value || {}),
      this.input.current || undefined,
      min_ ? moment(min_) : undefined,
      max_ ? moment(max_) : undefined
    );
  };

  // get the min/max acceptable value for a given part of a date
  getInputLimits = (name: NumericDateOrTimeParts<"date">) => {
    const cur_year = new Date().getFullYear();
    const { year = cur_year, month } = this.state.value;
    return {
      min: Object.assign({}, DATE_PARTS_MIN, this.state.min)[name],
      max: Object.assign(
        {},
        DATE_PARTS_MAX,
        { year: cur_year },
        month ? { day: get_days_in_month(month, year) } : {},
        this.state.max
      )[name],
    };
  };

  // calculate min or max values for individual parts based on the current value and min/max
  calcMinMax = <K extends "min" | "max">(
    key: K,
    value: string,
    state: ThisState = this.state
  ): ThisState[K] | null => {
    try {
      if (validate_date(value)) {
        const cur_value = state.value || this.state.value;
        const { day, month, year } = this.stringToParts(value);
        const new_state: any = { year };
        if (cur_value.year === year) {
          new_state.month = month;
          if (cur_value.month === month) {
            new_state.day = day;
          }
        }
        return new_state;
      } else {
        console.error(
          `Invalid date string used for prop \`${key}\` in FakeDateInput:`,
          value
        );
        return null;
      }
    } catch {
      console.error(
        `Failed to parse date string used for prop \`${key}\` in FakeDateInput:`,
        value
      );
    }
    return null;
  };

  partsToString = ({ month, day, year }: SomeDateParts): string => {
    if (!month || !day || !year) {
      return "";
    }
    return `${padZeros(year, 4)}-${padZeros(month)}-${padZeros(day)}`;
  };

  stringToParts = (value: string): DateParts => {
    let [year, month, day] = value
      .split("-")
      .map(v => parseInt(v))
      .filter(v => !isNaN(v));
    return { month, day, year };
  };

  // when changing the month number, check/adjust the day number to be valid
  // for the new month
  adjustForNewValue = ({ day, month, year }: SomeDateParts): SomeDateParts => {
    if (day && month) {
      const max_days = get_days_in_month(month, year);
      if (max_days != null && day > max_days) {
        day = max_days;
      }
    }
    return { month, day, year };
  };

  onClickContainer = e => {
    if (e.target.tagName !== "INPUT") {
      this.focusInput("month");
    }
  };

  render() {
    const {
      value: _,
      type,
      min: __,
      max: ___,
      onChangeValue,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      onChange,
      onKeyDown,
      className,
      ...props
    } = this.props;
    const { value = {} } = this.state;
    let { month, day, year } = value;
    const month_limits = this.getInputLimits("month");
    const day_limits = this.getInputLimits("day");
    const year_limits = this.getInputLimits("year");
    return (
      <div
        // @ts-expect-error:
        ref={this.container}
        className={`fake-datetime-input fake-datetime-input__date${maybeClassName(
          className
        )}`}
        {...{
          "data-value": `${props["data-value"]}`,
          "data-valid": `${props["data-valid"]}`,
          "data-empty": `${props["data-empty"]}`,
          "data-dirty": `${props["data-dirty"]}`,
          "data-focus": `${this.focus}`,
        }}
        onClick={this.onClickContainer}
      >
        <input
          ref={this.inputs.month}
          id={props.id ? `${props.id}-month` : undefined}
          autoComplete="off"
          placeholder="MM"
          value={padZeros(month) || "MM"}
          name="month"
          disabled={month_limits.min === month_limits.max}
          {...month_limits}
          onKeyDown={this.onKeyDownNumber("month")}
          onChange={() => this.selectText("month")}
          onClick={() => this.selectText("month")}
          onFocus={this.onFocus("month")}
          onBlur={this.onBlur("month")}
          data-placeholder={`${month == null}`}
        />
        <span>/</span>
        <input
          ref={this.inputs.day}
          id={props.id ? `${props.id}-day` : undefined}
          autoComplete="off"
          placeholder="DD"
          value={padZeros(day) || "DD"}
          name="day"
          disabled={day_limits.min === day_limits.max}
          {...day_limits}
          onKeyDown={this.onKeyDownNumber("day")}
          onChange={() => this.selectText("day")}
          onClick={() => this.selectText("day")}
          onFocus={this.onFocus("day")}
          onBlur={this.onBlur("day")}
          data-placeholder={`${day == null}`}
        />
        <span>/</span>
        <input
          ref={this.inputs.year}
          id={props.id ? `${props.id}-year` : undefined}
          autoComplete="off"
          placeholder="YYYY"
          value={padDashes(year, 4) || "YYYY"}
          name="year"
          disabled={year_limits.min === year_limits.max}
          {...year_limits}
          onKeyDown={this.onKeyDownNumber("year")}
          onChange={() => this.selectText("year")}
          onClick={() => this.selectText("year")}
          onFocus={this.onFocus("year")}
          onBlur={this.onBlur("year")}
          data-placeholder={`${year == null}`}
        />
        <input
          {...props}
          ref={this.input}
          value={this.partsToString(value)}
          className="datetime__hidden"
          tabIndex={-1}
          data-last-focused={this.last_focused}
        />
      </div>
    );
  }
}
