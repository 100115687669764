import * as Preact from "preact";
import { get_cache } from "@thrive-web/ui-utils";

// renders a component using a record from cache, or a fallback view if
// the record isn't in the cache
export const WithCache = <P extends object, D extends any>(
  Component: Preact.ComponentType<P & { data: D }>,
  FallbackView?: Preact.ComponentType
): Preact.FunctionComponent<P & { cache_key: string }> => {
  const ComponentWithData: Preact.FunctionComponent<
    P & {
      cache_key: string;
    }
  > = ({ cache_key, ...props }) => {
    const data = get_cache(cache_key);
    if (data === undefined) {
      return FallbackView ? <FallbackView /> : null;
    }
    // @ts-ignore
    return <Component data={data} {...props} />;
  };
  ComponentWithData.displayName = `WithCache(${Component.displayName})`;
  return ComponentWithData;
};
