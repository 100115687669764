/** this is mainly for the web client, and is tentative */

import {
  Types,
  ApiListResponse,
  ApiRecordResponse,
  ApiRequestBody,
  ApiUpdateRequestBody,
  ResourceObj,
  MetaTypes,
  RelationshipKeysOf,
  DocBase,
  ResourceIdObj,
  MultivaluedKeysOf,
  ApiRelationshipRequestBody,
  ApiRelationshipResponseBody,
  QueryParams,
} from "@thrive-web/core";

interface EndpointSpec<T extends keyof MetaTypes, WithIRI extends boolean> {
  GET: {
    // in reality, get requests can't have a body, but this is being specified for the purposes
    // of type completion, the data here should be transmitted via query params
    request: WithIRI extends false
      ? Exclude<ResourceObj<MetaTypes[T]>, "id" | "type">
      : never;
    response: WithIRI extends true
      ? ApiRecordResponse<MetaTypes[T]>
      : ApiListResponse<MetaTypes[T]>;
  };
  POST: {
    request: ApiRequestBody<MetaTypes[T], true>;
    response: ApiRecordResponse<MetaTypes[T]>;
  };
  /*PUT: {
    request: ApiUpdateRequestBody<MetaTypes[T]>;
    response: ApiRecordResponse<MetaTypes[T]>;
  };*/
  PATCH: {
    request: ApiUpdateRequestBody<MetaTypes[T]>;
    response: ApiRecordResponse<MetaTypes[T]>;
  };
  DELETE: {
    request: never;
    response: { meta?: any } | undefined;
  };
}

export type HTTPMethod = keyof EndpointSpec<any, any>;

export type ApiMethodMap<WithIRI extends boolean = boolean> = {
  [K in keyof Types]: EndpointSpec<K, WithIRI>;
};

interface RelationshipEndpointSpec<
  T extends keyof Types,
  R extends RelationshipKeysOf<MetaTypes[T]>,
  Multi extends boolean = MultivaluedKeysOf<MetaTypes[T]> extends R
    ? true
    : false
> {
  GET: {
    request: never;
    response: ApiRelationshipResponseBody<Multi>;
  };
  POST: {
    request: Multi extends true ? ApiRelationshipRequestBody<true> : never;
    response: ApiRelationshipResponseBody<Multi>;
  };
  PATCH: {
    request: ApiRelationshipRequestBody<Multi>;
    response: ApiRelationshipResponseBody<Multi>;
  };
  DELETE: {
    request: Multi extends true ? ApiRelationshipRequestBody<true> : never;
    response: DocBase;
  };
}

export type ApiRelationshipMethodMap<T extends keyof MetaTypes> = {
  [R in RelationshipKeysOf<MetaTypes[T]>]: RelationshipEndpointSpec<T, R>;
};

export type ApiMethodParameters<
  M extends HTTPMethod,
  T extends keyof Types,
  WithIRI extends boolean = M extends "PUT" | "PATCH" | "DELETE"
    ? true
    : M extends "POST"
    ? false
    : boolean
> =
  // if "POST", don't allow query params
  (M extends "POST"
    ? {}
    : {
        query?: QueryParams<Types[T]>;
      }) &
    // if not targeting a specific record
    (WithIRI extends false
      ? M extends "POST" // if "POST" (creating a new record)
        ? {
            body: EndpointSpec<T, WithIRI>[M]["request"];
          }
        : M extends "GET"
        ? {
            body?: EndpointSpec<T, WithIRI>[M]["request"];
          } // if "GET" or "DELETE", body should not be present
        : never // "PATCH", "PUT", and "DELETE" should never be called without a record ID
      : M extends "POST" // if a record id is specified
      ? never // "POST" should never be used with an id, it's for creating new records
      : M extends "GET" | "DELETE" // no body needed for get/delete a single record
      ? {}
      : {
          // body required for "PUT"/"PATCH"
          body: EndpointSpec<T, WithIRI>[M]["request"];
        });

export type ApiRelationshipMethodParameters<
  M extends HTTPMethod,
  T extends keyof Types,
  R extends RelationshipKeysOf<MetaTypes[T]>,
  Multi = MultivaluedKeysOf<MetaTypes[T]> extends R ? true : false
> = (M extends "GET"
  ? {
      query?: QueryParams<Types[T]>;
    }
  : {}) &
  Multi extends true
  ? M extends "POST" | "PATCH" | "DELETE" // if "POST" (creating a new record)
    ? {
        body: DocBase & { data: ResourceIdObj[] };
      }
    : {}
  : M extends "PATCH"
  ? {
      body: DocBase & { data: ResourceIdObj | null };
    }
  : never;

export type ApiConnectorMethod<Async extends boolean = true> = <
  M extends HTTPMethod,
  T extends keyof Types,
  WithIRI extends boolean = M extends "PUT" | "PATCH" | "DELETE"
    ? true
    : M extends "POST"
    ? false
    : boolean,
  Options = any
>(
  type: T,
  method: M,
  id: WithIRI extends true ? string : never | undefined,
  params?: ApiMethodParameters<M, T, WithIRI>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiMethodMap<WithIRI>[T][M]["response"]>
  : ApiMethodMap<WithIRI>[T][M]["response"];

export type ApiConnectorMethod_BoundType<
  T extends keyof Types,
  Async extends boolean = true
> = <
  M extends HTTPMethod,
  WithIRI extends boolean = M extends "PUT" | "PATCH" | "DELETE"
    ? true
    : M extends "POST"
    ? false
    : boolean,
  Options = any
>(
  method: M,
  id: WithIRI extends true ? string : never | undefined,
  params?: ApiMethodParameters<M, T, WithIRI>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiMethodMap<WithIRI>[T][M]["response"]>
  : ApiMethodMap<WithIRI>[T][M]["response"];

export type ApiConnectorMethod_BoundTypeMethod<
  T extends keyof Types,
  M extends HTTPMethod,
  Async extends boolean = true
> = <
  WithIRI extends boolean = M extends "PUT" | "PATCH" | "DELETE"
    ? true
    : M extends "POST"
    ? false
    : boolean,
  Options = any
>(
  id: WithIRI extends true ? string : never | undefined,
  params?: ApiMethodParameters<M, T, WithIRI>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiMethodMap<WithIRI>[T][M]["response"]>
  : ApiMethodMap<WithIRI>[T][M]["response"];

export type ApiConnectorMethod_BoundTypeCreateRecord<
  T extends keyof Types,
  M extends "POST" = "POST",
  Async extends boolean = true
> = <WithIRI extends false = false, Options = any>(
  params?: ApiMethodParameters<M, T, WithIRI>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiMethodMap<WithIRI>[T][M]["response"]>
  : ApiMethodMap<WithIRI>[T][M]["response"];

export type ApiConnectorMethod_BoundTypeGetRecord<
  T extends keyof Types,
  M extends "GET" = "GET",
  Async extends boolean = true
> = <WithIRI extends true = true, Options = any>(
  id: WithIRI extends true ? string : never | undefined,
  params?: ApiMethodParameters<M, T, WithIRI>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiMethodMap<WithIRI>[T][M]["response"]>
  : ApiMethodMap<WithIRI>[T][M]["response"];

export type ApiConnectorMethod_BoundTypeGetList<
  T extends keyof Types,
  M extends "GET" = "GET",
  Async extends boolean = true
> = <WithIRI extends false = false, Options = any>(
  params?: ApiMethodParameters<M, T, WithIRI>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiMethodMap<WithIRI>[T][M]["response"]>
  : ApiMethodMap<WithIRI>[T][M]["response"];

export type ApiConnectorRelationshipMethod<Async extends boolean = true> = <
  M extends HTTPMethod,
  T extends keyof Types,
  R extends RelationshipKeysOf<MetaTypes[T]>,
  Multi = R extends MultivaluedKeysOf<MetaTypes[T]> ? true : false,
  Options = any
>(
  type: T,
  relationship: R,
  method: M,
  id: string,
  params?: ApiRelationshipMethodParameters<M, T, R, Multi>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiRelationshipMethodMap<T>[R][M]["response"]>
  : ApiRelationshipMethodMap<T>[R][M]["response"];

export type ApiConnectorRelationshipMethod_Bound<
  T extends keyof Types,
  R extends RelationshipKeysOf<MetaTypes[T]>,
  M extends HTTPMethod,
  Async extends boolean = true,
  Multi = R extends MultivaluedKeysOf<MetaTypes[T]> ? true : false
> = <Options = any>(
  id: string,
  params?: ApiRelationshipMethodParameters<M, T, R, Multi>,
  options?: Options // client-implementation-specific info, e.g. http headers
) => Async extends true
  ? Promise<ApiRelationshipMethodMap<T>[R][M]["response"]>
  : ApiRelationshipMethodMap<T>[R][M]["response"];
