import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import {
  UserCoachingCard,
  UserProfileMood,
  UserProfileSidebar,
  UserStrengths,
  UserProfilePrivacyIndicator,
  UserContactInfo,
  UserFulfillmentSurvey,
} from "~/view/components";
import {
  asSubroute,
  Card,
  JoinStringList,
  LoadingParagraph,
  PageBody,
  WithTaxonomy,
  EmptyList,
} from "@thrive-web/ui-components";
import {
  USER_COACHES,
  USER_DETAIL_CONTEXTS,
  USER_DISPATCH,
  USER_USER,
} from "../contexts";
import {
  useAppUser,
  useDocumentTitle,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { get_url_for_entity, make_title } from "@thrive-web/ui-utils";
import { ExperienceCategory } from "@thrive-web/ui-api";

export const UserAboutBase: Preact.FunctionComponent<
  RoutePageProps & {
    userId: string;
    postId?: string;
  }
> = ({ userId, postId }) => {
  const self = useAppUser();
  const ctx_user = useContext(USER_USER);
  useDocumentTitle(
    () =>
      make_title(
        self?.id && ctx_user?.id === self?.id
          ? ["Your Profile"]
          : ctx_user?.first_name
          ? [`${ctx_user.first_name} ${ctx_user.last_name}`]
          : []
      ),
    [ctx_user?.first_name]
  );
  const { dispatch } = useContext(USER_COACHES);
  const dispatch_status = useContext(USER_DISPATCH);
  const [refresh_conns, set_refresh_conns] = useStateIfMounted(0);
  const afterInteractions = useCallback(() => {
    set_refresh_conns(refresh_conns + 1);
  }, [set_refresh_conns, refresh_conns]);
  const user = useMemo(() => ({ id: userId }), [userId]);
  useEffect(() => {
    return () => {
      dispatch.reset(null);
      dispatch_status("coach_status", {
        pending: false,
        success: false,
        error: undefined,
      });
    };
  }, [userId]);

  return (
    <PageBody>
      <div className="user-profile__about">
        <UserProfileMood />
        <UserContactInfo afterInteractions={afterInteractions} />
        <UserCoachingCard user={user} refresh={refresh_conns} />
        <UserStrengths userId={userId} />
        <UserPassions />
        <UserExperiences />
        {self?.id && self.id === ctx_user?.id && <UserFulfillmentSurvey />}
        {/*<UserActivity key={userId} userId={userId} postId={postId} />*/}
      </div>
      <UserProfileSidebar />
    </PageBody>
  );
};

export const UserAbout = asSubroute(UserAboutBase);

export const UserExperiences: Preact.FunctionComponent = () => {
  const user = useContext(USER_DETAIL_CONTEXTS.user);
  const render = useRenderPropsFunction(
    (values: ExperienceCategory[], loading?: boolean) =>
      loading ? (
        <LoadingParagraph />
      ) : (
        <Preact.Fragment>
          I have experience with{" "}
          <JoinStringList>{values.map(v => v.name) as string[]}</JoinStringList>
          , and can provide support to others in the community.
        </Preact.Fragment>
      ),
    "UserExperiencesTxStrings",
    []
  );

  if (user && (!user.has_experience || user.has_experience.length === 0)) {
    return null;
  }

  return (
    <Card className="user-profile__card user-profile__experiences">
      <UserProfilePrivacyIndicator level="public">
        <h3 className="user-profile__card__title">Experiences</h3>
      </UserProfilePrivacyIndicator>
      <div className="user-profile__card__text">
        {user?.has_experience ? (
          <WithTaxonomy type="ExperienceCategory" value={user.has_experience}>
            {render}
          </WithTaxonomy>
        ) : (
          <LoadingParagraph />
        )}
      </div>
    </Card>
  );
};

export const UserPassions: Preact.FunctionComponent = () => {
  const self = useAppUser();
  const user = useContext(USER_DETAIL_CONTEXTS.user);
  if (user && user.id !== self?.id && !user.passions) {
    return null;
  }

  // if self and no passions, show prompt to add passions
  const text = !user
    ? undefined
    : user.id !== self?.id
    ? user.passions
    : user.passions || (
        <EmptyList className="user-profile__passions__empty">
          <p>
            What are your passions? Edit your profile and tell your connections
            what you’re passionate about!
          </p>
          <a
            href={get_url_for_entity(user, "/edit#passions")}
            className="button filled gray"
          >
            Edit Profile
          </a>
        </EmptyList>
      );

  return (
    <Card className="user-profile__card user-profile__passions">
      <UserProfilePrivacyIndicator level="public">
        <h3 className="user-profile__card__title">Passions</h3>
      </UserProfilePrivacyIndicator>
      <div className="user-profile__card__text">
        {user ? text : <LoadingParagraph />}
      </div>
    </Card>
  );
};
