import { pick } from "@thrive-web/ui-common";
import { chain_event_listeners } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import {
  Icon,
  OptionsList,
  Popover,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import {
  useMounted,
  useStateIfMounted,
  useStateRef,
  useTextFilter,
  useTimer,
  useValueRef,
} from "@thrive-web/ui-hooks";

export const SearchBar: Preact.FunctionComponent<{
  onSubmit: (value: string) => void;
  placeholder?: string;
  autoSubmitDelay?: number;
  inputProps?: HTMLInputProps;
  pending?: boolean;
  error?: DisplayableError | null;
  value?: string;
}> = ({
  onSubmit,
  placeholder,
  inputProps = {},
  autoSubmitDelay = 666,
  pending,
  error,
  value: value_prop,
}) => {
  const mounted = useRef(true);
  const [value, setValue] = useStateIfMounted(value_prop || "");
  const [timer, setTimer] = useStateIfMounted<any>(null);
  // debounce automatic submission on value change
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        if (mounted.current) {
          onSubmit(value);
          clearTimeout(timer);
          setTimer(null);
        }
      }, autoSubmitDelay)
    );
    return () => {
      clearTimeout(timer);
      setTimer(null);
    };
  }, [value, setTimer]);

  useEffect(
    () => () => {
      mounted.current = false;
      clearTimeout(timer);
    },
    []
  );

  // allow overriding/clearing the search bar
  const prev_value = useRef(value_prop);
  useEffect(() => {
    if (
      value_prop != null &&
      value_prop !== value &&
      prev_value.current !== value_prop
    ) {
      setValue(value_prop);
    }
    // @ts-expect-error:
    prev_value.current = value_prop;
  }, [value_prop, value]);

  return (
    <div className="search-bar__container">
      <form
        className="search-bar__form"
        onSubmit={e => {
          e.preventDefault();
          if (timer) {
            clearTimeout(timer);
            setTimer(null);
          }
          onSubmit(value);
        }}
      >
        <RequestButtonWithIcon
          className="search-bar__button"
          type="submit"
          pending={!!pending}
          icon="search"
          error={error}
          showError={true}
        />
        <input
          {...inputProps}
          className="search"
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={(e: EventFor<HTMLInputElement>) => setValue(e.target.value)}
        />
      </form>
    </div>
  );
};

const filter_option = (opt: string, r: RegExp) => r.test(opt);
const RenderItemDefault: Preact.FunctionComponent<
  OptionsListItemProps<string>
> = ({ item, onSelect }) => (
  <div className="dropdown-input__item" onClick={() => onSelect(item)}>
    <div className="dropdown-input__item--default search-bar__suggestions__item">
      <div>{item}</div>
      <Icon name="chevron-right" />
    </div>
  </div>
);

// component that shows a (synchronous) list of suggestions filtered on search text
export const SearchBarWithSuggestions: Preact.FunctionComponent<{
  onSubmit: (value: string) => void;
  placeholder?: string;
  autoSubmit?: boolean;
  autoSubmitDelay?: number;
  inputProps?: HTMLInputProps;
  pending?: boolean;
  error?: DisplayableError | null;
  value?: string;
  suggestions: string[];
  RenderItem?: Preact.ComponentType<OptionsListItemProps<string>>;
  getInputRef?: (input: HTMLInputElement) => void;
}> = ({
  onSubmit,
  placeholder,
  inputProps = {},
  autoSubmit,
  autoSubmitDelay = 666,
  pending,
  error,
  value: value_prop,
  suggestions,
  RenderItem = RenderItemDefault,
  getInputRef,
}) => {
  const mounted = useMounted();
  const input_ref = useRef<HTMLInputElement>();
  const [input_props, set_input_props] = useStateIfMounted({});
  const [open, set_open_] = useStateIfMounted(false);
  const [value, set_value, value_ref] = useStateRef(value_prop || "");
  const prev_value = useRef(value_prop);
  const submitted = useRef(value === value_prop);

  // options that match the search text
  const options_all = useTextFilter(value, suggestions, filter_option);
  const options = useMemo(() => options_all.slice(0, 6), [options_all]);
  const has_opts = useValueRef(options.length > 0);

  const set_open = useCallback((val: boolean) => {
    // only open if there are available options and the search hasn't been submitted
    if (!val || (has_opts.current && !submitted.current)) {
      set_open_(val);
    }
  }, []);

  const [clear_submitted] = useTimer(
    () => {
      submitted.current = false;
    },
    200,
    true
  );

  const [auto_submit, cancel_auto_submit] = useTimer(
    (val: string) => {
      if (mounted.current) {
        onSubmit(val);
      }
    },
    autoSubmitDelay,
    true
  );

  useEffect(() => {
    if (has_opts.current && !submitted.current && !open) {
      set_open(true);
    } else if (mounted.current) {
      clear_submitted();
    }

    if (!autoSubmit) {
      return;
    } else {
      auto_submit(value);
      return cancel_auto_submit;
    }
  }, [value]);

  // allow overriding/clearing the search bar
  useEffect(() => {
    if (
      value_prop != null &&
      value_prop !== value &&
      prev_value.current !== value_prop
    ) {
      set_value(value_prop);
      submitted.current = true;
      clear_submitted();
    }
    // @ts-expect-error:
    prev_value.current = value_prop;
  }, [value_prop, value]);

  useEffect(() => {
    if (
      !open &&
      !input_ref.current?.value &&
      input_ref.current !== document.activeElement &&
      !value_ref.current &&
      !value_prop &&
      !submitted.current
    ) {
      onSubmit("");
    }
  }, [open]);

  const on_select_value = useCallback(
    (val: string) => {
      cancel_auto_submit();
      if (
        val !== prev_value.current ||
        document.activeElement !== input_ref.current
      ) {
        submitted.current = true;
        clear_submitted();
      }

      if (val !== value_ref.current && val === prev_value.current) {
        set_value(val);
      } else if (val !== prev_value.current) {
        onSubmit(val);
      }
    },
    [onSubmit]
  );

  const on_submit = useCallback(
    e => {
      e.preventDefault();
      on_select_value(value_ref.current);
    },
    [on_select_value]
  );

  const on_blur_pre = useCallback(e => {
    if (e.target.value) {
      submitted.current = true;
      clear_submitted();
    }
  }, []);
  const on_blur_post = useCallback(
    e => {
      if (!e.target.value && !value_ref.current && !e.ignoreBlur) {
        cancel_auto_submit();
        onSubmit("");
      }
    },
    [onSubmit]
  );

  const on_key_down = useCallback(
    e => {
      if (e.key === "Enter" && !e.alreadySubmitted) {
        e.alreadySubmitted = true;
        on_submit(e);
      }
    },
    [on_submit]
  );

  // combine event listeners from props with those needed internally
  const listeners = useMemo(() => {
    const conflicts = pick(Object.keys(input_props), inputProps);
    return chain_event_listeners<HTMLInputElement>(
      { onBlur: on_blur_pre },
      input_props,
      conflicts,
      { onKeyDown: on_key_down, onBlur: on_blur_post }
    );
  }, [inputProps, input_props, on_blur_pre, on_blur_post, on_key_down]);

  useEffect(() => {
    getInputRef?.(input_ref.current);
  }, []);

  return (
    <div className="search-bar__container search-bar__suggestions">
      <form className="search-bar__form" onSubmit={on_submit}>
        <RequestButtonWithIcon
          className="search-bar__button"
          type="submit"
          pending={!!pending}
          icon="search"
          error={error}
          showError={true}
        />
        <Popover
          show={open}
          className="search-bar__suggestions__dropdown dropdown-input"
          mountLocal={true}
          forceDirection="bottom"
          hideNub={true}
          triggerComponent={
            <input
              {...input_props}
              {...inputProps}
              {...listeners}
              ref={input_ref}
              className="search"
              type="search"
              placeholder={placeholder}
              value={value}
              onChange={(e: EventFor<HTMLInputElement>) => {
                set_value(e.target.value);
              }}
            />
          }
        >
          <div className="options-list__header">
            <Icon name="clock" />
            Recent Searches
          </div>
          <OptionsList
            options={options}
            RenderItem={RenderItem}
            onSelect={on_select_value}
            setControlListeners={set_input_props}
            open={open}
            setOpen={set_open}
            closeOnClickItem={true}
            hideWhenEmpty={true}
          />
        </Popover>
      </form>
    </div>
  );
};
