import * as Preact from "preact";
import {
  ButtonWithIcon,
  PillCheckList,
  Tooltip,
  useAsyncRenderResult,
  useTaxonomy,
} from "@thrive-web/ui-components";
import { useCallback, useMemo } from "preact/hooks";
import { TouchpointAccomplishment } from "@thrive-web/ui-api";

interface AccomplishmentsProps {
  isLastPage: boolean;
  accomplishments: readonly TouchpointAccomplishment[];
  onSelectAccomplishment: (
    acc: TouchpointAccomplishment,
    selected: boolean
  ) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const GroupTouchpointAccomplishments: Preact.FunctionComponent<AccomplishmentsProps> =
  ({
    accomplishments,
    onSelectAccomplishment,
    nextStep,
    prevStep,
    isLastPage,
  }) => {
    const accomplishment_options_raw = useTaxonomy("TouchpointAccomplishment");
    // map taxonomy to InputOptions
    const accomplishment_options = useMemo(
      () =>
        accomplishment_options_raw.value
          ? accomplishment_options_raw.value.map(o => ({
              value: o.id,
              label: o.title || "",
            }))
          : undefined,
      [accomplishment_options_raw]
    );
    const onChangeAccomplishments = useCallback(
      (opt: InputOption<string>, checked: boolean) =>
        accomplishment_options_raw.map &&
        onSelectAccomplishment(
          accomplishment_options_raw.map[opt.value],
          checked
        ),
      [onSelectAccomplishment, accomplishment_options_raw.map]
    );
    const values = useMemo(
      () => accomplishments.map(acc => acc.id),
      [accomplishments]
    );

    // display the checklist once the taxonomy is fetched
    const checklist = useAsyncRenderResult<InputOption<string>[]>(
      result => (
        <PillCheckList
          options={result}
          onSelectOption={onChangeAccomplishments}
          values={values}
        />
      ),
      [onChangeAccomplishments, accomplishments],
      accomplishment_options_raw.status,
      accomplishment_options,
      true
    );

    const stepComplete = accomplishments.length > 0;

    return (
      <div className="group-touchpoint__section group-touchpoint__accomplishments">
        <div className="modal-form__section form__section">
          <div className="form__section-header">What did you accomplish?</div>
          {checklist}
        </div>
        <div className="modal__footer">
          <div className="modal__footer__left">
            <ButtonWithIcon
              className="filled gray"
              onClick={prevStep}
              icon="chevron-left"
              side="left"
            >
              Back
            </ButtonWithIcon>
          </div>
          <div className="modal__footer__right">
            <Tooltip
              text="Please select at least one accomplishment."
              disabled={stepComplete}
              defaultDirection="top"
              defaultOffset="left"
            >
              <ButtonWithIcon
                className="filled gray"
                onClick={stepComplete ? nextStep : undefined}
                icon={isLastPage ? "checked" : "chevron-right"}
                side="right"
              >
                {isLastPage ? "Finish" : "Next"}
              </ButtonWithIcon>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };
