import * as Preact from "preact";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { route } from "preact-router";
import { Comment, Post, Reaction, User } from "@thrive-web/ui-api";
import { comparator } from "@thrive-web/ui-common";
import {
  Avatar,
  ButtonWithIcon,
  EntityLink,
  ReactionButton,
  PostMood,
  PostPhoto,
  PostTouchpoint,
  PostExpense,
  TextWithLinks,
  PostMeta,
  PostTaggedUsers,
  LazyListSection,
  useLazyList,
  PostHelp,
  useGroupUpdatesAllowed,
  INITIAL_SHOWN_COMMENT_COUNT,
  CommentItem,
  Icon,
  PostEvent,
  PostShareButton,
  WithUser,
  GroupPermCheck,
  useGroupPerms,
  ACTIVITY_FEED_CAN_INTERACT,
  PostLinkPreview,
  IS_ADMIN_UI,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useCallbackRef,
  useId,
  useSingleClickDetection,
  useValueRef,
  useZendeskReportEntity,
} from "@thrive-web/ui-hooks";
import { cache_record, get_url_for_entity } from "@thrive-web/ui-utils";
import { getScope } from "./utils";
import { PostActions } from "./PostActions";
import { CONTEXTS } from "@thrive-web/ui-model";
import { ScreenSize } from "@thrive-web/ui-constants";

interface PostListItemProps {
  data: Post;
  showScope?: boolean;
  deletePost?: (post: Post) => void;
  editPost?: (post: Post) => void;
  isLink?: boolean;
  onReact?: (post: Post, reaction: Reaction, deleted: boolean) => void;
}

export const PostListItem: Preact.FunctionComponent<PostListItemProps> = ({
  data,
  showScope,
  editPost,
  deletePost,
  isLink = true,
  onReact,
}) => {
  const is_admin_ui = useContext(IS_ADMIN_UI);
  const user = useAppUser();
  const window_size = useContext(CONTEXTS.window_size);
  const creator_name = (data.created_by as User)?.full_name;
  const eval_can_interact = useContext(ACTIVITY_FEED_CAN_INTERACT);
  const can_interact = useMemo(
    () => eval_can_interact?.(data),
    [eval_can_interact, data]
  );

  const scope = useMemo(
    () => (showScope ? getScope(data.posted_to!) : {}),
    [data.posted_to]
  );
  // is self a member of the scope that this post was posted to
  const is_member = useGroupPerms("has_member", data.posted_to);
  const is_link = is_member && isLink;

  const id = useId(`post-list-item-${data.id}`);
  const ignore_link_click = useRef(false);
  const link_listener = useCallbackRef<EventListener>(
    (e: EventFor<HTMLElement>) => {
      const { current } = ignore_link_click;
      ignore_link_click.current = false;
      // ignore if clicking on a button, link, or any element with a click listener, don't navigate
      if (
        e.target.tagName === "A" ||
        e.target.tagName === "BUTTON" ||
        e.target.classList.contains("expandable-image") ||
        e.target.dataset.ignoreLinkClick ||
        // @ts-expect-error:
        e.ignoreLinkClick ||
        current
      ) {
        return;
      }
      cache_record(data, true);
      route(get_url_for_entity(data));
    },
    [id, data]
  );

  const ignore_link_click_func = useCallback(e => {
    e.ignoreLinkClick = true;
    ignore_link_click.current = true;
  }, []);

  const ignore_attrs = useMemo(
    () => ({
      "data-ignore-link-click": true,
      onMouseUp: ignore_link_click_func,
    }),
    [ignore_link_click_func]
  );

  const listeners = useSingleClickDetection(link_listener);

  const onClickEdit = useCallback(
    () => editPost && editPost(data),
    [editPost, data]
  );
  const onClickDelete = useCallback(
    () => deletePost && deletePost(data),
    [deletePost, data]
  );

  const onClickComment = useCallback(() => {
    cache_record(data, true);
    route(get_url_for_entity(data, "#comment"));
  }, [data]);

  const touchpoint = useMemo(
    () =>
      data.has_touchpoint
        ? { ...data.has_touchpoint, posted_to: data }
        : undefined,
    [data]
  );

  const expense = useMemo(
    () =>
      data.has_expense ? { ...data.has_expense, posted_to: data } : undefined,
    [data]
  );

  const comments = useMemo(
    () =>
      data.has_comment?.length
        ? (data.has_comment
            .slice()
            .sort(
              comparator(
                (com: Comment) => new Date(com.created_at as string).getTime(),
                "desc"
              )
            )
            .slice(0, INITIAL_SHOWN_COMMENT_COUNT) as Comment[])
        : undefined,
    [data.has_comment]
  );

  const post = useValueRef(data);
  const onReactBound = useCallback(
    (reaction: Reaction, deleted: boolean) => {
      if (onReact) {
        onReact(post.current, reaction, deleted);
      }
    },
    [onReact]
  );

  const allow_group_updates = useGroupUpdatesAllowed(data?.posted_to);

  const tagged_users =
    data.has_tagged_user && data.has_tagged_user.length > 0 ? (
      <PostTaggedUsers
        post={data}
        align={window_size <= ScreenSize.xs ? "left" : "right"}
      />
    ) : null;

  const on_report_post = useZendeskReportEntity(data);

  const link = data.video || data.zendesk_article;

  if (!data.created_by || !user) {
    return null;
  }

  return (
    <article
      className={`card post${is_link ? " post--link" : ""}`}
      {...(is_link ? listeners : {})}
    >
      <div className="post__content">
        <div className="post__top">
          {scope.color && (
            <div className="post__top__border">
              <span style={{ color: scope.color }}> </span>
            </div>
          )}
          <div className="post__people">
            <div className="post__people__left" {...ignore_attrs}>
              <Avatar
                // @ts-ignore
                user={data.created_by}
                size="md"
              />
              <div className="post__people__creator">
                <EntityLink entity={data.created_by}>{creator_name}</EntityLink>
                {scope.name && (
                  <div className="post__people__scope">
                    in{" "}
                    <EntityLink
                      className="post__people__scope__link"
                      entity={data.posted_to!}
                      {...ignore_attrs}
                    >
                      {scope.name}
                    </EntityLink>
                  </div>
                )}
              </div>
            </div>
            {tagged_users && window_size > ScreenSize.xs && (
              <div className="post__people__right" {...ignore_attrs}>
                {tagged_users}
              </div>
            )}
          </div>
        </div>
        <div className="post__body" data-ignore-link-click={true}>
          {window_size <= ScreenSize.xs ? tagged_users : null}
          {data.mood && <PostMood mood={data.mood} />}
          {data.body && (
            <div className="post__text">
              <TextWithLinks>{data.body}</TextWithLinks>
            </div>
          )}
          {data.photo_url && <PostPhoto data={data} />}
        </div>
        <div className="post__blocks">
          {data.is_asking_for_help && <PostHelp user={data.created_by} />}
          {data.event && <PostEvent event={data.event} isLink={true} />}
          {link && (
            <PostLinkPreview
              link={link.id}
              onClickLink={ignore_link_click_func}
            />
          )}
          {allow_group_updates && touchpoint && (
            <PostTouchpoint touchpoint={touchpoint} />
          )}
          {allow_group_updates && expense && <PostExpense expense={expense} />}
          {comments && (
            <div className="comments post-block">
              <ul className="comment__list" data-length={comments.length}>
                {comments.map((c, i) => (
                  <li key={c.id} className="comment__list__item">
                    <WithUser id={c.created_by!.id} user={c.created_by}>
                      <CommentItem data={c} canInteract={!!can_interact} />
                    </WithUser>
                  </li>
                ))}
              </ul>
              {can_interact && (
                <GroupPermCheck role="has_member">
                  <div className="post__comment__add">
                    <div className="post__comment__add__avatar">
                      <Avatar user={user} size="sm" />
                    </div>
                    <div
                      className="fake-input"
                      data-placeholder={true}
                      data-disabled={true}
                      onClick={onClickComment}
                      onMouseUp={ignore_link_click_func}
                    >
                      Add a comment...
                    </div>
                    <div className="button with-icon icon-only">
                      <div className="with-icon__icon">
                        <Icon name="paper-airplane" />
                      </div>
                    </div>
                  </div>
                </GroupPermCheck>
              )}
            </div>
          )}
        </div>
        <PostMeta post={data} />
        {(can_interact || is_admin_ui) && (
          <div className="post__controls">
            <div className="post__controls__left" {...ignore_attrs}>
              {can_interact && (
                <Preact.Fragment>
                  {onReact && (
                    <ReactionButton
                      post_id={data.id}
                      showCount={false}
                      className="filled gray"
                      reactions={data.has_reaction || []}
                      onUpdate={onReactBound}
                    />
                  )}
                  <ButtonWithIcon
                    className="filled gray"
                    icon="comment-solid-chat-bubble"
                    side="left"
                    onClick={onClickComment}
                  />
                </Preact.Fragment>
              )}
            </div>
            <div className="post__controls__right" {...ignore_attrs}>
              {can_interact && <PostShareButton post={data} />}
              <PostActions
                post={data}
                editPost={onClickEdit}
                deletePost={onClickDelete}
                reportPost={!!onReact ? on_report_post : undefined}
              />
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

export const PostList: Preact.FunctionComponent<{
  data: readonly Post[];
  showScope?: boolean;
  editPost?: (post: Post) => void;
  deletePost?: (post: Post) => void;
  onReact?: (post: Post, reaction: Reaction, deleted: boolean) => void;
  loadMoreElem?: Preact.VNode | null;
}> = ({ data, showScope, editPost, deletePost, onReact, loadMoreElem }) => {
  const content = useLazyList(
    data,
    (p: Post) => (
      <li key={p.id} className="post__list__item">
        <PostListItem
          data={p}
          showScope={showScope}
          editPost={editPost}
          deletePost={deletePost}
          onReact={onReact}
        />
      </li>
    ),
    [showScope, editPost, deletePost, onReact]
  );

  return (
    <ul className="post__list">
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && <li className="load-more">{loadMoreElem}</li>}
    </ul>
  );
};
