import {
  AndClause,
  BooleanExpression,
  NegatableExpression,
  NotExpression,
} from "@swymbase/sparql-rest";
import { FilterSpec } from "@thrive-web/core";
import { ConnectionMapped, User } from "@thrive-web/ui-api";
import { moment } from "@thrive-web/ui-common";

export const connection_coach_filter = (
  user: User | undefined | null
): FilterSpec | undefined => {
  if (!user) {
    return;
  }
  return [
    ["exists", ["this", "Connection:has_coach"]],
    ["=", ["this", "Connection:users"], ["id", user.id]],
  ];
};

export const filter_user_not_connected = (user_id: string): NotExpression =>
  [
    "not",
    [
      "=",
      ["this", ["/", "User:has_connection", "Connection:users"]],
      ["id", user_id],
    ],
  ] as const;

export const filter_user_connected = <And extends boolean = false>(
  user: User,
  as_and?: And
): And extends false ? NegatableExpression[] : AndClause => {
  const filter: NegatableExpression[] = [
    [
      "=",
      ["this", ["/", "User:has_connection", "Connection:users"]],
      ["id", user.id],
    ],
    [
      "not",
      // workaround
      ["=", ["this", "User:firebase_uuid"], user.firebase_uuid as string],
    ],
  ];
  return as_and ? ["and", ...filter] : (filter as any);
};

export const filter_user_is_adult = (): BooleanExpression =>
  [
    "<=",
    ["this", "User:birth_date"],
    moment(Date.now()).subtract(18, "year").startOf("day").toISOString(),
  ] as const;

export const filter_user_is_minor = (): BooleanExpression =>
  [
    ">",
    ["this", "User:birth_date"],
    moment(Date.now()).subtract(18, "year").startOf("day").toISOString(),
  ] as const;

// create query filter object given search str
export const connection_search_filter = (
  user: User | undefined | null,
  search: string
): FilterSpec | undefined => {
  if (!user) {
    return;
  }
  let filter_clause: FilterSpec = [
    [
      "=",
      ["this", ["/", "User:has_connection", "Connection:users"]],
      ["id", user.id],
    ],
    [
      "not",
      // workaround
      ["=", ["this", "User:firebase_uuid"], user.firebase_uuid as string],
    ],
  ];

  if (search) {
    filter_clause = [
      ...filter_clause,
      ["match", ["this", "User:full_name"], search, "i"],
    ];
  }

  return filter_clause;
};

// create query filter object given search str and target user
export const connection_request_search_filter = (
  user_id: string | undefined,
  search: string | undefined,
  key: "recipient" | "sender"
): FilterSpec | undefined => {
  if (!user_id || !key) {
    return;
  }
  let filter_clause: FilterSpec = [
    ["=", ["this", "ConnectionRequest:accepted"], false],
    [
      "=",
      [
        "this",
        `ConnectionRequest:${key === "sender" ? "recipient" : "sender"}`,
      ],
      ["id", user_id],
    ],
  ];
  if (search) {
    filter_clause = [
      ...filter_clause,
      [
        "match",
        ["this", ["/", `ConnectionRequest:${key}`, "User:full_name"]],
        search,
        "i",
      ],
    ];
  }
  return filter_clause;
};

// generate filter for searching for new users to connect to
// conditions:
//   1. Not connected with current user
//   2. Not sender or recipient of conn request with current user
//   3. (optional) name matches search string
export const new_connection_search_filter = (
  user_id: string | undefined,
  search?: string
): FilterSpec | undefined => {
  if (!user_id) {
    return;
  }
  let filter_clause: FilterSpec = [
    [
      "not",
      [
        "=",
        ["this", ["/", "User:has_connection", "Connection:users"]],
        ["id", user_id],
      ],
    ],
    [
      "not",
      [
        "=",
        [
          "this",
          [
            "|",
            [
              "/",
              ["^", "ConnectionRequest:sender"],
              "ConnectionRequest:recipient",
            ],
            [
              "/",
              ["^", "ConnectionRequest:recipient"],
              "ConnectionRequest:sender",
            ],
          ],
        ],
        ["id", user_id],
      ],
    ],
  ];
  if (search) {
    filter_clause = [
      ...filter_clause,
      ["match", ["this", "User:full_name"], search, "i"],
    ];
  }
  return filter_clause;
};

// for optimization/easy access, adds property for the other user in the connection (not self)
export const map_connection_with_other_user = (
  { has_connection = [], ...other_user }: User,
  self: User
): ConnectionMapped | undefined => {
  const connection = has_connection.find(
    c => (c["users"] || []).some(u => u.id === self.id) as User
  );
  if (!connection) {
    return;
  }

  connection["users"] = [self, other_user];

  return {
    ...connection,
    other_user,
  };
};
