import { MetaTypes, RelationshipKeysOf } from "@thrive-web/core";
import * as Preact from "preact";
import { memo } from "preact/compat";
import { useEffect, useMemo } from "preact/hooks";
import { MappedApiResponse, User } from "@thrive-web/ui-api";
import { is_id_obj } from "@thrive-web/ui-common";
import { useApiFetch, useStateIfMounted } from "@thrive-web/ui-hooks";
import {
  cache_record,
  createNamedContext,
  get_cache,
} from "@thrive-web/ui-utils";

export const UserProvider = createNamedContext<User | null>(null, "WithUser");

const active_reqs: {
  [key: string]: Promise<MappedApiResponse<"getUser">>;
} = {};

// ensure that we have a user record with all the included properties populated
export const WithUser: Preact.FunctionComponent<{
  id: string;
  user?: User;
  include?: RelationshipKeysOf<MetaTypes["User"]>[];
}> = memo(({ id, user, include: include_, children }) => {
  const user_id = id || user?.id;
  const include = useMemo(
    () => include_ ?? ["profile_picture" as const],
    [include_]
  );

  const [fetched, set_fetched] = useStateIfMounted<User | null>(null);
  const user_data = useMemo(() => {
    const cached = get_cache(id);
    return (
      // if the user from props is sufficient, return it without fetching
      (user && !is_id_obj(user) && ensure_included(user, include)
        ? user
        : // use the cached record if it is sufficient
        ensure_included(cached, include)
        ? cached
        : // otherwise use the fetched record;
          null) || fetched
    );
  }, [user, id, fetched, include]);

  // stringify for cheap equality check
  const user_data_str = JSON.stringify(user_data);
  const include_str = JSON.stringify(include);

  const params = useMemo(() => {
    if (!include) {
      return;
    }
    return { query: { include } };
  }, [include_str]);
  const getUser = useApiFetch("getUser", id, params);

  // ensure the data we picked has all the included properties
  const has_included = useMemo(
    () => ensure_included(user_data, include),
    [user_data_str, include_str]
  );
  useEffect(() => {
    // if there's no data (or missing included props) we need to fetch the user
    if (!user_data?.id || is_id_obj(user_data) || !has_included) {
      if (!user_id) {
        return;
      }
      // if there's no request in progress for this user, send one
      if (!active_reqs[user_id]) {
        active_reqs[user_id] = getUser().then(res => {
          setTimeout(() => {
            delete active_reqs[user_id];
          }, 1000);
          return res;
        });
      }
      active_reqs[user_id].then(res => {
        cache_record(res.data, true);
        set_fetched(res.data);
        return res;
      });
    }
  }, [user_data_str, include_str]);

  return (
    <UserProvider.Provider value={user_data}>{children}</UserProvider.Provider>
  );
});

// given a user record and a list of user relationship properties, ensure that
// all values of those properties on the given user are present, and that none
// of them are just id objects (i.e. the full records are properly included)
const ensure_included = (
  user?: User | null,
  include?: RelationshipKeysOf<MetaTypes["User"]>[]
) => {
  if (!include) {
    return true;
  }
  if (!user) {
    return false;
  }
  return include.every(rel => {
    const val = user[rel];
    if (!val) {
      return false;
    }
    if (Array.isArray(val)) {
      return val.some(item => !is_id_obj(item));
    }
    // @ts-expect-error:
    return !is_id_obj(val);
  });
};
