import lottie from "lottie-web";
import * as Preact from "preact";
import { useContext, useEffect, useMemo, useRef, useState } from "preact/hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import { usePreviousValue } from "@thrive-web/ui-hooks";
const lottie_anim = require("~/../site/static/images/user-health-anim.json");

const AVATAR_MAX_HEALTH = 4;

// used guide: https://css-tricks.com/building-progress-ring-quickly/
export const AvatarHealth: Preact.FunctionComponent<{
  rank: number;
  showAnim?: boolean;
}> = ({ rank, showAnim, children }) => {
  const window_size = useContext(CONTEXTS.window_size);
  const [mounted, set_mounted] = useState(false);
  const svg_ref = useRef<SVGSVGElement>();
  const div_ref = useRef<HTMLDivElement>();
  const circle_ref = useRef<SVGCircleElement>();
  const anim_ref = useRef<HTMLDivElement>();
  // rad = radius to the center of the ring stroke
  // rad_outer = radius to the outside edge of the ring stroke
  // circ = circumference (using `rad`)
  const [rad, rad_outer, circ] = useMemo(() => {
    if (!svg_ref.current) {
      return [0, 0, 0, 0, 0];
    }
    const width = parseInt(
      window.getComputedStyle(div_ref.current).width.replace("px", "")
    );
    const stroke_w = parseInt(
      window.getComputedStyle(svg_ref.current).strokeWidth.replace("px", "")
    );

    const r = width / 2 - 1;
    const r_outer = r + stroke_w / 2;
    const c = r * 2 * Math.PI;
    return [r, r_outer, c];
  }, [div_ref.current?.clientWidth, svg_ref.current, mounted, window_size]);

  // store the previous rank for transition calculation
  const prev_rank = usePreviousValue(rank);
  const style = useMemo(() => {
    const diff = Math.abs((prev_rank.current || rank) - rank);
    prev_rank.current = rank;
    return {
      // stroke dash offset is a percentage of the circumference
      strokeDashOffset:
        circ === 0 ? 0 : circ - ((rank + 1) / (AVATAR_MAX_HEALTH + 1)) * circ,
      // faster transition when rank difference is higher
      transitionDuration: diff
        ? `${0.75 + Math.sqrt(diff) / (AVATAR_MAX_HEALTH / 2)}s`
        : "0.5s",
    };
  }, [circ, rank, mounted, window_size]);

  // confetti animation
  const anim = useMemo(
    () =>
      showAnim && anim_ref.current
        ? lottie.loadAnimation({
            container: anim_ref.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: lottie_anim,
          })
        : undefined,
    [showAnim, anim_ref.current]
  );

  useEffect(() => {
    if (prev_rank.current !== rank && rank === AVATAR_MAX_HEALTH) {
      setTimeout(() => anim?.goToAndPlay(0), 750);
    }
  }, [rank]);

  useEffect(() => {
    // force the component to rerender so the dimensions can be calculated now that the
    // refs are mounted
    set_mounted(true);
  }, []);

  useEffect(() => {
    if (circle_ref.current) {
      requestAnimationFrame(() => {
        if (circle_ref.current) {
          circle_ref.current.style.display = "none";
          requestAnimationFrame(() => {
            if (circle_ref.current) {
              circle_ref.current.style.display = "";
            }
          });
        }
      });
    }
  }, [window_size]);

  return (
    <div
      className="user-avatar__health"
      data-rank={rank}
      ref={div_ref}
      data-anim={`${showAnim}`}
    >
      {showAnim && <div className="user-avatar__health__anim" ref={anim_ref} />}
      <svg
        className="user-avatar__health__ring"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        ref={svg_ref}
        {...(circ > 0
          ? {
              width: rad_outer * 2,
              height: rad_outer * 2,
              viewBox: `0 0 ${rad_outer * 2} ${rad_outer * 2}`,
            }
          : {})}
      >
        {circ > 0 ? (
          <Preact.Fragment>
            <circle
              className="user-avatar__health__ring__bg"
              strokeWidth={0}
              stroke-width={0}
              stroke="none"
              r={rad_outer}
              cx={rad_outer}
              cy={rad_outer}
            />
            <circle
              className="user-avatar__health__ring__meter"
              r={rad}
              cx={rad_outer}
              cy={rad_outer}
              style={style}
              ref={circle_ref}
              stroke-dashoffset={style.strokeDashOffset}
              strokeDasharray={`${circ} ${circ}`}
            />
          </Preact.Fragment>
        ) : null}
      </svg>
      {children}
    </div>
  );
};
