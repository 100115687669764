import { ApiMethodParameters } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { ConnectionMapped, Group, User } from "@thrive-web/ui-api";
import { InteractionTypes } from "@thrive-web/ui-constants";
import { InteractionType, set_dirty } from "@thrive-web/ui-model";
import {
  Avatar,
  BooleanPillRadioList,
  ButtonWithIcon,
  Carousel,
  ErrorMessage,
  PillRadioList,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import { useApiCall, useStateIfMounted } from "@thrive-web/ui-hooks";

const INT_TYPE_OPTIONS = [
  {
    value: InteractionTypes.CALL_USER,
    label: "Phone Call",
  },
  {
    value: InteractionTypes.TEXT_USER,
    label: "Text Message",
  },
  {
    value: InteractionTypes.EMAIL_USER,
    label: "Email",
  },
  {
    value: InteractionTypes.MEET_IN_PERSON,
    label: "In Person",
  },
  {
    value: InteractionTypes.OTHER,
    label: "Other",
  },
];

export const FollowupModalBody: Preact.FunctionComponent<
  ModalBodyProps & {
    type: InteractionType;
    user: User;
    connection: ConnectionMapped;
    groups: Group[] | null;
    openPostModal: () => void;
    success?: boolean;
    interactionType: InteractionTypes;
    onCreateInteraction?: (health?: number) => void;
  }
> = ({
  user,
  groups,
  connection,
  openPostModal,
  type,
  closeButton,
  interactionType,
  onCreateInteraction,
  success: initial_success,
  dismiss,
}) => {
  const [page, set_page] = useStateIfMounted<"success" | "post" | "int_type">(
    type === "unknown" ? "int_type" : "success"
  );
  const [health, set_health] = useStateIfMounted(connection.health_tier || 0);
  useEffect(() => {
    set_dirty(true, "track-interaction-followup");
    setTimeout(() => set_health(Math.min(health + 1, 4)), 250);
  }, []);

  const [int_type, set_int_type] = useStateIfMounted(interactionType);
  const on_change_int_type = useCallback(
    (opt: InputOption<InteractionTypes>) => {
      set_int_type(opt.value);
    },
    [set_int_type]
  );

  const [success, set_success] = useStateIfMounted<boolean | undefined>(
    initial_success
  );
  const [start_post, set_start_post] = useStateIfMounted<boolean | undefined>(
    undefined
  );

  const no_groups = groups?.length === 0;

  const params = useMemo<ApiMethodParameters<"POST", "Interaction">>(
    () => ({
      body: {
        data: {
          attributes: {
            interaction: int_type,
            successful: success,
          },
          relationships: {
            target: {
              data: {
                id: user.id,
              },
            },
          },
        },
      },
    }),
    [success, user, int_type]
  );
  const [create_interaction, status] = useApiCall("createInteraction");

  const close = useCallback(() => {
    set_dirty(false, "track-interaction-followup");
    dismiss();
  }, [dismiss]);

  const onClickSuccess = useCallback(() => {
    if (success) {
      set_health(4);
    }
    create_interaction(params).then(() => {
      if (type === "unknown") {
        analytics.log_event(analytics.EVENTS.health_drop_nudge);
      }
      onCreateInteraction?.();
      if (!no_groups && success) {
        set_page("post");
      } else {
        setTimeout(close, 1200);
      }
    });
  }, [close, success, no_groups, type, onCreateInteraction]);

  const onClickFinish = useCallback(() => {
    if (start_post == null) {
      return;
    }
    if (start_post) {
      openPostModal?.();
      analytics.log_event(analytics.EVENTS.follow_up.group_update);
    } else {
      analytics.log_event(analytics.EVENTS.follow_up.do_later);
    }
    close();
  }, [close, start_post, user]);

  const pages = {
    success: (
      <Preact.Fragment>
        <div className="modal__body">
          <div className="interaction-modal__prompt">
            <h3>
              Did {user.first_name}{" "}
              {type === "phone"
                ? "answer"
                : `respond to your ${
                    type === "email" ? "email" : "text message"
                  }`}
              ?
            </h3>
          </div>
          <div className="interaction-modal__form">
            <BooleanPillRadioList
              onChange={set_success}
              value={success}
              name="was-successful"
            />
          </div>
        </div>
        <div className="modal__footer">
          <div className="modal__footer__left">
            <button className="filled gray" type="button" onClick={close}>
              Cancel
            </button>
          </div>
          <div className="modal__footer__right">
            {status.error && (
              <ErrorMessage>{status.error.message}</ErrorMessage>
            )}
            <RequestButtonWithIcon
              pending={!groups || status.pending}
              icon={
                success === false || no_groups ? "checked" : "chevron-right"
              }
              side="right"
              className="filled gray"
              disabled={success == null}
              onClick={onClickSuccess}
            >
              {success === false || no_groups ? "Done" : "Next"}
            </RequestButtonWithIcon>
          </div>
        </div>
      </Preact.Fragment>
    ),
    int_type: (
      <Preact.Fragment>
        <div className="modal__body">
          <div className="interaction-modal__prompt">
            <h3>How did you connect?</h3>
          </div>
          <div className="interaction-modal__form">
            <PillRadioList
              onSelectOption={on_change_int_type}
              value={int_type}
              name="int-type"
              options={INT_TYPE_OPTIONS}
            />
          </div>
        </div>
        <div className="modal__footer">
          <div className="modal__footer__left">
            <button className="filled gray" type="button" onClick={close}>
              Cancel
            </button>
          </div>
          <div className="modal__footer__right">
            {status.error && (
              <ErrorMessage>{status.error.message}</ErrorMessage>
            )}
            <RequestButtonWithIcon
              pending={!groups || status.pending}
              icon={no_groups ? "checked" : "chevron-right"}
              side="right"
              className="filled gray"
              disabled={success == null}
              onClick={onClickSuccess}
            >
              {no_groups ? "Done" : "Next"}
            </RequestButtonWithIcon>
          </div>
        </div>
      </Preact.Fragment>
    ),
    post: (
      <Preact.Fragment>
        <div className="modal__body">
          <div className="interaction-modal__prompt">
            <h3>That's great!</h3>
            <p>
              Would you like to let one of your groups know you connected with{" "}
              {user.first_name}?
            </p>
          </div>
          <div className="interaction-modal__form">
            <BooleanPillRadioList
              onChange={set_start_post}
              value={start_post}
              name="should-start-post"
              trueLabel="Update one of my groups"
              falseLabel="I'll do this later"
            />
          </div>
        </div>
        <div className="modal__footer">
          <div className="modal__footer__right">
            <ButtonWithIcon
              icon={start_post === false ? "checked" : "chevron-right"}
              side="right"
              className="filled gray"
              disabled={start_post == null}
              onClick={onClickFinish}
            >
              {start_post === false ? "Done" : "Next"}
            </ButtonWithIcon>
          </div>
        </div>
      </Preact.Fragment>
    ),
    finished: (
      <Preact.Fragment>
        <div className="modal__body">
          <div className="interaction-modal__prompt">
            <h3>That's great!</h3>
            <p>
              You’re keeping in touch with your connections—keep up the great
              work!
            </p>
          </div>
        </div>
      </Preact.Fragment>
    ),
  };

  return (
    <div className="interaction-modal__content">
      <div className="interaction-modal__avatar">
        <Avatar
          user={user}
          size="xl"
          health={health}
          showHealthAnim={true}
          isLink={false}
        />
      </div>
      <Carousel page={page} items={pages} />
    </div>
  );
};
