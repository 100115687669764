import * as Preact from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import {
  DefaultErrorView,
  DefaultPendingView,
  DropdownSelectInputDefaultButton,
  DropdownSelectInputListItem,
  DynamicListDispatch,
  OptionsList,
  Popover,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import {
  useId,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { closestAncestor, maybeClassName } from "@thrive-web/ui-utils";

const noop = () => {};

const PendingView: Preact.FunctionComponent = () => (
  <div className="dropdown-select-input__options-loading">
    <DefaultPendingView />
  </div>
);

const ErrorView: Preact.FunctionComponent<{ error: DisplayableError }> = ({
  error,
}) => (
  <div className="dropdown-select-input__options-error">
    <DefaultErrorView error={error} />
  </div>
);

export const DropdownSelectInputAsync = <
  T extends any,
  E extends HTMLElement = HTMLElement,
  Args extends [number, number?] = [number, number?]
>({
  value,
  options,
  getValueLabel,
  onSelect,
  label,
  // @ts-expect-error:
  Button = DropdownSelectInputDefaultButton,
  popoverProps,
  className,
  required,
  disabled,
  buttonProps = {},
  mountLocal,
  fetch,
  resetList,
  concatList,
}: DropdownSelectInputAsyncProps<T, E>) => {
  const menu_id = useId(undefined, "dropdown-select-input--menu");
  const [open, setOpen] = useStateIfMounted(false);
  const button_ref = useRef<E | null>(null);
  const [buttonControlProps, setButtonPropsState] = useStateIfMounted<
    OptionsListControlProps<E>
    // @ts-expect-error:
  >({});

  const mouse_down_in_menu = useRef(false);
  const ignore_on_focus = useRef(false);
  const on_mouse_release = useRef<((e) => void) | undefined>();

  const on_mouse_up = useCallback(e => {
    mouse_down_in_menu.current = false;
    if (!closestAncestor(e.target, `#${menu_id}`) && on_mouse_release.current) {
      on_mouse_release.current(e);
    } else {
      ignore_on_focus.current = true;
      button_ref.current &&
        button_ref.current.focus &&
        button_ref.current.focus();
    }
    on_mouse_release.current = undefined;
  }, []);

  const on_menu_mouse_down = useCallback(
    e => {
      if (mouse_down_in_menu.current) {
        return;
      }
      mouse_down_in_menu.current = true;
      window.addEventListener("mouseup", on_mouse_up, { once: true });
    },
    [on_mouse_up]
  );

  const setButtonProps = useCallback(
    (btn_props: OptionsListControlProps<E>) => {
      const { onBlur, onFocus } = btn_props;
      setButtonPropsState({
        ...btn_props,
        onFocus: e => {
          if (!ignore_on_focus.current) {
            onFocus(e);
          }
          ignore_on_focus.current = false;
        },
        onBlur: e => {
          if (!mouse_down_in_menu.current) {
            onBlur(e);
          } else {
            on_mouse_release.current = onBlur.bind(e);
          }
        },
      });
    },
    [setButtonPropsState]
  );

  const RenderItem = useRenderPropsFunction<OptionsListItemProps<T>>(
    ({ item, onSelect: onSelectItem }) => (
      <DropdownSelectInputListItem
        item={item}
        onSelect={onSelectItem}
        getValueLabel={getValueLabel}
      />
    ),
    "DropdownSelectInputListItem-Bound",
    [getValueLabel]
  );

  const dispatch = useMemo(
    () =>
      ({ reset: resetList, concat: concatList } as DynamicListDispatch<
        T,
        false,
        Args
      >),
    [resetList, concatList]
  );
  const passthrough_props = useMemo(
    () => ({
      menu_id,
      onMouseDown: on_menu_mouse_down,
      disabled: !open,
      tabIndex: !open ? -1 : undefined,
      open,
      RenderItem,
      onSelect,
      setControlListeners: setButtonProps,
      setOpen: !disabled ? setOpen : noop,
    }),
    [
      menu_id,
      on_menu_mouse_down,
      open,
      RenderItem,
      onSelect,
      setButtonProps,
      disabled,
    ]
  );

  const content = useRenderDynamicListWithPagedFetch(
    options,
    dispatch,
    (
      result: readonly T[],
      load_more_elem: Preact.VNode | null,
      _,
      passthrough
    ) => {
      if (!passthrough) {
        return null;
      }
      const { menu_id, onMouseDown, disabled, tabIndex, ...props } =
        passthrough;
      return (
        <fieldset
          id={menu_id}
          onMouseDown={onMouseDown}
          disabled={disabled}
          tabIndex={tabIndex}
        >
          <OptionsList
            options={result}
            loadMoreElem={load_more_elem}
            {...props}
          />
        </fieldset>
      );
    },
    [],
    fetch,
    passthrough_props,
    {
      limit: 5,
      PendingView,
      ErrorView,
      ignoreResendError: true,
      resendOnFuncChange: true,
    }
  );

  return (
    <Popover
      defaultDirection="bottom"
      {...popoverProps}
      className={`dropdown-input dropdown-menu dropdown-select-input${maybeClassName(
        className
      )}`}
      triggerComponent={
        <Button
          {...buttonProps}
          {...buttonControlProps}
          value={value !== undefined ? getValueLabel(value) : undefined}
          onClick={() => !disabled && setOpen(true)}
          ref={button_ref}
          className={`dropdown-select-input__button${maybeClassName(
            buttonProps?.className
          )}`}
          disabled={disabled}
        >
          <Preact.Fragment>
            {value !== undefined ? getValueLabel(value) : label}
            {required ? (
              <div className="input__hidden">
                <input
                  tabIndex={-1}
                  value={value ? getValueLabel(value) : undefined}
                  required={true}
                />
              </div>
            ) : null}
          </Preact.Fragment>
        </Button>
      }
      show={open && !disabled}
      hideNub={true}
      mountLocal={mountLocal}
    >
      {content}
    </Popover>
  );
};
