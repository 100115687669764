import { RecordType } from "@thrive-web/ui-api";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { Link } from "preact-router/match";
import { Types } from "@thrive-web/core";
import {
  maybeClassName,
  get_url_for_entity,
  cache_record,
} from "@thrive-web/ui-utils";

type EntityLinkProps<T extends keyof Types> = Preact.RenderableProps<
  {
    entity: RecordType<T>;
  } & Omit<HTMLAnchorProps, "href">
>;

// given any record, if that record has a valid route in the app, render a link to that route
export const EntityLink = <T extends keyof Types>({
  entity,
  onClick,
  className,
  ...props
}: EntityLinkProps<T>) => {
  const onClickLink = useCallback(
    e => {
      // @ts-ignore
      onClick && onClick(e);
      cache_record(entity, true);
    },
    [onClick, entity]
  );
  const href = useMemo(() => get_url_for_entity(entity), [entity.id]);
  // if no valid route, return children
  if (!entity) {
    return <Preact.Fragment>{props.children || null}</Preact.Fragment>;
  }
  return (
    <Link
      {...props}
      href={href}
      className={`entity-link${maybeClassName(className)}`}
      onClick={onClickLink}
    />
  );
};
