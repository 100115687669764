import * as Preact from "preact";
import { CONTEXTS, Provider, SiteComponentProps } from "@thrive-web/ui-model";
import authWrapper from "~/auth/auth";
import { Feedback } from "~/view/components";
import {
  AsyncRender,
  DefaultErrorView,
  Icon,
  SiteModal,
} from "@thrive-web/ui-components";
import { useApiFetch, useDocumentTitle } from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

export const FeedbackSite: Preact.FunctionComponent<
  SiteComponentProps & { token: string }
> = ({ token, model, keys }) => {
  const request = useApiFetch("feedbackSurvey", token);
  useDocumentTitle(() => make_title(["Feedback Survey"]), []);

  return (
    <Provider
      model={model}
      keys={keys}
      authWrapper={authWrapper}
      contexts={CONTEXTS}
    >
      <div className="site">
        <fieldset id="site-main">
          <div className="site__scroll-override-container">
            <div className="row site-body">
              <AsyncRender getPromise={request} ErrorView={FeedbackSiteError}>
                {({ data }) => <Feedback assessment={data} token={token} />}
              </AsyncRender>
            </div>
          </div>
        </fieldset>
        <SiteModal />
      </div>
    </Provider>
  );
};

const FeedbackSiteError: Preact.FunctionComponent<{
  error: DisplayableError;
}> = ({ error }) =>
  error?.code === "InvalidInvitationToken" ? (
    <div className="error-view">
      <h3>
        <Icon className="error-view__icon" name="alert" iconSize="xl" />
        Something went wrong!
      </h3>
      <p>That link is invalid or has expired.</p>
    </div>
  ) : (
    <DefaultErrorView error={error} />
  );
