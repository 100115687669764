import * as Preact from "preact";
import { getCurrentUrl, route, Router } from "preact-router";

import authWrapper from "~/auth/auth";
import { Context, EVENTS, LOGIN_LOCATION } from "@thrive-web/ui-model";
import { onRouteChange } from "~/model";
import {
  ProfileBuilder,
  Home,
  Groups,
  GroupDetailPage,
  People,
  Events,
  Login,
  Logout,
  Communities,
  CommunityDetailPage,
  NotificationPage,
  UserProfilePage,
  EventDetailPage,
  GroupInviteJoin,
  Welcome,
  Guide,
} from "~/view/components";

import {
  NotFoundPage,
  PrivacyPolicy,
  TermsOfUse,
  Redirect,
  PostDetailPage,
} from "@thrive-web/ui-components";
import { get_url_for_entity, setPageAttr } from "@thrive-web/ui-utils";

export class RoutedApp extends Preact.Component<Context<"auth", true>, {}> {
  skip_first_change: boolean = true;

  componentDidMount(): void {
    this.checkAuthRoute();
    setPageAttr(getCurrentUrl());
  }

  componentDidUpdate(previousProps: Readonly<Context<"auth", true>>) {
    this.checkAuthRoute(previousProps);
    setPageAttr(getCurrentUrl());
  }

  // check if the auth ctx is forcing a route or if the forced route has changed
  checkAuthRoute = (prevProps?: Readonly<Context<"auth", true>>) => {
    const cur_url = getCurrentUrl();
    if (
      !this.props.auth ||
      !this.props.auth.route ||
      this.props.auth.route === cur_url ||
      (prevProps &&
        prevProps.auth &&
        prevProps.auth.route === this.props.auth.route)
    ) {
      return;
    }
    const { route: new_route } = this.props.auth;
    this.props
      .dispatch({
        type: EVENTS.UPDATE_AUTH_CTX,
        payload: { ...this.props.auth, route: undefined },
      })
      .then(() => route(new_route, ["/login", "/register"].includes(cur_url)));
  };

  onRouteChange = e => {
    const { skip_first_change } = this;
    this.skip_first_change = false;
    const { auth } = this.props;
    // if navigated to logout, invoke logout call and return to login page
    if (e.url === "/logout") {
      if (auth && (auth.user || auth.firebaseUser)) {
        authWrapper.logout().then(() => {
          route(LOGIN_LOCATION, true);
          window.location.reload();
        });
      }
    } else {
      const cur_url = getCurrentUrl();
      setPageAttr(cur_url);
      if (skip_first_change && e.url === cur_url) {
        return;
      }
      // check if the new route is allowed
      onRouteChange(e.url).then(new_route => {
        this.props.dispatch({
          type: EVENTS.UPDATE_LOCATION,
          payload: new_route || e.url,
        });
        // if not, go to route specified by the auth ctx
        if (new_route && new_route !== e.url) {
          route(new_route, ["/login", "/register"].includes(cur_url));
        }
      });
    }
  };

  render() {
    const { auth } = this.props;
    return (
      <Router onChange={this.onRouteChange}>
        {auth && auth.userProfileBuilderComplete && (
          <Redirect path="/profile-builder" to="/" />
        )}
        <ProfileBuilder
          path="/profile-builder/:step?"
          default={
            !!auth &&
            !!auth.firebaseUser &&
            !!auth.user &&
            !auth.userProfileBuilderComplete
          }
        />
        {/*
        <CoachingGroup
          path="/coaching-group-builder"
          default={
            !!auth &&
            !!auth.firebaseUser &&
            !!auth.user &&
            auth.userProfileBuilderComplete &&
            !auth.hasGroups
          }
        />*/}
        {auth &&
          auth.firebaseUser &&
          auth.userProfileBuilderComplete &&
          auth.hasGroups && <Home path="/:query?" />}
        {auth &&
          auth.firebaseUser &&
          auth.userProfileBuilderComplete &&
          !auth.hasGroups && <Welcome path="/welcome" default={true} />}
        <Home path="/post/:post_id" />
        <Communities path="/community" />
        <Redirect path="/community/:id" to="/community/:id/activity" />
        <CommunityDetailPage path="/community/:id/:tab/:item?/:action?" />
        <Groups path="/groups" />
        <Groups path="/groups/create" />
        <Groups path="/groups/discover" />
        <Groups path="/groups/manage" />
        <Groups path="/groups/search" />
        <Redirect path="/groups/:id" to="/groups/:id/activity" />
        <GroupDetailPage path="/groups/:id/:tab/:item?/:action?" />
        <GroupInviteJoin path="/group-invitation" />
        <PostDetailPage path="/post-detail/:post_id" />
        <People path="/people" />
        <People path="/people/add" />
        <People path="/people/requests" />
        <UserProfilePage path="/people/:id/:tab?/:subtab?/:action?" />
        {auth?.user?.id && (
          <Redirect path="/profile" to={get_url_for_entity(auth.user)} />
        )}
        <Events path="/events" />
        <Events path="/events/my-events" />
        <EventDetailPage path="/events/:id" />
        <Guide path="/guide" />
        <NotificationPage path="/notifications" />
        <Login path="/login" default={!auth || !auth.firebaseUser} />
        <Logout path="/logout" />
        <Redirect path="/register" to="/" />
        <Redirect path="/email-verified" to="/profile-builder" />
        <PrivacyPolicy path="/privacy-policy" />
        <TermsOfUse path="terms-of-use" />
        <NotFoundPage default={true} />
      </Router>
    );
  }
}
