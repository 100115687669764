import * as Preact from "preact";
import { useContext } from "preact/hooks";
import { Card, GROUP_GROUP } from "@thrive-web/ui-components";
import { asSubroute } from "../asSubroute";

const GroupDetailAboutBase: Preact.FunctionComponent<RoutePageProps> = () => {
  const group = useContext(GROUP_GROUP);

  if (!group) {
    return null;
  }

  return (
    <div className="page-tab group-about-page">
      <Card>
        <h4>Purpose</h4>
        <p>{group.purpose}</p>
      </Card>
    </div>
  );
};

export const GroupDetailAbout = asSubroute(GroupDetailAboutBase);
