import * as Preact from "preact";
import {
  DefaultPendingView,
  InputWithFormHelpers,
  OptionsList,
  Popover,
} from "@thrive-web/ui-components";
import {
  useChildRef,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  chain_event_listeners,
  class_names,
  maybeClassName,
} from "@thrive-web/ui-utils";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { pick } from "@thrive-web/ui-common";

interface InputWithDropdownAsyncPropsBase<T extends any = any> {
  options: readonly T[];
  onChange: (option?: T) => void;
  value?: T;
  onChangeText: (text: string) => void;
  pending?: boolean;
  // render each option in the dropdown list
  renderOption?: (option: T) => Preact.VNode | null;
  // render the selected option in the visual input field
  renderSelected?: (option: T, clear: () => void) => Preact.VNode | null;
  // get the visual label text for the option
  getTextFromSelected: (option: T) => string;
  // allowBlank?: boolean;
  // allowCustom?: boolean;
  // allowMultiple?: boolean;
  // stayOpenAfterSelect?: boolean;
}
/*
interface InputWithDropdownPropsSingle<T extends string | number>
  extends InputWithDropdownPropsBase<T> {
  allowMultiple?: false;
}
interface InputWithDropdownPropsMultiple<T extends string | number>
  extends InputWithDropdownPropsBase<T> {
  allowMultiple: true;
  selected: InputOptions<T>;
}*/

type InputWithDropdownAsyncProps<T extends any = any> = Omit<
  HTMLInputProps,
  "onChange" | "value"
> &
  InputWithDropdownAsyncPropsBase<T>;
// | InputWithDropdownPropsSingle<T>
// | InputWithDropdownPropsMultiple<T>;

export const InputWithDropdownAsync = <T extends any>({
  options,
  onChange,
  value,
  renderOption,
  renderSelected,
  getTextFromSelected,
  onChangeText,
  pending,
  defaultValue,
  className,
  children,
  ...props
}: Preact.RenderableProps<
  InputWithDropdownAsyncProps<T>
>): Preact.VNode | null => {
  const [input_ref, get_input_ref] = useChildRef<HTMLInputElement>();
  const input_comp_ref = useRef<InputWithFormHelpers<string>>();
  const [text, setText] = useStateIfMounted("");
  const [open, setOpen] = useStateIfMounted(false);
  const onSelectOption = useCallback(
    (option: T) => {
      setText(getTextFromSelected(option));
      onChangeText("");
      onChange(option);
    },
    [onChange]
  );
  const onChangeInput = useCallback(
    e => {
      setText(e.target.value);
      onChangeText(e.target.value);
    },
    [setText]
  );
  // clear both the stored value and the text input
  const clearValue = useCallback(() => {
    onChange();
    setText("");
    input_ref.current && input_ref.current.focus();
  }, [onChange, setText, input_ref]);

  useEffect(() => {
    const new_text = value ? getTextFromSelected(value) : "";
    setText(new_text);
    onChangeText(new_text);
  }, [value, getTextFromSelected]);

  const [blurred, set_blurred] = useStateIfMounted(false);
  const [inputProps, setInputProps] = useStateIfMounted({});

  const validate = useCallback(
    (_, input?: HTMLInputElement | null) => {
      const valid = !blurred || !!value;
      if (input) {
        input.setCustomValidity(!valid ? "This field is required" : "");
      }
      return valid;
    },
    [blurred, value]
  );

  // clear input on "Backspace"
  const onBlur = useCallback(() => {
    if (input_comp_ref.current?.dirty) {
      set_blurred(true);
    }
  }, [set_blurred]);

  // clear input on "Backspace"
  const onKeyDown = useCallback(
    e => {
      if (e.key === "Backspace" && value) {
        e.preventDefault();
        clearValue();
      }
    },
    [clearValue, value]
  );

  // combine event listeners from props with those needed internally
  const listeners = useMemo(() => {
    const conflicts = pick(Object.keys(inputProps), props);
    return chain_event_listeners<HTMLInputElement>(
      { onKeyDown, onBlur },
      inputProps,
      conflicts
    );
  }, [inputProps, props]);

  const RenderItem = useRenderPropsFunction<OptionsListItemProps<T>>(
    ({ item, onSelect }) => (
      <div className="dropdown-input__item" onClick={() => onSelect(item)}>
        {renderOption ? (
          renderOption(item)
        ) : (
          <div className="dropdown-input__item--default">
            {getTextFromSelected(item)}
          </div>
        )}
      </div>
    ),

    "InputWithDropdown-ListItem",
    []
  );

  return (
    <Popover
      className={`dropdown-input ${class_names(
        { "--pending": pending },
        "dropdown-input__async"
      )}${maybeClassName(className)}`}
      triggerComponent={
        <div className="dropdown-input__input__container input__container">
          <InputWithFormHelpers
            ref={input_comp_ref}
            validate={props.required ? validate : undefined}
            className="dropdown-input__input"
            {...props}
            {...listeners}
            value={text}
            onChange={onChangeInput}
            readOnly={!!value && !!renderSelected}
            autocomplete="off"
            inputRef={get_input_ref}
            controlled={true}
          />
          {children}
          {value && renderSelected && (
            <div className="dropdown-input__input__value">
              {renderSelected(value, clearValue)}
            </div>
          )}
          <DefaultPendingView />
        </div>
      }
      show={open && !value}
      defaultDirection="bottom"
    >
      <OptionsList
        options={options}
        RenderItem={RenderItem}
        onSelect={onSelectOption}
        setControlListeners={setInputProps}
        open={open}
        setOpen={setOpen}
        emptyLabel={text ? `No options matched "${text}"` : "No options"}
      />
    </Popover>
  );
};
