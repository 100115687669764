import { useMedia } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { media_url } from "@thrive-web/core";
import { Community } from "@thrive-web/ui-api";
import { CONTEXTS } from "@thrive-web/ui-model";
import { display_text, get_url_for_entity } from "@thrive-web/ui-utils";
import {
  DynamicListProvider,
  PageBody,
  PageContent,
  PageHeader,
  TabLinks,
  COMMUNITY_EVENTS,
  COMMUNITY_PAST_EVENTS,
  COMMUNITY_POSTS,
} from "@thrive-web/ui-components";
import Match from "preact-router/match";

const createCommunityTabLinks = (community: Community): NavLinkSpec[] => {
  if (!community || !community.id) {
    return [];
  }
  const url = get_url_for_entity(community);
  return [
    {
      href: `${url}/activity`,
      icon: "activity",
      text: "Activity",
      activePathMatch: `${url}/activity/:post?/:whatever?`,
    },
    {
      href: `${url}/upcoming-events`,
      icon: "calendar",
      text: "Upcoming Events",
      activePathMatch: `${url}/upcoming-events/:post?/:whatever?`,
    },
    {
      href: `${url}/past-events`,
      icon: "calendar",
      text: "Past Events",
      activePathMatch: `${url}/past-events/:post?/:whatever?`,
    },
    {
      href: `${url}/about`,
      icon: "info-outline-circle",
      text: "About",
      activePathMatch: `${url}/about`,
    },
  ];
};

export const CommunityDetailHeader: Preact.FunctionComponent<{
  show_banner: boolean;
  community: Community;
}> = ({ community }) => {
  const tab_links = useMemo(
    () => createCommunityTabLinks(community),
    [community]
  );
  const window_size = useContext(CONTEXTS.window_size);
  const cover_url = useMedia<"Community", "cover_image">(
    community,
    "cover_image",
    1,
    window_size
  );
  const avatar_url = useMemo(
    () =>
      media_url<"Community", "avatar_image">(
        community,
        "avatar_image",
        "small"
      ),
    [community]
  );
  return (
    <PageHeader className="banner-header">
      <div
        className="community-page__banner page-header__banner"
        style={{
          backgroundImage: `url(${cover_url})`,
        }}
      >
        <div className="page-header__title">
          <img className="avatar community-page__avatar" src={avatar_url} />
          <span>{display_text(community.name)}</span>
        </div>
        <div className="page-header__subtitle">
          {display_text(community.purpose)}
        </div>
      </div>
      <TabLinks anchor={true} links={tab_links} />
    </PageHeader>
  );
};

// renders the header and mounts the community-detail-related ctx providers
export const CommunityDetailOuter: Preact.FunctionComponent<{
  community: Community;
}> = ({ community, children }) => {
  const url = useMemo(() => get_url_for_entity(community), [community]);
  return (
    <PageContent
      id="community-detail-page"
      className="community-page detail-page"
    >
      <DynamicListProvider context={COMMUNITY_EVENTS}>
        <DynamicListProvider context={COMMUNITY_PAST_EVENTS}>
          <DynamicListProvider context={COMMUNITY_POSTS}>
            <Match path={`${url}/activity/:post?/:whatever?`}>
              {match_props => (
                <CommunityDetailHeader
                  show_banner={match_props.matches}
                  community={community}
                />
              )}
            </Match>
            <PageBody>{children}</PageBody>
          </DynamicListProvider>
        </DynamicListProvider>
      </DynamicListProvider>
    </PageContent>
  );
};
