import * as Preact from "preact";
import { Todo } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  Icon,
  Tooltip,
  GROUP_DETAIL_LIST_CONTEXTS,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import { useApiCall, useDelayedEvent } from "@thrive-web/ui-hooks";
import { useCallback, useContext } from "preact/hooks";
import { analytics, make_displayable_error } from "@thrive-web/ui-common";

const popover_props = {
  portalClassName: "todo-list__item__toggle--tooltip",
};

// async "checkbox" toggle for marking todos as complete
export const GroupTodoCompleteToggle: Preact.FunctionComponent<{
  todo: Todo;
}> = ({ todo }) => {
  const updateTodos = useContext(GROUP_DETAIL_LIST_CONTEXTS.todos).dispatch;
  const overdue = Date.now() > new Date(todo.due_date || "").getTime();
  // delay event logging so they can cancel it if it was accidental
  const [log_event, cancel_event] = useDelayedEvent(() =>
    analytics.log_event(analytics.EVENTS.to_do_completed)
  );

  const [setComplete, { pending, error }] = useApiCall("updateTodo");
  const toggleComplete = useCallback(() => {
    if (!todo.completed) {
      cancel_event();
    }
    return setComplete(todo.id, {
      body: { data: { attributes: { completed: !todo.completed } } },
      query: {
        include: ["goal", "assigned_to.User:profile_picture"],
        fields: { User: DEFAULT_USER_FIELDS },
      },
    }).then(res => {
      if (res.data.completed) {
        log_event();
      }
      updateTodos.update(t => t.id === todo.id, res.data);
    });
  }, [setComplete, todo.completed]);

  return (
    <Preact.Fragment>
      <div
        className="todo-list__item__toggle"
        data-overdue={overdue}
        data-completed={!!todo.completed}
        data-pending={`${pending}`}
      >
        <Tooltip
          defaultDirection="top"
          text={`Mark as ${todo.completed ? "Incomplete" : "Completed"}`}
          delay={750}
          popoverProps={popover_props}
        >
          <ButtonWithIcon
            className="non-button"
            icon={
              !pending && todo.completed
                ? "checked-solid-circle"
                : "broken-circle"
            }
            onClick={toggleComplete}
          />
        </Tooltip>
      </div>
      {error && (
        <div className="todo-list__item__toggle__error">
          <Tooltip
            defaultDirection="left"
            text={make_displayable_error(error).message}
          >
            <Icon name="alert" iconSize="sm" />
          </Tooltip>
        </div>
      )}
    </Preact.Fragment>
  );
};
