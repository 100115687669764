import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { Mood } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DivWithIcon,
  WithTaxonomy,
} from "@thrive-web/ui-components";
import { display_text, maybeClassName } from "@thrive-web/ui-utils";

type MoodElemPropsBase = { mood: Mood; size: IconSize } & MaybeClass &
  Omit<WithIconProps, "icon" | "collapse">;
type MoodElemProps<P> = Omit<P, keyof MoodElemPropsBase> & MoodElemPropsBase;

// show loading component if mood taxonomy hasn't been fetched yet
const wrapMoodElem =
  <P extends any>(
    Component: Preact.ComponentType<MoodElemProps<P>>,
    props: MoodElemProps<P>
  ) =>
  (value?: Mood, loading?: boolean): Preact.VNode | null =>
    loading ? (
      <MoodLoading
        size={props.size}
        side={props.side}
        className={props.className}
      />
    ) : (
      <Component {...props} mood={value || props.mood} />
    );

const MoodDivBase: Preact.FunctionComponent<MoodElemProps<HTMLDivProps>> = ({
  mood,
  className,
  size,
  side = "left",
  ...props
}) => (
  <DivWithIcon
    className={`mood${maybeClassName(className)}`}
    {...props}
    side={side}
    data-size={size}
    icon={<span className="mood__emoji">{display_text(mood.symbol)}</span>}
  >
    Feeling <strong>{mood.title}</strong>
  </DivWithIcon>
);

export const MoodDiv: Preact.FunctionComponent<MoodElemProps<HTMLDivProps>> = ({
  mood,
  ...props
}) => {
  const children = useMemo(() => {
    return wrapMoodElem(MoodDivBase, { ...props, mood });
  }, Object.values(props));
  return (
    <WithTaxonomy type="Mood" value={mood}>
      {children}
    </WithTaxonomy>
  );
};

const MoodButtonBase: Preact.FunctionComponent<MoodElemProps<HTMLButtonProps>> =
  ({ mood, className, size, ...props }) => (
    <ButtonWithIcon
      className={`mood${maybeClassName(className)}`}
      {...props}
      data-size={size}
      icon={<span className="mood__emoji">{display_text(mood.symbol)}</span>}
    >
      Feeling <strong>{mood.title}</strong>
    </ButtonWithIcon>
  );

export const MoodButon: Preact.FunctionComponent<
  MoodElemProps<HTMLButtonProps>
> = ({ mood, ...props }) => {
  const children = useMemo(
    () => wrapMoodElem(MoodButtonBase, { ...props, mood }),
    Object.values(props)
  );
  return (
    <WithTaxonomy type="Mood" value={mood}>
      {children}
    </WithTaxonomy>
  );
};

export const MoodLoading: Preact.FunctionComponent<
  { size: IconSize; side?: "right" | "left" } & MaybeClass
> = ({ size, className }) => (
  <DivWithIcon
    data-size={size}
    className={`mood__loading loading-item${maybeClassName(className)}`}
    icon="unreads"
    side="left"
  >
    <div className="loading-item__shaded loading-item__text" />
  </DivWithIcon>
);
