import * as Preact from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import {
  Event,
  MappedApiResponse,
  WriteAddress,
  WriteEvent,
} from "@thrive-web/ui-api";
import { RSVPResponses } from "@thrive-web/ui-constants";
import { analytics, moment } from "@thrive-web/ui-common";
import { LocationInput } from "~/view/components";
import {
  Checkbox,
  DatePicker,
  DatePickerProps,
  DefaultFileUploadButton,
  ErrorMessage,
  FileUpload,
  FloatingTitle,
  InputWithFormHelpers,
  PillRadioList,
  TextAreaWithFormHelpers,
  TimeInput,
  WithFloatingTitle,
  RSVP_OPTIONS,
} from "@thrive-web/ui-components";
import {
  useCallbackRef,
  useDirtyFormWithDiff,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { compute_valid_range, maybeClassName } from "@thrive-web/ui-utils";

export interface EventFormProps {
  initialData?: WriteEvent;
  onChangeFile: (new_file?: FileUploadData) => void;
  onSubmit: (
    new_event: Event,
    rsvp: RSVPResponses
  ) => Promise<MappedApiResponse<"createPost" | "updatePost">>;
  onFinish: (new_event: Event) => void;
  photoFile?: FileUploadData;
  error?: DisplayableError;
  rsvp?: RSVPResponses;
}

export const CoverImageUploadButton: Preact.FunctionComponent<FileUploadButtonProps> =
  (props: FileUploadButtonProps) => (
    <DefaultFileUploadButton
      {...props}
      className={`filled gray${maybeClassName(props.className)}`}
    >
      Choose an Image
    </DefaultFileUploadButton>
  );

export const EventForm: Preact.FunctionComponent<EventFormProps> = ({
  initialData,
  onChangeFile,
  photoFile,
  onSubmit,
  error,
  onFinish,
  children,
  rsvp: initial_rsvp,
}) => {
  // track whether the location is different from the initial value
  const location_edited = useRef(false);

  const prepare_for_diffing = useCallback(
    (data: WriteEvent) =>
      ({
        ...data,
        cover_image: photoFile,
      } as WriteEvent),
    [photoFile]
  );

  const [
    form_data,
    updated_form_data,
    deleted_form_data_keys,
    set_field,
    on_input_change,
    ,
    reset_form,
  ] = useDirtyFormWithDiff<WriteEvent>(
    initialData,
    "EventForm",
    prepare_for_diffing,
    true
  );

  // detect first time setting these props, so we can log firebase events
  const location_changed = useRef(false);
  const photo_changed = useRef(false);

  const onChangeCheckbox = (field: keyof WriteEvent) => e =>
    set_field(field, e.target.checked);

  const onChangeLocation = useCallback(
    (value: WriteAddress) => {
      if (!!value && !location_changed.current) {
        location_changed.current = true;
        analytics.log_event(analytics.EVENTS.event.location_added);
      }
      // @ts-expect-error:
      set_field("location", value);
      set_field(
        "location_name",
        value?.common_name || value?.formatted_address
      );
      location_edited.current =
        // existence of value changed OR
        !!value !== !!initialData?.location ||
        // old and new values existed AND
        (!!value &&
          !!initialData?.location &&
          // formatted address string changed OR common name string changed
          // @ts-expect-error:
          (value.formatted_address !== initialData.location.formatted_address ||
            // @ts-expect-error:
            value.common_name !== initialData.location.common_name));
    },
    [!!initialData?.location]
  );

  // reset to initial value
  const on_reset_location = useCallback(() => {
    set_field("location", initialData?.location);
    set_field("location_name", initialData?.location_name);
    location_edited.current = false;
  }, [initialData, set_field]);

  // set initial rsvp
  const [rsvp, set_rsvp, rsvp_ref] = useStateRef(initial_rsvp);
  const on_change_rsvp = useCallback(
    (opt: InputOption<RSVPResponses>) => {
      set_rsvp(opt.value);
    },
    [set_rsvp]
  );

  const onChangeFile_ = useCallback(
    (data: FileUploadData | undefined) => {
      if (data && !photo_changed.current) {
        photo_changed.current = true;
        analytics.log_event(analytics.EVENTS.event.photo_added);
      }
      onChangeFile(data);
      set_field(
        "cover_image",
        // @ts-expect-error:
        !!initialData?.cover_image && !data ? null : undefined
      );
    },
    [onChangeFile]
  );

  // const on_change_datetime = useCallback(())
  const [initial_date, initial_time] = useMemo(() => {
    if (!initialData?.date_time) {
      return [undefined, undefined];
    }
    return moment(initialData.date_time).format("YYYY-MM-DD HH:mm").split(" ");
  }, []);

  const [date, set_date, date_ref] = useStateRef(initial_date);
  const [time, set_time, time_ref] = useStateRef(initial_time);

  const { date_range, time_range } = useMemo(
    () => compute_valid_range(time, date, new Date().toISOString()),
    [time, date]
  );

  const date_picker_props = useMemo<Omit<DatePickerProps, "children">>(
    () => ({
      onChange: (val: Date) => {
        // get date string in format YYYY-MM-DD
        const new_date = `${val.getFullYear()}-${`${
          val.getMonth() + 1
        }`.padStart(2, "0")}-${`${val.getDate()}`.padStart(2, "0")}`;

        set_date(new_date);
        // if time is also set, update the overall date_time value
        if (val && time_ref.current) {
          set_field(
            "date_time",
            moment(`${new_date}T${time_ref.current}`).toISOString()
          );
        } else if (!val && !time_ref.current) {
          set_field("date_time", undefined);
        }
      },
      popoverTriggerOptions: {
        click: true,
        focus: true,
        hover: false,
      },
      value: date ? new Date(`${date}T12:00:00`) : undefined,
      popoverProps: {
        defaultDirection: "top",
      },
    }),
    [set_field, date, set_date]
  );

  const time_picker_props = useMemo(() => {
    const onChangeValue = (val?: string) => {
      set_time(val);
      // if date is also set, update the overall date_time value
      if (val && date_ref.current) {
        set_field(
          "date_time",
          moment(`${date_ref.current} ${val}`).toISOString()
        );
      } else if (!val && !date_ref.current) {
        set_field("date_time", undefined);
      }
    };
    return {
      onChangeValue,
      onChange: e => onChangeValue(e.target.value),
    };
  }, [set_time, set_field]);

  const has_required_fields =
    !!form_data.name && !!form_data.date_time && !!date && !!time;

  const on_success = useCallbackRef(
    (new_event: Event) => {
      reset_form(new_event);
      onFinish(new_event);
    },
    [reset_form, onFinish]
  );

  const on_submit = useCallback(
    e => {
      e.preventDefault();
      if (!e.target.checkValidity() || !has_required_fields) {
        e.target.reportValidity();
        return;
      }

      const data: any = {
        ...(!initialData?.id ? { is_public: false } : {}),
        ...updated_form_data,
        cover_image: undefined,
        id: "",
      };
      // exclude location if it wasn't changed
      if (
        !location_edited.current &&
        !!initialData?.location === !!form_data.location
      ) {
        delete data.location;
      }

      deleted_form_data_keys?.forEach(k => {
        data[k] = null;
      });

      onSubmit(data, rsvp_ref.current!).then(({ data: new_event }) => {
        on_success.current &&
          setTimeout(() => {
            on_success.current?.(new_event);
          }, 1000);
      });
    },
    [onSubmit, updated_form_data, deleted_form_data_keys, has_required_fields]
  );

  return (
    <form className="event__form" onSubmit={on_submit}>
      <div className="event__form__body">
        <div className="form__input-row">
          <WithFloatingTitle title="Event Name">
            <InputWithFormHelpers
              name="name"
              placeholder="Event Name"
              value={form_data?.name}
              onChange={on_input_change("name")}
              required={true}
            />
          </WithFloatingTitle>
        </div>
        <div className="form__input-row">
          <TextAreaWithFormHelpers
            name="description"
            placeholder="Tell everyone a little more about your event!"
            // @ts-ignore
            value={form_data?.description}
            rows={10}
            onChange={on_input_change("description")}
            controlled={false}
          >
            <FloatingTitle name="purpose">Description</FloatingTitle>
          </TextAreaWithFormHelpers>
        </div>
        {!initialData?.id && (
          <div className="form__input-row event__form__public">
            <Checkbox
              name="is_public"
              checked={form_data?.is_public || false}
              onChange={onChangeCheckbox("is_public")}
              label="This is a public event."
              className="event__form__public"
            />
            <div className="input__description">
              Select this if you want others to be able to share this event.
            </div>
          </div>
        )}
        <div className="form__section-header">
          <h3>Banner Image</h3>
        </div>
        <div className="form__input-row">
          <FileUpload
            className="event__form__image-upload"
            value={photoFile}
            Button={CoverImageUploadButton}
            onChange={onChangeFile_}
            accept="image/*"
          />
        </div>
        {error && (
          <div className="form__input-row">
            <ErrorMessage>{error.message}</ErrorMessage>
          </div>
        )}
        <div className="form__section-header">
          <h3>Date and Time</h3>
        </div>
        <div className="form__input-row event__form__date-time">
          <DatePicker {...date_picker_props} {...date_range}>
            {trigger_props => (
              <WithFloatingTitle title="Date">
                <InputWithFormHelpers
                  {...trigger_props}
                  onChange={() => {}}
                  value={trigger_props.value?.toLocaleDateString()}
                  placeholder="Date"
                  required={true}
                  readOnly={true}
                  controlled={true}
                />
              </WithFloatingTitle>
            )}
          </DatePicker>

          <WithFloatingTitle title="Time">
            <TimeInput
              {...time_picker_props}
              {...time_range}
              required={true}
              value={time}
            />
          </WithFloatingTitle>
        </div>
        <div className="form__section-header">
          <h3>Location</h3>
        </div>
        <div className="form__input-row event__form__location">
          <WithFloatingTitle title="Location">
            <LocationInput
              onChange={onChangeLocation}
              value={form_data?.location}
              onReset={
                !!initialData?.location && location_edited.current
                  ? on_reset_location
                  : undefined
              }
            />
          </WithFloatingTitle>
        </div>
        {!initialData?.id && (
          <Preact.Fragment>
            <div className="form__section-header">
              <h3>Your RSVP</h3>
            </div>
            <div className="form__input-row event__form__rsvp">
              <PillRadioList
                label=""
                name="rsvp"
                onSelectOption={on_change_rsvp}
                options={RSVP_OPTIONS}
                value={rsvp}
              />
            </div>
          </Preact.Fragment>
        )}
      </div>
      {children}
    </form>
  );
};
