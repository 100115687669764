import * as Preact from "preact";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  useDocumentTitle,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import authWrapper from "~/auth/auth";
import { useCallback } from "preact/hooks";
import {
  ErrorMessage,
  InputWithFormHelpers,
  LinkWithIcon,
  RequestButton,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import { is_valid_email } from "@thrive-web/ui-common";

export const ForgotPassword: Preact.FunctionComponent = () => {
  useDocumentTitle(() => make_title(["Forgot Password"]), []);
  const [email, set_email] = useStateIfMounted("");

  const [sendRequest, { pending, success, error }] = useRequest(
    authWrapper.forgotPassword
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      sendRequest(email);
    },
    [email, sendRequest]
  );

  return (
    <div className="site">
      <fieldset id="site-main">
        <div className="site__scroll-override-container">
          <div className="row site-body">
            <div className="login-page forgot-password">
              <div className="login-page__logo">
                <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
              </div>
              <div className="login-page__form login-page__forgot card">
                <div className="form__section register__form__section">
                  <div className="register__form__back">
                    <LinkWithIcon
                      className="plain-link"
                      icon="chevron-left"
                      side="left"
                      href="/login"
                    >
                      Sign In
                    </LinkWithIcon>
                    <div className="form__section-header login-page__form__title">
                      Reset Password
                    </div>
                    <div className="info">
                      Enter your email address below and we will send you a link
                      to reset your password.
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="form__input-row">
                        <WithFloatingTitle title="Email Address">
                          <InputWithFormHelpers
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={e => set_email(e.target.value)}
                            autoFocus={true}
                            value={email}
                            submitOnEnter={true}
                            validate={is_valid_email}
                            disabled={success}
                          />
                        </WithFloatingTitle>
                      </div>
                      <div className="form__footer">
                        <RequestButton
                          pending={pending}
                          success={success}
                          successText="Email Sent!"
                          className="filled full-width"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Get Reset Email
                        </RequestButton>
                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};
