import * as Preact from "preact";
import { useOverflowMenu } from "@thrive-web/ui-hooks";
import { extract_route_matches, maybeClassName } from "@thrive-web/ui-utils";
import {
  AppLink,
  DivWithIcon,
  DropdownMenu,
  Icon,
} from "@thrive-web/ui-components";
import { useContext, useMemo, useRef } from "preact/hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import { ScreenSize } from "@thrive-web/ui-constants";
import { getCurrentUrl } from "preact-router";

type TabLinksProps = {
  links: NavLinkSpec[];
  // if true, scroll to the top of the container when clicked
  anchor?: boolean;
  // links that will always reside in the overflow dropdown
  extras?: (Preact.VNode | null)[];
} & MaybeClass;

const popover_props = {
  forceDirection: "bottom",
} as const;

// page navigation in the style of tabs, with dropdown menu for overflowed tabs
export const TabLinksLg: Preact.FunctionComponent<TabLinksProps> = ({
  links,
  className,
  anchor,
  extras = [],
}) => {
  const [listRef, container, menu, num_visible = links.length, overflowed] =
    useOverflowMenu<NavLinkSpec>(links, true);

  return (
    <div
      ref={container}
      className={`tab-links overflow${maybeClassName(className)}`}
    >
      <ul className="tab-links__list overflow__list" ref={listRef}>
        {links.map(({ icon, text, ...props }, i) => {
          const visible = i < num_visible;
          return (
            <li
              key={props.href}
              className="tab-links__list__item"
              data-visible={`${visible}`}
            >
              <AppLink
                key={i}
                {...props}
                disabled={!visible}
                aria-hidden={!visible}
                class="tab-links__link with-icon"
                activeClassName="active"
                tabIndex={!visible ? -1 : undefined}
                onClick={
                  anchor && visible
                    ? () => {
                        if (container.current) {
                          window.scrollTo(0, container.current.offsetTop);
                        }
                      }
                    : undefined
                }
              >
                <div className="with-icon__icon">
                  <Icon name={icon} />
                </div>
                <span data-text={typeof text === "string" ? text : undefined}>
                  {text}
                </span>
              </AppLink>
            </li>
          );
        })}
      </ul>
      {overflowed.length + extras.length > 0 && (
        <div ref={menu} className="tab-links__overflow">
          <DropdownMenu
            items={overflowed
              .map(({ icon, text, ...props }, i) => (
                <AppLink
                  key={i}
                  {...props}
                  class="with-icon pill filled gray"
                  activeClassName="active"
                  onClick={
                    anchor
                      ? () => {
                          if (container.current) {
                            window.scrollTo(0, container.current.offsetTop);
                          }
                        }
                      : undefined
                  }
                >
                  <Icon className="with-icon__icon" name={icon} />
                  <span data-text={text}>{text}</span>
                </AppLink>
              ))
              // @ts-expect-error:
              .concat(extras.filter(i => !!i))}
            buttonClassName="filled gray all-gray"
            listClassName="card pill-card"
            allowLinkEventPropagation={true}
            closeOnClickItem={true}
            popoverProps={popover_props}
          />
        </div>
      )}
    </div>
  );
};

// dropdown version for small screens
export const TabLinksSm: Preact.FunctionComponent<TabLinksProps> = ({
  links,
  className,
  anchor,
  extras = [],
}) => {
  const container = useRef<HTMLDivElement>();
  const url = getCurrentUrl();
  const [active, items] = useMemo(() => {
    let current: NavLinkSpec | null = null;
    const all_items = links
      .map(spec => {
        const { icon, text, href, activePathMatch } = spec;
        const does_match = extract_route_matches(
          activePathMatch || href || "",
          url,
          href
        );
        if (does_match && does_match.exact) {
          current = spec;
          return null;
        }
        return (
          <AppLink
            key={href}
            activePathMatch={activePathMatch}
            class="with-icon pill filled gray"
            activeClassName="active"
            href={href}
            onClick={
              anchor
                ? () => {
                    if (container.current) {
                      window.scrollTo(0, container.current.offsetTop);
                    }
                  }
                : undefined
            }
          >
            <Icon className="with-icon__icon" name={icon} />
            <span data-text={text}>{text}</span>
          </AppLink>
        );
      })
      .filter(i => !!i) as Preact.VNode[];
    if (!current) {
      current = links[0];
      all_items.shift();
    }
    return [
      current,
      all_items.concat(extras.filter(i => !!i) as Preact.VNode[]),
    ] as const;
  }, [links, anchor, url]);

  const popover_props_ = useMemo(
    () => ({ ...popover_props, portalClassName: "tab-links--sm--popover" }),
    [popover_props]
  );

  return (
    <div
      ref={container}
      className={`tab-links tab-links--sm overflow${maybeClassName(className)}`}
    >
      {items.length > 0 ? (
        <DropdownMenu
          items={items}
          button={
            <div className="tab-links--sm__button">
              <DivWithIcon icon={active.icon} side="left">
                <span data-text={active.text}>{active.text}</span>
              </DivWithIcon>
              <Icon className="with-icon__icon" name="disclosure" />
            </div>
          }
          listClassName="card pill-card"
          allowLinkEventPropagation={true}
          closeOnClickItem={true}
          popoverProps={popover_props_}
        />
      ) : null}
    </div>
  );
};

export const TabLinks: Preact.FunctionComponent<TabLinksProps> = props => {
  const window_size = useContext(CONTEXTS.window_size);
  return window_size <= ScreenSize.xs ? (
    <TabLinksSm {...props} />
  ) : (
    <TabLinksLg {...props} />
  );
};
