import * as Preact from "preact";
import api from "~/api";
import { WriteUser } from "@thrive-web/ui-api";
import {
  useApiFetch,
  useForm,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { useCallback } from "preact/hooks";
import {
  ErrorMessage,
  InputWithFormHelpers,
  RequestButton,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import { MIN_PASSWORD_LENGTH } from "@thrive-web/ui-constants";
import authWrapper from "~/auth/auth";

const is_valid_password = (value: string) =>
  value.length >= MIN_PASSWORD_LENGTH; //&&
// /\d/.test(value) &&
// /[A-Za-z]/.test(value);

export const RegisterPasswordForm: Preact.FunctionComponent<{
  data: WriteUser;
  backButton: Preact.VNode;
  onFinish: (data: WriteUser) => void;
  token?: string;
}> = ({ onFinish, backButton, data, token }) => {
  const [{ password, confirm_password }, , onChangeInput] = useForm<any>({});
  const [show_error, set_show_error] = useStateIfMounted(false);

  const createApiAccount_req = useApiFetch("register", data, token);
  const createApiAccount = useCallback(async () => {
    const token = await authWrapper.getToken();
    api.setAuthToken(token);
    return createApiAccount_req();
  }, [createApiAccount_req]);
  const createFbAccount = useCallback((data: WriteUser, password: string) => {
    return authWrapper.register(data.email, password, createApiAccount);
  }, []);

  const [register, { pending, success, error }] = useRequest(createFbAccount);

  const onSubmit = useCallback(
    e => {
      if (
        password &&
        is_valid_password(password) &&
        password === confirm_password
      ) {
        e.preventDefault();
        register(data, password)
          .then(res => onFinish(res.data))
          .catch(() => {});
      } else {
        if (password !== confirm_password) {
          e.preventDefault();
        }
        set_show_error(true);
      }
    },
    [onFinish, data, password, confirm_password, register, token]
  );

  const onBlur = useCallback(() => {
    if (!show_error) {
      set_show_error(true);
    }
  }, [show_error]);

  const validate = useCallback(
    value => !show_error || value === password,
    [show_error, password]
  );

  return (
    <div className="form__section register__form__section" data-part="password">
      <div className="register__form__back">{backButton}</div>
      <div className="form__section-header login-page__form__title">
        Create a Password
      </div>
      <div className="info">Use a password that you will remember.</div>
      <form onSubmit={onSubmit}>
        <div className="form__input-row">
          <WithFloatingTitle title="Password">
            <InputWithFormHelpers
              name="password"
              placeholder="Password"
              type="password"
              required={true}
              minLength={MIN_PASSWORD_LENGTH}
              onChange={onChangeInput("password")}
              autoFocus={true}
              value={password}
              submitOnEnter={true}
              controlled={true}
            />
          </WithFloatingTitle>
        </div>
        <div className="form__input-row">
          <WithFloatingTitle title="Confirm Password">
            <InputWithFormHelpers
              name="confirm_password"
              placeholder="Confirm Password"
              type="password"
              required={true}
              minLength={MIN_PASSWORD_LENGTH}
              onChange={onChangeInput("confirm_password")}
              value={confirm_password}
              submitOnEnter={true}
              controlled={true}
              onBlur={onBlur}
              validate={validate}
            />
          </WithFloatingTitle>
        </div>
        {show_error && password !== confirm_password && (
          <ErrorMessage className="info">Passwords do not match.</ErrorMessage>
        )}
        <div className="register__password-reqs">
          Passwords must be at least {MIN_PASSWORD_LENGTH} characters long
          {/* and
          contain one number and one symbol*/}
          .
        </div>
        <div className="form__footer">
          <RequestButton
            pending={pending}
            success={success}
            successText="Success!"
            className="filled full-width"
            type="submit"
            onClick={onSubmit}
          >
            Continue
          </RequestButton>
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </div>
      </form>
    </div>
  );
};
