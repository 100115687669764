import { analytics, entity_has_type } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import {
  DEFAULT_USER_FIELDS,
  DefaultPendingView,
  PostCreateBlocksResponse,
  PostForm,
  PostFormBaseProps,
  PostFormPage,
  useTouchpointAndExpenseCreate,
  WithPostScopeCtx,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useRequestChain,
  useStateIfMounted,
  useUntrackedRequestWithMedia,
} from "@thrive-web/ui-hooks";
import {
  ApiMethodCaller,
  ApiMethodParameters,
  MappedApiResponse,
  Post,
  WritePost,
} from "@thrive-web/ui-api";
import { DocBase, map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { group_role_of, group_updates_allowed } from "@thrive-web/ui-utils";

export const PostCreate: Preact.FunctionComponent<
  ModalBodyProps &
    PostFormBaseProps & {
      initialPage: PostFormPage;
      initialData?: WritePost;
    }
> = ({ allowCommunityPosts, moderatedCommunities, ...props }) => {
  const self = useAppUser();
  const thread_only =
    props.initialPage === "touchpoint" || props.initialPage === "expense";
  const [scope_type, set_scope_type] = useStateIfMounted<
    "Group" | "Community" | undefined
  >(moderatedCommunities?.length === 0 || thread_only ? "Group" : undefined);

  const [file, onChangeFile] = useStateIfMounted<FileUploadData | undefined>(
    undefined
  );
  const [expenseFile, onChangeExpenseFile] = useStateIfMounted<
    FileUploadData | undefined
  >(undefined);

  // POST type/Expense
  const createExpenseRecordRequest = useApiMethod("createExpense");

  // create Expense, then upload receipt image
  const [createExpenseRequest, expense_pending, expense_progress] =
    useUntrackedRequestWithMedia(
      "Expense",
      "receipt_photo",
      createExpenseRecordRequest,
      expenseFile,
      false,
      true
    );

  const createTouchpointAndExpense =
    useTouchpointAndExpenseCreate(createExpenseRequest);

  // POST type/Post
  const createPostRecordRequest = useApiMethod("createPost");
  const [createPostRequest, post_pending, post_progress] =
    useUntrackedRequestWithMedia(
      "Post",
      "photo",
      createPostRecordRequest,
      file,
      false,
      true
    );

  // combine result of post creation with created touchpoint/expense
  const combine_post_and_blocks = useCallback(
    (
      blocks: PostCreateBlocksResponse,
      post: MappedApiResponse<"createPost">
    ): DocBase & { data: Post } => {
      return {
        ...post,
        data: {
          ...post.data,
          ...blocks,
        },
      };
    },
    []
  );

  // get ids from created touchpoint/expense for post create request
  const get_post_params_from_blocks = useCallback(
    (
      blocks: PostCreateBlocksResponse,
      params: ApiMethodParameters<"createPost">
    ) => {
      const data = params[0];
      if (!data?.body?.data?.relationships) {
        data!.body.data.relationships = {};
      }
      data!.body.data.relationships.has_touchpoint = blocks.has_touchpoint
        ? {
            data: { id: blocks.has_touchpoint.id },
          }
        : undefined;
      data!.body.data.relationships.has_expense = blocks.has_expense
        ? {
            data: { id: blocks.has_expense.id },
          }
        : undefined;

      params[0] = data;
      return params;
    },
    []
  );

  // chain touchpoint/expense creation with post creation
  const createPostChained = useRequestChain<
    typeof createTouchpointAndExpense,
    ApiMethodCaller<"createPost">,
    DocBase & { data: Post }
  >(
    createTouchpointAndExpense,
    createPostRequest,
    combine_post_and_blocks,
    get_post_params_from_blocks
  );

  const createPost = useCallback(
    (new_post: Post) => {
      const { id, type, ...data } = map_record_to_json_resource(
        new_post,
        TYPES.Post
      );

      const params_2: ApiMethodParameters<"createPost"> = [
        {
          body: { data },
          // @ts-expect-error:
          query: {
            include: [
              "has_touchpoint",
              "has_reaction",
              "has_expense",
              "mood",
              "photo",
              "event.Event:cover_image",
              "created_by.User:profile_picture",
              "has_tagged_user.User:profile_picture",
              "has_comment.Comment:created_by.User:profile_picture",
            ],
            fields: {
              User: DEFAULT_USER_FIELDS,
            },
          },
        },
      ];

      if (
        !group_updates_allowed(new_post.posted_to) ||
        (!new_post.has_touchpoint && !new_post.has_expense)
      ) {
        return createPostRequest(...params_2).then(res => {
          analytics.log_event(
            analytics.EVENTS.post_created,
            undefined,
            undefined,
            true
          );
          if (res.data.event) {
            analytics.log_event(
              analytics.EVENTS.event_shared,
              undefined,
              undefined,
              true
            );
          }
          return res;
        });
      }

      return createPostChained([new_post], params_2).then(res => {
        analytics.log_event(
          analytics.EVENTS.post_created,
          undefined,
          undefined,
          true
        );
        if (res.data.event) {
          analytics.log_event(
            analytics.EVENTS.event_shared,
            undefined,
            undefined,
            true
          );
        }
        return res;
      });
    },
    [createPostRequest, createPostChained]
  );

  const join_group_req = useApiMethod("groupJoin");
  const join_group = useCallback((self_id: string, group_id: string) => {
    return join_group_req({
      body: {
        data: {
          attributes: {},
          relationships: {
            user: {
              data: {
                id: self_id,
              },
            },
            group: {
              data: {
                id: group_id,
              },
            },
          },
        },
      },
    });
  }, []);

  const joinGroupAndCreatePost = useCallback((new_post: Post) => {
    const { posted_to } = new_post;
    if (
      !self?.id ||
      !posted_to ||
      !entity_has_type(posted_to, "Group") ||
      posted_to.is_private ||
      !!group_role_of(self, posted_to)
    ) {
      return createPost(new_post);
    }
    // if it's a public group that we're not a member of, join the group, then create post
    return join_group(self.id, posted_to.id).then(() => createPost(new_post));
  }, []);

  const content = (
    // @ts-expect-error:
    <PostForm
      onChangeFile={onChangeFile}
      submitRequest={joinGroupAndCreatePost}
      photoFile={file}
      mediaProgress={
        post_pending
          ? post_progress
          : expense_pending
          ? expense_progress
          : undefined
      }
      expensePhotoFile={expenseFile}
      onChangeExpenseFile={onChangeExpenseFile}
      onChangeScopeType={
        allowCommunityPosts && moderatedCommunities?.length
          ? set_scope_type
          : undefined
      }
      {...props}
    />
  );

  // we don't know yet if they have communities to post to
  if (!moderatedCommunities && allowCommunityPosts) {
    return (
      <Preact.Fragment>
        <div className="modal__header" />
        <div className="modal__body">
          <DefaultPendingView />
        </div>
      </Preact.Fragment>
    );
  }

  // they can't post to communities, so don't bother with the scope ctx
  if (moderatedCommunities?.length === 0) {
    return content;
  }

  return (
    <WithPostScopeCtx type={scope_type} threadOnlyInitial={thread_only}>
      {content}
    </WithPostScopeCtx>
  );
};
