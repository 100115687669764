import { createNamedContext } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { CONTEXTS } from "@thrive-web/ui-model";
import { INITIAL_APP_STATE } from "~/app/initial-state";

// This is REALLY sketchy, but it works...
// If we put this code in @thrive-web/ui-model where CONTEXTS is initialized,
// we get an error the first time useContext(CONTEXTS.anything) is called.
// Putting the code here lets us keep the context definitions local to the
// application, while still allowing the common code to access the same
// instance of CONTEXTS.
// @ts-ignore
CONTEXTS.dispatch = Preact.createContext(async () => {});

Object.entries(INITIAL_APP_STATE).forEach(([key, value]) => {
  CONTEXTS[key] = createNamedContext(value, key);
});

export {};
