export const NAV_LINKS: NavLinkSpec[] = [
  { href: "/", text: "Home", icon: "home", activePathMatch: "/" },
  {
    href: "/community",
    text: "Community",
    icon: "groups",
    activePathMatch: "/community/:id?/:tab?/:action?",
  },
  {
    href: "/groups",
    text: "Groups",
    icon: "family",
    activePathMatch: "/groups/:id?/:tab?/:action?",
  },
  {
    href: "/people",
    text: "People",
    icon: "people",
    activePathMatch: "/people/:id?/:tab?/:action?",
  },
  {
    href: "/events",
    text: "Events",
    icon: "calendar",
    activePathMatch: "/events/:id?/:tab?/:action?",
  },
  {
    href: "/guide",
    text: "Guide",
    icon: "guide",
    activePathMatch: "/guide",
    // @ts-expect-error:
    target: "_blank",
    native: true,
  },
];
