import { AuthWrapper } from "@thrive-web/ui-auth";
import { analytics } from "@thrive-web/ui-common";
import * as config from "~/../site/env.json";
import { verifyAuthStateAndRoute } from "~/model";

class AuthWrapperMain extends AuthWrapper {
  protected set_firebase_user = (r: firebase.User | null) => {
    this.firebaseUser = r;
    analytics.set_user_id(r ? r.uid : "");
  };
}
const authWrapper = new AuthWrapperMain(config, verifyAuthStateAndRoute);
export default authWrapper;
