import * as Preact from "preact";
import { User } from "@thrive-web/ui-api";
import { useCallback, useLayoutEffect, useMemo, useRef } from "preact/hooks";
import {
  useRenderPropsFunction,
  useStateIfMounted,
  useTextFilter,
  useChildRef,
} from "@thrive-web/ui-hooks";
import {
  Avatar,
  ButtonWithIcon,
  CompoundButton,
  Icon,
  OptionsList,
  Popover,
  SearchBar,
  UserListItem,
} from "@thrive-web/ui-components";

// test user's first name, last name, and email
const filterUser: RegexFilterFunction<User> = (user, regex) =>
  (user.first_name ? regex.test(user.first_name) : false) ||
  (user.last_name ? regex.test(user.last_name) : false) ||
  (user.email
    ? regex.test(user.email.slice(0, user.email.indexOf("@")))
    : false);

/** a tooltip-like popover with a list of users and a text input search field */
export const GroupTodoAssigneeInput: Preact.FunctionComponent<{
  members: User[];
  assignee?: User;
  onChange: (user?: User) => void;
}> = ({ members, assignee, onChange }) => {
  const [icon_ref, setIconRef] = useChildRef();
  const pass_icon_ref = useCallback(() => icon_ref.current, [icon_ref.current]);

  const input_ref = useRef<HTMLInputElement | null>(null);
  const [text, setText] = useStateIfMounted("");
  // user record for the selected user
  const assignee_value = useMemo(
    () => (assignee ? members.find(m => m.id === assignee.id) : undefined),
    [assignee, members]
  );

  const [open, setOpen_] = useStateIfMounted(false);
  // clear text input on open/close
  const setOpen = useCallback(
    new_open => {
      setOpen_(new_open);
      setText("");
    },
    [setOpen_, setText]
  );
  useLayoutEffect(() => {
    if (!input_ref.current) {
      return;
    }
    open
      ? setTimeout(() => input_ref.current?.focus(), 50)
      : input_ref.current.blur();
  }, [open, input_ref.current]);

  const [inputProps, setInputProps] = useStateIfMounted({});

  const matches = useTextFilter(text, members, filterUser);

  const RenderItem = useRenderPropsFunction<OptionsListItemProps<User>>(
    ({ item, onSelect }) => (
      <UserListItem
        user={item}
        onClick={() => onSelect(item)}
        size="xs"
        useDiv
      />
    ),
    "GroupTodoAssigneeInput-ListItem",
    []
  );

  const clearValue = useCallback(() => onChange(), [onChange]);

  const inputElemProps = useMemo(
    () => ({ ...inputProps, ref: input_ref }),
    [inputProps]
  );

  return (
    <Popover
      triggerComponent={
        assignee ? (
          <CompoundButton
            className="with-icon__compound"
            items={[
              <ButtonWithIcon
                className="button filled gray"
                icon={
                  <Avatar
                    user={assignee_value as User}
                    size="xs"
                    isLink={false}
                    getRef={setIconRef}
                  />
                }
                side="left"
                onClick={() => setOpen(!open)}
              >
                {assignee_value?.full_name}
              </ButtonWithIcon>,
              <ButtonWithIcon
                className="filled gray"
                icon="remove"
                onClick={clearValue}
              />,
            ]}
          />
        ) : (
          <ButtonWithIcon
            className="filled gray"
            icon={<Icon name="connection-request" ref={icon_ref} />}
            side="left"
            onClick={() => setOpen(!open)}
          >
            Assign
          </ButtonWithIcon>
        )
      }
      show={open}
      getSourceRef={pass_icon_ref}
      defaultDirection="top"
    >
      <div className="stack">
        <SearchBar
          placeholder="Filter Group Members"
          onSubmit={setText}
          inputProps={inputElemProps}
          autoSubmitDelay={250}
        />
        <OptionsList
          options={matches}
          RenderItem={RenderItem}
          open={open}
          setOpen={setOpen}
          onSelect={onChange}
          setControlListeners={setInputProps}
          className="stack__scrolling-content"
        />
      </div>
    </Popover>
  );
};
