import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { useAppUser } from "@thrive-web/ui-hooks";
import { AppUser } from "@thrive-web/ui-model";
import { group_role_of } from "@thrive-web/ui-utils";

type PermissionCheckFunc<T extends object> = (
  user: AppUser,
  data: T
) => boolean;

// extra params that are required to evaluate permissions for each rule
interface PermissionCheckTypeMap {
  age: {};
  group_role: { group: Group | null; role: "has_member" | "has_admin" };
  // community_role: { community: Community, role: "has_member" | "has_moderator" | "has_manager" | "has_admin" };
}
type PermissionCriteria = keyof PermissionCheckTypeMap;

const PERMISSIONS: {
  [K in keyof PermissionCheckTypeMap]: PermissionCheckFunc<
    PermissionCheckTypeMap[K]
  >;
} = {
  age: user => !!user.is_adult,
  group_role: (user, { group, role }) => {
    const self_role = group ? group_role_of(user, group) : undefined;
    return !!self_role && (self_role === "has_admin" || role === "has_member");
  },
};

// hook form, returns boolean that indicates if permission is granted
export const usePermCheck = <T extends PermissionCriteria>(
  type: T,
  props: PermissionCheckTypeMap[T]
) => {
  const self = useAppUser();
  return useMemo<boolean>(
    () =>
      self
        ? (PERMISSIONS[type] as PermissionCheckFunc<PermissionCheckTypeMap[T]>)(
            self,
            props
          )
        : false,
    [self, ...Object.values(props)]
  );
};

type PermCheckProps<T extends PermissionCriteria> = Preact.RenderableProps<
  {
    type: T;
    alt?: Preact.ComponentChildren;
  } & PermissionCheckTypeMap[T]
>;

// component form, renders children if permission granted, otherwise renders
// alternate content (defaults to null)
export const PermCheck = <T extends PermissionCriteria>({
  type,
  alt = null,
  key,
  children,
  jsx,
  ref,
  ...props
}: PermCheckProps<T>): Preact.VNode | null => {
  const has_perm = usePermCheck(
    type,
    // @ts-expect-error:
    props
  );
  return <Preact.Fragment>{has_perm ? children : alt}</Preact.Fragment>;
};
