import * as Preact from "preact";
import { DocBase } from "@thrive-web/core";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { Goal, Todo, User, WriteTodo } from "@thrive-web/ui-api";
import {
  useDirtyFormWithDiff,
  useRenderPropsFunction,
  useRequest,
} from "@thrive-web/ui-hooks";
import {
  ButtonWithIcon,
  ErrorMessage,
  RequestButtonWithIcon,
  TextAreaWithFormHelpers,
  DatePicker,
  Icon,
  PopoverTriggerProps,
  FloatingTitle,
  GROUP_DETAIL_LIST_CONTEXTS,
  GROUP_GROUP,
} from "@thrive-web/ui-components";
import { analytics, make_displayable_error } from "@thrive-web/ui-common";
import { GroupTodoAssigneeInput } from "./GroupTodoAssigneeInput";
import { GroupTodoGoalInput } from "./GroupTodoGoalInput";

export const GroupTodoForm: Preact.FunctionComponent<{
  record?: WriteTodo;
  submitText: string;
  submitIcon: FontIconName;
  onSubmit: (data: WriteTodo) => Promise<DocBase & { data: Todo }>;
  dismiss: () => void;
}> = ({
  record: { id, type, created_by, created_at, group: _, ...record } = {},
  submitIcon,
  submitText,
  onSubmit,
  dismiss,
}) => {
  const group = useContext(GROUP_GROUP);
  const members = useContext(GROUP_DETAIL_LIST_CONTEXTS.members).list;

  const prep_for_diffing = useCallback(
    ({ description, assigned_to, due_date, goal }: WriteTodo) => ({
      description,
      assigned_to,
      due_date,
      goal,
      group: group?.id,
    }),
    [group]
  );
  const [todo, updatedData, deletedKeys, setField, onChangeInput, , resetForm] =
    useDirtyFormWithDiff(record, "GroupTodoForm", prep_for_diffing, !id);

  const setDueDate = useCallback(
    value => {
      if (!!value) {
        analytics.log_event(analytics.EVENTS.to_do.due_date);
      }
      setField("due_date", value.toISOString());
    },
    [setField]
  );
  const setAssignee = useCallback(
    (user?: User) => {
      if (!!user) {
        analytics.log_event(analytics.EVENTS.to_do.user_assigned);
      }
      setField("assigned_to", user);
    },
    [setField]
  );
  const setGoal = useCallback(
    (goal?: Goal) => {
      if (!!goal) {
        analytics.log_event(analytics.EVENTS.to_do.goal_tagged);
      }
      setField("goal", goal);
    },
    [setField]
  );

  const [sendRequest, { pending, success, error }] = useRequest(onSubmit);
  const submitForm = useCallback(
    e => {
      e.preventDefault();
      if (!e.target.checkValidity()) {
        return;
      }
      const req_data = {
        id,
        ...updatedData,
        group: group ? { id: group.id } : undefined,
      };
      deletedKeys?.forEach(k => {
        // @ts-expect-error:
        req_data[k] = null;
      });

      return sendRequest(req_data).then(res => {
        analytics.log_event(analytics.EVENTS.to_do_saved);
        resetForm({ ...res.data, group } as Todo);
        setTimeout(dismiss, 750);
      });
    },
    [sendRequest, todo, resetForm]
  );

  const icon_ref = useRef<HTMLElement>();
  const pass_icon_ref = useCallback(() => icon_ref.current, [icon_ref.current]);

  const datePickerButton = useRenderPropsFunction(
    ({ value, ...triggerProps }: PopoverTriggerProps & { value?: Date }) => (
      <ButtonWithIcon
        {...triggerProps}
        className="filled gray"
        icon={<Icon name="calendar" ref={icon_ref} />}
        side="left"
      >
        {value ? value.toLocaleDateString() : "Due Date"}
      </ButtonWithIcon>
    ),
    "DatePicker-Button",
    [todo.due_date]
  );

  const popover_props = useMemo<Partial<PopoverProps>>(
    () => ({
      defaultDirection: "top",
      getSourceRef: pass_icon_ref,
    }),
    [pass_icon_ref]
  );

  const date_value = useMemo(
    () => (todo.due_date ? new Date(todo.due_date) : undefined),
    [todo.due_date]
  );

  return (
    <form id="group-todos-form" onSubmit={submitForm}>
      <div className="group-todos__form">
        <div className="form__input-row">
          <TextAreaWithFormHelpers
            name="description"
            placeholder="What needs to get done?"
            onChange={onChangeInput("description")}
            value={todo.description}
            required={true}
            controlled={true}
          >
            <FloatingTitle name="description">Description</FloatingTitle>
          </TextAreaWithFormHelpers>
        </div>
        <div className="form__input-row">
          <GroupTodoGoalInput onChange={setGoal} value={todo.goal} />
        </div>
      </div>
      <div className="modal__footer group-todos__form__footer">
        <div className="modal__footer__left">
          <div className="group-todos__form__footer__item" data-item="due-date">
            <div className="group-todos__form__footer__label">Due date:</div>
            <DatePicker
              onChange={setDueDate}
              value={date_value}
              popoverProps={popover_props}
            >
              {datePickerButton}
            </DatePicker>
          </div>
          <div className="group-todos__form__footer__item" data-item="assignee">
            <div className="group-todos__form__footer__label">Assign to:</div>
            <GroupTodoAssigneeInput
              members={members || []}
              assignee={todo.assigned_to}
              onChange={setAssignee}
            />
          </div>
        </div>
        <div className="modal__footer__right">
          <RequestButtonWithIcon
            className="filled gray"
            icon={submitIcon}
            side="left"
            type="submit"
            pending={pending}
            success={success}
            successText="Success!"
          >
            {submitText}
          </RequestButtonWithIcon>
        </div>
      </div>
      {error && (
        <ErrorMessage>{make_displayable_error(error).message}</ErrorMessage>
      )}
    </form>
  );
};
