import { RequestButtonWithIcon, Tooltip } from "@thrive-web/ui-components";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import {
  useApiMethod,
  useAppUser,
  useDirtyForm,
  useDynamicListVariable,
  useRequest,
} from "@thrive-web/ui-hooks";
import {
  generate_id,
  is_valid_email,
  is_valid_phone_number,
  make_displayable_error,
} from "@thrive-web/ui-common";
import {
  AssessmentFeedbackInvitation,
  MappedApiResponse,
  WriteAssessmentFeedbackInvitation,
} from "@thrive-web/ui-api";
import { RecipientData } from "./types";
import { FeedbackInviteListItem } from "./FeedbackInviteListItem";
import { RequestStatus } from "@thrive-web/ui-model";

const blank_ids = [generate_id(), generate_id()];

type InviteSpec = {
  value: string;
  index: number;
  data: RecipientData | null;
  request: RequestStatus;
  validity?: string;
  record?: AssessmentFeedbackInvitation;
};

type InviteListItemSpec = InviteSpec & {
  send: () => Promise<MappedApiResponse<"createFeedbackInvitation">>;
};

const getValidRecipientData = (value: string): RecipientData | null => {
  if (is_valid_phone_number(value)) {
    const str = value.replace(/[^0-9]/g, "");
    return {
      contact: `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(6, 10)}`,
      contact_type: "tel",
    };
  } else if (is_valid_email(value)) {
    return {
      contact: value,
      contact_type: "email",
    };
  }
  return null;
};

export const ProfileBuilderFeedbackModal: Preact.FunctionComponent<{
  dismiss: () => void;
  open: boolean;
  finishStep: (data: any) => void;
  invited: readonly AssessmentFeedbackInvitation[] | null;
}> = props => {
  return (
    <div className="profile-builder__content">
      <div className="profile-builder__content__prompt">
        Feedback from others will assist you to understand your strengths and
        gain insights about yourself.
        <br />
        <br />
        Invite at least 3 people you know and trust.
      </div>
      <ProfileBuilderFeedbackForm {...props} />
    </div>
  );
};

export const ProfileBuilderFeedbackForm: Preact.FunctionComponent<{
  dismiss: () => void;
  finishStep: (data: any) => void;
  invited: readonly AssessmentFeedbackInvitation[] | null;
  hideInvited?: boolean;
  minInputs?: number;
}> = ({ finishStep, invited, dismiss, hideInvited, minInputs = 3 }) => {
  const self = useAppUser();
  const setInputValidity = useCallback(
    (input: HTMLInputElement, str: string) => {
      if (input) {
        input.setCustomValidity(str);
        str && input.reportValidity();
      }
    },
    []
  );

  const createInviteReq = useApiMethod("createFeedbackInvitation");
  const createInvite = useCallback(
    (
      inv: InviteSpec
    ): Promise<MappedApiResponse<"createFeedbackInvitation">> => {
      return new Promise((resolve, reject) => {
        if (inv.request.pending) {
          return reject({
            code: "api/request-already-sent",
            message: "This request is already in flight.",
          });
        } else if (inv.request.success && inv.record) {
          return resolve({ data: inv.record });
        }
        inv.request.pending = true;
        inv.request.success = false;
        return createInviteReq({
          body: { data: { attributes: { ...inv.data } } },
        })
          .then(response => {
            inv.request.pending = false;
            inv.request.success = true;
            inv.request.error = undefined;
            inv.record = response.data;
            return resolve(response);
          })
          .catch(err => {
            inv.request.pending = false;
            inv.request.success = false;
            inv.request.error = make_displayable_error(err);
            return reject(err);
          });
      });
    },
    [createInviteReq]
  );
  const [new_invites, set_new_invites] =
    useDynamicListVariable<InviteListItemSpec>([]);
  const sendAllInvitesReq = useCallback(
    // @ts-expect-error:
    () => Promise.allSettled(new_invites.map(inv => inv.send())),
    [new_invites]
  );
  const [sendAllInvites, { pending, success, error }] =
    useRequest(sendAllInvitesReq);

  const all_invites = useMemo<{ contact: string; index: string | number }[]>(
    () =>
      (invited || [])
        .map(i => ({ contact: i.contact!, index: i.id as string | number }))
        .concat(
          (new_invites?.filter(i => !!i.data?.contact) || []).map(i => ({
            contact: i.data!.contact,
            index: i.index,
          }))
        ),
    [invited, new_invites]
  );

  const new_invites_text = useMemo(
    () => new_invites?.map(i => i.value || "") || [],
    [new_invites]
  );
  const clearFormDirtyState = useDirtyForm(
    new_invites_text,
    "FeedbackInvite",
    true
  );

  const invited_count = all_invites.length;
  const blank_id = useMemo(generate_id, [new_invites?.length]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (invited_count >= 3 && e.target.checkValidity?.()) {
        sendAllInvites().then((res: any[]) => {
          const errors = res
            .filter(r => r.status === "rejected")
            .map(r => r.reason);
          const success = res
            .filter(r => r.status === "fulfilled")
            .map(r => r.value);
          if (errors.length > 0) {
            return;
          }
          clearFormDirtyState();
          setTimeout(() => {
            finishStep(success.map(r => r.data));
            dismiss();
          }, 1000);
        });
      }
    },
    [
      createInvite,
      new_invites,
      invited_count,
      finishStep,
      dismiss,
      clearFormDirtyState,
    ]
  );

  const onChangeInput = useCallback(
    (index: number, e) => {
      const { value } = e.target;
      if (!value) {
        set_new_invites.remove(item => item.index === index);
        return;
      }
      const prev = new_invites?.find(i => i.index === index);
      const recipientData = getValidRecipientData(value);
      const item: InviteSpec = {
        index,
        value,
        data: recipientData,
        request: prev?.request || {
          pending: false,
          success: false,
          error: undefined,
        },
        validity: undefined,
      };
      const send = () => createInvite(item);

      if (
        recipientData &&
        all_invites.find(
          i => i.contact === recipientData.contact && i.index !== index
        )
      ) {
        item.validity =
          "You cannot send an invite to the same person more than once.";
      } else if (
        recipientData &&
        recipientData.contact.toLowerCase() === self?.email?.toLowerCase()
      ) {
        item.validity = "You cannot send an invite to yourself.";
      }

      set_new_invites.update(r => r.index === index, { ...item, send }, "end");
    },
    [setInputValidity, invited_count, all_invites, self?.email]
  );

  const rows = useMemo(() => {
    let list: {
      data: WriteAssessmentFeedbackInvitation | null;
      node: Preact.VNode;
    }[] =
      new_invites?.map(({ value, index, data, request, validity }, i) => ({
        data,
        node: (
          <FeedbackInviteListItem
            key={index}
            index={index}
            value={value}
            onChange={onChangeInput}
            success={request.success}
            error={request.error}
            validity={validity}
          />
        ),
      })) || [];
    if (invited && !hideInvited) {
      list = invited
        .map(
          (data, i) => ({
            data,
            node: (
              <FeedbackInviteListItem
                key={data.contact}
                index={i}
                value={data.contact!}
                success={true}
              >
                <div className="button filled static success">Invited</div>
              </FeedbackInviteListItem>
            ),
          })
          // @ts-expect-error:
        )
        .concat(list);
    }
    list.push({
      data: null,
      node: (
        <FeedbackInviteListItem
          key={blank_id}
          index={blank_id}
          value=""
          onChange={onChangeInput}
          success={false}
        />
      ),
    });
    while (list.length < minInputs) {
      list.push({
        data: null,
        node: (
          <FeedbackInviteListItem
            key={blank_ids[list.length - 1]}
            index={blank_ids[list.length - 1]}
            value=""
            onChange={onChangeInput}
            success={false}
          />
        ),
      });
    }
    return list;
  }, [
    new_invites,
    invited,
    onChangeInput,
    success,
    error,
    blank_id,
    hideInvited,
  ]);

  return (
    <form onSubmit={onSubmit}>
      <div className="profile-builder__feedback__form profile-builder__form">
        {rows.map(({ node }) => node)}
      </div>
      <div className="profile-builder__footer modal__footer">
        <Tooltip
          text="Please invite at least 3 people to provide feedback."
          disabled={invited_count >= 3}
          defaultOffset="left"
          defaultDirection="top"
        >
          <RequestButtonWithIcon
            className="filled"
            icon="paper-airplane"
            side="left"
            type="submit"
            pending={pending}
            success={success}
            successText="Invites Sent!"
            disabled={new_invites?.length === 0}
          >
            Send Feedback Requests
          </RequestButtonWithIcon>
        </Tooltip>
      </div>
    </form>
  );
};
