import { ApiMethodParameters } from "@thrive-web/core";
import * as Preact from "preact";
import {
  CommentItem,
  PostAddComment,
  useRenderDynamicListWithPagedFetch,
  LoadingParagraph,
  AvatarLoading,
  DefaultPendingView,
  DefaultErrorView,
  PageLoaderProps,
  DynamicListDispatch,
  WithUser,
  IS_ADMIN_UI,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import { Comment, Post } from "@thrive-web/ui-api";
import {
  useApiFetchPaged,
  useAppUser,
  useDynamicListVariable,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { useCallback, useContext, useMemo } from "preact/hooks";
export const INITIAL_SHOWN_COMMENT_COUNT = 2;

export const CommentViewMoreLoader: Preact.FunctionComponent<PageLoaderProps> =
  ({ nextPage, pending, error }) => (
    <div
      className="view-more-loader comment__list__more"
      data-pending={`${pending}`}
    >
      <div className="view-more-loader__idle">
        <button className="pill" onClick={nextPage}>
          Load More Comments ↓
        </button>
      </div>
      <div className="view-more-loader__pending">
        <DefaultPendingView />
      </div>
      {error && <DefaultErrorView error={error} />}
    </div>
  );

// use when all comments are already fetched (i.e. `has_comment` is included)
export const CommentListLoaded: Preact.FunctionComponent<{
  id?: string;
  comments: readonly Comment[];
  post_id: string;
  onUpdate: (comment: Comment, deleted: boolean) => void;
  focused?: boolean;
  canInteract: boolean;
}> = ({ comments, ...props }) => {
  const [list, dispatch] = useDynamicListVariable(comments);
  return list ? (
    <CommentListView comments={list} updateComments={dispatch} {...props} />
  ) : null;
};

export const CommentListView: Preact.FunctionComponent<{
  id?: string;
  comments: readonly Comment[];
  post_id: string;
  onUpdate: (comment: Comment, deleted: boolean) => void;
  focused?: boolean;
  loadMoreElem?: Preact.VNode | null;
  updateComments: DynamicListDispatch<Comment, false, []>;
  canInteract: boolean;
}> = ({
  comments,
  post_id,
  onUpdate,
  focused,
  id,
  loadMoreElem,
  updateComments,
  canInteract,
}) => {
  const user = useAppUser();
  const is_admin_ui = useContext(IS_ADMIN_UI);
  const [show_all_comments, set_show_all_comments] = useStateIfMounted(
    comments.length <= INITIAL_SHOWN_COMMENT_COUNT
  );

  const onSuccess = useCallback(
    comment => {
      if (user) {
        comment.created_by = user;
      }
      updateComments.add(comment);
      onUpdate(comment, false);
    },
    [updateComments, user, onUpdate]
  );

  const onUpdateComment = useCallback(
    (data: Comment, deleted: boolean) => {
      if (!deleted) {
        updateComments.update(c => c.id === data.id, data);
        onUpdate(data, false);
      } else {
        updateComments.remove(c => c.id === data.id);
        onUpdate(data, true);
      }
    },
    [updateComments, onUpdate]
  );

  return (
    <div className="comments">
      <ul className="comment__list" data-length={comments.length}>
        {comments.map((c, i) =>
          !show_all_comments && i >= INITIAL_SHOWN_COMMENT_COUNT ? null : (
            <li key={c.id} className="comment__list__item">
              <WithUser id={c.created_by!.id} user={c.created_by}>
                <CommentItem
                  data={c}
                  onUpdate={
                    c.created_by?.id === user?.id || is_admin_ui
                      ? onUpdateComment
                      : undefined
                  }
                  canInteract={canInteract}
                />
              </WithUser>
            </li>
          )
        )}
      </ul>
      {!show_all_comments ? (
        <div className="comment__list__more">
          <button className="pill" onClick={() => set_show_all_comments(true)}>
            Load More Comments ↓
          </button>
        </div>
      ) : (
        loadMoreElem
      )}
      {canInteract && (
        <PostAddComment
          id={id}
          onSuccess={onSuccess}
          postId={post_id}
          focused={focused}
        />
      )}
    </div>
  );
};

// use when we only have a partial list (or id list) of comments
export const CommentListFetch: Preact.FunctionComponent<{
  id?: string;
  post_id: string;
  onUpdate: (comment: Comment, deleted: boolean) => void;
  focused?: boolean;
  post?: Post;
  canInteract: boolean;
}> = ({ post, ...props }) => {
  const params = useMemo<ApiMethodParameters<"GET", "Comment", false>>(
    () => ({
      query: {
        filter: [["=", ["this", "Comment:posted_to"], ["id", props.post_id]]],
        sort: [{ by: "created_at", dir: "desc" }],
        include: ["created_by.User:profile_picture"],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
      },
    }),
    [props.post_id]
  );
  const get_comments = useApiFetchPaged("getComments", params);

  const [comments, dispatch] = useDynamicListVariable(post?.has_comment);

  const passthroughProps = useMemo(
    () => ({ ...props, updateComments: dispatch }),
    [props, dispatch]
  );

  return useRenderDynamicListWithPagedFetch(
    comments,
    dispatch,
    (result, load_more_elem, _, passthrough) => (
      <CommentListView
        comments={result}
        {...passthrough!}
        loadMoreElem={load_more_elem}
      />
    ),
    [],
    get_comments,
    passthroughProps,
    {
      PendingView: CommentListLoading,
      limit: 10,
      LoadMoreComponent: CommentViewMoreLoader,
    }
  );
};

export const CommentList: Preact.FunctionComponent<{
  id?: string;
  post_id: string;
  onUpdate: (comment: Comment, deleted: boolean) => void;
  focused?: boolean;
  post?: Post;
  canInteract: boolean;
}> = ({ post, ...props }) => {
  const comments_included = useMemo(
    () => post?.has_comment && post.has_comment.every((c: Comment) => !!c.body),
    [JSON.stringify(post)]
  );

  return comments_included ? (
    <CommentListLoaded comments={post!.has_comment as Comment[]} {...props} />
  ) : (
    <CommentListFetch post={post} {...props} />
  );
};

export const CommentListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(2).fill(0), []);
  return (
    <div className="comments">
      <ul className="comment__list" data-length={2}>
        {items.map((_, i) => {
          const body = <LoadingParagraph maxLines={4} />;
          return (
            <li className="comment__list__item" key={i}>
              <div className="comment__content">
                <div className="comment__avatar">
                  <AvatarLoading size="sm" />
                </div>
                <div className="comment__body">
                  <span className="comment__creator loading-item__text loading-item__shaded" />
                  <span data-screen-above="sm" className="comment__text">
                    {body}
                  </span>
                </div>
              </div>
              <span data-screen-below="md" className="comment__text">
                {body}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
