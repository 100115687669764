import * as Preact from "preact";
import { ForwardFn, forwardRef } from "preact/compat";
import { useMemo } from "preact/hooks";
import { useSingleClickDetection, useValueRef } from "@thrive-web/ui-hooks";
import { chain_event_listeners } from "@thrive-web/ui-utils";

interface SingleClickDetectorProps {
  onStartClick?: EventListener;
  onCancelClick?: EventListener;
  onEndClick: EventListener;
}

// HOC to add single click listener to an html element
export const makeSingleClickDetector = <E extends HTMLElement>(
  Component: ForwardFn<PropsOfElem<E>, E>,
  name?: string
) => {
  if (!Component.displayName) {
    Component.displayName = name;
  }
  const ComponentForwarded = forwardRef(Component);
  const ComponentWrapped: Preact.FunctionComponent<
    SingleClickDetectorProps & PropsOfElem<E>
  > = (
    { onStartClick, onCancelClick, onEndClick, children, ...props },
    ref
  ) => {
    const start_ref = useValueRef(onStartClick!);
    const cancel_ref = useValueRef(onCancelClick!);
    const end_ref = useValueRef(onEndClick);
    const click_listeners = useSingleClickDetection(
      end_ref,
      start_ref,
      cancel_ref
    );
    const listeners = useMemo(
      () => chain_event_listeners(click_listeners, props),
      [click_listeners, props?.onMouseDown]
    );

    return (
      <ComponentForwarded {...props} {...listeners} ref={ref}>
        {children}
      </ComponentForwarded>
    );
  };

  ComponentWrapped.displayName = `withClickDetect(${Component.displayName})`;
  return forwardRef(ComponentWrapped);
};

export const ULWithClickDetect = makeSingleClickDetector<HTMLUListElement>(
  (props, ref) => <ul {...props} ref={ref} />,
  "ULWithClickDetect"
);
