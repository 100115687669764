import * as Preact from "preact";
import { forwardRef } from "preact/compat";
import { maybeClassName, PropsOfElem } from "@thrive-web/ui-utils";

export const Icon = forwardRef<
  HTMLElement,
  {
    name: FontIconName;
    iconSize?: IconSize;
  } & PropsOfElem<HTMLSpanElement>
>(({ name, iconSize, className, ...props }, ref) => {
  return (
    <i
      data-size={iconSize}
      className={`thrive-icon i-thrive-${name}${maybeClassName(className)}`}
      {...props}
      ref={ref}
    />
  );
});
