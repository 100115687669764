import * as Preact from "preact";
import { AppLink, Icon } from "@thrive-web/ui-components";
import { maybeClassName } from "@thrive-web/ui-utils";

// create list of rendered components using link spec
export const create_nav_links = (
  links: NavLinkSpec[],
  onClick?: (e: EventFor<HTMLAnchorElement>) => void,
  className: string = ""
): Preact.VNode[] =>
  links.map(({ text, icon, ...props }) => (
    <AppLink
      {...props}
      activeClassName="active"
      className={`site-nav__link${maybeClassName(className)}`}
      blurOnClick={true}
      // @ts-ignore
      onClick={onClick}
    >
      <Icon name={icon} />
      <span data-text={text}>{text}</span>
    </AppLink>
  ));

export const NavLinks: Preact.FunctionComponent<{
  links: NavLinkSpec[];
  onClick?: (e: EventFor<HTMLAnchorElement>) => void;
}> = ({ links, onClick }) => (
  <div className="site-nav__links">{create_nav_links(links, onClick)}</div>
);
