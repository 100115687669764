import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import {
  UserCoaching,
  UserProfileConnection,
  UserProfileMood,
  UserProfilePrivacyIndicatorSimple,
} from "~/view/components";
import {
  DefaultPendingView,
  PageSidebar,
  SidebarSection,
  useSelfConnectionWithUser,
  UserProfileContactItem,
} from "@thrive-web/ui-components";
import { USER_DETAIL_CONTEXTS, USER_DISPATCH } from "./contexts";
import { useAppUser, useStateIfMounted } from "@thrive-web/ui-hooks";

export const UserProfileSidebar: Preact.FunctionComponent = () => {
  const self = useAppUser();
  const user = useContext(USER_DETAIL_CONTEXTS.user);
  const dispatch = useContext(USER_DISPATCH);

  const [connection, { pending }] = useSelfConnectionWithUser(self, user);
  useLayoutEffect(() => {
    dispatch("connection", connection || null);
  }, [connection]);

  const [refresh_conns, set_refresh_conns] = useStateIfMounted(0);
  const afterInteractions = useCallback(() => {
    set_refresh_conns(refresh_conns + 1);
  }, [set_refresh_conns, refresh_conns]);
  const on_create_interaction = useCallback(
    (health?: number) => {
      console.log(`health: `, health);
      if (health != null && connection) {
        dispatch("connection", { ...connection, health_tier: health });
      }
    },
    [dispatch, connection]
  );

  const linkProps = useMemo(
    () => ({
      user: user as User,
      connection,
      onFinish: afterInteractions,
      onCreateInteraction: on_create_interaction,
    }),
    [user, connection, afterInteractions, on_create_interaction]
  );

  if (!self || ((!user?.is_adult || !self?.is_adult) && !connection)) {
    return null;
  }

  if (!user) {
    return (
      <PageSidebar className="user-profile__sidebar">
        <DefaultPendingView />
      </PageSidebar>
    );
  }

  if (!connection && user.id !== self.id && user.is_adult && self.is_adult) {
    return (
      <div className="user-profile__sidebar page-sidebar">
        <div className="page-sidebar__content">
          <div className="sidebar__section user-profile__not-connected">
            <div className="sidebar__section__header">
              <div className="sidebar__section__title">Connection</div>
            </div>

            <div className="sidebar__section__body">
              <UserProfileConnection
                user={user}
                self={self}
                pending={pending}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <PageSidebar className="user-profile__sidebar">
      {user.has_current_mood || user.id === self.id ? (
        <SidebarSection>
          <UserProfileMood />
        </SidebarSection>
      ) : null}
      <SidebarSection name="Contact Info">
        {user.email && (
          <UserProfileContactItem type="email" linkProps={linkProps}>
            {user.email}
          </UserProfileContactItem>
        )}
        {user.phone_number && (
          <UserProfileContactItem type="phone" linkProps={linkProps}>
            {user.phone_number}
          </UserProfileContactItem>
        )}
      </SidebarSection>
      <SidebarSection
        name="Coaching"
        moreLink={
          user.id === self.id ? (
            <UserProfilePrivacyIndicatorSimple level="connections" />
          ) : undefined
        }
      >
        <UserCoaching user={user} refresh={refresh_conns} />
      </SidebarSection>
    </PageSidebar>
  );
};
