import {
  MediaPropertiesOf,
  MediaSize,
  ObjectOfType,
  RelationshipKeysOf,
  ResourceIdObj,
  ResourceObj,
  ResourceWriteObj,
} from "../types";
import type { EntityFrom, MetaInterface } from "@swymbase/metatype-core";
import { MetaTypes, TYPES } from "@thrive-web/core";

export const map_object_to_json_resource = <
  R extends ObjectOfType<T>,
  T extends string = string
>(
  record: R,
  type?: T
): ResourceObj | ResourceIdObj => {
  // @ts-expect-error:
  const resource: ResourceObj = {
    ...(record.id ? { id: record.id as string } : {}),
    attributes: {},
    ...(type || record.type ? { type: type || record.type } : {}),
  };

  Object.entries(record).forEach(([key, value]) => {
    if (value == null || key === "id" || key === "type") {
      return;
    }
    if (["string", "number", "boolean"].includes(typeof value)) {
      resource.attributes[key] = value;
    } else if (key !== "type") {
      let mapping;
      if (Array.isArray(value)) {
        mapping = value
          .map(v =>
            v && typeof v === "object"
              ? // @ts-expect-error: assume we'll never see an array here
                { id: v.id, ...(v.type ? { type: v.type } : {}) }
              : undefined
          )
          .filter(v => !!v);
      } else if (typeof value === "object") {
        mapping = { id: value.id };
        if (value.type) {
          mapping.type = value.type;
        }
      }
      if (!resource.relationships) {
        resource.relationships = {};
      }
      resource.relationships[key] = { data: mapping };
    }
  });

  return resource;
};

export const map_json_resource_to_object = <T extends object = object>(
  resource: ResourceObj | ResourceIdObj
): object => {
  if (!resource.type || !("attributes" in resource)) {
    return { id: resource.id };
  }
  const { id, type, attributes, relationships = {} } = resource;

  const relationship_props = {};
  Object.keys(relationships).forEach(key => {
    relationship_props[key] = relationships[key].data;
  });

  return {
    ...(id ? { id } : {}),
    ...(type ? { type } : {}),
    ...attributes,
    ...relationship_props,
  };
};

export const map_json_write_resource_to_object = <T extends object = object>(
  resource: ResourceWriteObj
): object => {
  const { attributes, relationships = {} } = resource;

  const relationship_props = {};
  Object.keys(relationships).forEach(key => {
    relationship_props[key] = relationships[key].data;
  });

  return {
    ...attributes,
    ...relationship_props,
  };
};

export const get_record_metatype = <T extends MetaInterface>(
  record: EntityFrom<T> | ResourceObj<T>
): T | undefined =>
  // @ts-expect-error
  Object.values(TYPES).find(t => t["@type"] === record.type);

export const get_record_type = <T extends keyof MetaTypes>(
  record: EntityFrom<MetaTypes[T]> | ResourceObj<MetaTypes[T]>
): T | undefined =>
  // @ts-expect-error
  Object.keys(TYPES).find(t => TYPES[t]["@type"] === record.type);

export const map_value_using_meta = <
  T extends MetaInterface,
  K extends RelationshipKeysOf<T>
>(
  value: EntityFrom<T>[K],
  meta: T["properties"][K]
) => {
  if (meta.type === "object") {
    // @ts-expect-error
    const { id, type } = value;
    return {
      id,
      ...(type ? { type } : {}),
    };
  } else {
    return value;
  }
};

export const get_url_key_for_media_property = <T extends MetaInterface>(
  meta: T,
  property: MediaPropertiesOf<T>
): string =>
  // @ts-expect-error:
  meta.properties[property].media.url_property;

export const media_url = <
  T extends keyof MetaTypes,
  M extends MediaPropertiesOf<MetaTypes[T]>
>(
  record: EntityFrom<MetaTypes[T]>,
  property: M,
  size?: MediaSize
): string | undefined => {
  if (size && record?.[property]?.[`${size}_url`]) {
    return record[property][`${size}_url`];
  }

  const meta = get_record_metatype(record);
  if (!meta) {
    return;
  }
  const prop = get_url_key_for_media_property(meta, property);
  return record?.[prop] as string;
};
