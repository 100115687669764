import * as Preact from "preact";
import { DocBase } from "@thrive-web/core";
import { PureComponent } from "preact/compat";
import { DEFAULT_PAGE_SIZE, DEFAULT_UI_ERROR } from "@thrive-web/ui-constants";
import {
  DefaultErrorView,
  DefaultPendingView,
  PageLoaderProps,
  InfiniteScrollLoader,
} from "@thrive-web/ui-components";
import { make_displayable_error } from "@thrive-web/ui-common";

export const ASYNC_RENDER_PAGED_DEFAULT_PROPS = {
  PendingView: DefaultPendingView,
  ErrorView: DefaultErrorView,
  LoadMore: InfiniteScrollLoader,
  scrollOnLoad: true,
  resendOnFuncChange: true,
  limit: DEFAULT_PAGE_SIZE,
} as const;

/** renders the status/result of a promise */
export class AsyncRenderPaged<T, P extends object = any> extends PureComponent<
  {
    getPromise: (
      offset: number,
      limit?: number
    ) => Promise<DocBase & { data: T[] }>;
    ErrorView?: Preact.ComponentType<{ error: DisplayableError }>;
    PendingView?: Preact.ComponentType;
    LoadMore?: Preact.ComponentType<PageLoaderProps>;
    children: (
      result: T[],
      loadMoreComponent: Preact.VNode | null,
      pending?: boolean,
      passthroughProps?: P & { total: number }
    ) => Preact.VNode | null;
    // scroll to the page target after finishing the request
    scrollOnLoad?: boolean;
    // continue displaying the current view while the request is being resent
    keepViewOnUpdate?: boolean;
    initialValue?: readonly T[];
    limit?: number;
    initialOffset?: number;
    // do nothing when the promise rejects with the "api/request-already-sent" error
    ignoreResendError?: boolean;
    // resend when the identity of getPromise changes
    resendOnFuncChange?: boolean;
    // props to pass through to the children
    passthroughProps?: P;
  },
  {
    pending: boolean;
    success?: boolean;
    list?: T[];
    error?: DisplayableError;
    offset: number;
    total: number;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      total: -1,
      offset: props.initialOffset,
      list: props.initialValue,
    };
  }
  mounted?: boolean;
  // remove duplicates from the list before rendering
  check_duplicates?: boolean;

  static defaultProps = ASYNC_RENDER_PAGED_DEFAULT_PROPS;

  setStateIfMounted = (state: any, ...args: any[]) => {
    if (this.mounted) {
      this.setState(state, ...args);
    }
  };

  // send the request on component mount
  componentDidMount() {
    this.mounted = true;
    this.tryRequest();
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  componentDidUpdate(previousProps, prevState) {
    if (
      prevState.pending &&
      !this.state.pending &&
      this.props.scrollOnLoad &&
      window.location.hash
    ) {
      // scroll to the url target when the content finishes loading
      setTimeout(() => {
        const node = document.getElementById(window.location.hash.slice(1));
        if (node) {
          node.scrollIntoView();
          node.classList.add("target");
        }
      }, 25);
    }
    if (previousProps.getPromise !== this.props.getPromise) {
      // if the request function changes, reset and resend the request
      this.setStateIfMounted(
        {
          pending: true,
          success: false,
          error: this.props.keepViewOnUpdate ? this.state.error : undefined,
          list: this.props.keepViewOnUpdate ? this.state.list : undefined,
          offset: 0,
        },
        this.tryRequest
      );
    } else if (prevState.offset !== this.state.offset && !this.state.pending) {
      // if the offset changes (i.e. next page), send the request with the new offset
      this.setStateIfMounted(
        {
          pending: true,
          success: false,
        },
        this.tryRequest
      );
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setError(error);
    errorInfo &&
      console.error(`Additional info on error in AsyncRender:`, errorInfo);
  }

  tryRequest = () => {
    const {
      getPromise,
      limit = DEFAULT_PAGE_SIZE,
      ignoreResendError,
    } = this.props;
    const { offset, list = [] } = this.state;
    if (offset === 0) {
      // don't need to check duplicates when fetching the first page
      this.check_duplicates = false;
    }
    try {
      // send the request
      getPromise(offset, limit)
        .then(result => {
          // if the request function has changed and it's going to be automatically
          // resent, do nothing
          if (this.props.getPromise !== getPromise) {
            return;
          }
          // otherwise store the result
          this.setStateIfMounted({
            pending: false,
            success: true,
            list:
              // if offset is 0, replace the list
              result.meta?.offset === 0
                ? result.data
                : // concat the new response with the existing list
                this.check_duplicates
                ? list.concat(
                    result.data.filter(r =>
                      list.find(
                        r_ =>
                          r === r_ ||
                          // @ts-expect-error:
                          r_.id === r.id
                      )
                    )
                  )
                : list.concat(result.data),
            total: result.meta?.total_result_count ?? -1,
          });
          this.check_duplicates = false;
        })
        .catch(error => {
          if (this.props.getPromise !== getPromise) {
            return;
          }
          if (ignoreResendError && error.code === "api/request-already-sent") {
            return;
          }
          this.setError(error, `Promise rejected in AsyncRender component:`);
        });
    } catch (error) {
      this.setError(error);
    }
  };

  setError = (
    error = DEFAULT_UI_ERROR,
    log = "Error occurred in AsyncRender component"
  ) => {
    console.error(log, error);
    this.setStateIfMounted({
      pending: false,
      success: false,
      error: make_displayable_error(error),
    });
  };

  // macro to fetch the next page (offset = current list length)
  nextPage = () => {
    this.setState({ offset: this.state.list?.length || 0 });
  };

  render() {
    const {
      children,
      ErrorView = DefaultErrorView,
      PendingView = DefaultPendingView,
      LoadMore = InfiniteScrollLoader,
      keepViewOnUpdate,
      limit = DEFAULT_PAGE_SIZE,
      passthroughProps = {},
    } = this.props;
    const { pending, success, list, error, total, offset = 0 } = this.state;
    // @ts-expect-error:
    passthroughProps.total = total;
    // @ts-expect-error:
    passthroughProps.offset = offset;

    try {
      // display the error view when the request resulted in an error AND
      // there is no result OR we don't want to keep the existing view while updating
      return !pending && !success && error && (!list || !keepViewOnUpdate) ? (
        <ErrorView error={error || DEFAULT_UI_ERROR} />
      ) : // show the pending view when there is no result OR
      // the request is pending AND we don't want to keep the existing view while updating
      !list || (!keepViewOnUpdate && pending) ? (
        <PendingView />
      ) : // show the result view if the request was successful OR
      // there's a result AND we want to keep the view while updating
      success || (keepViewOnUpdate && list) ? (
        children(
          list,
          // unless the last page is loaded, show the LoadMore component
          offset + limit >= total && (!pending || offset === 0) ? null : (
            <LoadMore
              nextPage={this.nextPage}
              pending={pending}
              error={error}
            />
          ),
          pending,
          // @ts-expect-error:
          passthroughProps
        )
      ) : (
        <ErrorView error={error || DEFAULT_UI_ERROR} />
      );
    } catch (otherError) {
      return <ErrorView error={otherError} />;
    }
  }
}
