import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { DynamicListProvider } from "@thrive-web/ui-components";
import { useDocumentTitle } from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import { HomeActivity } from "./HomeActivity";
import { HOME_POSTS, HOME_POSTS_SEARCH } from "./contexts";
import { ensure_id_is_iri } from "@thrive-web/ui-common";

export const Home: Preact.FunctionComponent<RoutePageProps> = ({
  matches = {},
}) => {
  useDocumentTitle(() => make_title(["Home"]), []);
  // post id for post detail modal
  const post_id = useMemo(
    () =>
      matches.post_id
        ? ensure_id_is_iri(matches.post_id || "", "Post")
        : undefined,
    [matches.post_id]
  );

  return (
    <DynamicListProvider context={HOME_POSTS}>
      <DynamicListProvider context={HOME_POSTS_SEARCH}>
        <HomeActivity post_id={post_id} query={matches} />
      </DynamicListProvider>
    </DynamicListProvider>
  );
};
