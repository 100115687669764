import * as Preact from "preact";
import { WriteGroup } from "@thrive-web/ui-api";
import { forwardRef } from "preact/compat";
import { useCallback, useMemo } from "preact/hooks";
import {
  ButtonWithIcon,
  Checkbox,
  ColorPicker,
  DefaultFileUploadButton,
  DropdownSelectInput,
  DropdownSelectInputDefaultButton,
  ErrorMessage,
  FileUpload,
  FloatingTitle,
  InputWithFormHelpers,
  TextAreaWithFormHelpers,
  Tooltip,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import { maybeClassName } from "@thrive-web/ui-utils";

export const BackgroundImageUploadButton: Preact.FunctionComponent<FileUploadButtonProps> =
  (props: FileUploadButtonProps) => (
    <DefaultFileUploadButton
      {...props}
      className={`filled gray${maybeClassName(props.className)}`}
    >
      Pick a Background
    </DefaultFileUploadButton>
  );

const PrivacyDropdownButton: Preact.FunctionComponent<
  MaybeClass &
    OptionsListControlProps<HTMLButtonElement> & {
      children: string | Preact.VNode;
    }
> = forwardRef<
  HTMLButtonElement,
  MaybeClass &
    OptionsListControlProps<HTMLButtonElement> & {
      value?: string;
      icon?: FontIconName;
      children: string | Preact.VNode;
      cannotEdit?: boolean;
    }
>(
  (
    { className, children, value, cannotEdit, icon = "list", ...props },
    ref
  ) => {
    const button = (
      <ButtonWithIcon
        {...props}
        ref={ref}
        className={`filled gray${maybeClassName(className)}`}
        icon={icon}
        iconOpposite="disclosure"
        side="left"
      >
        {value || children}
      </ButtonWithIcon>
    );

    if (!cannotEdit) {
      return button;
    }
    return (
      <Tooltip text="To protect the members of the group, privacy can’t not be changed.">
        {button}
      </Tooltip>
    );
  }
);

type PrivacyOpt = {
  label: string;
  value: boolean;
  icon: FontIconName;
  description: string;
};
const privacy_option = (value: boolean): PrivacyOpt =>
  value
    ? {
        label: "Private",
        value: true,
        icon: "lock",
        description:
          "Only members can see who’s in this group and what they post.",
      }
    : {
        label: "Public",
        value: false,
        icon: "globe",
        description:
          "Anyone can see who’s in the group and what they post. Anyone can join this group.",
      };
const membership_option = (value: boolean) =>
  value ? "All Members" : "Admins only";

const boolean_options = [true, false];

const popover_props = {
  defaultDirection: "bottom",
} as const;
export const GroupForm: Preact.FunctionComponent<{
  record?: WriteGroup;
  onInputChange: (key: keyof WriteGroup) => (e: any) => void;
  onFieldChange: <K extends keyof WriteGroup>(
    field: K,
    value: WriteGroup[K]
  ) => void;
  onChangeFile: (new_file?: FileUploadData) => void;
  onSubmit: (e) => void;
  photoFile?: FileUploadData;
  error?: DisplayableError;
  isEdit?: boolean;
}> = ({
  record,
  onChangeFile,
  onInputChange,
  onFieldChange,
  photoFile,
  onSubmit,
  error,
  isEdit,
  children,
}) => {
  const onChangeCheckbox = (field: keyof WriteGroup) => e =>
    onFieldChange(field, e.target.checked);
  const onChangeColor = useCallback(
    color => onFieldChange("background_color", color),
    [onFieldChange]
  );

  const onChangePrivacy = useCallback(
    (value: boolean) => onFieldChange("is_private", value),
    [onFieldChange]
  );
  const onChangeMembership = useCallback(
    (value: boolean) => onFieldChange("can_members_invite", value),
    [onFieldChange]
  );

  const privacy_dropdown_props = useMemo(
    () => ({
      getValueLabel: (value: boolean) => privacy_option(value).label,
      buttonProps:
        record?.is_private != null
          ? {
              icon: privacy_option(record.is_private).icon,
              value: privacy_option(record.is_private).label,
              cannotEdit: record.is_private && isEdit,
            }
          : undefined,
    }),
    [record?.is_private]
  );
  const member_dropdown_props = useMemo(
    () => ({
      getValueLabel: (value: boolean) => membership_option(value),
      buttonProps:
        record?.is_private != null
          ? {
              value: membership_option(record.is_private),
              children: membership_option(record.is_private),
            }
          : undefined,
    }),
    [record?.is_private]
  );

  return (
    <form className="group-create__form" onSubmit={onSubmit}>
      <div className="group-create__form__body">
        <div className="form__input-row">
          <WithFloatingTitle title="Group Name">
            <InputWithFormHelpers
              name="name"
              placeholder="Group Name"
              value={record?.name}
              onChange={onInputChange("name")}
              required={true}
            />
          </WithFloatingTitle>
        </div>
        <div className="form__input-row" data-row="color-background">
          <ColorPicker
            onChange={onChangeColor}
            value={record?.background_color}
            required={true}
            popoverProps={popover_props}
          />
          <FileUpload
            value={photoFile}
            Button={BackgroundImageUploadButton}
            onChange={onChangeFile}
            accept="image/*"
          />
        </div>
        {error && (
          <div className="form__input-row">
            <ErrorMessage>{error.message}</ErrorMessage>
          </div>
        )}
        <div className="form__input-row">
          <TextAreaWithFormHelpers
            name="purpose"
            placeholder="What is the objective of this group?"
            // @ts-ignore
            value={record?.purpose}
            rows={10}
            onChange={onInputChange("purpose")}
            required={true}
            controlled={false}
          >
            <FloatingTitle name="purpose">Objective</FloatingTitle>
          </TextAreaWithFormHelpers>
        </div>
        <div className="form__section-header" data-row="privacy">
          <h3>Privacy</h3>
        </div>
        <div className="form__input-row">
          <DropdownSelectInput
            options={boolean_options}
            Button={PrivacyDropdownButton}
            {...privacy_dropdown_props}
            onSelect={isEdit && record?.is_private ? () => {} : onChangePrivacy}
            label="Select Privacy"
            required={true}
            value={record?.is_private}
            disabled={isEdit}
          />
          {record?.is_private != null && (
            <Preact.Fragment>
              <div className="form__field-description">
                {isEdit &&
                  "Privacy cannot be changed to protect the members of the group. "}
                {privacy_option(record.is_private).description}
              </div>
              {!isEdit && (
                <div className="form__field-description">
                  <strong>
                    Once this group is created the privacy cannot be changed.
                  </strong>
                </div>
              )}
            </Preact.Fragment>
          )}
        </div>
        {isEdit && (
          <Preact.Fragment>
            <div className="form__section-header">
              <h3>Membership</h3>
            </div>
            <div className="form__field-title">
              Who can add members to this group?
            </div>
            <div className="form__input-row">
              <DropdownSelectInput
                options={boolean_options}
                Button={DropdownSelectInputDefaultButton}
                {...member_dropdown_props}
                onSelect={onChangeMembership}
                label="Select an option"
                required={true}
                value={record?.can_members_invite ?? true}
              />
            </div>
            <div className="form__section-header">
              <h3>Features</h3>
            </div>
            <div className="form__input-row">
              <Checkbox
                name="has_relationship_management"
                checked={record?.has_relationship_management || false}
                onChange={onChangeCheckbox("has_relationship_management")}
                label="Group Engagement Score"
              />
              <div className="form__checkbox-description">
                Select this option if you want us to measure engagement in the
                group and display a group engagement score.
              </div>
            </div>
            <div className="form__input-row">
              <Checkbox
                name="has_goals"
                checked={record?.has_goals || false}
                onChange={onChangeCheckbox("has_goals")}
                label="Goals and Tasks"
              />
              <div className="form__checkbox-description">
                Select this option if you would like to set goals and create
                tasks for your group.
              </div>
            </div>
          </Preact.Fragment>
        )}
      </div>
      {children}
    </form>
  );
};
