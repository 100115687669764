import { Atom, Transacted } from "@thi.ng/atom";
import { event_handlers } from "./events";
import { AppContexts } from "./types";
import { AppEventName, AppEventRaw } from "./events/types";

export interface AppModel {
  state: Atom<AppContexts>;
  dispatch: <T extends AppEventName>(event: AppEventRaw<T>) => Promise<void>;
}

const createDispatcher =
  (state: Atom<AppContexts>) =>
  async <T extends AppEventName>(event: AppEventRaw<T>): Promise<void> => {
    console.debug(`Dispatched event: `, event);

    const handler = event_handlers[event.type];
    if (!handler || typeof handler !== "function") {
      console.error(`Invalid event type passed to reducer: `, event);
      return Promise.reject();
    }
    const tx = new Transacted(state);
    tx.begin();
    // @ts-ignore ts doesn't infer the payload type based on event.type
    return handler(state.deref(), tx, event.payload)
      .then(() => {
        tx.commit();
        return Promise.resolve();
      })
      .catch(e => {
        console.error(`Error occurred in handler for event`, event, e);
        tx.cancel();
        return Promise.reject();
      });
  };

export const Model = (contexts: AppContexts): AppModel => {
  const state = new Atom(contexts);
  return {
    state,
    dispatch: createDispatcher(state),
  };
};

export interface SiteComponentProps {
  model: AppModel;
  keys?: (keyof AppContexts)[];
}
