import * as Preact from "preact";
import { useCallback, useRef } from "preact/hooks";
import authWrapper from "~/auth/auth";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  ErrorMessage,
  RequestButton,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import {
  useDocumentTitle,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

export const EmailNotVerified: Preact.FunctionComponent<{ email: string }> = ({
  email,
}) => {
  useDocumentTitle(() => make_title(["Verify your email"]), []);
  const [resendEmail, { pending, success, error }] = useRequest(
    authWrapper.resendVerificationEmail
  );

  const [email_verified, set_email_verified] = useStateIfMounted(false);
  const checked = useRef(false);
  const checkEmailVerifiedReq = useCallback(
    () =>
      authWrapper.checkEmailVerified().then((res = false) => {
        set_email_verified(res);
        checked.current = true;
        if (res) {
          window.location.pathname = "/profile-builder";
        }
      }),
    [set_email_verified]
  );
  const [checkEmailVerified, { pending: check_pending, error: check_error }] =
    useRequest(checkEmailVerifiedReq);

  return (
    <div className="form__section register__form__section" data-part="verify">
      <h1>Check Your Email</h1>
      <p className="info">
        We’ve sent an email to <strong>{email}</strong> to verify your account.
      </p>
      <div className="verify-email__buttons">
        <RequestButtonWithIcon
          icon="logo"
          side="left"
          className="button filled full-width"
          onClick={checkEmailVerified}
          pending={check_pending}
        >
          Let's Get Started
        </RequestButtonWithIcon>
        {check_error || (checked.current && !email_verified) ? (
          <ErrorMessage>
            {check_error?.message ||
              "Please check your email to verify your account before continuing."}
          </ErrorMessage>
        ) : null}
        <RequestButton
          className="plain-link full-width"
          pending={pending}
          success={success}
          successText="Email Sent!"
          onClick={resendEmail}
        >
          I didn’t get an email, please re-send it.
        </RequestButton>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <a className="button plain-link full-width" href="/logout">
          Log in with another account
        </a>
      </div>
    </div>
  );
};
export const EmailNotVerifiedSite: Preact.FunctionComponent = () => {
  const email = authWrapper.getAuthState().firebaseUser?.email || "";
  return (
    <div className="login-page">
      <div className="login-page__logo">
        <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
      </div>
      <div className="login-page__form card verify-email">
        <EmailNotVerified email={email} />
      </div>
    </div>
  );
};
