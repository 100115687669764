/**
 * Shallow copy of the given record without the given keys.
 */
export const omit = <T, K extends keyof T>(
  record: T,
  keys: readonly K[]
): /* Produces a more readable type than Omit<T, K> */
Pick<T, Exclude<keyof T, K>> => {
  const sparse = {};
  for (const [key, value] of Object.entries(record)) {
    if (!keys.includes(key as K)) {
      sparse[key] = value;
    }
  }
  return sparse as any;
};
