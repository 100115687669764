import * as Preact from "preact";
import { PureComponent } from "preact/compat";
import { User } from "@thrive-web/ui-api";
import {
  ScreenSize,
  RELATIONSHIP_GROUP_MEMBER_LIMIT,
} from "@thrive-web/ui-constants";
import { Context, CONTEXTS } from "@thrive-web/ui-model";
import { useContext } from "preact/hooks";
import { Avatar, EmptyList } from "@thrive-web/ui-components";
interface ObjOf<T> {
  [key: number]: T;
}

const LINE_SCALE_DIFFERENCE = 2.125;
const NODE_TRANSITION_DURATION = 500;
const NODE_POSITIONS: ObjOf<ObjOf<[number, number]>> = {
  "0": {
    "0": [0, 0],
  },
  "1": {
    "0": [-1, 0],
    "1": [1, 0],
  },
  "2": {
    "0": [0, 0],
    "1": [-1, 0],
    "2": [1, 0],
  },
};
const NODE_OFFSET_RADIUS = {
  "0": 0,
  "1": 4.5,
  "2": 6,
  "3": 6,
  "4": 7,
  "5": 7,
  "6": 6,
  "7": 7,
  "8": 7,
  "9": 7,
  "10": 7,
  "11": 7,
  "12": 7,
  "13": 7,
  "14": 7.25,
  "15": 7.5,
};
const NODE_CONNECTION_ANGLES = {
  "0": {
    "0": 0,
  },
  "1": {
    "0": 0,
    "1": 180,
  },
  "2": {
    "0": 0,
    "1": 0,
    "2": 180,
  },
};

const rnd = n => Math.round(n * 1e10) / 1e10;
// precalculate angles/positions for all node counts up to the limit
(function () {
  for (let total = 3; total < RELATIONSHIP_GROUP_MEMBER_LIMIT; total++) {
    let start = 90;
    // angle between each branch
    const ang = 360 / total;
    // for even numbers, move the start by half the angle between nodes
    // (reduces the overall footprint of the graph)
    if (total % 2 === 0) {
      start += ang / 2;
    }
    NODE_POSITIONS[`${total}`] = {};
    NODE_POSITIONS[`${total}`]["0"] = [0, 0];
    NODE_CONNECTION_ANGLES[`${total}`] = {};
    NODE_CONNECTION_ANGLES[`${total}`]["0"] = 0;
    for (let pos = 1; pos <= total; pos++) {
      const angle = (2 * Math.PI * (start + ang * (pos - 1))) / 360;
      NODE_POSITIONS[`${total}`][`${pos}`] = [
        rnd(Math.cos(angle)),
        -rnd(Math.sin(angle)),
      ];
      let con_angle = start + ((ang * (total - 2)) / 2) * (pos - 1);
      if (total % 2 === 0) {
        con_angle -= ang;
      }
      if (pos % 2 === 0) {
        con_angle += 180;
      }
      NODE_CONNECTION_ANGLES[`${total}`][`${pos}`] = con_angle % 360;
    }
  }
})();

export type HealthGraphNode = User;

export interface HealthGraphProps {
  initialCenterId?: string;
  nodes: readonly HealthGraphNode[];
  // get the mapping for the health values for each connection on the graph with the
  // given center user
  calculateConnections: (center_id?: string) => ObjectOf<number>;
  // if true, the graph nodes can't be clicked (i.e. center node cannot be changed)
  isStatic?: boolean;
}

export class HealthGraphBase extends PureComponent<
  Context<"window_size"> & HealthGraphProps,
  {
    // current center user id
    center_id?: string;
    // previous center_id, during/after changing the center user
    old_center_id?: string;
    // mapping of user ids to their connection health scores with the center user
    connections: ObjOf<number>;
    // mapping of user ids to their index around the graph
    positions: ObjOf<number>;
    // this is set to false during center changes to prevent changes mid-animation
    allow_change: boolean;
    // id of the user that is currently hovered over
    hovered?: string;
  }
> {
  constructor(props) {
    super(props);
    // @ts-ignore
    this.state = {
      ...this.initState(props),
      // prevent changes until mounted
      allow_change: false,
    };
  }

  componentDidMount(): void {
    this.setState({ allow_change: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodes } = this.props;
    const { old_center_id } = this.state;
    if (nodes.length > 0 && prevProps.nodes.length === 0) {
      // @ts-ignore
      this.setState(this.initState());
    } else if (nodes.length > 0 && old_center_id != null) {
      setTimeout(
        () => this.setState({ allow_change: true, old_center_id: undefined }),
        NODE_TRANSITION_DURATION
      );
    }
  }

  initState = (props = this.props) => {
    const { nodes, initialCenterId = nodes[0].id } = props;
    if (nodes.length === 0) {
      return {};
    }
    const positions: ObjOf<number> = {};
    // assign each user an index around the graph
    nodes.forEach((n, i) => {
      // put the center node at 0
      if (n.id === initialCenterId && i !== 0) {
        positions[nodes[0].id] = i;
        positions[n.id] = 0;
      } else {
        positions[n.id] = i;
      }
    });
    return {
      center_id: initialCenterId,
      allow_change: true,
      positions,
      connections: props.calculateConnections(initialCenterId),
    };
  };

  // update the center user
  setCenterNode = (new_center_id: string) => () => {
    const { nodes, isStatic } = this.props;
    const { allow_change } = this.state;
    if (!allow_change || isStatic || nodes.length === 0) {
      return;
    }
    const { center_id = nodes[0].id, positions } = this.state;
    this.setState({
      center_id: new_center_id,
      old_center_id: center_id,
      // keep all positions the same, but swap the old and new centers
      positions: {
        ...positions,
        [center_id]: positions[new_center_id],
        [new_center_id]: 0,
      },
      connections: this.props.calculateConnections(new_center_id),
      allow_change: false,
    });
  };

  hoverNode =
    (id: string = "") =>
    () => {
      this.setState({ hovered: id });
    };

  render() {
    const { window_size, nodes, initialCenterId, isStatic } = this.props;
    const {
      hovered,
      center_id: s_center_id,
      old_center_id,
      connections,
      positions,
      allow_change,
    } = this.state;

    // if static, use props center_id, else use state center_id
    const center_id = !isStatic ? s_center_id : initialCenterId;

    // truncate the list if it exceeds the max node count
    const nodes_ =
      nodes.length > RELATIONSHIP_GROUP_MEMBER_LIMIT
        ? nodes.slice(0, RELATIONSHIP_GROUP_MEMBER_LIMIT)
        : nodes;
    const node_count = nodes_.length - 1;
    if (node_count === 0) {
      return (
        <div className="health-graph">
          <EmptyList>This group doesn't have any members yet.</EmptyList>
        </div>
      );
    }

    const transitionDuration = `${
      !allow_change ? NODE_TRANSITION_DURATION : 0
    }ms`;

    const scale = NODE_OFFSET_RADIUS[`${node_count}`];
    const line_scale = scale - LINE_SCALE_DIFFERENCE;
    // let section = "";

    return (
      <div className="health-graph">
        <div
          className="health-graph__graph"
          data-count={nodes.length}
          data-static={isStatic}
        >
          {nodes_.map(n => {
            const id = n.id;
            const pos = positions[id];
            // get coordinates for the user's assigned position
            const coordinates = NODE_POSITIONS[`${node_count}`][`${pos}`];
            const translate_node = `translate(${coordinates[0] * scale}em, ${
              coordinates[1] * scale
            }em)`;
            const translate_line = `translate(${
              coordinates[0] * line_scale
            }em, ${coordinates[1] * line_scale}em)`;

            let angle = NODE_CONNECTION_ANGLES[`${node_count}`][`${pos}`];
            if (id === center_id) {
              if (old_center_id != null) {
                angle =
                  NODE_CONNECTION_ANGLES[`${node_count}`][
                    `${positions[old_center_id]}`
                  ];
              } else if (hovered != null) {
                angle = NODE_CONNECTION_ANGLES[`${node_count}`][`${hovered}`];
              }
            }

            return (
              <Preact.Fragment key={id}>
                <div
                  key={`${id}-node`}
                  className="health-graph__graph__node"
                  data-pos={pos}
                  data-hovered={hovered === id}
                  style={{
                    transitionDuration,
                    transform: translate_node,
                  }}
                >
                  <Avatar
                    user={n}
                    size={window_size < ScreenSize.sm ? "sm" : "md"}
                    isLink={pos === 0}
                    tooltip={true}
                    onClick={
                      pos === 0 || isStatic ? undefined : this.setCenterNode(id)
                    }
                    onMouseEnter={
                      pos === 0 || isStatic ? undefined : this.hoverNode(id)
                    }
                    onMouseLeave={isStatic ? undefined : this.hoverNode()}
                  />
                </div>
                <div
                  key={`${id}-connection`}
                  className="health-graph__graph__connection"
                  style={{
                    transform: translate_line,
                    transitionDuration /*:
                        n.id === old_center_id ? "0ms" : transitionDuration*/,
                  }}
                >
                  <div
                    className="health-graph__graph__line"
                    data-placeholder={!n}
                    data-score={connections[id]}
                    style={{
                      width: `${
                        id === center_id || pos === 0
                          ? 0
                          : (node_count == 1 ? 2 * scale : scale) -
                            LINE_SCALE_DIFFERENCE * 2
                      }em`,
                      transform: `translate(0, 0) rotate(${angle}deg) scale(${
                        id === center_id ? 0 : 1
                      }, 1)`,
                      transition: `transform ${
                        (id === center_id && allow_change) ||
                        id === old_center_id
                          ? "0ms"
                          : transitionDuration
                      }, opacity ${NODE_TRANSITION_DURATION}ms, border-color ${NODE_TRANSITION_DURATION}ms, width ${NODE_TRANSITION_DURATION}ms, background-color ${NODE_TRANSITION_DURATION}ms`,
                    }}
                  >
                    <div className="health-graph__line__inner" />
                  </div>
                </div>
              </Preact.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

export const HealthGraph: Preact.FunctionComponent<
  Omit<HealthGraphProps, "window_size">
> = props => {
  const window_size = useContext(CONTEXTS.window_size);
  return <HealthGraphBase {...props} window_size={window_size} />;
};
