import * as Preact from "preact";
import { EventRSVP, Event } from "@thrive-web/ui-api";
import {
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
  DynamicListStatusDefault,
} from "@thrive-web/ui-components";
import { createNamedContext } from "@thrive-web/ui-utils";

// list of rsvps for a single event (on event detail)
export const EVENT_RSVPS: Preact.Context<
  DynamicListCtxSpec<EventRSVP, true, [string]>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
    status: DynamicListStatusDefault,
  },
  "EventRSVPs"
);

// list of events created by current user
export const EVENTS_CREATED: Preact.Context<
  DynamicListCtxSpec<Event, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "EventsCreated"
);
