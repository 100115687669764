import { Group } from "@thrive-web/ui-api";
import {
  DefaultModalContent,
  GROUP_DETAIL_CONTEXTS,
  RequestButtonWithIcon,
  useGroupJoin,
} from "@thrive-web/ui-components";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { useAppUser, useMedia, useRequest } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useCallback, useContext } from "preact/hooks";

// modal for joining a public group
export const GroupDetailJoin: Preact.FunctionComponent<
  ModalBodyProps & { onFinish: () => void }
> = ({ onFinish, dismiss, closeButton }) => {
  const self = useAppUser();
  const group = useContext(GROUP_DETAIL_CONTEXTS.group);

  const [join_group_req, status] = useRequest(useGroupJoin(group, self));
  const join_group = useCallback(() => {
    return join_group_req().then(() => {
      setTimeout(() => {
        setTimeout(onFinish, MODAL_ANIMATION_DELAY);
        dismiss();
      }, 1000);
    });
  }, [self?.id, group?.id]);

  const background_image_url = useMedia<"Group", "background_image">(
    group || ({} as Group),
    "background_image",
    undefined,
    undefined,
    "small"
  );

  if (!group || !self) {
    return null;
  }

  return (
    <DefaultModalContent
      title="Join Public Group"
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div className="modal__footer__left">
            <button className="bordered gray" onClick={dismiss}>
              Cancel
            </button>
          </div>
          <div className="modal__footer__right">
            <RequestButtonWithIcon
              icon="add-solid-circle"
              side="left"
              className="color-overlay group-detail__join__button"
              successText="Success!"
              successIcon="checked"
              {...status}
              onClick={join_group}
              style={{
                "--color-overlay-background-color": group.background_color,
                backgroundColor: group.background_color,
              }}
            >
              Join Group
            </RequestButtonWithIcon>
          </div>
        </div>
      }
    >
      <div className="group-card">
        <div
          className="group-dot color-overlay"
          data-size="xl"
          style={{
            "--color-overlay-background-color": group.background_color,
            backgroundColor: group.background_color,
            backgroundImage: `url(${background_image_url})`,
          }}
        />
        <h1>{group.name}</h1>
      </div>
      <p>Would you like to join this public group?</p>
    </DefaultModalContent>
  );
};
