import {
  DocWithData,
  RelationshipKeysOf,
  ResourceLinkage,
  ResourceObj,
  get_record_metatype,
  map_object_to_json_resource,
  map_value_using_meta,
} from "@thrive-web/core";
import type { EntityFrom, MetaInterface } from "@swymbase/metatype-core";

export const map_property_to_relationship = <
  T extends MetaInterface,
  K extends RelationshipKeysOf<T>,
  M extends T["properties"][K]
>(
  record: EntityFrom<T>,
  key: K,
  meta: M
): ResourceLinkage<T, K> => {
  if (meta.multivalued) {
    if (key in record && Array.isArray(record[key])) {
      return (
        record[key]
          // @ts-expect-error: ts still thinks record[key] could be a non-array
          .map(value => map_value_using_meta(value, meta))
          .filter(v => v != null)
      );
    }
    // @ts-expect-error: ts won't infer the return type
    return [];
  } else {
    if (!meta.multivalued) {
      if (Array.isArray(record[key]) || record[key] === undefined) {
        // @ts-expect-error: ts won't infer the return type
        return undefined;
      }
      if (record[key] === null) {
        // @ts-expect-error: if prop is null, it means we want to clear/delete it
        return null;
      }
    }
    // @ts-expect-error: ts still thinks record[key] could be undefined
    return map_value_using_meta(record[key], meta);
  }
};

export const map_record_to_json_resource = <T extends MetaInterface>(
  record: EntityFrom<T>,
  metatype: T
): ResourceObj<T> => {
  const resource = {
    ...(record.id ? { id: record.id as string } : {}),
    ...(record.type ? { type: record.type as string } : {}),
    attributes: {
      // ...(record.types ? { types: record.types } : {}),
    },
  } as ResourceObj<T>;

  Object.entries(metatype.properties).forEach(([key, meta]) => {
    if (!meta.relationship && key in record) {
      resource.attributes[key] = record[key];
      return;
    }
    if (meta.relationship) {
      const relationship = map_property_to_relationship(
        record,
        key as RelationshipKeysOf<T>,
        meta as T["properties"][RelationshipKeysOf<T>]
      );
      if (relationship === undefined) {
        return;
      }
      if (!resource.relationships) {
        resource.relationships = {};
      }
      resource.relationships[key] = { data: relationship };
    }
  });

  return resource;
};

export const map_record_to_json_doc = <T extends MetaInterface>(
  record: EntityFrom<T>,
  metatype: T,
  include: RelationshipKeysOf<T>[] = []
): DocWithData<T, false> => {
  const resource = {
    ...(record.id ? { id: record.id as string } : {}),
    ...(record.type ? { type: record.type as string } : {}),
    attributes: {
      // ...(record.types ? { types: record.types } : {}),
    },
  } as ResourceObj<T>;

  const included: ResourceObj[] = [];
  const add_to_included = (key, value) => {
    if (
      include.includes(key as RelationshipKeysOf<T>) &&
      !included.find(i => i.id === value.id)
    ) {
      const value_meta = get_record_metatype(value);
      if (value_meta) {
        included.push(map_record_to_json_resource(value, value_meta));
      } else {
        // @ts-expect-error included expects ResourceObj, but this might return ResourceIdObj
        included.push(map_object_to_json_resource(value, value.type));
      }
    }
  };

  Object.entries(metatype.properties).forEach(([key, meta]) => {
    if (!meta.relationship && key in record) {
      resource.attributes[key] = record[key];
      return;
    }
    if (meta.relationship) {
      let relationship;
      // Apply default of empty array for missing multivalued props
      if (meta.multivalued) {
        if (key in record && Array.isArray(record[key])) {
          // @ts-expect-error: ts still thinks record[key] could be undefined
          relationship = record[key]
            // @ts-expect-error: Property 'map' does not exist on type 'NonNullable<PropertyFrom<T["properties"][string]>>'. [2339]
            .map(value => {
              if (value == null) {
                return;
              }
              add_to_included(key, value);
              return map_value_using_meta(value, meta);
            })
            .filter(v => v != null);
        } else {
          relationship = [];
        }
      } else {
        if (
          !meta.multivalued &&
          (record[key] == null || Array.isArray(record[key]))
        ) {
          return;
        }
        // @ts-expect-error: ts still thinks record[key] could be undefined
        relationship = map_value_using_meta(record[key], meta);
        add_to_included(key, record[key]);
      }

      if (relationship == null) {
        return;
      }
      if (!resource.relationships) {
        resource.relationships = {};
      }
      resource.relationships[key] = { data: relationship };
    }
  });

  return {
    data: resource,
    ...(include ? { included } : {}),
  };
};

export const map_record_list_to_json_doc = <T extends MetaInterface>(
  records: readonly EntityFrom<T>[],
  metatype: T,
  include: RelationshipKeysOf<T>[] = []
): DocWithData<T, true> => {
  const docs = records.map(r => map_record_to_json_doc(r, metatype, include));
  const data: DocWithData<T, true>["data"] = [];
  const included: DocWithData<T, true>["included"] = [];
  const included_ids = {};
  docs.forEach(d => {
    data.push(d.data);
    if (d.included) {
      d.included.forEach(i => {
        if (!included_ids[i.id]) {
          included_ids[i.id] = true;
          included.push(i);
        }
      });
    }
  });

  return {
    data,
    ...(include.length > 0 ? { included } : {}),
  };
};
