import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { CreateRequestModalBody } from "~/view/components";
import {
  ButtonWithIcon,
  PageBody,
  PageContent,
  PageHeader,
  TabLinks,
  PEOPLE_REQUESTS_INCOMING,
  usePermCheck,
  PEOPLE_CONNECTIONS,
  ConnectionList,
} from "@thrive-web/ui-components";
import { useAppUser, useModal } from "@thrive-web/ui-hooks";
import { RequestsListPage } from "./Requests";

const PeopleTabLinks: Preact.FunctionComponent = () => {
  const { list } = useContext(PEOPLE_REQUESTS_INCOMING);
  const incoming_count = list ? list.length : 0;
  const tab_links: NavLinkSpec[] = useMemo(
    () => [
      {
        href: "/people",
        icon: "connection",
        text: "All Connections",
      },
      {
        href: "/people/requests",
        icon: "paper-airplane",
        text: incoming_count ? (
          <Preact.Fragment>
            Requests
            <span className="people-page__request-count">{incoming_count}</span>
          </Preact.Fragment>
        ) : (
          "Requests"
        ),
      },
    ],
    [incoming_count]
  );
  return <TabLinks links={tab_links} />;
};

export const People: Preact.FunctionComponent<RoutePageProps> = () => {
  const self = useAppUser();
  const { dispatch } = useContext(PEOPLE_CONNECTIONS);
  const [createRequestModal, setOpen] = useModal({
    id: "create-request",
    body: CreateRequestModalBody,
    className: "people-create__modal invite-users-modal",
  });
  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const is_adult = usePermCheck("age", {});

  return (
    <PageContent
      id="people"
      data-page="people-list"
      className="list-page people-page"
    >
      <PageHeader
        title={<h1>People</h1>}
        button={
          is_adult ? (
            <ButtonWithIcon
              className="button filled gray"
              icon="add"
              collapse={true}
              side="right"
              onClick={() => setOpen(true)}
            >
              New Connection
            </ButtonWithIcon>
          ) : undefined
        }
      >
        <PeopleTabLinks />

        {is_adult && (
          <ButtonWithIcon
            className="button filled gray"
            side="left"
            icon="add"
            onClick={() => setOpen(true)}
          >
            New Connection
          </ButtonWithIcon>
        )}
      </PageHeader>
      <PageBody>
        <ConnectionList
          path="/people"
          openModal={openModal}
          user={self}
          onUpdate={dispatch.reset}
        />
        <RequestsListPage path="/people/requests" />
        {is_adult && createRequestModal}
      </PageBody>
    </PageContent>
  );
};
