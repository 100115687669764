import * as firebase from "firebase/app";
import "firebase/analytics";

declare global {
  interface Window {
    analytics_id_set?: boolean;
  }
}

export const STORAGE_KEY_FEEDBACK_REFERRAL_ID = "feedback_referral_id";

const EVENTS = {
  feedback_survey: {
    survey: {
      finish_survey: "feedback_survey.survey.finish_survey",
      start_survey: "feedback_survey.survey.start_survey",
      click_sign_up: "feedback_survey.survey.click_sign_up",
      click_learn_more: "feedback_survey.survey.click_learn_more",
    },
    signup: {
      clicked_get_started: "feedback_survey.signup.clicked_get_started",
      entered_name: "feedback_survey.signup.entered_name",
      entered_birthday: "feedback_survey.signup.entered_birthday",
      entered_email: "feedback_survey.signup.entered_email",
      entered_password: "feedback_survey.signup.entered_password",
    },
  },
  profile_complete: "profile_complete",
  self_ID_complete: "self_ID_complete",
  action_button: {
    action_button: "action_button",
    contact: "action_button.contact",
    group_update: "action_button.group_update",
    reimbursement: "action_button.reimbursement",
  },
  create_group: "create_group",
  create_group_saved: "create_group_saved",
  view_profile: "view_profile",
  edit_profile: "edit_profile",
  edit_profile_saved: "edit_profile_saved",
  // edit_demographic_info: "edit_demographic_info", // unused
  retake_strengths_survey: "retake_strengths_survey",
  strengths_survey_complete: "strengths_survey_complete",
  resend_feedback_survey: "resend_feedback_survey",
  send_feedback_survey: "send_feedback_survey",
  // feedback_survey_sent: "feedback_survey_sent", // unused
  update_experiences: "update_experiences",
  update_experiences_saved: "update_experiences_saved",
  profile: {
    contact: "profile.contact",
  },
  relationship_fulfillment: "relationship_fulfillment",
  relationship_fulfillment_complete: "relationship_fulfillment_complete",
  to_do_saved: "to_do_saved",
  to_do: {
    to_do: "to_do",
    due_date: "to_do.due_date",
    user_assigned: "to_do.user_assigned",
    goal_tagged: "to_do.goal_tagged",
  },
  to_do_deleted: "to_do_deleted",
  to_do_completed: "to_do_completed",
  to_do_cleared: "to_do_cleared",
  create_goal: "create_goal",
  create_goal_saved: "create_goal_saved",
  create_goal_suggested: "create_goal_suggested",
  group_invite_link: "group_invite_link",
  group_archived: "group_archived",
  group_leave: "group_leave",
  group_remove_user: "group_remove_user",
  group_make_admin: "group_make_admin",
  group_remove_admin: "group_remove_admin",
  group_add_user: "group_add_user",
  post_created: "post_created",
  post: {
    photo_attached: "post.photo_attached",
    users_tagged: "post.users_tagged",
    group_update: "post.group_update",
    reimbursement: "post.reimbursement",
    ask_for_help: "post.ask_for_help",
  },
  update_mood: "update_mood",
  post_comment: "post_comment",
  post_like: "post_like",
  edit_post: "edit_post",
  edit_post_saved: "edit_post_saved",
  comment_like: "comment_like",
  comment_edit: "comment_edit",
  comment_delete: "comment_delete",
  event_created: "event_created",
  event: {
    photo_added: "event.photo_added",
    location_added: "event.location_added",
  },
  event_cancel: "event_cancel",
  event_edit: "event_edit",
  event_edit_saved: "event_edit_saved",
  event_shared: "event_shared",
  event_RSVP: "event_RSVP",
  event_invite: "event_invite",
  event_view_RSVP: "event_view_RSVP",
  connection_request_sent: "connection_request_sent",
  connection_request_accepted: "connection_request_accepted",
  connection_request_declined: "connection_request_declined",
  connection_request_canceled: "connection_request_canceled",
  manage_connection: "manage_connection",
  remove_connection: "remove_connection",
  make_coach: "make_coach",
  make_coachee: "make_coachee",
  remove_coaching_relationship: "remove_coaching_relationship",
  follow_up: {
    reminder: "follow_up.reminder",
    no_reminder: "follow_up.no_reminder",
    group_update: "follow_up.group_update",
    do_later: "follow_up.do_later",
  },
  health_drop_nudge: "health_drop_nudge",
} as const;

// register user id with firebase analytics instance
const set_user_id = id => {
  try {
    // flag used elsewhere to determine whether the user is logged in
    window.analytics_id_set = !!id;
    return firebase.analytics().setUserId(id);
  } catch (err) {
    console.debug("Failed to perform analytics operation `setUserId`:", err);
  }
};

const log_event = (
  eventName,
  params?,
  options?,
  ignore_when_anonymous?: boolean
) => {
  // certain events shouldn't fire when the user isn't logged in
  if (ignore_when_anonymous && !window.analytics_id_set) {
    return;
  }
  try {
    return firebase.analytics().logEvent(eventName, params, options);
  } catch (err) {
    console.debug("Failed to perform analytics operation `logEvent`:", err);
  }
};

export const analytics = {
  set_user_id,
  log_event,
  EVENTS,
};
