import * as Preact from "preact";
import {
  Icon,
  UserContactLink,
  UserContactLinkProps,
} from "@thrive-web/ui-components";

export const UserProfileContactItem: Preact.FunctionComponent<{
  type: "email" | "phone";
  linkProps: Omit<UserContactLinkProps, "type">;
  children: string;
}> = ({ type, linkProps, children }) => {
  return (
    <div className="user-profile__contact__item">
      <div className="user-profile__contact__icon">
        <Icon name={type} iconSize="sm" />
      </div>
      <div className="user-profile__contact__text">
        <div className="user-profile__contact__label">
          {type === "phone" ? "Mobile Phone" : "Email"}
        </div>
        <div className="user-profile__contact__value">
          {
            <UserContactLink
              type={type}
              className="plain-link"
              href={type === "email" ? `mailto:${children}` : `tel:${children}`}
              {...linkProps}
            >
              {children}
            </UserContactLink>
          }
        </div>
      </div>
    </div>
  );
};
