import * as Preact from "preact";
import {
  AvatarLoading,
  DivWithIcon,
  LoadingParagraph,
  PageBody,
  PageContent,
  PageHeader,
  PageSidebar,
} from "@thrive-web/ui-components";

export const EventDetailLoading: Preact.FunctionComponent = () => (
  <PageContent
    id="event-detail-page"
    className="event-page detail-page has-sidebar"
  >
    <PageHeader className="banner-header">
      <div className="event-page__banner page-header__banner loading-item__shaded">
        <div className="page-header__subtitle">
          <div className="loading-item__title loading-item__shaded loading-item__shaded--white" />
        </div>
        <div className="page-header__title">
          <div className="loading-item__header loading-item__shaded loading-item__shaded--white" />
        </div>
      </div>
    </PageHeader>
    <PageBody>
      <div className="event-detail__content">
        <div className="page-section__header">
          <h3 className="loading-item__shaded loading-item__shaded--dark loading-item__title" />
        </div>
        <div className="page-section__content">
          <LoadingParagraph
            minLines={4}
            maxLines={6}
            className="loading-item__shaded--dark"
          />
        </div>
      </div>

      <PageSidebar className="event-detail__sidebar">
        <DivWithIcon
          className="event-detail__creator"
          data-size="md"
          icon={
            <AvatarLoading size="md" className="loading-item__shaded--dark" />
          }
          side="left"
        >
          <div className="loading-item__text loading-item__shaded loading-item__shaded--dark" />
        </DivWithIcon>
      </PageSidebar>
    </PageBody>
  </PageContent>
);
