import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import {
  DEFAULT_GROUP_FIELDS,
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
  DynamicListProvider,
  post_scope_comm_query,
} from "@thrive-web/ui-components";
import { THREAD_COMM_ID } from "@thrive-web/ui-constants";
import {
  Community,
  ConnectionMapped,
  ConnectionRequest,
  Event,
  Group,
  Notification,
} from "@thrive-web/ui-api";
import {
  useApiMethod,
  useAppUser,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { createNamedContext } from "@thrive-web/ui-utils";

export const IS_ADMIN_UI: Preact.Context<boolean> = createNamedContext(
  false,
  "IsAdminUi"
);

export const GROUPS: Preact.Context<DynamicListCtxSpec<Group, false, []>> =
  createNamedContext(
    {
      list: [],
      dispatch: DynamicListDispatchDefault,
    },
    "Groups"
  );

export const THRIVE_GROUPS: Preact.Context<{
  list: Group[] | null;
  fetch: () => Promise<Group[] | null>;
}> = createNamedContext(
  {
    list: null,
    fetch: () => Promise.reject("Placeholder fetch function called"),
  },
  "ThreadGroups"
);

export const MODERATED_COMMUNITIES: Preact.Context<{
  list: Community[] | null;
  fetch: (reset?: boolean) => Promise<Community[] | null>;
}> = createNamedContext(
  {
    list: null,
    fetch: () => Promise.reject("Placeholder fetch function called"),
  },
  "ModeratedComms"
);

export const COMMUNITIES: Preact.Context<
  DynamicListCtxSpec<Community, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "Communities"
);

export const EVENTS: Preact.Context<DynamicListCtxSpec<Event, false, []>> =
  createNamedContext(
    {
      list: [],
      dispatch: DynamicListDispatchDefault,
    },
    "Events"
  );

export const NOTIFICATIONS: Preact.Context<
  DynamicListCtxSpec<Notification, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "Notifications"
);

export const PEOPLE_CONNECTIONS: Preact.Context<
  DynamicListCtxSpec<ConnectionMapped, false, []>
> = createNamedContext(
  {
    list: null,
    dispatch: DynamicListDispatchDefault,
  },
  "Connections"
);

export const PEOPLE_REQUESTS_OUTGOING: Preact.Context<
  DynamicListCtxSpec<ConnectionRequest, false, []>
> = createNamedContext(
  {
    list: null,
    dispatch: DynamicListDispatchDefault,
  },
  "RequestsOut"
);

export const PEOPLE_REQUESTS_INCOMING: Preact.Context<
  DynamicListCtxSpec<ConnectionRequest, false, []>
> = createNamedContext(
  {
    list: null,
    dispatch: DynamicListDispatchDefault,
  },
  "RequestsIn"
);

// ctx providers for various data used throughout the app
export const WithGlobalData: Preact.FunctionComponent = ({ children }) => {
  const user = useAppUser();

  const [thread_groups, set_thread_groups] = useStateIfMounted<Group[] | null>(
    null
  );
  const [mngd_comms, set_mngd_comms] = useStateIfMounted<Community[] | null>(
    null
  );

  // fetch thread community with groups
  const fetchThreadReq = useApiMethod("getCommunity");
  const fetchThread = useCallback(
    () =>
      !user
        ? Promise.resolve(null)
        : fetchThreadReq(THREAD_COMM_ID, {
            query: {
              include: ["has_group"],
              fields: {
                Group: DEFAULT_GROUP_FIELDS,
              },
            },
          }).then(({ data }) => {
            const new_list =
              data.has_group
                ?.filter(g =>
                  (g as Group).has_member?.some(m => m.id === user?.id)
                )
                .map(group => ({
                  ...group,
                  in_community: { id: data.id },
                })) || null;
            set_thread_groups(new_list);
            return new_list;
          }),
    [user?.id]
  );

  // fetch communities that the user is a mod, manager, or admin of
  const fetchCommunities = useApiMethod("getCommunities");
  const fetchManagedCommunities = useCallback(
    (reset?: boolean) => {
      if (!user) {
        return Promise.resolve(null);
      }
      if (reset) {
        set_mngd_comms(null);
      }
      return fetchCommunities({
        query: {
          filter: post_scope_comm_query(user),
          limit: 1,
          include_count: true,
          include: ["cover_image", "avatar_image"],
        },
      }).then(({ data }) => {
        set_mngd_comms(data);
        return data;
      });
    },
    [set_mngd_comms, fetchCommunities, user?.id]
  );

  useEffect(() => {
    fetchThread();
  }, [fetchThread]);
  const thread_value = useMemo(
    () => ({ list: thread_groups, fetch: fetchThread }),
    [thread_groups, fetchThread]
  );

  useEffect(() => {
    fetchManagedCommunities(true);
  }, [fetchManagedCommunities]);
  const mngd_comms_value = useMemo(
    () => ({
      list: mngd_comms,
      fetch: fetchManagedCommunities,
    }),
    [mngd_comms, fetchManagedCommunities]
  );

  const { Provider: TGProvider } = THRIVE_GROUPS;
  const { Provider: MCProvider } = MODERATED_COMMUNITIES;

  if (user) {
    return (
      <DynamicListProvider context={GROUPS}>
        <DynamicListProvider context={COMMUNITIES}>
          <DynamicListProvider context={EVENTS}>
            <DynamicListProvider context={NOTIFICATIONS}>
              <DynamicListProvider context={PEOPLE_CONNECTIONS}>
                <DynamicListProvider context={PEOPLE_REQUESTS_INCOMING}>
                  <DynamicListProvider context={PEOPLE_REQUESTS_OUTGOING}>
                    <MCProvider value={mngd_comms_value}>
                      <TGProvider value={thread_value}>{children}</TGProvider>
                    </MCProvider>
                  </DynamicListProvider>
                </DynamicListProvider>
              </DynamicListProvider>
            </DynamicListProvider>
          </DynamicListProvider>
        </DynamicListProvider>
      </DynamicListProvider>
    );
  } else {
    return <Preact.Fragment>{children}</Preact.Fragment>;
  }
};
