import { ApiMethodParameters } from "@thrive-web/core";
import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { RegInvite, User } from "@thrive-web/ui-api";
import {
  RequestButton,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useRequest,
  useStateIfMounted,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { copy_to_clipboard, createNamedContext } from "@thrive-web/ui-utils";

export const SKELETON_INVITE_LINK_CTX = createNamedContext<{
  link: RegInvite | null;
  setLink: (v: RegInvite | null) => void;
}>({ link: null, setLink: () => {} }, "SkeletonInviteLink");

export const SKELETON_LIST_INVITE_LINK_CTX = createNamedContext<{
  links: Promise<RegInvite[]> | null;
} | null>(null, "SkeletonListInviteLink");

// get invitations matching the given params
const useGetSkeletonInviteList = (
  params: ApiMethodParameters<"GET", "RegistrationInvitation"> | null
) => {
  const send_req_method = useApiMethod("getRegistrationInvitations");
  const results = useRef<RegInvite[]>([]);
  return useCallback(() => {
    if (!params) {
      return Promise.reject();
    }
    return send_req_method({
      query: {
        sort: [{ by: "created_at", dir: "desc" }],
        ...params.query,
      },
      ...params,
    }).then(({ data }) => {
      if (!params.query?.offset) {
        results.current = data;
      } else {
        results.current = results.current.concat(data);
      }
      return results.current;
    });
  }, [params]);
};

// fetch the most recent invite sent to the given user
const useGetSkeletonInvite = (user: User) => {
  const self = useAppUser();
  const list_ctx = useContext(SKELETON_LIST_INVITE_LINK_CTX);

  const from_list = useRef<RegInvite | null>(null);
  const send_req_method = useApiMethod("getRegistrationInvitations");
  return useCallback(async () => {
    if (!self) {
      return Promise.reject();
    }
    // first, check list
    if (list_ctx) {
      if (!from_list.current && list_ctx.links) {
        from_list.current = await list_ctx.links.then(
          data => data.find(r => r.recipient?.id === user.id) ?? null
        );
      }
      return from_list.current;
    }
    // if not in list, fetch from api
    return send_req_method({
      query: {
        filter: [
          ["=", ["this", "RegistrationInvitation:recipient"], ["id", user.id]],
          ["=", ["this", "RegistrationInvitation:referrer"], ["id", self.id]],
        ],
        limit: 1,
        sort: [{ by: "created_at", dir: "desc" }],
      },
    }).then(({ data }) => {
      if (!data || data.length === 0) {
        return null;
      }
      return data[0] || null;
    });
  }, [self?.id, user.id, list_ctx?.links]);
};

// create an invitation link for the given user
// if auto_send === true, automatically send the link to the user via email
const useCreateSkeletonInvite = (user: User, auto_send: boolean) => {
  const self = useAppUser();
  const send_req_method = useApiMethod("createRegistrationInvitation");
  return useCallback(() => {
    if (!self) {
      return Promise.reject();
    }
    return send_req_method({
      body: {
        data: {
          attributes: { auto_send },
          relationships: {
            recipient: { data: { id: user.id } },
            referrer: { data: { id: self.id } },
          },
        },
      },
    });
  }, [user.id, self?.id, auto_send]);
};

// Create invitation and auto-send via email
export const SendSkeletonInviteButton: Preact.FunctionComponent<{
  user: User;
  icon?: boolean;
}> = ({ icon = true, children, user }) => {
  const [create_invite, status] = useRequest(
    useCreateSkeletonInvite(user, true)
  );
  const { setLink } = useContext(SKELETON_INVITE_LINK_CTX);

  const on_click = useCallback(
    e => {
      e.preventDefault();
      e.stopImmediatePropagation();
      create_invite().then(({ data }) => {
        setLink(data || null);
      });
    },
    [create_invite, setLink]
  );

  if (!icon) {
    return (
      <RequestButton
        className="filled pill button gray"
        {...status}
        successText="Invite sent!"
        onClick={on_click}
      >
        Send Invite Link
      </RequestButton>
    );
  }

  return (
    <RequestButtonWithIcon
      icon="paper-airplane"
      side="left"
      className="filled pill button gray"
      {...status}
      successText="Invite sent!"
      onClick={on_click}
    >
      Send invite to join Thread app
    </RequestButtonWithIcon>
  );
};

// create invitation and get link
export const SendSkeletonLinkButton: Preact.FunctionComponent<{
  user: User;
  icon?: boolean;
  successText?: string;
}> = ({ icon = true, successText, children, user }) => {
  const get_existing = useGetSkeletonInvite(user);
  const create_invite = useCreateSkeletonInvite(user, false);
  const { link, setLink } = useContext(SKELETON_INVITE_LINK_CTX);
  const link_ref = useValueRef(link);
  const clipboard_input = useRef<HTMLTextAreaElement>();

  const [get_link, { pending, success, error }] = useRequest(
    useCallback(() => {
      if (link_ref.current) {
        return Promise.resolve(link_ref.current);
      }
      // check for existing link
      return get_existing().then(new_link => {
        if (new_link) {
          setLink(new_link);
          return new_link;
        }
        // if not found, create a new link
        return create_invite().then(({ data: new_link_ }) => {
          setLink(new_link_);
          return new_link_;
        });
      });
    }, [get_existing, create_invite])
  );

  const on_click = useCallback(
    e => {
      e.preventDefault();
      e.stopImmediatePropagation();
      get_link().then(new_link => {
        if (new_link?.link) {
          copy_to_clipboard(new_link.link, clipboard_input.current);
        }
      });
    },
    [link, get_existing]
  );

  if (!icon) {
    return (
      <RequestButton
        className="filled pill button gray"
        pending={pending}
        error={error}
        showError={true}
        onClick={on_click}
      >
        {success ? "Link copied!" : "Copy Invite Link"}
        <textarea className="clipboard-input" ref={clipboard_input} />
      </RequestButton>
    );
  }

  return (
    <RequestButtonWithIcon
      icon={success ? "checked" : "share"}
      side="left"
      className="filled pill button gray"
      pending={pending}
      error={error}
      showError={true}
      onClick={on_click}
    >
      {success ? "Link copied to clipboard!" : "Share link to join Thread app"}
      <textarea className="clipboard-input" ref={clipboard_input} />
    </RequestButtonWithIcon>
  );
};

// provides a list of all invites that match the given params
export const SkeletonListLinkProvider: Preact.FunctionComponent<{
  params: ApiMethodParameters<"GET", "RegistrationInvitation"> | null;
}> = ({ params, children }) => {
  const [list, set_list] = useStateIfMounted<Promise<RegInvite[]> | null>(null);
  const get_list = useGetSkeletonInviteList(params);
  useEffect(() => {
    if (params) {
      set_list(get_list());
    }
  }, [get_list]);
  const value = useMemo(() => ({ links: list }), [list]);

  return (
    <SKELETON_LIST_INVITE_LINK_CTX.Provider value={value}>
      {children}
    </SKELETON_LIST_INVITE_LINK_CTX.Provider>
  );
};

// provides the existing invite for the given user
export const SkeletonLinkProvider: Preact.FunctionComponent<{
  initialValue?: RegInvite | null;
  user: User;
}> = ({ initialValue = null, user, children }) => {
  const [link, setLink] = useStateIfMounted<RegInvite | null>(initialValue);
  const value = useMemo(() => ({ link, setLink }), [link, setLink]);
  const get_initial = useGetSkeletonInvite(user);
  useEffect(() => {
    if (!initialValue) {
      get_initial().then(new_link => {
        setLink(new_link || null);
      });
    }
  }, []);
  return (
    <SKELETON_INVITE_LINK_CTX.Provider value={value}>
      {children}
    </SKELETON_INVITE_LINK_CTX.Provider>
  );
};
