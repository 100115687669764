import * as hooks from "preact/hooks";
import { Post, User } from "@thrive-web/ui-api";
import { entity_has_type } from "@thrive-web/ui-common";
import {
  open_zendesk_widget,
  prefill_zendesk_report_id,
} from "@thrive-web/ui-components";
import { useAppUser } from "@thrive-web/ui-hooks";
import { get_guid_from_iri } from "@thrive-web/ui-utils";

export interface ZendeskTicketDataField {
  value: string | number | boolean;
  readOnly?: boolean;
}

// returns callback that opens the zendesk modal with the current user's
// info pre-filled
export const useZendeskSupportRequest = (
  data?: ObjectOf<ZendeskTicketDataField>,
  on_open?: () => void
) => {
  const self = useAppUser();
  return hooks.useCallback(() => {
    if (!self) {
      return;
    }
    on_open?.();
    open_zendesk_widget({
      name: {
        value: self.full_name,
        readOnly: true,
      },
      email: {
        value: self.email,
        readOnly: true,
      },
      userId: {
        value: self.id,
        readOnly: true,
      },

      ...(data || {}),
    });
  }, [self, JSON.stringify(data), on_open]);
};

export const create_zendesk_report_subject = (record: Post | User) => {
  if (entity_has_type(record, "User")) {
    return `Report for user ${record.email}`;
  }
  // @ts-expect-error:
  const creator_email = record.created_by?.email;
  return `Report for post ${
    creator_email ? `by ${creator_email}` : get_guid_from_iri(record.id)[0]
  }`;
};

export const useZendeskReportEntity = (record: Post | User) => {
  const prefill = hooks.useCallback(() => {
    prefill_zendesk_report_id(record.id, create_zendesk_report_subject(record));
  }, [record?.id]);
  return useZendeskSupportRequest(undefined, prefill);
};
