import { ApiConnector } from "./api";

// create a promise that returns the instance of the ApiConnector to be used
// throughout the app at runtime
// also create a separate function that takes the ApiConnector instance and
// resolves the promise with it
// This allows code from the shared packages and the site-specific code to
// reference the same ApiConnector instance at runtime
const [api_promise, set_api_connector_instance] = ((): [
  Promise<ApiConnector>,
  (instance: ApiConnector) => void
] => {
  const promise_ref: any = {};
  const api_promise = new Promise<ApiConnector>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject("Took too long to initialize API connector");
    }, 10000);
    promise_ref.resolve = (instance: ApiConnector) => {
      clearTimeout(timer);
      resolve(instance);
    };
  });
  const set_instance = (instance: ApiConnector) => {
    promise_ref.resolve(instance);
  };
  return [api_promise, set_instance];
})();

const get_api_connector_instance = () => api_promise;

export { set_api_connector_instance };
export default get_api_connector_instance;
