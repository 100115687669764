import * as Preact from "preact";
import { DivWithIcon, Tooltip } from "@thrive-web/ui-components";
import { useAppUser } from "@thrive-web/ui-hooks";
import { useContext } from "preact/hooks";
import { USER_DETAIL_CONTEXTS } from "~/view/components";

const PRIVACY_LEVELS = {
  public: {
    icon: "globe",
    label: "Public",
    tooltip: "This is visible to everyone.",
  },
  private: {
    icon: "people",
    label: "Private",
    tooltip: "This is only visible to you.",
  },
  connections: {
    icon: "connection",
    label: "Your connections",
    tooltip: "This is visible to people you are connected with.",
  },
} as const;

type PrivacyLevel = keyof typeof PRIVACY_LEVELS;

export const UserProfilePrivacyIndicator: Preact.FunctionComponent<{
  level: PrivacyLevel;
  children: Preact.VNode;
}> = ({ level, children }) => {
  const self = useAppUser();
  const user = useContext(USER_DETAIL_CONTEXTS.user);

  if (!user || !self) {
    return null;
  }
  return user.id === self.id ? (
    <div className="user-profile__card__header">
      {children}
      <Tooltip
        className="user-profile__card__privacy"
        text={PRIVACY_LEVELS[level].tooltip}
        delay={500}
      >
        <DivWithIcon icon={PRIVACY_LEVELS[level].icon} side="left">
          {PRIVACY_LEVELS[level].label}
        </DivWithIcon>
      </Tooltip>
    </div>
  ) : (
    children
  );
};

export const UserProfilePrivacyIndicatorSimple: Preact.FunctionComponent<{
  level: PrivacyLevel;
}> = ({ level }) => (
  <Tooltip
    className="user-profile__card__privacy"
    text={PRIVACY_LEVELS[level].tooltip}
    delay={500}
  >
    <DivWithIcon icon={PRIVACY_LEVELS[level].icon} side="left">
      {PRIVACY_LEVELS[level].label}
    </DivWithIcon>
  </Tooltip>
);
