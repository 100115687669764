import * as Preact from "preact";
import { Group, Todo, User } from "@thrive-web/ui-api";
import {
  Avatar,
  DivWithIcon,
  Icon,
  Tag,
  Tooltip,
  GROUP_DETAIL_LIST_CONTEXTS,
} from "@thrive-web/ui-components";
import { useContext, useMemo } from "preact/hooks";
import { moment } from "@thrive-web/ui-common";
import {
  clamp_string_length,
  display_text,
  get_guid_from_iri,
  get_url_for_entity,
} from "@thrive-web/ui-utils";

export const GroupSidebarTodoItem: Preact.FunctionComponent<{
  todo: Todo;
  group: Group;
}> = ({ todo, group }) => {
  const goals = useContext(GROUP_DETAIL_LIST_CONTEXTS.goals).list;
  const members = useContext(GROUP_DETAIL_LIST_CONTEXTS.members).list;
  const { description, due_date, goal, assigned_to } = todo;
  const assignee: User | undefined = useMemo(
    () => (members ? members.find(u => u.id === assigned_to?.id) : undefined),
    [members, assigned_to?.id]
  );
  const linked_goal = useMemo(
    () =>
      !goal ? undefined : goals ? goals.find(g => g.id === goal.id) : undefined,
    [goal?.id, goals]
  );
  const overdue = Date.now() > new Date(due_date || "").getTime();

  if (!due_date) {
    return null;
  }
  const due_str = moment(due_date).format("MMMM D");
  return (
    <a
      href={`${get_url_for_entity(group)}/todos#${
        get_guid_from_iri(todo.id)[0]
      }`}
      className="group-sidebar__todo"
    >
      <div className="group-sidebar__todo__description">
        {clamp_string_length(display_text(description), 90)}
      </div>

      <DivWithIcon
        data-overdue={overdue}
        icon={
          assignee ? (
            <Tooltip text={`Assigned to ${assignee.full_name}`} delay={500}>
              <Avatar user={assignee} size="xs" isLink={true} />
            </Tooltip>
          ) : (
            <Tooltip text="Not assigned" delay={500}>
              <Icon name="broken-circle" />
            </Tooltip>
          )
        }
        side="left"
        className="group-sidebar__todo__details"
      >
        Due {due_str}
      </DivWithIcon>
      {linked_goal && (
        <div className="group-sidebar__todo__goal">
          <Tag>
            <strong>Goal</strong>: {display_text(linked_goal.name)}
          </Tag>
        </div>
      )}
    </a>
  );
};
