import { Group } from "@thrive-web/ui-api";
import {
  asSubroute,
  useFetchGroups,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { useAppUser, useDynamicListVariable } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { GroupList, GroupListLoading } from "~/view/components";

// get groups that the user is an admin of
const GroupManageBase: Preact.FunctionComponent<RoutePageProps> = () => {
  const user = useAppUser();
  const [list, dispatch] = useDynamicListVariable<Group>(null);

  const manage_filter = useMemo(
    () =>
      user?.id
        ? ([["=", ["this", "Group:has_admin"], ["id", user.id]]] as const)
        : undefined,
    [user?.id]
  );
  const fetchGroups = useFetchGroups(user, manage_filter, undefined);

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem) => (
      <GroupList
        key="manage"
        groups={result}
        loadMoreElem={load_more_elem}
        showLatestActivity={true}
      />
    ),
    [],
    fetchGroups,
    undefined,
    { PendingView: GroupListLoading, limit: 15 }
  );
};

export const GroupManage = asSubroute(GroupManageBase);
