import * as Preact from "preact";
import {
  ButtonWithIcon,
  Icon,
  Popover,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import { THEME_COLORS } from "@thrive-web/ui-constants";
import { useId } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { useCallback, useRef } from "preact/hooks";

export const ColorPickerPopover: Preact.FunctionComponent<{
  onChange: (color: string) => void;
  value?: string;
}> = ({ onChange, value }) => {
  return (
    <div className="color-picker">
      <div className="color-picker__items">
        {THEME_COLORS.map(c => (
          <button
            type="button"
            className="color-picker__option"
            onClick={() => onChange(c)}
            data-selected={`${value === c}`}
          >
            <div
              className="color-picker__option__dot"
              style={{ backgroundColor: c }}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export const ColorPicker: Preact.FunctionComponent<
  {
    onChange: (color: string) => void;
    value?: string;
    popoverProps?: Omit<PopoverProps, "triggerComponent" | "show">;
  } & Omit<HTMLInputProps, "onChange" | "value" | "type">
> = ({ onChange, value, popoverProps, ...i_props }) => {
  const trigger = useRef<HTMLButtonElement>(null);
  const popoverSource = useRef<HTMLElement>(null);
  const getPopoverSource = useCallback(
    () => popoverSource.current,
    [popoverSource.current]
  );
  // id of the popover component
  const id = useId(i_props.id, "color-picker");
  const [show, setShow, { className, ...props }] = usePopoverTrigger(
    { hover: false, focus: false },
    id
  );
  const onChangeColor = useCallback(
    color => {
      setShow(false);
      onChange(color);
      // refocus the button after selection to return tab order
      trigger.current?.focus();
    },
    [setShow, onChange]
  );

  return (
    <Popover
      className="color-picker"
      {...popoverProps}
      triggerComponent={
        <Preact.Fragment>
          <ButtonWithIcon
            ref={trigger}
            disabled={i_props.disabled}
            {...props}
            className={`filled gray color-picker__button${maybeClassName(
              className
            )}`}
            side="left"
            icon={
              <Icon
                ref={popoverSource}
                name={value ? "blank-solid-circle" : "broken-circle"}
                style={value ? { color: value } : {}}
              />
            }
          >
            {i_props.placeholder || "Pick a Color"}
          </ButtonWithIcon>

          <div className="input__hidden">
            <input
              {...i_props}
              type="text"
              value={value}
              tabIndex={-1}
              onKeyPress={e => {
                e.preventDefault();
                // @ts-ignore
                e.target.value = value || "";
              }}
            />
          </div>
        </Preact.Fragment>
      }
      show={show}
      id={id}
      getSourceRef={getPopoverSource}
    >
      <ColorPickerPopover onChange={onChangeColor} value={value} />
    </Popover>
  );
};
