import * as Preact from "preact";
import { ConnectionMapped, Post, User } from "@thrive-web/ui-api";
import { RequestStatus } from "@thrive-web/ui-model";
import {
  ContextSpec,
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
  DynamicListStatusDefault,
  LocalDispatch,
} from "@thrive-web/ui-components";
import { createNamedContext } from "@thrive-web/ui-utils";

type UserLocalCtx = {
  user: User | null;
  connection: ConnectionMapped | null;
  coach_status: RequestStatus;
  coach_count: number;
  fetch: () => Promise<void>;
};

export const USER_USER = createNamedContext<null | User>(null, "UserRecord");

export const USER_CONNECTION = createNamedContext<null | ConnectionMapped>(
  null,
  "UserConnection"
);

export const USER_COACH_STATUS = createNamedContext<RequestStatus>(
  DynamicListStatusDefault,
  "UserCoachStatus"
);
export const USER_COACH_COUNT = createNamedContext<number>(
  -1,
  "UserCoachCount"
);

export const USER_DISPATCH = createNamedContext<LocalDispatch<UserLocalCtx>>(
  () => {},
  "UserDispatch"
);
export const USER_FETCH = createNamedContext<() => Promise<void>>(
  () => Promise.resolve(),
  "UserFetch"
);

export const USER_DETAIL_CONTEXTS: ContextSpec<UserLocalCtx> = {
  user: USER_USER,
  connection: USER_CONNECTION,
  coach_status: USER_COACH_STATUS,
  coach_count: USER_COACH_COUNT,
  dispatch: USER_DISPATCH,
  fetch: USER_FETCH,
};

export const USER_ACTIVITY: Preact.Context<
  DynamicListCtxSpec<Post, false, []>
> = createNamedContext(
  {
    list: null,
    dispatch: DynamicListDispatchDefault,
  },
  "UserActivity"
);

export const USER_COACHES: Preact.Context<
  DynamicListCtxSpec<ConnectionMapped, false, []>
> = createNamedContext(
  {
    list: null,
    dispatch: DynamicListDispatchDefault,
  },
  "UserCoaches"
);
