import * as Preact from "preact";
import {
  ButtonWithIcon,
  Popover,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import { useCallback } from "preact/hooks";
import { useId } from "@thrive-web/ui-hooks";

export const GroupTouchpointRemoveButton: Preact.FunctionComponent<{
  removeTouchpoint: () => void;
  hasExpense: boolean;
}> = ({ hasExpense, removeTouchpoint }) => {
  const id = useId("group-tp-remove-button");
  const [open, set_open, trigger_props] = usePopoverTrigger(
    { click: true, focus: false, hover: false },
    id
  );

  const onClickRemove = useCallback(() => {
    removeTouchpoint();
    set_open(false);
  }, [removeTouchpoint, set_open]);

  const button = (
    <ButtonWithIcon
      className="filled transparent all-gray"
      icon="remove"
      {...(hasExpense ? trigger_props : { onClick: removeTouchpoint })}
    />
  );

  if (!hasExpense) {
    return button;
  }

  return (
    <Popover id={id} triggerComponent={button} show={open} defaultOffset="left">
      <div className="group-touchpoint__remove-warning">
        <h5>
          Warning: Removing the Group Update from this post will also remove the
          associated Reimbursement. Do you want to continue?
        </h5>
        <div className="group-touchpoint__remove-warning__buttons">
          <button
            className="bordered"
            type="button"
            onClick={() => set_open(false)}
          >
            Cancel
          </button>
          <button className="filled gray" type="button" onClick={onClickRemove}>
            Continue
          </button>
        </div>
      </div>
    </Popover>
  );
};
