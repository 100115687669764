import { MetaTypes, Types } from "@thrive-web/core";
import { RecordType } from "@thrive-web/ui-api";
import { getCurrentUrl, route } from "preact-router";

export const ENTITY_TYPE_URL_MAP: {
  [K in keyof Types]:
    | string
    | ((record: RecordType<K>, guid: string, type: K) => string);
} = {
  Group: "groups",
  User: "people",
  TraitifyAssessment: "",
  AssessmentFeedbackInvitation: "",
  ExperienceCategory: "",
  Post: (post, guid, type) => {
    if (!post.posted_to) {
      return "#";
    } else if (
      /^\/$/.test(getCurrentUrl()) ||
      getCurrentUrl() === `/post/${guid}`
    ) {
      return `post/${guid}`;
    } else if (/^\/people\/[a-z0-9-]+/i.test(getCurrentUrl())) {
      const [, match] = getCurrentUrl().match(
        /^\/(people\/[a-z0-9-]+)(\/.*)?/i
      ) as RegExpMatchArray;
      return `${match}/post/${guid}`;
    }
    const posted_url = get_url_for_entity(post.posted_to);
    return `${posted_url.slice(1)}/activity/${guid}`;
  },
  Installation: "",
  Goal: "",
  GoalPreset: "",
  Todo: "",
  Community: "community",
  Connection: "",
  ConnectionRequest: "",
  Address: "",
  Event: "events",
  EventRSVP: "",
  Reaction: "",
  Comment: "",
  Notification: "",
  TouchpointAction: "",
  TouchpointAccomplishment: "",
  Touchpoint: "",
  Mood: "",
  ExpenseCategory: "",
  ExpenseReason: "",
  Expense: "reimbursements",
  Interaction: "",
  CapturedMood: "",
  GenderIdentity: "",
  EthnicIdentity: "",
  GroupIdentity: "",
  RaceIdentity: "",
  CustomIdentity: "",
  AwardedGroupPoints: "",
  RegistrationInvitation: "",
  Media: "",
  Conversation: "",
  Message: "",
  MessageReadReceipt: "",
  RelationshipFulfillmentSurvey: "",
  GroupInvitation: "",
  ConversationStarter: "",
  ConversationStarterQuestion: "",
};

export type RouteSegment =
  | {
      text: string;
      param: false;
    }
  | {
      text: string;
      param: true;
      optional: boolean;
    };

export type RouteMatches = {
  exact: boolean;
  matches: ObjectOf<string> | null;
};

export const split_path = (path: string): string[] =>
  path.replace(/^\/+|\/+$/i, "").split("/");

export const parse_path = (path: string): RouteSegment[] => {
  const segments_raw = split_path(path);
  return segments_raw.map(s => {
    let text = s;
    let param = false;
    if (s.startsWith(":")) {
      param = true;
      const optional = s.endsWith("?");
      text = s.replace(/^:|\?$/, "");
      return {
        text,
        param,
        optional,
      };
    }
    return {
      text,
      param,
    };
  });
};

/**
 * @param route {string} route pattern to match against
 * @param url {string} actual url pathname to process
 * @param exact_match_href {string} href against which to determine an exact match
 * @return { RouteMatches | null} returns RouteMatches if the path matches the route, otherwise returns null
 */
export const extract_route_matches = (
  route: string,
  url: string,
  exact_match_href?: string
): RouteMatches | null => {
  const route_segments = parse_path(route);
  const path_segments = split_path(url);
  let matched_param_count = 0;
  const matched_params: ObjectOf<string> = {};
  for (let i = 0; i < route_segments.length; i++) {
    const p_seg = path_segments[i];
    const r_seg = route_segments[i];
    if (!r_seg.param && r_seg.text !== p_seg) {
      /*console.debug(`Found missing path segment ${r_seg.text}, returning null.`, {
        route,
        url
      });*/
      return null;
    }
    if (r_seg.param) {
      if (!r_seg.optional && !p_seg) {
        /*console.debug(`Found missing required param ${r_seg.text}, returning null.`, {
          route,
          url
        });*/
        return null;
      } else if (p_seg) {
        matched_params[r_seg.text] = p_seg;
        matched_param_count++;
      }
    }
  }
  return {
    exact: exact_match_href === url,
    matches: matched_param_count === 0 ? null : matched_params,
  };
};

export const insert_route_matches = (
  route: string,
  matches: ObjectOf<string>
): string | null => {
  const route_segments = parse_path(route);
  const output_segments: string[] = [];
  for (let i = 0; i < route_segments.length; i++) {
    const seg = route_segments[i];
    if (!seg.param) {
      output_segments.push(seg.text);
    } else {
      const value = matches[seg.text];
      if (value) {
        output_segments.push(value);
      } else if (!seg.optional) {
        /*console.debug(
          `"matches" is missing non-optional path param ${seg.text}, cannot construct path.`,
          matches
        );*/
        return null;
      } else {
        /*console.debug(
          `Encountered missing optional path param ${seg.text}, stopping path construction.`,
          matches
        );*/
        break;
      }
    }
  }
  return `/${output_segments.join("/")}`;
};

// extracts the type and guid from an entity id IRI
// returns [guid, type]
export const get_guid_from_iri = (
  iri: string
): [string, keyof MetaTypes | ""] => {
  if (iri.startsWith("http")) {
    const match = iri.match(/.+\/(.+)\/(.+)$/i);
    if (match) {
      return [match[2], match[1] as keyof MetaTypes];
    }
  }
  return [iri, ""];
};

// gets the website url for a given entity
export const get_url_for_entity = <R extends { id?: string }>(
  entity: R,
  subpath: string = ""
): string => {
  if (!entity.id || !entity.id.startsWith("http")) {
    return "#";
  }
  const [guid, type] = get_guid_from_iri(entity.id);
  if (!type || !ENTITY_TYPE_URL_MAP[type]) {
    return "#";
  }

  if (typeof ENTITY_TYPE_URL_MAP[type] === "string") {
    return `/${ENTITY_TYPE_URL_MAP[type]}/${guid}${subpath}`;
  }
  // @ts-expect-error:
  return `/${ENTITY_TYPE_URL_MAP[type](entity, guid, type)}${subpath}`;
};

// route to the given url if it's not the same as the current url
export const maybe_route = (
  url: string | { url: string; replace?: boolean },
  replace?: boolean
) => {
  if (typeof url !== "string") {
    return route(url);
  } else if (url !== getCurrentUrl()) {
    return route(url, replace);
  }
};

export const make_title = (parts: string[]) =>
  parts.concat(["Thread"]).join(" | ");

export const set_title = (title: string) => {
  document.title = title;
  // window.history.replaceState(
  //   {},
  //   title
  // );
};
