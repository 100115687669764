import { ApiError } from "@thrive-web/core";

export const make_displayable_error = (error: any): DisplayableError => {
  if (!error) {
    return { code: -1, message: "An unknown error occurred" };
  }
  const json_errors = is_json_api_error(error)
    ? error
    : get_json_api_errors(error);
  if (json_errors) {
    return json_api_error_to_displayable(
      json_errors[0],
      error.status || error.statusCode
    );
  }
  return {
    http_status: error.status || error.statusCode,
    code: error.code,
    message:
      error.title ||
      error.message ||
      error.Message ||
      error?.response?.message ||
      error?.response?.Message ||
      error.toString(),
    raw: error,
  };
};

export const get_json_api_errors = (error: any): ApiError[] | void => {
  const errors = Array.isArray(error)
    ? error
    : Array.isArray(error.errors)
    ? error.errors
    : Array.isArray(error.response?.errors)
    ? error.response.errors
    : null;
  if (errors && !errors.some(e => !is_json_api_error(e))) {
    return errors;
  }
};

export const is_json_api_error = (error?: any): error is ApiError =>
  typeof error === "object" && "code" in error && "title" in error;

export const json_api_error_to_displayable = (
  error: ApiError,
  http_status?: number
): DisplayableError => {
  const { code, title, detail } = error;
  const status =
    http_status ||
    (error.status && !isNaN(parseInt(error.status))
      ? parseInt(error.status)
      : undefined);
  let message =
    title || detail
      ? `${status && code ? `${code}: ` : ""}${title || detail}`
      : `${code}`;
  return {
    http_status: status,
    code,
    message,
    raw: error,
  };
};
