import * as Preact from "preact";
import { PureComponent } from "preact/compat";
import { useCallback, useMemo } from "preact/hooks";

// container that unrenders its content but preserves its dimensions when out of view
export class LazyListSection extends PureComponent<
  {},
  { hidden: boolean; width?: number; height?: number; showing: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      showing: false,
    };
    this.container = Preact.createRef();
    this.observer = new IntersectionObserver(this.obsCallback, {
      rootMargin: "100px",
    });
  }
  observer: IntersectionObserver;
  container: Preact.RefObject<HTMLTableSectionElement>;

  componentDidMount() {
    if (this.container.current) {
      this.observer.observe(this.container.current);
      this.obsCallback(this.observer.takeRecords());
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  componentDidUpdate() {
    // set showing back to false on the next lifecycle after hidden is set to false
    if (this.state.showing) {
      this.setState({ showing: false });
    }
  }

  obsCallback = (entries: IntersectionObserverEntry[]) => {
    const { hidden, height } = this.state;
    const entry = entries[0];
    if (!entry) {
      return;
    }
    const new_state: any = {};
    if (!entry.isIntersecting !== hidden) {
      new_state.hidden = !entry.isIntersecting;
      // when hidden changes to false, it's being "revealed"
      new_state.showing = !new_state.hidden;
    }
    // store the height of the element while it's rendering its content
    if (!height || entry.boundingClientRect.height !== height) {
      new_state.height = entry.boundingClientRect.height;
    }
    if (Object.keys(new_state).length > 0) {
      this.setState(new_state);
    }
  };

  render() {
    const { hidden, height, showing } = this.state;
    return (
      <div
        className="lazy-list-section"
        ref={this.container}
        data-hidden={`${hidden}`}
        // keep the height set for 1 lifecycle after revealing to keep the layout from
        // shifting while the children are remounting
        style={showing ? { height: `${height}px` } : undefined}
      >
        {hidden ? (
          <div style={{ width: `100%`, height: `${height}px` }} />
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

// this is necessary so that the render function isn't responsible for
// assigning the `key` property to each list item
export class LazyListItem<T> extends PureComponent<{
  render: (item: T) => Preact.VNode | null;
  item: T;
}> {
  render() {
    const { render, item } = this.props;
    return render(item);
  }
}

export const useLazyList = <T extends object>(
  data: readonly T[],
  render: (item: T) => Preact.VNode | null,
  renderInputs: any[],
  sectionSize: number = 10,
  getKey: (item: T, index: number) => string | number = (_, i) => i
) => {
  const render_ = useCallback(render, renderInputs);
  return useMemo(() => {
    let index = 0;
    const groups: (Preact.VNode | null)[][] = [];
    while (index < data.length) {
      const group: (Preact.VNode | null)[] = [];
      for (let j = 0; j < sectionSize && index < data.length; j++, index++) {
        group.push(
          <LazyListItem
            key={getKey(data[index], index)}
            render={render_}
            item={data[index]}
          />
        );
      }
      groups.push(group);
    }
    return groups;
  }, [data, render_, getKey]);
};
