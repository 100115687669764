import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "preact/hooks";
import {
  ApiMethod,
  ApiMethodParameters,
  Connection,
  ConnectionMapped,
  ConnectionRequest,
  User,
} from "@thrive-web/ui-api";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { analytics, type_iri } from "@thrive-web/ui-common";
import { ConnectionRequestIncomingControls } from "~/view/components";
import {
  AvatarLoading,
  ButtonWithIcon,
  Card,
  DefaultModalContent,
  DefaultPendingView,
  DeleteModalBody,
  ErrorMessage,
  Icon,
  RequestButton,
  useAsyncRenderResult,
  UserListItem,
  PEOPLE_REQUESTS_OUTGOING,
} from "@thrive-web/ui-components";
import {
  preserveProps,
  useApiFetch,
  useApiMethod,
  useAppUser,
  useModal,
  usePreviousValue,
  useRequest,
  useStateIfMounted,
  useStateRef,
} from "@thrive-web/ui-hooks";

export const HomeSearchPeople: Preact.FunctionComponent<{
  users: readonly User[];
  fullList: boolean;
  setType: (str: string) => (e) => void;
  onUpdateUser: (user: User) => void;
  emptyView: Preact.VNode;
  loadMoreElem?: Preact.VNode | null;
}> = ({ users, fullList, setType, onUpdateUser, emptyView, loadMoreElem }) => {
  const list = useMemo(
    () => (fullList ? users : users.slice(0, 3)),
    [users, fullList]
  );

  const [modal_target, set_modal_target] = useStateIfMounted<User | null>(null);
  const prev_target = preserveProps(modal_target) || null;
  const on_close = useCallback(() => {
    setTimeout(() => {
      set_modal_target(null);
    }, MODAL_ANIMATION_DELAY);
  }, [set_modal_target]);

  const modal_props = useMemo(
    () => ({ user: modal_target || prev_target, onUpdateUser }),
    [modal_target || prev_target, onUpdateUser]
  );

  const [modal, set_open] = useModal(
    {
      id: "home-people-search-modal",
      className: "home-page__search__people__modal",
      body: HomeSearchPeopleItemModal,
      bodyProps: modal_props,
      giveTabFocus: true,
      overrideScroll: true,
      showCloseButton: true,
    },
    on_close
  );
  const open_modal = useCallback(
    (u: User) => {
      set_modal_target(u);
      set_open(true);
    },
    [set_open, set_modal_target]
  );

  return (
    <Card
      className={`home-page__search__people${
        list.length === 0 ? " home-page__search__people--empty" : ""
      }`}
    >
      <h3>People</h3>
      {list.length > 0 ? (
        <ul>
          {list.map(u => (
            <HomeSearchPeopleItem key={u.id} user={u} openModal={open_modal} />
          ))}
          {!fullList && (
            <li className="user-list__item home-page__search__people__more">
              <button onClick={setType("people")} className="plain-link">
                View More
              </button>
            </li>
          )}
          {fullList && loadMoreElem && (
            <li className="load-more">{loadMoreElem}</li>
          )}
        </ul>
      ) : (
        emptyView
      )}
      {modal}
    </Card>
  );
};

export const HomeSearchPeopleLoading: Preact.FunctionComponent = () => {
  const items = useMemo(
    () => new Array(4).fill(0).map(() => Math.round(Math.random() * 9) + 8),
    []
  );
  return (
    <Card className="home-page__search__people home-page__search__people--loading">
      <h3>People</h3>
      <ul>
        {items.map((width, i) => (
          <li key={i} className="user-list__item">
            <div className="user-list__item__left">
              <AvatarLoading size="sm" />
              <div className="user-list__item__name">
                <div
                  className="loading-item__text loading-item__shaded loading-item__name"
                  style={`width: ${width}rem`}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export const HomeSearchPeopleItem: Preact.FunctionComponent<{
  user: User;
  openModal: (user: User) => void;
}> = ({ user, openModal }) => {
  const self = useAppUser();

  const is_connected = useMemo(
    () =>
      user.has_connection?.some(
        (conn: Connection) =>
          conn.users?.some(u => u.id === self?.id) ||
          self?.has_connection?.some(
            (c: Connection) =>
              c.id === conn.id || c.users?.some(u_ => u_.id === user.id)
          )
      ),
    [user, self]
  );

  if (!self) {
    return null;
  }

  if (is_connected) {
    return (
      <UserListItem user={user} linkBox="all">
        <Icon name="chevron-right" />
      </UserListItem>
    );
  }

  return (
    <UserListItem user={user} linkBox="content">
      <ButtonWithIcon
        icon="connection-request"
        className="filled gray"
        onClick={() => openModal(user)}
      />
    </UserListItem>
  );
};

export const HomeSearchPeopleItemModal: Preact.FunctionComponent<
  ModalBodyProps & { user: User | null; onUpdateUser: (user: User) => void }
> = ({ user, onUpdateUser, open, closeButton, children, dismiss }) => {
  const self = useAppUser();
  const [, set_req, req_ref] = useStateRef<ConnectionRequest | undefined>(
    undefined
  );

  const getConnectionReq = useApiMethod("getConnectionRequests");
  const getAndStoreConnection = useCallback(
    (...args) => getConnectionReq(...args).then(res => set_req(res.data[0])),
    [getConnectionReq, set_req]
  );
  const [getConnection, status] = useRequest(getAndStoreConnection);
  const { pending } = status;

  const onUpdate = useCallback(
    (user_: User, request: ConnectionRequest | undefined) => {
      set_req(request);
      onUpdateUser(user_);
    },
    [set_req, onUpdateUser]
  );

  const is_connected = useMemo(
    () =>
      user?.has_connection?.some(
        (conn: Connection) =>
          conn.users?.some(u => u.id === self?.id) ||
          self?.has_connection?.some(
            (c: Connection) =>
              c.id === conn.id || c.users?.some(u_ => u_.id === user.id)
          )
      ),
    [user, self]
  );

  // check for existing connection request
  useEffect(() => {
    if (!user || !self || is_connected || pending) {
      return;
    }
    getConnection({
      query: {
        filter: [
          [
            "or",
            [
              "and",
              ["=", ["this", "ConnectionRequest:sender"], ["id", user.id]],
              ["=", ["this", "ConnectionRequest:recipient"], ["id", self.id]],
            ],
            [
              "and",
              ["=", ["this", "ConnectionRequest:sender"], ["id", self.id]],
              ["=", ["this", "ConnectionRequest:recipient"], ["id", user.id]],
            ],
          ],
        ],
      },
    });
  }, [user, self, is_connected]);

  const body_props = useMemo<HomeSearchPeopleItemModalBaseProps>(
    () => ({ open, dismiss, closeButton, user, onUpdate }),
    [open, dismiss, closeButton, user, onUpdate]
  );
  const prev_props = usePreviousValue(body_props);
  const props = open ? body_props : prev_props.current || body_props;

  return useAsyncRenderResult(
    result => <HomeSearchPeopleItemModalBase request={result} {...props} />,
    [props],
    status,
    req_ref.current,
    false,
    undefined,
    ModalLoading,
    true
  );
};

const ModalLoading = () => (
  <div className="modal__body modal__loading">
    <DefaultPendingView />
  </div>
);

type HomeSearchPeopleItemModalBaseProps = ModalBodyProps & {
  user: User | null;
  onUpdate: (user: User, request: ConnectionRequest | undefined) => void;
};

export const HomeSearchPeopleItemModalBase: Preact.FunctionComponent<
  HomeSearchPeopleItemModalBaseProps & {
    request?: ConnectionRequest;
  }
> = ({ user, request, onUpdate, dismiss, ...props }) => {
  const self = useAppUser();
  const { dispatch } = useContext(PEOPLE_REQUESTS_OUTGOING);

  // determine request method and args based on existence and direction of conn request
  const [method, args] = useMemo(<T extends ApiMethod>(): [
    T,
    ApiMethodParameters<T>
  ] => {
    if (!self || !user) {
      // @ts-expect-error:
      return ["", []];
    }
    // respond to conn req
    if (request?.recipient?.id === self.id) {
      return [
        "acceptConnectionRequest" as T,
        [
          request.id,
          {
            body: { data: { attributes: { accepted: true } } },
          },
        ] as ApiMethodParameters<T>,
      ];
    }

    // cancel outgoing req
    if (request?.sender?.id === self.id) {
      return [
        "declineConnectionRequest" as T,
        [request.id] as ApiMethodParameters<T>,
      ];
    }

    // create request
    return [
      "createConnectionRequest" as T,
      [
        {
          body: {
            data: {
              relationships: {
                sender: { data: { id: self.id } },
                recipient: { data: { id: user.id } },
              },
            },
          },
        },
      ] as ApiMethodParameters<T>,
    ];
  }, [self?.id, user?.id, request]);

  useLayoutEffect(() => {
    if (!self || !method || !user) {
      dismiss();
    }
  }, [self, method, user]);

  const onFinishRequest = useCallback(
    ({ data }: { data: ConnectionRequest | null }) => {
      // upon accepting conn request, create a conn record using the data we have
      // locally
      if (data?.accepted) {
        analytics.log_event(analytics.EVENTS.connection_request_accepted);
        const temp_conn: ConnectionMapped = {
          id: "",
          type: type_iri("Connection"),
          // @ts-expect-error:
          users: [data.recipient, data.sender],
          // @ts-expect-error:
          other_user: data.sender,
          connection_request: data,
          created_at: new Date().toISOString(),
        };
        // @ts-expect-error:
        user?.has_connection?.push(temp_conn);
      } else if (method === "declineConnectionRequest") {
        analytics.log_event(analytics.EVENTS.connection_request_canceled);
        dispatch.remove(c => c.id === request?.id);
      } else if (data) {
        analytics.log_event(analytics.EVENTS.connection_request_sent);
      }

      setTimeout(() => {
        dismiss();
        setTimeout(() => {
          onUpdate(user as User, data || undefined);
        }, MODAL_ANIMATION_DELAY);
      }, 800);
      return Promise.resolve();
    },
    [dismiss, onUpdate, user, method, dispatch]
  );

  // @ts-expect-error:
  const sendRequestRaw = useApiFetch(method, ...args);
  const sendRequest = useCallback(() => {
    return sendRequestRaw().then(onFinishRequest);
  }, [onFinishRequest]);
  const [sendRequestTracked, { pending, success, error }] =
    useRequest(sendRequest);

  const after_cancel = useCallback(() => {
    analytics.log_event(analytics.EVENTS.connection_request_canceled);
  }, []);

  if (!method || !self || !user) {
    return null;
  }

  if (method === "createConnectionRequest") {
    return (
      <DefaultModalContent
        title="Make a Connection"
        closeButton={props.closeButton}
      >
        <div className="modal__body">
          Send a connection request to {user.full_name}?
        </div>
        <div className="modal__footer">
          <button className="filled gray" onClick={dismiss}>
            Cancel
          </button>
          <RequestButton
            pending={pending}
            success={success}
            successText="Success!"
            className="filled"
            onClick={sendRequestTracked}
          >
            Confirm
          </RequestButton>
        </div>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </DefaultModalContent>
    );
  } else if (request && method === "acceptConnectionRequest") {
    return (
      <DefaultModalContent
        title="Make a Connection"
        closeButton={props.closeButton}
      >
        <div className="modal__body">
          {user.full_name} has sent you a connection request!
        </div>
        <div className="modal__footer" data-buttons="approve">
          <ConnectionRequestIncomingControls
            request={request}
            onComplete={onFinishRequest}
          />
        </div>
      </DefaultModalContent>
    );
  } else if (request && method === "declineConnectionRequest") {
    return (
      <DeleteModalBody
        dismiss={dismiss}
        deleteRecord={sendRequestRaw}
        afterDelete={after_cancel}
        {...props}
      >
        Are you sure you want to delete your connection request to{" "}
        {user.full_name}?
      </DeleteModalBody>
    );
  }

  return null;
};
