import { DocBase } from "@thrive-web/core";
import * as Preact from "preact";
import { comparator } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  DynamicListDispatch,
  PillCheckList,
  Tooltip,
  useRenderDynamicListWithPagedFetch,
  ViewMoreLoader,
} from "@thrive-web/ui-components";
import { useCallback, useMemo } from "preact/hooks";
import { Goal, TouchpointAccomplishment } from "@thrive-web/ui-api";
import { useRecordIdMap } from "@thrive-web/ui-hooks";
import { join_string_list } from "@thrive-web/ui-utils";
import { RequestStatus } from "@thrive-web/ui-model";

interface GoalsProps {
  isLastPage: boolean;
  accomplishments: readonly TouchpointAccomplishment[];
  goals: readonly Goal[];
  options: readonly Goal[] | null;
  optionsStatus: RequestStatus;
  setOptions: DynamicListDispatch<Goal, false, []>;
  fetchOptions: (
    offset: number,
    limit?: number
  ) => Promise<DocBase & { data: Goal[] }>;
  onSelectGoal: (goal: Goal, selected: boolean) => void;
  goToCreateGoal: () => void;
  nextStep: () => void;
  prevStep: () => void;
}

const map_goal_to_option = goal => ({ value: goal.id, label: goal.name });

export const GroupTouchpointGoals: Preact.FunctionComponent<GoalsProps> = ({
  isLastPage,
  accomplishments,
  goals,
  options,
  optionsStatus,
  setOptions,
  fetchOptions,
  onSelectGoal,
  goToCreateGoal,
  nextStep,
  prevStep,
}) => {
  const goals_map = useRecordIdMap(options || []);

  const onChangeGoals = useCallback(
    (opt: InputOption<string>, checked: boolean) =>
      onSelectGoal(goals_map[opt.value], checked),
    [onSelectGoal, goals_map]
  );

  const values = useMemo(() => goals.map(g => g.id), [goals]);
  const prompt = useMemo(() => {
    const strings = accomplishments
      .slice()
      // @ts-expect-error:
      .sort(comparator("display_rank"))
      .map(acc => (acc.title || "").toLowerCase());
    return join_string_list(strings);
  }, [accomplishments]);

  const stepComplete = goals.length > 0;

  const passthrough = useMemo(
    () => ({ onSelectOption: onChangeGoals, values } as const),
    [values, onChangeGoals]
  );
  const content = useRenderDynamicListWithPagedFetch(
    options,
    setOptions,
    (options_, load_more_elem, pending, passthrough) => (
      <PillCheckList
        className="stack__scrolling-content"
        options={options_}
        loadMoreElem={load_more_elem}
        mapToOption={map_goal_to_option}
        {...passthrough!}
      />
    ),
    [],
    fetchOptions,
    passthrough,
    { limit: 10, LoadMoreComponent: ViewMoreLoader }
  );

  const options_fetched =
    options && !optionsStatus.pending && optionsStatus.success;
  return (
    <div className="group-touchpoint__section group-touchpoint__goals">
      <div className="modal-form__section form__section stack">
        <div className="form__section-header">Which group goal?</div>
        <div>We {prompt} of...</div>
        {!options_fetched || (options && options.length > 0) ? (
          content
        ) : (
          <div className="group-touchpoint__goals__empty">
            <h6>
              Looks like you don't have any group goals. Would you like to
              create a goal?
            </h6>
          </div>
        )}
        <div className="modal-form__section group-touchpoint__goals__create">
          <ButtonWithIcon
            icon="add"
            side="left"
            // @ts-expect-error:
            className={`filled${options?.length > 0 ? " gray" : ""}`}
            onClick={goToCreateGoal}
          >
            Create a New Goal
          </ButtonWithIcon>
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <ButtonWithIcon
            className="filled gray"
            onClick={prevStep}
            icon="chevron-left"
            side="left"
          >
            Back
          </ButtonWithIcon>
        </div>
        <div className="modal__footer__right">
          <Tooltip
            text="Please select at least one goal."
            disabled={stepComplete}
            defaultDirection="top"
            defaultOffset="left"
          >
            <ButtonWithIcon
              className="filled gray"
              onClick={stepComplete ? nextStep : undefined}
              icon={isLastPage ? "checked" : "chevron-right"}
              side="right"
            >
              {isLastPage ? "Finish" : "Next"}
            </ButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
