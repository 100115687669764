import * as Preact from "preact";
import { ButtonWithIcon } from "@thrive-web/ui-components";

export const RegisterGetStarted: Preact.FunctionComponent<{
  nextPage: () => void;
}> = ({ nextPage }) => (
  <div className="form__section register__form__section" data-part="splash">
    <h1>Join the Thread App</h1>
    <p>In just a few steps we will have you connected!</p>
    <ButtonWithIcon
      icon="logo"
      side="left"
      className="filled full-width"
      onClick={() => nextPage()}
    >
      Get Started
    </ButtonWithIcon>
  </div>
);
