import * as Preact from "preact";
import { PureComponent } from "preact/compat";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Wrapper for react-image-crop component
export class ImageCrop extends PureComponent<
  {
    initialImage: FileUploadData;
    onChange: (data: FileUploadData) => void;
  },
  { crop: ReactCrop.Crop; pixelCrop?: any; croppedImage?: FileUploadData }
> {
  constructor(props) {
    super(props);
    this.state = {
      crop: {
        aspect: 1,
        unit: "%",
      },
    };
    this.canvas = document.createElement("canvas");
    this.component = Preact.createRef();
    this.timer = null;
  }
  initial?: ReactCrop.Crop;
  canvas: HTMLCanvasElement;
  component: Preact.RefObject<ReactCrop>;
  timer: any;

  // when the crop box is changed
  onChange = (crop, pixelCrop) => {
    const { initial } = this;
    this.initial = undefined;
    this.setState({
      crop: initial || crop,
      pixelCrop,
      croppedImage: initial ? undefined : this.state.croppedImage,
    });
    if (this.timer) {
      clearTimeout(this.timer);
    }
    // debounce the cropping operation
    this.timer = setTimeout(() => {
      this.getCroppedImage(pixelCrop).then(data => {
        this.setState({
          croppedImage: data,
        });
        this.props.onChange(data);
        this.timer = null;
      });
    }, 200);
  };

  onImageLoad = (image: HTMLImageElement) => {
    image.setAttribute("draggable", "false");
    // get the smaller of the image's dimensions
    const min = Math.min(image.naturalHeight, image.naturalWidth);
    const crop: any = {};
    // set the initial crop to be 80% of the natural image min dimension
    if (image.naturalWidth > image.naturalHeight) {
      crop.height = (min / image.naturalHeight) * 80;
    } else {
      crop.width = (min / image.naturalWidth) * 80;
    }
    const size = min * 0.8;

    this.initial = {
      ...crop,
      aspect: 1,
      x: ((image.naturalWidth - size) / 2 / image.naturalWidth) * 100,
      y: ((image.naturalHeight - size) / 2 / image.naturalHeight) * 100,
      unit: "%",
    };
  };

  getCroppedImage = (percentCrop): Promise<FileUploadData> =>
    new Promise<FileUploadData>((resolve, reject) => {
      const { mime } = this.props.initialImage;
      if (!percentCrop) {
        return reject(`No crop set.`);
      }
      // @ts-expect-error:
      const img_elem = this.component.current?.imageRef as HTMLImageElement;
      if (!img_elem) {
        return reject(`Could not get ref to img element.`);
      }
      const scale_x = img_elem.naturalWidth / 100;
      const scale_y = img_elem.naturalHeight / 100;
      const pixelCrop = {
        ...percentCrop,
        x: percentCrop.x * scale_x,
        y: percentCrop.y * scale_y,
        width: percentCrop.width * scale_x,
        height: percentCrop.height * scale_y,
      };

      // https://www.npmjs.com/package/react-image-crop/v/6.0.7#what-about-showing-the-crop-on-the-client
      this.canvas.width = pixelCrop.width;
      this.canvas.height = pixelCrop.height;
      const ctx = this.canvas.getContext("2d");
      if (!ctx) {
        return reject(`Could not get canvas ctx.`);
      }

      ctx.drawImage(
        img_elem,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
      this.canvas.toBlob(cropped => {
        if (!cropped) {
          return reject(`Could not get cropped image data`);
        }

        const reader = new FileReader();
        reader.onload = ev => {
          if (ev.target) {
            resolve({
              ...this.props.initialImage,
              data: ev.target["result"] as string,
            });
          }
        };
        reader.readAsDataURL(cropped);
      }, mime || "image/jpeg");
    }).catch(err => {
      console.warn(`Failed to get cropped image:`, err);
      return this.state.croppedImage || this.props.initialImage;
    });

  render() {
    return (
      <ReactCrop
        src={this.props.initialImage.data}
        crop={this.state.crop}
        onChange={this.onChange}
        onImageLoaded={this.onImageLoad}
        keepSelection={true}
        // @ts-expect-error:
        ref={this.component}
      />
    );
  }
}
