import * as Preact from "preact";
import {
  DefaultModalContent,
  ErrorMessage,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import { useCallback } from "preact/hooks";
import { useRequest } from "@thrive-web/ui-hooks";
import { analytics, make_displayable_error } from "@thrive-web/ui-common";

// modal for cancelling an event
export const EventCancelModalBody: Preact.FunctionComponent<
  ModalBodyProps & {
    cancelEvent: () => Promise<{ meta?: any } | void>;
    afterCancel?: () => void;
  }
> = ({ dismiss, cancelEvent, afterCancel, closeButton, children }) => {
  const [sendRequest, { pending, success, error }] = useRequest(cancelEvent);
  const onConfirm = useCallback(() => {
    sendRequest().then(() => {
      analytics.log_event(analytics.EVENTS.event_cancel);
      setTimeout(() => {
        dismiss();
        afterCancel &&
          setTimeout(() => {
            afterCancel();
          }, 100);
      }, 1000);
    });
  }, [sendRequest, dismiss, afterCancel]);
  return (
    <DefaultModalContent
      title="Cancel Event"
      closeButton={closeButton}
      footer={
        <div className="modal__footer event-cancel__footer">
          <div className="modal__footer__left">
            <button className="filled gray" onClick={dismiss}>
              Back
            </button>
          </div>
          <div className="modal__footer__right">
            {error && (
              <ErrorMessage>
                {make_displayable_error(error).message}
              </ErrorMessage>
            )}
            <RequestButtonWithIcon
              className="filled negative"
              pending={pending}
              success={success}
              successText="Success!"
              icon="remove-solid-circle"
              side="left"
              onClick={onConfirm}
            >
              Confirm
            </RequestButtonWithIcon>
          </div>
        </div>
      }
    >
      <div className="modal__delete-record">
        <p>Are you sure you want to cancel this event?</p>
      </div>
    </DefaultModalContent>
  );
};
