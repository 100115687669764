import * as Preact from "preact";
import { Group, User } from "@thrive-web/ui-api";
import { GroupList, GroupListLoading } from "~/view/components";
import {
  asSubroute,
  PageBody,
  useRenderDynamicListWithPagedFetch,
  useFetchGroups,
  NotFoundPage,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useDocumentTitle,
  useDynamicListVariable,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

export const UserGroupsPage: Preact.FunctionComponent<
  RoutePageProps & { user: User | null }
> = ({ user }) => {
  const self = useAppUser();
  useDocumentTitle(
    () =>
      make_title(
        self?.id && user?.id === self?.id
          ? ["Your Profile"]
          : user?.first_name
          ? [`${user.first_name} ${user.last_name}'s Groups`]
          : []
      ),
    [user?.first_name]
  );

  const fetchGroups = useFetchGroups(user);
  const [list, dispatch] = useDynamicListVariable<Group>([]);

  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem) => (
      <GroupList groups={result} loadMoreElem={load_more_elem} links={true} />
    ),
    [],
    fetchGroups,
    undefined,
    { PendingView: GroupListLoading, limit: 15 }
  );

  return (
    <PageBody>
      <div className="user-profile__groups">
        {self?.is_adult ? (
          user ? (
            content
          ) : (
            <GroupListLoading />
          )
        ) : (
          <NotFoundPage />
        )}
      </div>
    </PageBody>
  );
};

export const UserGroups = asSubroute(UserGroupsPage);
