import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const PageHeader: Preact.FunctionComponent<
  {
    title?: string | Preact.VNode;
    subtitle?: string | Preact.VNode;
    icon?: string | Preact.VNode;
    button?: Preact.VNode;
    breadcrumb?: string | Preact.VNode;
    withTabmenu?: boolean;
  } & MaybeClass
> = ({
  title,
  subtitle,
  icon,
  button,
  className,
  breadcrumb,
  withTabmenu,
  children,
}) => (
  <header className={`page-header${withTabmenu ? " with-tabmenu" : ""}`}>
    <div
      className={`page-header__container fake-margins${maybeClassName(
        className
      )}`}
    >
      <div className="page-header__content">
        {breadcrumb && (
          <div className="page-header__content__breadcrumb">{breadcrumb}</div>
        )}
        {(title || subtitle || button) && (
          <div className="page-header__content__main">
            {(title || subtitle) && (
              <div className="page-header__left">
                {icon && <div className="page-header__icon">{icon}</div>}
                <div className="page-header__text">
                  {title && <div className="page-header__title">{title}</div>}
                  {subtitle && (
                    <div className="page-header__subtitle">{subtitle}</div>
                  )}
                </div>
              </div>
            )}
            {button && <div className="page-header__right">{button}</div>}
          </div>
        )}
        {children && (
          <div className="page-header__content__children">{children}</div>
        )}
      </div>
    </div>
  </header>
);
