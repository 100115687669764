import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { ApiMethodParameters } from "@thrive-web/core";
import { Event } from "@thrive-web/ui-api";
import { EVENTS_CREATED } from "~/view/components";
import {
  asSubroute,
  ButtonWithIcon,
  EmptyList,
  EventList,
  EventListLoading,
  useRenderDynamicListWithPagedFetch,
  EVENTS,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useAppUser,
  useDocumentTitle,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

type EventsListProps = RoutePageProps & {
  openCreateModal: () => void;
  setShareTarget: (target: Event) => void;
};

// list of events that the user is attending (rsvp = "going" or "maybe")
const EventsListAttending: Preact.FunctionComponent<EventsListProps> = ({
  openCreateModal,
  setShareTarget,
}) => {
  useDocumentTitle(() => make_title(["Events"]), []);

  const user = useAppUser();
  const { list, dispatch } = useContext(EVENTS);

  const event_params = useMemo<ApiMethodParameters<"GET", "Event">>(
    () => ({
      query: {
        filter: [
          [
            "or",
            [
              "and",
              [
                "=",
                [
                  "this",
                  ["/", ["^", "EventRSVP:event"], "EventRSVP:created_by"],
                ],
                ["id", `${user!.id as string}`],
              ],
              /*[
                "in",
                ["this", ["/", ["^", "EventRSVP:event"], "EventRSVP:response"]],
                ["going", "maybe"],
              ],*/
            ],
            ["=", ["this", "Event:has_invitee"], ["id", user!.id as string]],
          ],
          [">=", ["this", "Event:date_time"], new Date().toISOString()],
        ],
        include: [
          "has_invitee.User:profile_picture",
          "location",
          "cover_image",
        ],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
        sort: [{ by: "date_time", dir: "asc" }],
      },
    }),
    []
  );
  const fetch_events = useApiFetchPaged("getEvents", event_params);

  const passthrough_props = useMemo(
    () => ({
      onClickShare: setShareTarget,
      emptyListView: (
        <EmptyList className="event-list__empty">
          <p>
            You currently have no events. Click the button below to create one.
          </p>
          <ButtonWithIcon
            icon="add"
            side="left"
            className="filled gray"
            onClick={openCreateModal}
          >
            Create New Event
          </ButtonWithIcon>
        </EmptyList>
      ),
    }),
    [openCreateModal]
  );

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem, pending, passthrough) => (
      <EventList
        events={result}
        loadMoreElem={load_more_elem}
        {...passthrough}
      />
    ),
    [],
    fetch_events,
    passthrough_props,
    { PendingView: EventListLoading, limit: 10 }
  );
};

export const EventsListAttendingPage =
  asSubroute<EventsListProps>(EventsListAttending);

// events that the user has created
const EventsListCreated: Preact.FunctionComponent<EventsListProps> = ({
  openCreateModal,
  setShareTarget,
}) => {
  useDocumentTitle(() => make_title(["Events"]), []);

  const user = useAppUser();
  const { list, dispatch } = useContext(EVENTS_CREATED);

  const event_params = useMemo<ApiMethodParameters<"GET", "Event">>(
    () => ({
      query: {
        filter: [
          ["=", ["this", "Event:created_by"], ["id", `${user!.id as string}`]],
        ],
        include: [
          "has_invitee.User:profile_picture",
          "location",
          "cover_image",
        ],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
        sort: [{ by: "date_time", dir: "asc" }],
      },
    }),
    []
  );
  const fetch_events = useApiFetchPaged("getEvents", event_params);

  const passthrough_props = useMemo(
    () => ({
      onClickShare: setShareTarget,
      emptyListView: (
        <EmptyList className="event-list__empty">
          <p>
            You currently have no events. Click the button below to create one.
          </p>
          <ButtonWithIcon
            icon="add"
            side="left"
            className="filled gray"
            onClick={openCreateModal}
          >
            Create New Event
          </ButtonWithIcon>
        </EmptyList>
      ),
    }),
    [openCreateModal]
  );

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem, pending, passthrough) => (
      <EventList
        events={result}
        loadMoreElem={load_more_elem}
        {...passthrough}
      />
    ),
    [],
    fetch_events,
    passthrough_props,
    { PendingView: EventListLoading, limit: 10 }
  );
};

export const EventsListCreatedPage = asSubroute(EventsListCreated);
