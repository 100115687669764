import { DocBase } from "@thrive-web/core";
import {
  Connection,
  ConnectionMapped,
  MappedApiResponse,
  User,
} from "@thrive-web/ui-api";
import { connection_search_filter } from "@thrive-web/ui-components";
import {
  useApiMethod,
  useStateIfMounted,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { useCallback } from "preact/hooks";

// given a list of conns and a list of users, output a list of conns, each with an
// additional property `other_user`
const map_conns_and_users = (
  conns: Connection[],
  users_res: MappedApiResponse<"getUsers">
) =>
  new Promise<DocBase & { data: ConnectionMapped[] }>((resolve, reject) => {
    const { data, ...result } = users_res;
    const mapped: ConnectionMapped[] = data.map(u => {
      const this_conn = conns.find(c => c.users?.find(u_ => u_.id === u.id));
      if (!this_conn) {
        reject("Failed to map users to connections");
      }
      return {
        ...(this_conn || {}),
        other_user: { ...u, has_connection: undefined },
      } as ConnectionMapped;
    });
    return resolve({ data: mapped, ...result });
  });

export const useConnectionList = (user: User | null, search: string = "") => {
  const get_self_connections = useApiMethod("getUser");
  const get_users = useApiMethod("getUsers");
  const [self_connections, set_self_conns] = useStateIfMounted<
    Connection[] | null
  >(null);
  const conns_ref = useValueRef(self_connections);

  // get full list of user connections by getting self and including `has_connection`
  const fetch_self_conns = useCallback(
    (refetch?: boolean): Promise<Connection[] | null> => {
      if (!user) {
        return Promise.reject("Not logged in?");
      }
      if (!refetch && conns_ref.current && conns_ref.current.length > 0) {
        return Promise.resolve(null);
      }
      return get_self_connections(user.id, {
        query: { include: ["has_connection", "profile_picture"] },
      }).then(({ data }) => (data.has_connection as Connection[]) || null);
    },
    [user?.id]
  );

  return useCallback(
    (
      offset: number,
      limit?: number
    ): Promise<DocBase & { data: ConnectionMapped[] }> => {
      // get self conns, and get users that are connected with self
      return Promise.all([
        fetch_self_conns(),
        get_users({
          query: {
            filter: connection_search_filter(user, search),
            sort: [{ by: "created_at", dir: "desc" }],
            include_count: true,
            offset,
            limit,
          },
        }),
      ] as const).then(([conns, users_res]) => {
        if (conns) {
          set_self_conns(conns);
        } else if (!conns_ref.current) {
          return Promise.reject("Failed to fetch self connections");
        }
        // map conns with fetched users
        const self_conns = (conns || conns_ref.current) as Connection[];
        return map_conns_and_users(self_conns, users_res).catch(() =>
          fetch_self_conns(true).then(conns_2 => {
            if (!conns_2) {
              return Promise.reject("Failed to fetch self connections");
            }
            set_self_conns(conns_2);
            return map_conns_and_users(conns_2, users_res);
          })
        );
      });
    },
    [fetch_self_conns, user?.id, search]
  );
};
