import * as Preact from "preact";
import { Group } from "@thrive-web/ui-api";
import { GroupListItem, GroupListItemLoading } from "~/view/components";
import { LazyListSection, useLazyList } from "@thrive-web/ui-components";
import { useMemo } from "preact/hooks";

export const GroupList: Preact.FunctionComponent<{
  groups: readonly Group[];
  links?: boolean;
  showLatestActivity?: boolean;
  loadMoreElem?: Preact.VNode | null;
}> = ({ groups, loadMoreElem, links = true, showLatestActivity }) => {
  const content = useLazyList(
    groups,
    group => (
      <GroupListItem
        key={group.id}
        group={group}
        isLink={!!links}
        showLatestActivity={showLatestActivity}
      />
    ),
    [],
    15
  );
  return (
    <div className="group-list">
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && <div className="load-more">{loadMoreElem}</div>}
    </div>
  );
};

export const GroupListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <div className="group-list loading-item__list">
      {items.map((_, i) => (
        <GroupListItemLoading key={i} />
      ))}
    </div>
  );
};
