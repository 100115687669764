import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { route } from "preact-router";
import { media_url } from "@thrive-web/core";
import { EntityLink, LoadingParagraph } from "@thrive-web/ui-components";
import { Community } from "@thrive-web/ui-api";
import { IMAGES_PREFIX, ScreenSize } from "@thrive-web/ui-constants";
import {
  useCallbackRef,
  useMedia,
  useSingleClickDetection,
} from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  cache_record,
  display_text,
  get_url_for_entity,
} from "@thrive-web/ui-utils";

export const CommunityCard: Preact.FunctionComponent<{
  community: Community;
}> = ({ community }) => {
  /*const member_count = (community.has_group || []).reduce(
    (total, group) =>
      total +
      // @ts-expect-error:
      (group.has_member || []).length,
    0
  );
  const group_count = (community.has_group || []).length;*/

  const on_click_link = useCallbackRef(() => {
    cache_record(community, true);
    route(get_url_for_entity(community));
  }, [community]);

  const link_listeners = useSingleClickDetection(on_click_link);

  const window_size = useContext(CONTEXTS.window_size);
  const cover_url = useMedia<"Community", "cover_image">(
    community,
    "cover_image",
    window_size > ScreenSize.md ? 2 : 1,
    window_size
  );
  const avatar_url = useMemo(
    () =>
      media_url<"Community", "avatar_image">(
        community,
        "avatar_image",
        "small"
      ),
    [community]
  );

  return (
    <div className="card community-card card--with-banner">
      <EntityLink
        entity={community}
        className="community-card__header card__banner"
        style={{
          backgroundImage: cover_url ? `url(${cover_url})` : undefined,
          borderBottomColor: `${community.accent_color}`,
        }}
      >
        <div className="community-card__avatar">
          {avatar_url ? (
            <img src={avatar_url} />
          ) : (
            <img
              className="community-card__avatar__placeholder"
              src={`${IMAGES_PREFIX}/icon-groups.svg`}
            />
          )}
        </div>
        <h3>{display_text(community.name)}</h3>
      </EntityLink>
      <div {...link_listeners} className="community-card__description">
        <span className="community-card__description__text">
          {display_text(community.purpose)}
        </span>
      </div>
      {/*`<div className="community-card__bottom">
        <DivWithIcon className="community-card__stat" side="left" icon="people">
          {member_count} Member{member_count !== 1 ? "s" : ""}
        </DivWithIcon>
        <DivWithIcon className="community-card__stat" side="left" icon="family">
          {group_count} Group{group_count !== 1 ? "s" : ""}
        </DivWithIcon>
      </div>*/}
    </div>
  );
};

export const CommunityCardLoading: Preact.FunctionComponent = () => {
  return (
    <div className="card community-card card--with-banner">
      <div className="community-card__header card__banner">
        <div className="community-card__avatar loading-item__shaded">?</div>
        <h3 className="loading-item__header loading-item__shaded" />
      </div>
      <div className="community-card__description">
        <LoadingParagraph maxLines={3} />
      </div>
      {/* <div className="community-card__bottom">
        <DivWithIcon className="community-card__stat" side="left" icon="people">
          <div className="loading-item__text loading-item__shaded" />
        </DivWithIcon>
        <DivWithIcon className="community-card__stat" side="left" icon="family">
          <div className="loading-item__text loading-item__shaded" />
        </DivWithIcon>
      </div>*/}
    </div>
  );
};
