import * as Preact from "preact";
import { User } from "@thrive-web/ui-api";
import {
  useAppUser,
  useRenderPropsFunction,
  useRequest,
  useStateIfMounted,
  useStateObject,
} from "@thrive-web/ui-hooks";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import {
  ButtonWithIcon,
  DefaultPendingView,
  Icon,
  OptionsList,
  Popover,
  SearchBar,
  UserListItem,
  UserSearchFn,
} from "@thrive-web/ui-components";

// dropdown/popover style input with a text search box to search users by name
export const GroupExpenseParticipantInput: Preact.FunctionComponent<{
  getUsers: UserSearchFn;
  selected: readonly User[];
  onChange: (user: User, was_tagged: boolean) => void;
}> = ({ getUsers, selected, onChange }) => {
  const self = useAppUser();
  const input_ref = useRef<HTMLInputElement | null>(null);
  const button_ref = useRef<HTMLButtonElement | null>(null);
  const icon_ref = useRef<HTMLElement>();
  const pass_icon_ref = useCallback(() => icon_ref.current, [icon_ref.current]);

  const scroll_container = useRef<HTMLDivElement>();

  const [{ search, users, open }, set_state] = useStateObject({
    search: "",
    users: null as User[] | null,
    open: false,
  });

  const getUsersPaged = useCallback(
    () =>
      getUsers(search, 0).then(result => {
        console.log(`scroll_container: `, scroll_container);
        scroll_container.current?.scrollTo({ top: 0 });
        set_state({
          users: result.data,
        });
      }),
    [search, set_state]
  );

  const on_change_search = useCallback(
    (str: string) => set_state({ search: str }),
    [set_state]
  );
  const [get_filtered_users, { pending, error }] = useRequest(
    getUsersPaged,
    true
  );

  useEffect(() => {
    get_filtered_users();
  }, [get_filtered_users]);

  // options in dropdown = search results minus self and already selected users
  const options = useMemo(
    () =>
      users && self
        ? users.filter(
            u => u.id !== self.id && !selected.some(s => s.id === u.id)
          )
        : [],
    [users, selected]
  );

  // clear text input on open/close
  const setOpen = useCallback(
    new_open => {
      set_state({ search: "", open: new_open });
    },
    [set_state]
  );

  // focus the search input upon opening the dropdown, blur upon closing
  useLayoutEffect(() => {
    if (!input_ref.current) {
      return;
    }
    open ? input_ref.current.focus() : input_ref.current.blur();
  }, [open, input_ref.current]);

  const [inputProps, setInputProps] = useStateIfMounted({});

  const addUser = useCallback(
    (user: User) => onChange(user, false),
    [onChange]
  );

  // focus the dropdown trigger button upon closing
  const on_close = useCallback(() => {
    button_ref.current && button_ref.current.focus();
  }, [button_ref]);

  const RenderItem = useRenderPropsFunction<OptionsListItemProps<User>>(
    ({ item, onSelect }) => (
      <UserListItem
        user={item}
        onClick={() => {
          setTimeout(() => input_ref.current?.focus(), 100);
        }}
        size="xs"
        useDiv
        linkBox="none"
      />
    ),
    "GroupExpenseParticipantInput-ListItem",
    []
  );

  const inputElemProps = useMemo(
    () => ({ ...inputProps, ref: input_ref }),
    [inputProps]
  );

  return (
    <Popover
      triggerComponent={
        <ButtonWithIcon
          ref={button_ref}
          icon={<Icon name="connection-request" ref={icon_ref} />}
          side="left"
          className="filled gray"
          onClick={() => setOpen(!open)}
        >
          Add Participant
        </ButtonWithIcon>
      }
      show={open}
      defaultDirection="top"
      getSourceRef={pass_icon_ref}
      mountLocal={true}
    >
      <fieldset disabled={!open} tabIndex={!open ? -1 : undefined}>
        {!users && pending ? (
          <DefaultPendingView />
        ) : (
          <div className="stack">
            <SearchBar
              placeholder="Filter Group Members"
              onSubmit={on_change_search}
              inputProps={inputElemProps}
              autoSubmitDelay={250}
              error={error}
            />
            <OptionsList
              options={options}
              RenderItem={RenderItem}
              open={open}
              setOpen={setOpen}
              onSelect={addUser}
              setControlListeners={setInputProps}
              closeOnClickItem={false}
              onClose={on_close}
              className="stack__scrolling-content"
            />
          </div>
        )}
      </fieldset>
    </Popover>
  );
};
