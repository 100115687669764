import { current_screen_size } from "@thrive-web/ui-utils";
import { AppContexts } from "@thrive-web/ui-model";

export const INITIAL_APP_STATE: AppContexts = {
  window_size: current_screen_size(),
  modal: {
    modal: null,
    open: false,
  },
  login_modal: {
    modal: null,
    open: false,
  },
  location: window.location.href,
  auth: null,
  modal_container_ready: false,
  interaction_data: null,
  dispatch: async e => {
    console.error(
      `Dispatch function not initialized. Something's gone terribly wrong.`
    );
  },
  site_header_content_set: false,
};
