import { stringify } from "query-string";
import {
  map_json_doc_to_record,
  map_json_list_doc_to_record_list,
  QueryParams,
  SortSpec,
} from "@thrive-web/core";

export const stringify_filter = ({
  filter,
  ...query
}: QueryParams<any>): QueryParams<any> => {
  if (!filter) {
    return query;
  }
  const output: QueryParams<any> = { ...query };
  if (Array.isArray(filter)) {
    // @ts-expect-error:
    output.filter = JSON.stringify(filter);
  }
  return output;
};

export const stringify_sort = ({ sort, ...query }: QueryParams<any>) => {
  if (!sort) {
    return query;
  }
  return {
    ...query,
    sort: sort.map(({ by, dir }) => `${dir === "desc" ? "-" : ""}${by}`),
  };
};

export const stringify_query = ({ fields, ...query }: QueryParams<any>) => {
  const query_string = stringify(query, { arrayFormat: "comma" });
  if (!fields) {
    return query_string;
  }
  let fields_str = "";
  for (let [key, list] of Object.entries(fields)) {
    const segment = stringify({ [key]: list }, { arrayFormat: "comma" });
    fields_str += `&fields[${key}]=${segment.slice(key.length + 1)}`;
  }

  return `${query_string}${fields_str}`.replace(/^&/, "");
};

export const parse_sort = <T extends object>(
  sort: string
): SortSpec<T> | undefined => {
  if (!sort) {
    return undefined;
  }
  if (sort.startsWith("-")) {
    // @ts-expect-error:
    return { by: sort.slice(1), dir: "desc" };
  }
  // @ts-expect-error:
  return { by: sort, dir: "asc" };
};

// map from json api format to flattened records
export const map_api_response = res => {
  if (!res || typeof res !== "object") {
    return res;
  }
  const { deep_includes, ...response } = res;
  if (!response || !response.data) {
    return response;
  }
  const { data, included, ...others } = response;
  if (Array.isArray(data)) {
    return {
      data: map_json_list_doc_to_record_list({ data, included }, deep_includes),
      ...others,
    };
  }
  return {
    data: map_json_doc_to_record({ data, included }, deep_includes),
    ...others,
  };
};
