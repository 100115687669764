import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { Post, WritePost } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DropdownMenu,
  IS_ADMIN_UI,
} from "@thrive-web/ui-components";
import { useAppUser } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";

// if the current user is the creator, show options to edit/delete the post
// otherwise show the option to report the post

export const PostActions: Preact.FunctionComponent<{
  menuProps?: Partial<Omit<DropdownMenuProps, "items">>;
  post: Post;
  editPost?: (updates: WritePost) => void;
  deletePost?: () => void;
  reportPost?: () => void;
}> = ({ post, editPost, deletePost, reportPost, menuProps }) => {
  const user = useAppUser();
  const is_admin_ui = useContext(IS_ADMIN_UI);
  if (!user || !post.created_by) {
    return null;
  }
  const overflow_items = is_admin_ui
    ? // in Admin UI, the only option should be Delete
      [
        <ButtonWithIcon
          icon="remove"
          side="left"
          className="filled negative"
          onClick={deletePost}
        >
          Delete Post
        </ButtonWithIcon>,
      ]
    : // if post is editable and user is creator, show edit/delete buttons
    user.id === post.created_by.id && post.is_editable
    ? [
        editPost ? (
          <ButtonWithIcon
            icon="edit"
            side="left"
            className="filled gray"
            onClick={editPost}
          >
            Edit Post
          </ButtonWithIcon>
        ) : null,
        deletePost ? (
          <ButtonWithIcon
            icon="remove"
            side="left"
            className="filled negative"
            onClick={deletePost}
          >
            Delete Post
          </ButtonWithIcon>
        ) : null,
      ].filter(i => !!i)
    : // if user is not creator, show report button
    reportPost && user.id !== post.created_by.id
    ? [
        <ButtonWithIcon
          icon="alert"
          side="left"
          className="filled negative"
          onClick={reportPost}
        >
          Report this Post
        </ButtonWithIcon>,
      ]
    : [];

  const menu_props = useMemo(
    () => ({
      ...menuProps,
      listClassName: `card pill-card${maybeClassName(
        menuProps?.listClassName
      )}`,
      buttonClassName: `filled gray${maybeClassName(
        menuProps?.buttonClassName
      )}`,
      popoverProps: {
        defaultOffset: "left" as const,
        ...menuProps?.popoverProps,
      },
    }),
    [menuProps]
  );

  return overflow_items.length > 0 ? (
    <DropdownMenu items={overflow_items} {...menu_props} />
  ) : null;
};
