import * as Preact from "preact";
import {
  ButtonWithIcon,
  TextAreaWithFormHelpers,
  Tooltip,
} from "@thrive-web/ui-components";
import { useCallback, useMemo } from "preact/hooks";
import { TouchpointAccomplishment } from "@thrive-web/ui-api";
import { join_string_list } from "@thrive-web/ui-utils";

interface BarriersProps {
  isLastPage: boolean;
  accomplishments: readonly TouchpointAccomplishment[];
  barriers: string;
  setBarriers: (value: string) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const GroupTouchpointBarriers: Preact.FunctionComponent<BarriersProps> =
  ({
    isLastPage,
    accomplishments,
    barriers,
    setBarriers,
    nextStep,
    prevStep,
  }) => {
    const onChangeBarriers = useCallback(
      e => setBarriers(e.target.value),
      [setBarriers]
    );

    // generate prompt using accomplishments selected in prev screen
    const prompt = useMemo(() => {
      const strings = accomplishments.map(acc =>
        (acc.title || "").toLowerCase()
      );
      return join_string_list(strings);
    }, [accomplishments]);

    const stepComplete = barriers.length > 0;

    return (
      <div className="group-touchpoint__section group-touchpoint__barriers">
        <div className="modal-form__section form__section">
          <div className="form__section-header">What were the barriers?</div>
          We {prompt} of...
          <div className="form__input-row">
            <TextAreaWithFormHelpers
              controlled={true}
              onChange={onChangeBarriers}
            />
          </div>
        </div>
        <div className="modal__footer">
          <div className="modal__footer__left">
            <ButtonWithIcon
              className="filled gray"
              onClick={prevStep}
              icon="chevron-left"
              side="left"
            >
              Back
            </ButtonWithIcon>
          </div>
          <div className="modal__footer__right">
            <Tooltip
              text="Please fill out the field above."
              disabled={stepComplete}
              defaultDirection="top"
              defaultOffset="left"
            >
              <ButtonWithIcon
                className="filled gray"
                onClick={stepComplete ? nextStep : undefined}
                icon={isLastPage ? "checked" : "chevron-right"}
                side="right"
              >
                {isLastPage ? "Finish" : "Next"}
              </ButtonWithIcon>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };
