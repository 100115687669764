import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { ApiMethodParameters } from "@thrive-web/core";
import { Community } from "@thrive-web/ui-api";
import {
  CommunityActivityPage,
  CommunityDetailOuter,
  CommunityAboutPage,
  CommunityDetailLoadingPage,
  CommunityEventsPage,
  CommunityPastEventsPage,
} from "~/view/components";
import {
  DEFAULT_USER_FIELDS,
  useAsyncRenderResult,
  with_profile_picture,
} from "@thrive-web/ui-components";
import { ensure_id_is_iri } from "@thrive-web/ui-common";
import { useApiRequest, useRecordForDetailPage } from "@thrive-web/ui-hooks";

export const CommunityDetail: Preact.FunctionComponent<{
  result: Community;
  postId?: string;
}> = ({ result, postId }) => {
  return (
    <CommunityDetailOuter community={result}>
      <CommunityActivityPage
        path="/community/:id/activity/:post?"
        community={result}
        post_id={postId}
      />
      <CommunityEventsPage
        path="/community/:id/upcoming-events"
        community={result}
      />
      <CommunityPastEventsPage
        path="/community/:id/past-events"
        community={result}
      />
      <CommunityAboutPage path="/community/:id/about" community={result} />
    </CommunityDetailOuter>
  );
};

export const CommunityDetailPage: Preact.FunctionComponent<RoutePageProps> = ({
  matches = {},
}) => {
  const id = useMemo(
    () => ensure_id_is_iri(matches.id || "", "Community"),
    [matches.id]
  );

  // post id if viewing a post detail modal
  const passthrough = useMemo(
    () =>
      matches.item
        ? { postId: ensure_id_is_iri(matches.item || "", "Post") }
        : {},
    [matches.item]
  );

  const params = useMemo<ApiMethodParameters<"GET", "Community">>(
    () => ({
      query: {
        include: [
          "has_admin",
          "has_moderator",
          "has_manager",
          ...with_profile_picture(["has_member"]),
        ],
        fields: {
          User: [...DEFAULT_USER_FIELDS, "has_connection"],
        },
      },
    }),
    []
  );
  const [get_community, status] = useApiRequest("getCommunity", id, params);
  const [community] = useRecordForDetailPage(id, get_community);

  const PendingView = useMemo(() => CommunityDetailLoadingPage(id), [id]);
  return useAsyncRenderResult(
    (result, pending, passthrough) => (
      <CommunityDetail result={result} {...passthrough} />
    ),
    [],
    status,
    community,
    true,
    undefined,
    PendingView,
    false,
    passthrough
  );
};
