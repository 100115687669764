import * as Preact from "preact";
import { useEffect, useMemo } from "preact/hooks";
import {
  DefaultModalContent,
  ErrorMessage,
  FileUpload,
  Icon,
  MaybePlaceholderAvatar,
  ProfilePhotoFormProps,
  RequestButton,
  Tooltip,
  useProfilePhotoForm,
} from "@thrive-web/ui-components";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import { useModal } from "@thrive-web/ui-hooks";

export const ProfilePhotoUploadButton: Preact.FunctionComponent<FileUploadButtonProps> =
  ({ file, clickInput, children, originalFile }) => {
    const body_props = useMemo(() => ({ children }), [children]);
    const [modal, set_open] = useModal({
      id: "profile-picture-upload-modal",
      className: "profile-picture-upload__modal profile-builder__photo__modal",
      body: ProfilePhotoCropModal,
      showCloseButton: true,
      bodyProps: body_props,
    });
    useEffect(() => {
      if (file) {
        set_open(true);
      }
    }, [originalFile]);

    if (!file) {
      return (
        <div className="profile-picture-upload">
          <button
            className={`profile-picture-upload__button full-width`}
            onClick={clickInput}
            type="button"
          >
            {/*<img src={`${IMAGES_PREFIX}/icon-upload-photo-outline.svg`} />*/}
            <div className="profile-picture-upload__circle">
              <Icon name="add" />
            </div>
            <div className="profile-picture-upload__prompt">
              Upload a Profile Picture
            </div>
          </button>
        </div>
      );
    }

    return (
      <div className="profile-picture-upload">
        {modal}
        <div className="profile-picture-upload__preview">
          {
            // @ts-expect-error: no name
            <MaybePlaceholderAvatar
              size="xl"
              url={file.data}
              onClick={e => {
                // @ts-expect-error:
                if (!e.dontOpenModal) {
                  set_open(true);
                }
              }}
              type="button"
            >
              <Tooltip
                className="user-avatar__icon"
                text="Choose a different picture"
                defaultDirection="bottom"
                defaultOffset="left"
                animation="fade"
                delay={1000}
              >
                <button
                  className="profile-picture-upload__edit filled gray"
                  onClick={e => {
                    // @ts-expect-error:
                    e.dontOpenModal = true;

                    return clickInput(e);
                  }}
                  type="button"
                >
                  <Icon name="edit" />
                </button>
              </Tooltip>
            </MaybePlaceholderAvatar>
          }
        </div>
      </div>
    );
  };

export const ProfilePhotoPage: Preact.FunctionComponent<ProfilePhotoFormProps> =
  ({ onFinish, user }) => {
    const [
      profile_picture,
      set_file,
      onSubmit,
      progress,
      pending,
      success,
      error,
      get_url_pending,
    ] = useProfilePhotoForm(onFinish, user);

    const url_pending = get_url_pending && !!profile_picture?.data;

    return (
      <div className="login-page profile-builder__photo">
        <div className="login-page__logo">
          <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
        </div>
        <div className="login-page__form register__form card">
          <div
            className="form__section register__form__section"
            data-part="email"
          >
            <div className="form__section-header login-page__form__title">
              Profile Picture
            </div>
            <div className="register-details-page__prompt">
              Make it easy for people to recognize you in the app.
            </div>
            <form onSubmit={onSubmit}>
              <div className="register-details-page__profile-picture">
                <FileUpload
                  name="profile_picture"
                  required={true}
                  onChange={set_file}
                  Button={ProfilePhotoUploadButton}
                  value={profile_picture}
                  allowCrop={true}
                />
              </div>
              {error && (
                <ErrorMessage>
                  {" "}
                  Your profile picture failed to upload. Please try again or
                  select another image. (Error: {error.message})
                </ErrorMessage>
              )}
              <div className="register-details-page__submit">
                <RequestButton
                  pending={pending}
                  success={success}
                  className={`login-page__submit filled full-width${
                    url_pending ? " loading-item__shaded" : ""
                  }`}
                  type="submit"
                  successText="Success!"
                  progress={progress}
                  disabled={url_pending}
                >
                  Submit
                </RequestButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

export const ProfilePhotoCropModal: Preact.FunctionComponent<ModalBodyProps> =
  ({ dismiss, closeButton, children }) => (
    <DefaultModalContent
      title="Profile Picture"
      closeButton={closeButton}
      footer={
        <div className="register-details-page__submit modal__footer">
          <div className="modal__footer__right">
            <button className="filled" onClick={dismiss}>
              Save
            </button>
          </div>
        </div>
      }
    >
      <div className="profile-picture-upload__modal__content">{children}</div>
    </DefaultModalContent>
  );
