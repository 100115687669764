import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import {
  AvatarListLoading,
  AvatarLoading,
  DivWithIcon,
  LoadingParagraph,
  GROUP_GROUP,
  COMMUNITY_COMMUNITY,
} from "@thrive-web/ui-components";

export const PostLoading: Preact.FunctionComponent<{ showScope: boolean }> = ({
  showScope,
}) => {
  const has_mood = useMemo(() => Math.random() > 0.5, []);
  return (
    <article className="card post post__loading">
      <div className="post__content">
        <div className="post__top">
          <div className="post__people">
            <div className="post__people__left">
              <AvatarLoading size="md" />
              <div className="post__people__creator">
                <div className="loading-item__shaded loading-item__name loading-item__header" />

                {showScope && (
                  <div className="loading-item__shaded loading-item__shaded--light loading-item__text" />
                )}
              </div>
            </div>
            <div className="post__people__right">
              <AvatarListLoading
                size="sm"
                align="left"
                maxWidth={5}
                maxHeight={1}
              />
            </div>
          </div>
        </div>
        <div className="post__body">
          {has_mood && (
            <div className="post__mood">
              <DivWithIcon
                className="pill loading-item__shaded loading-item__shaded--light"
                icon="unreads"
                side="left"
              >
                <div className="loading-item__shaded loading-item__text" />
              </DivWithIcon>
            </div>
          )}
          <div className="post__text">
            <LoadingParagraph />
          </div>
        </div>
        {/*<div className="post__blocks">
        {touchpoint && <PostTouchpoint touchpoint={touchpoint} />}
        {expense && <PostExpense expense={expense} />}
      </div>*/}
        <div className="post__meta">
          <div className="loading-item__shaded loading-item__text" />
          <div className="loading-item__shaded loading-item__text" />
        </div>
        {/*<div className="post__controls">
        <div className="post__controls__left" {...ignore_attrs}>
          {onReact && (
            <ReactionButton
              post_id={data.id}
              showCount={false}
              className="filled gray"
              reactions={data.has_reaction || []}
              onUpdate={onReactBound}
            />
          )}
          <ButtonWithIcon
            className="filled gray"
            icon="comment-solid-chat-bubble"
            side="left"
            onClick={onClickComment}
          />
        </div>
        <div className="post__controls__right" {...ignore_attrs}>
          <ButtonWithIcon className="filled gray" icon="share" />
          <PostActions
            post={data}
            editPost={onClickEdit}
            deletePost={onClickDelete}
          />
        </div>
      </div>*/}
      </div>
    </article>
  );
};

export const PostListLoading: Preact.FunctionComponent = () => {
  const is_group = !!useContext(GROUP_GROUP);
  const is_community = !!useContext(COMMUNITY_COMMUNITY);
  const posts = useMemo(() => new Array(4).fill(0), []);
  return (
    <div className="loading-item__list">
      <ul className="post__list">
        {posts.map((_, i) => (
          <li key={i} className="post__list__item loading-item">
            <PostLoading key={i} showScope={!(is_group || is_community)} />
          </li>
        ))}
      </ul>
    </div>
  );
};
