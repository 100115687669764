import { capitalize, path_or, URL_REGEX_G } from "@thrive-web/ui-common";
import { escape_regex_str } from "@thrive-web/ui-utils";
import { stringify } from "query-string";

export const YOUTUBE_API_URL = "https://www.googleapis.com/youtube/v3/videos";
export const VIMEO_API_URL = "https://vimeo.com/api/oembed.json";

export const YOUTUBE_URL_REGEX =
  /\b(?:https?:\/\/)?(?:(?:(?:(?:www|m)\.)?youtube\.com(?:\/watch\?v=|(?:\/(?:embed|v)\/)))|youtu\.be\/)((?:\w|-)+)/i;
export const VIMEO_URL_REGEX =
  /\b(?:https?:\/\/)?(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/i;
// from https://stackoverflow.com/a/50777192

export const is_youtube_video_link = (link: string) =>
  YOUTUBE_URL_REGEX.test(link);

export const is_vimeo_video_link = (link: string) => VIMEO_URL_REGEX.test(link);

export const is_zendesk_article_link = (link: string) =>
  ZENDESK_ARTICLE_URL_REGEX().test(link);

// zendesk article url, using the zendesk base url from the env
export const ZENDESK_ARTICLE_URL_REGEX = () =>
  new RegExp(
    `\\b(?:https?:\\/\\/)?${escape_regex_str(
      // @ts-expect-error:
      window.thread?.config?.zendesk?.zendeskUrl.replace(
        /^https?:\/\/(.*?)\/?$/i,
        "$1"
      )
    )}\\/hc\\/en-us\\/articles\\/(\\d+)([^?/]*)`
  );

export const ensure_https = (url: string) =>
  `https://${url.replace(/^https?:\/\//i, "")}`;

export interface VideoLinkMeta {
  url: string;
  title: string;
  description?: string;
  thumbnail_url: string;
  site: string;
}

export interface ZendeskLinkMeta {
  url: string;
  title: string;
  description?: string;
}

// get the first (supported) url in a string
export const extract_link_from_body = (text: string): string | undefined => {
  if (!text || typeof text !== "string") {
    return;
  }
  // get all links
  const matches = text.match(URL_REGEX_G);
  if (!matches) {
    return;
  }
  // find first link that is supported
  let link = matches.find(
    m =>
      is_youtube_video_link(m) ||
      is_vimeo_video_link(m) ||
      is_zendesk_article_link(m)
  );
  if (link) {
    link = ensure_https(link);
  }
  // make sure the link is valid
  try {
    new URL(link || "");
  } catch (e) {
    return;
  }
  return link;
};

// get metadata about the link to display
export const scrape_video_link = (
  link: string,
  api_key: string
): Promise<VideoLinkMeta | null> => {
  // get the metadata api url for this particular video link
  const url = get_scrape_url(link, api_key);
  if (!url) {
    return Promise.resolve(null);
  }

  return new Promise<VideoLinkMeta | null>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onload = e => {
      console.debug(`scraper response: `, e);
      if (xhr.status >= 400) {
        let response = xhr.response;
        try {
          response = JSON.parse(xhr.response);
        } catch (e) {}
        reject({ status: xhr.status, response });
      } else {
        const response = xhr.response ? JSON.parse(xhr.response) : null;
        // extract the metadata from the response
        resolve(get_meta_from_scrape(response, link));
      }
    };
    xhr.onerror = () => {
      console.error(`scrape_video_link error: `, xhr);
      reject({ status: xhr.status, response: JSON.parse(xhr.response) });
    };
    xhr.send();
  });
};

export const get_scrape_url = (
  link: string,
  api_key: string
): string | null => {
  if (!link) {
    return null;
  }
  return (
    get_youtube_scrape_url(link, api_key) || get_vimeo_scrape_url(link) || null
  );
};

export const get_youtube_scrape_url = (
  link: string,
  api_key: string
): string | null => {
  if (!api_key || !link) {
    return null;
  }
  let [, id] = link.match(YOUTUBE_URL_REGEX) || [];
  if (id) {
    return `${YOUTUBE_API_URL}?${stringify(
      {
        id,
        part: ["id", "snippet"],
        key: api_key,
      },
      {
        arrayFormat: "comma",
      }
    )}`;
  }

  return null;
};

export const get_vimeo_scrape_url = (link: string): string | null => {
  if (is_vimeo_video_link(link)) {
    return `${VIMEO_API_URL}?url=${encodeURIComponent(link)}`;
  }
  return null;
};

export const get_meta_from_scrape = (
  response: any,
  link: string
): VideoLinkMeta | null => {
  if (!response || !link) {
    return null;
  }
  if (is_youtube_video_link(link)) {
    return get_meta_from_youtube_scrape(response, link);
  }
  if (is_vimeo_video_link(link)) {
    return get_meta_from_vimeo_scrape(response, link);
  }
  return null;
};

export const get_meta_from_youtube_scrape = (
  response: any,
  link: string
): VideoLinkMeta | null =>
  response && response.items?.length && link
    ? {
        url: link,
        site: "youtube.com",
        title: path_or(
          "",
          ["items", 0, "snippet", "localized", "title"],
          response
        ),
        thumbnail_url: path_or(
          "",
          ["items", 0, "snippet", "thumbnails", "medium", "url"],
          response
        ),
        description: path_or(
          "",
          ["items", 0, "snippet", "localized", "description"],
          response
        ),
      }
    : null;

export const get_meta_from_vimeo_scrape = (
  response: any,
  link: string
): VideoLinkMeta | null =>
  response && link
    ? {
        url: link,
        site: "vimeo.com",
        title: response.title,
        thumbnail_url: response.thumbnail_url,
        description: response.description,
      }
    : null;

export const scrape_zendesk_link = (link: string) => {
  // @ts-expect-error:
  let base_url = window.thread?.config?.zendesk?.zendeskUrl;

  const url = get_zendesk_scrape_url(link, base_url);
  if (!url) {
    return Promise.resolve(null);
  }

  // parse title from url path
  const [, , title_raw] = link.match(ZENDESK_ARTICLE_URL_REGEX()) || [];
  const title = title_raw.split("-").map(capitalize).join(" ");

  return Promise.resolve({ url: ensure_https(link), title });

  // disabled for now because zendesk auth is dumb
  /*return new Promise<ZendeskLinkMeta | null>(async (resolve, reject) => {
    const token = (await get_api_connector_instance()).token;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url.href);
    xhr.setRequestHeader("Authentication", `Bearer ${token}`);
    xhr.onload = e => {
      console.debug(`scraper response: `, e);
      if (xhr.status >= 400) {
        let response = xhr.response;
        try {
          response = JSON.parse(xhr.response);
        } catch (e) {}
        reject({ status: xhr.status, response });
      } else {
        const response = xhr.response ? JSON.parse(xhr.response) : null;
        resolve(get_zendesk_meta_from_scrape(response, link));
      }
    };
    xhr.onerror = () => {
      console.error(`scrape_zendesk_link error: `, xhr);
      // return resolve({ url: ensure_https(link), title: "Test Title" });
      reject({ status: xhr.status, response: JSON.parse(xhr.response) });
    };
    xhr.send();
  });*/
};

export const get_zendesk_scrape_url = (
  link: string,
  base_url: string
): URL | undefined => {
  if (!base_url || !link) {
    return;
  }

  let url;
  try {
    url = new URL(ensure_https(base_url));
  } catch (e) {
    return;
  }
  const [, id] = link.match(ZENDESK_ARTICLE_URL_REGEX()) || [];
  if (!id) {
    return;
  }
  url.pathname = `/api/v2/help_center/en-us/articles/${id}.json`;

  return url;
};

export const get_zendesk_meta_from_scrape = (
  response: any,
  link: string
): ZendeskLinkMeta | null =>
  link && response
    ? {
        url: ensure_https(link),
        title: response.article.title,
      }
    : null;
