import { AppUser } from "@thrive-web/ui-model";
import * as Preact from "preact";
import { useContext, useEffect, useMemo, useRef } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { useAppUser, useZendeskReportEntity } from "@thrive-web/ui-hooks";
import {
  display_text,
  get_url_for_entity,
  get_user_name,
} from "@thrive-web/ui-utils";
import {
  USER_ACTIVITY,
  USER_COACHES,
  USER_CONNECTION,
  USER_DETAIL_CONTEXTS,
  USER_USER,
} from "./contexts";
import { UserUpdatePhoto } from "~/view/components";
import {
  Avatar,
  AvatarLoading,
  ButtonWithIcon,
  DefaultDropdownButton,
  DropdownMenu,
  DynamicListProvider,
  LocalProvider,
  PageContent,
  PageHeader,
  TabLinks,
} from "@thrive-web/ui-components";
import { capitalize, moment } from "@thrive-web/ui-common";
import { getCurrentUrl } from "preact-router";

const createUserTabLinks = (
  user: User,
  self: AppUser | null,
  connected: boolean
): NavLinkSpec[] => {
  if (!user || !user.id || !self?.id) {
    return [];
  }
  const url = get_url_for_entity(user);
  const links: NavLinkSpec[] = [
    {
      href: url,
      icon: "info-solid-circle",
      text: `About ${capitalize(get_user_name(user, self.id))}`,
      activePathMatch: `${url}`,
    },
  ];
  if (self.is_adult && (connected || user.is_adult)) {
    links.push({
      href: `${url}/groups`,
      icon: "groups",
      text: "Groups",
      activePathMatch: `${url}/groups/:action?`,
    });
  }
  return links;
};

const popover_props = {
  defaultDirection: "bottom",
  defaultOffset: "left",
} as const;
export const UserProfilePageHeader: Preact.FunctionComponent = () => {
  // let user: User | undefined;
  const self = useAppUser();
  const user = useContext(USER_USER);
  const connection = useContext(USER_CONNECTION);

  const tab_links = useMemo(
    () => (user && self ? createUserTabLinks(user, self, !!connection) : []),
    [user, self?.id]
  );
  const url = useMemo(() => (user ? get_url_for_entity(user) : ".."), [user]);

  // @ts-expect-error:
  const on_report_user = useZendeskReportEntity(user);

  if (!self) {
    return null;
  }

  return (
    <PageHeader
      title={
        user ? (
          <h1>{display_text(user.full_name)}</h1>
        ) : (
          <div className="loading-item__shaded loading-item__name loading-item__header" />
        )
      }
      subtitle={
        user ? (
          `Joined ${moment(user.created_at).format("MMMM, YYYY")}`
        ) : (
          <div className="loading-item__shaded loading-item__text" />
        )
      }
      withTabmenu={true}
      icon={
        user ? (
          user.id === self.id ? (
            <UserUpdatePhoto />
          ) : (
            <div className="user-avatar" data-size="xl">
              <Avatar
                user={user}
                size="xl"
                isLink={false}
                expandable={true}
                health={
                  typeof connection?.health_tier === "number"
                    ? connection.health_tier
                    : undefined
                }
              />
            </div>
          )
        ) : (
          <AvatarLoading size="xl" />
        )
      }
      button={
        <div className="page-header__action-menu">
          <DropdownMenu
            items={
              self.id === user?.id
                ? [
                    <a href={`${url}/edit`} className="pill filled gray">
                      Edit Profile
                    </a>,
                    <a href={`${url}/settings`} className="pill filled gray">
                      Account Settings
                    </a>,
                  ]
                : [
                    <ButtonWithIcon
                      icon="alert"
                      side="left"
                      className="negative filled"
                      onClick={on_report_user}
                    >
                      Report this profile
                    </ButtonWithIcon>,
                  ]
            }
            button={
              <DefaultDropdownButton
                className="filled transparent all-gray"
                icon={self.id === user?.id ? "settings" : "more"}
              />
            }
            listClassName="card pill-card"
            allowLinkEventPropagation={false}
            closeOnClickItem={true}
            popoverProps={popover_props}
          />
        </div>
      }
    >
      {/*
      <div className="page-header__left">
        <div className="page-header__icon">
          {user ? (
            <div className="user-avatar" data-size="lg">
              <ExpandableImage src={user.profile_picture_url} />
            </div>
          ) : (
            <AvatarLoading size="lg" />
          )}
        </div>
      </div>
      <div className="page-header__right">
        <div className="page-header__title">
          {user ? (
            display_text(user.full_name)
          ) : (
            <div className="loading-item__shaded loading-item__name loading-item__header" />
          )}
        </div>
        <div className="page-header__subtitle">
          {user ? (
            `Joined ${moment(user.created_at).format("MMMM, YYYY")}`
          ) : (
            <div className="loading-item__shaded loading-item__text" />
          )}
        </div>
      </div>*/}
      <TabLinks links={tab_links} />
    </PageHeader>
  );
};

export const UserProfileOuter: Preact.FunctionComponent<{
  user?: User;
  fetch: () => Promise<void>;
  tab?: string;
}> = ({ tab, user, fetch, children }) => {
  const is_edit = useMemo(
    () => !!user && getCurrentUrl() === `${get_url_for_entity(user)}/edit`,
    [user, getCurrentUrl()]
  );
  const local_ctx_initial = useMemo(
    () => ({
      user,
      fetch,
      coach_count: -1,
      coach_status: { pending: false, error: undefined, success: false },
    }),
    [user, fetch]
  );

  const prev_tab = useRef(tab === "settings" ? "" : tab);
  useEffect(() => {
    if (tab !== "settings") {
      prev_tab.current = tab || "";
    }
  }, [tab]);

  return (
    <PageContent
      id="user-profile-page"
      className={`user-page detail-page${is_edit ? " no-separate-header" : ""}${
        tab === "groups" ||
        (tab === "settings" && prev_tab.current === "groups")
          ? ""
          : " has-sidebar"
      }`}
    >
      <LocalProvider
        contexts={USER_DETAIL_CONTEXTS}
        initialValues={local_ctx_initial}
      >
        <DynamicListProvider context={USER_ACTIVITY}>
          <DynamicListProvider context={USER_COACHES}>
            {!is_edit && <UserProfilePageHeader />}
            {children}
          </DynamicListProvider>
        </DynamicListProvider>
      </LocalProvider>
    </PageContent>
  );
};
