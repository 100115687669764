import { current_screen_size } from "@thrive-web/ui-utils";

const WINDOW_SIZE_UPDATE_DELAY = 150;

export const track_window_size = (
  set_window_size: (size: ScreenSize) => void
) => {
  let timer: any;
  const update_size = () => {
    set_window_size(current_screen_size());
  };
  update_size();
  window.addEventListener("resize", () => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }
    timer = setTimeout(update_size, WINDOW_SIZE_UPDATE_DELAY);
  });
};
