import * as Preact from "preact";
import Match from "preact-router/match";
import { useRef } from "preact/hooks";
import { NotFoundPage } from "@thrive-web/ui-components";

// HOC that shows the component when the app location matches the specified path
export const asSubroute = <P extends RoutePageProps>(
  Component: Preact.ComponentType<P>
): Preact.FunctionComponent<P & { path: string; hasModalPath?: string }> => {
  const SubrouteComponent: Preact.FunctionComponent<
    P & { path: string; hasModalPath?: string }
  > = ({ path, hasModalPath, ...props }) => {
    // use case: a page that has tabs AND a routed modal, when at the modal route,
    // we want the most recent tab to continue displaying behind the modal, so store
    // that path
    const path_before_modal = useRef("");
    return (
      <Match path={path}>
        {
          //
          // @ts-ignore
          match_props => {
            const content =
              props.hasPermission === false ? (
                <NotFoundPage />
              ) : (
                <Component {...props} {...match_props} />
              );
            if (match_props.url !== hasModalPath) {
              path_before_modal.current = match_props.url;
              return match_props.matches ? content : null;
            }
            if (path_before_modal.current === path) {
              return content;
            }
            return null;
          }
        }
      </Match>
    );
  };
  SubrouteComponent.displayName = `asSubroute(${
    Component.displayName || Component.name
  })`;
  return SubrouteComponent;
};
