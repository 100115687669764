import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { DocWithData, MetaTypes } from "@thrive-web/core";
import { Card, TAXONOMIES_CTX } from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useModal,
  useRequestChain,
} from "@thrive-web/ui-hooks";
import {
  ApiMethodCaller,
  ApiMethodParameters,
  CustomIdentity,
  User,
} from "@thrive-web/ui-api";
import { ProfileBuilderIdentityModal } from "./IdentityForm";

export type UserIdentityProp =
  | "has_gender_identity"
  | "has_race_identity"
  | "has_ethnic_identity";

export type CustomIdMap = { [k in UserIdentityProp]?: string };

export const useCustomIdentities = (
  updateUser: ApiMethodCaller<"updateUser">
) => {
  const createCustomIdentityReq = useApiMethod("createCustomIdentity");

  const createCustomIdentities = useCallback(
    (ids: CustomIdMap): Promise<[UserIdentityProp, CustomIdentity][]> => {
      const entries = Object.entries(ids).filter(([_, value]) => !!value);
      if (entries.length === 0) {
        return Promise.resolve([]);
      }
      // @ts-expect-error:
      return Promise.allSettled(
        entries.map(([prop, value]) =>
          createCustomIdentityReq({
            body: { data: { attributes: { title: value } } },
          }).then(({ data }) => {
            return [prop, data];
          })
        )
      ).then(res => {
        const errors = res
          .filter(r => r.status === "rejected")
          .map(r => r.reason);
        const success = res
          .filter(r => r.status === "fulfilled")
          .map(r => r.value);
        if (errors.length > 0) {
          throw errors;
        }
        return success;
      });
    },
    [createCustomIdentityReq]
  );

  const combine_results = useCallback((identity, user_) => user_.data, []);
  const get_user_params_from_identity = useCallback(
    (
      identities: [UserIdentityProp, CustomIdentity][],
      params: ApiMethodParameters<"updateUser">
    ) => {
      const data = params[1];
      if (!data || identities.length < 1) {
        return params;
      }
      if (!data.body.data.relationships) {
        data.body.data.relationships = {};
      }
      identities.forEach(([prop, value]) => {
        data.body.data.relationships![prop] = { data: { id: value.id } };
      });

      params[1] = data;
      return params;
    },
    []
  );

  return useRequestChain<
    (identities: CustomIdMap) => Promise<[UserIdentityProp, CustomIdentity][]>,
    ApiMethodCaller<"updateUser">,
    User
  >(
    createCustomIdentities,
    updateUser,
    combine_results,
    get_user_params_from_identity
  );
};

export const ProfileBuilderIdentity: Preact.FunctionComponent<{
  finishStep: () => void;
}> = ({ finishStep }) => {
  const user = useAppUser();
  const fetch_tx = useContext(TAXONOMIES_CTX.fetch);
  useEffect(() => {
    fetch_tx("GenderIdentity");
    fetch_tx("EthnicIdentity");
    fetch_tx("GroupIdentity");
    fetch_tx("RaceIdentity");
  }, []);

  const updateUser = useApiMethod("updateUser");
  const createIdentityAndUpdateUserChained = useCustomIdentities(updateUser);

  const onSubmit = useCallback(
    (data: DocWithData<MetaTypes["User"], false>, custom_ids?: CustomIdMap) => {
      if (!user) {
        return Promise.reject();
      }
      if (custom_ids) {
        return createIdentityAndUpdateUserChained(
          [custom_ids],
          [user.id, { body: data }]
        ).then(() => finishStep());
      } else {
        return updateUser(user.id, { body: data }).then(() =>
          setTimeout(finishStep, 800)
        );
      }
    },
    [user, createIdentityAndUpdateUserChained, updateUser, finishStep]
  );

  const modal_props = useMemo(() => ({ finishStep: onSubmit }), [onSubmit]);
  const [identityModal, setIdentityModalOpen] = useModal(
    {
      id: "profile-builder-identity",
      className: "profile-builder__modal profile-builder__identity",
      dismissOnClickBackdrop: false,
      showCloseButton: true,
      body: ProfileBuilderIdentityModal,
      bodyProps: modal_props,
    },
    undefined,
    true
  );

  return (
    <Card className="profile-builder__intro__list__item">
      <div className="profile-builder__identity__content">
        <h4>Great work! You're almost done.</h4>
        <p>
          We have a few more optional questions to ask you. These will not
          appear on your profile.
        </p>
        <div className="profile-builder__identity__button">
          <button className="filled" onClick={() => setIdentityModalOpen(true)}>
            Finish setting up my profile.
          </button>
        </div>
        <a href="/logout" className="plain-link">
          Log out of my account.
        </a>
      </div>
      {identityModal}
    </Card>
  );
};
