import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { class_names, get_cache } from "@thrive-web/ui-utils";
import { Avatar, AvatarLoading, MaybePlaceholderAvatar } from "./Avatar";
import { Tooltip, TooltipProps } from "@thrive-web/ui-components";

export const AvatarList: Preact.FunctionComponent<{
  users: readonly User[];
  // max number of columns
  maxWidth?: number;
  // max number of rows
  maxHeight?: number;
  size: IconSize;
  align: "left" | "right";
  showTooltips?: boolean;
  tooltipProps?: Omit<TooltipProps, "text">;
  links?: boolean;
  onClickMore?: () => void;
}> = ({
  users: users_input,
  maxWidth = 6,
  maxHeight = 1,
  size = "md",
  align = "right",
  showTooltips,
  tooltipProps,
  links = true,
  onClickMore,
}) => {
  const users = useMemo(
    () =>
      users_input
        .map(u => (u && u.email ? u : get_cache(u.id)))
        .filter(u => !!u),
    [users_input]
  );
  const avatars = useMemo<Preact.ComponentChildren[]>(() => {
    const list: Preact.ComponentChildren[][] = [];
    let row: Preact.ComponentChildren[] = [];
    for (let i = 0; i < users.length; i++) {
      if (i < users.length - 1 && i === maxWidth * maxHeight - 1) {
        const item = showTooltips ? (
          <Tooltip
            key="avatar-list-more"
            animation="fade"
            delay={200}
            {...tooltipProps}
            text={`${users.length - i} more people`}
          >
            <MaybePlaceholderAvatar
              size={size}
              name={`${users.length - i} more people`}
              onClick={onClickMore}
              data-link={!!onClickMore}
            >
              <div>+{users.length - i}</div>
            </MaybePlaceholderAvatar>
          </Tooltip>
        ) : (
          <MaybePlaceholderAvatar
            key="avatar-list-more"
            size={size}
            name={`${users.length - i} more people`}
            onClick={onClickMore}
            data-link={!!onClickMore}
            title={
              onClickMore
                ? "Click to view all people"
                : `${users.length - i} more people`
            }
          >
            <div>+{users.length - i}</div>
          </MaybePlaceholderAvatar>
        );
        row.push(item);
        list.push(row);
        row = [];
        break;
      }
      const { full_name } = users[i];
      const item =
        showTooltips && full_name ? (
          <Tooltip
            key={users[i].id}
            animation="fade"
            delay={200}
            text={full_name}
          >
            <Avatar user={users[i]} size={size} isLink={links} />
          </Tooltip>
        ) : (
          <Avatar
            key={users[i].id}
            user={users[i]}
            size={size}
            isLink={links}
          />
        );
      row.push(item);
      if (row.length % maxWidth === 0) {
        list.push(row);
        row = [];
      }
    }
    if (row.length > 0) {
      list.push(row);
    }
    return list.map((row, i) => (
      <div className="avatar-list__row" key={i}>
        {row}
      </div>
    ));
  }, [users, maxWidth, maxHeight, showTooltips, links]);
  return (
    <div
      data-size={size}
      className={class_names({ [`--${align}`]: true }, "avatar-list")}
    >
      {avatars}
    </div>
  );
};

export const AvatarListLoading: Preact.FunctionComponent<{
  maxWidth: number;
  maxHeight: number;
  size: IconSize;
  align: "left" | "right";
}> = ({ maxWidth, maxHeight, size, align }) => {
  const count = useMemo(
    () => Math.ceil(Math.random() * maxWidth * maxHeight),
    []
  );
  const avatars = useMemo<Preact.ComponentChildren[]>(() => {
    const list: Preact.ComponentChildren[][] = [];
    let row: Preact.ComponentChildren[] = [];
    for (let i = 0; i < count; i++) {
      row.push(<AvatarLoading size={size} />);
      if (row.length % maxWidth === 0) {
        list.push(row);
        row = [];
      }
    }
    if (row.length > 0) {
      list.push(row);
    }
    return list.map((row, i) => (
      <div className="avatar-list__row" key={i}>
        {row}
      </div>
    ));
  }, [size]);

  return (
    <div
      data-size={size}
      className={`${class_names(
        { [`--${align}`]: true },
        "avatar-list"
      )} avatar-list__loading`}
    >
      {avatars}
    </div>
  );
};
