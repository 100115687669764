import * as Preact from "preact";
import { useCallback, useContext, useRef } from "preact/hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  ErrorMessage,
  Icon,
  Popover,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import { useId } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { ScreenSize } from "@thrive-web/ui-constants";

// component that displays an error popover in the footer of a page or modal
export const FooterError: Preact.FunctionComponent<{
  error?: DisplayableError;
  popoverProps?: Omit<PopoverProps, "triggerComponent" | "show" | "className">;
}> = ({ error, popoverProps, children }) => {
  const source_ref = useRef<HTMLDivElement>();
  const window_size = useContext(CONTEXTS.window_size);
  const get_source_ref = useCallback(() => source_ref.current, [source_ref]);
  const id = useId("", "footer-error-tooltip");
  const [show, , trigger_props] = usePopoverTrigger(
    { click: true, focus: true, hover: true },
    id,
    200
  );

  if (!error) {
    return null;
  }

  if (!popoverProps) {
    popoverProps = {};
  }
  if (!popoverProps.getSourceRef && popoverProps.mountLocal) {
    popoverProps.getSourceRef = get_source_ref;
  }

  return (
    <div className="footer-error">
      <ErrorMessage>
        {children ||
          (window_size < ScreenSize.sm ? "Error" : "Something went wrong.")}
      </ErrorMessage>
      <Popover
        id={id}
        className="footer-error__popover"
        triggerComponent={
          <button
            {...trigger_props}
            className={`non-button${maybeClassName(trigger_props.className)}`}
          >
            <Icon ref={source_ref} name="alert" />
          </button>
        }
        show={show}
      >
        <div className="footer-error__top">
          {(error.http_status || error.code) && (
            <div className="error-view__code">
              Status Code: {error.http_status || error.code}
            </div>
          )}
          <pre className="error-view__message">{error.message}</pre>
        </div>
        <div className="footer-error__bottom">
          Try refreshing the page, or{" "}
          <a className="plain-link inline" href="/support">
            contact support
          </a>{" "}
          if the issue persists.
        </div>
      </Popover>
    </div>
  );
};
