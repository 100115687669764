import * as Preact from "preact";
import { WriteUser } from "@thrive-web/ui-api";
import { useModal, useStateIfMounted } from "@thrive-web/ui-hooks";
import { useCallback, useMemo } from "preact/hooks";
import {
  DatePickerInput,
  DatePickerProps,
  DatePickerTriggerInput,
  DatePickerWithInput,
  DefaultModalContent,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import { moment } from "@thrive-web/ui-common";

const BirthdayPickerInput: DatePickerTriggerInput = props => (
  // @ts-expect-error:
  <WithFloatingTitle title="Birthday" className="datetime__container">
    <DatePickerInput
      name="birth_date"
      placeholder="MM/DD/YYYY"
      {...props}
      controlled={true}
      required={true}
      submitOnEnter={true}
      pattern="\d{2}\/\d{2}\/\d{4}"
    />
  </WithFloatingTitle>
);

const BirthdayInfoModal: Preact.FunctionComponent<ModalBodyProps> = ({
  closeButton,
}) => (
  <DefaultModalContent
    title="Why do I need to provide my birthday?"
    closeButton={closeButton}
  >
    Users of this app must be 13 or older. We ask your birthday so we can verify
    your age to see if you are eligible to create an account.
  </DefaultModalContent>
);

// @ts-expect-error:
const check_age = (value: string) =>
  moment().isSameOrAfter(moment(value).add(moment.duration(13, "years")));

export const RegisterBirthdayForm: Preact.FunctionComponent<{
  data: WriteUser;
  backButton: Preact.VNode;
  onFinish: (data: WriteUser) => void;
}> = ({ onFinish, backButton, data }) => {
  const [birth_date, set_dob] = useStateIfMounted(data.birth_date || "");
  const [too_young, set_too_young] = useStateIfMounted(false);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!too_young && check_age(birth_date)) {
        onFinish({ birth_date });
      } else {
        set_too_young(true);
        return;
      }
    },
    [onFinish, birth_date, too_young]
  );

  const onChange = useCallback(
    (val: string) => {
      val && set_too_young(!check_age(val));
      set_dob(val ? moment(val).toISOString() : "");
    },
    [set_dob, set_too_young]
  );

  const date_picker_props = useMemo<
    Omit<DatePickerProps, "children"> & {
      TriggerComponent: DatePickerTriggerInput;
    }
  >(
    () => ({
      onChange,
      value: birth_date ? new Date(birth_date) : undefined,
      TriggerComponent: BirthdayPickerInput,
      popoverProps: {
        defaultDirection: "top",
      },
    }),
    [set_dob, birth_date, onChange]
  );

  const [info_modal, set_open] = useModal({
    id: "birthday-info",
    className: "register__form__birthday-info",
    body: BirthdayInfoModal,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
    showCloseButton: true,
  });

  return (
    <div className="form__section register__form__section" data-part="birthday">
      <div className="register__form__back">{backButton}</div>
      <div className="form__section-header login-page__form__title">
        What's your birthday?
      </div>
      <form onSubmit={onSubmit}>
        <div className="form__input-row">
          <DatePickerWithInput {...date_picker_props} />
        </div>
        {too_young && (
          <div className="info error-message">
            You do not meet the age requirement to use this application. Click
            the link below if you want to know why we can’t let you.
          </div>
        )}
        <div className="form__input-row">
          <button
            className="plain-link"
            type="button"
            onClick={() => set_open(true)}
          >
            Why do I need to provide my birthday?
          </button>
        </div>
        <div className="form__footer">
          <button className="filled" type="submit" disabled={too_young}>
            Continue
          </button>
        </div>
      </form>
      {info_modal}
    </div>
  );
};
