import { ApiMethodParameters } from "@thrive-web/core";
import { DEFAULT_USER_FIELDS } from "@thrive-web/ui-components";
import { AppUser } from "@thrive-web/ui-model";

export type HomeSearchPostType =
  | "has_touchpoint"
  | "event"
  | "is_asking_for_help";

export const HOME_POST_QUERY: ApiMethodParameters<"GET", "Post", false> = {
  query: {
    include: [
      "posted_to",
      "has_touchpoint",
      "has_reaction",
      "has_expense",
      "mood",
      "photo",
      "event.Event:cover_image",
      "created_by.User:profile_picture",
      "has_tagged_user.User:profile_picture",
      "has_comment.Comment:created_by.User:profile_picture",
    ],
    sort: [{ by: "created_at", dir: "desc" }],
    include_count: true,
  },
};

export const post_query = (
  self: AppUser
): ApiMethodParameters<"GET", "Post", false> => ({
  ...HOME_POST_QUERY,
  query: {
    ...HOME_POST_QUERY.query,
    fields: {
      User: DEFAULT_USER_FIELDS,
    },
    filter: [
      [
        "or",
        [
          "=",
          ["this", ["/", "Post:posted_to", "Group:has_member"]],
          ["id", self.id],
        ],
        [
          "=",
          ["this", ["/", "Post:posted_to", "Community:has_member"]],
          ["id", self.id],
        ],
      ],
    ],
  },
});

export const post_search_query = (
  self: AppUser,
  search: string,
  post_type?: HomeSearchPostType
): ApiMethodParameters<"GET", "Post", false> => {
  const base = post_query(self);
  const filter: any = [
    [
      "or",
      [
        "match",
        ["this", ["/", "Post:created_by", "User:full_name"]],
        search,
        "i",
      ],
      [
        "match",
        ["this", ["/", "Post:has_tagged_user", "User:full_name"]],
        search,
        "i",
      ],
    ],
  ];
  return {
    ...base,
    query: {
      ...base.query,
      filter: post_type
        ? [
            ...(base.query?.filter || []),
            ...filter,
            post_type === "is_asking_for_help"
              ? ["=", ["this", `Post:${post_type}`], true]
              : ["exists", ["this", `Post:${post_type}`]],
          ]
        : base.query?.filter,
    },
  };
};
