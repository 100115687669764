import * as Preact from "preact";
import { deep_equals } from "@thrive-web/ui-common";
// no idea why, but the alias path ("~/view/components") wouldn't work, so using rel path
import { InputWithFormHelpers } from "./inputs";

export abstract class FakeDateOrTimeInput<
  T extends "date" | "time",
  P = {}
> extends InputWithFormHelpers<
  string,
  FakeDateOrTimeProps<T> & P,
  FakeDateOrTimeState<T>
> {
  focus?: boolean;
  blurTimeout;
  abstract validateParts: (parts: SomeDateOrTimeParts<T>) => boolean;
  abstract partsToString: (parts: SomeDateOrTimeParts<T>) => string;
  abstract stringToParts: (value: string) => DateOrTimeParts<T>;
  abstract getInputLimits: (name: NumericDateOrTimeParts<T>) => {
    min: number;
    max: number;
  };
  abstract calcMinMax: <K extends "min" | "max">(
    key: K,
    value: string,
    state?: Partial<FakeDateOrTimeState<T>>
  ) => FakeDateOrTimeState<T>[K] | null;
  adjustForNewValue?: (value: SomeDateOrTimeParts<T>) => SomeDateOrTimeParts<T>;

  inputs: {
    [K in keyof DateOrTimeParts<T>]: Preact.RefObject<HTMLInputElement>;
  };
  container: Preact.RefObject<HTMLElement>;
  last_focused?: keyof DateOrTimeParts<T>;

  componentDidMount() {
    // necessary because apparently the normal listener we set in render() doesn't get
    // called when we manually dispatch a change event
    this.withElement(elem => {
      // @ts-ignore
      elem.onchange = this.onChange;
    });
  }

  componentDidUpdate(prevProps: FakeDateOrTimeProps<T>, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    const new_state = this.refreshMinMax(prevProps);
    if (Object.keys(new_state).length > 0) {
      console.log(`new_state: `, new_state);
      this.setState(new_state);
      this.validateParts(this.state.value);
    }
    if (this.props.value !== prevProps.value) {
      const parts = this.stringToParts(this.props.value as string);
      if (this.validateParts(parts)) {
        this.setState({ value: parts });
        return;
      }
    }
    if (this.state.cur_value !== prevState.cur_value) {
      this.withElement(elem => {
        elem.dispatchEvent(new Event("change"));
      });
    }
  }

  /** if the min/max props have changed, update component state to reflect them */
  refreshMinMax = (
    prevProps: FakeDateOrTimeProps<T>
  ): Pick<FakeDateOrTimeState<T>, "min" | "max"> => {
    const { min, max } = this.props;
    let state: Pick<FakeDateOrTimeState<T>, "min" | "max"> = {};
    if (max == null && prevProps.max != null) {
      state.max = undefined;
    } else if (max) {
      const new_max = this.calcMinMax("max", max, state);
      if (
        new_max !== null &&
        (prevProps.max !== max || !deep_equals(new_max, this.state.max))
      ) {
        state.max = new_max;
      }
    }
    if (min == null && prevProps.min != null) {
      state.min = undefined;
    } else if (min) {
      const new_min = this.calcMinMax("min", min, state);
      if (
        new_min !== null &&
        (prevProps.min !== min || !deep_equals(new_min, this.state.min))
      ) {
        state.min = new_min;
      }
    }
    return state;
  };

  clearValue = (name: keyof DateOrTimeParts<T>) => {
    const new_value = {
      ...this.state.value,
      [name]: undefined,
    } as SomeDateOrTimeParts<T>;
    const new_state: any = { value: new_value };
    if (!Object.values(new_value).some(v => v != null)) {
      new_state.cur_value = undefined;
    }
    this.setState(new_state);
  };

  updateValue = (parts: SomeDateOrTimeParts<T>) => {
    parts = Object.assign({}, this.state.value, parts);
    if (this.adjustForNewValue) {
      parts = this.adjustForNewValue(parts);
    }
    if (!deep_equals(this.state.value, parts)) {
      if (!this.partsToString(parts)) {
        this.setState({ value: parts });
      } else if (this.validateParts(parts)) {
        this.setState({ value: parts, cur_value: this.partsToString(parts) });
      }
    }
  };

  /** onKeyDown listener to attach to all [type="number"] inputs */
  onKeyDownNumber = (name: NumericDateOrTimeParts<T>) => (e: KeyboardEvent) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      e.stopPropagation();
      // @ts-ignore
      this.clearValue(name);
      return this.onKeyDown(e);
    }
    if (!/[0-9]/.test(e.key)) {
      return this.onArrowKey(name)(e);
    }
    const input = this.inputs[name].current;
    const cur_value =
      // @ts-ignore
      (this.state.value[name] as number) ||
      parseInt(`${input ? input.value : undefined}`);
    let new_value = parseInt(e.key);
    const { min, max } = this.getInputLimits(name);
    if (cur_value != null && !isNaN(cur_value)) {
      const len = name === "year" ? 4 : 2;
      let new_str: any = `${cur_value}${new_value}`;
      new_str = parseInt(
        new_str.length > len
          ? `${new_value}`.slice(-len)
          : new_str.padStart(len, "0")
      );
      new_value = max < new_str ? new_value : new_str;
    }
    if (new_value < min) {
      this.setState({ value: { ...this.state.value, [name]: new_value } });
    } else {
      // @ts-ignore
      this.updateValue({ [name]: new_value });
    }

    e.preventDefault();
    e.stopPropagation();
    this.selectText(name);
    return this.onKeyDown(e);
  };

  onArrowKey = (name: NumericDateOrTimeParts<T>) => (e: KeyboardEvent) => {
    const { min, max } = this.getInputLimits(name);
    // @ts-ignore
    const value = this.state.value[name];
    // @ts-ignore
    let new_val = value == null ? parseInt(e.target.value) : (value as number);
    switch (e.key) {
      case "ArrowUp":
        new_val++;
        if (value === null || isNaN(value)) {
          new_val = min;
        }
        break;
      case "ArrowDown":
        new_val--;
        if (value === null || isNaN(value)) {
          new_val = max;
        }
        break;
      case "Tab":
      case "Enter":
        return this.onKeyDown(e);
      default:
        e.preventDefault();
        e.stopPropagation();
        this.selectText(name);
        return this.onKeyDown(e);
    }
    if (new_val > max) {
      new_val = min;
    }
    if (new_val < min) {
      new_val = max;
    }
    // @ts-ignore
    this.updateValue({ [name]: new_val });
    this.selectText(name);
    return this.onKeyDown(e);
  };

  withInput = <R extends any>(
    name: keyof DateOrTimeParts<T>,
    func: (el: HTMLInputElement) => R
  ): R | undefined => {
    const input = this.inputs[name].current;
    return input ? func(input) : undefined;
  };

  setFocus = (is_focused: boolean, name?: keyof DateOrTimeParts<T>) => {
    this.focus = is_focused;
    if (name && this.input.current) {
      this.last_focused = name;
      this.input.current.dataset["last_focused"] = name as string;
    }
    if (this.container.current) {
      this.container.current.dataset.focus = `${is_focused}`;
    }
  };

  focusInput = (name: keyof DateOrTimeParts<T>) =>
    this.withInput(name, el => el.focus());
  selectText = (name: keyof DateOrTimeParts<T>) =>
    this.withInput(name, el => el.select());
  onFocus = (name: keyof DateOrTimeParts<T>) => e => {
    this.onFocusBound(e);
    this.withInput(name, el => {
      el.dataset.focus = "true";
    });
    this.setFocus(true, name);
    e.target.select();
  };
  onBlur = (name: keyof DateOrTimeParts<T>) => e => {
    this.withInput(name, el => {
      el.dataset.focus = "false";
    });
    this.setFocus(false);
    const { onBlur } = this.props;
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
    const state_value = { ...this.state.value };
    if (this.props["minutesStep"] && name === "minutes") {
      const step = this.props["minutesStep"];
      const cur_value = state_value["minutes"];
      if (cur_value != null) {
        state_value["minutes"] = Math.round(cur_value / step) * step;
        if (state_value["minutes"] === 60) {
          state_value["minutes"] = 0;
          state_value["hours"] =
            state_value["hours"] === 23 ? 0 : state_value["hours"] + 1;
        }
        if (state_value["minutes"] !== cur_value) {
          this.setState({ value: state_value });
        }
      }
    }
    if (onBlur) {
      const ev = e.nativeEvent;
      this.blurTimeout = setTimeout(() => {
        const has_focus = Object.keys(this.inputs)
          .map(n =>
            // @ts-ignore
            this.withInput(n, el => el.dataset.focus === "true")
          )
          .includes(true);
        const value = this.partsToString(state_value);
        if (!has_focus && value) {
          this.setState({ cur_value: value });
          this.onBlurBound(ev);
        }
        this.blurTimeout = null;
      }, 25);
    }
  };
}
