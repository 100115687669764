import * as Preact from "preact";
import { useEffect, useMemo, useRef } from "preact/hooks";
import lottie from "lottie-web";
import {
  GroupScoreIcon,
  get_group_conn_level,
  get_points_for_group_conn_level,
  GROUP_SCORE_ANIM_DELAY,
  GROUP_SCORE_ANIM_DURATION,
} from "@thrive-web/ui-components";
import { usePreviousValue, useStateObject } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
const lottie_anim = require("~/../site/static/images/group-score-anim.json");

export const GroupConnectionScoreSidebar: Preact.FunctionComponent<
  MaybeClass & {
    score: number;
    showLevelUpAnim?: boolean;
  }
> = ({ score, className, showLevelUpAnim }) => (
  <div className={`group-sidebar__score${maybeClassName(className)}`}>
    <div className="group-sidebar__score__left">
      <GroupConnectionScore score={score} showLevelUpAnim={showLevelUpAnim} />
    </div>
    <div className="group-sidebar__score__right">
      <h4>Keep it up!</h4>
      <p>Keep connecting and updating this group to continue to level up.</p>
    </div>
  </div>
);

export const GroupConnectionScore: Preact.FunctionComponent<{
  score: number;
  showLevelUpAnim?: boolean;
}> = ({ score, showLevelUpAnim }) => {
  const anim_ref = useRef<HTMLDivElement>();
  // a lottie animation instance used to control the level-up animation
  const anim = useMemo(
    () =>
      showLevelUpAnim && anim_ref.current
        ? lottie.loadAnimation({
            container: anim_ref.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: lottie_anim,
          })
        : undefined,
    [showLevelUpAnim, anim_ref.current]
  );
  // current level (zero indexed) and % progress to the next level
  const [level, progress] = useMemo(() => {
    const level = get_group_conn_level(score);
    const level_pts = get_points_for_group_conn_level(level);
    const progress =
      (100 * (score - level_pts)) /
      (get_points_for_group_conn_level(level + 1) - level_pts); // xx.xx%
    return [level, progress];
  }, [score]);
  const prev_level = usePreviousValue(level);
  const [{ updated, start_next, initialized }, set_state] = useStateObject({
    updated: false,
    start_next: false,
    initialized: false,
  });
  useEffect(() => {
    // when level changes, play the animation
    if (initialized) {
      if (level !== prev_level.current) {
        // play the lottie anim halfway through the score anim so that it starts when the
        // score indicator reaches the top of the element
        setTimeout(() => anim?.goToAndPlay(0), 0.5 * GROUP_SCORE_ANIM_DURATION);
      }
      // set updated to true (triggers the css animation of the score indicator)
      set_state({ updated: true });
      // after the animation is complete, update the prev_level value and stop animations
      setTimeout(() => {
        prev_level.current = level;
        set_state({ updated: false, start_next: false });
      }, GROUP_SCORE_ANIM_DURATION);
      // at a set point during the score animation, start the animation for the score
      // indicator moving up from the bottom (progress in the new level)
      setTimeout(() => {
        set_state({ start_next: true });
      }, GROUP_SCORE_ANIM_DELAY);
    }
    set_state({ initialized: true });
  }, [score]);

  const leveled = prev_level.current != null && level !== prev_level.current;
  return (
    <div
      className="group-score__icon"
      data-updated={`${updated}`}
      style={{
        animationDuration: `${GROUP_SCORE_ANIM_DURATION}ms`,
        transitionDuration: `${GROUP_SCORE_ANIM_DELAY}ms`,
      }}
    >
      {showLevelUpAnim && (
        <div className="group-score__icon__anim" ref={anim_ref} />
      )}
      <div className="group-score__icon__text">{level + 1}</div>
      <GroupScoreIcon
        progress={progress}
        leveled={leveled}
        startNextLevel={start_next}
      />
    </div>
  );
};
