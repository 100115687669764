import { Transacted } from "@thi.ng/atom";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { AppContexts } from "../types";
import { AppEventHandlerMap } from "./types";

const event_handlers: AppEventHandlerMap<Transacted<AppContexts>> = {
  SET_WINDOW_SIZE: async (state, tx, { size }) => {
    if (size !== state.window_size) {
      tx.resetIn("window_size", size);
    }
  },

  UPDATE_AUTH_CTX: async (state, tx, payload) => {
    tx.resetIn("auth", payload);
  },

  UPDATE_AUTH_USER: async (state, tx, { value, path = [] }) => {
    tx.resetIn(["auth", "user", ...path], value);
  },

  UPDATE_LOCATION: async (state, tx, location) => {
    tx.resetIn("location", location);
  },

  /*
   * Modals
   */

  SET_APP_MODAL_OPEN: async (state, tx, open) => {
    tx.resetIn("modal.open", open);
  },
  SET_APP_MODAL_CONTENT: async (state, tx, modal) => {
    tx.resetIn("modal.modal", modal);
  },
  OPEN_APP_MODAL: async (state, tx, modal) => {
    tx.resetIn("modal.open", true);
    tx.resetIn("modal.modal", modal);
  },
  CLOSE_APP_MODAL: async (state, tx) => {
    tx.resetIn("modal.open", false);
    setTimeout(() => {
      if (!tx.isTransaction) {
        tx.begin();
      }
      tx.resetIn("modal.modal", null);
      tx.commit();
    }, MODAL_ANIMATION_DELAY);
  },
  SET_MODAL_CONTAINER_READY: async (state, tx) => {
    tx.resetIn("modal_container_ready", true);
  },
  SET_INTERACTION_DATA: async (state, tx, data) => {
    tx.resetIn("interaction_data", data);
  },
  SET_HEADER_CONTENT: async (state, tx, is_set) => {
    tx.resetIn("site_header_content_set", is_set);
  },
};

export default event_handlers;
