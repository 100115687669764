import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { GoalPreset, WriteGoal } from "@thrive-web/ui-api";
import {
  useApiCall,
  useDirtyForm,
  useForm,
  useResettableRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { display_text } from "@thrive-web/ui-utils";
import { useCallback, useEffect, useRef } from "preact/hooks";
import {
  ErrorMessage,
  RequestButtonWithIcon,
  TextAreaWithFormHelpers,
  WithFloatingTitle,
  InputWithFormHelpers,
  DivWithIcon,
  DefaultPendingView,
  DefaultErrorView,
  FloatingTitle,
} from "@thrive-web/ui-components";

export const GroupGoalForm: Preact.FunctionComponent<{
  record?: WriteGoal;
  submitText: string;
  submitIcon: FontIconName;
  onSubmit: (data: WriteGoal) => Promise<any>;
  clearOnSubmit?: boolean;
  dismiss: () => void;
}> = ({
  record = {},
  submitIcon,
  submitText,
  onSubmit,
  dismiss,
  clearOnSubmit,
}) => {
  const form_ref = useRef<HTMLFormElement>();
  const [goal, setField, onChangeInput, setFormData] = useForm({
    name: record?.name,
    description: record?.description,
  });
  const clearDirtyFormState = useDirtyForm(goal, "GroupGoalForm", true);
  const [sendRequest, { pending, success, error }, resetRequest] =
    useResettableRequest(onSubmit);
  const submitForm = useCallback(
    e => {
      e.preventDefault();
      if (!goal.description || !goal.name) {
        return;
      }
      sendRequest({
        id: record.id,
        ...goal,
      }).then(() => {
        clearDirtyFormState();
        setTimeout(() => {
          dismiss();
          if (clearOnSubmit) {
            setFormData({
              name: record?.name,
              description: record?.description,
            });
            form_ref.current && form_ref.current.reset();
            resetRequest();
          }
        }, 750);
      });
    },
    [goal, sendRequest, clearOnSubmit, setFormData, resetRequest]
  );

  const applyPreset = useCallback(
    (preset: GoalPreset) => {
      analytics.log_event(analytics.EVENTS.create_goal_suggested);
      setField("name", preset.name);
    },
    [setField]
  );

  return (
    <form
      ref={form_ref}
      id="group-goals-create-form"
      className="group-goals__form"
      onSubmit={submitForm}
    >
      <div className="form__input-row">
        <WithFloatingTitle title="Title">
          <InputWithFormHelpers
            form="group-goals-create-form"
            name="name"
            placeholder="Title"
            onChange={onChangeInput("name")}
            value={goal.name}
            required={true}
            controlled={true}
          />
        </WithFloatingTitle>
      </div>
      <div className="form__input-row">
        <TextAreaWithFormHelpers
          form="group-goals-create-form"
          name="description"
          placeholder="Describe your goal..."
          onChange={onChangeInput("description")}
          value={goal.description}
          required={true}
          controlled={true}
        >
          <FloatingTitle name="description">Description</FloatingTitle>
        </TextAreaWithFormHelpers>
      </div>
      <div className="modal-form__section">
        <GoalSuggestions applyPreset={applyPreset} />
      </div>
      <div className="modal__footer group-goals__form__footer">
        <div className="modal__footer__left">
          <button type="button" className="filled gray" onClick={dismiss}>
            Cancel
          </button>
        </div>
        <div className="modal__footer__right">
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          <RequestButtonWithIcon
            className="filled gray"
            icon={submitIcon}
            side="left"
            type="submit"
            pending={pending}
            success={success}
            successText="Success!"
          >
            {submitText}
          </RequestButtonWithIcon>
        </div>
      </div>
    </form>
  );
};

const GOAL_PRESET_PAGE_SIZE = 5;
// todo: fetch these on page load instead of on modal show
export const GoalSuggestions: Preact.FunctionComponent<{
  applyPreset: (preset: GoalPreset) => void;
}> = ({ applyPreset }) => {
  const [presets, setPresets] = useStateIfMounted<GoalPreset[]>([]);
  const page = useRef(0);
  const [fetchPresets, { pending, error }] = useApiCall("getGoalPresets");
  const getPresets = useCallback(() => {
    if (pending) {
      return;
    }
    fetchPresets({
      query: {
        include_count: true,
        offset: page.current * GOAL_PRESET_PAGE_SIZE,
        limit: GOAL_PRESET_PAGE_SIZE,
      },
    }).then(({ data, meta }) => {
      const {
        offset = 0,
        limit = GOAL_PRESET_PAGE_SIZE,
        total_result_count,
      } = meta as any;
      if (offset + limit >= total_result_count) {
        page.current = 0;
      } else {
        page.current++;
      }
      return setPresets(data);
    });
  }, [fetchPresets, pending, setPresets]);
  useEffect(() => {
    getPresets();
  }, []);

  return (
    <div className="group-goals__create__presets stack">
      <div className="group-goals__create__presets__header">
        <DivWithIcon icon="goal" side="left">
          Goal Suggestions
        </DivWithIcon>
        <button type="button" onClick={getPresets}>
          Refresh List
        </button>
      </div>
      <div className="group-goals__create__presets__list stack__scrolling-content">
        {pending ? (
          <DefaultPendingView />
        ) : error ? (
          <DefaultErrorView error={error} />
        ) : presets.length === 0 ? (
          <div className="group-goals__create__presets__empty">
            No presets found
          </div>
        ) : (
          presets.map(p => (
            <button
              type="button"
              className="group-goals__create__preset"
              key={p.id}
              onClick={() => applyPreset(p)}
            >
              <div className="group-goals__create__preset__name">
                {display_text(p.name)}
              </div>
              <div className="group-goals__create__preset__button">
                Use Suggestion
              </div>
            </button>
          ))
        )}
      </div>
    </div>
  );
};
