import * as Preact from "preact";
import { Community, Event, Post } from "@thrive-web/ui-api";
import {
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
} from "@thrive-web/ui-components";
import { createNamedContext } from "@thrive-web/ui-utils";

// stores the community record for a community detail page
export const COMMUNITY_COMMUNITY = createNamedContext<null | Community>(
  null,
  "CommunityRecord"
);

// stores the post records for a community activity feed
export const COMMUNITY_POSTS: Preact.Context<
  DynamicListCtxSpec<Post, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "CommunityPosts"
);

export const COMMUNITY_EVENTS: Preact.Context<
  DynamicListCtxSpec<Event, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "CommunityEvents"
);

export const COMMUNITY_PAST_EVENTS: Preact.Context<
  DynamicListCtxSpec<Event, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "CommunityPastEvents"
);
