import * as Preact from "preact";
import { forwardRef } from "preact/compat";
import { useCallback, useRef } from "preact/hooks";
import {
  ButtonWithIcon,
  DefaultPendingView,
  Icon,
  InputWithFormHelpers,
  OptionsList,
  Popover,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import {
  useId,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  closestAncestor,
  getFocusableContainer,
  maybeClassName,
} from "@thrive-web/ui-utils";

export const DropdownSelectInputDefaultButton: Preact.FunctionComponent<
  MaybeClass &
    OptionsListControlProps<HTMLButtonElement> & {
      children: string | Preact.VNode;
    }
> = forwardRef<
  HTMLButtonElement,
  MaybeClass &
    OptionsListControlProps<HTMLButtonElement> & {
      value?: string;
      children: string | Preact.VNode;
    }
>(({ className, children, value, ...props }, ref) => (
  <ButtonWithIcon
    {...props}
    ref={ref}
    className={`filled gray${maybeClassName(className)}`}
    icon="list"
    iconOpposite="disclosure"
    side="left"
  >
    {value || children}
  </ButtonWithIcon>
));

export const DropdownSelectInputDefaultTextInput: Preact.FunctionComponent<
  MaybeClass &
    OptionsListControlProps<HTMLInputElement> & {
      label?: string;
      children: string | Preact.VNode;
      value?: string;
    }
> = forwardRef<
  HTMLInputElement | null,
  MaybeClass &
    OptionsListControlProps<HTMLInputElement> & {
      label?: string;
      value?: string;
      children: string;
    }
>(({ children, onChange, ...props }, ref) => {
  const input = (
    <Preact.Fragment>
      <InputWithFormHelpers
        {...props}
        readOnly={true}
        inputRef={elem => {
          ref.current = elem;
        }}
        onChange={() => {}}
        controlled={true}
      />
      <Icon name="disclosure" className="select__caret" />
    </Preact.Fragment>
  );
  return props["label"] ? (
    <WithFloatingTitle title={props["label"]}>{input}</WithFloatingTitle>
  ) : (
    input
  );
});

export const DropdownSelectInputListItem = <T extends any>({
  item,
  onSelect,
  getValueLabel,
}: {
  item: T;
  onSelect: (val: T) => void;
  getValueLabel: (val: T) => string;
}) => (
  <div className="dropdown-input__item" onClick={() => onSelect(item)}>
    <div className="dropdown-input__item--default">{getValueLabel(item)}</div>
  </div>
);

const noop = () => {};

export const DropdownSelectInput = <
  T extends any,
  E extends HTMLElement = HTMLElement
>({
  value,
  options,
  getValueLabel,
  onSelect,
  label,
  // @ts-expect-error:
  Button = DropdownSelectInputDefaultButton,
  popoverProps,
  className,
  required,
  disabled,
  buttonProps = {},
  triggerClassName,
  mountLocal,
}: DropdownSelectInputProps<T, E>) => {
  const menu_id = useId(undefined, "dropdown-select-input--menu");
  const [open, setOpen] = useStateIfMounted(false);
  const button_ref = useRef<E | null>(null);
  const [buttonControlProps, setButtonPropsState] = useStateIfMounted<
    OptionsListControlProps<E>
    // @ts-expect-error:
  >({});

  const mouse_down_in_menu = useRef(false);
  const ignore_on_focus = useRef(false);
  const on_mouse_release = useRef<((e) => void) | undefined>();

  const on_mouse_up = useCallback(e => {
    mouse_down_in_menu.current = false;
    if (!closestAncestor(e.target, `#${menu_id}`) && on_mouse_release.current) {
      on_mouse_release.current(e);
    } else {
      ignore_on_focus.current = true;
      button_ref.current &&
        button_ref.current.focus &&
        button_ref.current.focus();
    }
    on_mouse_release.current = undefined;
  }, []);

  const on_menu_mouse_down = useCallback(
    e => {
      if (mouse_down_in_menu.current) {
        return;
      }
      mouse_down_in_menu.current = true;
      window.addEventListener("mouseup", on_mouse_up, { once: true });
    },
    [on_mouse_up]
  );

  const setButtonProps = useCallback(
    (btn_props: OptionsListControlProps<E>) => {
      const { onBlur, onFocus } = btn_props;
      setButtonPropsState({
        ...btn_props,
        onFocus: e => {
          if (!ignore_on_focus.current) {
            onFocus(e);
          }
          ignore_on_focus.current = false;
        },
        onBlur: e => {
          if (!mouse_down_in_menu.current) {
            onBlur(e);
          } else {
            on_mouse_release.current = onBlur.bind(e);
          }
        },
      });
    },
    [setButtonPropsState]
  );

  const RenderItem = useRenderPropsFunction<OptionsListItemProps<T>>(
    ({ item, onSelect: onSelectItem }) => (
      <DropdownSelectInputListItem
        item={item}
        onSelect={onSelectItem}
        getValueLabel={getValueLabel}
      />
    ),
    "DropdownSelectInputListItem-Bound",
    [getValueLabel]
  );

  return (
    <Popover
      defaultDirection="bottom"
      {...popoverProps}
      disableFocusTrap={true}
      className={`dropdown-input dropdown-menu dropdown-select-input${maybeClassName(
        className
      )}`}
      triggerClassName={triggerClassName}
      triggerComponent={
        <Button
          {...buttonProps}
          {...buttonControlProps}
          value={value !== undefined ? getValueLabel(value) : undefined}
          onClick={e => {
            if (!disabled) {
              setOpen(true);
              // @ts-expect-error:
              getFocusableContainer(e.target, button_ref.current)?.focus?.();
            }
          }}
          ref={button_ref}
          className={`dropdown-select-input__button${maybeClassName(
            buttonProps?.className
          )}`}
          disabled={disabled}
        >
          <Preact.Fragment>
            {value !== undefined ? getValueLabel(value) : label}
            {required ? (
              <div className="input__hidden">
                <input
                  tabIndex={-1}
                  value={value ? getValueLabel(value) : undefined}
                  required={true}
                />
              </div>
            ) : null}
          </Preact.Fragment>
        </Button>
      }
      show={open && !disabled}
      hideNub={true}
      mountLocal={mountLocal}
    >
      {options ? (
        <fieldset
          id={menu_id}
          onMouseDown={on_menu_mouse_down}
          disabled={!open}
          tabIndex={!open ? -1 : undefined}
        >
          <OptionsList
            options={options}
            RenderItem={RenderItem}
            onSelect={onSelect}
            setControlListeners={setButtonProps}
            open={open}
            setOpen={!disabled ? setOpen : noop}
            selectOnSpacebar={true}
          />
        </fieldset>
      ) : (
        <div className="dropdown-select-input__options-loading">
          <DefaultPendingView />
        </div>
      )}
    </Popover>
  );
};
