import * as Preact from "preact";
import { User } from "@thrive-web/ui-api";
import { USER_DETAIL_CONTEXTS } from "~/view/components";
import {
  Card,
  DivWithIcon,
  Tooltip,
  UserProfileContactItem,
} from "@thrive-web/ui-components";
import { useContext, useMemo } from "preact/hooks";
import { useAppUser } from "@thrive-web/ui-hooks";

export const UserContactInfo: Preact.FunctionComponent<{
  afterInteractions: () => void;
}> = ({ afterInteractions }) => {
  const self = useAppUser();
  const user = useContext(USER_DETAIL_CONTEXTS.user);
  const connection = useContext(USER_DETAIL_CONTEXTS.connection) || undefined;

  const linkProps = useMemo(
    () => ({
      user: user as User,
      connection,
      onFinish: afterInteractions,
    }),
    [user, connection, afterInteractions]
  );

  if (!user || !self || (!connection && user.id !== self.id)) {
    return null;
  }

  return (
    <Card className="user-profile__card user-profile__contact__card">
      <div className="user-profile__card__header">
        <h3 className="user-profile__card__title">Contact Info</h3>
        {self.id === user.id && (
          <Tooltip
            className="user-profile__card__privacy"
            text="This is visible to people you are connected with."
            delay={500}
          >
            <DivWithIcon icon="connection" side="left">
              Your connections
            </DivWithIcon>
          </Tooltip>
        )}
      </div>
      <div className="user-profile__contact__card__list">
        {user.email && (
          <UserProfileContactItem type="email" linkProps={linkProps}>
            {user.email}
          </UserProfileContactItem>
        )}
        {user.phone_number && (
          <UserProfileContactItem type="phone" linkProps={linkProps}>
            {user.phone_number}
          </UserProfileContactItem>
        )}
      </div>
    </Card>
  );
};
