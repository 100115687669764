import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";

import { useCallback, useContext } from "preact/hooks";
import { Mood, User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DivWithIcon,
  MoodSelectorModal,
  WithTaxonomy,
} from "@thrive-web/ui-components";
import { USER_DETAIL_CONTEXTS } from "../contexts";
import {
  useApiMethod,
  useAppUser,
  useAppUserPropertyUpdate,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { display_text } from "@thrive-web/ui-utils";

export const UserSelfMood: Preact.FunctionComponent<{
  user?: User;
  mood: Mood;
}> = ({ user, mood }) => {
  const dispatch_local = useContext(USER_DETAIL_CONTEXTS.dispatch);
  const updateMoodReq = useApiMethod("setCapturedMood");
  const updateAppUser = useAppUserPropertyUpdate();

  const [open, setOpen] = useStateIfMounted(false);

  const updateMood = useCallback(
    (new_mood?: Mood) => {
      if (!user) {
        return Promise.resolve();
      }
      return updateMoodReq({
        body: {
          data: {
            attributes: {},
            relationships: {
              mood: {
                data: new_mood?.id ? { id: new_mood.id } : null,
              },
            },
          },
        },
      })
        .then(() => updateAppUser("has_current_mood", new_mood))
        .then(new_user => {
          analytics.log_event(analytics.EVENTS.update_mood);
          setTimeout(() => {
            setOpen(false);
            dispatch_local("user", new_user);
          }, 800);
        });
    },
    [updateMoodReq, user]
  );

  return (
    <ButtonWithIcon
      className="pill filled gray user-profile__mood"
      icon={
        mood?.id ? (
          <span className="mood__emoji">{display_text(mood.symbol)}</span>
        ) : (
          "mood-share"
        )
      }
      side="left"
      onClick={() => setOpen(true)}
    >
      {mood?.id ? (
        <span>
          Feeling <strong>{mood.title}</strong>
        </span>
      ) : (
        <span>Update Mood</span>
      )}
      <span className="plain-link">Edit</span>
      <MoodSelectorModal
        onSelect={updateMood}
        open={open}
        setOpen={setOpen}
        value={mood?.id ? mood : undefined}
      />
    </ButtonWithIcon>
  );
};

export const UserProfileMood: Preact.FunctionComponent = () => {
  const user = useContext(USER_DETAIL_CONTEXTS.user);
  const self = useAppUser();

  const render = useRenderPropsFunction(
    (mood: Mood, loading?: boolean) => {
      if (loading) {
        return (
          <DivWithIcon
            className="pill filled gray static user-profile__mood"
            icon={
              <div className="group__dot loading-item__shaded loading-item__shaded--light" />
            }
            side="left"
          >
            <div className="loading-item__shaded loading-item__shaded--light loading-item__text" />
          </DivWithIcon>
        );
      }

      if (!self || !user) {
        return null;
      }

      if (self?.id !== user?.id) {
        return mood ? (
          <DivWithIcon
            className="pill filled gray static user-profile__mood"
            icon={
              <span className="mood__emoji">{display_text(mood.symbol)}</span>
            }
            side="left"
          >
            <span>
              Feeling <strong>{mood.title}</strong>
            </span>
          </DivWithIcon>
        ) : null;
      }
      return <UserSelfMood user={self} mood={mood} />;
    },
    "UserProfileMoodRender",
    [user?.id, self?.id]
  );

  if (!user || !self) {
    return null;
  }

  const mood = user.has_current_mood;

  return mood || user.id === self.id ? (
    <WithTaxonomy type="Mood" value={mood}>
      {render}
    </WithTaxonomy>
  ) : null;
};
