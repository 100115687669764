import * as Preact from "preact";
import { useStateIfMounted } from "@thrive-web/ui-hooks";
import { class_names, maybeClassName } from "@thrive-web/ui-utils";
import { useCallback, useMemo } from "preact/hooks";

type ItemComponentBaseProps = MaybeClass & {
  style?: Partial<CSSStyleDeclaration>;
};

export type RemovableListItemComponentProps<P, Result extends any = never> = P &
  ItemComponentBaseProps & {
    onRemoveItem: Result extends never
      ? () => Promise<void>
      : (data: Result) => Promise<void>;
    removed: boolean;
  };

export type RemovableListItemProps<
  P extends ItemComponentBaseProps,
  Result extends any = never
> = Omit<P, "removed" | "onRemoveItem"> & {
  delay?: number;
  duration?: number;
  onRemoveItem?: Result extends never
    ? () => Promise<void>
    : (data: Result) => Promise<void>;
  Component: Preact.ComponentType<
    P & {
      onRemoveItem: Result extends never
        ? () => Promise<void>
        : (data: Result) => Promise<void>;
      removed: boolean;
    }
  >;
};

// HOC for a list item that fades away and then unrenders when the remove function is invoked
export const RemovableListItem = <
  P extends ItemComponentBaseProps,
  Result extends any = never
>({
  delay = 0,
  duration = 400,
  style,
  className,
  Component,
  onRemoveItem,
  ...props
}: RemovableListItemProps<P, Result>) => {
  const [removed, setRemoved] = useStateIfMounted(false);
  const [faded, setFaded] = useStateIfMounted(false);
  const removeItem = useCallback(
    (data): Promise<void> =>
      new Promise(resolve => {
        setTimeout(() => {
          setRemoved(true);
          setTimeout(() => {
            setFaded(true);
            onRemoveItem?.(data);
            resolve();
          }, duration);
        }, delay);
      }),
    [setTimeout, setRemoved, duration, delay, onRemoveItem]
  );

  const style_prop = useMemo(
    () => ({ ...style, transitionDuration: `${duration}ms` }),
    [style, duration]
  );

  return (
    // @ts-expect-error:
    <Component
      {...props}
      className={`${class_names(
        {
          "--removed": removed,
          "--faded": faded,
        },
        "removable-list-item"
      )}${maybeClassName(className)}`}
      style={style_prop}
      onRemoveItem={removeItem}
      removed={removed && faded}
    />
  );
};
