import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  unrenderAfterDismissed,
  useCallbackRef,
  useFirstShown,
  useFocusTrap,
  usePortal,
  useWindowEventListener,
} from "@thrive-web/ui-hooks";
import { class_names_postfixed, maybeClassName } from "@thrive-web/ui-utils";

let scroll_override_active_count = 0;

const useEscListener = (
  id: string,
  dismiss: () => void,
  giveTabFocus: boolean,
  dismissOnEsc: boolean
): void => {
  const listener = useCallbackRef<(e: KeyboardEventFor<HTMLElement>) => void>(
    e => {
      if (!e.target) {
        return;
      }
      if (e.key === "Escape" && dismissOnEsc) {
        dismiss();
      }
    },
    [id, dismiss, giveTabFocus, dismissOnEsc],
    true
  );
  const set_down_listener_active = useWindowEventListener("keydown", listener);
  const set_press_listener_active = useWindowEventListener(
    "keypress",
    listener
  );
  useEffect(() => {
    set_down_listener_active(giveTabFocus || dismissOnEsc);
    set_press_listener_active(giveTabFocus || dismissOnEsc);
  }, [giveTabFocus, dismissOnEsc]);
};

const useBackdropClickListener = (
  id: string,
  dismiss: () => void,
  backdrop_clicked_ref: Preact.RefObject<boolean>
): { onMouseDown: EventListener; onMouseUp: EventListener } => {
  const onMouseDown = useCallback(
    e => {
      if ((e.target as HTMLElement).id === `${id}-backdrop`) {
        backdrop_clicked_ref.current = true;
      }
    },
    [id, backdrop_clicked_ref]
  );
  const onMouseUp = useCallback(
    e => {
      if (
        (e.target as HTMLElement).id === `${id}-backdrop` &&
        backdrop_clicked_ref.current
      ) {
        dismiss();
      }
    },
    [id, backdrop_clicked_ref]
  );
  return { onMouseDown, onMouseUp };
};

// when enabled, it disables scrolling for the main site content container (overflow
// hidden), and allows scrolling on the modal container (i.e. modal can go off the page)
const useScrollOverride = (enabled?: boolean, delay?: number) => {
  const scrollPos = useRef(0);
  const applied = useRef(false);
  const timer = useRef<any>(null);
  const toggle = useCallback(
    (enabled_?: boolean) => {
      if (enabled_ === applied.current) {
        return;
      }
      if (!enabled_ && scroll_override_active_count > 1) {
        applied.current = false;
        scroll_override_active_count--;
        return;
      }
      if (enabled_ && scroll_override_active_count > 0) {
        applied.current = true;
        scroll_override_active_count++;
        return;
      }
      const node = document.getElementById("site-main");
      if (!(node instanceof HTMLElement) || !node.parentElement) {
        return;
      }
      const sidebar = document.getElementById("site-userbar");

      if (enabled_) {
        scroll_override_active_count++;
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }
        const has_scroll_bar = document.body.scrollHeight > window.innerHeight;
        const scrollbar_width =
          window.outerWidth -
          (node.parentElement?.clientWidth || window.outerWidth);
        scrollPos.current = window.scrollY;
        node.setAttribute("data-no-scroll", `${!has_scroll_bar}`);
        node.setAttribute("data-override-scroll", "true");
        if (!has_scroll_bar) {
          node.style.maxWidth = `100vw`;
          node.style.minWidth = `100vw`;
        } else {
          node.parentElement.setAttribute(
            "style",
            `--scroll-y: ${-1 * scrollPos.current}px`
          );
          node.style.top = `var(--scroll-y)`;
          node.style.width = `calc(100vw - ${scrollbar_width}px)`;
          sidebar && (sidebar.style.right = `${scrollbar_width}px`);
        }

        applied.current = true;
      } else if (!timer.current) {
        timer.current = setTimeout(() => {
          node.style.top = "";
          node.style.maxWidth = "";
          node.style.minWidth = "";
          node.style.width = "";
          sidebar && (sidebar.style.right = "");
          node.removeAttribute("data-override-scroll");
          node.removeAttribute("data-no-scroll");
          window.scrollTo(0, scrollPos.current);
          applied.current = false;
          timer.current = null;
          scroll_override_active_count--;
        }, delay);
      }
    },
    [scrollPos, applied]
  );
  useEffect(() => {
    requestAnimationFrame(() => toggle(enabled));
  }, [enabled]);
  useEffect(() => () => applied.current && toggle(false), []);
};

const useTabFocus = (enabled: boolean) => {
  const applied = useRef(false);
  useLayoutEffect(() => {
    if (applied.current === enabled) {
      return;
    }
    const node = document.getElementById("site-main");
    if (!(node instanceof HTMLElement)) {
      return;
    }
    if (enabled) {
      node.setAttribute("disabled", "true");
      node.setAttribute("tabindex", "-1");
    } else {
      node.removeAttribute("disabled");
      node.removeAttribute("tabindex");
    }
    applied.current = enabled;
  }, [applied, enabled]);
  useEffect(
    () => () => {
      if (!applied.current) {
        return;
      }
      const node = document.getElementById("site-main");
      if (!(node instanceof HTMLElement)) {
        return;
      }
      node.removeAttribute("disabled");
      node.removeAttribute("tabIndex");
      applied.current = false;
    },
    []
  );
};

/** popup that takes the focus of the whole page */
export const Popup: Preact.FunctionComponent<PopupProps> = ({
  id,
  popupType,
  open,
  giveTabFocus = true,
  dismissOnClickBackdrop = true,
  overrideScroll = true,
  dismiss,
  className,
  innerClassName,
  children,
}) => {
  useEscListener(id, dismiss, giveTabFocus, dismissOnClickBackdrop);
  useScrollOverride(overrideScroll && open);
  useTabFocus(giveTabFocus && open);
  const body = useRef<HTMLDivElement>();
  const first_shown = useFirstShown(open);
  const backdrop_clicked = useRef<boolean>(false);
  const passed_class_names = className?.split(" ") || [];
  const backdropProps = useMemo(
    () =>
      dismissOnClickBackdrop
        ? useBackdropClickListener(id, dismiss, backdrop_clicked)
        : {},
    [dismissOnClickBackdrop, id, dismiss, backdrop_clicked]
  );
  useFocusTrap(open, body, undefined, undefined, true, false);

  return (
    <div
      id={`${id}-backdrop`}
      data-show={`${open}`}
      data-first-shown={`${first_shown}`}
      data-override-scroll={overrideScroll}
      className={class_names_postfixed(
        ["popup", popupType, ...passed_class_names],
        "__backdrop"
      )}
      {...backdropProps}
    >
      <div
        className={class_names_postfixed(
          ["popup", popupType, ...passed_class_names],
          "__container"
        )}
      >
        <div
          id={id}
          className={`popup ${popupType}${maybeClassName(
            className
          )}${maybeClassName(innerClassName)}`}
          ref={body}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export type PopupGenerator<
  T extends Omit<PopupProps, "popupType"> & {
    popupType?: PopupType;
  } = PopupProps
> = (
  props: Omit<T, "open" | "dismiss">,
  open: boolean,
  dismiss: () => void,
  unrenderWhenDismissed: boolean,
  container_id?: string
) => Preact.VNode | null;

// hook for managing portal/rendering for a popup
export const createPopup: PopupGenerator = (
  props,
  open,
  dismiss,
  unrenderWhenDismissed,
  container_id = "popup-container"
) => {
  useContext(CONTEXTS.modal_container_ready);
  const shouldRender = unrenderAfterDismissed(open, unrenderWhenDismissed);
  const showPopup = usePortal(container_id, shouldRender);
  return shouldRender
    ? null
    : showPopup(<Popup {...props} open={open} dismiss={dismiss} />);
};
