import * as Preact from "preact";
import { useRenderPropsFunction } from "@thrive-web/ui-hooks";
import { DefaultModalContent, Icon } from "@thrive-web/ui-components";

export const ProfileBuilderItem: Preact.FunctionComponent<{
  complete: boolean;
  name: string;
  description: string | Preact.VNode;
  button?: Preact.VNode | null;
}> = ({ complete, name, description, button, children }) => (
  <div className="profile-builder__intro__list__item card">
    <div className="profile-builder__intro__list__item__top">
      <div className="profile-builder__intro__list__item__header">
        <div className="profile-builder__intro__list__item__check">
          <Icon
            data-complete={complete}
            name={complete ? "checked-solid-circle" : "checked-broken-circle"}
          />
        </div>
        <div className="profile-builder__intro__list__item__info">
          <h4>
            <Icon
              data-complete={complete}
              name={complete ? "checked-solid-circle" : "checked-broken-circle"}
            />
            {name}
          </h4>
          <span>{description}</span>
        </div>
      </div>
      {button && (
        <div className="profile-builder__intro__list__item__button">
          {button}
        </div>
      )}
    </div>
    {children}
  </div>
);

export const makeProfileBuilderModalBody = <
  T extends any[],
  R extends any,
  P extends {
    finishStep: (...args: T) => R;
    dismiss: () => void;
    open: boolean;
  }
>(
  title: string,
  Component: Preact.ComponentType<P>,
  setData: (...args: T) => R,
  props?: Omit<P, "finishStep" | "dismiss" | "open">
) => {
  return useRenderPropsFunction<ModalBodyProps>(
    ({ dismiss, closeButton, open }) => {
      return (
        <DefaultModalContent title={title} closeButton={closeButton}>
          {
            // @ts-expect-error
            <Component
              finishStep={setData}
              dismiss={dismiss}
              open={open}
              {...(props || {})}
            />
          }
        </DefaultModalContent>
      );
    },
    `asProfileBuilderModalBody(${Component.displayName})`,
    [setData, props, title, Component]
  );
};
