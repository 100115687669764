import * as Preact from "preact";

export interface BreadcrumbItem {
  label: Preact.ComponentChildren;
  href?: string;
}

export const Breadcrumb: Preact.FunctionComponent<{
  items: BreadcrumbItem[];
  lastItemClickable?: boolean;
}> = ({ items, lastItemClickable }) => {
  return (
    <div className="breadcrumb">
      {items.map((item, i) => {
        const crumb =
          // item for current page should not be a link
          item.href && (i < items.length - 1 || lastItemClickable) ? (
            <a key={i} href={item.href} className="breadcrumb__item">
              {item.label}
            </a>
          ) : (
            <div key={i} className="breadcrumb__item">
              {item.label}
            </div>
          );

        if (i === items.length - 1) {
          return crumb;
        }
        return (
          <Preact.Fragment key={i}>
            {crumb}
            <span className="breadcrumb__divider">/</span>
          </Preact.Fragment>
        );
      })}
    </div>
  );
};
