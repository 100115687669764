import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { MappedApiResponse, TraitifyAssessment } from "@thrive-web/ui-api";
import {
  preserveProps,
  useApiCall,
  useAppUser,
  useDynamicListVariable,
  useModal,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  AsyncRender,
  ButtonWithIcon,
  DefaultPendingView,
  Icon,
  RequestButtonWithIcon,
  TraitifySurvey,
  TraitifySurveyResultsButtons,
  useAsyncRender,
} from "@thrive-web/ui-components";
import {
  makeProfileBuilderModalBody,
  ProfileBuilderItem,
} from "./ProfileBuilderItem";

export const ProfileBuilderStrengths: Preact.FunctionComponent<{
  step: number;
  name: string;
  description: string;
  button_text: string;
  button_icon: FontIconName;
  finishStep: (key: string, data: any) => void;
  currentStep: number;
}> = ({
  button_icon,
  button_text,
  finishStep,
  currentStep,
  step,
  ...props
}) => {
  const user = useAppUser();
  const [latestResult, setResult] =
    useStateIfMounted<null | TraitifyAssessment>(null);
  const [assessments, updateAssessments] =
    useDynamicListVariable<TraitifyAssessment>([]);
  const [getExistingAssessment, { pending }] = useApiCall("getSelfSurveys");

  const onFinish = useCallback(
    (result: TraitifyAssessment) => {
      updateAssessments.update(a => a.id === result.id, result);
      setResult(result);
    },
    [setResult, updateAssessments]
  );

  useEffect(() => {
    latestResult && finishStep("survey", latestResult);
  }, [latestResult]);

  useEffect(() => {
    if (!user) {
      return;
    }
    // get all existing assessments taken by the current user
    getExistingAssessment({
      query: {
        filter: [
          ["=", ["this", "TraitifyAssessment:taken_by"], ["id", user.id]],
        ],
        sort: [
          { by: "created_at", dir: "desc" },
          { by: "completed_at", dir: "desc" },
        ],
      },
    })
      .then((result: MappedApiResponse<"getSelfSurveys">) => {
        updateAssessments.reset(result.data);
        setResult(
          // @ts-expect-error:
          result.data.find(a => a.assessment?.status === "complete") || null
        );
      })
      .catch(() => {});
  }, []);

  const body_props = useMemo(
    () => ({
      onFetchNewAssessment: updateAssessments.add,
      assessment:
        // @ts-expect-error:
        assessments.find(a => a.assessment?.status !== "complete") || null,
    }),
    [updateAssessments, assessments]
  );

  const strengthsModalBody = makeProfileBuilderModalBody(
    "Identify Strengths",
    ProfileBuilderStrengthsSurvey,
    onFinish,
    preserveProps(body_props)
  );
  const modalProps = useMemo(
    () => ({
      id: "profile-builder-strengths",
      className: "profile-builder__modal profile-builder__strengths",
      dismissOnClickBackdrop: false,
      showCloseButton: !!latestResult,
      body: strengthsModalBody,
    }),
    [latestResult, strengthsModalBody]
  );

  const [strengthsModal, setStrengthsModalOpen] = useModal(
    modalProps,
    undefined,
    true
  );
  const openModal = useCallback(() => {
    setStrengthsModalOpen(true);
  }, [setStrengthsModalOpen]);

  return !user ? null : (
    <ProfileBuilderItem
      {...props}
      description={
        !!latestResult
          ? "Click on your results to learn more."
          : props.description
      }
      complete={!!latestResult}
      button={
        latestResult ? (
          <ButtonWithIcon
            side="left"
            className="filled gray"
            icon={<Icon name="jump-back" />}
            onClick={openModal}
          >
            Retake the Strengths Survey
          </ButtonWithIcon>
        ) : (
          <RequestButtonWithIcon
            icon={button_icon}
            side="left"
            pending={pending}
            success={false}
            className="filled"
            disabled={!!latestResult}
            onClick={openModal}
          >
            {button_text}
          </RequestButtonWithIcon>
        )
      }
    >
      {latestResult && (
        <TraitifySurveyResultsButtons
          className="profile-builder__intro__list__item__bottom"
          data={latestResult}
          showFooter={true}
        />
      )}
      {strengthsModal}
    </ProfileBuilderItem>
  );
};

export const ProfileBuilderStrengthsSurvey: Preact.FunctionComponent<{
  dismiss: () => void;
  open: boolean;
  assessment: TraitifyAssessment | null;
  finishStep: (data: any) => void;
  onFetchNewAssessment: (data: TraitifyAssessment) => void;
}> = ({ assessment, finishStep, dismiss, onFetchNewAssessment, open }) => {
  const new_assessment = useRef<TraitifyAssessment>();
  const onFinish = useCallback(
    data => {
      // @ts-expect-error:
      new_assessment.current = undefined;
      finishStep(data);
      dismiss();
    },
    [finishStep, dismiss]
  );

  const [SuccessView, startAssessment] = useAsyncRender(
    result => (
      <TraitifySurvey
        data={result.data}
        onFinish={onFinish}
        showResults={true}
      />
    ),
    [onFinish],
    "selfSurveyStart"
  );

  const fetchNewAssessment = useCallback(() => {
    return startAssessment().then(result => {
      new_assessment.current = result.data;
      return result;
    });
  }, []);

  useEffect(
    () => () =>
      new_assessment.current && onFetchNewAssessment(new_assessment.current),
    []
  );

  if (assessment) {
    return (
      <div className="profile-builder__content traitify-survey__container">
        <TraitifySurvey
          data={assessment}
          onFinish={onFinish}
          showResults={true}
        />
      </div>
    );
  }
  if (!open) {
    return (
      <div className="profile-builder__content traitify-survey__container">
        <DefaultPendingView />
      </div>
    );
  }

  return (
    <div className="profile-builder__content traitify-survey__container">
      <AsyncRender getPromise={fetchNewAssessment}>{SuccessView}</AsyncRender>
    </div>
  );
};
