import * as hooks from "preact/hooks";
import { AppUser, EVENTS } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { DEFAULT_UI_ERROR } from "@thrive-web/ui-constants";
import { cache_record } from "@thrive-web/ui-utils";

// shortcut for getting the logged-in user
export const useAppUser = (): AppUser | null => {
  const auth = hooks.useContext(CONTEXTS.auth);
  if (!auth || !auth.firebaseUser) {
    return null;
  }
  return auth.user;
};

// provide a callback that takes a property name and value of a use record
// and updates the stored data for the logged in user
export const useAppUserPropertyUpdate = <P extends keyof AppUser>(): ((
  property: P,
  value: AppUser[P]
) => Promise<AppUser>) => {
  const self = useAppUser();
  const dispatch = hooks.useContext(CONTEXTS.dispatch);

  return hooks.useCallback(
    (property: P, value: AppUser[P]) => {
      if (!self) {
        return Promise.reject(DEFAULT_UI_ERROR);
      }
      const new_user = { ...self, [property]: value };
      return dispatch({
        type: EVENTS.UPDATE_AUTH_USER,
        payload: { value, path: [property] },
      }).then(() => {
        cache_record(new_user, true);
        return new_user;
      });
    },
    [dispatch, self]
  );
};

// provides a callback for updating the entire stored user record for the logged in user
export const useAppUserUpdate = (): ((value: AppUser) => Promise<AppUser>) => {
  const self = useAppUser();
  const dispatch = hooks.useContext(CONTEXTS.dispatch);

  return hooks.useCallback(
    (value: AppUser) => {
      if (!self) {
        return Promise.reject(DEFAULT_UI_ERROR);
      }
      const new_user = { ...self, ...value };
      return dispatch({
        type: EVENTS.UPDATE_AUTH_USER,
        payload: { value: new_user },
      }).then(() => {
        cache_record(new_user, true);
        return new_user;
      });
    },
    [dispatch, self]
  );
};
