import { ApiMethodParameters } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { ConnectionMapped, User } from "@thrive-web/ui-api";
import { InteractionTypes } from "@thrive-web/ui-constants";
import { set_dirty } from "@thrive-web/ui-model";
import {
  Avatar,
  BooleanPillRadioList,
  ErrorMessage,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import { useApiCall, useStateIfMounted } from "@thrive-web/ui-hooks";

export const ReminderModalBody: Preact.FunctionComponent<
  ModalBodyProps & {
    user: User;
    connection: ConnectionMapped;
    interactionType: InteractionTypes;
    onCreateInteraction?: (health?: number) => void;
  }
> = ({ user, connection, interactionType, onCreateInteraction, dismiss }) => {
  const [health, set_health] = useStateIfMounted(connection.health_tier || 0);
  useEffect(() => {
    set_dirty(true, "track-interaction-reminder");
    setTimeout(() => set_health(Math.min(health + 1, 4)), 250);
  }, []);

  const [remind, set_remind] = useStateIfMounted<boolean | undefined>(
    undefined
  );

  const params = useMemo<ApiMethodParameters<"POST", "Interaction">>(
    () => ({
      body: {
        data: {
          attributes: {
            interaction: interactionType,
            needs_follow_up: remind,
          },
          relationships: {
            target: {
              data: {
                id: user.id,
              },
            },
          },
        },
      },
    }),
    [remind, user, interactionType]
  );
  const [create_interaction, status] = useApiCall("createInteraction");

  const close = useCallback(() => {
    set_dirty(false, "track-interaction-reminder");
    dismiss();
  }, [dismiss]);

  const onClickFinish = useCallback(() => {
    create_interaction(params).then(() => {
      analytics.log_event(
        analytics.EVENTS.follow_up[remind ? "reminder" : "no_reminder"]
      );
      onCreateInteraction?.(health);
      setTimeout(close, 1200);
    });
  }, [remind, create_interaction, params, close]);

  return (
    <div className="interaction-modal__content">
      <div className="interaction-modal__avatar">
        <Avatar
          user={user}
          size="xl"
          health={health}
          showHealthAnim={true}
          isLink={false}
        />
      </div>
      <div className="modal__body">
        <div className="interaction-modal__prompt">
          <h3>Keep it up!</h3>
          <p>
            You are doing a good job. We can send you a follow up to see if you
            were able to connect with {user.first_name}.
          </p>
        </div>
        <div className="interaction-modal__form">
          <BooleanPillRadioList
            onChange={set_remind}
            value={remind}
            name="should-remind"
            trueLabel="Remind me"
            falseLabel="Don't remind me"
          />
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <button className="filled gray" type="button" onClick={close}>
            Cancel
          </button>
        </div>
        <div className="modal__footer__right">
          {status.error && <ErrorMessage>{status.error.message}</ErrorMessage>}
          <RequestButtonWithIcon
            pending={status.pending}
            icon="checked"
            side="right"
            className="filled gray"
            disabled={remind == null}
            onClick={remind == null ? undefined : onClickFinish}
          >
            Done
          </RequestButtonWithIcon>
        </div>
      </div>
    </div>
  );
};
