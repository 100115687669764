import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { WriteAddress } from "@thrive-web/ui-api";
import { gMaps, map_place_to_address, PLACES_INCLUDED_FIELDS } from "~/utils";
import { Icon, InputWithFormHelpers, Tooltip } from "@thrive-web/ui-components";
import { useChildRef, useFocus, useStateIfMounted } from "@thrive-web/ui-hooks";
import { chain_event_listeners, maybeClassName } from "@thrive-web/ui-utils";

export const LocationInput: Preact.FunctionComponent<
  Omit<HTMLInputProps, "value" | "onChange" | "defaultValue"> & {
    onChange: (value?: WriteAddress) => void;
    onReset?: () => void;
    value?: WriteAddress;
  }
> = ({ className, onChange, value, onReset, ...props }) => {
  const [input, get_ref] = useChildRef<HTMLInputElement>();
  // google autocomplete api result
  const g_autocomplete = useRef<google.maps.places.Autocomplete | null>(null);
  const g_listener = useRef<google.maps.MapsEventListener | null>(null);

  // visual text in the input element when not focused
  const value_text = useMemo(() => {
    if (value?.common_name && value?.formatted_address) {
      if (value?.formatted_address?.startsWith(value?.common_name)) {
        return value?.formatted_address;
      }
      return `${value?.common_name}, ${value?.formatted_address}`;
    }
    return value?.common_name || value?.formatted_address;
  }, [value]);

  // visual text in the input element when focused/typing
  const [input_text, set_input_text] = useStateIfMounted(value_text);
  const on_change_text = useCallback(
    e => {
      if (!e.target.value) {
        onChange();
      }
      set_input_text(e.target.value);
    },
    [onChange]
  );

  const on_undo = useCallback(
    e => {
      if (e.key === "z" && (e.ctrlKey || e.metaKey) && !value) {
        set_input_text(value_text);
        onReset?.();
      }
    },
    [set_input_text, value_text, onReset]
  );
  const [focus, listeners] = useFocus();
  const chained_props = useMemo(
    () => ({
      ...props,
      ...chain_event_listeners(listeners, props, { onKeyDown: on_undo }),
    }),
    [listeners, props]
  );

  useEffect(() => {
    if (input.current && !g_autocomplete.current && gMaps.api) {
      g_autocomplete.current = new gMaps.api.maps.places.Autocomplete(
        input.current,
        { fields: PLACES_INCLUDED_FIELDS }
      );
      g_listener.current = g_autocomplete.current.addListener(
        "place_changed",
        () => {
          const new_place = g_autocomplete.current?.getPlace();
          onChange(
            new_place && new_place.formatted_address
              ? map_place_to_address(new_place)
              : undefined
          );
        }
      );
    }
  });

  useEffect(() => {
    if (value_text !== input_text) {
      set_input_text(value_text);
    }
  }, [value_text]);
  useEffect(
    () => () => {
      g_listener.current?.remove();
      g_autocomplete.current?.unbindAll();
      document.querySelector(".pac-container.pac-logo")?.remove();
    },
    []
  );

  return (
    <Preact.Fragment>
      <InputWithFormHelpers
        className={`location-input${maybeClassName(className)}`}
        onChange={on_change_text}
        value={focus ? input_text : value_text}
        inputRef={get_ref}
        submitOnEnter={false}
        controlled={true}
        {...chained_props}
        data-has-reset={`${!!onReset}`}
      />
      {onReset && (
        <button
          type="button"
          className="location-input__reset"
          onClick={onReset}
        >
          <Tooltip text="Revert to initial value" defaultOffset="left">
            <Icon name="jump-back" />
          </Tooltip>
        </button>
      )}
    </Preact.Fragment>
  );
};
