import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { GROUP_INVITE_STORAGE_KEY } from "~/view/components";
import {
  BuilderPageHeader,
  Icon,
  PageBody,
  MaybePlaceholderAvatar,
  TAXONOMIES_CTX,
  Tooltip,
  TransitionOneWay,
  useGroupJoin,
} from "@thrive-web/ui-components";
import {
  Redirect,
  PageContent,
  PageHeader,
  ProfilePhotoModal,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useDocumentTitle,
  useModal,
  useStateObject,
} from "@thrive-web/ui-hooks";
import {
  cache_record,
  createNamedContext,
  get_url_for_entity,
  make_title,
} from "@thrive-web/ui-utils";
import { PROFILE_BUILDER_STEPS } from "./constants";
import authWrapper from "~/auth/auth";
import { ProfileBuilderIdentity } from "./Identity";
import { ProfilePhotoPage } from "./ProfilePhoto";

export const EXPERIENCE_CATEGORIES =
  createNamedContext<null | InputOptions<string>>(null, "ExpCategoriesOptions");

export const ProfileBuilder: Preact.FunctionComponent = () => {
  const user = useAppUser();
  useDocumentTitle(() => make_title(["Profile Builder"]), []);
  const fetch_tx = useContext(TAXONOMIES_CTX.fetch);
  useEffect(() => {
    fetch_tx("ExperienceCategory");
  }, []);

  const [state, setState] = useStateObject<{
    photo?: FileUploadData;
    survey?: object;
    invites?: any[];
    experiences?: any[];
  }>({
    photo: user?.profile_picture_url
      ? {
          data: user?.profile_picture_url,
          name: user.profile_picture_url,
          mime: "",
        }
      : undefined,
  });
  const finishStep = useCallback(
    <K extends keyof typeof state>(key: K, data: typeof state[K]) => {
      setState({ [key]: data });
    },
    [setState]
  );

  const { id: group_id, invitation } = useMemo(() => {
    const text = localStorage.getItem(GROUP_INVITE_STORAGE_KEY);
    if (!text) {
      return {};
    }
    try {
      return JSON.parse(text);
    } catch (e) {
      return {};
    }
  }, []);
  const join_group = useGroupJoin(null);

  const onFinish = useCallback(async () => {
    analytics.log_event(analytics.EVENTS.self_ID_complete);
    if (group_id && user) {
      await join_group({ id: group_id }, user, invitation).catch(err => {
        console.error(`Failed to join group after signup:`, err);
      });
      localStorage.removeItem(GROUP_INVITE_STORAGE_KEY);
    }

    authWrapper
      .refreshApiUser(
        group_id ? get_url_for_entity({ id: group_id }) : "/profile"
      )
      .then(apiUser => apiUser && cache_record(apiUser, true));
  }, []);

  const step = !state.survey
    ? 0
    : !state.invites || state.invites.length === 0
    ? 1
    : !state.experiences || state.experiences.length === 0
    ? 2
    : 3;

  const profile_picture_modal_props = useMemo(
    () => ({
      onFinish: finishStep,
      user,
    }),
    [finishStep, user]
  );
  const [profile_picture_modal, set_open] = useModal({
    id: "profile-picture-upload-modal",
    className: "profile-picture-upload__modal profile-builder__photo__modal",
    body: ProfilePhotoModal,
    bodyProps: profile_picture_modal_props,
    showCloseButton: true,
  });

  return !user ? null : (
    <Preact.Fragment>
      <TransitionOneWay
        from={<ProfilePhotoPage onFinish={finishStep} user={user} />}
        to={
          <PageContent
            className="profile-builder setup-page"
            id="profile-builder-intro"
          >
            <BuilderPageHeader />
            <PageHeader
              title={
                <h1>
                  <span>Build Your Profile</span>
                  {
                    // @ts-expect-error: no name
                    <MaybePlaceholderAvatar size="md" url={state.photo?.data}>
                      <Tooltip
                        className="user-avatar__icon profile-picture-upload__icon"
                        text="Choose a different picture"
                        defaultOffset="left"
                        animation="fade"
                        delay={1000}
                      >
                        <button
                          className="profile-picture-upload__edit filled"
                          type="button"
                          onClick={() => set_open(true)}
                        >
                          <Icon name="edit" />
                        </button>
                      </Tooltip>
                    </MaybePlaceholderAvatar>
                  }
                </h1>
              }
              subtitle="Your profile is almost ready! Let’s keep going by discovering your strengths and identifying what special traits you can share."
              button={
                // @ts-expect-error: no name
                <MaybePlaceholderAvatar size="xl" url={state.photo?.data}>
                  <Tooltip
                    className="user-avatar__icon profile-picture-upload__icon"
                    text="Choose a different picture"
                    defaultOffset="left"
                    animation="fade"
                    delay={1000}
                  >
                    <button
                      className="profile-picture-upload__edit filled"
                      type="button"
                      onClick={() => set_open(true)}
                    >
                      <Icon name="edit" />
                    </button>
                  </Tooltip>
                </MaybePlaceholderAvatar>
              }
            />
            <PageBody>
              <ul className="profile-builder__intro__list">
                {PROFILE_BUILDER_STEPS.map(({ Component, ...props }, i) => {
                  return (
                    <li key={i}>
                      <Component
                        {...props}
                        step={i + 1}
                        finishStep={finishStep}
                        currentStep={step + 1}
                      />
                    </li>
                  );
                })}
                {step === 3 && <ProfileBuilderIdentity finishStep={onFinish} />}
              </ul>
            </PageBody>
          </PageContent>
        }
        start={!!state.photo}
      />
      <Redirect path="/profile-builder/:step*" to="/profile-builder" />
      {profile_picture_modal}
    </Preact.Fragment>
  );
};
