import * as Preact from "preact";
import { WriteUser } from "@thrive-web/ui-api";
import { useStateIfMounted } from "@thrive-web/ui-hooks";
import { useCallback } from "preact/hooks";
import {
  InputWithFormHelpers,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import authWrapper from "~/auth/auth";
import { is_valid_email } from "@thrive-web/ui-common";

export const RegisterEmailForm: Preact.FunctionComponent<{
  data: WriteUser;
  backButton: Preact.VNode;
  onFinish: (data: WriteUser) => void;
}> = ({ onFinish, backButton, data }) => {
  const [email, set_email] = useStateIfMounted(data.email);
  const [error, set_error] = useStateIfMounted("");

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!email || !is_valid_email(email)) {
        set_error("Please enter a valid email address.");
        return;
      }
      authWrapper.checkExistingEmail(email).then(exists => {
        if (!exists) {
          set_error("");
          onFinish({ email });
        } else {
          set_error("An account with this email already exists.");
        }
      });
    },
    [onFinish, email]
  );

  return (
    <div className="form__section register__form__section" data-part="email">
      <div className="register__form__back">{backButton}</div>
      <div className="form__section-header login-page__form__title">
        What's your email?
      </div>
      <div className="info">
        Please choose an email you can access because we will be sending you a
        verification email shortly.
      </div>
      <form onSubmit={onSubmit}>
        <div className="form__input-row">
          <WithFloatingTitle title="Email Address">
            <InputWithFormHelpers
              type="email"
              name="email"
              placeholder="Email"
              onChange={e => set_email(e.target.value)}
              autoFocus={true}
              value={email}
              submitOnEnter={true}
              validate={is_valid_email}
            />
          </WithFloatingTitle>
        </div>
        {error && <div className="info error-message">{error}</div>}
        <div className="form__footer">
          <button className="filled" type="submit">
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};
