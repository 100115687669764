import * as Preact from "preact";
import { useCallback, useContext } from "preact/hooks";
import { Event } from "@thrive-web/ui-api";
import { should_leave } from "@thrive-web/ui-model";
import { ArrayInsertPosSpecifier } from "@thrive-web/ui-common";
import {
  EventCreateModal,
  EventShareModal,
  EventsListAttendingPage,
  EventsListCreatedPage,
  EVENTS_CREATED,
} from "~/view/components";
import {
  ButtonWithIcon,
  DynamicListProvider,
  PageBody,
  PageContent,
  PageHeader,
  TabLinks,
  EVENTS,
} from "@thrive-web/ui-components";
import { useAppUser, useStateIfMounted } from "@thrive-web/ui-hooks";

const EVENTS_TAB_LINKS: NavLinkSpec[] = [
  {
    href: "/events",
    icon: "calendar",
    text: "Invited/RSVPed",
  },
  {
    href: "/events/my-events",
    icon: "pencil",
    text: "Created",
  },
];

const sort_by_date: ArrayInsertPosSpecifier<Event> = (new_event, list_item) => {
  if (!new_event.date_time) {
    return !list_item.date_time ? 0 : 1;
  } else if (!list_item.date_time) {
    return -1;
  }
  const date_n = new Date(new_event.date_time).getTime();
  const date_i = new Date(list_item.date_time).getTime();
  return date_n < date_i ? -1 : date_n === date_i ? 0 : 1;
};

export const EventsPage: Preact.FunctionComponent<RoutePageProps> = () => {
  const user = useAppUser();
  const { dispatch: dispatch_all } = useContext(EVENTS);
  const { dispatch: dispatch_created } = useContext(EVENTS_CREATED);

  const [share_target, set_share_target] = useStateIfMounted<Event | undefined>(
    undefined
  );
  const clear_share_target = useCallback(
    () => set_share_target(undefined),
    [set_share_target]
  );

  // for create event modal
  const [open, set_open] = useStateIfMounted(false);
  const dismiss = useCallback(() => {
    if (should_leave()) {
      set_open(false);
    }
  }, [set_open]);
  const open_modal = useCallback(() => set_open(true), [set_open]);

  // after creating a new event, add it to the list(s)
  const on_save_event = useCallback(
    (new_event: Event) => {
      dispatch_all.add(new_event, sort_by_date);
      dispatch_created.add(new_event, sort_by_date);
    },
    [dispatch_all, dispatch_created]
  );

  if (!user) {
    return null;
  }

  return (
    <PageContent
      id="events"
      data-page="events-list"
      className="list-page event-page"
    >
      <PageHeader
        title={<h1>Your Events</h1>}
        button={
          <ButtonWithIcon
            className="button filled gray"
            side="left"
            icon="add"
            onClick={open_modal}
          >
            Create an Event
          </ButtonWithIcon>
        }
      >
        <TabLinks links={EVENTS_TAB_LINKS} />
      </PageHeader>
      <PageBody>
        <EventsListAttendingPage
          path="/events/"
          openCreateModal={open_modal}
          setShareTarget={set_share_target}
        />
        <EventsListCreatedPage
          path="/events/my-events"
          openCreateModal={open_modal}
          setShareTarget={set_share_target}
        />
      </PageBody>
      <EventCreateModal
        open={open}
        dismiss={dismiss}
        onFinish={on_save_event}
      />
      <EventShareModal event={share_target} onClose={clear_share_target} />
    </PageContent>
  );
};

export const Events: Preact.FunctionComponent<RoutePageProps> = props => (
  <DynamicListProvider context={EVENTS_CREATED}>
    <EventsPage {...props} />
  </DynamicListProvider>
);
