import * as Preact from "preact";
import { createPortal } from "preact/compat";
import * as hooks from "preact/hooks";
import {
  media_url,
  MediaPropertiesOf,
  MediaSize,
  MetaTypes,
  Types,
} from "@thrive-web/core";
import { RecordType } from "@thrive-web/ui-api";
import { generate_id } from "@thrive-web/ui-common";
import {
  createModal,
  ModalComponentProps,
  PopupGenerator,
} from "@thrive-web/ui-components";
import { MediaSizes, MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import {
  preserveProps,
  useCallbackRef,
  useMemoRef,
  useRouteMatch,
  useStateIfMounted,
  useStateRef,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { CONTEXTS, should_leave } from "@thrive-web/ui-model";
import {
  getFirstFocusable,
  getNumNodesBeforeOverflow,
  maybe_route,
  maybeClassName,
  media_size,
  queryFocusable,
  queryPotentialFocusable,
  ReturnFocusOption,
  toggle_allow_tab_focus_batch,
} from "@thrive-web/ui-utils";

// creates a ref that tracks mounted state
export const useMounted = () => {
  const mounted = hooks.useRef<boolean>(true);
  hooks.useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return mounted;
};

export const useTimer = <P extends any[]>(
  func: (...args: P) => void,
  delay: number,
  // set true if we want the value of `func` to be bound at the time the timer is STARTED,
  // otherwise the value of `func` will be bound at the time the timer EXPIRES
  useRef?: boolean
) => {
  const timer = hooks.useRef<any>();
  const func_ref = useValueRef(func);

  const clear_timeout = hooks.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = null;
  }, []);

  const set_timeout = hooks.useCallback(
    (...args: P) => {
      clear_timeout();
      timer.current = setTimeout(() => {
        clear_timeout();
        (useRef ? func_ref.current : func)(...args);
      }, delay);
    },
    useRef ? [func] : []
  );

  hooks.useEffect(
    () => () => {
      if (!useRef) {
        clear_timeout();
      }
    },
    [func]
  );

  hooks.useEffect(() => clear_timeout, []);

  return [set_timeout, clear_timeout] as const;
};

// debounces a function with the given delay
export const useDebounce = <P extends any[]>(
  func: (...args: P) => void,
  delay: number = 100
) => {
  const [callback] = useTimer(func, delay, true);
  return useCallbackRef(callback, [func, delay]);
};

// given a record and a media property, return the url for the media
// also takes size options to determine the best size to display
export const useMedia = <
  T extends keyof MetaTypes,
  M extends MediaPropertiesOf<MetaTypes[T]>
>(
  record: RecordType<T>,
  property: M,
  // amount of the screen width the element takes up (2 = 1/2 of the width, etc.)
  size_factor: number = 1,
  // current window_size from AppContexts
  window_size?: ScreenSize,
  // always use a certain size, if we want
  force_size?: MediaSize
): string | undefined => {
  const largest_loaded = hooks.useRef<MediaSize | "full">();
  const largest_loaded_url = hooks.useRef<string | undefined>();
  return hooks.useMemo(() => {
    // if we've already loaded the full size version of the image, don't bother
    // loading smaller versions
    if (largest_loaded.current === "full") {
      return largest_loaded_url.current;
    }
    // if we have no window size data, or we want to force the size
    if (!window_size || force_size) {
      // use the forced size (or full size)
      largest_loaded.current = force_size || "full";
      largest_loaded_url.current = media_url(record, property, force_size);
      return largest_loaded_url.current;
    }
    const match = media_size(window_size, size_factor);
    // if we can't find the url for the size we want, use the full size image
    if (!match) {
      largest_loaded.current = "full";
      largest_loaded_url.current = media_url(record, property);
      // if we haven't loaded an image yet, or the new size is larger than the
      // previously loaded size, use the new url
    } else if (
      !largest_loaded.current ||
      MediaSizes[match] > MediaSizes[largest_loaded.current]
    ) {
      largest_loaded.current = match;
      largest_loaded_url.current = media_url(record, property, match);
    }
    return largest_loaded_url.current;
  }, [window_size, size_factor, record, property]);
};

// takes a node, event type, and listener callback, and returns a function that
// enables/disables the listener
export const useEventListener = <E extends EventTarget, T extends any[]>(
  node: E | undefined | null,
  eventType: string,
  funcRef: Preact.RefObject<EventListener>,
  options?: EventListenerOptions
): ((active: boolean) => void) => {
  const mounted = useMounted();
  const listenerSet = hooks.useRef(false);
  const listener = hooks.useCallback(e => {
    funcRef.current && funcRef.current(e);
  }, []);
  const removeOptions = hooks.useRef<EventListenerOptions | undefined>(
    undefined
  );

  // remove the listener whenever the target node changes
  hooks.useEffect(() => {
    return () => {
      node?.removeEventListener(eventType, listener, removeOptions.current);
      listenerSet.current = false;
      removeOptions.current = undefined;
    };
  }, [node]);

  // remove the listener on unmount
  /*hooks.useEffect(() => {
    return () => {
      node?.removeEventListener(eventType, listener, removeOptions.current);
      removeOptions.current = undefined;
    };
  }, []);*/

  return hooks.useCallback(
    (active: boolean) => {
      if (!mounted.current || !node) {
        return;
      }
      if (active && !listenerSet.current) {
        node.addEventListener(eventType, listener, options);
        removeOptions.current = options?.capture
          ? { capture: options.capture }
          : undefined;
        listenerSet.current = true;
      }
      if (!active && listenerSet.current) {
        node.removeEventListener(eventType, listener, removeOptions.current);
        listenerSet.current = false;
        removeOptions.current = undefined;
      }
    },
    [listener, options, node]
  );
};

// macro for useEventListener with window
export const useWindowEventListener = <T extends any[]>(
  eventType: keyof WindowEventMap,
  funcRef: Preact.RefObject<EventListener>,
  options?: AddEventListenerOptions
): ((active: boolean) => void) => {
  return useEventListener(window, eventType, funcRef, options);
};

// get a guaranteed unique id
export const useId = (id?: string, fallback_prefix?: string): string =>
  hooks.useMemo(
    () => id || generate_id(fallback_prefix),
    [id, fallback_prefix]
  );

// creates listeners and a state var to track focus state
export const useFocus = () => {
  const [focus, setFocus] = useStateIfMounted(false);
  const listeners = hooks.useMemo(
    () => ({
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
    }),
    [setFocus]
  );
  return [focus, listeners] as const;
};

// detect a mouse down/up action on a single target element, cancel if the mouse is dragged
export const useSingleClickDetection = (
  callbackRef: Preact.RefObject<EventListener>,
  onClickStart?: Preact.RefObject<EventListener>,
  onClickCancel?: Preact.RefObject<EventListener>
) => {
  const initial_pos = hooks.useRef<any>({});
  const [mouse_down, setMouseDown, mouse_down_ref] = useStateRef(false);
  const onMouseMove = hooks.useCallback(e => {
    if (
      Math.abs(e.clientX - initial_pos.current.x) > 3 ||
      Math.abs(e.clientY - initial_pos.current.y) > 3
    ) {
      setMouseDown(false);
      onClickCancel?.current?.(e);
    }
  }, []);
  const onMouseUp = hooks.useCallback(e => {
    const { current } = mouse_down_ref;
    setMouseDown(false);
    if (current) {
      return callbackRef.current ? callbackRef.current(e) : undefined;
    }
  }, []);
  const onWindowFocus = hooks.useCallback(e => {
    if (e.target === window) {
      setMouseDown(false);
    }
  }, []);

  hooks.useEffect(() => {
    if (!mouse_down && initial_pos.current.target) {
      initial_pos.current.target.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("focus", onWindowFocus);
      initial_pos.current = {};
    }
  }, [mouse_down]);
  return hooks.useMemo(
    () => ({
      onMouseDown: e => {
        if (e.button !== 0) {
          return;
        }
        setMouseDown(true);
        onClickStart?.current?.(e);
        initial_pos.current = { x: e.clientX, y: e.clientY, target: e.target };
        e.target.addEventListener("mousemove", onMouseMove, { once: false });
        window.addEventListener("mouseup", onMouseUp, { once: true });
        window.addEventListener("focus", onWindowFocus, { once: true });
      },
    }),
    [mouse_down]
  );
};

// memoizes a render props function, and gives it a displayName
export const useRenderPropsFunction = <Args extends any[] | object>(
  render: Args extends any[]
    ? (...args: Args) => preact.VNode | null
    : (args: Args) => preact.VNode | null,
  ComponentName: string,
  inputs: any[]
) => {
  const wrapped = hooks.useCallback(render, inputs);
  // @ts-expect-error:
  wrapped.displayName = ComponentName;

  return wrapped;
};

// creates a portal using the given container id, and allows manual showing/hiding
export const usePortal = (container_id: string, show: boolean = true) => {
  hooks.useContext(CONTEXTS.window_size);
  const container = hooks.useRef<HTMLElement | null>(null);
  container.current = document.getElementById(container_id);
  return hooks.useCallback<(vnode: preact.VNode) => preact.VNode | null>(
    vnode =>
      !container.current || !show
        ? null
        : createPortal(vnode, container.current),
    [container, show]
  );
};

// macro for popup generator with setOpen func
export const usePopup = <T extends Omit<PopupProps, "popupType">>(
  generator: PopupGenerator<T>,
  props: Omit<T, "open" | "dismiss">,
  onClose?: () => void
): [preact.VNode | null, hooks.StateUpdater<boolean>] => {
  const [open, setOpen] = useStateIfMounted(false);
  const popup = generator(
    props,
    open,
    () => {
      onClose && onClose();
      setOpen(false);
    },
    true
  );
  return [popup, setOpen];
};

// macro that takes ModalComponentProps and returns the modal VNode and setOpen func
export const useModal = (
  props: Omit<ModalComponentProps, "open" | "dismiss">,
  onClose?: () => void,
  hasForm?: boolean,
  container: string = "modal-container"
): [preact.VNode | null, hooks.StateUpdater<boolean>] => {
  const [open, setOpen] = useStateIfMounted(false);
  const onClose_ = useCallbackRef(onClose || (() => {}), [onClose], true);
  const dismiss = hooks.useCallback(() => {
    if (!hasForm || should_leave()) {
      onClose_.current && onClose_.current();
      setOpen(false);
    }
  }, [setOpen, hasForm]);
  const modal = createModal(props, open, dismiss, true, container);
  return [modal, setOpen];
};

// useModal, but the modal opens/closes based on url
export const useModalAsRoute = (
  props: Omit<ModalComponentProps, "open" | "dismiss">,
  path: string,
  exitPath?: string,
  onClose?: () => void,
  forceOpen?: boolean
): preact.VNode | null => {
  const [open, setOpen] = useStateIfMounted(false);
  const onClose_ = useCallbackRef(onClose || (() => {}), [onClose], true);
  const dismiss = hooks.useCallback(() => {
    if (!open) {
      return;
    }
    if (!exitPath) {
      window.history.go(-1);
    } else {
      maybe_route(exitPath);
    }
  }, [open, exitPath]);
  const modal = createModal(props, open, dismiss, true, "modal-container");
  const matches = useRouteMatch(path);
  hooks.useEffect(() => {
    if (forceOpen != null) {
      setOpen(forceOpen);
    } else if (matches !== open) {
      setOpen(matches);
    }
  }, [matches, open, forceOpen]);
  hooks.useEffect(() => {
    !open && onClose_.current && onClose_.current();
  }, [open]);

  return modal;
};

// returns true after the first time `shown` gets set to true
export const useFirstShown = (shown: boolean): boolean => {
  const firstShown = hooks.useRef<boolean>(shown);
  return hooks.useMemo(() => {
    if (!firstShown.current && shown) {
      firstShown.current = true;
    }
    return firstShown.current;
  }, [shown, firstShown]);
};

// returns bool that tells whether content should be rendered in the DOM
// (supports delay after closing to allow animations to finish)
export const unrenderAfterDismissed = (
  open: boolean,
  shouldUnrender: boolean,
  delay: number = 500
) => {
  const timer = hooks.useRef<any>(null);
  const [shouldRender, setShouldRender] = useStateIfMounted(
    open || !shouldUnrender
  );
  hooks.useEffect(() => {
    if (!shouldUnrender && !shouldRender) {
      setShouldRender(true);
    }
    if (!open && shouldRender && !timer.current) {
      timer.current = setTimeout(() => {
        setShouldRender(open);
        timer.current = null;
      }, delay);
    } else if (open) {
      if (!shouldRender) {
        setShouldRender(open);
      }
      if (timer.current) {
        clearTimeout(timer.current);
      }
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [open, shouldRender, setShouldRender, shouldUnrender]);
  hooks.useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    []
  );
  return shouldRender;
};

// sets an observer with the given node and callback, disconnects on unmount
export const useObserver = (
  node_ref: preact.RefObject<HTMLElement>,
  callback_ref: preact.RefObject<(...args: any[]) => void>
) => {
  const callback_func = useDebounce(callback_ref.current!);

  const new_observer = hooks.useMemo(
    () => new MutationObserver(callback_func.current || (() => {})),
    []
  );
  const observer = hooks.useRef(new_observer);

  hooks.useEffect(() => {
    observer.current.disconnect();
    node_ref.current &&
      observer.current.observe(node_ref.current, {
        subtree: true,
        childList: true,
        attributes: true,
      });
    return () => {
      observer.current.disconnect();
    };
  }, [node_ref, node_ref.current]);

  hooks.useEffect(
    () => () => {
      observer.current.disconnect();
    },
    []
  );
};

export const useResizeObserver = !("ResizeObserver" in window)
  ? // if browser doesn't support ResizeObserver, use normal observer
    (
      node_ref: preact.RefObject<HTMLElement>,
      callback_ref: preact.RefObject<() => void>,
      enabled: boolean
    ) => {
      const listener = useDebounce(callback_ref.current!, 250);
      const enableListener = useWindowEventListener("resize", listener);
      hooks.useEffect(() => () => enableListener(false), []);
      hooks.useEffect(() => enableListener(enabled), [enableListener, enabled]);
      useObserver(node_ref, callback_ref);
    }
  : (
      node_ref: preact.RefObject<HTMLElement>,
      callback_ref: preact.RefObject<(...args: any[]) => void>,
      enabled: boolean
    ) => {
      const update_timer: any = hooks.useRef();
      const callback_func = hooks.useCallback(
        (...args: any[]) => {
          if (update_timer.current) {
            clearTimeout(update_timer.current);
          }
          update_timer.current = setTimeout(
            () => callback_ref.current && callback_ref.current(...args),
            100
          );
        },
        [callback_ref]
      );

      const new_observer = hooks.useMemo(
        () => new ResizeObserver(callback_func),
        []
      );
      const observer = hooks.useRef(new_observer);

      hooks.useLayoutEffect(() => {
        observer.current.disconnect();
        node_ref.current &&
          enabled &&
          observer.current.observe(node_ref.current);
        return () => {
          observer.current.disconnect();
        };
      }, [node_ref, node_ref.current, enabled]);

      hooks.useEffect(
        () => () => {
          observer.current.disconnect();
        },
        []
      );
    };

export const useIntersectionObserver = (
  items: Element | Element[] | NodeListOf<Element>,
  callback_ref: Preact.RefObject<IntersectionObserverCallback>,
  options?: IntersectionObserverInit
) => {
  const debounced = useDebounce(callback_ref.current!, 100);
  const callback = hooks.useCallback<IntersectionObserverCallback>(
    (...args) => {
      debounced.current?.(...args);
    },
    [debounced]
  );

  const observer = hooks.useMemo(
    () => new IntersectionObserver(callback, options),
    [callback, options]
  );
  const observer_ref = hooks.useRef(observer);
  observer_ref.current = observer;
  hooks.useEffect(() => {
    // @ts-expect-error:
    if (items.entries && items.forEach) {
      (items as Element[] | NodeListOf<Element>).forEach(el =>
        observer_ref.current?.observe(el)
      );
    } else {
      // @ts-expect-error:
      observer_ref.current?.observe(items);
    }
    return () => observer.disconnect();
  }, [callback, options, items]);
};

export const useChildRef = <T extends HTMLElement>() => {
  const ref = hooks.useRef<T | null>(null);
  const getRef = hooks.useCallback(
    (elem: T | null) => {
      ref.current = elem;
    },
    [ref]
  );
  return [ref, getRef] as const;
};

export const useForwardedRef = <T extends HTMLElement>(
  setter?: (elem: T | null) => void
) => {
  const ref = hooks.useRef<T>(null);
  hooks.useLayoutEffect(() => {
    setter && setter(ref.current);
  }, [ref.current, setter]);
  return ref;
};

// takes a list of items and calculates how many can fit without overflow
// returns the calculated limit, total width of the visible items, and list of overflowed items
// also returns refs that must be set by the caller:
//  - list: ul or immediate parent of the list items
//  - container: parent of the list and menu elements
//  - menu: element that contains the overflow menu
export const useOverflowMenu = <T extends any>(items: T[], enabled) => {
  const list: Preact.RefObject<HTMLUListElement> = hooks.useRef();
  const container: Preact.RefObject<HTMLDivElement> = hooks.useRef();
  const menu: Preact.RefObject<HTMLDivElement> = hooks.useRef();
  const mounted = useMounted();
  const [num_visible_buttons, set_num_visible_buttons] = useStateIfMounted(
    list.current
      ? getNumNodesBeforeOverflow(list.current, container.current, menu.current)
      : items.length
  );

  const evaluate_limit = useCallbackRef(() => {
    if (!list.current || !mounted.current) {
      return;
    }
    const new_visible = getNumNodesBeforeOverflow(
      list.current,
      container.current,
      menu.current
    );
    set_num_visible_buttons(new_visible);
  }, [list.current, set_num_visible_buttons, container.current, menu.current]);

  hooks.useEffect(() => {
    evaluate_limit.current && evaluate_limit.current();
  }, []);

  useResizeObserver(list, evaluate_limit, enabled);

  const overflowed = hooks.useMemo(
    () => items.slice(num_visible_buttons),
    [items, num_visible_buttons]
  );
  return [list, container, menu, num_visible_buttons, overflowed] as const;
};

export const useCrudModal = <T extends keyof Types, P extends object>(
  id: string,
  Body: Preact.ComponentType<ModalBodyProps & P & { record: RecordType<T> }>,
  props: P,
  inner_class_name?: string
) => {
  const [target, set_target] = useStateIfMounted<RecordType<T> | null>(null);
  const modal_target = preserveProps(target);

  const onFinish = hooks.useCallback(
    () => setTimeout(() => set_target(null), MODAL_ANIMATION_DELAY),
    [set_target]
  );

  const bodyProps = hooks.useMemo(
    () => ({
      ...props,
      record: modal_target,
    }),
    [props, modal_target]
  );
  const [modal, set_modal_open] = useModal(
    {
      id,
      innerClassName: `card card-stack modal-form${maybeClassName(
        inner_class_name
      )}`,
      body: Body,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      bodyProps,
    },
    onFinish,
    true
  );

  hooks.useEffect(() => {
    set_modal_open(!!target);
  }, [target]);

  return [modal, set_target] as const;
};

export const useToggleAllowTabFocus = (
  container: Preact.RefObject<HTMLElement | null>,
  show: boolean,
  enabled?: boolean
) => {
  const tab_index_state = hooks.useRef<boolean>();
  hooks.useEffect(() => {
    if (!enabled || show === tab_index_state.current) {
      return;
    }
    const tabbable = container.current
      ? queryFocusable(container.current)
      : null;
    if (!tabbable || tabbable.length === 0) {
      tab_index_state.current = show;
      return;
    }

    const cancel = { current: false };
    const done = { current: false };
    requestAnimationFrame(() => {
      tab_index_state.current = show;
      const apply_attr_updates = toggle_allow_tab_focus_batch(
        tabbable,
        show,
        cancel
      );
      if (!cancel.current && apply_attr_updates) {
        apply_attr_updates.forEach(func => func());
      }
      done.current = true;
    });

    return () => {
      if (!done.current) {
        cancel.current = true;
      }
    };
  }, [show]);
};

// macro to enable/disable focus trapping for a container
export const useFocusTrap = (
  enabled: boolean,
  container: Preact.RefObject<Element>,
  trigger?: Preact.RefObject<HTMLElement>,
  return_on_focus_leave?: ReturnFocusOption,
  force_trap?: boolean,
  auto_clear_on_click_outside?: boolean
) => {
  const state = hooks.useRef(false);
  hooks.useEffect(() => {
    if (!container.current || enabled === state.current) {
      return;
    }
    if (enabled) {
      window.threadAppFocusManager.setFocusContainer(
        container.current,
        trigger?.current || null,
        return_on_focus_leave,
        force_trap,
        auto_clear_on_click_outside
      );
      state.current = true;
    } else {
      window.threadAppFocusManager.clearFocusContainer(
        container.current,
        return_on_focus_leave
      );
      state.current = false;
    }
  }, [enabled]);
  hooks.useEffect(
    () => () => {
      if (container.current && state.current) {
        window.threadAppFocusManager.clearFocusContainer(
          container.current,
          return_on_focus_leave
        );
        state.current = false;
      }
    },
    []
  );
};

export const usePopoverTabFocus = (
  container: Preact.RefObject<HTMLElement | null>,
  trigger: Preact.RefObject<HTMLElement | null>,
  show: boolean,
  enableInnerTabOrder?: boolean,
  enableDisallowFocus?: boolean,
  disableFocusTrap?: boolean
) => {
  const tab_index_state = hooks.useRef<boolean>();

  // we have refs for the trigger provided in the hook's args, and the
  // target element of the "click" event, but what we really want is the
  // focusable element among (or inside) these.
  const trigger_elem = hooks.useRef<Element | null>();
  const picked_trigger = !trigger_elem.current ? trigger : trigger_elem;
  const actual_trigger = useMemoRef(() => {
    if (!picked_trigger.current) {
      return null;
    }
    return getFirstFocusable(picked_trigger.current) || picked_trigger.current;
  }, [picked_trigger.current]);

  // listen for keydown events on the "Tab" key
  const tab_listener = useCallbackRef(
    e => {
      if (
        !show ||
        e.key !== "Tab" ||
        !document.activeElement ||
        disableFocusTrap
      ) {
        return;
      }
      const tabbable = container.current
        ? queryFocusable(container.current)
        : null;

      // if the container has no tabbable elements inside, do nothing.
      if (!tabbable || tabbable.length === 0) {
        return;
      }

      // if the current focused element (before hitting "Tab") is the trigger,
      // and we're not shift+tabbing, focus the first focusable element inside
      // the container
      if (
        (!!trigger.current?.contains(document.activeElement) ||
          !!trigger.current?.isSameNode(document.activeElement)) &&
        !e.shiftKey
      ) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        // @ts-expect-error:
        tabbable[0].focus?.();

        // if the current focused element (before hitting "Tab") is the first
        // focusable element inside the container and we ARE shift+tabbing,
        // focus the trigger
      } else if (document.activeElement.isSameNode(tabbable[0]) && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        // @ts-expect-error:
        trigger_elem.current?.focus?.();
      } else if (
        // if the current focused element (before hitting "Tab") is the last
        // focusable element inside the container and we're not shift+tabbing,
        // focus the trigger
        document.activeElement.isSameNode(tabbable[tabbable.length - 1]) &&
        !e.shiftKey
      ) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        // @ts-expect-error:
        trigger_elem.current?.focus?.();
      }
    },
    [show]
  );

  const override_tab_order = useWindowEventListener("keydown", tab_listener);
  // ready_to_trap gets set to true after mount to make sure the element is in the DOM
  // before enabling the focus trap
  const [ready_to_trap, set_ready_to_trap] = useStateIfMounted(false);
  useFocusTrap(
    !!enableInnerTabOrder && show && ready_to_trap && !disableFocusTrap,
    // @ts-expect-error:
    container,
    actual_trigger,
    "tab"
  );

  hooks.useEffect(() => {
    if (
      (!enableDisallowFocus && !enableInnerTabOrder) ||
      show === tab_index_state.current
    ) {
      return;
    }
    const tabbable = container.current
      ? queryPotentialFocusable(container.current)
      : false;

    if (!tabbable || tabbable.length === 0) {
      tab_index_state.current = show;
      return;
    }

    // if the focused element is (or is inside) the trigger, store
    // the element in our ref
    if (
      !!trigger.current?.contains(document.activeElement) ||
      !!trigger.current?.isSameNode(document.activeElement)
    ) {
      trigger_elem.current = document.activeElement;
    }

    override_tab_order(!!enableInnerTabOrder && show);

    const cancel = { current: false };
    const done = { current: false };
    // make elements inside the container focusable or not focusable,
    // depending on the `show` state
    requestAnimationFrame(() => {
      tab_index_state.current = show;
      const apply_attr_updates = toggle_allow_tab_focus_batch(
        tabbable,
        show,
        cancel
      );
      if (!cancel.current && apply_attr_updates) {
        apply_attr_updates.forEach(func => func());
      }
      done.current = true;
      set_ready_to_trap(show);
    });

    return () => {
      if (!done.current) {
        cancel.current = true;
      }
    };
  }, [show]);
};
