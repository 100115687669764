import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { Notification } from "@thrive-web/ui-api";
import { moment } from "@thrive-web/ui-common";
import { useCallbackRef, useSingleClickDetection } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import {
  Icon,
  RemovableListItem,
  RemovableListItemComponentProps,
} from "@thrive-web/ui-components";
import { useHideNotif, useNotifIconAndAction } from "./utils";

export const NotificationListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<
    MaybeClass & {
      item: Notification;
      onClick: (notif: Notification) => void;
      onUpdate: (id: string, notif?: Notification | null) => void;
      visible?: boolean;
      isMenu: boolean;
    }
  >
> = ({
  className,
  item,
  onUpdate,
  onClick,
  visible,
  isMenu,
  style,
  onRemoveItem,
  removed,
}) => {
  const on_remove = useHideNotif(item.id, onRemoveItem);
  const [icon, on_click, buttons, bottom] = useNotifIconAndAction(
    item,
    onUpdate,
    isMenu,
    on_remove
  );
  const [time, time_full] = useMemo(() => {
    // @ts-expect-error:
    const m = moment(item.created_at).tz(moment.tz.guess());
    return [
      m.format("MMMM D [at] h:mm A z"),
      m.format("dddd, MMMM Do YYYY h:mm A z"),
    ];
  }, [item.created_at]);

  const on_click_item = useCallbackRef(
    e => {
      onClick(item);
      if (
        e.target.tagName === "A" ||
        e.target.tagName === "BUTTON" ||
        e.ignoreLinkClick
      ) {
        return;
      }
      on_click();
    },
    [onClick, on_click, item],
    false
  );
  const listeners = useSingleClickDetection(on_click_item);

  const on_key_press = useCallback(
    e => {
      if (
        (visible == null || visible) &&
        (e.key === "Enter" || e.key === " ")
      ) {
        e.preventDefault();
        onClick(item);
        on_click();
      }
    },
    [onClick, on_click, item]
  );

  return (
    <li
      tabIndex={visible === false ? -1 : 0}
      className={`notif-list__item notif-item${maybeClassName(className)}`}
      {...listeners}
      onKeyPress={on_key_press}
      // @ts-expect-error:
      style={style}
    >
      <div className="notif-item__top">
        <div className="notif-item__left">{icon}</div>
        <div className="notif-item__content">
          <div className="notif-item__title">
            {item.custom_type === "view_preliminary_interaction"
              ? item.body
              : item.title}
          </div>
          <div className="notif-item__time" title={time_full}>
            {time}
          </div>
          {buttons && <div className="notif-item__actions">{buttons}</div>}
        </div>
        <div className="notif-item__right">
          {!item.read && (
            <div className="notif-item__unread">
              <Icon name="unreads" />
            </div>
          )}
        </div>
      </div>
      {bottom && <div className="notif-item__bottom">{bottom}</div>}
    </li>
  );
};

type NotifListItemProps = MaybeClass & {
  item: Notification;
  onClick: (notif: Notification) => void;
  onUpdate: (id: string, notif?: Notification | null) => void;
  visible?: boolean;
  isMenu: boolean;
};

export const NotificationListItem: Preact.FunctionComponent<NotifListItemProps> =
  props => {
    return (
      <RemovableListItem<NotifListItemProps>
        Component={NotificationListItemBase}
        {...props}
      />
    );
  };
