import * as Preact from "preact";
import {
  Event,
  Expense,
  Post,
  Reaction,
  Touchpoint,
  User,
  WriteExpense,
  WriteTouchpoint,
} from "@thrive-web/ui-api";
import {
  GroupTouchpointRemoveButton,
  ButtonWithIcon,
  DivWithIcon,
  Icon,
  UserListItem,
  usePopoverTrigger,
  Popover,
  DefaultModalContent,
  AvatarList,
  Tooltip,
} from "@thrive-web/ui-components";
import { useAppUser, useMedia, useModal } from "@thrive-web/ui-hooks";
import {
  format_dollar_amount,
  get_cache,
  get_guid_from_iri,
  get_url_for_entity,
  maybeClassName,
} from "@thrive-web/ui-utils";
import { count_label, moment, noop } from "@thrive-web/ui-common";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  ScreenSize,
  EVENT_BANNER_PLACEHOLDER_PATH,
} from "@thrive-web/ui-constants";

export const PostHelp: Preact.FunctionComponent<{ user: User }> = ({
  user,
}) => (
  <DivWithIcon
    icon="helping-hands"
    side="left"
    className="post__help post-block"
  >
    {user.first_name} is asking for help!
  </DivWithIcon>
);

export const PostTouchpoint: Preact.FunctionComponent<{
  touchpoint: Touchpoint | WriteTouchpoint;
  removeTouchpoint?: () => void;
  hasExpense?: boolean;
}> = ({ touchpoint, removeTouchpoint, hasExpense }) => {
  return (
    <div className="post__touchpoint post-block">
      <div className="post-block__left">
        <Icon name="amplify" />
      </div>
      <div className="post-block__right">
        <div className="post-block__header">
          {removeTouchpoint ? (
            <h5>Group Update</h5>
          ) : (
            <h6>{touchpoint.description}</h6>
          )}
          {removeTouchpoint ? (
            <GroupTouchpointRemoveButton
              removeTouchpoint={removeTouchpoint}
              hasExpense={!!hasExpense}
            />
          ) : (
            <div />
          )}
        </div>
        {removeTouchpoint && (
          <div className="post-block__body">{touchpoint.description}</div>
        )}
      </div>
    </div>
  );
};

export const PostExpense: Preact.FunctionComponent<{
  expense: Expense | WriteExpense;
  removeExpense?: () => void;
}> = ({ expense, removeExpense }) => {
  const self = useAppUser();
  const main_line =
    expense.requested_amount != null &&
    `Your requested reimbursement is
      ${format_dollar_amount(expense.requested_amount)}`;

  if (expense.id && expense.created_by?.id !== self?.id) {
    return null;
  }

  return (
    <div className="post__expense post-block">
      <div className="post-block__left">
        <Icon name="expense" />
      </div>
      <div className="post-block__right">
        <div className="post-block__header">
          {removeExpense ? (
            <h5>Reimbursement Request</h5>
          ) : (
            <h6>{main_line}</h6>
          )}
          {removeExpense ? (
            <ButtonWithIcon
              className="filled transparent all-gray"
              icon="remove"
              onClick={removeExpense}
            />
          ) : (
            <div />
          )}
        </div>
        {removeExpense && <div className="post-block__body">{main_line}</div>}
        <DivWithIcon icon="lock" side="left" className="post__expense__privacy">
          Reimbursements are not shared publicly.
        </DivWithIcon>
      </div>
    </div>
  );
};

export const PostTouchpointRequired: Preact.FunctionComponent<{
  goToTouchpointPage?: () => void;
}> = ({ goToTouchpointPage }) => {
  return (
    <div className="post-block post__expense__tp-required">
      <div className="post-block__left">
        <div className="post__expense__alert">
          <Icon name="alert" />
        </div>
      </div>
      <div className="post-block__right">
        <div className="post-block__header">
          <h5>A Reimbursement must also have a Group Update.</h5>
        </div>
        <div className="post-block__body">
          <ButtonWithIcon
            icon="amplify"
            side="left"
            className="filled gray"
            onClick={goToTouchpointPage}
          >
            Create a Group Update
          </ButtonWithIcon>
        </div>
      </div>
    </div>
  );
};

export const PostFormTaggedUsers: Preact.FunctionComponent<{
  users: readonly User[];
  allowEdit: boolean;
  onClickEdit: () => void;
}> = ({ users, onClickEdit, allowEdit }) => {
  const window_size = useContext(CONTEXTS.window_size);
  return (
    <div className="post-block post__form__users">
      <div className="post__form__facepile">
        <AvatarList
          users={users}
          size="sm"
          align="right"
          links={false}
          showTooltips={true}
          maxHeight={1}
          maxWidth={window_size > ScreenSize.xs ? 10 : 5}
        />
      </div>
      <div className="post__form__users__button">
        <Tooltip
          text="You may not change the tagged users for this post unless you remove the existing Group Update."
          disabled={allowEdit}
        >
          <ButtonWithIcon
            icon="pencil"
            className="pill filled gray"
            onClick={allowEdit ? onClickEdit : undefined}
          />
        </Tooltip>
      </div>
    </div>
  );
};

const POST_REACTION_USER_LIST_LIMIT = 5;

export const PostUserList: Preact.FunctionComponent<{
  users: User[];
  limit?: number;
  onClickMore: () => void;
}> = ({ users, limit, onClickMore }) => {
  const [list, show_more] = useMemo(() => {
    if (limit && limit > 0 && users.length > limit) {
      return [users.slice(0, limit), true];
    }
    return [users, false];
  }, [users, limit]);

  return (
    <div className="stack">
      <ul className="user-list post__reaction__list stack__scrolling-content">
        {list.map(u => (
          <UserListItem
            key={u.id}
            user={u}
            linkBox="all"
            size={limit ? "xs" : "sm"}
          />
        ))}
        {show_more && (
          <li key="user-list__more" className="user-list__more">
            <button className="plain-link" onClick={onClickMore}>
              + {users.length - list.length} More
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const tooltip_props = { defaultOffset: "right" } as const;
export const PostTaggedUserListModal: Preact.FunctionComponent<
  ModalBodyProps & {
    users: User[];
    limit?: number;
  }
> = ({ users, limit, closeButton }) => (
  <DefaultModalContent title="Tagged Users" closeButton={closeButton}>
    <PostUserList users={users} limit={limit} onClickMore={noop} />
  </DefaultModalContent>
);

export const PostTaggedUsers: Preact.FunctionComponent<{
  post: Post;
  containerId?: string;
  maxWidth?: number;
  maxHeight?: number;
  size?: IconSize;
  align?: "left" | "right";
}> = ({
  maxHeight = 1,
  maxWidth = 5,
  size = "sm",
  align = "right",
  containerId,
  post,
}) => {
  const id = useMemo(
    () => `${containerId}-post-user-list-${get_guid_from_iri(post.id)[0]}`,
    [post.id, containerId]
  );

  const list_props = useMemo(
    () => ({
      users:
        (post.has_tagged_user
          ?.map((u: User) => (u.email ? u : (get_cache(u.id) as User)))
          ?.filter(u => !!u) as User[]) || [],
    }),
    [post.has_reaction]
  );

  const [modal, set_modal_open] = useModal(
    {
      id: `${id}-modal`,
      innerClassName: "card post__users__list__modal stack",
      body: PostTaggedUserListModal,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      overrideScroll: !containerId,
      showCloseButton: true,
      bodyProps: list_props,
      className: containerId ? "inner-modal" : undefined,
    },
    undefined,
    false,
    containerId
  );

  const onClickMore = useCallback(() => {
    set_modal_open(true);
  }, [set_modal_open]);

  if (!post.has_tagged_user || post.has_tagged_user.length < 1) {
    return null;
  }

  return (
    <Preact.Fragment>
      <AvatarList
        users={post.has_tagged_user as User[]}
        showTooltips={true}
        tooltipProps={tooltip_props}
        align={align}
        size={size}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        onClickMore={onClickMore}
      />
      {modal}
    </Preact.Fragment>
  );
};

export const PostReactionUserListModal: Preact.FunctionComponent<
  ModalBodyProps & {
    users: User[];
    limit?: number;
  }
> = ({ users, limit, closeButton }) => (
  <DefaultModalContent title="Likes" closeButton={closeButton}>
    <PostUserList users={users} limit={limit} onClickMore={noop} />
  </DefaultModalContent>
);

// date posted, reaction/comment count
export const PostMeta: Preact.FunctionComponent<{
  post: Post;
  containerId?: string;
  popoverListLimit?: number;
}> = ({
  post,
  containerId,
  popoverListLimit = POST_REACTION_USER_LIST_LIMIT,
}) => {
  const label_ref = useRef<HTMLElement>();
  const total = post.has_reaction?.length || 0;

  const id = useMemo(
    () => `${containerId}-post-reaction-list-${get_guid_from_iri(post.id)[0]}`,
    [post.id, containerId]
  );

  const [date_str, date_short, date_full] = useMemo(() => {
    // @ts-expect-error:
    const m = moment(post.created_at).tz(moment.tz.guess());
    return [
      m.format("MMMM D, YYYY"),
      m.format(m.year() !== moment().year() ? "MM/DD/YY" : "MMM D"),
      m.format("dddd, MMMM Do YYYY h:mm A z"),
    ];
  }, [post.created_at]);

  const list_props = useMemo(
    () => ({
      users:
        (post.has_reaction
          ?.map((r: Reaction) =>
            r.created_by?.id ? (get_cache(r.created_by?.id) as User) : null
          )
          ?.filter(u => !!u) as User[]) || [],
    }),
    [post.has_reaction]
  );

  const [modal, set_modal_open] = useModal(
    {
      id: `${id}-modal`,
      innerClassName: "card post__users__list__modal stack",
      body: PostReactionUserListModal,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      overrideScroll: !containerId,
      showCloseButton: true,
      bodyProps: list_props,
      className: containerId ? "inner-modal" : undefined,
    },
    undefined,
    false,
    containerId
  );

  const [open, set_open, listeners, window_listeners] = usePopoverTrigger(
    { click: false, focus: true, hover: true },
    id,
    800
  );

  const onClick = useCallback(() => {
    if (total <= POST_REACTION_USER_LIST_LIMIT) {
      set_open(!open, true);
    } else {
      set_modal_open(true);
      label_ref.current?.blur();
    }
  }, [set_open, set_modal_open, total, open, containerId]);

  const onClickMore = useCallback(() => {
    set_modal_open(true);
    set_open(false);
  }, [set_open, set_modal_open]);

  const reactions_text = useMemo(
    () => count_label(total, "Like"),
    [post.has_reaction]
  );

  const Button =
    containerId || total > POST_REACTION_USER_LIST_LIMIT ? "button" : "span";

  return (
    <div className="post__meta">
      <div className="post__meta__left">
        {list_props.users.length > 0 ? (
          <Popover
            id={id}
            triggerComponent={
              <Button
                // @ts-expect-error:
                ref={label_ref}
                tabIndex={0}
                {...listeners}
                className={`plain-link${maybeClassName(listeners.className)}`}
                onClick={onClick}
                data-text={reactions_text}
              >
                {reactions_text}
              </Button>
            }
            disableTabIndexOnClose={true}
            show={open}
            windowProps={window_listeners}
          >
            <PostUserList
              users={list_props.users}
              limit={popoverListLimit}
              onClickMore={onClickMore}
            />
          </Popover>
        ) : (
          reactions_text
        )}
        , {count_label(post.has_comment?.length || 0, "Comment")}
      </div>
      <div className="post__meta__right" title={date_full}>
        <div data-screen-above="sm">{date_str}</div>
        <div data-screen-below="md">{date_short}</div>
      </div>
      {total > POST_REACTION_USER_LIST_LIMIT && modal}
    </div>
  );
};

export const PostEvent: Preact.FunctionComponent<{
  event: Event;
  isLink?: boolean;
}> = ({ event, isLink }) => {
  const date = useMemo(
    () => moment(event.date_time).format("MMMM Do, YYYY"),
    [event.date_time]
  );
  const link = useMemo(() => get_url_for_entity(event), [event]);

  const window_size = useContext(CONTEXTS.window_size);
  const img_url = useMedia<"Event", "cover_image">(
    event,
    "cover_image",
    1,
    window_size
  );

  const Tag = isLink ? "a" : "div";
  return (
    <Tag
      {...(isLink
        ? {
            href: link,
          }
        : {})}
      className="post-block banner-header post__event"
      style={{
        backgroundImage: `url(${img_url || EVENT_BANNER_PLACEHOLDER_PATH})`,
      }}
      onClick={e => {
        e.ignoreLinkClick = true;
      }}
    >
      <div className="post__event__header">
        <div className="post__event__title">
          {event.is_canceled ? "CANCELED - " : ""}
          {event.name}
        </div>
        <div className="post__event__date">{date}</div>
      </div>
    </Tag>
  );
};
