import * as Preact from "preact";
import { useContext, useEffect } from "preact/hooks";
import { PureComponent } from "preact/compat";
import {
  WithUser,
  SiteModal,
  SiteHeader,
  NavSidebar,
  UserSidebar,
  WithGlobalData,
} from "@thrive-web/ui-components";
import {
  DEFAULT_GROUP_BACKGROUND_COLOR,
  PO_EDGE_BUFFER_LG,
  PO_EDGE_BUFFER_SM,
} from "@thrive-web/ui-constants";
import {
  RoutedApp,
  NotificationMenu,
  InteractionModal,
  NAV_LINKS,
} from "~/view/components";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  useCallbackRef,
  useStateIfMounted,
  useWindowEventListener,
} from "@thrive-web/ui-hooks";
import { setPageAttr } from "@thrive-web/ui-utils";
import { getCurrentUrl } from "preact-router";

// main component where the sections of the page are mounted
export const View: Preact.FunctionComponent = () => {
  const modal = useContext(CONTEXTS.modal);
  const login_modal = useContext(CONTEXTS.login_modal);
  const auth = useContext(CONTEXTS.auth);
  const dispatch = useContext(CONTEXTS.dispatch);

  useEffect(() => {
    setPageAttr(getCurrentUrl());
  }, []);

  // detect if using a touch screen to determine certain behaviors/styles throughout the app
  const [is_touch_screen, set_is_touch_screen] =
    useStateIfMounted<boolean>(false);
  const touch_listener = useCallbackRef(
    e => {
      set_is_touch_screen(true);
      window["is_touch_screen"] = true;
    },
    [set_is_touch_screen]
  );
  const enable_touch_listener = useWindowEventListener(
    "touchstart",
    touch_listener
  );
  useEffect(() => {
    enable_touch_listener(!is_touch_screen);
  }, [is_touch_screen]);

  return (
    <div
      className="site"
      data-touch-screen={`${is_touch_screen}`}
      style={{
        "--po-edge-buffer-lg": `${PO_EDGE_BUFFER_LG}px`,
        "--po-edge-buffer-sm": `${PO_EDGE_BUFFER_SM}px`,
        "--color-overlay-background-color": DEFAULT_GROUP_BACKGROUND_COLOR,
      }}
    >
      <fieldset
        id="site-main"
        {...((modal && modal.open) || (login_modal && login_modal.open)
          ? {
              disabled: true,
              tabIndex: -1,
            }
          : {})}
      >
        <div className="site__scroll-override-container">
          <SiteHeader navLinks={NAV_LINKS} />
          <WithGlobalData>
            <div className="row site-body">
              <div id="popover-intersect" />
              <NavSidebar navLinks={NAV_LINKS} />
              <RoutedApp auth={auth} dispatch={dispatch} />
              <UserSidebar notificationMenu={<NotificationMenu />} />
            </div>
          </WithGlobalData>
        </div>
      </fieldset>
      <SiteModalWithInteractionModal />
    </div>
  );
};

const user_include = ["has_connection" as const];
const SiteModalWithInteractionModal: Preact.FunctionComponent = () => {
  const interaction_props = useContext(CONTEXTS.interaction_data);

  return (
    <SiteModal>
      {interaction_props && (
        <WithUser id={interaction_props.user.id} include={user_include}>
          <InteractionModal {...interaction_props} />
        </WithUser>
      )}
    </SiteModal>
  );
};

export class ViewWrapper extends PureComponent<{}, { mounted: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }
  componentDidMount() {
    this.setState({ mounted: true });
  }
  render() {
    return this.state.mounted ? this.props.children : null;
  }
}
