import { ApiMethodParameters } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { route } from "preact-router";
import { Group, User } from "@thrive-web/ui-api";
import {
  useApiFetch,
  useDynamicListVariable,
  useModal,
  useModalAsRoute,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { cache_record, get_url_for_entity } from "@thrive-web/ui-utils";
import {
  ButtonWithIcon,
  Carousel,
  DefaultModalContent,
  DeleteModalBody,
  GROUP_DISPATCH,
  GroupCreate,
  GroupEdit,
  GroupMemberInvite,
} from "@thrive-web/ui-components";

export const GroupCreateModalBody: Preact.FunctionComponent<
  ModalBodyProps & {
    onCreate?: (group: Group) => void;
  }
> = ({ closeButton, dismiss, onCreate }) => {
  const [pageNumber, setPage] = useStateIfMounted("1");
  const [group, setGroup] = useStateIfMounted<Group | null>(null);

  const onFinishPageOne = useCallback(
    (new_group: Group) => {
      onCreate?.(new_group);
      setGroup(new_group);
      cache_record(new_group);
      setPage("2");
    },
    [setPage, setGroup, onCreate]
  );
  const onFinishPageTwo = useCallback(() => {
    if (!group || !group["id"]) {
      return;
    }
    const href = get_url_for_entity(group);
    group && route(href);
  }, [group]);

  const [members, update_members] = useDynamicListVariable<User>([]);

  const pages = {
    "1": <GroupCreate onFinish={onFinishPageOne} dismiss={dismiss} />,
    "2": (
      <GroupMemberInvite
        onFinish={onFinishPageTwo}
        members={members as User[]}
        addMember={update_members.add}
        group_id={group?.id || ""}
        allowLink={true}
        isAdmin={true}
      />
    ),
  };
  return (
    <DefaultModalContent title="Create a Group" closeButton={closeButton}>
      <div className="group-create__body">
        <Carousel page={pageNumber as keyof typeof pages} items={pages} />
      </div>
    </DefaultModalContent>
  );
};

export const GroupCreateModal: Preact.FunctionComponent<{
  onClose?: () => void;
  onCreate?: (group: Group) => void;
}> = ({ onClose, onCreate }) => {
  const bodyProps = useMemo(() => ({ onCreate }), [onCreate]);
  return useModalAsRoute(
    {
      id: "group-create",
      body: GroupCreateModalBody,
      className: "group-create__modal",
      showCloseButton: true,
      dismissOnClickBackdrop: true,
      bodyProps,
    },
    "/groups/create",
    "",
    onClose
  );
};

export const useGroupEditModal = (group: Group, onClose?: () => void) => {
  const updateGroup = useContext(GROUP_DISPATCH);
  const onFinish = useCallback(
    (new_group: Group) => {
      updateGroup("group", new_group);
    },
    [updateGroup]
  );
  const EditGroupModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) => (
      <GroupEdit
        group={group}
        closeButton={closeButton}
        onFinish={onFinish}
        {...props}
      />
    ),
    "GroupEdit-ModalBody",
    [group, onFinish]
  );

  return useModal(
    {
      id: "update-group-modal",
      innerClassName: "card card-stack modal-form group-update",
      body: EditGroupModalBody,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
    },
    onClose,
    true
  );
};

export const GroupEditModal: Preact.FunctionComponent<{ group: Group }> = ({
  group,
}) => {
  const [editGroupModal, openEditGroupModal] = useGroupEditModal(group);
  const openModal = useCallback(
    () => openEditGroupModal(true),
    [openEditGroupModal]
  );

  return (
    <ButtonWithIcon
      icon="edit"
      side="left"
      className="filled pill gray"
      onClick={openModal}
    >
      Edit Group
      {editGroupModal}
    </ButtonWithIcon>
  );
};

const delete_params: ApiMethodParameters<"PATCH", "Group"> = {
  body: {
    data: {
      attributes: {
        is_archived: true,
      },
    },
  },
};
export const GroupDeleteModal: Preact.FunctionComponent<{ group: Group }> = ({
  group,
}) => {
  const deleteGroup = useApiFetch("updateGroup", group.id, delete_params);
  const submitDeleteGroup = useCallback(() => {
    if (!group) {
      return Promise.resolve();
    }
    return deleteGroup().then(res => {
      analytics.log_event(
        analytics.EVENTS.group_archived,
        undefined,
        undefined,
        true
      );
      return res;
    });
  }, [deleteGroup, group]);

  const onFinish = useCallback(() => {
    route("/groups");
  }, []);
  const DeleteGroupModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) => (
      <DefaultModalContent title="Archive Group" closeButton={closeButton}>
        <DeleteModalBody
          deleteRecord={submitDeleteGroup}
          afterDelete={onFinish}
          {...props}
        >
          Are you sure you want to archive this group? You will lose access to
          all of the group's posts and content.
        </DeleteModalBody>
      </DefaultModalContent>
    ),
    "GroupDelete-ModalBody",
    [group, onFinish]
  );

  const [deleteGroupModal, openDeleteGroupModal] = useModal(
    {
      id: "update-group-modal",
      innerClassName: "card card-stack modal-form group-delete",
      body: DeleteGroupModalBody,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
    },
    undefined,
    true
  );

  const openModal = useCallback(
    () => openDeleteGroupModal(true),
    [openDeleteGroupModal]
  );

  return (
    <ButtonWithIcon
      icon="lock"
      side="left"
      className="filled pill negative"
      onClick={openModal}
    >
      Archive Group
      {deleteGroupModal}
    </ButtonWithIcon>
  );
};
