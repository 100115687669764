import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import {
  ButtonWithIcon,
  CompoundButton,
  RequestButtonWithIcon,
  Tooltip,
} from "@thrive-web/ui-components";
import { useApiMethod, useRequest, useStateRef } from "@thrive-web/ui-hooks";
import { copy_to_clipboard } from "@thrive-web/ui-utils";

export const GroupMemberInviteLinkButton: Preact.FunctionComponent<{
  groupId: string;
}> = ({ groupId }) => {
  /*const self = useAppUser();
  const get_params = useMemo<ApiMethodParameters<"GET", "GroupInvitation">>(
    () => ({
      query: {
        filter: [
          ["=", ["this", "GroupInvitation:group"], ["id", groupId]],
          [
            "=",
            ["this", "GroupInvitation:referrer"],
            ["id", self!.id as string],
          ],
          ["=", ["this", "GroupInvitation:is_accepted"], false],
        ],
      },
    }),
    [groupId]
  );
  const get_link_req = useApiFetch("getGroupInvites", get_params);*/
  const create_link_req = useApiMethod("createGroupInvite");
  const create_link = useCallback(() => {
    return create_link_req({
      body: {
        data: {
          attributes: {},
          relationships: {
            group: {
              data: {
                id: groupId,
              },
            },
          },
        },
      },
    }).then(res => {
      analytics.log_event(analytics.EVENTS.group_invite_link);
      return res;
    });
  }, [create_link_req, groupId]);

  const [, set_link, link_ref] = useStateRef<string | undefined>(undefined);
  const [get_link, status] = useRequest(create_link);

  const on_click = useCallback(() => {
    if (link_ref.current) {
      copy_to_clipboard(link_ref.current);
    } else {
      get_link().then(invite => {
        if (invite.data.link) {
          set_link(invite.data.link);
          copy_to_clipboard(invite.data.link);
        }
      });
    }
  }, []);

  const items = [
    <RequestButtonWithIcon
      icon="share"
      side="left"
      className="filled gray"
      successText="Link copied to clipboard!"
      onClick={on_click}
      {...status}
      successIcon="checked"
      showError={true}
      type="button"
    >
      Share link to join this group
    </RequestButtonWithIcon>,
  ];
  if (!status.pending && status.success) {
    items.push(
      <Tooltip
        delay={500}
        text="Each invitation link can only be used once. Click here to generate another link."
      >
        <ButtonWithIcon
          icon="jump-back"
          className="filled gray"
          onClick={() => {
            get_link().then(invite => {
              if (invite.data.link) {
                set_link(invite.data.link);
                copy_to_clipboard(invite.data.link);
              }
            });
          }}
        />
      </Tooltip>
    );
  }

  return <CompoundButton items={items} className="filled gray" />;
};
