import { Group } from "@thrive-web/ui-api";
import * as Preact from "preact";
import { useContext } from "preact/hooks";
import {
  GROUP_DETAIL_CONTEXTS,
  PermCheck,
  usePermCheck,
} from "@thrive-web/ui-components";

export const GroupPermCheck: Preact.FunctionComponent<{
  role: "has_member" | "has_admin";
}> = props => {
  const group = useContext(GROUP_DETAIL_CONTEXTS.group);
  return <PermCheck type="group_role" group={group} {...props} />;
};

export const useGroupPerms = (
  role: "has_member" | "has_admin",
  group: Group | null = null
): boolean => {
  const group_ = useContext(GROUP_DETAIL_CONTEXTS.group) || group;
  return usePermCheck("group_role", { group: group_, role });
};
