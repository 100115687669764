import { ApiMethodParameters } from "@thrive-web/core";
import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { Address, Event, EventRSVP, User } from "@thrive-web/ui-api";
import {
  MODAL_ANIMATION_DELAY,
  RSVPResponses,
  EVENT_BANNER_PLACEHOLDER_PATH,
  ScreenSize,
} from "@thrive-web/ui-constants";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  add_item_to,
  analytics,
  capitalize,
  ensure_id_is_iri,
  moment,
  truncate_paragraph,
} from "@thrive-web/ui-common";
import {
  EventCancelModal,
  EventDetailLoading,
  EventEditModal,
  EventInviteModal,
  AttendeeUserListModal,
  StaticMap,
} from "~/view/components";
import {
  Avatar,
  ButtonWithIcon,
  Card,
  DefaultDropdownButton,
  DivWithIcon,
  DropdownMenu,
  Icon,
  PageBody,
  PageContent,
  PageHeader,
  PageSidebar,
  PillRadioList,
  RSVP_OPTIONS,
  useAsyncRenderResult,
  EVENTS,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiRequest,
  useAppUser,
  useDynamicListUpdaters,
  useModal,
  useRecordForDetailPage,
  useStateIfMounted,
  useValueRef,
  useDocumentTitle,
  useMedia,
} from "@thrive-web/ui-hooks";
import { display_text, make_title } from "@thrive-web/ui-utils";

const popover_props = {
  defaultDirection: "bottom",
  defaultOffset: "left",
} as const;

export const EventDetail: Preact.FunctionComponent<{
  event: Event;
  onUpdateEvent: (new_event: Event) => void;
}> = ({ event, onUpdateEvent }) => {
  const self = useAppUser();
  // formatted string for the date/time of the event
  const date_str = useMemo(() => {
    return event.date_time
      ? moment(event.date_time).format("dddd, MMMM Do YYYY [at] h:mm A")
      : "";
  }, [event.date_time]);

  const create_rsvp = useApiMethod("createEventRSVP");
  const update_rsvp = useApiMethod("updateEventRSVP");

  const { dispatch: update_events } = useContext(EVENTS);

  const event_ref = useValueRef(event);
  const rsvps = (event.has_rsvp as readonly EventRSVP[]) || null;
  const set_rsvps = useCallback(
    (list: EventRSVP[] | null) => {
      onUpdateEvent({ ...event_ref.current, has_rsvp: list || undefined });
    },
    [onUpdateEvent]
  );
  const [update_rsvps] = useDynamicListUpdaters(rsvps || null, set_rsvps);

  // the current user's rsvp, as of page load
  const initial_rsvp = useMemo(() => {
    return rsvps?.find(r => r.created_by?.id === self?.id)?.response as
      | RSVPResponses
      | undefined;
  }, []);
  // the user's current rsvp status for the event, if it exists
  const [rsvp, set_rsvp] = useStateIfMounted<RSVPResponses | undefined>(
    initial_rsvp
  );
  const on_change_rsvp = useCallback(
    (opt: InputOption<RSVPResponses>) => {
      // if there's an existing rsvp for this user, update that record
      const existing = rsvps?.find(r => r.created_by?.id === self?.id);
      const params = {
        body: { data: { attributes: { response: opt.value } } },
      };
      if (existing) {
        update_rsvp(existing.id, params)
          .then(({ data }) => {
            analytics.log_event(analytics.EVENTS.event_RSVP);
            data &&
              update_rsvps.update(r => r.id === data.id, {
                ...data,
                created_by: self!,
              });
          })
          .catch(() => {
            set_rsvp(existing?.response as RSVPResponses);
          });
      } else {
        // if the user hasn't rsvp'd yet, create a new rsvp record
        // @ts-expect-error:
        params.body.data.relationships = { event: { data: { id: event.id } } };
        create_rsvp(params)
          .then(({ data }) => {
            update_rsvps.add({ ...data, created_by: self! });
          })
          .catch(() => {
            set_rsvp(undefined);
          });
      }
      set_rsvp(opt.value);
    },
    [set_rsvp, rsvp]
  );

  // when rsvp list changes, update state with the user's rsvp record from the list
  useEffect(() => {
    if (!rsvp && !!rsvps?.length) {
      set_rsvp(
        rsvps.find(r => r.created_by?.id === self?.id)?.response as
          | RSVPResponses
          | undefined
      );
    }
  }, [rsvps, self?.id]);

  useEffect(
    () => () => {
      set_rsvp(undefined);
      update_rsvps.reset(null);
    },
    []
  );

  const invite_modal_props = useMemo(
    () => ({
      onSuccess: (user: User) =>
        event_ref.current
          ? onUpdateEvent({
              ...event_ref.current,
              has_invitee: add_item_to(
                event_ref.current.has_invitee || [],
                user,
                "end"
              ),
            })
          : undefined,
      eventId: event.id,
    }),
    [event.id, onUpdateEvent]
  );
  const [invite_modal, set_modal_open] = useModal({
    id: "event-invite-modal",
    className: "event-detail__invite__modal modal-form",
    body: EventInviteModal,
    bodyProps: invite_modal_props,
  });

  // use one modal for all three rsvp status lists
  const [attendee_modal_category, set_attendee_modal_category] =
    useStateIfMounted<RSVPResponses | "invited" | undefined>(undefined);
  const clear_attendee_modal_category = useCallback(
    () =>
      setTimeout(() => {
        set_attendee_modal_category(undefined);
      }, MODAL_ANIMATION_DELAY),
    [set_attendee_modal_category]
  );

  // group rsvps by response
  const responses = useMemo(() => {
    const res = {
      going: [],
      maybe: [],
      declined: [],
      invited: event.has_invitee || [],
    };
    rsvps?.forEach(r => {
      res[r.response || ""]?.push(r);
    });
    return res;
  }, [rsvps, event.has_invitee]);

  const on_update = useCallback(
    (new_event: Event) => {
      onUpdateEvent(new_event);
      update_events.update(e => e.id === event.id, new_event);
    },
    [update_events, onUpdateEvent]
  );
  const on_cancel = useCallback(() => {
    setTimeout(
      () => on_update({ ...event, is_canceled: true }),
      MODAL_ANIMATION_DELAY
    );
  }, [on_update]);

  const is_creator = event.created_by?.id === self?.id;

  const window_size = useContext(CONTEXTS.window_size);
  const img_url = useMedia<"Event", "cover_image">(
    event,
    "cover_image",
    1,
    window_size
  );

  const map = event.location ? (
    <Card className="event-detail__map">
      <StaticMap location={event.location} />
      <div className="event-detail__map__address">
        <h3>{(event.location as Address)?.common_name}</h3>
        <p>{(event.location as Address)?.formatted_address}</p>
      </div>
    </Card>
  ) : null;

  const creator = (
    <DivWithIcon
      className="event-detail__creator"
      data-size="md"
      icon={<Avatar user={event.created_by as User} size="md" isLink={true} />}
      side="left"
    >
      Created by {(event.created_by as User)?.full_name}
    </DivWithIcon>
  );

  return (
    <PageContent
      id="event-detail-page"
      className="event-page detail-page has-sidebar"
    >
      <PageHeader className="banner-header">
        <div
          className="event-page__banner page-header__banner"
          style={{
            backgroundImage: `url(${img_url || EVENT_BANNER_PLACEHOLDER_PATH})`,
          }}
        >
          <div className="page-header__subtitle">
            <span>{date_str}</span>
            <DivWithIcon
              className="event-detail__privacy"
              icon={event.is_public ? "globe" : "lock"}
              side="left"
            >
              {event.is_public ? "Public" : "Private"}
            </DivWithIcon>
          </div>
          <div className="page-header__title">
            <h3>{display_text(event.name)}</h3>
            {is_creator && !event.is_canceled && (
              <div className="page-header__action-menu">
                <DropdownMenu
                  items={[
                    <EventEditModal target={event} onFinish={on_update} />,
                    <EventCancelModal target={event} onFinish={on_cancel} />,
                  ]}
                  button={
                    <DefaultDropdownButton
                      className="filled transparent all-gray"
                      icon="edit"
                    />
                  }
                  listClassName="card pill-card"
                  allowLinkEventPropagation={true}
                  closeOnClickItem={true}
                  popoverProps={popover_props}
                />
              </div>
            )}
          </div>
          {event.is_canceled && (
            <div className="event-detail__cancelled">
              This event was cancelled
            </div>
          )}
        </div>
      </PageHeader>
      <PageBody>
        <div className="event-detail__content">
          {!event.is_canceled && (
            <Preact.Fragment>
              {window_size <= ScreenSize.md && creator}
              <div className="page-section__header">
                <h3>Your RSVP</h3>
              </div>
              <div className="page-section__content event-detail__rsvp">
                <PillRadioList
                  label=""
                  name="rsvp"
                  onSelectOption={on_change_rsvp}
                  options={RSVP_OPTIONS}
                  value={rsvp}
                  disabled={!rsvps}
                />
              </div>
              <div className="page-section__header">
                <h3>Responses</h3>
              </div>
              <div className="page-section__content">
                <div className="event-detail__responses">
                  {Object.entries(responses).map(([label, list]) => {
                    const number = rsvps ? (
                      list?.length
                    ) : (
                      <Icon name="help-outline-circle" iconSize="lg" />
                    );
                    const BlockTag =
                      rsvps && list.length > 0 ? "button" : "div";

                    return label !== "declined" || is_creator ? (
                      <BlockTag
                        className={`event-detail__responses__block${
                          !rsvps
                            ? " loading-item__shaded loading-item__shaded--dark"
                            : ""
                        }`}
                        onClick={
                          list.length > 0
                            ? () =>
                                set_attendee_modal_category(
                                  label as RSVPResponses
                                )
                            : undefined
                        }
                      >
                        <div className="event-detail__responses__count">
                          {number}
                        </div>
                        <div className="event-detail__responses__label">
                          {capitalize(label)}
                        </div>
                      </BlockTag>
                    ) : null;
                  })}
                  <AttendeeUserListModal
                    event={event}
                    category={attendee_modal_category}
                    clearCategory={clear_attendee_modal_category}
                  />
                </div>
                {event.created_by?.id === self?.id && (
                  <div className="event-detail__invite">
                    <ButtonWithIcon
                      icon="connection-request"
                      side="left"
                      className="filled blue"
                      onClick={() => set_modal_open(true)}
                    >
                      Invite one of your connections
                    </ButtonWithIcon>
                    {invite_modal}
                  </div>
                )}
              </div>
            </Preact.Fragment>
          )}
          {event.description && (
            <Preact.Fragment>
              <div className="page-section__header">
                <h3>Details</h3>
              </div>
              <div className="page-section__content event-detail__description">
                {display_text(event.description)}
              </div>
            </Preact.Fragment>
          )}
          {window_size <= ScreenSize.md && map && (
            <Preact.Fragment>
              <div className="page-section__header">
                <h3>Location</h3>
              </div>
              <div className="page-section__content">{map}</div>
            </Preact.Fragment>
          )}
        </div>
        <PageSidebar className="event-detail__sidebar">
          {creator}
          {map}
        </PageSidebar>
      </PageBody>
    </PageContent>
  );
};

export const EventDetailPage: Preact.FunctionComponent<RoutePageProps> = ({
  matches = {},
}) => {
  const id = useMemo(
    () => ensure_id_is_iri(matches.id || "", "Event"),
    [matches.id]
  );

  const [get_event, status] = useApiRequest(
    "getEvent",
    id,
    useMemo<ApiMethodParameters<"GET", "Event">>(
      () => ({
        query: {
          include: [
            "has_invitee.User:profile_picture",
            "created_by",
            "location",
            "has_rsvp",
          ],
          fields: {
            User: DEFAULT_USER_FIELDS,
          },
        },
      }),
      []
    )
  );
  const [event, , update_event] = useRecordForDetailPage(id, get_event);
  const page_props = useMemo(
    () => ({ onUpdateEvent: update_event }),
    [update_event]
  );

  useDocumentTitle(
    () =>
      make_title(
        event?.name
          ? [truncate_paragraph(event.name, 64), "Events"]
          : ["events"]
      ),
    [event?.name]
  );

  return useAsyncRenderResult(
    (result, pending, passthruProps) => (
      <EventDetail event={result} {...passthruProps!} />
    ),
    [],
    status,
    event,
    true,
    undefined,
    EventDetailLoading,
    false,
    page_props
  );
};
