import { Atom } from "@thi.ng/atom";

export const state_id_key = "CUSTOM_HISTORY_STATE_ID";

let initial_id = Date.now();
const next_id = () => initial_id++;
let prev_state = (!window.history.state || {})[state_id_key];
if (!prev_state) {
  prev_state = next_id();
  window.history.replaceState({ [state_id_key]: prev_state }, "");
}

export const dirty_state = new Atom({ dirty: 0 });
export const DIRTY_FORM_MESSAGE =
  "Are you sure you want to leave this page and abandon your changes?";

// for debugging
let dirty_forms: any = {};

// indicate that there's a dirty form on the current page
export const set_dirty = (
  dirty: boolean,
  name: string,
  reset: boolean = false
) => {
  if (reset) {
    dirty_forms = {};
  } else if (dirty) {
    dirty_forms[name] = true;
  } else {
    delete dirty_forms[name];
  }
  console.debug("Dirty forms: ", Object.keys(dirty_forms));
  return dirty_state.resetIn(
    "dirty",
    reset ? 0 : Math.max(dirty_state.deref().dirty + (dirty ? 1 : -1), 0)
  );
};

// check if there's a dirty form on the page before navigating away
export const should_leave = (): boolean => {
  if (dirty_state.deref().dirty === 0) {
    return true;
  }
  console.debug("Dirty forms preventing leave:", Object.keys(dirty_forms));
  if (window.confirm(DIRTY_FORM_MESSAGE)) {
    set_dirty(false, "", true);
    return true;
  }
  return false;
};

// override preact-router's link click listener to add our check
export const onClickLink = e => {
  // copied from preact-router
  // ignore events the browser takes care of already:
  if (e.ctrlKey || e.metaKey || e.altKey || e.shiftKey || e.button !== 0) {
    return;
  }
  let t = e.target;
  do {
    if (String(t.nodeName).toUpperCase() === "A" && t.getAttribute("href")) {
      // end copy from preact-router
      if (!t.hasAttribute("native") && !should_leave()) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
      }
    }
  } while ((t = t.parentNode));
};

// add listeners to prevent leaving on a dirty form
export const onPopState = e => {
  const new_state = (window.history.state || {})[state_id_key];
  if (prev_state !== new_state && !should_leave()) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    window.history.go(prev_state < new_state ? -1 : 1);
    return false;
  }
  prev_state = new_state;
};

export const onBeforeUnload = e => {
  if (dirty_state.deref().dirty !== 0) {
    console.debug("Dirty forms preventing leave:", Object.keys(dirty_forms));
    e.returnValue = DIRTY_FORM_MESSAGE;
    return DIRTY_FORM_MESSAGE;
  }
};

// override window.history methods to add dirty check

const original_replaceState = window.history.replaceState;
function wrapReplaceState(data: any, title: string, url?: string | null) {
  if (should_leave()) {
    const data_with_id = {
      ...data,
      [state_id_key]: (this.state && this.state[state_id_key]) || next_id(),
    };
    prev_state = data_with_id[state_id_key];
    return original_replaceState.apply(window.history, [
      data_with_id,
      title,
      url,
    ]);
  }
}
window.history.replaceState = wrapReplaceState.bind(window.history);

const original_pushState = window.history.pushState;
function wrapPushState(data: any, title: string, url?: string | null) {
  if (should_leave()) {
    const data_with_id = {
      ...data,
      [state_id_key]: next_id(),
    };
    prev_state = data_with_id[state_id_key];
    return original_pushState.apply(window.history, [data_with_id, title, url]);
  }
}

window.history.pushState = wrapPushState.bind(window.history);

export const initRoutingListeners = () => {
  window.addEventListener("beforeunload", onBeforeUnload, {
    capture: true,
  });
  window.addEventListener("popstate", onPopState, { capture: true });
  window.addEventListener("click", onClickLink, { capture: true });
};
