import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import { RelationshipFulfillmentSurvey } from "@thrive-web/ui-api";
import {
  Carousel,
  FooterError,
  PillRadioList,
  RequestButton,
} from "@thrive-web/ui-components";
import { useApiCall, useStateObject, useStateRef } from "@thrive-web/ui-hooks";

const FULFILLMENT_SURVEY_OPTIONS: InputOptions<number> = [
  { value: 1, label: "Agree" },
  { value: 2, label: "Neutral" },
  { value: 3, label: "Disagree" },
];

export const FulfillmentSurveyForm: Preact.FunctionComponent<{
  dismiss: () => void;
  onFinish: (data: RelationshipFulfillmentSurvey) => void;
}> = ({ onFinish, dismiss }) => {
  const [page, set_page, page_ref] = useStateRef(0);
  const increment_page = useCallback(
    (amt: 1 | -1 = 1) => {
      if (page_ref.current == null || (page_ref.current === 0 && amt === -1)) {
        set_page(0);
      } else {
        set_page(page_ref.current + amt);
      }
    },
    [set_page, page_ref]
  );

  const [form_data, set_form_data] = useStateObject({
    content: 0,
    enough_people: 0,
    satisfying: 0,
  });
  const onSelect = (name: keyof typeof form_data) =>
    useCallback(
      (opt: InputOption<number>) => set_form_data({ [name]: opt.value }),
      [set_form_data, name]
    );
  const onSelectContent = onSelect("content");
  const onSelectEnough = onSelect("enough_people");
  const onSelectSatisfying = onSelect("satisfying");

  const score =
    form_data.content + form_data.enough_people + form_data.satisfying;

  const [submit_survey, status] = useApiCall("createFulfillmentSurvey");
  const on_submit = useCallback(() => {
    submit_survey({
      body: {
        data: {
          attributes: {
            score,
          },
        },
      },
    }).then(({ data }) => {
      analytics.log_event(analytics.EVENTS.relationship_fulfillment_complete);
      onFinish(data);
      increment_page(1);
    });
  }, [score, onFinish, increment_page]);

  const pages = {
    "0": (
      <FulfillmentSurveySection
        prompt="Are you feeling fulfilled in your relationships?"
        backButton={
          <button className="gray filled" onClick={dismiss}>
            Cancel
          </button>
        }
        nextButton={
          <button className="filled" onClick={() => increment_page(1)}>
            Begin
          </button>
        }
      >
        <p>
          Doctors and researchers have found that a lack of fulfilling
          relationships is a growing public health problem, one that can impact
          both physical and mental well-being. The following self-reflection is
          designed to help you assess how your relationships may be impacting
          you. When answering the questions, you could take account of the
          following:
        </p>
        <ul>
          <li>There are no right or wrong answers.</li>
          <li>We would like you to be completely honest.</li>
          <li>
            In answering the questions, it is best to think of your life as it
            generally is now (we all have some good or bad days).
          </li>
        </ul>
      </FulfillmentSurveySection>
    ),
    "1": (
      <FulfillmentSurveySection
        prompt="I am content with my friendships and relationships."
        backButton={
          <button className="gray filled" onClick={() => increment_page(-1)}>
            Back
          </button>
        }
        nextButton={
          <button
            className="filled"
            disabled={!form_data.content}
            onClick={
              form_data.content && page === 1
                ? () => increment_page(1)
                : undefined
            }
          >
            Next
          </button>
        }
      >
        <PillRadioList
          name="fulfillment-content"
          onSelectOption={onSelectContent}
          options={FULFILLMENT_SURVEY_OPTIONS}
          value={form_data.content}
        />
      </FulfillmentSurveySection>
    ),
    "2": (
      <FulfillmentSurveySection
        prompt="I have enough people I feel comfortable asking for help at any time."
        backButton={
          <button className="gray filled" onClick={() => increment_page(-1)}>
            Back
          </button>
        }
        nextButton={
          <button
            className="filled"
            disabled={!form_data.enough_people}
            onClick={
              form_data.enough_people && page === 2
                ? () => increment_page(1)
                : undefined
            }
          >
            Next
          </button>
        }
      >
        <PillRadioList
          name="fulfillment-enough-people"
          onSelectOption={onSelectEnough}
          options={FULFILLMENT_SURVEY_OPTIONS}
          value={form_data.enough_people}
        />
      </FulfillmentSurveySection>
    ),
    "3": (
      <FulfillmentSurveySection
        prompt="My relationships are as satisfying as I would want them to be."
        backButton={
          <button className="gray filled" onClick={() => increment_page(-1)}>
            Back
          </button>
        }
        error={<FooterError error={status.error} />}
        nextButton={
          <Preact.Fragment>
            <RequestButton
              pending={status.pending}
              className="filled"
              disabled={!form_data.satisfying}
              onClick={
                form_data.satisfying && page === 3 ? on_submit : undefined
              }
            >
              Finish
            </RequestButton>
          </Preact.Fragment>
        }
      >
        <PillRadioList
          name="fulfillment-satisfying"
          onSelectOption={onSelectSatisfying}
          options={FULFILLMENT_SURVEY_OPTIONS}
          value={form_data.satisfying}
        />
      </FulfillmentSurveySection>
    ),
    "4": (
      <FulfillmentSurveySection
        nextButton={
          <button className="filled" onClick={dismiss}>
            Close
          </button>
        }
      >
        <FulfillmentSurveyResults score={score} />
      </FulfillmentSurveySection>
    ),
  };

  return <Carousel page={`${page}` as keyof typeof pages} items={pages} />;
};

const FulfillmentSurveySection: Preact.FunctionComponent<{
  prompt?: Preact.ComponentChildren;
  backButton?: Preact.ComponentChildren;
  nextButton: Preact.ComponentChildren;
  error?: Preact.ComponentChildren;
}> = ({ prompt, children, backButton, nextButton, error }) => (
  <Preact.Fragment>
    <div className="modal-form__content">
      {prompt && (
        <div className="fulfillment-modal__prompt">
          <h3>{prompt}</h3>
        </div>
      )}
      <div className="fulfillment-modal__form">{children}</div>
    </div>

    <div className="modal__footer">
      <div className="modal__footer__left">{backButton}</div>
      {error}
      <div className="modal__footer__right">{nextButton}</div>
    </div>
  </Preact.Fragment>
);

export const fulfillment_survey_result_description = (score: number) =>
  score >= 6
    ? "You are less likely to be fulfilled in your relationships."
    : "You are likely to be fulfilled in your relationships.";

export const FulfillmentSurveyResults: Preact.FunctionComponent<{
  score: number;
}> = ({ score }) =>
  score >= 6 ? (
    <Preact.Fragment>
      <p>
        <strong>Your Results</strong>
      </p>
      <h5>{fulfillment_survey_result_description(score)}</h5>
      <p>
        <strong>Suggestions</strong>
      </p>
      <p>
        Everyone may sometimes feel a lack of fulfillment in their
        relationships. Connecting with others in-person can help. Here are a few
        ideas on how to make it happen:
      </p>
      <ul>
        <li>
          Call a friend or family member to say hi, set up time to go to lunch,
          or drop by for a visit.
        </li>
        <li>
          Stop and talk to others the next time you walk through the office or
          go to the gym or coffee shop.
        </li>
        <li>
          Sign up for a group exercise class to break a sweat and meet new
          people.
        </li>
        <li>
          Get the right amount of sleep each night. Try not to use technology
          right before bedtime and set an alarm to avoid oversleeping.
        </li>
        <li>
          Get involved in your community. VolunteerMatch, Create the Good®, and
          United Way® can match you with volunteer opportunities based on your
          interests.
        </li>
      </ul>
    </Preact.Fragment>
  ) : (
    <Preact.Fragment>
      <p>
        <strong>Your Results</strong>
      </p>
      <h5>You are likely to be fulfilled in your relationships.</h5>
      <p>
        <strong>Suggestions</strong>
      </p>
      <p>
        Even if you feel fulfillment in your relationships, there are actions
        you can take to help others. Is there someone in your life who could
        benefit from more human connection? Take a few minutes to reach out –
        call just to chat, schedule lunch, or go over for a visit. In the
        workplace, gym or local community center, try asking colleagues or
        friends out for coffee to catch up.
      </p>
    </Preact.Fragment>
  );
