import * as Preact from "preact";
import { useOverflowMenu } from "@thrive-web/ui-hooks";
import {
  maybeClassName,
  queryOverflowListItems,
  toggle_allow_tab_focus_batch,
} from "@thrive-web/ui-utils";
import { DropdownMenu } from "@thrive-web/ui-components";
import { useEffect, useMemo } from "preact/hooks";

const popover_props = {
  defaultDirection: "bottom",
} as const;
const overflowMenuDefaultRender = (overflowed: (Preact.VNode | null)[]) => (
  <DropdownMenu
    items={overflowed}
    buttonClassName="filled gray"
    listClassName="card pill-card"
    allowLinkEventPropagation={false}
    closeOnClickItem={true}
    popoverProps={popover_props}
  />
);

// renders a list of items, any items that don't fit within the container are placed
// in an overflow menu (dropdown by default)
export const OverflowMenu: Preact.FunctionComponent<
  MaybeClass & {
    items: (Preact.VNode | null)[];
    alwaysMenuItems?: (Preact.VNode | null)[];
    renderMenu?: (overflowed: (Preact.VNode | null)[]) => Preact.VNode;
    menuClassName?: string;
  }
> = ({
  className,
  menuClassName,
  items,
  alwaysMenuItems,
  renderMenu = overflowMenuDefaultRender,
}) => {
  const real_items = useMemo(() => items.filter(i => !!i), [items]);
  const [
    listRef,
    container,
    menu,
    num_visible = real_items.length,
    overflowed,
  ] = useOverflowMenu<Preact.VNode | null>(real_items, true);

  const always_menu_items = useMemo(
    () => (alwaysMenuItems ? alwaysMenuItems.filter(i => !!i) : []),
    [alwaysMenuItems]
  );
  const menu_items = useMemo(
    () =>
      always_menu_items.length > 0
        ? overflowed.length > 0
          ? overflowed.concat(always_menu_items)
          : always_menu_items
        : overflowed,
    [overflowed, alwaysMenuItems]
  );

  useEffect(() => {
    if (!listRef.current) {
      return;
    }
    const cancel = { current: false };
    const visible = queryOverflowListItems(listRef.current, true);
    const hidden = queryOverflowListItems(listRef.current, false);

    requestAnimationFrame(() => {
      let attr_updates: any[] = [];
      if (visible) {
        const v_updates = toggle_allow_tab_focus_batch(visible, true, cancel);
        if (v_updates) {
          attr_updates = v_updates;
        }
      }
      if (hidden) {
        const h_updates = toggle_allow_tab_focus_batch(hidden, false, cancel);
        if (h_updates) {
          attr_updates = attr_updates.concat(h_updates);
        }
      }
      if (!cancel.current) {
        attr_updates.forEach(func => func());
      }
    });

    return () => {
      cancel.current = true;
    };
  }, [real_items]);
  useEffect(() => {
    console.log(`OverflowMenu mounted`);
    return () => console.log(`OverflowMenu unmounted`);
  }, []);

  return (
    <div
      ref={container}
      className={`overflow__container${maybeClassName(className)}`}
    >
      <ul className="overflow__list" ref={listRef}>
        {real_items.map((item, i) => (
          <li
            key={i}
            className="overflow__list__item"
            data-visible={`${i < num_visible}`}
            data-last-visible={`${i === num_visible - 1}`}
          >
            {item}
          </li>
        ))}
      </ul>
      {menu_items.length > 0 && (
        <div
          ref={menu}
          className={`overflow__menu${maybeClassName(menuClassName)}`}
        >
          {renderMenu(menu_items)}
        </div>
      )}
    </div>
  );
};
