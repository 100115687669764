import * as Preact from "preact";
import { route } from "preact-router";
import Match from "preact-router/match";
import { insert_route_matches } from "@thrive-web/ui-utils";

// redirects to the given route when the app location matches a route
export const Redirect: Preact.FunctionComponent<
  RoutePageProps & {
    to: string;
    shouldRedirect?: (matches, path) => boolean;
  }
> = ({ matches, path, to, shouldRedirect }) => {
  const matched_to = insert_route_matches(to, matches || {});
  if (matches && (!shouldRedirect || shouldRedirect(matches, path))) {
    route(matched_to || "/", true);
  }
  return null;
};

export const RedirectSubroute: Preact.FunctionComponent<
  RoutePageProps & {
    to: string;
    shouldRedirect?: (matches, path) => boolean;
  }
> = ({ path, ...props }) => (
  <Match path={path}>
    {match_props => match_props.matches && <Redirect path={path} {...props} />}
  </Match>
);
