import { ApiMethodParameters } from "@thrive-web/core";
import { ExperienceCategory, Group } from "@thrive-web/ui-api";
import { comparator } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  POST_SCOPE_OPTIONS,
  RadioButton,
  ScopeItem,
  Tooltip,
  useAsyncRenderResult,
  ViewMoreLoader,
} from "@thrive-web/ui-components";
import {
  useApiRequest,
  useAppUser,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useContext, useEffect, useMemo } from "preact/hooks";

export const PostHelpFormGroupBase: Preact.FunctionComponent<{
  experience?: ExperienceCategory;
  scope?: ScopeItem;
  onChangeScope: (new_scope: ScopeItem) => void;
  scopeOptions: ScopeItem[] | null;
  loadMore?: () => void;
  pending: boolean;
  error?: DisplayableError;
  nextStep: () => void;
  prevStep: () => void;
}> = ({
  experience,
  scope,
  onChangeScope,
  scopeOptions,
  loadMore,
  pending,
  error,
  nextStep,
  prevStep,
}) => {
  const self = useAppUser();
  // get public groups that help with the selected experience
  const pub_group_params = useMemo<ApiMethodParameters<"GET", "Group", false>>(
    () =>
      self && experience
        ? {
            query: {
              filter: [
                [
                  "=",
                  ["this", "Group:provides_help_with"],
                  ["id", experience.id],
                ],
                [
                  "or",
                  ["=", ["this", "Group:has_member"], ["id", self.id]],
                  ["=", ["this", "Group:is_private"], false],
                ],
              ],
              sort: [{ by: "last_activity_at", dir: "desc" }],
              include: ["provides_help_with", "background_image"],
            },
          }
        : {},
    [experience?.id, self?.id]
  );

  const [get_pub_groups, pub_status] = useApiRequest(
    "getGroups",
    pub_group_params
  );
  const [pub_groups, set_pub_groups] = useStateIfMounted<Group[] | null>(null);
  const pub_passthru = useMemo(
    () => ({
      selected: scope,
      onSelect: onChangeScope,
      ...pub_status,
    }),
    [onChangeScope, scope?.id, pub_status]
  );

  useEffect(() => {
    if (!self?.is_adult) {
      return;
    }
    get_pub_groups().then(({ data }) => {
      set_pub_groups(data);
      // if no initial scope provided, select the first one in the list by default
      if (!scope) {
        onChangeScope(data[0]);
      }
    });
  }, [get_pub_groups]);

  const pub_groups_list = useAsyncRenderResult(
    (result, pending, passthru) => (
      // @ts-expect-error:
      <PostHelpFormGroupList options={result} {...passthru} pending={pending} />
    ),
    [],
    pub_status,
    pub_groups,
    true,
    undefined,
    undefined,
    false,
    pub_passthru
  );

  const other_status = useMemo(
    () => ({ pending, error, success: !pending && !error && !!scopeOptions }),
    [!!scopeOptions, pending, error]
  );
  const other_passthru = useMemo(
    () => ({
      selected: scope,
      onSelect: onChangeScope,
      loadMore: loadMore,
      error,
    }),
    [onChangeScope, scope?.id, loadMore, error]
  );

  // the user's normal scope option list, minus groups shown above
  const other_options = useMemo(
    () =>
      pub_groups
        ? scopeOptions?.filter(o => !pub_groups.some(g => g.id === o.id)) ||
          null
        : scopeOptions,
    [pub_groups, scopeOptions]
  );

  const other_groups = useAsyncRenderResult(
    (result, pending, passthru) => (
      // @ts-expect-error:
      <PostHelpFormGroupList options={result} {...passthru} pending={pending} />
    ),
    [],
    other_status,
    other_options,
    true,
    undefined,
    undefined,
    false,
    other_passthru
  );

  return (
    <div className="post-help__section">
      <div className="modal-form__section form__section">
        <div className="form__section-header">
          <h4>What group in the community would you like to post this to?</h4>
        </div>
      </div>
      {self?.is_adult && pub_groups?.length ? (
        <Preact.Fragment>
          <div className="modal-form__section form__section">
            <div className="form__section-header">Public Group</div>
            {pub_groups_list}
          </div>
          <div className="modal-form__section form__section post-help__disclaimer">
            <p>
              <em>
                We have a group of community members that are standing by to
                help you out.
              </em>
            </p>
            <p>
              <em>
                CAUTION: What you share here will be posted to a public group
                that deals with {experience?.name}. Please use caution when
                sharing details about yourself and details about others.
              </em>
            </p>
          </div>
        </Preact.Fragment>
      ) : null}
      <div className="modal-form__section form__section">
        <div className="form__section-header">Your Groups</div>
        {other_groups}
      </div>

      <div className="modal__footer">
        <div className="modal__footer__left">
          <button className="filled gray" onClick={prevStep}>
            Back
          </button>
        </div>
        <div className="modal__footer__right">
          <Tooltip
            text="Please select a group to post to."
            disabled={!!scope}
            defaultDirection="top"
            defaultOffset="left"
          >
            <ButtonWithIcon
              className="filled gray"
              onClick={!!scope ? nextStep : undefined}
              icon="chevron-right"
              side="right"
            >
              Next
            </ButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const PostHelpFormGroupList: Preact.FunctionComponent<{
  selected?: ScopeItem;
  options: ScopeItem[];
  onSelect: (scope: ScopeItem) => void;
  loadMore?: () => void;
  pending?: boolean;
  error?: Error;
}> = ({ selected, options, onSelect, loadMore, pending, error }) => {
  return (
    <ul className="record-list post-help__group-list group-list--simple">
      {options.map(item => {
        return (
          <li className="record-list__item post-help__group-list__item group-list--simple__item">
            <div
              className="group-list--simple__item__content group-card"
              onClick={() => onSelect(item)}
            >
              <div className="group-list--simple__item__left">
                <div className="group-list--simple__item__dot">
                  <div
                    className="group-dot"
                    data-size="md"
                    style={{
                      backgroundColor: (item as Group).background_color,
                    }}
                  />
                </div>
                <div className="group-list--simple__item__name">
                  {item.name}
                </div>
              </div>
              <div className="group-list--simple__item__right">
                <RadioButton checked={item.id === selected?.id} label="" />
              </div>
            </div>
          </li>
        );
      })}
      {loadMore && (
        <li className="post-help__group-list__item record-list__load-more">
          <ViewMoreLoader nextPage={loadMore} pending={pending} error={error} />
        </li>
      )}
    </ul>
  );
};

export const PostHelpFormGroup: Preact.FunctionComponent<{
  experience?: ExperienceCategory;
  scope?: ScopeItem;
  initialScope?: ScopeItem;
  onChangeScope: (new_scope: ScopeItem) => void;
  nextStep: () => void;
  prevStep: () => void;
}> = props => {
  const { list, next_page } = useContext(POST_SCOPE_OPTIONS);
  const { fetch, error, pending = false } = next_page || {};
  const options = useMemo(() => {
    let opts = list;
    if (!fetch) {
      opts =
        list?.slice().sort(
          comparator(
            // @ts-expect-error:
            item => new Date(item.last_activity_at || 0).getTime(),
            "desc"
          )
        ) || null;
    }
    if (props.initialScope) {
      opts = [props.initialScope].concat(
        opts?.filter(o => o.id !== props.initialScope?.id) || []
      );
    }
    return opts;
  }, [list]);

  return (
    <PostHelpFormGroupBase
      {...props}
      scopeOptions={options}
      loadMore={fetch}
      pending={pending}
      error={error}
    />
  );
};
