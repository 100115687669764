import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { Icon } from "@thrive-web/ui-components";
import { asSubroute } from "./asSubroute";

const Chance = require("chance");
const chance = new Chance();

const get_404_string = () =>
  chance.pickone([
    "This is not the page you're looking for.",
    "Are you suggesting web pages migrate?",
    "Page not found",
    "Page not found",
    "Page not found",
    "Page not found",
    "Page not found",
    /* "We ain't found shit." I wish I could include this one :( */
  ]);

export const NotFoundPage: Preact.FunctionComponent<{
  error?: DisplayableError;
  path?: string;
}> = ({ error }) => {
  const str = useMemo(get_404_string, []);
  return (
    <div className="error-view not-found">
      <div className="not-found__content">
        <h3>
          <Icon className="error-view__icon" name="alert" iconSize="xl" />
          <span>{str} (404)</span>
        </h3>
        <p>The page or resource you requested could not be found.</p>
      </div>
      {error && (
        <div className="not-found__details">
          <details>
            <summary className="i-thrive-disclosure">View more details</summary>
            {(error.http_status || error.code) && (
              <div className="error-view__code">
                Status Code: {error.http_status || error.code}
              </div>
            )}
            <pre className="error-view__message">{error.message}</pre>
          </details>
        </div>
      )}
    </div>
  );
};

export const NotFoundPageRoute = asSubroute(NotFoundPage);

export const DefaultErrorView: Preact.FunctionComponent<{
  error: DisplayableError;
}> = ({ error }) =>
  error.http_status === 404 ? (
    <div className="error-view">
      <NotFoundPage />
    </div>
  ) : (
    <div className="error-view">
      <h3>
        <Icon className="error-view__icon" name="alert" iconSize="xl" />
        Something went wrong!
      </h3>
      <p>
        Try refreshing the page, or{" "}
        <a className="plain-link" href="/support">
          contact support
        </a>{" "}
        if the issue persists.
      </p>
      <details>
        <summary className="i-thrive-disclosure">View more details</summary>
        {(error.http_status || error.code) && (
          <div className="error-view__code">
            Status Code: {error.http_status || error.code}
          </div>
        )}
        <pre className="error-view__message">{error.message}</pre>
      </details>
    </div>
  );
