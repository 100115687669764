import * as Preact from "preact";
import { Goal } from "@thrive-web/ui-api";
import { useStateIfMounted } from "@thrive-web/ui-hooks";
import { display_text } from "@thrive-web/ui-utils";
import {
  FloatingTitle,
  InputWithDropdownAsync,
  Tag,
  useGroupGoalsList,
  GROUP_GROUP,
} from "@thrive-web/ui-components";
import { useContext, useEffect, useMemo } from "preact/hooks";

const getGoalText = (goal: Goal) => goal.name || goal.id;

// const GoalInputDropdownItem = (option: Goal): Preact.VNode => (
//   <div className="group-todos__form___goal__item">{option.name}</div>
// );

export const GoalInputSelectedItem = (
  option: Goal,
  clearValue: () => void
): Preact.VNode => (
  <Tag
    className="group-todos__form___goal__selected"
    onRemove={clearValue}
    icon="goal"
    iconSize="sm"
  >
    {display_text(option.name)}
  </Tag>
);

// async dropdown, single value
export const GroupTodoGoalInput: Preact.FunctionComponent<{
  value?: Goal;
  onChange: (goal?: Goal) => void;
}> = ({ value, onChange }) => {
  const [search, set_search] = useStateIfMounted("");
  const group = useContext(GROUP_GROUP);
  const [goals, set_goals, fetch_goals, { pending }] = useGroupGoalsList(
    group!,
    search
  );

  useEffect(() => {
    fetch_goals(0).then(({ data }) => {
      set_goals.reset(data);
    });
  }, [fetch_goals]);

  // get goal record for selected goal
  const selected = useMemo(
    () => (value?.name ? value : goals?.find(g => g.id === value?.id)),
    [value, goals]
  );
  return (
    <InputWithDropdownAsync
      className="group-todos__form__goal tag-input"
      options={goals || []}
      onChange={onChange}
      renderSelected={GoalInputSelectedItem}
      getTextFromSelected={getGoalText}
      onChangeText={set_search}
      pending={pending}
      placeholder="Type to search goals..."
      name="goal"
      value={selected}
    >
      <FloatingTitle name="goal">Goal</FloatingTitle>
    </InputWithDropdownAsync>
  );
};
