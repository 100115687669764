import * as Preact from "preact";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";

export const BuilderPageHeader: Preact.FunctionComponent = () => (
  <div className="setup-page__header">
    <div className="setup-page__header__logo">
      <img src={`${IMAGES_PREFIX}/logo_thread.svg`} />
    </div>
  </div>
);
