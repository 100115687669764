import * as hooks from "preact/hooks";

const ANALYTICS_DELAYED_EVENT_TIMEOUT = 6000;

// fire an analytics event at the end of a timer, and provide
// a callback to cancel the timer if the action was undone or accidental
export const useDelayedEvent = <Args extends any[], Ret>(
  callback: (...args: Args) => Ret,
  inputs: any[] = [],
  delay: number = ANALYTICS_DELAYED_EVENT_TIMEOUT
) => {
  const timer = hooks.useRef<any>();
  const callback_ = hooks.useCallback(callback, inputs);
  const clear_timeout = hooks.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);
  const send_event = hooks.useCallback(
    (...args: Args) => {
      clear_timeout();
      timer.current = setTimeout(() => callback_(...args), delay);
    },
    [callback_]
  );

  return [send_event, clear_timeout] as const;
};
