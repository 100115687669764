import { capitalize, moment } from "@thrive-web/ui-common";
import { User } from "@thrive-web/ui-api";

export const join_string_list = (strings: string[]): string => {
  if (strings.length === 1) {
    return strings[0];
  }
  const last = strings.pop();
  return `${strings.join(", ")}${strings.length > 1 ? "," : ""} and ${last}`;
};

export const join_possible_string_list = (
  strings: (string | undefined)[]
): string => join_string_list(strings.filter(s => !!s) as string[]);

// gets user's first name, or "you" if user is self
export const get_user_name = (
  user: User | undefined,
  self_id: string
): string =>
  !user ? "" : user["id"] === self_id ? "you" : capitalize(user.first_name);

// lists users' first names
// supports a limit for list length and appends "and # others" after limit
export const get_user_name_list = (
  users: User[],
  self_id: string,
  max_names: number,
  full_name?: boolean
): string => {
  if (users.length === 0) {
    return "";
  }
  let users_copy = users.slice();
  let names: string[] = [];
  if (users_copy.find(u => u.id === self_id)) {
    names.push("You");
    users_copy = users_copy.filter(u => u.id !== self_id);
    max_names--;
  }
  for (let i = 0; i < max_names && users_copy.length > 0; i++) {
    const u = users_copy.shift();
    u && names.push((full_name ? u.full_name! : get_user_name(u, "")).trim());
  }
  if (users_copy.length > 0) {
    if (users_copy.length > 1) {
      names.push(`${users_copy.length} others`);
    } else {
      const u = users_copy[0];
      names.push((full_name ? u.full_name! : get_user_name(u, "")).trim());
    }
  }

  return join_string_list(names);
};

export const clamp_string_length = (
  str: string | undefined,
  max_length: number
): string => {
  if (!str) {
    return "";
  }
  if (str.length > max_length) {
    return `${str.slice(0, max_length - 3)}...`;
  }
  return str;
};

export const hex_to_rgb = (hex: string | undefined): RGBValue | null => {
  const parsed = hex
    ? hex.match(/^#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/i)
    : null;
  return parsed
    ? {
        r: parseInt(parsed[1], 16),
        g: parseInt(parsed[2], 16),
        b: parseInt(parsed[3], 16),
      }
    : null;
};

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
// const YEAR = DAY * 365;
// generate formatted string expressing time between given date and current moment
// e.g. 3 minutes ago, 14 hours ago, 2 weeks ago
export const format_time_passed = (
  time: Date,
  // use abbreviated form, e.g. 3m, 14H, 2w
  short?: boolean,
  lowercase?: boolean,
  show_time_with_date_string?: boolean
) => {
  const now = new Date();
  const diff = now.getTime() - time.getTime();
  const units_ago = (num: number, unit: string, abbr: string) =>
    `${num}${short ? abbr : ` ${unit}`}${short || num === 1 ? "" : "s"}${
      short ? "" : " ago"
    }`;
  if (diff <= 10 * SECOND && !short) {
    return `Just now`;
  }
  if (diff < MINUTE) {
    return units_ago(
      Math.max(0, Math.floor(diff / SECOND)),
      "second",
      lowercase ? "s" : "S"
    );
  }
  if (diff < HOUR) {
    return units_ago(Math.floor(diff / MINUTE), "minute", "m");
  }
  if (diff < DAY) {
    return units_ago(Math.floor(diff / HOUR), "hour", lowercase ? "h" : "H");
  }
  if (diff < 2 * DAY && !short) {
    return `yesterday`;
  }
  if (diff < WEEK) {
    return units_ago(Math.floor(diff / DAY), "day", lowercase ? "d" : "D");
  }
  if (short && diff < 10 * WEEK) {
    return units_ago(Math.floor(diff / WEEK), "week", lowercase ? "w" : "W");
  }
  /*if (short && diff >= YEAR) {
    return `${Math.floor(diff / YEAR)}Y`;
  }
  if (short && diff > 4 * WEEK) {
    const that_month = time.getFullYear() * 12 + time.getMonth();
    const this_month = now.getFullYear() * 12 + now.getMonth();
    const month_diff =
      this_month - that_month - (now.getDate() < time.getDate() ? 1 : 0);
    return month_diff < 1 ? `${Math.floor(diff / WEEK)}W` : `${month_diff}M`;
  }*/

  // after 10 weeks, just display the date string
  let str = moment(time).format(
    time.getFullYear() !== now.getFullYear() ? "MMM D YYYY" : "MMM D"
  );

  if (show_time_with_date_string) {
    return `${str} at ${time
      .toLocaleTimeString()
      .replace(/(\d+:\d+):\d+ /i, "$1 ")}`;
  }
  return str;
};

export const format_dollar_amount = (value: number) => `$${value.toFixed(2)}`;

export const chain_event_listeners = <E extends HTMLElement>(
  ...props: EventListenersOf<E>[]
) => {
  const output: EventListenersOf<E> = {};
  props.forEach(listeners => {
    Object.keys(listeners).forEach(name => {
      if (!listeners[name]) {
        return;
      }
      if (output[name]) {
        const prev = output[name];
        output[name] = e => {
          prev(e);
          listeners[name](e);
        };
      } else {
        output[name] = listeners[name];
      }
    });
  });
  return output;
};

export const setPageAttr = (url: string) => {
  const [, name] = url.match(/\/(\w*)(\/.*)?/i) || [];
  const el = document.getElementById("site-main");
  if (!el) {
    return;
  }
  if (name || name === "") {
    el.setAttribute("data-page", name || "home");
  } else {
    el.removeAttribute("data-page");
  }
};
