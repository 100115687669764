import { capitalize } from "@thrive-web/ui-common";
import * as Preact from "preact";
import {
  ButtonWithIcon,
  DatePicker,
  DatePickerProps,
  DefaultFileUploadButton,
  DefaultModalContent,
  DollarInput,
  FileUpload,
  FloatingTitle,
  InputWithFormHelpers,
  TextAreaWithFormHelpers,
  UserListItem,
  useTaxonomy,
  WithFloatingTitle,
  DropdownSelectInput,
  UserSearchFn,
} from "@thrive-web/ui-components";
import {
  Expense,
  ExpenseCategory,
  ExpenseDistributionMethod,
  ExpenseReason,
  User,
  WriteExpense,
} from "@thrive-web/ui-api";
import {
  useDirtyForm,
  useDynamicListVariable,
  useForm,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import { GroupExpenseParticipantInput } from "./GroupExpenseParticipantInput";

const GUIDELINES_DOC_URL =
  "https://docs.google.com/document/d/1Kv25wE4PwEJcLcWWbFwa-JLI5SR-0MEvnG53RxYgoH8/edit";

export const ReceiptPhotoUploadButton: Preact.FunctionComponent<FileUploadButtonProps> =
  (props: FileUploadButtonProps) => (
    <DefaultFileUploadButton
      {...props}
      className={`filled gray${maybeClassName(props.className)}`}
    >
      Add Photograph of Receipt
    </DefaultFileUploadButton>
  );

export const GroupExpenseForm: Preact.FunctionComponent<{
  expense?: Expense;
  file?: FileUploadData;
  onChangeFile: (file?: FileUploadData) => void;
  getUsers: UserSearchFn;
  taggedUsers: readonly User[];
  setTaggedUsers?: (users: readonly User[]) => void;
  dismiss: () => void;
  onFinish: (exp: WriteExpense) => void;
}> = ({
  expense: initialData,
  file,
  onChangeFile,
  dismiss,
  onFinish,
  getUsers,
  taggedUsers,
  setTaggedUsers,
}) => {
  const category_tx = useTaxonomy("ExpenseCategory");
  const reason_tx = useTaxonomy("ExpenseReason");
  const [file_, setFile] = useStateIfMounted<FileUploadData | undefined>(file);

  const [formData, setField, onInputChange, resetData] = useForm<WriteExpense>(
    initialData || {}
  );

  const date_picker_props = useMemo<Omit<DatePickerProps, "children">>(
    () => ({
      onChange: (val: Date) => {
        setField("purchase_date", val.toISOString());
      },
      popoverTriggerOptions: {
        click: true,
        focus: true,
        hover: false,
      },
      value: formData.purchase_date
        ? new Date(formData.purchase_date)
        : undefined,
      popoverProps: {
        defaultDirection: "bottom",
        portalClassName: "group-expense__form__date--popover",
      },
    }),
    [setField, formData.purchase_date]
  );

  const onChangeReceiptAmt = useCallback(
    (value?: number) => setField("receipt_amount", value),
    [setField]
  );

  const onChangeRequestAmt = useCallback(
    (value?: number) => setField("requested_amount", value),
    [setField]
  );

  // props for dropdown inputs
  const category_props = useMemo<DropdownSelectInputProps<ExpenseCategory>>(
    () => ({
      options: category_tx.value || [],
      onSelect: (category: ExpenseCategory) => {
        setField("has_category", category);
      },
      getValueLabel: (category: ExpenseCategory) => category.title || "",
      label: "Choose a Category",
      required: true,
    }),
    [setField, category_tx.value]
  );
  const reason_props = useMemo<DropdownSelectInputProps<ExpenseReason>>(
    () => ({
      options: reason_tx.value || [],
      onSelect: (reason: ExpenseReason) => {
        setField("has_reason", reason);
      },
      getValueLabel: (reason: ExpenseReason) => reason.title || "",
      label: "Select Reason",
      required: true,
    }),
    [setField, reason_tx.value]
  );

  const distrib_method_props = useMemo<
    DropdownSelectInputProps<ExpenseDistributionMethod>
  >(
    () => ({
      options: ["check", "paypal"], // method_tx.value || [],
      onSelect: (method: ExpenseDistributionMethod) => {
        setField("distribution_method", method);
      },
      getValueLabel: (method: ExpenseDistributionMethod) => capitalize(method), // reason.title || "",
      label: "Select Method",
      required: true,
    }),
    [setField /*, method_tx.value*/]
  );

  const [tagged_users, set_tagged_users] =
    useDynamicListVariable<User>(taggedUsers);
  // if tagged users changes externally, update it here
  useEffect(() => {
    set_tagged_users.reset(taggedUsers);
  }, [taggedUsers]);
  const toggle_tagged_user = useCallback(
    (u: User, checked: boolean) => {
      checked
        ? set_tagged_users.remove(us => us.id === u.id)
        : set_tagged_users.add(u);
    },
    [set_tagged_users]
  );

  const allFormData = useMemo(
    () => ({
      ...formData,
      photo: file_,
    }),
    [formData, tagged_users, file_]
  );
  const clearFormDirtyState = useDirtyForm(
    allFormData,
    "GroupExpenseForm",
    true
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      setTaggedUsers && setTaggedUsers(tagged_users || []);
      onChangeFile(file_);
      onFinish(formData);
      clearFormDirtyState();
    },
    [onFinish, file_, formData, clearFormDirtyState]
  );

  return (
    <form
      id="group-expense-form"
      className="group-expense__form"
      onSubmit={onSubmit}
    >
      <DefaultModalContent
        title="Create a Reimbursement"
        footer={
          <div className="modal__footer">
            <div className="modal__footer__left">
              <button
                className="button filled gray"
                type="button"
                onClick={() => {
                  clearFormDirtyState();
                  resetData(initialData || {});
                  set_tagged_users.reset(taggedUsers);
                  setFile(file);
                  dismiss();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="modal__footer__right">
              <button
                type="submit"
                form="group-expense-form"
                className="button filled gray"
              >
                Submit Reimbursement
              </button>
            </div>
          </div>
        }
      >
        <div className="group-expense__form__notice form__section">
          <div className="post-block">
            Reimbursement information will not be published to the group and is
            only shared with Thread. For more details, please visit the{" "}
            <a className="plain-link" target="_blank" href={GUIDELINES_DOC_URL}>
              Volunteer Spending Guidelines
            </a>{" "}
            document.
          </div>
        </div>
        <div className="group-expense__form__photo form__section">
          <div className="form__section-header">
            <h3>Receipt Photo</h3>
          </div>
          <FileUpload
            onChange={setFile}
            Button={ReceiptPhotoUploadButton}
            required={true}
            value={file_}
          />
        </div>
        <div className="group-expense__form__info form__section">
          <div className="form__section-header">
            <h3>Reimbursement Information</h3>
          </div>
          <div className="form__input-row">
            <WithFloatingTitle title="Vendor Name">
              <InputWithFormHelpers
                onChange={onInputChange("vendor_name")}
                value={formData.vendor_name}
                placeholder="Vendor Name"
                required={true}
              />
            </WithFloatingTitle>
            <DatePicker {...date_picker_props}>
              {trigger_props => (
                <WithFloatingTitle title="Reimbursement Date">
                  <InputWithFormHelpers
                    {...trigger_props}
                    onChange={() => {}}
                    value={trigger_props.value?.toLocaleDateString()}
                    placeholder="Reimbursement Date"
                    required={true}
                    readOnly={true}
                    controlled={true}
                  />
                </WithFloatingTitle>
              )}
            </DatePicker>
          </div>
        </div>
        <div className="group-expense__form__amount form__section">
          <div className="form__section-header">
            <h3>Amounts</h3>{" "}
          </div>

          <div className="form__input-row">
            <DollarInput
              onChangeValue={onChangeReceiptAmt}
              value={formData.receipt_amount}
              required={true}
            >
              <FloatingTitle>Receipt Amount</FloatingTitle>
            </DollarInput>
            <DollarInput
              onChangeValue={onChangeRequestAmt}
              value={formData.requested_amount}
              required={true}
              max={formData.receipt_amount}
            >
              <FloatingTitle>Requested Amount</FloatingTitle>
            </DollarInput>
          </div>
        </div>
        <div className="group-expense__form__category form__section">
          <div className="form__section-header">
            <h3>What did you spend money on?</h3>
          </div>
          <div className="form__input-row">
            <DropdownSelectInput
              {...category_props}
              value={formData.has_category}
            />
          </div>
        </div>
        <div className="group-expense__form__reason form__section">
          <div className="form__section-header">
            <h3>What was the reason to spend money?</h3>
          </div>
          <div className="form__input-row">
            <DropdownSelectInput
              {...reason_props}
              value={formData.has_reason}
            />
          </div>
        </div>
        <div className="group-expense__form__method form__section">
          <div className="form__section-header">
            <h3>How would you like to be reimbursed?</h3>
          </div>
          <div className="form__input-row">
            <DropdownSelectInput
              {...distrib_method_props}
              value={formData.distribution_method}
            />
          </div>
        </div>
        <div className="group-expense__form__users form__section">
          <div className="form__section-header">
            <h3>With/For Whom?</h3>
          </div>
          {tagged_users && tagged_users.length > 0 && (
            <div className="group-expense__form__users__list">
              {tagged_users.map(u => (
                <UserListItem user={u} linkBox="none" useDiv={true}>
                  {setTaggedUsers && (
                    <ButtonWithIcon
                      icon="remove"
                      className="filled transparent all-gray"
                      onClick={() => toggle_tagged_user(u, true)}
                    />
                  )}
                </UserListItem>
              ))}
            </div>
          )}
          {setTaggedUsers && (
            <div className="form__input-row">
              <GroupExpenseParticipantInput
                getUsers={getUsers}
                selected={tagged_users || []}
                onChange={toggle_tagged_user}
              />
            </div>
          )}
        </div>
        <div className="group-expense__form__details form__section">
          <div className="form__section-header">
            <h3>Additional Details</h3>
            <div className="form__input-row">
              <WithFloatingTitle title="Description">
                <TextAreaWithFormHelpers
                  placeholder="Add any other details about this reimbursement..."
                  onChange={onInputChange("details")}
                  value={formData.details}
                />
              </WithFloatingTitle>
            </div>
          </div>
        </div>
      </DefaultModalContent>
    </form>
  );
};
