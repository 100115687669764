import * as Preact from "preact";
import { Group } from "@thrive-web/ui-api";
import {
  asSubroute,
  useFetchGroups,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { useAppUser, useDynamicListVariable } from "@thrive-web/ui-hooks";
import { GroupList, GroupListLoading } from "~/view/components";

// const public_filter = [["=", ["this", "Group:is_private"], false]] as const;
const GroupDiscoverBase: Preact.FunctionComponent<RoutePageProps> = () => {
  const user = useAppUser();
  const [list, dispatch] = useDynamicListVariable<Group>(null);
  // fetch public groups
  const fetchGroups = useFetchGroups(user, undefined, undefined, true);

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem) => (
      <GroupList key="discover" groups={result} loadMoreElem={load_more_elem} />
    ),
    [],
    fetchGroups,
    undefined,
    { PendingView: GroupListLoading, limit: 15 }
  );
};

export const GroupDiscover = asSubroute(GroupDiscoverBase);
