import * as Preact from "preact";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { route } from "preact-router";
import { Group } from "@thrive-web/ui-api";
import { ScreenSize, THREAD_COMM_ID } from "@thrive-web/ui-constants";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  useCallbackRef,
  useFocus,
  useMedia,
  useSingleClickDetection,
} from "@thrive-web/ui-hooks";
import {
  cache_record,
  clamp_string_length,
  display_text,
  format_time_passed,
  get_url_for_entity,
  hex_to_rgb,
} from "@thrive-web/ui-utils";
import {
  AvatarListLoading,
  Card,
  LoadingParagraph,
  DivWithIcon,
  GroupConnectionScoreStatic,
} from "@thrive-web/ui-components";
import { CONTEXTS } from "@thrive-web/ui-model";

export const DEFAULT_GROUP_BACKGROUND_COLOR = "#808080";
export const GROUP_PURPOSE_CHAR_LIMIT = 144;

export const GroupListItem: Preact.FunctionComponent<{
  group: Group;
  isLink?: boolean;
  showLatestActivity?: boolean;
}> = ({ isLink = true, group, showLatestActivity }) => {
  const { name, background_color, purpose = "" } = group;
  const window_size = useContext(CONTEXTS.window_size);
  const [focus, focus_listeners] = useFocus();
  const rgb = useMemo(() => hex_to_rgb(background_color), [background_color]);
  const backgroundColor =
    rgb === null
      ? DEFAULT_GROUP_BACKGROUND_COLOR
      : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;

  const ignore_link_click = useRef(false);
  const link_listener = useCallbackRef<EventListener>(
    (e: EventFor<HTMLElement>) => {
      if (!isLink) {
        return;
      }
      const { current } = ignore_link_click;
      ignore_link_click.current = false;
      // ignore if clicking on a button, link, or any element with a click listener, don't navigate
      if (
        e.target.tagName === "A" ||
        e.target.tagName === "BUTTON" ||
        e.target.classList.contains("expandable-image") ||
        // @ts-expect-error:
        e.ignoreLinkClick ||
        current
      ) {
        return;
      }
      cache_record(group, true);
      route(get_url_for_entity(group));
    },
    [group]
  );

  const ignore_link_click_func = useCallback(e => {
    e.ignoreLinkClick = true;
    ignore_link_click.current = true;
  }, []);
  const ignore_attrs = useMemo(
    () =>
      isLink
        ? {
            "data-ignore-link-click": true,
            onMouseUp: ignore_link_click_func,
          }
        : {},
    [ignore_link_click_func]
  );

  const link_listeners = useSingleClickDetection(link_listener);

  const description = clamp_string_length(purpose, GROUP_PURPOSE_CHAR_LIMIT);

  const background_image_url = useMedia<"Group", "background_image">(
    group,
    "background_image",
    1,
    window_size
  );

  return (
    <article
      className="group-list__item color-overlay"
      {...(isLink ? { ...focus_listeners, ...link_listeners } : {})}
      style={{
        backgroundImage: background_image_url
          ? `url(${background_image_url})`
          : undefined,
        backgroundColor,
        "--color-overlay-background-color": backgroundColor,
      }}
      data-focus={focus}
      data-link={`${!!isLink}`}
    >
      <div className="group-list__item__color-overlay">
        <div className="group-list__item__top">
          <div className="group-list__item__left">
            {group.in_community?.id === THREAD_COMM_ID && (
              <DivWithIcon
                className="group-list__item__community-label"
                {...ignore_attrs}
                icon={
                  <img
                    className="thread-logo-icon"
                    src={`${IMAGES_PREFIX}/logo.svg`}
                  />
                }
                side="left"
              >
                Thread Community
              </DivWithIcon>
            )}
            <div className="group-list__item__title">{display_text(name)}</div>
            {showLatestActivity && group.last_activity_at && (
              <div className="group-list__item__subtitle">
                Last activity{" "}
                {format_time_passed(new Date(group.last_activity_at))}
              </div>
            )}
          </div>
          <div className="group-list__item__right" {...ignore_attrs}>
            {/*<AvatarList
              users={(has_member || []) as User[]}
              size={window_size > ScreenSize.xs ? "sm" : "xs"}
              maxWidth={
                window_size > ScreenSize.sm
                  ? 8
                  : (window_size > ScreenSize.xs ? 6 : 4) -
                    (group.has_relationship_management ? 2 : 0)
              }
              maxHeight={1}
              align="right"
              showTooltips={true}
              tooltipProps={tooltip_props}
              onClickMore={isLink ? goToMembers : undefined}
              links={false}
            />*/}
            {group.has_relationship_management && (
              <Card className="group-list__item__score">
                <GroupConnectionScoreStatic score={group.score || 0} />
              </Card>
            )}
          </div>
        </div>
        {description && (
          <div className="group-list__item__bottom">
            <div className="group-list__item__purpose" {...ignore_attrs}>
              {display_text(description)}
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

export const GroupListItemLoading: Preact.FunctionComponent = () => {
  const window_size = useContext(CONTEXTS.window_size);
  const in_community = useMemo(() => Math.random() > 0.8, []);
  const title_width = useMemo(
    () => ({
      width: `min(${Math.round(Math.random() * 10) + 6}rem, 100%)`,
    }),
    []
  );
  return (
    <div className="group-list__item loading-item">
      <div className="group-list__item__color-overlay">
        <div className="group-list__item__top">
          <div className="group-list__item__left">
            {in_community && (
              <DivWithIcon
                className="group-list__item__community-label"
                icon={
                  <div className="loading-item__dot loading-item__shaded" />
                }
                side="left"
              >
                <div className="loading-item__text loading-item__shaded" />
              </DivWithIcon>
            )}
            <div
              className="group-list__item__title loading-item__header loading-item__shaded"
              style={title_width}
            />
          </div>
          <div className="group-list__item__right">
            <AvatarListLoading
              size={window_size > ScreenSize.xs ? "sm" : "xs"}
              maxWidth={
                window_size > ScreenSize.sm
                  ? 8
                  : window_size > ScreenSize.xs
                  ? 6
                  : 4
              }
              maxHeight={1}
              align="right"
            />
          </div>
        </div>
        <div className="group-list__item__bottom">
          <div className="group-list__item__purpose">
            <LoadingParagraph maxLines={2} />
          </div>
        </div>
      </div>
    </div>
  );
};
