import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { Event, User } from "@thrive-web/ui-api";
import { moment } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  DivWithIcon,
  EntityLink,
  LinkWithIcon,
  LoadingParagraph,
  useRSVPState,
} from "@thrive-web/ui-components";
import { EVENT_BANNER_PLACEHOLDER_PATH } from "@thrive-web/ui-constants";
import { CONTEXTS } from "@thrive-web/ui-model";
import { useAppUser, useMedia } from "@thrive-web/ui-hooks";
import { display_text, get_url_for_entity } from "@thrive-web/ui-utils";

export const EventListItem: Preact.FunctionComponent<{
  event: Event;
  showRSVP?: boolean;
  onClickShare?: (event: Event) => void;
}> = ({ showRSVP, event, onClickShare }) => {
  const date = useMemo(
    () =>
      event.date_time ? moment(event.date_time).format("MMMM Do, YYYY") : "",
    [event.date_time]
  );
  // link to event detail page
  const link = useMemo(() => get_url_for_entity(event), [event]);
  const self = useAppUser();
  const [rsvp_label, rsvp_icon] = useRSVPState(self as User, event);
  const share_event = useCallback(
    () => onClickShare?.(event),
    [onClickShare, event]
  );

  const window_size = useContext(CONTEXTS.window_size);
  const img_url = useMedia<"Event", "cover_image">(
    event,
    "cover_image",
    1,
    window_size
  );

  return (
    <div className="card card--with-banner event-list__item">
      <EntityLink
        entity={event}
        className="card__banner event-list__item__banner"
        style={{
          backgroundImage: `url(${img_url || EVENT_BANNER_PLACEHOLDER_PATH})`,
        }}
      >
        <div className="event-list__item__header">
          <h2 className="event-list__item__title">{event.name}</h2>
          <div className="event-list__item__date">{date}</div>
          {event.is_canceled && (
            <div className="event-list__item__cancelled">
              This event was cancelled
            </div>
          )}
        </div>
      </EntityLink>
      <div className="event-list__item__body">
        {event.location_name && (
          <DivWithIcon
            className="event-list__item__location"
            icon="location-pin"
            side="left"
          >
            {event.location_name}
          </DivWithIcon>
        )}
        <p>{display_text(event.description)}</p>
        <div className="event-list__item__buttons">
          <LinkWithIcon
            icon="info-outline-circle"
            side="left"
            className="button filled gray"
            href={link}
          >
            Event Details
          </LinkWithIcon>
          <div className="event-list__item__buttons__right">
            {onClickShare && !event.is_canceled && event.is_public && (
              <ButtonWithIcon
                className="filled gray"
                icon="share"
                side="left"
                onClick={share_event}
              >
                Share
              </ButtonWithIcon>
            )}
            {showRSVP && !event.is_canceled && (
              <ButtonWithIcon
                className="filled gray"
                icon={rsvp_icon}
                side="left"
              >
                {rsvp_label}
              </ButtonWithIcon>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const EventListItemLoading: Preact.FunctionComponent = () => {
  return (
    <div className="card card--with-banner event-list__item loading-item">
      <div className="card__banner event-list__item__banner loading-item__image loading-item__shaded loading-item__shaded--dark">
        <div className="event-list__item__header">
          <h2 className="event-list__item__title loading-item__text loading-item__title loading-item__shaded loading-item__shaded--light" />
          <div className="event-list__item__date loading-item__text loading-item__shaded loading-item__shaded--light" />
        </div>
      </div>
      <div className="event-list__item__body">
        <DivWithIcon
          className="event-list__item__location"
          icon="location-pin"
          side="left"
        >
          <div className="loading-item__text loading-item__shaded" />
        </DivWithIcon>
        <LoadingParagraph minLines={3} />
        <DivWithIcon
          icon="info-outline-circle"
          side="left"
          className="button filled gray event-list__item__link"
        >
          <div className="loading-item__text loading-item__shaded loading-item__shaded--dark" />
        </DivWithIcon>
      </div>
    </div>
  );
};
