import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { MetaTypes, RelationshipKeysOf } from "@thrive-web/core";
import { Comment, Post, Reaction, User, WritePost } from "@thrive-web/ui-api";
import {
  useApiFetch,
  useAppUser,
  useDebounce,
  useRenderPropsFunction,
  useStateIfMounted,
  useStateRef,
  useZendeskReportEntity,
} from "@thrive-web/ui-hooks";
import {
  AsyncRender,
  Avatar,
  CommentList,
  DefaultPendingView,
  EntityLink,
  PostMood,
  PostPhoto,
  PostActions,
  ReactionButton,
  useAsyncRender,
  DynamicListCtxSpec,
  DefaultModalContent,
  DeleteModalBody,
  Carousel,
  PostEdit,
  PostTouchpoint,
  PostExpense,
  TextWithLinks,
  PostMeta,
  PostTaggedUsers,
  useGroupUpdatesAllowed,
  PostHelp,
  PostEvent,
  PostShareButton,
  ACTIVITY_FEED_CAN_INTERACT,
  PostLinkPreview,
  IS_ADMIN_UI,
} from "@thrive-web/ui-components";
import { getScope } from "./utils";
import {
  add_item_to,
  remove_item_from,
  replace_item_in,
} from "@thrive-web/ui-common";

type PostDetailCarouselPage = "main" | "delete" | "edit" | "report";

export interface PostDetailBodyProps extends ModalBodyProps {
  deletePost?: () => void;
  editPost?: (updates: WritePost) => void;
  reportPost?: () => void;
  showScope?: boolean;
}
const PostDetailMain: Preact.FunctionComponent<
  PostDetailBodyProps & {
    data: Post;
    onReact: (reaction: Reaction, deleted: boolean) => void;
    onComment: (comment: Comment, deleted: boolean) => void;
  }
> = ({
  data,
  closeButton,
  editPost,
  deletePost,
  reportPost,
  onReact,
  onComment,
  showScope,
}) => {
  const user = useAppUser();
  const creator_name = (data.created_by as User)?.full_name;

  const scope = useMemo(
    () => (showScope ? getScope(data.posted_to!) : {}),
    [data.posted_to, showScope]
  );
  const allow_group_updates = useGroupUpdatesAllowed(data?.posted_to);

  const is_admin_ui = useContext(IS_ADMIN_UI);
  const eval_can_interact = useContext(ACTIVITY_FEED_CAN_INTERACT);
  const can_interact = useMemo(
    () => eval_can_interact?.(data),
    [eval_can_interact, data]
  );
  const link = data.video || data.zendesk_article;

  if (!data.created_by || !user) {
    return null;
  }

  return (
    <div className="post__content">
      <div className="post__top">
        {scope.color && (
          <div className="post__top__border">
            <span style={{ color: scope.color }}> </span>
          </div>
        )}
        <div className="post__people">
          <div className="post__people__left">
            <Avatar
              // @ts-ignore
              user={data.created_by}
              size="md"
            />
            <div className="post__people__creator">
              <EntityLink entity={data.created_by}>{creator_name}</EntityLink>
              {scope.name && (
                <div className="post__people__scope">
                  in{" "}
                  <EntityLink
                    className="post__people__scope__link"
                    entity={data.posted_to!}
                  >
                    {scope.name}
                  </EntityLink>
                </div>
              )}
            </div>
          </div>
          <div className="post__people__right">{closeButton}</div>
        </div>
        {(data.has_tagged_user || data.mood) && (
          <div className="post__detail__block">
            <div className="post__detail__block__left">
              {data.mood && <PostMood mood={data.mood} />}
            </div>

            <div className="post__detail__block__right">
              {data.has_tagged_user && data.has_tagged_user.length > 0 && (
                <PostTaggedUsers post={data} containerId="post-detail-modal" />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="post__body">
        {data.body && (
          <div className="post__text">
            <TextWithLinks>{data.body}</TextWithLinks>
          </div>
        )}
        {data.photo_url && <PostPhoto data={data} />}
      </div>
      <div className="post__blocks">
        {data.is_asking_for_help && <PostHelp user={data.created_by} />}
        {data.event && <PostEvent event={data.event} isLink={true} />}
        {link && <PostLinkPreview link={link.id} />}
        {allow_group_updates && data.has_touchpoint && (
          <PostTouchpoint touchpoint={data.has_touchpoint} />
        )}
        {allow_group_updates && data.has_expense && (
          <PostExpense expense={data.has_expense} />
        )}
      </div>
      <PostMeta post={data} containerId="post-detail-modal" />
      {(can_interact || is_admin_ui) && (
        <div className="post__controls">
          <div className="post__controls__left">
            {can_interact && (
              <ReactionButton
                post_id={data.id}
                showCount={false}
                className="filled gray"
                onUpdate={onReact}
                reactions={data.has_reaction as Reaction[]}
              />
            )}
            {/*<ButtonWithIcon
            className="filled gray"
            icon="comment-solid-chat-bubble"
            side="left"
            // onClick={() => toggle_show_comments(!show_comments)}
          />*/}
          </div>
          <div className="post__controls__right">
            {can_interact && <PostShareButton post={data} />}
            <PostActions
              post={data}
              editPost={editPost}
              deletePost={deletePost}
              reportPost={reportPost}
            />
          </div>
        </div>
      )}
      <div className="post__comments">
        <CommentList
          post_id={data.id}
          onUpdate={onComment}
          focused={window.location.hash === "#comment"}
          post={data}
          canInteract={!!can_interact}
        />
      </div>
    </div>
  );
};

const PostDetailBody: Preact.FunctionComponent<
  PostDetailBodyProps & {
    showScope?: boolean;
    data: Post;
    dynamicListCtx: Preact.Context<DynamicListCtxSpec<Post, false, []>>;
  }
> = ({ data, showScope, dismiss, closeButton, dynamicListCtx, open }) => {
  const [page, setPage] = useStateIfMounted<PostDetailCarouselPage>("main");
  const goToMain = useCallback(() => setPage("main"), []);
  const goToDelete = useCallback(() => setPage("delete"), []);
  const goToEdit = useCallback(() => setPage("edit"), []);
  const on_report_post = useZendeskReportEntity(data);

  const [post, setPostValue, post_ref] = useStateRef(data);
  useEffect(() => {
    if (data !== post) setPostValue(data);
  }, [data]);

  const post_id = post.id;
  const { dispatch } = useContext(dynamicListCtx);

  // update the post in the parent post list
  const updateList = useCallback(
    (new_post: Post) => dispatch.update(i => i.id === post_id, new_post),
    [post_id, dispatch]
  );
  const updatePostInList = useDebounce(updateList, 350);

  const setPost = useCallback(
    (new_post: Post) => {
      updatePostInList.current?.(new_post);
      setPostValue(new_post);
    },
    [setPostValue]
  );

  const EditModal = useRenderPropsFunction<{ record: Post }>(
    ({ record }) => (
      <PostEdit
        target={record}
        open={open}
        dismiss={goToMain}
        onFinish={setPost}
      />
    ),
    "PostEdit-Bound",
    [setPost, open, page === "edit"]
  );

  const submitDeleteRequest = useApiFetch("deletePost", post_id);
  const afterDelete = useCallback(
    () => dispatch.remove(p => p.id === post_id),
    [dispatch, post_id]
  );

  const onReact = useCallback(
    (reaction: Reaction, deleted: boolean) => {
      const { has_reaction = [] } = post_ref.current;
      const updated = {
        ...post_ref.current,
        has_reaction: deleted
          ? remove_item_from(has_reaction, i => i.id === reaction.id)
          : add_item_to(has_reaction, reaction),
      };
      setPost(updated);
    },
    [post_ref, setPost]
  );

  const onComment = useCallback(
    (comment: Comment, deleted: boolean) => {
      const { has_comment = [] } = post_ref.current;
      const updated = {
        ...post_ref.current,
        has_comment: deleted
          ? remove_item_from(has_comment, i => i.id === comment.id)
          : replace_item_in(
              has_comment,
              c => c.id === comment.id,
              comment,
              "start"
            ),
      };
      setPost(updated);
    },
    [post_ref, setPost]
  );

  const pages = {
    main: (
      <PostDetailMain
        dismiss={dismiss}
        closeButton={closeButton}
        open={open}
        data={post}
        deletePost={goToDelete}
        editPost={goToEdit}
        reportPost={on_report_post}
        onReact={onReact}
        onComment={onComment}
        showScope={showScope}
      />
    ),
    delete: (
      <DefaultModalContent title="Delete Post" closeButton={closeButton}>
        <DeleteModalBody
          open={open}
          deleteRecord={submitDeleteRequest}
          afterDelete={afterDelete}
          dismiss={dismiss}
          cancel={goToMain}
        >
          Are you sure you want to delete this post?
        </DeleteModalBody>
      </DefaultModalContent>
    ),
    edit: <EditModal record={post} />,
    report: <button onClick={goToMain}>WIP</button>,
  };

  return <Carousel page={page} items={pages} trackHeight={true} />;
};

export const PostDetail: Preact.FunctionComponent<
  ModalBodyProps & {
    showScope?: boolean;
    data?: Post;
    post_id: string;
    dynamicListCtx: Preact.Context<DynamicListCtxSpec<Post, false, []>>;
  }
> = ({ data, post_id, ...props }) => {
  const initialData = useMemo(
    () => (data ? { ...data } : undefined),
    [post_id]
  );
  const PendingView = useRenderPropsFunction(
    () =>
      initialData ? (
        <PostDetailBody {...props} data={initialData} />
      ) : (
        <div className="post-detail__loading">
          <DefaultPendingView />
        </div>
      ),
    "PostDetailBody-Loading",
    [initialData, props]
  );

  const params = useMemo(
    () => ({
      query: {
        include: [
          "has_touchpoint",
          "has_reaction",
          "has_expense",
          "mood",
          "photo",
          "event.Event:cover_image",
          "created_by.User:profile_picture",
          "has_tagged_user.User:profile_picture",
          "has_comment.Comment:created_by.User:profile_picture",
          "posted_to",
        ] as RelationshipKeysOf<MetaTypes["Post"]>[],
      },
    }),
    []
  );

  const [SuccessView, getPost] = useAsyncRender(
    result => <PostDetailBody {...props} data={result.data} />,
    [props],
    "getPost",
    post_id,
    params
  );

  const { dispatch } = useContext(props.dynamicListCtx);
  const getAndStorePost = useCallback(
    () =>
      getPost().then(res => {
        dispatch.update(i => i.id === initialData?.id, res.data);
        return res;
      }),
    [initialData]
  );

  const initialDataProp = useMemo(
    () => (initialData ? { data: initialData } : undefined),
    [initialData]
  );
  if (!post_id) {
    return null;
  }

  return (
    <AsyncRender
      getPromise={getAndStorePost}
      PendingView={PendingView}
      keepViewOnUpdate={true}
      initialValue={initialDataProp}
    >
      {SuccessView}
    </AsyncRender>
  );
};
