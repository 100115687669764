// Metadata defining domain vocabulary and types.
import type { InterfacesFrom, MetaInterfaces } from "@swymbase/metatype-core";
import { Specs as $ } from "@swymbase/metatype-core";

export const VOCAB = "https://app.thread.org/vocab/v1#";
export const ENTITIES = "https://env.thread.org/entities/";

const DEFAULT_MEDIA_EXPIRATION = 60 * 60 * 24 * 6;

/**
 * Helper constructor for metatype definitions.  Provides argument with both a
 * `const`-like context (e.g. for use with `InterfacesFrom`) and contextual
 * awareness (completion as `MetaInterfaces`).
 *
 * See also https://github.com/Microsoft/TypeScript/issues/30680
 */
//todo: defining this here rather than importing from @thrive-web/metatype
//because otherwise parcel build throws up when resolving dependencies
export const metatypes = <T extends MetaInterfaces>(input: T): T => input;

export const TYPES = metatypes({
  User: {
    "@type": `${VOCAB}User`,
    comment: "A person with a registered account",
    instance_namespace: `${ENTITIES}User/`,
    properties: {
      email: {
        term: `${VOCAB}email`,
        type: "string",
      },
      first_name: {
        term: `${VOCAB}first_name`,
        type: "string",
      },
      last_name: {
        term: `${VOCAB}last_name`,
        type: "string",
      },
      full_name: {
        term: `${VOCAB}full_name`,
        type: "string",
        comment: "Computed property.",
      },
      birth_date: {
        term: `${VOCAB}birth_date`,
        type: "string",
      },
      phone_number: {
        term: `${VOCAB}phone_number`,
        type: "string",
      },
      firebase_uuid: {
        term: `${VOCAB}firebase_uuid`,
        type: "string",
      },
      profile_picture: {
        term: `${VOCAB}profile_picture`,
        type: "object",
        relationship: true,
        media: {
          url_property: "profile_picture_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      profile_picture_url: {
        // TODO: support “computed” properties, which will not require term
        term: `${VOCAB}DUMMY`,
        type: "string",
      },
      has_experience: {
        term: `${VOCAB}has_experience`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      has_completed_profile: {
        term: `${VOCAB}has_completed_profile`,
        type: "boolean",
        comment: "This is a computed property only used in /self",
      },
      has_group_membership: {
        term: `${VOCAB}has_group_membership`,
        type: "boolean",
        comment: "This is a computed property only used in /self",
      },
      has_completed_identities: {
        term: `${VOCAB}has_completed_identities`,
        type: "boolean",
        comment: `Indicates if the user has completed the identity section
        of registration. Stored value.`,
      },
      has_connection: {
        term: `${VOCAB}has_connection`,
        type: "object",
        relationship: true,
        multivalued: true,
        converse_property: `${VOCAB}connection_users`,
      },
      has_current_mood: {
        term: `${VOCAB}has_current_mood`,
        type: "object",
        relationship: true,
      },
      has_gender_identity: {
        term: `${VOCAB}has_gender_identity`,
        type: "object",
        relationship: true,
      },
      has_ethnic_identity: {
        term: `${VOCAB}has_ethnic_identity`,
        type: "object",
        relationship: true,
      },
      has_group_identity: {
        term: `${VOCAB}has_group_identity`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      has_race_identity: {
        term: `${VOCAB}has_race_identity`,
        type: "object",
        relationship: true,
      },
      is_transgender: {
        term: `${VOCAB}is_transgender`,
        type: "boolean",
      },
      passions: {
        term: `${VOCAB}passions`,
        type: "string",
      },
      notifications_last_seen_at: {
        term: `${VOCAB}notifications_last_seen_at`,
        type: "string",
        comment: "Used to determine badge count for push notifications.",
      },
      is_adult: {
        term: `${VOCAB}`,
        type: "boolean",
        comment: "Computed property, dependant on birth_date",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Group: {
    "@type": `${VOCAB}Group`,
    comment: "Two or more users with some common goal or interest.",
    instance_namespace: `${ENTITIES}Group/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      has_member: {
        term: `${VOCAB}has_member`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      in_community: {
        term: `${VOCAB}in_community`,
        type: "object",
        relationship: true,
        converse_property: `${VOCAB}has_group`,
      },
      has_admin: {
        term: `${VOCAB}has_admin`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      background_color: {
        term: `${VOCAB}background_color`,
        type: "string",
      },
      background_image: {
        term: `${VOCAB}background_image`,
        type: "object",
        relationship: true,
        media: {
          url_property: "background_image_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      background_image_url: {
        term: `${VOCAB}DUMMY`, // see above
        type: "string",
      },
      purpose: {
        term: `${VOCAB}purpose`,
        type: "string",
      },
      is_private: {
        term: `${VOCAB}is_private`,
        type: "boolean",
      },
      has_relationship_management: {
        term: `${VOCAB}has_relationship_management`,
        type: "boolean",
      },
      has_goals: {
        term: `${VOCAB}has_goals`,
        type: "boolean",
      },
      provides_help_with: {
        term: `${VOCAB}provides_help_with`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      can_members_invite: {
        term: `${VOCAB}can_members_invite`,
        type: "boolean",
      },
      score: {
        term: `${VOCAB}score`,
        type: "number",
      },
      is_archived: {
        term: `${VOCAB}is_archived`,
        type: "boolean",
      },
      has_label: {
        term: `${VOCAB}has_label`,
        type: "object",
        comment:
          "CommunityGroupLabel used for categorization purposes, only available to admins",
        relationship: true,
        multivalued: true,
      },
      last_activity_at: {
        term: `${VOCAB}last_activity_at`,
        type: "string",
        comment: "Date of last group activity (post created)",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  TraitifyAssessment: {
    "@type": `${VOCAB}TraitifyAssessment`,
    comment: "A wrapper around a traitify assessment.",
    instance_namespace: `${ENTITIES}TraitifyAssessment/`,
    properties: {
      assessment_id: {
        term: `${VOCAB}assessment_id`,
        type: "string",
      },
      assessment: {
        term: `${VOCAB}assessment`,
        type: {
          // This is not actually used as such.  The value is served directly
          // from the Traitify API in an observer.  But we need a valid spec so
          // that the value can be marshaled in and out without error.  It may
          // be that this could be used as a backup cache, now that it will be
          // available going forward.  See also
          // `packages/lib/@thrive-web/core/src/@types/traitify.ts`
          spec: $.record({
            slides: $.array(
              $.record({
                id: $.string,
                position: $.number,
                caption: $.string,
                // images: $.array($.string),
                image_desktop: $.string,
                image_desktop_retina: $.string,
                response: $.boolean, // should be nullable
                // likert_response: null,
                time_taken: $.number,
                focus_x: $.number,
                focus_y: $.number,
                alternative_text: $.string,
                created_at: $.string, // '2021-05-24T18:51:58.371Z'
              })
            ),
          }),
        },
      },
      completed_at: {
        term: `${VOCAB}completed_at`,
        type: "string",
      },
      started_at: {
        term: `${VOCAB}started_at`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      taken_by: {
        term: `${VOCAB}taken_by`,
        type: "object",
        relationship: true,
      },
      taken_about: {
        term: `${VOCAB}taken_about`,
        type: "object",
        relationship: true,
      },
    },
  },
  AssessmentFeedbackInvitation: {
    "@type": `${VOCAB}AssessmentFeedbackInvitation`,
    comment:
      "An invitation for a third-party to give feedback about an assessment.",
    instance_namespace: `${ENTITIES}AssessmentFeedbackInvitation/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      contact: {
        term: `${VOCAB}contact`,
        type: "string",
        comment: "A phone number or email address",
      },
      contact_type: {
        term: `${VOCAB}contact_type`,
        type: "string",
        comment: "enum: tel,email",
      },
      sender: {
        term: `${VOCAB}sender`,
        type: "object",
        comment: "The user that sent the invite",
        relationship: true,
      },
      recipient_assessment: {
        term: `${VOCAB}recipient_assessment`,
        type: "object",
        relationship: true,
      },
      token: {
        term: `${VOCAB}token`,
        comment: "Used to authenticate anonymous users",
        type: "string",
      },
      updated_at: {
        term: `${VOCAB}updated_at`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  ExperienceCategory: {
    "@type": `${VOCAB}ExperienceCategory`,
    comment:
      "A experience category term, to be looked up as valid value for input",
    instance_namespace: `${ENTITIES}ExperienceCategory/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      description: {
        term: `${VOCAB}description`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  Mood: {
    "@type": `${VOCAB}Mood`,
    comment: `A temporary feeling`,
    instance_namespace: `${ENTITIES}Mood/`,
    properties: {
      symbol: {
        term: `${VOCAB}symbol`,
        type: "string",
      },
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  CapturedMood: {
    "@type": `${VOCAB}CapturedMood`,
    comment: "A captured feeling",
    instance_namespace: `${ENTITIES}CapturedMood/`,
    properties: {
      mood: {
        term: `${VOCAB}mood`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  Post: {
    "@type": `${VOCAB}Post`,
    comment: "A piece of content",
    instance_namespace: `${ENTITIES}Post/`,
    properties: {
      posted_to: {
        term: `${VOCAB}post_posted_to`,
        type: "object",
        relationship: true,
      },
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      body: {
        term: `${VOCAB}body`,
        type: "string",
      },
      has_tagged_user: {
        term: `${VOCAB}has_tagged_user`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      mood: {
        term: `${VOCAB}mood`,
        type: "object",
        relationship: true,
      },
      event: {
        term: `${VOCAB}event`,
        type: "object",
        relationship: true,
      },
      photo: {
        term: `${VOCAB}photo`,
        type: "object",
        relationship: true,
        media: {
          url_property: "photo_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      photo_url: {
        term: `${VOCAB}DUMMY`,
        type: "string",
      },
      video: {
        term: `${VOCAB}video`,
        comment: `The IRI of this object is expected to be the URL of a video at some well-known service.`,
        type: "object",
        relationship: true,
      },
      zendesk_article: {
        term: `${VOCAB}zendesk_article`,
        comment: `The IRI of this object is expected to be the URL of an article on a well-known Zendesk content site.`,
        type: "object",
        relationship: true,
      },
      has_reaction: {
        term: `${VOCAB}has_reaction`,
        type: "object",
        relationship: true,
        multivalued: true,
        converse_property: `${VOCAB}reacted_to`,
      },
      has_comment: {
        term: `${VOCAB}has_comment`,
        type: "object",
        relationship: true,
        multivalued: true,
        converse_property: `${VOCAB}comment_posted_to`,
      },
      has_touchpoint: {
        term: `${VOCAB}post_has_touchpoint`,
        type: "object",
        relationship: true,
      },
      has_expense: {
        term: `${VOCAB}post_has_expense`,
        type: "object",
        relationship: true,
      },
      is_asking_for_help: {
        term: `${VOCAB}is_asking_for_help`,
        type: "boolean",
      },
      is_editable: {
        term: `${VOCAB}DUMMY`,
        type: "boolean",
        comment: "A computed property calculated in observer.",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Installation: {
    "@type": `${VOCAB}Installation`,
    comment: "A mobile app installation",
    instance_namespace: `${ENTITIES}Installation/`,
    properties: {
      user: {
        term: `${VOCAB}user`,
        type: "object",
        relationship: true,
      },
      device_token: {
        term: `${VOCAB}device_token`,
        type: "string",
      },
      app_version: {
        term: `${VOCAB}app_version`,
        type: "string",
      },
      os: {
        term: `${VOCAB}os`,
        type: "string",
      },
      os_version: {
        term: `${VOCAB}os_version`,
        type: "string",
      },
      device_model: {
        term: `${VOCAB}device_model`,
        type: "string",
      },
      is_notifications_enabled: {
        term: `${VOCAB}is_notifications_enabled`,
        type: "boolean",
      },
      silenced_notification_types: {
        term: `${VOCAB}silenced_notification_types`,
        type: "string",
        multivalued: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Goal: {
    "@type": `${VOCAB}Goal`,
    comment: "An objective of a group",
    instance_namespace: `${ENTITIES}Goal/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      description: {
        term: `${VOCAB}description`,
        type: "string",
      },
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  GoalPreset: {
    "@type": `${VOCAB}GoalPreset`,
    comment: "Preset for Goals",
    instance_namespace: `${ENTITIES}GoalPreset/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  Todo: {
    "@type": `${VOCAB}Todo`,
    comment: "A task to complete",
    instance_namespace: `${ENTITIES}Todo/`,
    properties: {
      description: {
        term: `${VOCAB}description`,
        type: "string",
      },
      completed: {
        term: `${VOCAB}completed`,
        type: "boolean",
      },
      goal: {
        term: `${VOCAB}goal`,
        type: "object",
        relationship: true,
      },
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      due_date: {
        term: `${VOCAB}due_date`,
        type: "string",
      },
      assigned_to: {
        term: `${VOCAB}assigned_to`,
        type: "object",
        relationship: true,
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Connection: {
    "@type": `${VOCAB}Connection`,
    comment: "A link between two users.",
    instance_namespace: `${ENTITIES}Connection/`,
    properties: {
      users: {
        term: `${VOCAB}connection_users`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      connection_request: {
        term: `${VOCAB}request`,
        type: "object",
        relationship: true,
      },
      has_coach: {
        term: `${VOCAB}has_coach`,
        type: "object",
        relationship: true,
        comment: `Dictates which user is the Coach in the Connection. The other user
          is inferred as Coachee.`,
      },
      raw_health: {
        term: `${VOCAB}raw_health`,
        type: "number",
        comment: "Internal health value used to calculate health tier.",
      },
      last_interaction_date: {
        term: `${VOCAB}last_interaction_date`,
        type: "string",
        comment:
          "Internal date used to calculate health tier. Last time raw_health was updated.",
      },
      health_tier: {
        term: `${VOCAB}DUMMY`,
        type: "number",
        comment: "Computed health tier value.",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  ConnectionRequest: {
    "@type": `${VOCAB}ConnectionRequest`,
    comment: "A request to connection",
    instance_namespace: `${ENTITIES}ConnectionRequest/`,
    properties: {
      recipient: {
        term: `${VOCAB}recipient`,
        type: "object",
        relationship: true,
      },
      sender: {
        term: `${VOCAB}sender`,
        type: "object",
        relationship: true,
      },
      accepted: {
        term: `${VOCAB}accepted`,
        type: "boolean",
      },
      accepted_at: {
        term: `${VOCAB}accepted_at`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Event: {
    "@type": `${VOCAB}Event`,
    comment: "An event posted to a community",
    instance_namespace: `${ENTITIES}Event/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      description: {
        term: `${VOCAB}description`,
        type: "string",
      },
      has_rsvp: {
        term: `${VOCAB}event_has_rsvp`,
        type: "object",
        relationship: true,
        multivalued: true,
        converse_property: `${VOCAB}event_rsvp_event`,
      },
      has_invitee: {
        term: `${VOCAB}has_invitee`,
        type: "object",
        relationship: true,
        multivalued: true,
        comment: "Users invited to this Event",
      },
      location_name: {
        term: `${VOCAB}location_name`,
        type: "string",
        comment: "Optional location name to be used for view purposes",
      },
      location: {
        term: `${VOCAB}location`,
        type: "object",
        relationship: true,
        comment: "Relationship pointing to Address record",
      },
      cover_image: {
        term: `${VOCAB}cover_image`,
        type: "object",
        relationship: true,
        media: {
          url_property: "cover_image_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      cover_image_url: {
        term: `${VOCAB}DUMMY`,
        type: "string",
      },
      is_public: {
        term: `${VOCAB}is_public`,
        type: "boolean",
      },
      is_canceled: {
        term: `${VOCAB}is_canceled`,
        type: "boolean",
      },
      date_time: {
        term: `${VOCAB}date_time`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  Community: {
    "@type": `${VOCAB}Community`,
    comment: "A set of groups.",
    instance_namespace: `${ENTITIES}Community/`,
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
      },
      purpose: {
        term: `${VOCAB}purpose`,
        type: "string",
      },
      description: {
        term: `${VOCAB}description`,
        type: "string",
      },
      website_url: {
        term: `${VOCAB}website_url`,
        type: "object",
        relationship: true,
      },
      accent_color: {
        term: `${VOCAB}accent_color`,
        type: "string",
      },
      has_member: {
        term: `${VOCAB}has_member`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      has_admin: {
        term: `${VOCAB}has_admin`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      has_manager: {
        term: `${VOCAB}has_manager`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      has_moderator: {
        term: `${VOCAB}has_moderator`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      has_group: {
        term: `${VOCAB}has_group`,
        type: "object",
        multivalued: true,
        relationship: true,
      },
      cover_image: {
        term: `${VOCAB}cover_image`,
        type: "object",
        relationship: true,
        media: {
          url_property: "cover_image_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      cover_image_url: {
        term: `${VOCAB}DUMMY`, // see above
        type: "string",
      },
      avatar_image: {
        term: `${VOCAB}avatar_image`,
        type: "object",
        relationship: true,
        media: {
          url_property: "avatar_image_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      avatar_image_url: {
        term: `${VOCAB}DUMMY`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Address: {
    "@type": `${VOCAB}Address`,
    comment: "A location",
    instance_namespace: `${ENTITIES}Address/`,
    properties: {
      common_name: {
        term: `${VOCAB}common_name`,
        type: "string",
      },
      country_code: {
        term: `${VOCAB}country_code`,
        type: "string",
      },
      administrative_area: {
        term: `${VOCAB}administrative_area`,
        type: "string",
      },
      locality: {
        term: `${VOCAB}locality`,
        type: "string",
      },
      postal_code: {
        term: `${VOCAB}postal_code`,
        type: "string",
      },
      address_line1: {
        term: `${VOCAB}address_line1`,
        type: "string",
      },
      address_line2: {
        term: `${VOCAB}address_line2`,
        type: "string",
      },
      lat: {
        term: `${VOCAB}lat`,
        type: "number",
      },
      lng: {
        term: `${VOCAB}lng`,
        type: "number",
      },
      formatted_address: {
        term: `${VOCAB}formatted_address`,
        type: "string",
      },
    },
  },
  EventRSVP: {
    "@type": `${VOCAB}EventRSVP`,
    comment: "Event RSVP response",
    instance_namespace: `${ENTITIES}EventRSVP/`,
    properties: {
      event: {
        term: `${VOCAB}event_rsvp_event`,
        type: "object",
        relationship: true,
      },
      response: {
        term: `${VOCAB}response`,
        type: "string",
        comment: "Enum: going, maybe, declined",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  Reaction: {
    "@type": `${VOCAB}Reaction`,
    comment: "A response to some record",
    instance_namespace: `${ENTITIES}Reaction/`,
    properties: {
      reacted_to: {
        term: `${VOCAB}reacted_to`,
        type: "object",
        relationship: true,
        comment: "Can be related to any entity type in practice.",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  Comment: {
    "@type": `${VOCAB}Comment`,
    comment: "A remark about a piece of content.",
    instance_namespace: `${ENTITIES}Comment/`,
    properties: {
      body: {
        term: `${VOCAB}body`,
        type: "string",
      },
      posted_to: {
        term: `${VOCAB}comment_posted_to`,
        type: "object",
        relationship: true,
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Notification: {
    "@type": `${VOCAB}Notification`,
    comment: "A notification record",
    instance_namespace: `${ENTITIES}Notification/`,
    properties: {
      recipient: {
        term: `${VOCAB}recipient`,
        type: "object",
        relationship: true,
      },
      initiator: {
        term: `${VOCAB}initiator`,
        type: "object",
        relationship: true,
        comment: "A user whose action initiated the notification",
      },
      connection_request: {
        term: `${VOCAB}connection_request`,
        type: "object",
        relationship: true,
        comment: `Connection Request Notification specific property`,
      },
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      body: {
        term: `${VOCAB}body`,
        type: "string",
      },
      custom_type: {
        term: `${VOCAB}custom_type`,
        type: "string",
      },
      custom_data: {
        term: `${VOCAB}custom_data`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      read: {
        term: `${VOCAB}read`,
        type: "boolean",
      },
      hidden: {
        term: `${VOCAB}hidden`,
        type: "boolean",
      },
    },
  },
  TouchpointAction: {
    "@type": `${VOCAB}TouchpointAction`,
    comment: "What a user did during a touchpoint",
    instance_namespace: `${ENTITIES}TouchpointAction/`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  TouchpointAccomplishment: {
    "@type": `${VOCAB}TouchpointAccomplishment`,
    comment: "What a user accomplished during a touchpoint",
    instance_namespace: `${ENTITIES}TouchpointAccomplishment`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  Touchpoint: {
    "@type": `${VOCAB}Touchpoint`,
    comment: "An update to a Thread Group",
    instance_namespace: `${ENTITIES}Touchpoint/`,
    properties: {
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      description: {
        term: `${VOCAB}description`,
        type: "string",
      },
      has_action: {
        term: `${VOCAB}has_action`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      has_accomplishment: {
        term: `${VOCAB}has_accomplishment`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      has_goal: {
        term: `${VOCAB}has_goal`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      barrier: {
        term: `${VOCAB}barrier`,
        type: "string",
      },
      posted_to: {
        term: `${VOCAB}touchpoint_posted_to`,
        type: "object",
        relationship: true,
        converse_property: `${VOCAB}post_has_touchpoint`,
      },
      sf_id: {
        term: `${VOCAB}sf_id`,
        type: "string",
      },
      sf_error: {
        term: `${VOCAB}sf_error`,
        type: "string",
      },
      sf_ignored: {
        term: `${VOCAB}sf_ignored`,
        type: "boolean",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Interaction: {
    "@type": `${VOCAB}Interaction`,
    comment: "A communication between two users",
    instance_namespace: `${ENTITIES}Interaction/`,
    properties: {
      interaction: {
        term: `${VOCAB}interaction`,
        type: "string",
        comment: "A enum",
      },
      needs_follow_up: {
        term: `${VOCAB}needs_follow_up`,
        type: "boolean",
      },
      target: {
        term: `${VOCAB}target`,
        type: "object",
        relationship: true,
      },
      successful: {
        term: `${VOCAB}successful`,
        type: "boolean",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  ExpenseCategory: {
    "@type": `${VOCAB}ExpenseCategory`,
    instance_namespace: `${ENTITIES}ExpenseCategory/`,
    comment: "What a user spent money on",
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  ExpenseReason: {
    "@type": `${VOCAB}ExpenseReason`,
    instance_namespace: `${ENTITIES}ExpenseReason/`,
    comment: "Why a user spent money",
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  Expense: {
    "@type": `${VOCAB}Expense`,
    instance_namespace: `${ENTITIES}Expense/`,
    comment: "Get reimbursed for a touchpoint",
    properties: {
      touchpoint: {
        term: `${VOCAB}touchpoint`,
        type: "object",
        relationship: true,
      },
      receipt_photo: {
        term: `${VOCAB}receipt_photo`,
        type: "object",
        relationship: true,
        media: {
          url_property: "receipt_photo_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["image"],
        },
      },
      receipt_photo_url: {
        term: `${VOCAB}DUMMY`,
        type: "string",
      },
      vendor_name: {
        term: `${VOCAB}vendor_name`,
        type: "string",
      },
      purchase_date: {
        term: `${VOCAB}purchase_date`,
        type: "string",
      },
      receipt_amount: {
        term: `${VOCAB}receipt_amount`,
        type: "number",
      },
      requested_amount: {
        term: `${VOCAB}requested_amount`,
        type: "number",
      },
      details: {
        term: `${VOCAB}details`,
        type: "string",
      },
      distribution_method: {
        term: `${VOCAB}distribution_method`,
        type: "string",
        comment: "enum: check,paypal",
      },
      has_category: {
        term: `${VOCAB}has_category`,
        type: "object",
        relationship: true,
      },
      has_reason: {
        term: `${VOCAB}has_reason`,
        type: "object",
        relationship: true,
      },
      posted_to: {
        term: `${VOCAB}expense_posted_to`,
        type: "object",
        relationship: true,
        converse_property: `${VOCAB}post_has_expense`,
      },
      is_approved: {
        term: `${VOCAB}is_approved`,
        type: "boolean",
      },
      approved_by: {
        term: `${VOCAB}approved_by`,
        type: "object",
        relationship: true,
      },
      approved_at: {
        term: `${VOCAB}approved_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  GenderIdentity: {
    "@type": `${VOCAB}GenderIdentity`,
    instance_namespace: `${ENTITIES}GenderIdentity/`,
    comment: `A personal sense of one's own gender.`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  EthnicIdentity: {
    "@type": `${VOCAB}EthnicIdentity`,
    instance_namespace: `${ENTITIES}EthnicIdentity/`,
    comment: `A personal sense of one's own ethnic group.`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  GroupIdentity: {
    "@type": `${VOCAB}GroupIdentity`,
    instance_namespace: `${ENTITIES}GroupIdentity/`,
    comment: `A personal sense of one belonging to a particular group.`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  RaceIdentity: {
    "@type": `${VOCAB}RaceIdentity`,
    instance_namespace: `${ENTITIES}RaceIdentity/`,
    comment: `A personal sense of one's own racial group.`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      display_rank: {
        term: `${VOCAB}display_rank`,
        type: "number",
      },
    },
  },
  CustomIdentity: {
    "@type": `${VOCAB}CustomIdentity`,
    instance_namespace: `${ENTITIES}CustomIdentity/`,
    comment: `A custom personal sense of one's own identity. To be used as a custom value.`,
    properties: {
      title: {
        term: `${VOCAB}title`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  AwardedGroupPoints: {
    "@type": `${VOCAB}AwardedGroupPoints`,
    instance_namespace: `${ENTITIES}AwardedGroupPoints/`,
    comment: "",
    properties: {
      trigger_id: {
        term: `${VOCAB}trigger_id`,
        type: "string",
        comment: "enum",
      },
      triggering_record: {
        term: `${VOCAB}triggering_record`,
        type: "object",
        relationship: true,
      },
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      points: {
        term: `${VOCAB}points`,
        type: "number",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  RegistrationInvitation: {
    "@type": `${VOCAB}RegistrationInvitation`,
    instance_namespace: `${ENTITIES}RegistrationInvitation/`,
    comment: "",
    properties: {
      recipient: {
        term: `${VOCAB}recipient`,
        type: "object",
        relationship: true,
      },
      referrer: {
        term: `${VOCAB}referrer`,
        type: "object",
        relationship: true,
      },
      auto_send: {
        term: `${VOCAB}auto_send`,
        type: "boolean",
      },
      token: {
        term: `${VOCAB}token`,
        type: "string",
      },
      link: {
        term: `${VOCAB}link`,
        type: "string",
        comment:
          "Computed property. Firebase dynamic link generated when the record is retrieved.",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Media: {
    "@type": `${VOCAB}Media`,
    // This is required in sparql-rest, but internally we will always specify an ID on record creation.
    instance_namespace: `${ENTITIES}Media/`,
    comment:
      "Container to store metadata about AWS S3 objects. The IRI should be an ARN.",
    properties: {
      video_duration: {
        term: `${VOCAB}video_duration`,
        type: "number",
        comment:
          "Video duration in milliseconds. Only has value when applicable.",
      },
      video_thumbnail: {
        term: `${VOCAB}video_thumbnail`,
        type: "object",
        relationship: true,
        media: {
          url_property: "video_thumbnail_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
        },
      },
      video_thumbnail_url: {
        term: `${VOCAB}video_thumbnail_url`,
        type: "string",
      },
      small: {
        term: `${VOCAB}small`,
        type: "object",
        relationship: true,
        media: {
          url_property: "small_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
        },
      },
      small_url: {
        term: `${VOCAB}small_url`,
        type: "string",
      },
      medium: {
        term: `${VOCAB}medium`,
        type: "object",
        relationship: true,
        media: {
          url_property: "medium_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
        },
      },
      medium_url: {
        term: `${VOCAB}medium_url`,
        type: "string",
      },
      large: {
        term: `${VOCAB}large`,
        type: "object",
        relationship: true,
        media: {
          url_property: "large_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
        },
      },
      large_url: {
        term: `${VOCAB}large_url`,
        type: "string",
      },
      xlarge: {
        term: `${VOCAB}xlarge`,
        type: "object",
        relationship: true,
        media: {
          url_property: "xlarge_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
        },
      },
      xlarge_url: {
        term: `${VOCAB}xlarge_url`,
        type: "string",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  Conversation: {
    "@type": `${VOCAB}Conversation`,
    instance_namespace: `${ENTITIES}Conversation/`,
    comment: "A communication between one or more users.",
    properties: {
      name: {
        term: `${VOCAB}name`,
        type: "string",
        comment: "A custom name for a Conversation, optional.",
      },
      users: {
        term: `${VOCAB}users`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      has_current_conversation_starter: {
        term: `${VOCAB}has_current_conversation_starter`,
        type: "object",
        relationship: true,
        multivalued: true,
        comment: "Reference to current conversation starter record.",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      updated_at: {
        term: `${VOCAB}updated_at`,
        type: "string",
      },
    },
  },
  Message: {
    "@type": `${VOCAB}Message`,
    instance_namespace: `${ENTITIES}Message/`,
    comment: "A message in a Conversation",
    properties: {
      posted_to: {
        term: `${VOCAB}posted_to`,
        type: "object",
        relationship: true,
      },
      text: {
        term: `${VOCAB}text`,
        type: "string",
      },
      video: {
        term: `${VOCAB}video`,
        type: "object",
        relationship: true,
        media: {
          url_property: "video_url",
          expiration: DEFAULT_MEDIA_EXPIRATION,
          processors: ["video"],
        },
      },
      video_url: {
        term: `${VOCAB}video_url`,
        type: "string",
      },
      has_read_receipt: {
        term: `${VOCAB}has_read_receipt`,
        type: "object",
        relationship: true,
        multivalued: true,
        converse_property: `${VOCAB}message_read_receipt_message`,
      },
      is_conversation_starter_question: {
        term: `${VOCAB}is_conversation_starter_question`,
        type: "boolean",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  MessageReadReceipt: {
    "@type": `${VOCAB}MessageReadReceipt`,
    instance_namespace: `${ENTITIES}MessageReadReceipt/`,
    comment: "A receipt of a message being read.",
    properties: {
      message: {
        term: `${VOCAB}message_read_receipt_message`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}read_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
  RelationshipFulfillmentSurvey: {
    "@type": `${VOCAB}RelationshipFulfillmentSurvey`,
    instance_namespace: `${ENTITIES}RelationshipFulfillmentSurvey/`,
    comment: "A survey used to measure a user's relationship fulfillment.",
    properties: {
      score: {
        term: `${VOCAB}score`,
        type: "number",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}read_at`,
        type: "string",
      },
    },
  },
  GroupInvitation: {
    "@type": `${VOCAB}GroupInvitation`,
    instance_namespace: `${ENTITIES}GroupInvitation/`,
    comment: "An invitation to a Group",
    properties: {
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      group_name: {
        term: `${VOCAB}group_name`,
        type: "string",
      },
      user_name: {
        term: `${VOCAB}user_name`,
        type: "string",
      },
      link: {
        term: `${VOCAB}link`,
        type: "string",
        comment:
          "Computed property. Firebase dynamic link generated when the record is retrieved.",
      },
      referrer: {
        term: `${VOCAB}referrer`,
        type: "object",
        relationship: true,
      },
      is_accepted: {
        term: `${VOCAB}is_accepted`,
        type: "boolean",
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  GroupJoin: {
    "@type": `${VOCAB}GroupJoin`,
    instance_namespace: `${ENTITIES}GroupJoin/`,
    comment: "A record used to track and manage group joins",
    properties: {
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      user: {
        term: `${VOCAB}user`,
        type: "object",
        relationship: true,
      },
      invitation: {
        term: `${VOCAB}invitation`,
        type: "object",
        relationship: true,
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  GroupLeave: {
    "@type": `${VOCAB}GroupLeave`,
    instance_namespace: `${ENTITIES}GroupLeave/`,
    comment: "A record used to track and manage group leaves",
    properties: {
      group: {
        term: `${VOCAB}group`,
        type: "object",
        relationship: true,
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
    },
  },
  ConversationStarter: {
    "@type": `${VOCAB}ConversationStarter`,
    instance_namespace: `${ENTITIES}ConversationStarter/`,
    comment: "A guided experience to spark conversations.",
    properties: {
      conversation: {
        term: `${VOCAB}conversation`,
        type: "object",
        relationship: true,
      },
      questions: {
        term: `${VOCAB}questions`,
        type: "object",
        relationship: true,
        multivalued: true,
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      updated_at: {
        term: `${VOCAB}updated_at`,
        type: "string",
      },
    },
  },
  ConversationStarterQuestion: {
    "@type": `${VOCAB}ConversationStarterQuestion`,
    instance_namespace: `${ENTITIES}ConversationStarterQuestion/`,
    comment: "A conversation starter question; taxonomy (w/o display_rank).",
    properties: {
      question: {
        term: `${VOCAB}question`,
        type: "object",
        relationship: true,
        media: {
          expiration: 0,
          url_property: "dummy_unused",
          processors: ["video"],
        },
        comment:
          "Media will be referenced by Messages, videos will not be served via these records directly.",
      },
      text: {
        term: `${VOCAB}text`,
        type: "string",
      },
      difficulty: {
        term: `${VOCAB}difficulty`,
        type: "number",
        comment: "0=intro; 1=mild; 2=medium; 3=hard",
      },
    },
  },
  CommunityGroupLabel: {
    "@type": `${VOCAB}CommunityGroupLabel`,
    instance_namespace: `${ENTITIES}CommunityGroupLabel/`,
    comment: "A user-created taxonomy used to tag community groups.",
    properties: {
      label: {
        term: `${VOCAB}label`,
        type: "string",
      },
      community: {
        term: `${VOCAB}community`,
        type: "object",
        relationship: true,
      },
      created_at: {
        term: `${VOCAB}created_at`,
        type: "string",
      },
      created_by: {
        term: `${VOCAB}created_by`,
        type: "object",
        relationship: true,
      },
    },
  },
});

export type MetaTypes = typeof TYPES;
export type Types = InterfacesFrom<typeof TYPES>;
