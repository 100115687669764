import { DocBase, map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import {
  ApiMethodCaller,
  ApiMethodParameters,
  Event,
  MappedApiResponse,
  WriteEvent,
} from "@thrive-web/ui-api";
import { RSVPResponses } from "@thrive-web/ui-constants";
import {
  DefaultModalContent,
  ErrorMessage,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import { EventForm } from "~/view/components";
import {
  useApiMethod,
  useAppUser,
  useRequest,
  useRequestChain,
  useStateIfMounted,
  useUntrackedRequestWithMedia,
} from "@thrive-web/ui-hooks";

export const EventCreate: Preact.FunctionComponent<
  ModalBodyProps & {
    onFinish: (event: WriteEvent) => void;
    initialData?: WriteEvent;
  }
> = ({ onFinish, initialData, dismiss, closeButton }) => {
  const self = useAppUser();
  // cover image file
  const [file, on_change_file] = useStateIfMounted<FileUploadData | undefined>(
    undefined
  );
  // POST type/Address
  const create_address_request = useApiMethod("createAddress");
  // POST type/Event
  const create_event_record_request = useApiMethod("createEvent");

  // create event and upload cover image
  const [create_event_request, , event_progress] = useUntrackedRequestWithMedia(
    "Event",
    "cover_image",
    create_event_record_request,
    file,
    false,
    true
  );

  // merge create-address result with event data
  const get_event_params_from_location = useCallback(
    (
      location: MappedApiResponse<"createAddress">,
      params: ApiMethodParameters<"createEvent">
    ) => {
      if (location.data) {
        const data = params[0];
        if (!data?.body?.data?.relationships) {
          data!.body.data.relationships = {};
        }
        data!.body.data.relationships.location = {
          data: { id: location.data.id },
        };
        // @ts-expect-error:
        data!.body.data.attributes.location_name = location.data.common_name;

        params[0] = data;
      }
      return params;
    },
    []
  );

  // combine new event and address records
  const combine_event_and_location = useCallback(
    (
      location: MappedApiResponse<"createAddress">,
      event: MappedApiResponse<"createEvent">
    ): DocBase & { data: Event } => {
      return {
        ...event,
        data: {
          ...event.data,
          location: location.data,
        },
      };
    },
    []
  );

  // chain address and event creation
  const create_event_chained = useRequestChain<
    ApiMethodCaller<"createAddress">,
    ApiMethodCaller<"createEvent">,
    MappedApiResponse<"createEvent">
  >(
    create_address_request,
    create_event_request,
    combine_event_and_location,
    get_event_params_from_location
  );

  const create_event = useCallback(
    (new_event: Event) => {
      // put in JSON api format
      const { id, type, ...data } = map_record_to_json_resource(
        new_event,
        TYPES.Event
      );
      // params for createEvent request
      const params_2: ApiMethodParameters<"createEvent"> = [
        {
          body: { data },
          // @ts-expect-error:
          query: {
            include: ["location", "has_invitee.User:profile_picture"],
          },
        },
      ];
      // if no location, just create the event
      if (!new_event.location) {
        return create_event_request(...params_2);
      }

      const {
        id: _,
        type: __,
        ...address_data
      } = map_record_to_json_resource(
        { ...new_event.location, id: "" },
        TYPES.Address
      );
      // params for createAddress request
      const params_1: ApiMethodParameters<"createAddress"> = [
        {
          body: { data: address_data },
        },
      ];
      return create_event_chained(params_1, params_2);
    },
    [create_event_request, create_event_chained]
  );

  // POST type/EventRSVP
  const create_rsvp_req = useApiMethod("createEventRSVP");
  const create_rsvp = useCallback(
    (new_event: Event, rsvp: RSVPResponses) => {
      return create_rsvp_req({
        body: {
          data: {
            attributes: {
              response: rsvp,
            },
            relationships: {
              event: { data: { id: new_event.id } },
            },
          },
        },
      });
    },
    [create_rsvp_req]
  );

  // combine event and event rsvp results
  const combine_event_and_rsvp = useCallback(
    (
      event: MappedApiResponse<"createEvent">,
      rsvp: MappedApiResponse<"createEventRSVP">
    ) => ({ ...event, has_rsvp: [rsvp] }),
    []
  );

  // get created event record for rsvp creation
  const get_rsvp_params_from_event = useCallback(
    (
      event: MappedApiResponse<"createEvent">,
      params: [Event, RSVPResponses]
    ) => {
      if (event.data) {
        params[0] = event.data;
      }
      return params;
    },
    []
  );

  // chain event and rsvp creation
  const create_event_and_rsvp_chained = useRequestChain<
    (new_event: WriteEvent) => Promise<MappedApiResponse<"createEvent">>,
    (
      new_event: Event,
      rsvp: RSVPResponses
    ) => Promise<MappedApiResponse<"createEventRSVP">>,
    MappedApiResponse<"createEvent">
  >(
    create_event,
    create_rsvp,
    combine_event_and_rsvp,
    get_rsvp_params_from_event
  );

  const create_event_and_rsvp = useCallback(
    (new_event: WriteEvent, rsvp: RSVPResponses) => {
      // @ts-expect-error:
      return create_event_and_rsvp_chained([new_event], [undefined, rsvp]).then(
        res => {
          analytics.log_event(analytics.EVENTS.event_created);
          return res;
        }
      );
    },
    [create_event_request, create_event_chained]
  );

  const [submit_form, { pending, success, error }] = useRequest(
    create_event_and_rsvp
  );

  const on_finish = useCallback(
    (new_event: Event): void => {
      dismiss();
      onFinish({
        ...new_event,
        created_by: self || new_event.created_by,
      });
    },
    [self, onFinish, dismiss]
  );

  return (
    <DefaultModalContent title="Create an Event" closeButton={closeButton}>
      <EventForm
        initialData={initialData}
        photoFile={file}
        onChangeFile={on_change_file}
        onSubmit={submit_form}
        onFinish={on_finish}
        rsvp={RSVPResponses.GOING}
      >
        <div className="event-create__footer modal__footer">
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          <RequestButtonWithIcon
            className="filled gray"
            icon="add"
            side="left"
            type="submit"
            pending={pending}
            success={success}
            successText="Success!"
            progress={event_progress}
          >
            Create New Event
          </RequestButtonWithIcon>
        </div>
      </EventForm>
    </DefaultModalContent>
  );
};
