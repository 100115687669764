import * as hooks from "preact/hooks";
import { useDirtyForm, useObjectDiff, useStateObject } from ".";

// form data with explicit typing, and macros for onChange listeners and property updates
export const useForm = <Schema extends object>(
  initial?: Schema
): [
  Partial<Schema>,
  <T extends keyof Schema>(field: T, value: Schema[T]) => void,
  <T extends keyof Schema>(field: T) => (e: any) => void,
  hooks.StateUpdater<Partial<Schema>>
] => {
  const [formData, setFormData] = useStateObject(initial || {});
  const [setField, onChangeInput] = useFormUpdaters(setFormData);

  return [formData, setField, onChangeInput, setFormData];
};

export const useFormWithDiff = <Schema extends object>(
  initial?: Schema,
  prepare_for_diffing: (data: Schema) => Schema = d => d
): [
  Partial<Schema>,
  Partial<Schema> | undefined,
  (keyof Schema)[] | undefined,
  <T extends keyof Schema>(field: T, value: Schema[T]) => void,
  <T extends keyof Schema>(field: T) => (e: any) => void,
  hooks.StateUpdater<Partial<Schema>>
] => {
  const [formData, setFormData] = useStateObject(initial || {});
  const [updatedData, deletedKeys] = useObjectDiff<Partial<Schema>>(
    formData,
    prepare_for_diffing
  );
  const [setField, onChangeInput] = useFormUpdaters(setFormData);

  return [
    formData,
    updatedData,
    deletedKeys,
    setField,
    onChangeInput,
    setFormData,
  ];
};

export const useDirtyFormWithDiff = <Schema extends object>(
  initial: Schema | undefined,
  form_name: string,
  prepare_for_diffing: (data: Schema) => Schema = d => d,
  ignore_blanks_in_dirty_state?: boolean
): [
  Partial<Schema>,
  Partial<Schema> | undefined,
  (keyof Schema)[] | undefined,
  <T extends keyof Schema>(field: T, value: Schema[T]) => void,
  <T extends keyof Schema>(field: T) => (e: any) => void,
  hooks.StateUpdater<Partial<Schema>>,
  (data: Schema) => void
] => {
  const [formData, setFormData] = useStateObject(initial || {});
  const [updatedData, deletedKeys, resetDiffData] = useObjectDiff<
    Partial<Schema>
  >(formData, prepare_for_diffing);
  const [setField, onChangeInput] = useFormUpdaters(setFormData);

  const dirtyFormData = hooks.useMemo(
    () => ({ changed: updatedData, deleted: deletedKeys }),
    [updatedData, deletedKeys]
  );
  const clearFormDirtyState = useDirtyForm(
    dirtyFormData,
    form_name,
    ignore_blanks_in_dirty_state
  );

  const resetForm = hooks.useCallback(
    (data: Schema) => {
      setFormData(data);
      resetDiffData(data);
      clearFormDirtyState({ changed: undefined, deleted: undefined });
    },
    [setFormData, resetDiffData, clearFormDirtyState]
  );

  return [
    formData,
    updatedData,
    deletedKeys,
    setField,
    onChangeInput,
    setFormData,
    resetForm,
  ];
};

// returns a pair of updaters for easy use with native or custom input elements
export const useFormUpdaters = <
  Schema extends object,
  T extends keyof Schema = keyof Schema
>(
  setFormData: hooks.StateUpdater<Schema>
): [
  (field: T, value: Schema[T]) => void,
  (field: T, input_property?: string) => (e: any) => void
] => {
  // set a field using the field name and its raw value
  const setField = hooks.useCallback(
    (field: T, value: Schema[T]) => {
      // @ts-expect-error:
      setFormData({ [field]: value });
    },
    [setFormData]
  );

  // create an event listener using the field name
  const onChangeInput = hooks.useCallback(
    (field: T, input_property: string = "value") =>
      e => {
        // @ts-expect-error:
        setFormData({
          [field]:
            input_property in e.target
              ? e.target[input_property]
              : e.target.value,
        });
      },
    [setFormData]
  );

  return [setField, onChangeInput];
};
