import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const with_required = (required, title) =>
  `${required ? "* " : ""}${title}`;

/** wraps/adds a floating title to an input, textarea, or select element
 *  note: the wrapped element must have a `placeholder` set */
export const WithFloatingTitle: Preact.FunctionComponent<{
  className?: string;
  title: string;
  name?: string;
}> = ({ className, title, name, children }) => (
  <div className={`input__container${maybeClassName(className)}`}>
    {children}
    <label htmlFor={name} className="input__title">
      <span>{title}</span>
    </label>
  </div>
);
WithFloatingTitle.displayName = "WithFloatingTitle";

/** Inserts a floating title to an input, textarea, or select element
 *  NOTE: the sibling input must precede this component, must have a
 *  `placeholder` set, and the parent node must have `position: relative`
 */
export const FloatingTitle: Preact.FunctionComponent<{
  name?: string;
}> = ({ name, children }) => (
  <label htmlFor={name} className="input__title">
    <span>{children}</span>
  </label>
);
FloatingTitle.displayName = "FloatingTitle";
