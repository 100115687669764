import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const ErrorMessage: Preact.FunctionComponent<
  Preact.JSX.HTMLAttributes<HTMLDivElement>
> = ({ children, className, ...props }) => (
  <div className={`error-message${maybeClassName(className)}`} {...props}>
    {children}
  </div>
);
