import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { DocBase, map_record_to_json_resource, TYPES } from "@thrive-web/core";
import {
  ApiMethodCaller,
  ApiMethodParameters,
  MappedApiResponse,
  Post,
} from "@thrive-web/ui-api";
import {
  useApiMethod,
  useRequestChain,
  useStateIfMounted,
  useUntrackedRequestWithMedia,
} from "@thrive-web/ui-hooks";
import {
  PostCreateBlocksResponse,
  PostForm,
  PostFormPage,
  useGroupUpdatesAllowed,
  useTouchpointAndExpenseCreate,
} from "@thrive-web/ui-components";

export type PostEditProps = {
  target?: Post | null;
  onFinish: (new_post: Post) => void;
  initialPage?: PostFormPage;
};

export const PostEdit: Preact.FunctionComponent<
  ModalBodyProps & PostEditProps
> = ({ target, onFinish, initialPage, ...props }) => {
  const initialPhoto = useMemo<FileUploadData | undefined>(
    () =>
      target?.photo_url
        ? { data: target.photo_url, name: "", mime: "" }
        : undefined,
    []
  );
  const initialExpensePhoto = useMemo<FileUploadData | undefined>(
    () =>
      // @ts-expect-error:
      target?.has_expense?.receipt_photo_url
        ? // @ts-expect-error:
          { data: target.has_expense.receipt_photo_url, name: "", mime: "" }
        : undefined,
    []
  );
  const [file, onChangeFile] = useStateIfMounted<FileUploadData | undefined>(
    initialPhoto
  );
  const [expenseFile, onChangeExpenseFile] = useStateIfMounted<
    FileUploadData | undefined
  >(initialExpensePhoto);

  const allow_group_updates = useGroupUpdatesAllowed(target?.posted_to);

  // POST type/Expense
  const createExpenseRecordRequest = useApiMethod("createExpense");
  // create Expense, then upload receipt image
  const [createExpenseRequest, expense_pending, expense_progress] =
    useUntrackedRequestWithMedia(
      "Expense",
      "receipt_photo",
      createExpenseRecordRequest,
      expenseFile,
      false,
      true
    );

  const createTouchpointAndExpense =
    useTouchpointAndExpenseCreate(createExpenseRequest);

  // PATCH type/Post
  const updatePostRecordRequest = useApiMethod("updatePost");
  const [updatePostRequest, post_pending, post_progress] =
    useUntrackedRequestWithMedia(
      "Post",
      "photo",
      updatePostRecordRequest,
      file,
      false,
      true
    );

  // combine result of post update with created touchpoint/expense
  const combine_post_and_blocks = useCallback(
    (
      blocks: PostCreateBlocksResponse,
      post: MappedApiResponse<"createPost">
    ): DocBase & { data: Post } => {
      return {
        ...post,
        data: {
          ...post.data,
          ...blocks,
        },
      };
    },
    []
  );

  // get ids from created touchpoint/expense for post create request
  const get_post_params_from_blocks = useCallback(
    (
      blocks: PostCreateBlocksResponse,
      params: ApiMethodParameters<"updatePost">
    ) => {
      const data = params[1];
      if (!data?.body?.data?.relationships) {
        data!.body.data.relationships = {};
      }
      data!.body.data.relationships.has_touchpoint = blocks.has_touchpoint
        ? {
            data: { id: blocks.has_touchpoint.id },
          }
        : undefined;
      data!.body.data.relationships.has_expense = blocks.has_expense
        ? {
            data: { id: blocks.has_expense.id },
          }
        : undefined;

      params[1] = data;
      return params;
    },
    []
  );

  // chain touchpoint/expense creation with post creation
  const updatePostChained = useRequestChain<
    typeof createTouchpointAndExpense,
    ApiMethodCaller<"updatePost">,
    DocBase & { data: Post }
  >(
    createTouchpointAndExpense,
    updatePostRequest,
    combine_post_and_blocks,
    get_post_params_from_blocks
  );

  const updatePost = useCallback(
    (new_post: Post) => {
      if (!target) {
        return Promise.reject({
          code: "post/no-edit-target",
          message: "Missing target for edit post request.",
        });
      }

      const { id, type, ...data } = map_record_to_json_resource(
        new_post,
        TYPES.Post
      );
      // remove fields that can't be edited
      // @ts-expect-error:
      data.attributes?.photo_url && delete data.attributes.photo_url;
      // @ts-expect-error:
      data.attributes?.created_at && delete data.attributes.created_at;
      data.relationships?.posted_to && delete data.relationships.posted_to;
      data.relationships?.has_comment && delete data.relationships.has_comment;
      data.relationships?.created_by && delete data.relationships.created_by;
      data.relationships?.has_reaction &&
        delete data.relationships.has_reaction;

      const params_2: ApiMethodParameters<"updatePost"> = [
        target.id,
        {
          body: { data },
          query: {
            include: [
              "has_touchpoint",
              "has_reaction",
              "has_expense",
              "mood",
              "photo",
              "event.Event:cover_image",
              "created_by.User:profile_picture",
              "has_tagged_user.User:profile_picture",
              "has_comment.Comment:created_by.User:profile_picture",
            ],
          },
        },
      ];

      if (
        !allow_group_updates ||
        (!new_post.has_expense && !new_post.has_touchpoint)
      ) {
        return updatePostRequest(...params_2).then(res => {
          analytics.log_event(
            analytics.EVENTS.edit_post_saved,
            undefined,
            undefined,
            true
          );
          return res;
        });
      }

      return updatePostChained([new_post], params_2).then(res => {
        analytics.log_event(
          analytics.EVENTS.edit_post_saved,
          undefined,
          undefined,
          true
        );
        return res;
      });
    },
    [updatePostRequest, updatePostChained, allow_group_updates]
  );

  if (!target) {
    return null;
  }

  return (
    <PostForm
      initialPage={initialPage}
      initialData={target}
      submitRequest={updatePost}
      onFinish={onFinish}
      defaultScope={target.posted_to}
      photoFile={file}
      onChangeFile={onChangeFile}
      mediaProgress={
        post_pending
          ? post_progress
          : expense_pending
          ? expense_progress
          : undefined
      }
      expensePhotoFile={expenseFile}
      onChangeExpenseFile={onChangeExpenseFile}
      {...props}
    />
  );
};
