import * as Preact from "preact";
import {
  DefaultErrorView,
  DefaultPendingView,
} from "@thrive-web/ui-components";
import { DEFAULT_UI_ERROR } from "@thrive-web/ui-constants";
import { make_displayable_error } from "@thrive-web/ui-common";
import { useCallback, useLayoutEffect } from "preact/hooks";
import {
  ApiMethod,
  ApiMethodParameters,
  MappedApiResponse,
} from "@thrive-web/ui-api";
import { useApiFetch } from "@thrive-web/ui-hooks";
import { RequestStatus } from "@thrive-web/ui-model";

/** renders the status/result of a promise */
export class AsyncRender<T, P extends object = any> extends Preact.Component<
  {
    getPromise: () => Promise<T>;
    ErrorView?: Preact.ComponentType<{ error: DisplayableError }>;
    PendingView?: Preact.ComponentType;
    children: (
      result: T,
      pending?: boolean,
      passthrough?: P
    ) => Preact.VNode | null;
    // scroll to the page target after finishing the request
    scrollOnLoad?: boolean;
    // continue displaying the current view while the request is being resent
    keepViewOnUpdate?: boolean;
    initialValue?: T;
    // resend when the identity of getPromise changes
    resendOnFuncChange?: boolean;
    // props to pass through to the children
    passthroughProps?: P;
  },
  { pending: boolean; success?: boolean; result?: T; error?: DisplayableError }
> {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      result: props.initialValue,
    };
  }
  mounted?: boolean;

  static defaultProps = {
    PendingView: DefaultPendingView,
    ErrorView: DefaultErrorView,
    scrollOnLoad: true,
    resendOnFuncChange: true,
  };

  setStateIfMounted = (state: any, ...args: any[]) => {
    if (this.mounted) {
      this.setState(state, ...args);
    }
  };

  // send the request on component mount
  componentDidMount() {
    this.mounted = true;
    this.tryRequest();
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  componentDidUpdate(previousProps, prevState) {
    if (
      prevState.pending &&
      !this.state.pending &&
      this.props.scrollOnLoad &&
      window.location.hash
    ) {
      // scroll to the url target when the content finishes loading
      setTimeout(() => {
        const node = document.getElementById(window.location.hash.slice(1));
        if (node) {
          node.scrollIntoView();
          node.classList.add("target");
        }
      }, 25);
    }
    if (
      previousProps.getPromise !== this.props.getPromise &&
      this.props.resendOnFuncChange
    ) {
      // if the request function changes, reset and resend the request
      this.setStateIfMounted(
        {
          pending: true,
          success: false,
          error: this.props.keepViewOnUpdate ? this.state.error : undefined,
          result: this.props.keepViewOnUpdate ? this.state.result : undefined,
        },
        this.tryRequest
      );
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setError(error);
    errorInfo &&
      console.error(`Additional info on error in AsyncRender:`, errorInfo);
  }

  tryRequest = () => {
    const { getPromise } = this.props;
    try {
      // send the request
      getPromise()
        .then(result => {
          // if the request function has changed and it's going to be automatically
          // resent, do nothing
          if (
            this.props.getPromise !== getPromise &&
            this.props.resendOnFuncChange
          ) {
            return;
          }
          // otherwise store the result
          this.setStateIfMounted({
            pending: false,
            success: true,
            result,
          });
        })
        .catch(error => {
          if (
            this.props.getPromise !== getPromise &&
            this.props.resendOnFuncChange
          ) {
            return;
          }
          this.setError(error, `Promise rejected in AsyncRender component:`);
        });
    } catch (error) {
      this.setError(error);
    }
  };

  setError = (
    error = DEFAULT_UI_ERROR,
    log = "Error occurred in AsyncRender component"
  ) => {
    console.error(log, error);
    this.setStateIfMounted({
      pending: false,
      success: false,
      error: make_displayable_error(error),
    });
  };

  render() {
    const {
      children,
      ErrorView = DefaultErrorView,
      PendingView = DefaultPendingView,
      keepViewOnUpdate,
      passthroughProps,
    } = this.props;
    const { pending, success, result, error } = this.state;
    try {
      // display the error view when the request resulted in an error AND
      // there is no result OR we don't want to keep the existing view while updating
      return !pending && !success && error && (!result || !keepViewOnUpdate) ? (
        <ErrorView error={error || DEFAULT_UI_ERROR} />
      ) : // show the pending view when there is no result OR
      // the request is pending AND we don't want to keep the existing view while updating
      !result || (!keepViewOnUpdate && pending) ? (
        <PendingView />
      ) : // show the result view if the request was successful OR
      // there's a result AND we want to keep the view while updating
      success || (keepViewOnUpdate && result) ? (
        children(result, pending, passthroughProps)
      ) : (
        <ErrorView error={error || DEFAULT_UI_ERROR} />
      );
    } catch (otherError) {
      return <ErrorView error={otherError} />;
    }
  }
}

// shortcut hook for making a memoized render func and getPromise func
export const useAsyncRender = <
  M extends ApiMethod,
  Mapped extends boolean,
  T = MappedApiResponse<M>
>(
  render: (result: T) => Preact.VNode | null,
  renderInputs: any[],
  method: M,
  ...args: ApiMethodParameters<M>
): [
  (result: T) => Preact.VNode | null,
  () => Promise<MappedApiResponse<M>>
] => {
  const renderFunc = useCallback(render, renderInputs);
  const makeRequest = useApiFetch<M>(method, ...args);
  return [renderFunc, makeRequest];
};

/** hook substitute for AsyncRender class; useful for when the result/status is needed
 * outside of the result render view
 * */
export const useAsyncRenderResult = <T extends any, P extends object = any>(
  render: (
    result: T,
    pending?: boolean,
    passthruProps?: P
  ) => Preact.VNode | null,
  renderInputs: any[],
  status: RequestStatus,
  result?: T,
  keepViewOnUpdate?: boolean,
  ErrorView = DefaultErrorView,
  PendingView = DefaultPendingView,
  allowEmptyResult: boolean = false,
  passthruProps?: P
): Preact.VNode | null => {
  const Component = useCallback(
    ({ res, pending, passthroughProps }) =>
      render(res, pending, passthroughProps),
    [...renderInputs]
  );
  useLayoutEffect(() => {
    if (status.error) {
      console.error(`Error in useAsyncRenderResult:`, status.error);
    }
  }, [status.error]);

  // Show the pending view if
  // the request is pending and we don't want to keep the existing view while updating OR
  // there is no result AND (empty results are not allowed OR the request hasn't been sent)
  // todo: make sure this didn't break anything
  if (
    (!keepViewOnUpdate && status.pending) ||
    (!result &&
      ((!status.pending && !status.success && !status.error) ||
        !allowEmptyResult))
  ) {
    return <PendingView />;
  }
  if (status.error) {
    return <ErrorView error={status.error} />;
  }

  return (
    <Component
      res={result}
      pending={status.pending}
      passthroughProps={passthruProps}
    />
  );
};
