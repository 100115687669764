import * as Preact from "preact";
import {
  StateUpdater,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { Mood } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  createModal,
  DefaultErrorView,
  DefaultModalContent,
  DefaultPendingView,
  EmptyList,
  ErrorMessage,
  Icon,
  OptionsList,
  Popover,
  RequestButton,
  SearchBar,
  TaxonomiesContextSpec,
  usePopoverTrigger,
  useTaxonomy,
} from "@thrive-web/ui-components";
import {
  useId,
  useRequest,
  useStateIfMounted,
  useStateRef,
  useTextFilter,
} from "@thrive-web/ui-hooks";
import { display_text, maybeClassName } from "@thrive-web/ui-utils";
import * as hooks from "preact/hooks";

const noop = () => {};

interface MoodSelectorProps {
  value?: Mood;
  onSelect: (mood?: Mood) => void;
  open: boolean;
  setOpen: StateUpdater<boolean>;
  moodSearch: string;
  setMoodSearch: StateUpdater<string>;
  moods: TaxonomiesContextSpec<"Mood">;
  allowRemove?: boolean;
  closeOnSelectItem?: boolean;
}

const useMoodSelector = (open: boolean, setOpen_: StateUpdater<boolean>) => {
  const moods = useTaxonomy("Mood");
  const [mood_search, set_mood_search] = useStateIfMounted("");

  // clear text input on open/close
  const setOpen = useCallback(
    new_open => {
      setOpen_(new_open);
      set_mood_search("");
    },
    [setOpen_, set_mood_search]
  );

  return [setOpen, mood_search, set_mood_search, moods] as const;
};

// mood list/selection component
export const MoodSelectorDropdown: Preact.FunctionComponent<
  MaybeClass & {
    id?: string;
    value?: Mood;
    onSelect: (mood?: Mood) => void;
    containerRef?: () => HTMLElement;
  }
> = ({ value, onSelect, containerRef, className, id: _id }) => {
  const id = useId(_id, "mood-selector");
  const [open, setOpen_, { className: triggerClassName, ...trigger_props }] =
    usePopoverTrigger(
      { hover: false, focus: false, click: true },
      `${id}-popover`
    );

  const [setOpen, mood_search, set_mood_search, moods] = useMoodSelector(
    open,
    setOpen_
  );

  return (
    <MoodSelectorDropdownOuter
      id={id}
      triggerProps={trigger_props}
      triggerClassName={triggerClassName}
      open={open}
      className={className}
    >
      <MoodSelector
        open={open}
        setOpen={setOpen}
        onSelect={onSelect}
        value={value}
        moodSearch={mood_search}
        setMoodSearch={set_mood_search}
        moods={moods}
        closeOnSelectItem={true}
      />
    </MoodSelectorDropdownOuter>
  );
};

// the trigger/popover component for the mood selector dropdown
export const MoodSelectorDropdownOuter: Preact.FunctionComponent<
  MaybeClass & {
    id?: string;
    triggerProps: any;
    triggerClassName?: string;
    open: boolean;
  }
> = ({ id, className, open, triggerProps, triggerClassName, children }) => {
  return (
    <Popover
      key={id}
      id={`${id}-popover`}
      className={`dropdown-menu mood-selector${maybeClassName(className)}`}
      triggerComponent={
        <ButtonWithIcon
          id={id}
          className="post__form__mood__button filled gray"
          {...triggerProps}
          icon="mood-share"
          side="left"
        >
          Share Your Mood
        </ButtonWithIcon>
      }
      show={open}
      hideNub={true}
    >
      {children}
    </Popover>
  );
};

type MoodSelectorModalProps = {
  id?: string;
  value?: Mood;
  onSelect: (mood?: Mood) => Promise<void>;
  open: boolean;
  setOpen: StateUpdater<boolean>;
};

export const MoodSelectorModalBody: Preact.FunctionComponent<
  ModalBodyProps & MoodSelectorModalProps
> = ({ id: _id, value, onSelect, open, setOpen, closeButton }) => {
  const [, mood_search, set_mood_search, moods] = useMoodSelector(
    open,
    setOpen
  );

  const [selected, set_selected, selected_ref] = useStateRef<Mood | undefined>(
    value
  );

  const onSubmitReq = useCallback(
    () => onSelect(selected_ref.current),
    [onSelect]
  );
  const [onSubmit, { pending, success, error }] = useRequest(onSubmitReq);

  return (
    <DefaultModalContent title="Update Mood" closeButton={closeButton}>
      <MoodSelector
        open={true}
        setOpen={noop}
        onSelect={set_selected}
        value={selected}
        moodSearch={mood_search}
        setMoodSearch={set_mood_search}
        moods={moods}
        allowRemove={!!value}
        closeOnSelectItem={false}
      />
      <div className="modal__footer">
        <div className="modal__footer__left">
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </div>
        <div className="modal__footer__right">
          <RequestButton
            disabled={!moods.value}
            onClick={moods.value ? onSubmit : undefined}
            pending={pending}
            success={success}
            successText="Success!"
            className="filled gray"
          >
            Update Mood
          </RequestButton>
        </div>
      </div>
    </DefaultModalContent>
  );
};

export const MoodSelectorModal: Preact.FunctionComponent<MoodSelectorModalProps> =
  ({ id: _id, open, setOpen, value, onSelect }) => {
    const id = useId(_id, "mood-selector-modal");
    const dismiss = hooks.useCallback(() => {
      setOpen(false);
    }, [setOpen]);

    const bodyProps = useMemo(
      () => ({
        value,
        onSelect,
        setOpen,
      }),
      [value, onSelect, setOpen]
    );

    return createModal(
      {
        id,
        className: "mood-selector__modal",
        innerClassName: "mood-selector",
        body: MoodSelectorModalBody,
        bodyProps,
        giveTabFocus: true,
        overrideScroll: true,
        showCloseButton: true,
      },
      open,
      dismiss,
      true
    );
  };

// display list of moods with search bar and selection callback
export const MoodSelector: Preact.FunctionComponent<MoodSelectorProps> = ({
  open,
  setOpen,
  value,
  onSelect,
  moodSearch,
  setMoodSearch,
  moods,
  allowRemove,
  closeOnSelectItem,
}) => {
  const input_ref = useRef<HTMLInputElement | null>(null);

  // when the selector is opened, focus the search input
  const onFocusTrapped = useCallback(e => {
    if (
      !input_ref.current ||
      !e.target ||
      !e.target.contains(input_ref.current)
    ) {
      return;
    }
    input_ref.current.focus();
  }, []);
  useLayoutEffect(() => {
    if (open) {
      window.addEventListener("focus-trapped", onFocusTrapped, { once: true });
    }
  }, [open]);

  const [inputProps_, setInputProps] = useStateIfMounted({});
  const inputProps = useMemo(
    () => ({ ...inputProps_, ref: input_ref }),
    [inputProps_, input_ref]
  );

  // filter on search string
  const test_mood = useCallback(
    (val: Mood, reg: RegExp) => reg.test(val.title || ""),
    []
  );
  const filtered_moods = useTextFilter(
    moodSearch,
    moods.value || [],
    test_mood
  );
  const options = useMemo<Mood[]>(
    () =>
      // if a mood is selected and removal is allowed, show the button to clear the value
      value?.id && allowRemove
        ? [{ title: "Remove Mood", symbol: "remove" } as Mood].concat(
            filtered_moods
          )
        : filtered_moods,
    [filtered_moods, value, allowRemove]
  );

  const RenderItem = useCallback(
    ({ item, onSelect, selectedValue }) => (
      <ButtonWithIcon
        className={`pill-checkbox button filled ${
          (selectedValue?.id || "") === item.id ? "success" : "gray"
        }`}
        for={`pill-checkbox-${item.id}`}
        icon={
          <span className="post__mood__emoji">
            {!item.id && item.symbol === "remove" ? (
              <Icon name="remove" />
            ) : (
              display_text(item.symbol)
            )}
          </span>
        }
        side="left"
        onClick={() => onSelect(item)}
        data-mood={item.title}
      >
        {item.title}
      </ButtonWithIcon>
    ),
    []
  );

  return (
    <div className="stack">
      <SearchBar
        placeholder="Find a mood..."
        onSubmit={setMoodSearch}
        inputProps={inputProps}
        autoSubmitDelay={250}
        value={moodSearch}
      />

      {moods.status.pending ? (
        <DefaultPendingView />
      ) : moods.status?.error ? (
        <DefaultErrorView error={moods.status.error} />
      ) : (
        <OptionsList
          value={value}
          options={options}
          RenderItem={RenderItem}
          open={open}
          setOpen={setOpen}
          onSelect={onSelect}
          setControlListeners={setInputProps}
          className="stack__scrolling-content"
          emptyLabel={
            <EmptyList>No matching results for "{moodSearch}".</EmptyList>
          }
          closeOnClickItem={closeOnSelectItem}
        />
      )}
    </div>
  );
};
