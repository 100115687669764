import * as Preact from "preact";
import { useState, useContext } from "preact/hooks";
import {
  ButtonWithIcon,
  Popup,
  PopupGenerator,
} from "@thrive-web/ui-components";
import { unrenderAfterDismissed, usePortal } from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { generate_id } from "@thrive-web/ui-common";

export interface ImageViewProps extends Omit<PopupProps, "popupType"> {
  open: boolean;
  dismiss: () => void;
  children: Preact.ComponentChildren;
}

/** an expanded full-screen-esque popup view of an image */
export const ImageView: Preact.FunctionComponent<ImageViewProps> = ({
  className,
  id,
  giveTabFocus,
  dismiss,
  children,
  ...props
}) => {
  return (
    <Popup
      id={id}
      popupType="image-view"
      className={className}
      giveTabFocus={giveTabFocus}
      dismiss={dismiss}
      {...props}
    >
      <div className="image-view__close">
        <ButtonWithIcon
          id={`${id}-close-button`}
          className="image-view__close__button"
          side="left"
          icon="remove"
          onClick={dismiss}
        />
      </div>
      <div className="image-view__content">{children}</div>
    </Popup>
  );
};

// custom hook to manage visibility and portal
export const createImageView: PopupGenerator<
  Omit<PopupProps, "popupType"> & ImageViewProps
> = (
  { id, ...props },
  open,
  dismiss,
  unrenderWhenDismissed = true,
  container_id = "popup-container"
) => {
  useContext(CONTEXTS.modal_container_ready);
  const shouldRender = unrenderAfterDismissed(
    open,
    unrenderWhenDismissed,
    MODAL_ANIMATION_DELAY
  );
  const showPopup = usePortal(container_id, shouldRender);
  const [popup_id] = useState(id || generate_id("image-view"));
  return !shouldRender
    ? null
    : showPopup(
        <ImageView {...props} open={open} dismiss={dismiss} id={popup_id} />
      );
};
