import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { analytics, make_displayable_error } from "@thrive-web/ui-common";
import { useApiMethod, useApiRequest, useAppUser } from "@thrive-web/ui-hooks";
import {
  ButtonWithIcon,
  RequestButton,
  Tooltip,
  UserInviteListItem,
  SelectUserModal,
  ModalHeader,
  ModalTitle,
  RemovableListItemComponentProps,
  RemovableListItem,
  new_connection_search_filter,
  PEOPLE_CONNECTIONS,
  PEOPLE_REQUESTS_INCOMING,
  PEOPLE_REQUESTS_OUTGOING,
} from "@thrive-web/ui-components";

type CreateReqUserListItemProps = {
  user: User;
  removeOnInvite?: (user: User) => void;
};

export const CreateRequestUserListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<CreateReqUserListItemProps>
> = ({ user, removeOnInvite }) => {
  const { dispatch } = useContext(PEOPLE_REQUESTS_OUTGOING);

  const req_params = useMemo(
    () => ({
      body: {
        data: {
          attributes: {},
          relationships: { recipient: { data: { id: user.id } } },
        },
      },
    }),
    [user?.id]
  );
  const [createRequest, { pending, success, error }] = useApiRequest(
    "createConnectionRequest",
    req_params
  );

  const sendRequest = useCallback(() => {
    createRequest().then(res => {
      analytics.log_event(analytics.EVENTS.connection_request_sent);
      const req: any = res.data;
      req.recipient = user;
      dispatch.add(req);
      removeOnInvite?.(user);
      return res;
    });
  }, [createRequest, dispatch]);

  return (
    <UserInviteListItem
      user={user}
      linkBox="all"
      button={
        <Tooltip
          text={`An error occurred while sending this invitation.\n${
            error ? make_displayable_error(error).message : ""
          }`}
          delay={1000}
          disabled={!error || pending}
          defaultDirection="left"
        >
          <RequestButton
            className={`filled${error && !pending ? " error" : ""} ${
              success ? "success static" : "gray"
            }`}
            pending={pending}
            success={success}
            onClick={pending || success ? undefined : sendRequest}
          >
            {error ? "Retry" : "Invite"}
          </RequestButton>
        </Tooltip>
      }
    />
  );
};

export const CreateRequestUserListItem: Preact.FunctionComponent<CreateReqUserListItemProps> =
  props => {
    const onRemoveItem = useCallback(() => {
      props.removeOnInvite?.(props.user);
      return Promise.resolve();
    }, [props.user, props.removeOnInvite]);

    return (
      <RemovableListItem<
        RemovableListItemComponentProps<CreateReqUserListItemProps>,
        User
      >
        Component={CreateRequestUserListItemBase}
        delay={1000}
        onRemoveItem={onRemoveItem}
        {...props}
      />
    );
  };

export const CreateRequestModalBody: Preact.FunctionComponent<ModalBodyProps> =
  ({ dismiss, closeButton }) => {
    const self = useAppUser();
    const sendSearchRequest = useApiMethod("getUsers");
    const connections = useContext(PEOPLE_CONNECTIONS).list || [];
    const incoming = useContext(PEOPLE_REQUESTS_INCOMING).list || [];
    const outgoing = useContext(PEOPLE_REQUESTS_OUTGOING).list || [];
    // create a dict for existing connections/requests
    const existing = useMemo(() => {
      const map: any = {};
      if (!self) {
        return map;
      }
      connections?.forEach(conn => {
        map[conn.other_user.id] = conn;
      });
      (incoming || []).concat(outgoing || []).forEach(conn => {
        const other_user =
          conn.recipient?.id === self.id ? conn.sender : conn.recipient;
        if (other_user) {
          map[other_user.id] = conn;
        }
      });
      return map;
    }, [incoming, outgoing, connections]);

    // user search
    const getUsers = useCallback(
      (search: string, offset: number, limit?: number) =>
        sendSearchRequest({
          query: {
            filter: new_connection_search_filter(self?.id, search),
            sort: [{ by: "full_name", dir: "asc" }],
            limit,
            offset,
            include: ["profile_picture"],
            include_count: true,
          },
        }),
      [sendSearchRequest]
    );

    const empty_label = useCallback(
      search => `No users found that match "${search}"`,
      []
    );

    const renderUser = useCallback(
      (user: User, selected, remove_on_select) =>
        !self || self.id == user.id ? null : (
          <CreateRequestUserListItem
            user={user}
            key={user.id}
            removeOnInvite={remove_on_select}
          />
        ),
      [existing, self]
    );

    return (
      <Preact.Fragment>
        <ModalHeader button={closeButton}>
          <ModalTitle>Add a Connection</ModalTitle>
        </ModalHeader>
        <SelectUserModal
          className="modal__body"
          getUsers={getUsers}
          emptyLabel={empty_label}
          renderUser={renderUser}
          removeOnSelect={true}
        />
        <div className="modal__footer invite-users-modal__footer">
          <ButtonWithIcon
            className="filled gray"
            icon="checked"
            side="right"
            type="submit"
            onClick={dismiss}
          >
            Finish
          </ButtonWithIcon>
        </div>
      </Preact.Fragment>
    );
  };
