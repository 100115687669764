import { Event, EventRSVP, User } from "@thrive-web/ui-api";
import { RSVPResponses } from "@thrive-web/ui-constants";
import { capitalize } from "@thrive-web/ui-common";

export const RSVP_ICON_MAP: { [K in RSVPResponses]: FontIconName } = {
  going: "checked",
  declined: "remove",
  maybe: "asterisk",
};

export const RSVP_OPTIONS: InputOptions<string> = Object.entries(
  RSVP_ICON_MAP
).map(([value, icon]) => ({
  value,
  label: capitalize(value),
  icon,
}));

export const useRSVPState = (
  user: User,
  event: Event,
  rsvp?: EventRSVP
): [string, FontIconName] => {
  const rec = rsvp; /*useMemo<EventRSVP | undefined>(
    () => rsvp || event.did_rsvp?.find(r => r.created_by?.id === user?.id),
    [user?.id, rsvp, event]
  );*/
  const response = rec?.response;
  if (!response) {
    return ["RSVP", "help-broken-circle"];
  }

  const label = capitalize(response);
  const icon = RSVP_ICON_MAP[response];
  return [label, icon];
};
