import { ApiMethodParameters, FilterSpec } from "@thrive-web/core";
import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { Group, Post, User, WritePost } from "@thrive-web/ui-api";
import {
  InteractionTypes,
  MODAL_ANIMATION_DELAY,
  THREAD_COMM_ID,
} from "@thrive-web/ui-constants";
import {
  EVENTS,
  InteractionModalProps,
  InteractionType,
} from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { is_id_obj } from "@thrive-web/ui-common";
import {
  FollowupModalBody,
  ReminderModalBody,
  GroupScoreModal,
} from "~/view/components";
import {
  POST_SCOPE_FORCE_OPTIONS,
  PostCreateModal,
  PostFormPage,
  WithPostScopeCtx,
  DefaultPendingView,
  DefaultErrorView,
  UserProvider,
  get_shared_connection_from_user,
} from "@thrive-web/ui-components";
import {
  useApiCall,
  useApiMethod,
  useAppUser,
  useModal,
  useStateIfMounted,
  useStateObject,
  useStateRef,
} from "@thrive-web/ui-hooks";

const ModalPending = () => (
  <div className="modal__body modal__pending">
    <DefaultPendingView />
  </div>
);

const ModalError = ({ error }) => (
  <div className="modal__body modal__error">
    <DefaultErrorView error={error} />
  </div>
);

export const InteractionModal = <T extends InteractionType = InteractionType>({
  type,
  interaction,
  onClose,
  success,
  onCreateInteraction,
  ...props
}: Preact.RenderableProps<InteractionModalProps<T>>) => {
  const dispatch = useContext(CONTEXTS.dispatch);
  const self = useAppUser();
  const ctx_user = useContext(UserProvider);
  const user = ctx_user || props.user;
  const connection = useMemo(() => {
    if (props.connection && !is_id_obj(props.connection)) {
      return props.connection;
    }
    if (!self) {
      return;
    }
    return get_shared_connection_from_user(user, self);
  }, [user, self, props.connection]);

  const [, set_cur_type, cur_type_ref] = useStateRef<
    InteractionType | undefined
  >(type);
  const open = useRef(!!type);
  const mount_score_modal = useRef(false);

  const [groups, get_groups] = useCommonThreadGroups(user, self);
  const [group, set_group, group_ref] = useStateRef<Group | undefined>(
    undefined
  );

  const new_score = useRef<number | undefined>(group?.score);

  const [interaction_data, set_interaction_data] =
    useStateIfMounted(interaction);
  const [get_interaction, status] = useApiCall("getInteraction");
  useEffect(() => {
    if (interaction && is_id_obj(interaction)) {
      get_interaction(interaction.id).then(res => {
        if (res?.data) {
          set_interaction_data(res.data);
          const int_type = res.data.interaction;
          set_cur_type(
            int_type === InteractionTypes.CALL_USER
              ? "phone"
              : int_type === InteractionTypes.TEXT_USER ||
                int_type === InteractionTypes.PRELIM_TEXT_USER
              ? "text-chat"
              : "email"
          );
        }
      });
    }
  }, []);

  const clear_interaction = useCallback(() => {
    setTimeout(() => {
      dispatch({
        type: EVENTS.SET_INTERACTION_DATA,
        payload: null,
      });
      set_cur_type(undefined);
      open.current = false;
      mount_score_modal.current = false;
      set_group(undefined);
      new_score.current = undefined;
    }, MODAL_ANIMATION_DELAY);
  }, [dispatch]);

  const [body_, int_type, is_followup] = useInteractionModalContent(
    cur_type_ref.current,
    !!interaction_data
  );
  const body = status.error
    ? ModalError
    : status.pending ||
      !user ||
      is_id_obj(user) ||
      !connection ||
      is_id_obj(connection)
    ? ModalPending
    : body_;

  const score_modal_props = useMemo(
    () => ({
      group,
      updateGroup: () =>
        !!group_ref.current &&
        set_group({
          ...group_ref.current,
          score:
            new_score.current == null
              ? group_ref.current.score
              : new_score.current,
        }),
    }),
    [set_group, group?.id, group?.score]
  );

  const [score_modal, set_score_modal_open] = useModal(
    {
      id: "group-score-modal",
      innerClassName: "card card-stack group-score__modal",
      body: GroupScoreModal,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      showCloseButton: true,
      bodyProps: score_modal_props,
    },
    clear_interaction
  );

  const on_create_post = useCallback(
    (new_post: Post) => {
      const { id } = new_post.posted_to!;
      const posted_to = groups?.find(g => g.id === id);
      // @ts-expect-error:
      if (posted_to?.score !== new_post.posted_to?.score) {
        // @ts-expect-error:
        new_score.current = new_post.posted_to?.score;
        set_group(posted_to);
        set_score_modal_open(true);
      } else {
        onClose?.();
      }
    },
    [set_group, groups, set_group]
  );

  const [post_modal, open_post_modal_] = usePostCreateModal(
    groups,
    user,
    on_create_post
  );

  const open_post_modal = useCallback(() => {
    mount_score_modal.current = true;
    open_post_modal_();
  }, [open_post_modal_]);

  const body_props = useMemo(
    () => ({
      user,
      connection,
      interaction_data,
      interactionType: int_type,
      success,
      onCreateInteraction,
      ...(is_followup
        ? {
            groups,
            type: cur_type_ref.current,
            openPostModal: open_post_modal,
          }
        : {}),
    }),
    [
      user,
      connection,
      groups,
      open_post_modal,
      cur_type_ref.current,
      interaction_data,
    ]
  );

  const on_close_modal = useCallback(() => {
    onClose?.();
    if (!mount_score_modal.current) {
      clear_interaction();
    }
  }, [onClose]);

  const [int_modal, set_int_modal_open] = useModal(
    {
      id: "userInteractionModal",
      className: "interaction-modal",
      // @ts-expect-error:
      body,
      bodyProps: body_props,
      dismissOnClickBackdrop: !cur_type_ref.current,
    },
    on_close_modal,
    true
  );

  useEffect(() => {
    if (type) {
      set_int_modal_open(true);
      open.current = true;
      set_cur_type(type);
      if (type === "phone" || type === "unknown" || interaction_data) {
        get_groups();
      }
    }
  }, [type]);

  return (
    <Preact.Fragment>
      {int_modal}
      {is_followup && post_modal}
      {is_followup && mount_score_modal.current && score_modal}
    </Preact.Fragment>
  );
};

const { Provider } = POST_SCOPE_FORCE_OPTIONS;
const noop = () => {};

const common_groups_filter = (uid1, uid2): FilterSpec => [
  ["=", ["this", "Group:in_community"], ["id", THREAD_COMM_ID]],
  ["=", ["this", "Group:has_member"], ["id", uid1]],
  ["=", ["this", "Group:has_member"], ["id", uid2]],
  ["=", ["this", "Group:has_goals"], true],
];
const useCommonThreadGroups = (user?: User | null, self?: User | null) => {
  const [groups, set_groups] = useStateIfMounted<Group[] | null>(null);
  const params = useMemo<ApiMethodParameters<"GET", "Group">>(
    () => ({
      query: {
        filter: common_groups_filter(user?.id, self?.id),
        sort: [{ by: "last_activity_at", dir: "desc" }],
        include: ["background_image"],
      },
    }),
    [user?.id, self?.id]
  );
  const get_groups_req = useApiMethod("getGroups");
  const get_groups = useCallback(() => {
    if (!user || !self) {
      return Promise.reject();
    }
    return get_groups_req(params).then(({ data }) => {
      set_groups(data);
      return data;
    });
  }, [get_groups_req, params, set_groups]);

  return [groups, get_groups] as const;
};

const usePostCreateModal = (
  groups: Group[] | null,
  user?: User | null,
  onFinish: (new_post: Post) => void = noop
) => {
  const [state, set_state] = useStateObject<{
    open: boolean;
    initial_page?: PostFormPage;
    initial_data?: WritePost;
  }>({
    open: false,
  });

  const dismiss = useCallback(() => {
    set_state({ open: false, initial_page: undefined });
  }, [set_state]);

  const open_post_modal = useCallback(() => {
    if (!user) {
      return;
    }
    set_state({
      open: true,
      initial_data: {
        has_tagged_user: [user],
      },
      initial_page: "touchpoint",
    });
  }, [set_state, user]);

  const modal = (
    <Provider value={groups}>
      <WithPostScopeCtx threadOnlyInitial={true}>
        <PostCreateModal
          onFinish={onFinish}
          dismiss={dismiss}
          open={state.open}
          initialPage={state.initial_page}
          initialData={state.initial_data}
          allowScopeChange={true}
        />
      </WithPostScopeCtx>
    </Provider>
  );

  return [modal, open_post_modal] as const;
};

const useInteractionModalContent = (
  type?: InteractionType | "loading",
  interaction?: boolean
) => {
  if (!type) {
    return [() => null, undefined, false] as const;
  }
  switch (type) {
    case "email":
      if (interaction) {
        return [FollowupModalBody, InteractionTypes.EMAIL_USER, true] as const;
      }
      return [
        ReminderModalBody,
        InteractionTypes.PRELIM_EMAIL_USER,
        false,
      ] as const;
    case "text-chat":
      if (interaction) {
        return [FollowupModalBody, InteractionTypes.TEXT_USER, true] as const;
      }
      return [
        ReminderModalBody,
        InteractionTypes.PRELIM_TEXT_USER,
        false,
      ] as const;
    case "phone":
      return [FollowupModalBody, InteractionTypes.CALL_USER, true] as const;
    case "loading":
      return [ModalPending, undefined, true] as const;
    case "unknown":
    default:
      return [FollowupModalBody, undefined, true] as const;
  }
};

/*

export class InteractionModal<
  T extends InteractionType
  > extends Preact.Component<
  InteractionModalProps<T>,
  { track_open: boolean; post_open: boolean }
  > {
  constructor(props) {
    super(props);
    this.state = {
      track_open: false,
      post_open: false,
    };

    this.body_props = {
      user: props.user,
      connection: props.connection,
      // @ts-expect-error:
      groups: nextProps.groups,
      // @ts-expect-error:
      openPostModal: nextProps.openPostModal,
    };
  }

  body_props: Omit<InteractionModalProps<T>, "type">;

  componentWillUpdate(nextProps: Readonly<InteractionModalProps<T>>) {
    if (
      ["user", "connection", "groups", "openPostModal"].some(
        k => nextProps[k] !== this.props[k]
      )
    ) {
      this.body_props = {
        user: nextProps.user,
        connection: nextProps.connection,
        // @ts-expect-error:
        groups: nextProps.groups,
        // @ts-expect-error:
        openPostModal: nextProps.openPostModal,
      };
    }
  }

  render() {
    const { type, onClose user, connection, groups, openPostModal } = this.props;

    const body = MODAL_BODY_TYPE_MAP[type];

    const [phone_modal, set_phone_modal_open] = useModal(
      {
        id: "userInteractionPhoneModal",
        className: "interaction-modal",
        body,
        bodyProps: this.body_props,
        dismissOnClickBackdrop: false,
      },
      onClose,
      true
    );
  }
}*/
