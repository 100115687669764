import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const Card: Preact.FunctionComponent<
  MaybeClass & {
    title?: string | Preact.VNode;
    actions?: Preact.VNode;
  }
> = ({ title, actions, className, children }) => (
  <div className={`card${maybeClassName(className)}`}>
    {title && <h4>{title}</h4>}
    {children}
    {actions && <div className="textarea-card__actions">{actions}</div>}
  </div>
);
