import * as Preact from "preact";
import { PureComponent } from "preact/compat";
import {
  chain_event_listeners,
  data_attrs,
  maybeClassName,
} from "@thrive-web/ui-utils";
import { Icon, Tooltip } from "@thrive-web/ui-components";
import { INPUT_DEBOUNCE_TIME } from "@thrive-web/ui-constants";
import { useCallback, useMemo, useRef } from "preact/hooks";

export interface ElementWithFormHelpersProps<
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  V extends string | number
> extends Omit<PropsOfElem<T>, "value" | "defaultValue" | "onChange" | "ref"> {
  validate?: (value?: V, input?: T | null) => boolean;
  onSubmitInput?: (any) => void;
  submitOnEnter?: boolean;
  value?: V;
  defaultValue?: V;
  onChangeDebounce?: boolean;
  debounceTime?: number;
  onChange: (e: EventFor<T>) => void;
  inputRef?: (elem: T | null) => void;
  controlled?: boolean;
}

/**
 * Adds data attributes (empty, dirty, valid, value) to an input
 * to assist with styling
 */
export abstract class ElementWithFormHelpers<
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  V extends string | number,
  P = {},
  S = {}
> extends PureComponent<ElementWithFormHelpersProps<T, V> & P, S> {
  constructor(props) {
    super(props);
    this.initialValue = props.value;
    this.value = props.value;
    if (props.value) {
      this.updateAttributes(props);
    }
    this.mounted = false;
    this.resetListenerSet = false;
    this.input = Preact.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateAttributes = this.updateAttributes.bind(this);
  }
  static defaultProps = {
    submitOnEnter: true,
    onChangeDebounce: true,
    debounceTime: INPUT_DEBOUNCE_TIME,
  };

  input: Preact.RefObject<T>;
  valid: boolean = true;
  dirty: boolean = false;
  empty: boolean = true;
  value?: V;
  initialValue?: V;
  validate?: (value?: V, input?: T | null) => boolean;
  mounted: boolean;
  debounce?: any;
  resetListenerSet?: boolean;

  componentDidMount() {
    this.mounted = true;
    this.props.inputRef &&
      this.input.current &&
      this.props.inputRef(this.input.current);

    if ((this.props.autoFocus || this.props.autofocus) && this.input.current) {
      this.input.current.focus();
    }
  }
  componentWillUnmount(): void {
    this.mounted = false;
    this.input.current?.form?.removeEventListener("reset", this.onReset);
  }

  updateAttributes(props): boolean | void {
    let update = false;
    const { value, validate = this.validate, required } = props;
    const invalid = validate
      ? !validate(value, this.input ? this.input.current : undefined)
      : false;
    const empty = value == null || value === "";
    const dirty = this.dirty || !empty;
    const valid = required ? !(dirty && empty) && !invalid : empty || !invalid;

    if (
      this.value !== value ||
      this.empty !== empty ||
      this.dirty !== dirty ||
      this.valid !== valid
    ) {
      update = true;
    }

    this.value = value;
    this.empty = empty;
    this.dirty = dirty;
    this.valid = valid;
    update && this.mounted && !this.props.controlled && this.forceUpdate();
    return update && this.mounted;
  }

  onChangeBound = e => {
    if (this.props.onChange) {
      return this.props.onChange.bind(this.input.current)(e);
    }
  };

  onFocusBound = e => {
    if (this.props.onFocus) {
      return this.props.onFocus.bind(this.input.current)(e);
    }
  };

  onBlurBound = e => {
    if (this.props.onBlur) {
      return this.props.onBlur.bind(this.input.current)(e);
    }
  };

  onKeyDownBound = e => {
    if (this.props.onKeyDown) {
      return this.props.onKeyDown.bind(this.input.current)(e);
    }
  };

  onResetBound = e => {
    if (this.props.onReset) {
      return this.props.onReset.bind(this.input.current)(e);
    }
  };

  handleChange(ev) {
    this.onChangeBound(ev);
    this.updateAttributes({
      value: ev.target.value,
      required: this.props.required,
    });
  }

  onChange(e: Event) {
    if (!this.props.onChangeDebounce || this.props.controlled) {
      this.handleChange(e);
      return;
    }
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = setTimeout(() => {
      this.handleChange(e);
      clearTimeout(this.debounce);
      this.debounce = null;
    }, this.props.debounceTime || INPUT_DEBOUNCE_TIME);
  }

  onReset = e => {
    this.value = undefined;
    this.empty = true;
    this.dirty = false;
    this.valid = true;
    this.onResetBound(e);
    this.mounted && !this.props.controlled && this.forceUpdate();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value, validate = this.validate } = this.props;
    const { value: pValue, validate: pValidate } = prevProps;
    if (
      pValue !== value ||
      pValue !== this.value ||
      (validate && validate(value, this.input.current)) !==
        (pValidate && pValidate(pValue, this.input.current))
    ) {
      this.updateAttributes(this.props);
    }
    this.props.inputRef &&
      this.input.current &&
      this.props.inputRef(this.input.current);

    if (!this.resetListenerSet) {
      this.withElement(elem => {
        if (this.input.current && this.input.current.form) {
          this.resetListenerSet = true;
          this.input.current.form.addEventListener("reset", this.onReset);
        }
      });
    }
  }

  withElement = (func: (elem: T) => void): void => {
    if (this.input && this.input.current) {
      func(this.input.current);
    }
  };
}

export const RadioButton: Preact.FunctionComponent<RadioButtonProps> = ({
  label,
  ...props
}) => {
  const _for =
    props.id || props.name ? props.id || `radio-${props.name}` : undefined;
  return (
    <label
      className={`radio-button__label`}
      data-disabled={props.disabled}
      htmlFor={_for}
    >
      <input id={_for} {...props} type="radio" />
      <span className="radio-button__button">
        <Icon name="checked" />
      </span>
      <span data-text={label} className="radio-button__text">
        {label}
      </span>
    </label>
  );
};

export const RadioButtons: Preact.FunctionComponent<
  RadioListProps<string | number>
> = ({ label, className, value, onSelectOption, options, ...props }) => (
  <div className={`radio-button__list${maybeClassName(className)}`}>
    {label && <div className="radio-button__list__label">{label}</div>}
    {options.map((opt, i) => (
      <RadioButton
        {...props}
        key={opt.value}
        name={props.name || props.id}
        id={`${props.id || props.name}-${i}`}
        value={opt.value}
        checked={value === opt.value}
        onChange={() => onSelectOption(opt)}
        label={opt.label}
      />
    ))}
  </div>
);

export const Checkbox: Preact.FunctionComponent<CheckboxProps> = ({
  label,
  children,
  tooltip,
  ...props
}) => {
  const _for = props.id || undefined;
  const listeners = useMemo(
    () =>
      chain_event_listeners(
        { onKeyDown: props.onKeyDown },
        {
          onKeyDown: e => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          },
        }
      ),
    [props.onKeyDown]
  );
  const source_ref = useRef<HTMLInputElement>();
  const get_source_ref = useCallback(() => source_ref.current, [source_ref]);
  const pop_props = useMemo(() => ({ getSourceRef: get_source_ref }), []);

  const content = (
    <label className="checkbox__label" htmlFor={_for}>
      <input ref={source_ref} {...props} {...listeners} type="checkbox" />
      <span data-text={label} className="checkbox__text">
        {label}
      </span>
      {children}
    </label>
  );

  return tooltip ? (
    <Tooltip popoverProps={pop_props} text={tooltip}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export const CheckList = <T extends string | number>({
  label,
  className,
  values,
  onSelectOption,
  options,
  ...props
}: Preact.RenderableProps<
  CheckListProps<T, InputOption<T>>
>): Preact.VNode<any> | null => {
  return (
    <div className={`checkbox__list${maybeClassName(className)}`}>
      {label && <div className="checkbox__list__label">{label}</div>}
      {options.map(opt => (
        <Checkbox
          {...props}
          key={opt.value}
          name={props.name || props.id}
          value={opt.value}
          checked={values.includes(opt.value)}
          // @ts-ignore
          onChange={e => onSelectOption(opt, e.target?.checked)}
          label={opt.label}
        />
      ))}
    </div>
  );
};

export const DollarInput: Preact.FunctionComponent<
  HTMLInputProps & {
    value?: number;
    defaultValue?: number;
    onChangeValue: (value?: number) => void;
  }
> = ({
  value,
  defaultValue,
  className,
  children,
  onBlur,
  onChange,
  onChangeValue,
  ...props
}) => {
  const onInputChange = useCallback(
    e => {
      if (e.target.value == null || e.target.value == "") {
        onChangeValue();
        return;
      }
      const text = e.target.value
        .replace(",", ".")
        .replace("$", "")
        .replace(/[^0-9.]/g, "")
        .replace(/^(\d*\.\d*)\..*/, "$1");
      e.target.value = text;
      const val = parseFloat(parseFloat(text).toFixed(2));
      if (!isNaN(val)) {
        onChangeValue(val);
      }
    },
    [onChangeValue]
  );

  const onInputBlur = useMemo(() => {
    const listener = {
      onBlur: e => {
        if (
          e.target.value == null ||
          e.target.value == "" ||
          isNaN(parseFloat(e.target.value))
        ) {
          onChangeValue();
          return;
        }
        const halves: string[] = e.target.value.split(".");
        if (halves.length < 1 || halves.length > 2) {
          return onChangeValue();
        }
        for (let i = 0; i < halves.length; i++) {
          if (!/^\d*$/.test(halves[i])) {
            return onChangeValue();
          }
        }
        const num = parseFloat(halves.join("."));
        onChangeValue(num);
        e.target.value = num.toFixed(2);
      },
    };
    return onBlur ? chain_event_listeners(listener, { onBlur }) : listener;
  }, [onBlur, onChangeValue]);

  const validate = useCallback(
    (val, input?) => {
      val = parseFloat(val);
      if (typeof props.min === "number" && val < props.min) {
        input?.setCustomValidity(
          `Please enter a value greater than or equal to $${props.min.toFixed(
            2
          )}.`
        );
        return false;
      }
      if (typeof props.max === "number" && val > props.max) {
        input?.setCustomValidity(
          `Please enter a value less than or equal to $${props.max.toFixed(2)}.`
        );
        return false;
      }
      input?.setCustomValidity("");
      return true;
    },
    [props.min, props.max]
  );

  return (
    <div className="dollar-input input__container">
      <InputWithFormHelpers
        {...props}
        {...onInputBlur}
        onChange={onInputChange}
        onChangeDebounce={false}
        defaultValue={(defaultValue || value)?.toFixed(2)}
        value={value?.toFixed(2)}
        placeholder="00.00"
        controlled={false}
        pattern="\d*\.?\d?\d?"
        validate={validate}
      />
      {children}
      <div className="dollar-input__prefix">$</div>
    </div>
  );
};

export class InputWithFormHelpers<
  V extends string | number,
  P = {},
  S = never
> extends ElementWithFormHelpers<
  HTMLInputElement,
  V,
  P,
  S & { cur_value?: V }
> {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e: EventFor<HTMLInputElement>) {
    if (!e.target) {
      return;
    }
    const { type, step } = this.props;
    if (
      (type === "number" && typeof step === "number") ||
      typeof step === "string"
    ) {
      const factor =
        1 /
        (typeof step === "number"
          ? (step as number)
          : parseFloat(step as string));
      e.target.valueAsNumber =
        Math.round(e.target.valueAsNumber * factor) / factor;
      e.target.value = e.target.valueAsNumber.toString();
    }
    this.onChangeBound(e);
    this.updateAttributes({
      value: e.target.value,
      required: this.props.required,
    });
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key && e.key === "Enter") {
      if (this.props.submitOnEnter) {
        this.props.onSubmitInput && this.props.onSubmitInput(this.props.value);
      } else {
        e.stopPropagation();
        e.preventDefault();
      }
    }
    this.onKeyDownBound(e);
    return this.props.submitOnEnter;
  };

  render() {
    const {
      value,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      debounceTime,
      controlled,
      ...props
    } = this.props;
    return (
      <input
        {...data_attrs({
          value: this.value,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        defaultValue={this.initialValue}
        {...(controlled ? { value } : {})}
        {...props}
        ref={this.input}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onReset={this.onReset}
      />
    );
  }
}

export class TextAreaWithFormHelpers<
  P extends object = {},
  C extends boolean = false
> extends ElementWithFormHelpers<
  HTMLTextAreaElement,
  string,
  { charLimit?: number; showCharLimit?: boolean } & P
> {
  static defaultProps = {
    submitOnEnter: false,
    onChangeDebounce: true,
    showCharLimit: false,
    debounceTime: INPUT_DEBOUNCE_TIME,
  };

  handleChange(ev) {
    if (this.props.charLimit && ev.target.value.length > this.props.charLimit) {
      return;
    }
    super.handleChange(ev);
  }

  render() {
    const {
      value,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      debounceTime,
      showCharLimit,
      charLimit,
      controlled,
      children,
      ...props
    } = this.props;
    const content = (
      <textarea
        maxLength={charLimit}
        {...data_attrs({
          value: value,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        defaultValue={this.initialValue as string}
        {...(controlled ? { value } : {})}
        {...props}
        ref={this.input}
        onChange={this.onChange}
        onReset={this.onReset}
      />
    );
    if (!showCharLimit && !charLimit && !children) {
      return content;
    }
    const chars_left = (charLimit || Infinity) - (value?.length || 0);
    return (
      <div className="textarea__container">
        {content}
        {charLimit &&
          !showCharLimit &&
          // if !showCharLimit, show char limit only when we're close to the limit
          chars_left <= Math.max(50, Math.min(0.1 * charLimit, 200)) && (
            <span>{chars_left} Characters Remaining</span>
          )}
        {children}
      </div>
    );
  }
}
