export const LOGIN_LOCATION = "/login";

export const DEFAULT_APP_LOCATION = "/";

export const PUBLIC_PAGES = ["/privacy-policy", "/terms-of-service"];

export const REGISTER_PAGES = [
  "/register",
  "/register/get-started",
  "/register/failed",
];

export const SETUP_PAGES = [
  ...REGISTER_PAGES,
  "/forgot-password",
  "/pending-email-verification",
  "/profile-builder",
];

export const ANON_ONLY_PAGES = [
  ...REGISTER_PAGES,
  "/login",
  "/forgot-password",
];

export const NO_AUTH_PAGES = [LOGIN_LOCATION, ...PUBLIC_PAGES, ...SETUP_PAGES];

export const CREATE_RECORD_PATH = "create";
export const EDIT_RECORD_PATH = "edit";
