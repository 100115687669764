import { useAppUser } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";

export const Welcome: Preact.FunctionComponent<RoutePageProps> = () => {
  const user = useAppUser();
  if (!user) {
    return null;
  }
  return (
    <div className="login-page welcome-page">
      <div className="login-page__logo">
        <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
      </div>
      <div className="login-page__form login-page__welcome card">
        <h1>Welcome to Thread</h1>
        <p>
          We believe everyone is coached and everyone is a coach. To start off,
          someone from our team will reach out to help you set up your own
          personal coaching group.
        </p>
        <p>
          While you’re waiting, take this opportunity to learn more about
          Thread.
        </p>
        <div>
          <a
            className="button filled full-width"
            href={`https://www.tfaforms.com/4688067?tfa_3218=App&first=${user.first_name}&last=${user.last_name}&email=${user.email}`}
          >
            Learn more about Thread...
          </a>
        </div>
      </div>
    </div>
  );
};
