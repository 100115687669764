import { DocBase } from "@thrive-web/core";
import { AsyncRenderPaged, ULWithClickDetect } from "@thrive-web/ui-components";
import * as Preact from "preact";
import { handle_after } from "@thrive-web/ui-common";
import { maybeClassName } from "@thrive-web/ui-utils";

const BLUR_FOCUS_DELAY = 50;

interface OptionsListState {
  active_index: number;
}

/** A list of items with keyboard navigation and selection */
export class OptionsList<
  T extends any,
  E extends HTMLElement = HTMLElement,
  P extends object = {},
  S extends object = any
> extends Preact.Component<P & OptionsListProps<E, T>, OptionsListState & S> {
  constructor(props) {
    super(props);
    // @ts-expect-error:
    this.state = {
      active_index: -1,
    };
    this.list_ref = Preact.createRef();
    this.active_ref = Preact.createRef();
    this.ignore_mouse_hover = false;
    this.ignore_blur = false;
  }
  mounted: boolean;

  setStateIfMounted = (new_state, callback?: () => void) => {
    if (this.mounted) {
      this.setState(new_state, callback);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // if the index changed, check that the active item is scrolled into view
    if (this.state.active_index !== prevState.active_index) {
      this.scroll_menu();
    }
    const { open, customOption, hideWhenEmpty } = this.props;
    const options = this.get_options();
    // if opening or closing, reset the active index, or if no options
    if (
      (this.state.active_index > -1 &&
        (!this.props.open || (options.length === 0 && !customOption))) ||
      open !== prevProps.open
    ) {
      this.setStateIfMounted({ active_index: -1 });
      // if the list of options changed, reset the active index to 0
    } else if (
      open &&
      this.opts_did_change(prevProps, prevState) &&
      !this.props.noScrollOnListChange
    ) {
      this.setStateIfMounted({ active_index: 0 });
    }

    if (this.opts_did_change(prevProps, prevState) && hideWhenEmpty) {
      if (options.length === 0 && open) {
        this.close_menu();
      } else if (options.length > 0 && !open) {
        this.open_menu();
      }
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.props.setControlListeners({
      onKeyDown: this.on_key_down,
      onFocus: this.on_control_focus,
      onBlur: this.on_control_blur,
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  list_ref: Preact.RefObject<HTMLUListElement>;
  active_ref: Preact.RefObject<HTMLLIElement>;
  ignore_blur: boolean;
  ignore_mouse_hover: boolean;
  timer: any;
  use_timer = (callback: () => void, delay: number = 0) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      callback();
      this.timer = null;
    }, delay);
  };

  get_options = () => this.props.options;
  opts_did_change = (prevProps, prevState) =>
    JSON.stringify(this.get_options()) !== JSON.stringify(prevProps.options);

  max_index = () =>
    this.get_options().length - (this.props.customOption ? 0 : 1);

  // if the active item is not fully in view, scroll so that it is at the edge of the view
  scroll_menu = () => {
    if (
      !this.list_ref.current ||
      !this.active_ref.current ||
      this.state.active_index === -1
    ) {
      return;
    }
    this.ignore_mouse_hover = true;
    const { scrollTop, clientHeight } = this.list_ref.current;
    const { offsetTop, clientHeight: itemHeight } = this.active_ref.current;
    // if the new active item is outside the current scroll window
    if (scrollTop < offsetTop + itemHeight - clientHeight) {
      this.list_ref.current.scrollTop = offsetTop + itemHeight - clientHeight;
    } else if (scrollTop > offsetTop) {
      this.list_ref.current.scrollTop = offsetTop;
    }
    setTimeout(() => {
      this.ignore_mouse_hover = false;
    }, 25);
  };

  highlight_item = (dir: "next" | "prev") => {
    const max = this.max_index();
    const { active_index } = this.state;
    const new_active_index =
      dir === "next"
        ? !open || active_index === max
          ? -1
          : active_index + 1
        : !open || active_index === -1
        ? max
        : active_index - 1;
    this.setStateIfMounted(
      {
        active_index: new_active_index,
      },
      () => {
        !this.props.open && this.props.setOpen(true);
      }
    );
  };

  open_menu = () => {
    if (!this.props.open) {
      this.props.setOpen(true);
      this.setStateIfMounted({ active_index: -1 });
    }
  };

  close_menu = () => {
    this.props.open && this.props.setOpen(false);
    this.setStateIfMounted({ active_index: -1 });
    this.props.onClose && this.props.onClose();
  };

  select_value = e => {
    const { onSelect, closeOnClickItem = true, customOption } = this.props;
    const options = this.get_options();
    const { active_index } = this.state;
    if (active_index === options.length && customOption) {
      e.alreadySubmitted = true;
      onSelect(customOption);
    } else if (active_index !== -1) {
      e.alreadySubmitted = true;
      onSelect(options[active_index]);
    }
    this.ignore_blur = false;
    if (closeOnClickItem) {
      this.close_menu();
    } else {
      if (e && e.type !== "keydown") {
        this.use_timer(this.open_menu, 4 * BLUR_FOCUS_DELAY);
      } else {
        this.open_menu();
      }
    }
  };

  on_key_down = e => {
    switch (e.key) {
      // move down the list (or to the top if at the end)
      case "ArrowDown":
        e.preventDefault();
        this.highlight_item("next");
        return;

      // move up the list (or to the bottom if at the top)
      case "ArrowUp":
        e.preventDefault();
        this.highlight_item("prev");
        return;

      // close the menu
      case "Escape":
        e.preventDefault();
        e.stopPropagation();
        this.close_menu();
        return;

      // select the highlighted option
      case "Enter":
      case " ":
        if (e.key === " " && !this.props.selectOnSpacebar) {
          return;
        }
        if (e.alreadySubmitted) {
          if (this.props.closeOnClickItem) {
            this.close_menu();
          }
        } else {
          e.preventDefault();
          this.select_value(e);
        }
    }
  };

  on_mouse_up = e => {
    this.ignore_blur = false;
    if (!e.alreadySubmitted && e.button === 0) {
      e.preventDefault();
      this.select_value(e);
    }
  };

  on_mouse_down = e => {
    if (this.props.open && !this.ignore_mouse_hover && e.button === 0) {
      this.ignore_blur = true;
    }
  };

  on_cancel_click = () => {
    this.ignore_blur = false;
    this.props.setOpen(false);
  };

  on_mouse_enter = (index: number) => () => {
    if (
      this.props.open &&
      !this.ignore_mouse_hover &&
      index !== this.state.active_index
    ) {
      this.setStateIfMounted({ active_index: index });
    }
  };

  on_control_blur = handle_after(this, "onBlur", e => {
    if (!this.ignore_blur) {
      this.close_menu();
    } else {
      e.ignoreBlur = true;
    }
  });
  on_control_focus = handle_after(this, "onFocus", () =>
    this.use_timer(
      this.props.hideWhenEmpty
        ? () => {
            if (this.get_options().length > 0) {
              this.open_menu();
            }
          }
        : this.open_menu,
      BLUR_FOCUS_DELAY
    )
  );

  render() {
    const {
      RenderItem,
      className,
      open,
      value,
      emptyLabel,
      loadMoreElem,
      customOption,
      hideEmptyLabelWithCustom,
    } = this.props;
    const { active_index } = this.state;
    const options = this.get_options();

    return (
      <ULWithClickDetect
        onStartClick={this.on_mouse_down}
        onEndClick={this.on_mouse_up}
        onCancelClick={this.on_cancel_click}
        className={`options-list${maybeClassName(className)}`}
        ref={this.list_ref}
      >
        {options.length === 0 &&
          (!hideEmptyLabelWithCustom || !customOption) && (
            <li className="options-list__empty">{emptyLabel}</li>
          )}
        {options.map((opt, i) => (
          <li
            key={i}
            ref={open && active_index === i ? this.active_ref : undefined}
            data-active={i === active_index}
            onMouseMove={
              i === active_index ? undefined : this.on_mouse_enter(i)
            }
          >
            <RenderItem
              item={opt}
              onSelect={this.select_value}
              selectedValue={value}
            />
          </li>
        ))}
        {customOption && (
          <li
            key="create-option"
            ref={
              open && active_index === options.length
                ? this.active_ref
                : undefined
            }
            data-active={options.length === active_index}
            onMouseMove={
              options.length === active_index
                ? undefined
                : this.on_mouse_enter(options.length)
            }
          >
            <RenderItem
              item={customOption}
              onSelect={this.select_value}
              isCustom={true}
            />
          </li>
        )}
        {loadMoreElem && (
          <li className="options-list__load-more">{loadMoreElem}</li>
        )}
      </ULWithClickDetect>
    );
  }
}
OptionsList.displayName = "OptionsList";

export class OptionsListAsync<
  T extends any,
  E extends HTMLElement = HTMLElement
> extends OptionsList<
  T,
  E,
  {
    getOptions: (
      offset: number,
      limit?: number
    ) => Promise<DocBase & { data: T[] }>;
    filterOptions: (opt: T) => boolean;
    optionsAreEqual: (opt1: T, opt2: T) => boolean;
  },
  {
    options: readonly T[];
    filteredOptions: readonly T[];
  }
> {
  options: T[];
  options_prev: T[];
  options_unfiltered: T[];

  get_options = () => this.state.filteredOptions;
  opts_did_change = (prevProps, prevState) =>
    JSON.stringify(this.get_options()) !==
    JSON.stringify(prevState.filteredOptions);

  set_options = (opts: T[]) => {
    if (opts.length === 0 && this.options_unfiltered?.length === 0) {
      return;
    }
    const filtered = this.props.filterOptions
      ? opts.filter(this.props.filterOptions)
      : opts;
    this.setStateIfMounted(
      {
        options: opts,
        filteredOptions: filtered,
      },
      () => {
        this.options = filtered;
      }
    );
  };

  render_list = (options, loadMoreElem, pending, passthru) => {
    const {
      RenderItem,
      className,
      open,
      value,
      emptyLabel,
      customOption,
      hideEmptyLabelWithCustom,
      filterOptions,
      optionsAreEqual,
    } = passthru;
    const { active_index, filteredOptions } = this.state;
    this.options_prev = this.options_unfiltered;

    if (options !== this.options_unfiltered) {
      this.set_options(options);
    }

    this.options_unfiltered = options;

    return (
      <ULWithClickDetect
        onStartClick={this.on_mouse_down}
        onEndClick={this.on_mouse_up}
        onCancelClick={this.on_cancel_click}
        className={`options-list${maybeClassName(className)}`}
        ref={this.list_ref}
      >
        {filteredOptions.length === 0 &&
          (!hideEmptyLabelWithCustom || !customOption) && (
            <li className="options-list__empty">{emptyLabel}</li>
          )}
        {filteredOptions.map((opt, i) => (
          <li
            key={i}
            ref={open && active_index === i ? this.active_ref : undefined}
            data-active={i === active_index}
            onMouseMove={
              i === active_index ? undefined : this.on_mouse_enter(i)
            }
          >
            <RenderItem
              item={opt}
              onSelect={this.select_value}
              selectedValue={value}
            />
          </li>
        ))}
        {customOption &&
          (!filterOptions || !!filterOptions(customOption)) &&
          !filteredOptions.find(o => optionsAreEqual(o, customOption)) && (
            <li
              key="create-option"
              ref={
                open && active_index === filteredOptions.length
                  ? this.active_ref
                  : undefined
              }
              data-active={filteredOptions.length === active_index}
              onMouseMove={
                filteredOptions.length === active_index
                  ? undefined
                  : this.on_mouse_enter(filteredOptions.length)
              }
            >
              <RenderItem
                item={customOption}
                onSelect={this.select_value}
                isCustom={true}
              />
            </li>
          )}
        {loadMoreElem && (
          <li className="options-list__load-more">{loadMoreElem}</li>
        )}
      </ULWithClickDetect>
    );
  };

  render() {
    const { getOptions, ...props } = this.props;
    return (
      <AsyncRenderPaged
        getPromise={getOptions}
        keepViewOnUpdate={false}
        ignoreResendError={true}
        resendOnFuncChange={true}
        passthroughProps={props}
      >
        {this.render_list}
      </AsyncRenderPaged>
    );
  }
}
