import * as Preact from "preact";
import { CONTEXTS, Provider, SiteComponentProps } from "@thrive-web/ui-model";
import authWrapper from "~/auth/auth";
import { RegisterForm } from "~/view/components";
import { SiteModal } from "@thrive-web/ui-components";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";

export const RegisterSite: Preact.FunctionComponent<
  SiteComponentProps & { token?: string }
> = ({ token, model, keys }) => {
  const url = window.location.pathname;
  return (
    <Provider
      model={model}
      keys={keys}
      authWrapper={authWrapper}
      contexts={CONTEXTS}
    >
      <div className="site">
        <fieldset id="site-main">
          <div className="site__scroll-override-container">
            <div className="row site-body">
              <div className="login-page register">
                <div className="login-page__logo">
                  <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
                </div>
                <div className="login-page__form login-page__signup card">
                  <RegisterForm
                    token={token}
                    splash={url === "/register/get-started"}
                    retry={url === "/register/failed"}
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <SiteModal />
      </div>
    </Provider>
  );
};
