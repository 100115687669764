import { Types, VOCAB, ENTITIES } from "@thrive-web/core";
import { RecordType } from "@thrive-web/ui-api";

let _next_id = 0;

export const type_iri = (name: keyof Types) => `${VOCAB}${name}`;
export const ensure_id_is_iri = (id: string, type: keyof Types) =>
  id.startsWith(`${ENTITIES}${type}`) ? id : `${ENTITIES}${type}/${id}`;

// check if type property iri matches the given type
export const entity_has_type = <K extends keyof Types>(
  entity: any,
  type: K
): entity is RecordType<K> => entity && entity.type === type_iri(type);

export const is_id_obj = (resource: RecordType): resource is { id: string } =>
  JSON.stringify(resource) ===
  JSON.stringify({
    id: resource.id,
    ...(resource.type ? { type: resource.type } : {}),
  });

export const next_id = () => _next_id++;
export const generate_id = (prefix?: string) =>
  `${prefix ? `${prefix}-` : ""}${next_id()}`;

/** True if argument is a non-empty array; typechecks the result accordingly. */
export const has_items = <T>(
  x?: readonly T[]
): x is { shift(): T; pop(): T } & T[] => Array.isArray(x) && x.length > 0;

export const clamp = (min: number, max: number, n: number) =>
  Math.min(Math.max(n, min), max);

export const capitalize = (str: string | undefined) =>
  str != null
    ? str.length > 1
      ? `${str[0].toUpperCase()}${str.slice(1, str.length)}`
      : str.toUpperCase()
    : "";

export const pluralize = (str: string | undefined) =>
  str != null
    ? str.length > 1
      ? str.slice(-1) === "y"
        ? `${str.slice(0, -1)}ies`
        : `${str}s`
      : ""
    : "";

// truncate paragraph with ellipsis after specified length
export const truncate_paragraph = (value: string, length: number): string => {
  if (!value) {
    return "";
  }
  if (value.length <= length) {
    return value;
  }
  const short = value.slice(0, length);
  if (/\W/.test(value[length])) {
    return `${short}...`;
  }
  return short.replace(/\W+\w*$/i, "...");
};

// make a text label singular or plural based on count
export const count_label = (
  value: number,
  label: string,
  label_plural?: string
): string => {
  if (!label) {
    return `${value}`;
  }
  if (value === 0 || value > 1) {
    return `${value} ${label_plural || pluralize(label)}`;
  }
  return `${value} ${label}`;
};

// resolve promise after timeout finishes
export const awaitTimeout = (
  callback: (resolve, reject) => Promise<any>,
  timeout: number
) =>
  new Promise((resolve, reject) => {
    setTimeout(() => callback(resolve, reject), timeout);
  });

export type ArrayInsertPosSpecifier<T> =
  | "start"
  | "end"
  | ((a: T, b: T) => -1 | 0 | 1);

// helper functions to manipulate arrays without mutating the original copy
export const add_item_to = <T>(
  list: readonly T[],
  item: T,
  pos: ArrayInsertPosSpecifier<T> = "end"
): T[] => {
  if (!item) {
    return list ? list.slice() : [];
  }
  if (!list) {
    list = [];
  }
  if (list.length === 0) {
    return [item];
  }
  if (typeof pos === "function") {
    let index = -1;
    let i = 0;
    while (i < list.length && index < 0) {
      const comp = pos(item, list[i]);
      if (comp === 1) {
        i++;
      } else {
        index = i;
      }
    }
    if (index !== 0 && index !== list.length - 1) {
      return [...list.slice(0, index), item, ...list.slice(index)];
    }

    pos = index === 0 ? "start" : "end";
  }
  return pos === "start" ? [item].concat(list) : list.concat([item]);
};

export const remove_item_from = <T>(
  list: readonly T[],
  match: (item: T, index?: number) => boolean
): T[] => {
  const index = list.findIndex(match);
  if (index === -1) return list.slice();
  return list.slice(0, index).concat(list.slice(index + 1));
};

export const replace_item_in = <T>(
  list: readonly T[],
  match: (item: T, index?: number) => boolean,
  new_item: T,
  append_if_missing?: ArrayInsertPosSpecifier<T>
): T[] => {
  const index = list.findIndex(match);
  if (index === -1) {
    return append_if_missing
      ? add_item_to(list, new_item, append_if_missing)
      : list.slice();
  }
  return list
    .slice(0, index)
    .concat([new_item])
    .concat(list.slice(index + 1));
};

export const project_from_list = <T extends object, C extends keyof T>(
  list: T[],
  query: Partial<T>,
  project?: C | C[]
): T[C] | Partial<T> | null => {
  // find the item in the list that matches the query
  const index = list.findIndex(
    item =>
      Object.entries(query).filter(([key, value]) => item[key] !== value)
        .length === 0
  );
  if (index === -1) return null;

  // if a projection is specified, pick those props, otherwise return the whole object
  return (
    !project
      ? list[index]
      : Array.isArray(project)
      ? pick(project as string[], list[index])
      : list[index][project]
  ) as any;
};

export const get_label_from_value = <T extends string | number>(
  options: InputOptions<T>,
  value: T
): string | null =>
  project_from_list(options, { value }, "label") as string | null;

export const get_value_from_label = <T extends string | number>(
  options: InputOptions<T>,
  label: string
): T | null => project_from_list(options, { label }, "value") as T | null;

export const pick = <T extends object>(keys: string[], obj: T): object => {
  let ret: any = {};
  keys.forEach(k => {
    // @ts-ignore
    if (Object.keys(obj).includes(k)) {
      ret[k] = obj[k];
    }
  });
  return ret;
};

export const deep_equals = (
  a: any,
  b: any,
  max_depth: number = 10,
  assume_different_after_max_depth: boolean = false,
  depth: number = 0
) => {
  if (depth > max_depth) {
    return assume_different_after_max_depth;
  }
  if (a === b) {
    return true;
  }
  const ta = typeof a;
  const tb = typeof b;
  if (tb !== ta) {
    return false;
  }
  if (a === null || b === null) {
    return a === null && b === null;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return (
      a.length === b.length &&
      a.every((v, i) =>
        deep_equals(
          v,
          b[i],
          max_depth,
          assume_different_after_max_depth,
          depth + 1
        )
      )
    );
  }
  if (ta === "object") {
    const ea = Object.entries(a);
    const eb = Object.entries(b);
    return (
      ea.length === eb.length &&
      ea.every(
        ([k, v]) =>
          k in b &&
          deep_equals(
            v,
            b[k],
            max_depth,
            assume_different_after_max_depth,
            depth + 1
          )
      )
    );
  }
  return a === b;
};

// returns a tuple with:
// 1. the properties of B that are missing/different from A
// 2. an array of keys of A that are not present in B
export const get_object_diff = <Schema extends object>(
  a: Partial<Schema>, // original data
  b: Partial<Schema>, // updated data
  max_depth: number = 10,
  assume_different_after_max_depth: boolean = false,
  depth: number = 0
): [Partial<Schema> | undefined, (keyof Schema)[] | undefined] => {
  type T = keyof Schema;
  const deleted: T[] = [];
  const no_diff: [undefined, undefined] = [undefined, undefined];
  if (depth > max_depth) {
    return assume_different_after_max_depth ? [b, undefined] : no_diff;
  }
  if (a === b) {
    return no_diff;
  }
  const eb = Object.entries(b);
  const diffs: any[] = [];
  eb.forEach(([k, v]) => {
    if (!(k in a) && v !== undefined) {
      diffs.push([k, v]);
      return;
    }
    if (v === undefined && a[k] !== undefined) {
      deleted.push(k as T);
      return;
    }
    const prop_diff = deep_equals(
      v,
      a[k],
      max_depth,
      assume_different_after_max_depth,
      depth + 1
    )
      ? undefined
      : v;
    if (prop_diff !== undefined) {
      diffs.push([k, prop_diff]);
    }
  });
  if (diffs.length > 0) {
    const output: Partial<Schema> = {};
    diffs.forEach(([k, v]) => {
      output[k] = v;
    });
    return [output, deleted.length > 0 ? deleted : undefined];
  }
  if (deleted.length > 0) {
    return [undefined, deleted];
  }
  return no_diff;
};

export const get_object_from_entries = <
  T extends object = any,
  K extends keyof T = keyof T
>(
  entries: [K, T[K]][]
) => {
  const obj: any = {};
  entries.forEach(([k, v]) => {
    obj[k] = v;
  });
  return obj as T;
};

// https://stackoverflow.com/a/16245768
export const get_image_blob_url = (
  data: string,
  content_type: string,
  slice_size: number = 512
) => {
  const byte_data = atob(data.replace(/^.*?,/i, ""));
  const bytes: Uint8Array[] = [];

  for (let offset = 0; offset < byte_data.length; offset += slice_size) {
    const slice = byte_data.slice(offset, offset + slice_size);

    const byte_nums = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byte_nums[i] = slice.charCodeAt(i);
    }

    const byte_arr = new Uint8Array(byte_nums);
    bytes.push(byte_arr);
  }

  return window.URL.createObjectURL(new Blob(bytes, { type: content_type }));
};

export const comparator =
  <T extends object>(
    extract: PropsOfType<T, string | number> | ((item: T) => number),
    direction: "asc" | "desc" = "asc",
    fallback?: (a: T, b: T) => number
  ) =>
  (a: T, b: T): number => {
    const v_a = typeof extract === "function" ? extract(a) : a[extract];
    const v_b = typeof extract === "function" ? extract(b) : b[extract];
    return (
      (direction === "asc" ? 1 : -1) *
      (v_a < v_b ? -1 : v_a > v_b ? 1 : fallback ? fallback(a, b) : 0)
    );
  };

export const sort_by_id = <T extends { id: number }>(list: T[]) =>
  // @ts-expect-error: dunno why this doesn't work
  list.sort(comparator<T>("id"));

/** Wrap a handler so that it first run any existing handler for the specified
 * prop.  This is useful when creating drop-in replacements for standard
 * components (i.e. ones where certain props have defined semantics). */
export const handle_after = (target, name, handler) => event => {
  if (typeof target.props[name] === "function") target.props[name](event);
  handler.call(target, event);
};

/** copied from mindgrub library */
export const path_or = (def, path: Array<string | number>, obj: object) => {
  let ret = obj;
  for (let key of path) {
    if (ret == null) return def;
    ret = ret[key];
  }
  return ret == null ? def : ret;
};

export const noop = () => {};

export const get_file_name = (url: string): string => {
  const [, name] = url.match(/.+\/(.*\.([a-z]+))(\?.*)?$/i) || ["", ""];
  return name || "<unknown filename>";
};

export const is_valid_phone_number = (value: string): boolean =>
  /^([0-9]{3}-?){2}[0-9]{4}$/.test(value.replace(/[^0-9-]/g, ""));

export const is_valid_email = (value: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

// https://stackoverflow.com/a/19696443
export const URL_REGEX =
  /(^|\b)((?:(http|https|Http|Https):\/\/(?:(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,64}(?::(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,25})?@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?::\d{1,5})?)(\/(?:(?:[a-zA-Z0-9;\/?:@&=#~\-.+!*'(),_])|(?:%[a-fA-F0-9]{2}))*)?(?:\b|$)/i;
export const URL_REGEX_G =
  /(^|\b)((?:(http|https|Http|Https):\/\/(?:(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,64}(?::(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,25})?@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?::\d{1,5})?)(\/(?:(?:[a-zA-Z0-9;\/?:@&=#~\-.+!*'(),_])|(?:%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
