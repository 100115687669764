import * as Preact from "preact";
import { useCallback, useContext, useEffect } from "preact/hooks";
// import { LoginModalConnected } from "./LoginModal";
import { EVENTS, CONTEXTS } from "@thrive-web/ui-model";
import { Modal } from "@thrive-web/ui-components";

// renders the containers for modals, popups, and popovers
export const SiteModal: Preact.FunctionComponent = ({ children }) => {
  const dispatch = useContext(CONTEXTS.dispatch);
  // this component needs to finish mounting before any modals can be opened
  // (e.g. if a modal tries to open on page load)
  useContext(CONTEXTS.modal_container_ready);
  const { open, modal } = useContext(CONTEXTS.modal);
  // const login_modal = useContext(CONTEXTS.login_modal);
  useEffect(() => {
    dispatch({ type: "SET_MODAL_CONTAINER_READY" });
  }, []);
  const dismiss = useCallback(() => {
    dispatch({ type: EVENTS.CLOSE_APP_MODAL });
  }, [dispatch]);

  // subtract the site header from the usable area for popovers
  const header = document.getElementById("site-header");
  const header_height = header
    ? Math.max(header.offsetHeight - window.scrollY, 0)
    : 0;

  return (
    <Preact.Fragment>
      <div id="modal-container" /*data-login-open={`${login_modal.open}`}*/>
        {modal && (
          <Modal
            {...modal}
            open={open}
            /*
            giveTabFocus={true} //!login_modal.open}
            dismissOnClickBackdrop={true}*/
            dismiss={dismiss}
          />
        )}
        {children}
        {/*{login_modal.modal && (
        <LoginModalConnected
          id="login"
          {...login_modal.modal}
          closed={!login_modal.open}
          giveTabFocus={true}
          dismissOnClickBackdrop={false}
        />
      )}*/}
      </div>
      <div id="popup-container" />
      <div
        id="popover-container"
        style={{
          height: `calc(100% - ${header_height}px)`,
          top: `${header_height}px`,
        }}
      />
      <div id="clipboard-container">
        <textarea
          className="clipboard-input"
          id="clipboard-input"
          tabIndex={-1}
        />
      </div>
    </Preact.Fragment>
  );
};
