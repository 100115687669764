import * as Preact from "preact";
import { map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import {
  Goal,
  Touchpoint,
  TouchpointAccomplishment,
  TouchpointAction,
  User,
  WriteGoal,
  WriteTouchpoint,
} from "@thrive-web/ui-api";
import {
  useApiMethod,
  useDirtyForm,
  useDynamicListVariable,
  usePreviousValue,
  useStateIfMounted,
  useStateRef,
  useStateWithPrevValue,
} from "@thrive-web/ui-hooks";
import {
  Carousel,
  DefaultModalContent,
  GroupTouchpointAccomplishments,
  GroupTouchpointGoals,
  GroupTouchpointBarriers,
  GroupTouchpointActions,
  GroupTouchpointUsers,
  GroupGoalForm,
  ScopeItem,
  useGroupMemberSearch,
  UserSearchFn,
  useGroupGoalsList,
} from "@thrive-web/ui-components";
import { generateTouchpointText } from "@thrive-web/ui-utils";

export const GroupTouchpointForm: Preact.FunctionComponent<{
  touchpoint?: Touchpoint;
  group?: ScopeItem;
  warnOnChangeGroup?: boolean;
  allowScopeChange?: boolean;
  onFinish: (data?: WriteTouchpoint, scope?: ScopeItem) => void;
  taggedUsers: readonly User[];
  setTaggedUsers: (users: readonly User[]) => void;
  getUsers: UserSearchFn;
}> = ({
  touchpoint,
  group: initial_group,
  warnOnChangeGroup,
  onFinish,
  getUsers,
  taggedUsers = [],
  setTaggedUsers,
  allowScopeChange,
}) => {
  const [page, setPage, page_ref] = useStateRef(1);
  const prev_page = usePreviousValue(page);
  // initial/minimum page count is 2
  const page_count = useRef(2);

  const [group, setGroup, prev_group] = useStateWithPrevValue(initial_group);

  const searchGroupMembers = useGroupMemberSearch(group, setGroup);

  const [actions, setActions] = useDynamicListVariable<TouchpointAction>(
    ((touchpoint && touchpoint.has_action) as TouchpointAction[]) || []
  );

  const onChangeActions = useCallback(
    (act: TouchpointAction, checked: boolean) => {
      // if "attempted to reach out" is selected, no other option can be selected
      if (/attempt/i.test(act.id) && checked) {
        setActions.reset([act]);
      } else if (checked) {
        setActions.add(act);
        setActions.remove(a => /attempt/i.test(a.id));
      } else {
        setActions.remove(a => a.id === act.id);
      }
    },
    [setActions]
  );

  // if the only action selected is "attempted to reach out", the only other
  // data to be added to the tp is tagged users
  const attempt_only = useMemo(
    () => !!actions?.some(a => /attempt/i.test(a.id)),
    [actions]
  );

  const [tagged_users, set_tagged_users] =
    useDynamicListVariable<User>(taggedUsers);
  // if taggedUsers changes externally, update it here
  useEffect(() => {
    set_tagged_users.reset(taggedUsers);
  }, [taggedUsers]);
  // clear tagged users when group is changed
  useEffect(() => {
    if (group?.id !== prev_group.current?.id) {
      set_tagged_users.reset([]);
    }
  }, [group?.id]);
  const toggle_tagged_user = useCallback(
    (u: User, checked: boolean) => {
      checked
        ? set_tagged_users.remove(us => us.id === u.id)
        : set_tagged_users.add(u);
    },
    [set_tagged_users]
  );

  const [accomplishments, setAccomplishments] =
    useDynamicListVariable<TouchpointAccomplishment>(
      ((touchpoint &&
        touchpoint.has_accomplishment) as TouchpointAccomplishment[]) || []
    );
  const onChangeAccomplishments = useCallback(
    (acc: TouchpointAccomplishment, checked: boolean) =>
      checked
        ? setAccomplishments.add(acc)
        : setAccomplishments.remove(acco => acco.id === acc.id),
    [setAccomplishments]
  );
  // accomplishments that involve barriers
  const acc_barriers = useMemo(
    () => accomplishments?.filter(acc => /barrier/i.test(acc.id)) || [],
    [accomplishments]
  );
  // accomplishments that involve goals
  const acc_goals = useMemo(
    () => accomplishments?.filter(acc => /goal/i.test(acc.id)) || [],
    [accomplishments]
  );

  const goToCreateGoal = useCallback(() => {
    // @ts-expect-error:
    setPage("goal");
  }, [setPage]);
  const [
    goal_options,
    set_goal_options,
    get_goal_options,
    goal_options_status,
    // @ts-expect-error:
  ] = useGroupGoalsList(group);
  const [goals, setGoals] = useDynamicListVariable<Goal>(
    ((touchpoint && touchpoint.has_goal) as Goal[]) || []
  );
  const onChangeGoals = useCallback(
    (goal: Goal, checked: boolean) =>
      checked ? setGoals.add(goal) : setGoals.remove(g => g.id === goal.id),
    [setGoals]
  );
  const createGoal = useApiMethod("createGoal");
  const submitCreateGoal = useCallback(
    (new_goal: WriteGoal) =>
      group
        ? createGoal({
            body: {
              data: map_record_to_json_resource(
                { ...new_goal, group: { id: group.id } } as Goal,
                TYPES["Goal"]
              ),
            },
          }).then(result => {
            setGoals.add(result.data);
            set_goal_options.add(result.data, "start");
          })
        : Promise.resolve(),
    [createGoal, set_goal_options, setGoals, group]
  );

  const [barriers, setBarriers] = useStateIfMounted("");

  const clearForm = useCallback(() => {
    setActions.reset([]);
    setAccomplishments.reset([]);
    setGoals.reset([]);
    setBarriers("");
  }, [setActions, setAccomplishments, setGoals, setBarriers]);

  const allFormData = useMemo(
    () => ({
      group: group?.id,
      actions,
      accomplishments,
      goals,
      barriers,
    }),
    [group?.id, actions, accomplishments, goals, barriers]
  );
  const clearDirtyFormState = useDirtyForm(
    allFormData,
    "GroupTouchpointForm",
    true
  );

  // handle clicking the "back" form button
  const prevPage = useCallback(() => {
    // if on page one, clear form and exit
    if (page_ref.current === 1) {
      if (!touchpoint) {
        clearForm();
      }
      onFinish();
      clearDirtyFormState();
      return;
    }
    // handle goal separately because it's a string
    // @ts-expect-error:
    if (page_ref.current === "goal") {
      setPage(prev_page.current || 1);
      return;
    }
    setPage(page_ref.current - 1);
  }, [setPage, page_ref, touchpoint, clearForm]);

  // handle clicking the "next" form button
  const nextPage = useCallback(() => {
    // if on the last page, save and return to the post form
    if (page_ref.current === page_count.current) {
      const tp: WriteTouchpoint = {
        group,
        has_action: actions || undefined,
        has_accomplishment: accomplishments || undefined,
        has_goal: goals || undefined,
        barrier: barriers || undefined,
      };
      // @ts-expect-error:
      tp["description"] = generateTouchpointText({
        ...tp,
        posted_to: {
          // @ts-expect-error:
          has_tagged_user: tagged_users || undefined,
        },
      });
      setTaggedUsers(tagged_users || []);
      onFinish(tp, group);
      setPage(1);
      clearDirtyFormState();
      return;
    }
    setPage(page_ref.current + 1);
  }, [
    setPage,
    page_ref,
    group,
    actions,
    tagged_users,
    accomplishments,
    goals,
    barriers,
    onFinish,
    clearDirtyFormState,
  ]);

  // detect if touchpoint was deleted from main form page, and clear fields
  const prev_complete_touchpoint = usePreviousValue(touchpoint);
  useEffect(() => {
    if (prev_complete_touchpoint.current && !touchpoint) {
      clearForm();
    }
  }, [touchpoint, prev_complete_touchpoint, clearForm]);

  const pages_arr = [
    <GroupTouchpointActions
      group={group}
      setGroup={allowScopeChange !== undefined ? setGroup : undefined}
      warnOnChangeGroup={warnOnChangeGroup}
      allowScopeChange={allowScopeChange}
      actions={actions || []}
      onSelectAction={onChangeActions}
      nextStep={nextPage}
      prevStep={prevPage}
    />,
    <GroupTouchpointUsers
      isLastPage={attempt_only}
      getUsers={initial_group?.id === group?.id ? getUsers : searchGroupMembers}
      selected={tagged_users || []}
      onSelectUser={toggle_tagged_user}
      nextStep={nextPage}
      prevStep={prevPage}
    />,
  ]
    .concat(
      !attempt_only
        ? [
            <GroupTouchpointAccomplishments
              isLastPage={acc_goals?.length === 0 && acc_barriers.length === 0}
              accomplishments={accomplishments || []}
              onSelectAccomplishment={onChangeAccomplishments}
              nextStep={nextPage}
              prevStep={prevPage}
            />,
          ]
        : []
    )
    .concat(
      acc_goals.length > 0
        ? [
            <GroupTouchpointGoals
              isLastPage={acc_barriers.length === 0}
              accomplishments={acc_goals}
              goals={goals || []}
              options={goal_options}
              optionsStatus={goal_options_status}
              setOptions={set_goal_options}
              fetchOptions={get_goal_options}
              onSelectGoal={onChangeGoals}
              goToCreateGoal={goToCreateGoal}
              nextStep={nextPage}
              prevStep={prevPage}
            />,
          ]
        : []
    )
    .concat(
      acc_barriers.length > 0
        ? [
            <GroupTouchpointBarriers
              isLastPage={true}
              accomplishments={acc_barriers}
              barriers={barriers}
              setBarriers={setBarriers}
              nextStep={nextPage}
              prevStep={prevPage}
            />,
          ]
        : []
    );

  page_count.current = pages_arr.length;

  const pages: any = {
    goal: (
      <GroupGoalForm
        submitText="Create Goal"
        submitIcon="add"
        onSubmit={submitCreateGoal}
        dismiss={prevPage}
        clearOnSubmit={true}
      />
    ),
  };
  pages_arr.forEach((el, i) => {
    pages[`${i + 1}`] = el;
  });

  return (
    <div className="group-touchpoint__form">
      <DefaultModalContent title="Update Group">
        <Carousel page={`${page}`} items={pages} trackHeight={true} />
      </DefaultModalContent>
    </div>
  );
};
