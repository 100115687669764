import type { EntityFrom, MetaInterface } from "@swymbase/metatype-core";
import {
  DocWithData,
  ResourceIdObj,
  ResourceObj,
  map_json_resource_to_object,
  MetaTypes,
  IncludedProperty,
  get_record_type,
  get_record_metatype,
} from "@thrive-web/core";

export const map_json_doc_to_record = <T extends MetaInterface>(
  doc: DocWithData<T, false>,
  deep_includes: IncludedProperty<EntityFrom<T>>[] = [],
  // pre-mapped included record array
  included_records?: EntityFrom<any>[]
): EntityFrom<T> => {
  const { id, type, attributes, relationships } = doc.data;
  const record = {
    ...(id ? { id } : {}),
    ...(type ? { type } : {}),
    ...attributes,
  } as EntityFrom<T>;

  if (!relationships) {
    return record;
  }

  let included =
    included_records ??
    (doc.included ? map_included(doc.included, deep_includes) : undefined);

  const keys = Object.keys(relationships);
  keys.forEach(key => {
    if (!("data" in relationships[key])) {
      return;
    }

    const data = relationships[key]["data"];

    if (data === null) {
      // @ts-expect-error
      record[key] = undefined;
    } else if (Array.isArray(data)) {
      // @ts-expect-error
      record[key] = included
        ? data.map(item => included!.find(inc => inc.id === item.id) ?? item)
        : data;
    } else {
      // @ts-expect-error: type `object` is acceptable
      record[key] = included
        ? included!.find(inc => inc.id === data.id) || data
        : map_json_resource_to_object<T>(data);
    }
  });

  return record;
};

export const map_json_resource_to_record = <T extends MetaInterface>(
  resource: ResourceObj<T> | ResourceIdObj<T>,
  media?: EntityFrom<MetaTypes["Media"]>[]
): EntityFrom<T> => {
  if (!resource.type || !("attributes" in resource)) {
    // @ts-expect-error:
    return { id: resource.id };
  }
  const { id, type, attributes, relationships = {} } = resource;

  const relationship_props = {};
  Object.keys(relationships).forEach(key => {
    const media_rec = media?.find(m => m.id === relationships[key].data?.id);
    if (media_rec) {
      relationship_props[key] = map_json_resource_to_record(media_rec);
    } else {
      relationship_props[key] = relationships[key].data;
    }
  });

  return {
    ...(id ? { id } : {}),
    ...(type ? { type } : {}),
    ...attributes,
    ...relationship_props,
  } as EntityFrom<T>;
};

export const map_deep_include = <T extends EntityFrom<MetaInterface>>(
  records: T[],
  included_properties: IncludedProperty<T>[]
): T[] => {
  if (included_properties.length === 0) {
    return records;
  }
  let records_ = records;
  // go all the way down the inclusion "path", then start mapping the included
  // records and work backwards
  if (included_properties.length > 1) {
    records_ = map_deep_include(records, included_properties.slice(1));
  }

  const [type, prop] = (included_properties[0] as string).split(":");
  return records_.map(r => {
    if (
      // ignore media records and media props
      r.id.startsWith("arn") ||
      get_record_metatype(r)?.properties[prop]?.media ||
      // ignore if the record type doesn't match the type specified in the include "path"
      get_record_type(r as EntityFrom<MetaInterface>) !== type ||
      // or if the prop is empty
      r[prop] == null
    ) {
      return r;
    }
    const value: any = r[prop];
    // if multivalue, map included records for each value
    if (Array.isArray(r[prop])) {
      return {
        ...r,
        [prop]: value.map(i => {
          const match = records_.find(m => m.id === i.id);
          if (match) {
            return { ...match };
          }
          return i;
        }),
      };
    }
    if (typeof value === "object") {
      return {
        ...r,
        [prop]: records_.find(m => m.id === value.id) ?? value,
      };
    }
    return r;
  });
};

export const map_included = <T extends object>(
  included: ResourceObj[],
  deep_includes: IncludedProperty<T>[]
): EntityFrom<MetaInterface>[] => {
  const media = included?.filter(item => item.id.startsWith("arn"));
  // map from json api to record format, and map all included media objects
  let mapped = included.map(r => map_json_resource_to_record(r, media));

  if (deep_includes?.length === 0) {
    return mapped;
  }

  // apply deep include for each include "path"
  deep_includes.forEach(path => {
    const [, ...props] = (path as string).split(".");
    mapped = map_deep_include(mapped as EntityFrom<any>[], props);
  });

  return mapped;
};

export const map_json_list_doc_to_record_list = <T extends MetaInterface>(
  doc: DocWithData<T, true>,
  deep_includes: IncludedProperty<EntityFrom<T>>[] = []
) => {
  // map included records first so we only have to do it once
  const included = doc.included
    ? map_included(doc.included, deep_includes)
    : undefined;
  return doc.data.map(data =>
    map_json_doc_to_record(
      {
        data,
        included: doc.included,
      },
      deep_includes,
      included
    )
  );
};
