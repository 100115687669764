import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import {
  ApiMethodParameters,
  AssessmentFeedbackInvitation,
  MappedApiResponse,
} from "@thrive-web/ui-api";
import { RequestStatus } from "@thrive-web/ui-model";
import {
  UserProfilePrivacyIndicator,
  UserStrengthsContent,
  UserStrengthsLoading,
  ProfileBuilderFeedbackForm,
} from "~/view/components";
import {
  AsyncRender,
  DefaultModalContent,
  EmptyList,
  ErrorMessage,
  Icon,
  RequestButtonWithIcon,
  useAsyncRender,
  useAsyncRenderResult,
  DynamicListDispatch,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiRequest,
  useDynamicListVariable,
  useModal,
  useRequest,
} from "@thrive-web/ui-hooks";
import { format_time_passed } from "@thrive-web/ui-utils";

export const UserFeedback: Preact.FunctionComponent<{
  userId: string;
}> = ({ userId }) => {
  const inv_params = useMemo<ApiMethodParameters<"getFeedbackInvitations">[0]>(
    () => ({
      query: {
        sort: [{ by: "created_at", dir: "desc" }],
        filter: [
          [
            "=",
            ["this", "AssessmentFeedbackInvitation:sender"],
            ["id", userId],
          ],
        ],
        include: ["recipient_assessment"],
      },
    }),
    [userId]
  );

  const [invites, update_invites] =
    useDynamicListVariable<AssessmentFeedbackInvitation>([]);
  const [get_invites_req, { pending, success, error }] = useApiRequest(
    "getFeedbackInvitations",
    inv_params
  );

  const fb_params = useMemo<ApiMethodParameters<"getSelfSurveys">[0]>(
    () => ({
      query: {
        sort: [{ by: "completed_at", dir: "desc" }],
        filter: [
          ["=", ["this", "TraitifyAssessment:taken_about"], ["id", userId]],
          ["exists", ["this", "TraitifyAssessment:completed_at"]],
        ],
      },
    }),
    [userId]
  );

  const [FbSuccessView, getFeedback] = useAsyncRender(
    result =>
      result.data.length === 0 ? (
        <EmptyList className="user-profile__feedback__empty">
          <Icon iconSize="md" name="email" />
          <div>
            Your recipients haven’t responded yet! To re-send your invitations,
            use <em>View/Edit Survey Recipients</em>.
          </div>
        </EmptyList>
      ) : (
        <UserStrengthsContent data={result.data} />
      ),
    [],
    "getSelfSurveys",
    fb_params
  );

  useEffect(() => {
    get_invites_req().then(res => {
      update_invites.reset(res.data);
    });
  }, []);

  const modal_props = useMemo(
    () => ({
      invites,
      status: { pending, success, error },
      updateInvites: update_invites,
    }),
    [invites, pending, success, error]
  );
  const [modal, set_open] = useModal({
    id: "user-profile-feedback-modal",
    className: "user-profile__feedback__modal",
    body: UserFeedbackModal,
    bodyProps: modal_props,
    showCloseButton: true,
  });

  return (
    <div className="user-profile__strengths__feedback">
      <UserProfilePrivacyIndicator level="private">
        <h4>Your Feedback Survey</h4>
      </UserProfilePrivacyIndicator>
      <div className="user-profile__card__text">
        These are the strengths your contacts see in you.
      </div>
      <AsyncRender getPromise={getFeedback} PendingView={UserStrengthsLoading}>
        {FbSuccessView}
      </AsyncRender>
      <button
        type="button"
        className="filled gray"
        onClick={() => set_open(true)}
      >
        View/Edit Survey Recipients
      </button>
      {modal}
    </div>
  );
};

export const UserFeedbackModal: Preact.FunctionComponent<
  ModalBodyProps & {
    invites: AssessmentFeedbackInvitation[];
    status: RequestStatus;
    updateInvites: DynamicListDispatch<AssessmentFeedbackInvitation, false, []>;
  }
> = ({ invites, status, updateInvites, closeButton, dismiss }) => {
  const content = useAsyncRenderResult(
    result => (
      <UserFeedbackModalBody
        dismiss={dismiss}
        invites={result}
        updateInvites={updateInvites}
      />
    ),
    [dismiss],
    status,
    invites,
    false
  );

  return (
    <DefaultModalContent title="Get Feedback" closeButton={closeButton}>
      <div className="user-profile__feedback__modal__top user-profile__feedback__section">
        <p>
          Feedback from others will assist you to understand your strengths and
          gain insights about yourself.
        </p>
        <p>Invite at least 3 people you know and trust.</p>
      </div>

      {content}
    </DefaultModalContent>
  );
};

export const UserFeedbackModalBody: Preact.FunctionComponent<{
  invites: AssessmentFeedbackInvitation[];
  dismiss: () => void;
  updateInvites: DynamicListDispatch<AssessmentFeedbackInvitation, false, []>;
}> = ({ invites, dismiss, updateInvites }) => {
  const resend_req = useApiMethod("resendFeedbackInvitation");
  const resend = useCallback(
    (id: string) => {
      return resend_req(id, { body: { data: { attributes: {} } } }).then(
        res => {
          analytics.log_event(analytics.EVENTS.resend_feedback_survey);
          updateInvites.update(i => i.id === id, res.data);
          return res;
        }
      );
    },
    [resend_req, updateInvites]
  );
  const onAddInvites = useCallback(
    (new_invites: AssessmentFeedbackInvitation[]) => {
      analytics.log_event(analytics.EVENTS.send_feedback_survey);
      updateInvites.concat(new_invites);
    },
    [updateInvites, invites]
  );

  return (
    <Preact.Fragment>
      <div className="user-profile__feedback__section">
        <h3>Active Recipients</h3>
        <div className="user-profile__feedback__list">
          {invites.map(inv => (
            <UserFeedbackModalItem key={inv.id} invite={inv} resend={resend} />
          ))}
        </div>
      </div>

      <div className="user-profile__feedback__section">
        <h3>Add Another Recipient</h3>
        <ProfileBuilderFeedbackForm
          dismiss={dismiss}
          hideInvited={true}
          invited={invites}
          finishStep={onAddInvites}
          minInputs={1}
        />
      </div>
    </Preact.Fragment>
  );
};

export const UserFeedbackModalItem: Preact.FunctionComponent<{
  invite: AssessmentFeedbackInvitation;
  resend: (
    id: string
  ) => Promise<MappedApiResponse<"resendFeedbackInvitation">>;
}> = ({ invite, resend }) => {
  const [resend_invite, { pending, success, error }] = useRequest(resend);
  const completed =
    // @ts-expect-error:
    invite.recipient_assessment?.assessment?.status === "complete";
  const time_passed = useMemo(() => {
    const text = format_time_passed(
      new Date(
        completed
          ? //
            // @ts-expect-error:
            invite.recipient_assessment.completed_at
          : invite.updated_at || invite.created_at
      )
    );
    return text === "Just now" ? text.toLowerCase() : text;
  }, [invite.created_at, invite.updated_at, completed]);

  return (
    <div className="user-profile__feedback__row">
      <div className="user-profile__feedback__row__info">
        <div className="user-profile__feedback__row__contact">
          {invite.contact}
        </div>
        <div className="user-profile__feedback__row__date">
          {completed ? "Responded" : "Request sent"} {time_passed}.
        </div>
      </div>
      <div className="user-profile__feedback__row__button">
        {!completed && (
          <RequestButtonWithIcon
            className="pill filled gray"
            icon="jump-ahead"
            side="left"
            onClick={() => resend_invite(invite.id)}
            pending={pending}
            success={success}
            successText="Invite Sent!"
          >
            Resend Invite
          </RequestButtonWithIcon>
        )}
      </div>
      {error && (
        <div className="user-profile__feedback__row__error">
          <ErrorMessage>{error.message}</ErrorMessage>
        </div>
      )}
    </div>
  );
};
