import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { pick } from "@thrive-web/ui-common";
import {
  DefaultPendingView,
  InputWithFormHelpers,
  MultiValueInput,
  OptionsList,
  OptionsListAsync,
  Popover,
  SvgLoadingSpinner,
} from "@thrive-web/ui-components";
import {
  useChildRef,
  useDebounce,
  useRenderPropsFunction,
  useStateIfMounted,
  useTextFilter,
} from "@thrive-web/ui-hooks";
import {
  chain_event_listeners,
  class_names,
  maybeClassName,
} from "@thrive-web/ui-utils";

export const InputWithDropdown = <
  T extends any,
  M extends boolean,
  A extends boolean,
  C extends boolean
>({
  options = [],
  onChange,
  value,
  renderOption,
  renderSelected,
  getTextFromSelected,
  defaultValue,
  className,
  children,
  allowCustom,
  allowMultiple,
  async,
  selected,
  filterOption,
  createOption,
  createCustomOptText,
  onRemoveValue,
  stayOpenAfterSelect,
  getOptions,
  loadWhenOpened,
  ...props
}: Preact.RenderableProps<
  InputWithDropdownProps<T, M, A, C>
>): Preact.VNode | null => {
  const is_async = useMemo(() => !!async, []);
  const is_multi = useMemo(() => !!allowMultiple, []);
  const stay_open_on_select =
    stayOpenAfterSelect != null ? stayOpenAfterSelect : !is_async && is_multi;

  const [input_ref, get_input_ref] = useChildRef<HTMLInputElement>();
  const input_comp_ref = useRef<InputWithFormHelpers<string>>();
  const [text, setText] = useStateIfMounted("");
  const [search, set_search] = useStateIfMounted("");
  const [open, setOpen] = useStateIfMounted(false);
  const [blurred, set_blurred] = useStateIfMounted(false);
  const [inputProps, setInputProps] = useStateIfMounted({});
  let matches = options;
  let input_props: any = {};
  let debounce_time = 200;
  let req_pending = false;
  let filter_opts;
  let get_options;
  const OptionsListComp = is_async ? OptionsListAsync : OptionsList;

  // FOR ALL MODES =========

  const onSelectOption = useCallback(
    (option: T) => {
      if (is_multi) {
        setText("");
        if (is_async) {
          set_search("");
        }
      } else {
        const new_text = getTextFromSelected(option);
        setText(new_text);
        set_search(new_text);
      }
      onChange(option);
      if (stay_open_on_select) {
        input_ref.current?.focus();
        setOpen(true);
      }
    },
    [onChange]
  );

  const validate = useCallback(
    (_, input?: HTMLInputElement | null) => {
      const valid =
        !blurred || (is_multi ? (selected || []).length > 0 : !!value);
      if (input) {
        input.setCustomValidity(!valid ? "This field is required" : "");
      }
      return valid;
    },
    [blurred, value]
  );

  const onBlur = useCallback(() => {
    if (input_comp_ref.current?.dirty) {
      set_blurred(true);
    }
  }, [set_blurred]);

  const RenderItem = useRenderPropsFunction(
    ({ item, onSelect, isCustom, selected }) => (
      <div className="dropdown-input__item" onClick={() => onSelect(item)}>
        {renderOption ? (
          renderOption(item, isCustom)
        ) : (
          <div className="dropdown-input__item--default">
            {(text => (isCustom ? createCustomOptText?.(text) : text))(
              getTextFromSelected(item)
            )}
          </div>
        )}
      </div>
    ),

    "InputWithDropdown-ListItem",
    []
  );

  // FOR SINGLE VALUE ============

  const clearValue = useCallback(() => {
    // clear both the stored value and the text input
    onChange();
    setText("");
    input_ref.current && input_ref.current.focus();
  }, [onChange, setText, input_ref]);

  // clear input on "Backspace"
  const onKeyDown = useCallback(
    e => {
      if (e.key === "Backspace" && value) {
        e.preventDefault();
        clearValue();
      }
    },
    [clearValue, value]
  );

  // this is only safe because is_multi never changes
  if (!is_multi) {
    useEffect(() => {
      const new_text = value ? getTextFromSelected(value) : "";
      setText(new_text);
      set_search(new_text);
    }, [value, getTextFromSelected]);
  }

  const options_are_equal = useCallback(
    (opt1: T, opt2: T) =>
      getTextFromSelected(opt1) === getTextFromSelected(opt2),
    [getTextFromSelected]
  );
  // FOR ALLOW CUSTOM =============
  const custom_option = useMemo(() => {
    if (!allowCustom || !createOption || !text.trim() || search !== text) {
      return;
    }
    const opt = createOption(text.trim());
    if (!selected?.find(s => options_are_equal(s, opt))) {
      return opt;
    }
  }, [allowCustom, selected, options_are_equal, createOption, text, search]);

  // FOR ASYNCHRONOUS ============
  // this is only safe because is_async never changes
  if (is_async) {
    debounce_time = 350;
    get_options = useCallback(
      (offset, limit) => getOptions(search, offset, limit),
      [search, getOptions]
    );
    filter_opts = filterOption;
  } else {
    // FOR SYNCHRONOUS ============
    matches = useTextFilter(text, options, filterOption!);
  }

  // FOR MULTI VALUE =============
  if (is_multi) {
    const renderValue = useCallback(
      (opt: T, onRemove) => renderSelected?.(opt, onRemove),
      [renderSelected]
    );
    if (!is_async) {
      matches = useMemo(
        () => matches.filter(m => !selected?.includes(m)),
        [selected, matches]
      );
    }
    input_props = {
      ...input_props,
      values: selected,
      renderValue,
      onRemoveValue,
      children: (
        <Preact.Fragment>
          {children}
          {is_async && (
            <div className="loading-spinner">
              <SvgLoadingSpinner />
            </div>
          )}
        </Preact.Fragment>
      ),
    };
  }

  // OTHER STUFF ====================

  const onChangeInputRef = useDebounce(e => {
    set_search(e.target.value);
    if (is_async || !stay_open_on_select) {
      setOpen(true);
    }
  }, debounce_time);

  const onChangeInput = useCallback(e => {
    setText(e.target.value);
    onChangeInputRef.current?.(e);
  }, []);

  // combine event listeners from props with those needed internally
  const listeners = useMemo(() => {
    const conflicts = pick(Object.keys(inputProps), props);
    return chain_event_listeners<HTMLInputElement>(
      {
        onKeyDown: !is_multi ? onKeyDown : undefined,
        onBlur: is_async ? onBlur : undefined,
      },
      inputProps,
      conflicts
    );
  }, [inputProps, props]);

  const InputComponent = is_multi ? MultiValueInput : InputWithFormHelpers;

  const async_class_names = is_async
    ? class_names({ "--pending": req_pending }, "dropdown-input__async")
    : "";
  const multiple_class_name = is_multi ? " dropdown-input__multiple" : "";

  return (
    <Popover
      className={`dropdown-input${maybeClassName(
        multiple_class_name
      )}${maybeClassName(async_class_names)}${maybeClassName(className)}`}
      triggerComponent={
        <div
          className={`${
            is_multi ? "" : "input__container "
          }dropdown-input__input__container`}
        >
          {
            // @ts-expect-error:
            <InputComponent
              ref={input_comp_ref}
              className="dropdown-input__input"
              validate={props.required ? validate : undefined}
              {...props}
              {...listeners}
              value={text}
              onChange={onChangeInput}
              readOnly={!!value && !!renderSelected}
              autocomplete="off"
              inputRef={get_input_ref}
              controlled={true}
              {...input_props}
            />
          }
          {!is_multi && (
            <Preact.Fragment>
              {children}
              {value && renderSelected && (
                <div className="dropdown-input__input__value">
                  {renderSelected(value, clearValue)}
                </div>
              )}
              {is_async && <DefaultPendingView />}
            </Preact.Fragment>
          )}
        </div>
      }
      show={open && !value}
      defaultDirection="bottom"
    >
      {
        // @ts-expect-error:
        <OptionsListComp
          options={matches}
          RenderItem={RenderItem}
          onSelect={onSelectOption}
          setControlListeners={setInputProps}
          open={open}
          setOpen={setOpen}
          emptyLabel={text ? `No options matched "${text}"` : "No options"}
          customOption={custom_option}
          closeOnClickItem={!stay_open_on_select}
          {...(is_async
            ? {
                getOptions: get_options,
                filterOptions: filter_opts,
                optionsAreEqual: options_are_equal,
              }
            : {})}
        />
      }
    </Popover>
  );
};
InputWithDropdown.displayName = "InputWithDropdown";
