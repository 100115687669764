import { ApiMethodParameters, FilterSpec } from "@thrive-web/core";
import type { FilterClause } from "@swymbase/sparql-rest";
import {
  Goal,
  Group,
  Touchpoint,
  TouchpointAccomplishment,
  TouchpointAction,
  User,
  WriteTouchpoint,
} from "@thrive-web/ui-api";
import { comparator, entity_has_type, has_items } from "@thrive-web/ui-common";
import { THREAD_COMM_ID } from "@thrive-web/ui-constants";
import { get_user_name_list, join_string_list } from "@thrive-web/ui-utils";

// check whether a group belongs to the Thread community
export const is_thread_group = (group?: Group): group is Group =>
  !!group &&
  entity_has_type(group, "Group") &&
  group.in_community?.id === THREAD_COMM_ID;

// check whether touchpoints are allowed in posts to the given group
export const group_updates_allowed = (group?: Group) =>
  is_thread_group(group) && !!group.has_goals;

// chick if the user is an admin or member of the specified group
export const group_role_of = (
  user: User,
  group: Group
): "has_admin" | "has_member" | null => {
  if (!!group.has_admin?.some(u => u.id === user.id)) {
    return "has_admin";
  } else if (!!group.has_member?.some(u => u.id === user.id)) {
    return "has_member";
  }
  return null;
};

// chick if the user is an admin of the specified group
export const user_is_group_admin = (user: User, group: Group) => {
  return group_role_of(user, group) === "has_admin";
};

// generate query params for fetching goals using given group and search term
export const group_goals_query_params = (
  group: Group,
  search: string = ""
): ApiMethodParameters<"GET", "Goal", false> => {
  const filter: FilterClause[] = [
    ["=", ["this", "Goal:group"], ["id", group!.id as string]],
  ];
  if (search) {
    filter.push(["match", ["this", "Goal:name"], search, "i"]);
  }
  return {
    query: {
      filter,
      sort: [{ by: "created_at", dir: "desc" }],
    },
  };
};

// generate query params for fetching users to invite to a group
// conditions:
//   1. Connected with current user
//   2. Not a member of the group
//   3. (optional) Name matches search term
export const group_invite_search_filter = (
  user_id: string | undefined,
  group_id: string | undefined,
  search?: string
): FilterSpec | undefined => {
  if (!user_id || !group_id) {
    return;
  }
  let filter_clause: FilterSpec = [
    [
      "=",
      ["this", ["/", "User:has_connection", "Connection:users"]],
      ["id", user_id],
    ],
    ["not", ["=", ["this", ["^", "Group:has_member"]], ["id", group_id]]],
  ];
  if (search) {
    filter_clause = [
      ...filter_clause,
      ["match", ["this", "User:full_name"], search, "i"],
    ];
  }
  return filter_clause;
};

// generate the touchpoint description text using the data selected for the touchpoint
export const generateTouchpointText = (
  touchpoint: Touchpoint | WriteTouchpoint
): string => {
  const { has_accomplishment, has_action, has_goal, posted_to, barrier } =
    touchpoint;

  // if no users or actions, tp is invalid, return empty string;
  if (
    !posted_to ||
    !has_items<User>(posted_to["has_tagged_user"]) ||
    !has_items<TouchpointAction>(has_action)
  ) {
    return "";
  }
  const sentences: string[] = [];
  // "I {actions} with {users}."
  sentences.push(
    `I ${join_string_list(
      // @ts-expect-error:
      has_action
        .sort(comparator("display_rank", "asc"))
        .map(a => a.title!.toLowerCase())
    )} with ${get_user_name_list(posted_to["has_tagged_user"], "", 3, false)}.`
  );
  if (has_items<TouchpointAccomplishment>(has_accomplishment)) {
    // "[We connected.]"
    const connected = has_accomplishment.find(a => /connected/i.test(a.id));
    if (connected) {
      sentences.push(`We ${connected.title!.toLowerCase()}.`);
    }
    const acc_goals = has_accomplishment
      .filter(acc => /goal/i.test(acc.id))
      // @ts-expect-error:
      .sort(comparator("display_rank", "asc"))
      .map(a => /*(a.text || */ a.title!.toLowerCase());
    if (acc_goals.length > 0 && has_items<Goal>(has_goal)) {
      // "We {goal-accomplishments} of {goals}."
      sentences.push(
        `We ${join_string_list(acc_goals)} of ${join_string_list(
          has_goal.map(g => g.name!.toLowerCase())
        )}.`
      );
    }
    const acc_barriers = has_accomplishment
      .filter(acc => /barrier/i.test(acc.id))
      // @ts-expect-error:
      .sort(comparator("display_rank", "asc"))
      .map(a => /*(a.text || */ a.title!.toLowerCase());
    if (acc_barriers.length > 0 && barrier) {
      // "We {barrier-accomplishments} of {barriers}."
      sentences.push(`We ${join_string_list(acc_barriers)} of ${barrier}.`);
    }
  }

  return sentences.join(" ");
};
