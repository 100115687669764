import { MediaSize } from "@thrive-web/core";
import * as Preact from "preact";
import { MediaSizes, ScreenSize } from "@thrive-web/ui-constants";

export type PropsOfElem<T extends HTMLElement> = Preact.JSX.HTMLAttributes<T>;

const matches = (node, selector) =>
  node
    ? node.msMatchesSelector
      ? node.msMatchesSelector(selector)
      : node.matches?.(selector)
    : false;

// returns true if the given element matches any of the given selectors
export const hasAnySelector = (
  el: HTMLElement,
  selector: string | string[]
): boolean => {
  return Array.isArray(selector)
    ? selector.filter(sel => matches(el, sel)).length > 0
    : matches(el, selector);
};

// returns the closest ancestor of the given element that matches the given selector
export const closestAncestor = (
  el: HTMLElement,
  selector: string | string[]
): HTMLElement | null => {
  if (hasAnySelector(el, selector)) {
    return el;
  }
  while (
    (el = el.parentElement as HTMLElement) &&
    !hasAnySelector(el, selector)
  );
  return el || null;
};

export const maybeClassName = str => (str ? ` ${str}` : "");

/** Serialize a set of map keys into a list of DOM class names.  For each
 * property of the given object having a truthy value, the key will be included
 * in the list of classes.  If a prefix is provided, it will be prepended to
 * each class name and also included in the list itself. */
export const class_names = (
  classes: object,
  prefix: string,
  omit_base?: boolean
) =>
  Object.entries(classes)
    .filter(([_, value]) => value)
    .map(([key]) => (prefix ? prefix : "") + key)
    .concat(prefix && !omit_base ? [prefix] : [])
    .join(" ");

export const class_names_postfixed = (
  classes: (string | undefined)[],
  postfix: string
) =>
  classes
    .filter(value => !!value)
    .map(value => value + postfix)
    .join(" ");

/** Map pixel widths to sizes as defined in `/style/lib/breakpoints.styl` */
const media_size_from = (width: number): MediaSize | undefined => {
  if (width <= MediaSizes.small || width - MediaSizes.small < 180)
    return "small";
  if (width <= MediaSizes.medium || width - MediaSizes.medium < 180)
    return "medium";
  if (width <= MediaSizes.large || width - MediaSizes.large < 180)
    return "large";
  if (width <= MediaSizes.xlarge || width - MediaSizes.xlarge < 180)
    return "xlarge";
  return;
};
// gets media size from mapping, with some rules enforced based on expected css styles
export const media_size = (
  window_size: ScreenSize,
  // portion of the page content width that the media is expected to fill up
  // (e.g. 2 = 1/2 of the width of the page content)
  factor: number
): MediaSize | undefined => {
  let est_size = window_size;
  // at this size, the page content has `max-width: 1440px`
  if (window_size > ScreenSize.xl) {
    est_size = 1440;
    // at this size, the page content has `max-width: 1024px`
  } else if (window_size > ScreenSize.lg) {
    est_size = 1024;
  }
  return media_size_from(est_size / factor);
};

/** Map pixel widths to sizes as defined in `/style/lib/breakpoints.styl` */
const screen_size_from = (width: number): ScreenSize => {
  if (width <= ScreenSize.xxs) return ScreenSize.xxs;
  if (width <= ScreenSize.xs) return ScreenSize.xs;
  if (width <= ScreenSize.sm) return ScreenSize.sm;
  if (width <= ScreenSize.md) return ScreenSize.md;
  if (width <= ScreenSize.lg) return ScreenSize.lg;
  if (width <= ScreenSize.xl) return ScreenSize.xl;
  return ScreenSize.xxl;
};

const current_window_width = () =>
  window.innerWidth ||
  (document.documentElement && document.documentElement.clientWidth) ||
  document.body.clientWidth;

export const current_screen_size = () =>
  screen_size_from(current_window_width());

export const scroll_top = (top: number = 0) => {
  document.body.scrollTop = top;
  document.documentElement.scrollTop = top;
};

// returns true if the document.body has a scroll bar on the given axis (defaults to "y")
export const has_scrollbar = (dir: "x" | "y" = "y") => {
  return dir === "x"
    ? document.body.scrollWidth > document.body.clientWidth
    : document.body.scrollHeight > window.innerHeight;
};

let scrollbar_width_monitor = document.getElementById(
  "scrollbar-width-monitor"
);
const SCROLLBAR_WIDTH = {
  x: 0,
  y: 0,
};
// fetch the with of the scrollbar along the given axis, in px
export const get_scrollbar_width = (axis: "x" | "y" = "y") => {
  if (!scrollbar_width_monitor) {
    scrollbar_width_monitor = document.getElementById(
      "scrollbar-width-monitor"
    );
  }
  if (!scrollbar_width_monitor) {
    return 0;
  }
  const width =
    axis === "y"
      ? scrollbar_width_monitor.offsetWidth -
        scrollbar_width_monitor.clientWidth
      : scrollbar_width_monitor.offsetHeight -
        scrollbar_width_monitor.clientHeight;

  // if the value changed, store it in a css variable
  if (
    width !== SCROLLBAR_WIDTH[axis] ||
    !document.body.style["--scrollbar-width-x"] ||
    !document.body.style["--scrollbar-width-y"]
  ) {
    SCROLLBAR_WIDTH[axis] = width;
    document.body.setAttribute(
      "style",
      `--scrollbar-width-x: ${has_scrollbar("y") ? SCROLLBAR_WIDTH.x : 0}px;` +
        `--scrollbar-width-y: ${has_scrollbar("x") ? SCROLLBAR_WIDTH.y : 0}px; `
    );
  }
  return width;
};

// macro to map an object to a set of data-attrs for a DOM element
export const data_attrs = (
  attrs: ObjectOf<string | number | boolean | null | undefined>
) => {
  const output = {};
  Object.entries(attrs).forEach(([key, value]) => {
    if (value !== undefined) {
      output[`data-${key}`] = `${value}`;
    }
  });
  return output;
};

export const node_is_link = (node: HTMLElement | null) =>
  node && node.nodeName.toUpperCase() === "A" && node.hasAttribute("href");

// displays emojis and line breaks properly
export const display_text = (text?: string): string | undefined => {
  if (!text) {
    return;
  }
  if (typeof text !== "string") {
    return text;
  }
  return text
    .split("\n")
    .map(part => decodeURIComponent(JSON.parse(`"${part}"`)))
    .join("\n");
};

export const getNumNodesBeforeOverflow = (
  list: HTMLElement,
  container?: HTMLElement | null,
  overflowNode?: HTMLElement | null
) => {
  const { childNodes } = list;
  const offsetParent = container || list.offsetParent;
  // if we can't find the offsetParent, give up and assume full page width
  if (!offsetParent || !(offsetParent instanceof HTMLElement)) {
    return childNodes.length;
  }
  const container_width = offsetParent.clientWidth;
  const menu_width = overflowNode?.clientWidth || 0;
  let i = 0,
    stop = false;
  // check each node and stop on the first one that overflows the offsetParent
  childNodes.forEach((node, index) => {
    if (!(node instanceof HTMLElement) || stop) {
      return;
    }
    const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = node;
    if (
      offsetLeft + offsetWidth >
        container_width -
          (index < childNodes.length - 1 ? menu_width + 8 : 0) ||
      offsetTop + offsetHeight > list.offsetTop + list.offsetHeight
    ) {
      stop = true;
      return;
    }
    i++;
  });
  return i;
};

// potentially focusable = would be focusable if the element
// doesn't have [tabindex="-1"]
// https://stackoverflow.com/a/38865836
export const POTENTIAL_FOCUSABLE_SELECTORS = [
  "a[href]",
  "area[href]",
  'input:not([disabled]):not([type="hidden"])',
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "iframe",
  "object",
  "embed",
  "*[tabindex]:not([tabindex='-1'])",
  "*[contenteditable]",
];
export const FOCUSABLE_SELECTORS = POTENTIAL_FOCUSABLE_SELECTORS.map(s => {
  const extra = ":not([tabindex='-1'])";
  return s.endsWith(extra) ? s : `${s}:not([tabindex='-1'])`;
});

// find elements in given container that are potentially focusable
export const queryPotentialFocusable = (
  container: Element,
  prefix?: string
) => {
  if (!prefix) {
    return container.querySelectorAll(POTENTIAL_FOCUSABLE_SELECTORS.join(", "));
  }
  return container.querySelectorAll(
    POTENTIAL_FOCUSABLE_SELECTORS.map(s => `${prefix} ${s}`).join(", ")
  );
};

// find elements in given container that are currently focusable
export const queryFocusable = (container: Element, prefix?: string) => {
  if (!prefix) {
    return container.querySelectorAll(FOCUSABLE_SELECTORS.join(", "));
  }
  return container.querySelectorAll(
    FOCUSABLE_SELECTORS.map(s => `${prefix} ${s}`).join(", ")
  );
};

// get first focusable element in a container
export const getFirstFocusable = (container: Element, prefix?: string) => {
  if (!prefix) {
    return container.querySelector(FOCUSABLE_SELECTORS.join(", "));
  }
  return container.querySelector(
    FOCUSABLE_SELECTORS.map(s => `${prefix} ${s}`).join(", ")
  );
};

// get last focusable element in a container
export const getLastFocusable = (container: Element, prefix?: string) => {
  const selectors = prefix
    ? FOCUSABLE_SELECTORS.map(s => `${prefix} ${s}`).join(", ")
    : FOCUSABLE_SELECTORS.join(", ");

  const list = container.querySelectorAll(selectors);
  return list[list.length - 1] || null;
};

// get first focusable ancestor element of the given node
export const getFocusableContainer = (
  node: HTMLElement,
  outerContainer?: HTMLElement
) => {
  if (hasAnySelector(node, FOCUSABLE_SELECTORS)) {
    return node;
  }
  while (
    (node = node.parentElement as HTMLElement) &&
    node !== outerContainer &&
    !hasAnySelector(node, FOCUSABLE_SELECTORS)
  );
  return node || null;
};

export const queryOverflowListItems = (container: Element, visible: boolean) =>
  queryFocusable(container, `li[data-visible="${visible}"]`);

// toggle attributes on an element to allow/disallow focusing the element via Tab key
export const toggle_allow_tab_focus = (
  element: Element,
  allow: boolean,
  cancel: Preact.RefObject<boolean>
) => {
  if (cancel.current) {
    return;
  }
  if (!allow) {
    const og_val = element.getAttribute("tabindex");
    if (element.hasAttribute("data-tab-focus-disabled") || og_val === "-1") {
      return;
    }
    return () => {
      if (cancel.current) {
        return;
      }
      element.setAttribute("data-tab-focus-disabled", "true");
      element.setAttribute("tabindex", "-1");
      element.setAttribute("aria-hidden", "true");
      if (og_val) {
        element.setAttribute("data-og-tabindex", og_val);
      }
    };
  } else {
    if (!element.hasAttribute("data-tab-focus-disabled")) {
      return;
    }
    const og_val = element.getAttribute("data-og-tabindex");
    return () => {
      if (cancel.current) {
        return;
      }
      element.removeAttribute("data-tab-focus-disabled");
      element.removeAttribute("aria-hidden");
      if (og_val) {
        element.setAttribute("tabindex", og_val);
      } else {
        element.removeAttribute("tabindex");
      }
    };
  }
};

// toggle_allow_tab_focus, but for an array of elements
export const toggle_allow_tab_focus_batch = (
  elements: NodeListOf<Element>,
  allow: boolean,
  cancel: Preact.RefObject<boolean>
) => {
  // do all attr reads, THEN all attr writes
  let apply_attr_updates: (() => void)[] = [];
  elements.forEach(el => {
    const func = toggle_allow_tab_focus(el, allow, cancel);
    if (func) {
      apply_attr_updates.push(func);
    }
  });
  if (!cancel.current) {
    return apply_attr_updates;
  }
};

export const copy_to_clipboard = (
  text: string,
  input: HTMLTextAreaElement = document.getElementById(
    "clipboard-input"
  ) as HTMLTextAreaElement
) => {
  if (!input) {
    return;
  }
  input.value = text;
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand("copy");
  navigator?.clipboard?.writeText(text);
  input.value = "";
};

// create a context and give it a distinguishable display name so it can be
// easily identified in devtools
export const createNamedContext = <T>(
  initialValue: T,
  name: string
): Preact.Context<T> => {
  const ctx = Preact.createContext<T>(initialValue);
  ctx.displayName = `__${name}__`;
  return ctx;
};
