import * as Preact from "preact";
import { memo } from "preact/compat";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { ApiMethodParameters, DocBase } from "@thrive-web/core";
import { ConnectionMapped, User } from "@thrive-web/ui-api";
import {
  UserProfilePrivacyIndicator,
  USER_COACH_COUNT,
  USER_COACH_STATUS,
  USER_COACHES,
  USER_DETAIL_CONTEXTS,
  USER_DISPATCH,
} from "~/view/components";
import {
  AsyncRender,
  Card,
  DefaultModalContent,
  useAsyncRenderResult,
  useRenderDynamicListWithPagedFetch,
  UserListItem,
  UserListItemLoading,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useApiMethod,
  useAppUser,
  useModal,
  usePreviousValue,
  useResettableRequest,
} from "@thrive-web/ui-hooks";
import { connection_coach_filter } from "@thrive-web/ui-utils";

const SIDEBAR_LIST_MAX_LENGTH = 4;

export const UserCoachingListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <ul className="user-profile__connections__list loading-item__list">
      {items.map((_, i) => (
        <UserListItemLoading key={i} />
      ))}
    </ul>
  );
};

export const UserCoachingListItem: Preact.FunctionComponent<{
  connection: ConnectionMapped;
}> = ({ connection }) => (
  <UserListItem
    user={connection.other_user}
    linkBox="content"
    health={connection.health_tier}
    size="md"
    cardSubtext={
      connection.other_user.id === connection.has_coach?.id
        ? "Coach"
        : "Coachee"
    }
  />
);

export const UserCoachingList = (
  result: DocBase & { data: ConnectionMapped[] },
  pending?: boolean,
  passthrough?: { total: number; user: User }
) => {
  const list = useMemo(
    () => result.data.slice(0, SIDEBAR_LIST_MAX_LENGTH),
    [result]
  );
  return (
    <ul className="user-profile__connections__list">
      {list.map(c => (
        <UserCoachingListItem connection={c} />
      ))}
      {passthrough && passthrough.total > SIDEBAR_LIST_MAX_LENGTH && (
        <li key="user-list__more" className="user-list__more">
          <UserCoachingModal user={passthrough.user} />
        </li>
      )}
    </ul>
  );
};

const UserCoachingModalListRender = (
  result: ConnectionMapped[],
  load_more_elem: Preact.VNode | null
) => (
  <div className="stack">
    <ul className="user-list stack__scrolling-content">
      {result.map(c => (
        <UserCoachingListItem key={c.id} connection={c} />
      ))}
      {load_more_elem && (
        <li key="user-list__more" className="user-list__more">
          {load_more_elem}
        </li>
      )}
    </ul>
  </div>
);

export const UserCoachingModalList: Preact.FunctionComponent<
  ModalBodyProps & { user: User }
> = ({ user, closeButton }) => {
  const { list, dispatch } = useContext(USER_COACHES);
  const dispatch_ctx = useContext(USER_DISPATCH);
  const params = useMemo<ApiMethodParameters<"GET", "Connection", false>>(
    () => ({
      query: {
        filter: connection_coach_filter(user),
        include: ["users.User:profile_picture"],
        sort: [{ by: "raw_health", dir: "asc" }],
        include_count: true,
      },
    }),
    [user.id]
  );
  const get_coaches_req = useApiFetchPaged("getConnections", params);
  const get_coaches_mapped = useCallback(
    (offset: number, limit?: number) =>
      get_coaches_req(offset, limit).then(({ data, ...result }) => {
        dispatch_ctx(
          "coach_count",
          (result.meta?.total_result_count as number) ?? -1
        );
        return {
          ...result,
          data: data
            .map(c => {
              const other_user = c.users?.find(u => u.id !== user?.id);
              if (!other_user) {
                return null;
              }
              return { ...c, other_user };
            })
            .filter(c => !!c) as ConnectionMapped[],
        };
      }),
    [get_coaches_req, user.id]
  );

  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    UserCoachingModalListRender,
    [],
    get_coaches_mapped,
    undefined,
    { PendingView: UserCoachingListLoading }
  );

  return (
    <DefaultModalContent
      title="Coaching Relationships"
      closeButton={closeButton}
    >
      {content}
    </DefaultModalContent>
  );
};

export const UserCoachingModal: Preact.FunctionComponent<{
  user: User;
}> = props => {
  const [modal, set_modal_open] = useModal(
    {
      id: `${props.user.id}-coaches-modal`,
      innerClassName: "card stack user-list__modal",
      body: UserCoachingModalList,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      showCloseButton: true,
      bodyProps: props,
    },
    undefined,
    false
  );

  return (
    <Preact.Fragment>
      <button className="plain-link blue" onClick={() => set_modal_open(true)}>
        View All
      </button>
      {modal}
    </Preact.Fragment>
  );
};

export const UserCoaching: Preact.FunctionComponent<{
  user: User;
  refresh: any;
}> = memo(({ user, refresh }) => {
  const { dispatch } = useContext(USER_COACHES);
  const dispatch_ctx = useContext(USER_DISPATCH);
  const total = useContext(USER_COACH_COUNT);
  const get_coaches_req = useApiMethod("getConnections");
  const get_coaches_mapped = useCallback(
    () =>
      get_coaches_req({
        query: {
          filter: connection_coach_filter(user),
          include: ["users.User:profile_picture"],
          sort: [{ by: "raw_health", dir: "asc" }],
          include_count: true,
          limit: SIDEBAR_LIST_MAX_LENGTH,
        },
      }).then(({ data, ...result }) => {
        dispatch_ctx(
          "coach_count",
          (result.meta?.total_result_count as number) ?? -1
        );
        const res = {
          ...result,
          data: data
            .map(c => {
              const other_user = c.users?.find(u => u.id !== user?.id);
              if (!other_user) {
                return null;
              }
              return { ...c, other_user };
            })
            .filter(c => !!c) as ConnectionMapped[],
        };
        dispatch.reset(res.data);
        return res;
      }),
    [get_coaches_req, user.id, refresh, dispatch_ctx]
  );
  const [get_coaches, status, reset] = useResettableRequest(get_coaches_mapped);
  const passthrough = useMemo(() => ({ total, user }), [total, user]);
  const prev_user_id = usePreviousValue(user.id);
  const prev_refresh = usePreviousValue(refresh);
  const keep_list =
    prev_user_id.current === user.id && refresh !== prev_refresh.current;

  useEffect(() => {
    return () => {
      reset();
      dispatch_ctx("coach_count", -1);
    };
  }, [user.id]);
  useEffect(() => {
    dispatch_ctx("coach_status", status);
  }, [status]);

  return (
    <AsyncRender
      getPromise={get_coaches}
      PendingView={UserCoachingListLoading}
      passthroughProps={passthrough}
      keepViewOnUpdate={keep_list}
    >
      {UserCoachingList}
    </AsyncRender>
  );
});

export const UserCoachingCard: Preact.FunctionComponent<{
  user: User;
  refresh: any;
}> = ({ user, refresh }) => {
  const self = useAppUser();
  const status = useContext(USER_COACH_STATUS);
  const total = useContext(USER_COACH_COUNT);
  const { list } = useContext(USER_COACHES);
  const connection = useContext(USER_DETAIL_CONTEXTS.connection) || undefined;

  const passthru = useMemo(() => ({ total, user }), [total, user.id]);
  const prev_user_id = usePreviousValue(user.id);
  const prev_refresh = usePreviousValue(refresh);
  const keep_list =
    prev_user_id.current === user.id && refresh !== prev_refresh.current;

  const content = useAsyncRenderResult(
    (result, ...args) => UserCoachingList({ data: result || [] }, ...args),
    [],
    status,
    list,
    keep_list,
    undefined,
    UserCoachingListLoading,
    false,
    passthru
  );

  if (!self || !user || (!connection && self.id !== user.id)) {
    return null;
  }

  return !status.pending &&
    !status.error &&
    (!status.success || (list && list.length === 0)) ? null : (
    <Card className="user-profile__card user-profile__coaches">
      {self?.id === user.id ? (
        <UserProfilePrivacyIndicator level="public">
          <h3 className="user-profile__card__title">Coaching</h3>
        </UserProfilePrivacyIndicator>
      ) : (
        <h3 className="user-profile__card__title">Coaching</h3>
      )}
      {content}
    </Card>
  );
};
