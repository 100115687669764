import * as Preact from "preact";
import {
  ConnectionRequest,
  Event,
  Mood,
  Notification,
  User,
} from "@thrive-web/ui-api";
import {
  NotifCustomTypes,
  EVENT_BANNER_PLACEHOLDER_PATH,
} from "@thrive-web/ui-constants";
import { moment } from "@thrive-web/ui-common";
import {
  useApiMethod,
  useAppUser,
  useAppUserPropertyUpdate,
  useMedia,
  useRenderPropsFunction,
} from "@thrive-web/ui-hooks";
import {
  get_guid_from_iri,
  get_url_for_entity,
  maybe_route,
  maybeClassName,
} from "@thrive-web/ui-utils";
import { getCurrentUrl } from "preact-router";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { EVENTS } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { ConnectionRequestIncomingControls } from "~/view/components";
import {
  Avatar,
  AvatarWithCtx,
  ButtonWithIcon,
  Icon,
  MoodSelectorModalBody,
  RemovableListItem,
  RemovableListItemComponentProps,
  useSelfConnectionWithUser,
} from "@thrive-web/ui-components";

const noop = () => {};

const createNotifMoodSelector: (
  notif: Notification,
  on_update: (id: string, notif?: Notification | null) => void,
  is_menu: boolean
) => [Preact.VNode, () => void, Preact.VNode?] = (
  notif,
  on_update,
  is_menu
) => {
  const self = useAppUser();
  const dispatch = useContext(CONTEXTS.dispatch);
  const updateMoodReq = useApiMethod("setCapturedMood");
  const updateAppUser = useAppUserPropertyUpdate();
  const onFinish = useCallback(() => {
    dispatch({ type: EVENTS.CLOSE_APP_MODAL });
    on_update(notif.id, null);
  }, [notif, on_update]);

  const updateMood = useCallback(
    (new_mood?: Mood) => {
      if (!self) {
        return Promise.resolve();
      }
      return updateMoodReq({
        body: {
          data: {
            attributes: {},
            relationships: {
              mood: {
                data: new_mood?.id ? { id: new_mood.id } : null,
              },
            },
          },
        },
      })
        .then(() => updateAppUser("has_current_mood", new_mood))
        .then(() => {
          setTimeout(onFinish, 800);
        });
    },
    [updateMoodReq, self, notif]
  );

  const modal_body = useRenderPropsFunction(
    (props: ModalBodyProps) => (
      <MoodSelectorModalBody {...props} setOpen={noop} onSelect={updateMood} />
    ),
    "NotifMoodSelector",
    [updateMood]
  );
  const open_modal = useCallback(() => {
    dispatch({
      type: EVENTS.OPEN_APP_MODAL,
      payload: {
        id: "mood-selector-modal",
        className: "mood-selector__modal",
        innerClassName: "mood-selector",
        body: modal_body,
        giveTabFocus: true,
        overrideScroll: true,
        showCloseButton: true,
      },
    });
  }, []);
  return [
    <Icon name="mood" iconSize={is_menu ? "sm" : "md"} />,
    open_modal,
    <ButtonWithIcon
      icon="loneliness"
      side="left"
      className="filled gray pill"
      onClick={open_modal}
    >
      Select a Mood
    </ButtonWithIcon>,
  ];
};

export const NotifEventPreview: Preact.FunctionComponent<{
  event: Event;
}> = ({ event }) => {
  const date = useMemo(
    () => moment(event.date_time).format("MMMM Do, YYYY [@] h:mm A z"),
    [event.date_time]
  );

  const window_size = useContext(CONTEXTS.window_size);
  const img_url = useMedia<"Event", "cover_image">(
    event,
    "cover_image",
    getCurrentUrl() !== "/notifications" ? 99 : 1,
    window_size,
    getCurrentUrl() !== "/notifications" ? "small" : undefined
  );

  return (
    <div
      className="banner-header event-preview"
      style={{
        backgroundImage: `url(${img_url || EVENT_BANNER_PLACEHOLDER_PATH})`,
      }}
    >
      <div className="event-preview__content">
        <div className="event-preview__content__top">
          <Icon name="calendar" />
          <div className="event-preview__date">{date}</div>
        </div>
        <div className="event-preview__content__bottom">
          {event.location_name && (
            <div className="event-preview__place">
              <Icon name="location-pin" />
              {event.location_name}
            </div>
          )}
          <div className="event-preview__title">
            {event.is_canceled ? "CANCELLED - " : ""}
            {event.name}
          </div>
        </div>
      </div>
    </div>
  );
};

const createEventNotifItem = (
  notif: Notification,
  on_update: (id: string, notif?: Notification | null) => void,
  is_menu: boolean
): [Preact.VNode, () => void, Preact.VNode?, Preact.VNode?] => {
  const initiator: Event | undefined = notif.initiator;
  return [
    <Icon name="calendar" iconSize={is_menu ? "sm" : "md"} />,
    () => {
      maybe_route(get_url_for_entity({ id: notif.custom_data }));
    },
    is_menu && initiator?.name ? (
      <div className="notif-item__event-name">{initiator.name}</div>
    ) : undefined,
    !is_menu && notif.initiator ? (
      <NotifEventPreview event={notif.initiator} />
    ) : undefined,
  ];
};

const NotifConnectionRequestButtons: Preact.FunctionComponent<{
  notif: Notification;
  onUpdate: (id: string, notif?: Notification | null) => void;
}> = ({ notif, onUpdate }) => {
  const deleteNotif = useApiMethod("updateNotification");
  const onRemove = useCallback(
    ({ data }: { data: ConnectionRequest | null }) => {
      // @ts-expect-error:
      onUpdate(notif.id, { ...notif, connection_request: data });
      return deleteNotif(notif.id, {
        body: {
          data: {
            attributes: {
              read: true,
              hidden: true,
            },
          },
        },
      }).then(() => {});
    },
    [notif.id, onUpdate]
  );

  const request = useMemo<ConnectionRequest>(
    () => ({
      ...(notif.connection_request as ConnectionRequest),
      sender: notif.initiator,
      recipient: notif.recipient,
    }),
    [notif]
  );

  // @ts-expect-error:
  return notif.connection_request?.accepted !== false ? null : (
    <RemovableListItem
      Component={NotifConnectionRequestButtonsBase}
      delay={1200}
      request={request}
      onRemoveItem={onRemove}
    />
  );
};

const NotifConnectionRequestButtonsBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<
    {
      request: ConnectionRequest;
    },
    { data: ConnectionRequest | null }
  >
> = ({ request, className, onRemoveItem }) => (
  <div
    className={`pill-buttons${maybeClassName(className)}`}
    onMouseUp={e => {
      // @ts-expect-error:
      e.ignoreLinkClick = true;
      e.stopPropagation();
    }}
  >
    <ConnectionRequestIncomingControls
      request={request}
      onComplete={onRemoveItem}
    />
  </div>
);

const NotifFollowupButtons: Preact.FunctionComponent<
  MaybeClass & {
    openModal: (success: boolean) => void;
  }
> = ({ className, openModal }) => (
  <div
    className={`pill-buttons${maybeClassName(className)}`}
    onMouseUp={e => {
      // @ts-expect-error:
      e.ignoreLinkClick = true;
      e.stopPropagation();
    }}
  >
    <button className="filled gray pill" onClick={() => openModal(true)}>
      Yes
    </button>
    <button className="filled gray pill" onClick={() => openModal(false)}>
      No
    </button>
  </div>
);

const NotifRelHealthButtons: Preact.FunctionComponent<
  MaybeClass & {
    onClickYes: () => void;
    onClickNo: () => Promise<void>;
  }
> = ({ className, onClickYes, onClickNo }) => {
  return (
    <div
      className={`pill-buttons${maybeClassName(className)}`}
      onMouseUp={e => {
        // @ts-expect-error:
        e.ignoreLinkClick = true;
        e.stopPropagation();
      }}
    >
      <button className="filled gray pill" onClick={onClickYes}>
        Yes
      </button>
      <button className="filled gray pill" onClick={onClickNo}>
        No
      </button>
    </div>
  );
};

const createPostNotifItem =
  (icon: FontIconName) =>
  (
    notif: Notification,
    on_update: (id: string, notif?: Notification | null) => void,
    is_menu: boolean
  ): [Preact.VNode, () => void, Preact.VNode?] =>
    [
      <Avatar
        user={notif.initiator as User}
        size={is_menu ? "sm" : "md"}
        isLink={!is_menu}
        icon={icon}
      />,
      () => {
        maybe_route(
          `/post-detail/${get_guid_from_iri(notif.custom_data || "")[0]}`
        );
      },
    ];

export const NOTIF_ICON_ACTION_MAP: {
  [K in NotifCustomTypes]: (
    notif: Notification,
    on_update: (id: string, notif?: Notification | null) => void,
    is_menu: boolean,
    on_remove: (id: string, notif?: Notification | null) => Promise<void>
  ) => [
    // icon/element to display for the notification
    Preact.VNode,
    // callback to invoke upon clicking on the notification
    (e?) => void,
    // buttons/controls to display in the notification component
    Preact.VNode?,
    // extra content to display at the bottom of the notification
    Preact.VNode?
  ];
} = {
  view_connection_request: (notif, on_update, is_menu) => [
    <Avatar
      user={notif.initiator as User}
      size={is_menu ? "sm" : "md"}
      isLink={!is_menu}
      icon="connection"
    />,
    () => {
      maybe_route("/people/requests");
      return false;
    },
    <NotifConnectionRequestButtons notif={notif} onUpdate={on_update} />,
  ],
  view_tagged_in_post: createPostNotifItem("amplify"),
  view_reaction_to_post: createPostNotifItem("heart-solid"),
  view_comment_on_post: createPostNotifItem("comment-solid-chat-bubble"),
  view_mood_update: createNotifMoodSelector,
  view_connection_request_accepted: (notif, on_update, is_menu) => [
    <Avatar
      user={notif.initiator as User}
      size={is_menu ? "sm" : "md"}
      isLink={!is_menu}
      icon="connection"
    />,
    () => {
      maybe_route(get_url_for_entity(notif.initiator as User));
    },
  ],
  view_event_invite: createEventNotifItem,
  view_upcoming_event: createEventNotifItem,
  view_canceled_event: createEventNotifItem,
  view_new_group_member: (notif, on_update, is_menu) => [
    <Avatar
      user={notif.initiator as User}
      size={is_menu ? "sm" : "md"}
      isLink={!is_menu}
      icon="connection-request"
    />,
    () => {
      maybe_route(get_url_for_entity({ id: notif.custom_data }));
    },
  ],
  view_degraded_connection_health: (notif, on_update, is_menu, on_remove) => {
    // using hooks here is dangerous, but as long as all uses of NotificationListItem
    // are given a proper `key`, nothing should break
    const dispatch = useContext(CONTEXTS.dispatch);

    const self = useAppUser();
    const [connection] = useSelfConnectionWithUser(
      self,
      notif.initiator || null
    );

    const on_click_response = useCallback(
      (successful?: boolean) => {
        if (!successful) {
          on_remove(notif.id);
          return;
        }
        dispatch({
          type: EVENTS.SET_INTERACTION_DATA,
          payload: {
            // @ts-expect-error:
            user: { id: notif.initiator?.id },
            connection: connection || { id: "" },
            success: true,
            type: "unknown",
            onCreateInteraction: () => on_remove(notif.id),
          },
        });
      },
      [dispatch, notif, connection]
    );

    return [
      <AvatarWithCtx
        // @ts-expect-error:
        user_id={notif.initiator?.id}
        size={is_menu ? "sm" : "md"}
        isLink={!is_menu}
        health={connection?.health_tier}
      />,
      () => {},
      <NotifRelHealthButtons
        onClickYes={on_click_response}
        onClickNo={() => on_remove(notif.id)}
      />,
    ];
  },
  view_preliminary_interaction: (notif, on_update, is_menu, on_remove) => {
    // using hooks here is dangerous, but as long as all uses of NotificationListItem
    // are given a proper `key`, nothing should break
    const dispatch = useContext(CONTEXTS.dispatch);
    const user = notif.initiator;
    const interaction_id = notif.custom_data;
    const open_modal = useCallback(
      (successful?: boolean) => {
        dispatch({
          type: EVENTS.SET_INTERACTION_DATA,
          payload: {
            // @ts-expect-error:
            user,
            connection: { id: "" },
            interaction: { id: interaction_id as string },
            type: "loading",
            success: successful,
            onCreateInteraction: () => on_remove(notif.id),
          },
        });
      },
      [dispatch, notif, on_remove]
    );

    const cancel_open = useCallback(e => {
      e.cancelInteractionModal = true;
    }, []);

    return [
      <Avatar
        size={is_menu ? "sm" : "md"}
        isLink={!is_menu}
        icon="chat-spark-solid-chat-bubble"
        onClick={cancel_open}
        user={notif.initiator as User}
      />,
      e => {
        if (!e?.cancelInteractionModal) {
          open_modal();
        }
      },
      <NotifFollowupButtons openModal={open_modal} />,
    ];
  },
  view_rate_interaction: (notif, on_update, is_menu, on_remove) => {
    return [
      <Avatar
        size={is_menu ? "sm" : "md"}
        isLink={!is_menu}
        icon="amplify"
        // onClick={cancel_open}
        user={notif.initiator as User}
      />,
      () => {},
      <a
        className="button filled pill gray"
        href={notif.custom_data}
        // @ts-expect-error:
        native={true}
        target="_blank"
        onClick={() => on_remove(notif.id)}
      >
        Rate this Interaction
      </a>,
    ];
  },
};
