import * as Preact from "preact";
import { ExperienceCategory } from "@thrive-web/ui-api";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import {
  CheckList,
  DefaultPendingView,
  TAXONOMIES_CTX,
} from "@thrive-web/ui-components";
import {
  add_item_to,
  comparator,
  remove_item_from,
} from "@thrive-web/ui-common";

export const UserUpdateExperiences: Preact.FunctionComponent<{
  value: readonly ExperienceCategory[];
  onChange: (selected: ExperienceCategory[]) => void;
}> = ({ onChange, value }) => {
  const input_ref = useRef<HTMLInputElement>();
  const { value: exp_tx, map: exp_map } = useContext(
    TAXONOMIES_CTX.ExperienceCategory
  );
  const options = useMemo(
    () =>
      exp_tx
        ? exp_tx.map(e => ({ value: e.id, label: e.name || "" }))
        : undefined,
    [exp_tx]
  );

  const selected = useMemo<string[]>(
    () => (value ? value.map(e => e.id) : []),
    [value]
  );
  const value_ref = useRef(value);
  value_ref.current = value;

  const onChangeCheckbox = useCallback(
    (opt, checked) => {
      if (exp_map) {
        onChange(
          checked
            ? add_item_to(value_ref.current, { id: opt.value }).sort(
                comparator(e => exp_map[e.id].display_rank || 0)
              )
            : remove_item_from(value_ref.current, v => v.id === opt.value)
        );
      }
    },
    [onChange, exp_map]
  );

  useLayoutEffect(() => {
    if (!value || value.length < 1) {
      input_ref.current?.setCustomValidity(
        "Please select at least one option from this list."
      );
    } else {
      input_ref.current?.setCustomValidity("");
    }
  }, [value]);

  return options ? (
    <Preact.Fragment>
      <div className="user-profile__update__experiences stack__scrolling-content">
        <CheckList
          onSelectOption={onChangeCheckbox}
          options={options}
          values={selected || []}
        />
      </div>
      <div className="input__hidden">
        <input ref={input_ref} />
      </div>
    </Preact.Fragment>
  ) : (
    <div className="user-profile__update__experiences__loading">
      <DefaultPendingView />
    </div>
  );
};
