import * as Preact from "preact";
import {
  useCallbackRef,
  useResizeObserver,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";

import { maybeClassName } from "@thrive-web/ui-utils";
import { Icon } from "@thrive-web/ui-components";
import { ForwardFn, forwardRef } from "preact/compat";
import { useContext, useEffect, useRef } from "preact/hooks";
import { CONTEXTS } from "@thrive-web/ui-model";

export const withIcon = <P extends object, F extends HTMLElement>(
  Component: ForwardFn<P, F>,
  name?: string
) => {
  if (!Component.displayName) {
    Component.displayName = name;
  }
  const ComponentForwarded = forwardRef(Component);
  const ComponentWithIcon: Preact.FunctionComponent<
    Omit<P, keyof WithIconProps> & MaybeClass & WithIconProps
  > = (
    { children, collapse, className, icon, side, iconOpposite, ...props },
    ref
  ) => {
    const icon_only = children == null || children === "";
    const _icon = (
      <div className="with-icon__icon">
        {typeof icon === "string" ? (
          // @ts-ignore
          <Icon name={icon} />
        ) : (
          icon
        )}
      </div>
    );
    const _icon_opposite =
      !collapse && iconOpposite ? (
        <div className="with-icon__icon">
          {typeof iconOpposite === "string" ? (
            // @ts-ignore
            <Icon name={iconOpposite} />
          ) : (
            iconOpposite
          )}
        </div>
      ) : null;
    return (
      // @ts-ignore
      <ComponentForwarded
        {...props}
        ref={ref}
        className={`with-icon${icon_only ? " icon-only" : ""}${maybeClassName(
          className
        )}${collapse ? " with-icon__collapse" : ""}`}
        data-direction={collapse ? side : undefined}
      >
        {(side === "left" || icon_only) && _icon}
        {side === "right" && _icon_opposite}
        {!icon_only &&
          (collapse ? (
            <CollapseText>{children}</CollapseText>
          ) : (
            <span className="with-icon__text">{children}</span>
          ))}
        {side === "right" && _icon}
        {side === "left" && _icon_opposite}
      </ComponentForwarded>
    );
  };
  ComponentWithIcon.displayName = `withIcon(${Component.displayName})`;
  return forwardRef(ComponentWithIcon);
};

export const ButtonWithIcon = withIcon<HTMLButtonProps, HTMLButtonElement>(
  (props, ref) => <button type="button" ref={ref} {...props} />,
  "ButtonWithIcon"
);
export const LinkWithIcon = withIcon<HTMLAnchorProps, HTMLAnchorElement>(
  (props, ref) => <a ref={ref} {...props} />,
  "LinkWithIcon"
);

export const DivWithIcon = withIcon<HTMLDivProps, HTMLDivElement>(
  (props, ref) => <div ref={ref} {...props} />,
  "DivWithIcon"
);

/** shows icon only by default, and expands to show text when hovered/focused */
export const CollapseText: Preact.FunctionComponent = ({ children }) => {
  const text_ref = useRef<HTMLSpanElement>();
  const width_ref = useRef<number>(0);
  const [width, set_width] = useStateIfMounted(0);
  const update_width = useCallbackRef(() => {
    if (
      text_ref.current &&
      text_ref.current.scrollWidth !== width_ref.current
    ) {
      set_width(text_ref.current.scrollWidth);
    }
  }, [text_ref, set_width]);
  useResizeObserver(text_ref, update_width, true);

  const window_size = useContext(CONTEXTS.window_size);
  useEffect(() => update_width.current?.(), [window_size]);

  return (
    <span
      ref={text_ref}
      style={{ maxWidth: `${width}px`, flexBasis: `${width}px` }}
      className="with-icon__text"
    >
      {children}
    </span>
  );
};
