import type { google } from "google-maps";
import { Loader, LoaderOptions } from "google-maps";
import { stringify } from "query-string";
import { Address, WriteAddress } from "@thrive-web/ui-api";
import { ScreenSize } from "@thrive-web/ui-constants";
const { google: gMapsAuth } = require("../../site/env.json");

const options: LoaderOptions = {
  libraries: ["places"],
};
const loader = new Loader(gMapsAuth.mapsApiKey, options);
export const gMaps: {
  api: google | null;
} = {
  api: null,
};

export const STATIC_MAP_API_URL =
  "https://maps.googleapis.com/maps/api/staticmap";

export const MAPS_LINK_BASE_URL = "https://www.google.com/maps/search/";

export const STATIC_IMG_SIZE_MAP: { [K in ScreenSize]: number } = {
  360: 288, // xxs
  480: 288, // xs
  768: 500, // sm
  1024: 640, // md
  1390: 276, // lg
  1920: 276, // xl
  1921: 276, // xxl
};

export const PLACES_INCLUDED_FIELDS = [
  "address_components",
  "formatted_address",
  "name",
  "place_id",
  "geometry.location",
];

export const PLACES_ADDRESS_COMPONENTS_MAP: {
  [K: string]: {
    key: keyof Omit<WriteAddress, "id" | "type">;
    use_name: "short_name" | "long_name";
  };
} = {
  country: {
    key: "country_code",
    use_name: "long_name",
  },
  administrative_area_level_1: {
    key: "administrative_area",
    use_name: "short_name",
  },
  locality: {
    key: "locality",
    use_name: "long_name",
  },
  postal_code: {
    key: "postal_code",
    use_name: "long_name",
  },
  /*route: {
    key: "route",
    use_name: "short_name",
  },
  street_number: {
    key: "street_number",
    use_name: "long_name",
  },*/
};

// map google PlaceResult to our Address record
export const map_place_to_address = (
  place: google.maps.places.PlaceResult
): WriteAddress => {
  const { address_components = [], name, formatted_address, geometry } = place;
  const address: WriteAddress = {
    common_name: name,
    formatted_address,
    lat: geometry?.location?.lat(),
    lng: geometry?.location?.lng(),
  };
  address_components.forEach(comp => {
    for (let type of comp.types) {
      const mapping = PLACES_ADDRESS_COMPONENTS_MAP[type];
      if (mapping) {
        // @ts-expect-error:
        address[mapping.key] = comp[mapping.use_name];
        return;
      }
    }
  });
  Object.values(PLACES_ADDRESS_COMPONENTS_MAP).forEach(c => {
    if (!(c.key in address)) {
      // @ts-expect-error:
      address[c.key] = null;
    }
  });

  return address;
};

// format params for the static map api query
export const get_static_map_params_from_address = (
  location: Address,
  window_size: ScreenSize
) => {
  const center =
    location.lat && location.lng
      ? [location.lat, location.lng]
      : location.formatted_address || "";
  const params: ObjectOf<string | number | string[] | number[]> = {
    center,
    size: `${STATIC_IMG_SIZE_MAP[window_size]}x${STATIC_IMG_SIZE_MAP[window_size]}`,
    scale: window_size < ScreenSize.sm ? 2 : 1,
    key: gMapsAuth.mapsApiKey,
    zoom: 14,
    markers: `color:red|${
      typeof center === "string" ? center : center.join(",")
    }`,
  };
  return stringify(params, { arrayFormat: "comma" });
};

export const get_map_link_params_from_address = (location: Address) => {
  const { common_name, formatted_address, lat, lng } = location;
  let query: any = ``;
  const latlng = `${lat},${lng}`;
  if (common_name) {
    query += common_name;
    if (formatted_address) {
      query += `, ${formatted_address}`;
    }
  } else if (formatted_address) {
    query = formatted_address;
  } else {
    query = latlng;
  }

  const params: ObjectOf<string | number | string[] | number[]> = {
    api: 1,
    query,
    center: latlng,
  };
  return stringify(params).replace(/%20/g, "+");
};

const loaded = { loaded: false };
if (!loaded.loaded) {
  loaded.loaded = true;
  console.log(`loading`);
  loader
    .load()
    .then(places => {
      console.debug(`Initialized Google Maps API:`, places);
      gMaps.api = places;
    })
    .catch(err => {
      console.error(`Failed to initialize Google Maps API:`, err);
    });
}
