import {
  Avatar,
  AvatarLoading,
  ButtonWithIcon,
  Card,
  DivWithIcon,
  DropdownMenu,
  Icon,
  Tag,
  GROUP_DETAIL_LIST_CONTEXTS,
} from "@thrive-web/ui-components";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { Goal, Todo, User } from "@thrive-web/ui-api";
import { display_text, get_guid_from_iri } from "@thrive-web/ui-utils";
import { is_id_obj, moment } from "@thrive-web/ui-common";
import { GroupTodoCompleteToggle } from "./GroupTodoCompleteToggle";

export const GroupTodoListItem: Preact.FunctionComponent<{
  todo: Todo;
  editTodo: (target: Todo) => void;
  updateRecord: (target: Todo) => void;
  deleteTodo: (target: Todo) => void;
}> = ({ todo, editTodo, deleteTodo, updateRecord }) => {
  const goals = useContext(GROUP_DETAIL_LIST_CONTEXTS.goals).list;
  const members = useContext(GROUP_DETAIL_LIST_CONTEXTS.members).list;

  const goal: Goal | undefined = useMemo(() => {
    if (!todo.goal) {
      return;
    }
    if (!is_id_obj(todo.goal)) {
      return todo.goal;
    }
    return goals?.find(g => g.id === todo.goal?.id);
  }, [todo.goal, goals]);

  const assignee: User | undefined = useMemo(
    () =>
      members && todo.assigned_to
        ? members.find(u => u.id === todo.assigned_to?.id)
        : undefined,
    [members, todo.assigned_to?.id]
  );

  const edit_todo = useCallback(() => editTodo(todo), [editTodo, todo]);
  const delete_todo = useCallback(() => deleteTodo(todo), [deleteTodo, todo]);

  const overdue = Date.now() > new Date(todo.due_date || "").getTime();
  return todo ? (
    <li className="todo-list__item" id={get_guid_from_iri(todo.id)[0]}>
      <Card>
        <div className="todo-list__item__icon">
          <GroupTodoCompleteToggle todo={todo} />
        </div>
        <div className="todo-list__item__content">
          <div className="todo-list__item__text">
            {display_text(todo.description)}
          </div>
          <div className="todo-list__item__details">
            {assignee && (
              <DivWithIcon
                className="todo-list__item__assignee"
                side="left"
                icon={<Avatar user={assignee} size="xs" isLink={true} />}
              >
                {assignee.full_name}
              </DivWithIcon>
            )}
            {todo.due_date && (
              <div className="todo-list__item__due" data-overdue={overdue}>
                {overdue ? "Overdue for" : "Due"}{" "}
                {moment(todo.due_date).format("MMMM D")}
              </div>
            )}
          </div>
          {goal && (
            <div className="todo-list__item__goal">
              <Tag icon="goal" iconSize="md">
                {display_text(goal.name)}
              </Tag>
            </div>
          )}
        </div>
        <div className="todo-list__item__actions">
          <DropdownMenu
            items={[
              <ButtonWithIcon
                icon="remove"
                side="left"
                className="filled pill gray"
                onClick={delete_todo}
              >
                Delete To-Do
              </ButtonWithIcon>,
              <ButtonWithIcon
                icon="edit"
                side="left"
                onClick={edit_todo}
                className="filled pill gray"
              >
                Edit To-Do
              </ButtonWithIcon>,
            ]}
            buttonClassName="filled with-icon icon-only transparent all-gray actions"
            listClassName="card pill-card"
          />
        </div>
      </Card>
    </li>
  ) : null;
};

export const GroupTodoListItemLoading: Preact.FunctionComponent = () => {
  const title_width = useMemo(
    () => ({
      width: `min(${Math.round(Math.random() * 10) + 6}rem, 100%)`,
    }),
    []
  );
  return (
    <li className="todo-list__item loading-item">
      <Card>
        <div className="todo-list__item__icon">
          <Icon name="broken-circle" />
        </div>
        <div className="todo-list__item__content">
          <div className="todo-list__item__text">
            <div
              className="loading-item__title loading-item__shaded"
              style={title_width}
            />
          </div>
          <div className="todo-list__item__details">
            <DivWithIcon
              className="todo-list__item__assignee"
              side="left"
              icon={<AvatarLoading size="xs" />}
            >
              <div className="loading-item__name loading-item__text loading-item__shaded" />
            </DivWithIcon>
            <div className="todo-list__item__due">
              <div className="loading-item__text loading-item__shaded" />
            </div>
          </div>
          <div className="todo-list__item__goal">
            <Tag className="loading-item__shaded" icon="goal" iconSize="md">
              <div className="loading-item__text loading-item__shaded--white" />
            </Tag>
          </div>
        </div>
      </Card>
    </li>
  );
};
