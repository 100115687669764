import { path_or } from "@thrive-web/ui-common";
import { closestAncestor } from "@thrive-web/ui-utils";

// set the field id for our custom zendesk report field
export const set_zendesk_report_field_id = (value: any) => {
  window["zE-thread"] = {
    ZENDESK_REPORT_FIELD_ID: value,
  };
};
// get the field id for our custom zendesk report field
export const get_zendesk_report_field_id = () =>
  path_or(undefined, ["zE-thread", "ZENDESK_REPORT_FIELD_ID"], window);

// open the support widget modal, and prefill the form with the given data
export const open_zendesk_widget = (data: ObjectOf<any>) => {
  if (!window.zE) {
    return;
  }
  window.zE("webWidget", "prefill", data);
  window.zE("webWidget", "open");
  window.zE("webWidget", "show");
  setTimeout(() => {
    // hide the report id field so that the user can't edit it
    const field_id = get_zendesk_report_field_id();
    try {
      hide_zendesk_field(`key:${field_id}`);
    } catch (err) {
      setTimeout(() => {
        try {
          hide_zendesk_field(`key:${field_id}`);
        } catch (err) {
          console.warn(
            `Failed to hide report id field in Zendesk support form.`
          );
        }
      }, 150);
    }
  }, 150);
  const container = document.querySelector(
    "iframe[data-product='web_widget'] + div"
  );
  if (container) {
    (container as HTMLDivElement).dataset.open = "true";
  }
};

export const close_zendesk_widget = () => {
  if (!window.zE) {
    return;
  }
  window.zE("webWidget", "close");
  window.zE("webWidget", "reset");
};

const hide_zendesk_field = name => {
  const widget = document.getElementById(
    "webWidget"
  ) as HTMLIFrameElement | null;

  if (widget) {
    const input = widget.contentDocument?.querySelector(
      `input[name="${name}"]`
    ) as HTMLInputElement | null;
    if (input) {
      input.setAttribute("readonly", "true");
      input.setAttribute("type", "hidden");
      const parent = closestAncestor(input, "[data-garden-id='forms.field']");
      const label = parent?.querySelector("label");
      if (label) {
        label.setAttribute("style", "none !important");
      }
      if (parent?.parentElement) {
        parent.parentElement.setAttribute(
          "style",
          `
            margin: 0 !important;
            padding: 0 !important;
            width: 1px !important;
            height: 1px !important;
            opacity: 0 !important;
            z-index: -9999 !important;
            pointer-events: none !important;
            overflow: hidden !important;
            position: absolute !important;
          `
        );
        return;
      }
    }
  }
  throw new Error("Failed to find elements");
};

export const prefill_zendesk_report_id = (id?: string, subject?: string) => {
  if (!window.zE) {
    return;
  }
  window.zE("webWidget", "updateSettings", {
    webWidget: {
      contactForm: {
        fields: [
          { id: get_zendesk_report_field_id(), prefill: { "*": id || "" } },
          { id: "subject", prefill: { "*": subject || "" } },
        ],
      },
    },
  });
};

window.zE?.("webWidget:on", "close", () => {
  const container = document.querySelector(
    "iframe[data-product='web_widget'] + div"
  );
  if (container) {
    (container as HTMLDivElement).dataset.open = "false";
  }
  setTimeout(() => window.zE?.("webWidget", "hide"), 100);
  prefill_zendesk_report_id();
});
