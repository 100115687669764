import * as Preact from "preact";
import { useEffect, useMemo } from "preact/hooks";
import { parse } from "query-string";
import authWrapper from "~/auth/auth";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  Card,
  DefaultErrorView,
  DefaultPendingView,
  useGroupJoin,
} from "@thrive-web/ui-components";
import { useAppUser, useDocumentTitle, useRequest } from "@thrive-web/ui-hooks";
import {
  get_url_for_entity,
  make_title,
  maybe_route,
} from "@thrive-web/ui-utils";

export const GROUP_INVITE_STORAGE_KEY = "group-invite-id";

export const GroupInviteJoin: Preact.FunctionComponent<RoutePageProps> = () => {
  const self = useAppUser();
  const params = useMemo(() => parse(window.location.search || ""), []);
  const [join_group, { error }] = useRequest(useGroupJoin(null, null));

  useEffect(() => {
    if (!params || !params.id) {
      maybe_route("/", true);
    }
    const id = decodeURIComponent(params.id as string);
    const invitation = decodeURIComponent((params.invitation as string) || "");
    if (!self) {
      return;
    }
    join_group({ id }, self, invitation).then(() => {
      const url = get_url_for_entity({ id });
      if (!self.has_group_membership) {
        authWrapper.refreshApiUser(url);
      } else {
        maybe_route(url, true);
      }
    });
  }, []);

  return error ? <DefaultErrorView error={error} /> : <DefaultPendingView />;
};

export const GroupInviteJoinSite: Preact.FunctionComponent = () => {
  const params = useMemo(() => parse(window.location.search || ""), []);
  useDocumentTitle(() => make_title([`Join ${params.group_name}`]), []);
  useEffect(() => {
    if (!params || !params.id) {
      maybe_route("/", true);
    }
    localStorage.setItem(
      GROUP_INVITE_STORAGE_KEY,
      JSON.stringify({
        id: decodeURIComponent(params.id as string),
        invitation: decodeURIComponent((params.invitation as string) || ""),
      })
    );
  }, []);

  return (
    <div className="site">
      <fieldset id="site-main">
        <div className="site__scroll-override-container">
          <div className="row site-body">
            <div className="group-invite-page">
              <Card>
                <div className="login-page__logo">
                  <img src={`${IMAGES_PREFIX}/logo_thread.svg`} />
                </div>
                <div className="group-invite-page__text">
                  {params.user_name} invites you to join{" "}
                  <strong>{params.group_name}</strong>
                </div>
                <div className="group-invite-page__buttons">
                  <a href="/register" className="button filled">
                    Join this group now
                  </a>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};
