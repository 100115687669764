import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { Address } from "@thrive-web/ui-api";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  get_map_link_params_from_address,
  get_static_map_params_from_address,
  MAPS_LINK_BASE_URL,
  STATIC_MAP_API_URL,
} from "~/utils";

// takes an Address record and uses its fields to display an image from the
// static maps api
export const StaticMap: Preact.FunctionComponent<{ location: Address }> = ({
  location,
}) => {
  const window_size = useContext(CONTEXTS.window_size);
  const src = useMemo(
    () => get_static_map_params_from_address(location, window_size),
    [location, window_size]
  );

  const href = useMemo(
    () => `${MAPS_LINK_BASE_URL}?${get_map_link_params_from_address(location)}`,
    [location]
  );

  return (
    <a className="static-map" href={href} target="_blank">
      <img
        src={`${STATIC_MAP_API_URL}?${src}`}
        alt={`Map view of ${location.common_name} at ${location.formatted_address}`}
      />
    </a>
  );
};
