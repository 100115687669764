import * as Preact from "preact";
import {
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
} from "@thrive-web/ui-components";
import { Post } from "@thrive-web/ui-api";
import { createNamedContext } from "@thrive-web/ui-utils";

export const HOME_POSTS: Preact.Context<DynamicListCtxSpec<Post, false, []>> =
  createNamedContext(
    {
      list: [],
      dispatch: DynamicListDispatchDefault,
    },
    "HomePosts"
  );

export const HOME_POSTS_SEARCH: Preact.Context<
  DynamicListCtxSpec<Post, false, []>
> = createNamedContext(
  {
    list: [],
    dispatch: DynamicListDispatchDefault,
  },
  "HomePostsSearch"
);
