import * as Preact from "preact";
import {
  ApiMethodParameters,
  MappedApiResponse,
  TraitifyAssessment,
} from "@thrive-web/ui-api";
import {
  UserProfilePrivacyIndicator,
  makeProfileBuilderModalBody,
  ProfileBuilderStrengthsSurvey,
  UserFeedback,
} from "~/view/components";
import {
  AsyncRender,
  Card,
  Icon,
  RequestButtonWithIcon,
  TraitifySurveyResultsButtons,
  useAsyncRender,
} from "@thrive-web/ui-components";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import {
  preserveProps,
  useApiCall,
  useAppUser,
  useDynamicListVariable,
  useModal,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { analytics, comparator, path_or } from "@thrive-web/ui-common";

export const UserStrengthsContent: Preact.FunctionComponent<{
  data?: TraitifyAssessment[];
}> = ({ data }) => {
  const aggregated = useMemo<TraitifyAssessment | null>(() => {
    if (!data || data.length === 0) {
      return null;
    }
    if (data.length === 1) {
      return data[0];
    }

    // dict for each personality type with the scores from each assessment
    const all_results: { [k: string]: any[] } = {};
    data.forEach(r => {
      const result_set = path_or([], ["assessment", "personality_types"], r);
      result_set.forEach(p => {
        if (!all_results[p.personality_type.name]) {
          all_results[p.personality_type.name] = [];
        }
        all_results[p.personality_type.name].push(p);
      });
    });

    // take average of all scores for each type and sort
    const avg_results = Object.values(all_results)
      .map(list => {
        const avg =
          list.reduce((score, res) => score + res.score, 0) / list.length;
        return {
          personality_type: list[0].personality_type,
          score: avg,
          standard_score: avg,
        };
      })
      .sort(comparator("score", "desc"))
      .map((p, i) => ({ ...p, rank: i + 1 }));

    const latest = data[0];
    return {
      ...latest,
      assessment: { ...latest.assessment, personality_types: avg_results },
    } as TraitifyAssessment;
  }, [data]);

  return !aggregated ? null : (
    <TraitifySurveyResultsButtons data={aggregated} />
  );
};

export const UserStrengths: Preact.FunctionComponent<{
  userId: string;
  showRetake?: boolean;
}> = ({ userId, showRetake }) => {
  const self = useAppUser();
  const params = useMemo<ApiMethodParameters<"getSelfSurveys">[0]>(
    () => ({
      query: {
        sort: [{ by: "completed_at", dir: "desc" }],
        filter: [
          ["=", ["this", "TraitifyAssessment:taken_by"], ["id", userId]],
          ["exists", ["this", "TraitifyAssessment:completed_at"]],
        ],
      },
    }),
    [userId]
  );

  const [retake_results, set_retake_results] =
    useDynamicListVariable<TraitifyAssessment>([]);

  const on_finish_retake = useCallback(
    (res: TraitifyAssessment) => {
      set_retake_results.add(res);
    },
    [set_retake_results]
  );

  const [SuccessView, getAssessment] = useAsyncRender(
    result => (
      <UserStrengthsContent data={result.data.concat(retake_results || [])} />
    ),
    [retake_results],
    "getSelfSurveys",
    params
  );

  if (!self) {
    return null;
  }

  return (
    <Card className="user-profile__card user-profile__strengths">
      <h3 className="user-profile__card__title">Strengths</h3>
      {self.id === userId && (
        <UserProfilePrivacyIndicator level="public">
          <h4>Your Results</h4>
        </UserProfilePrivacyIndicator>
      )}
      <div className="user-profile__strengths__results">
        <AsyncRender
          getPromise={getAssessment}
          PendingView={UserStrengthsLoading}
        >
          {SuccessView}
        </AsyncRender>
        {showRetake && (
          <UserStrengthsRetake userId={userId} onFinish={on_finish_retake} />
        )}
      </div>
      {self.id === userId && <UserFeedback userId={userId} />}
    </Card>
  );
};

export const UserStrengthsLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(3).fill(0), []);

  return (
    <div className="traitify-survey__result__list user-profile__strengths user-profile__strengths__loading">
      {items.map((_, i) => (
        <div key={i} className="traitify-survey__result__button">
          <div className="traitify-survey__result__icon">
            <Icon
              name="help-outline-circle"
              className="loading-item__shaded loading-item"
            />
          </div>
          <div className="traitify-survey__result__name loading-item__text loading-item__shaded" />
        </div>
      ))}
    </div>
  );
};

export const UserStrengthsRetake: Preact.FunctionComponent<{
  userId: string;
  onFinish: (result: TraitifyAssessment) => void;
}> = ({ userId, onFinish }) => {
  const [getExistingAssessment, { pending }] = useApiCall("getSelfSurveys");
  const [assessment, set_assessment] = useStateIfMounted<
    TraitifyAssessment | undefined
  >(undefined);

  const [latestResult, setResult] =
    useStateIfMounted<null | TraitifyAssessment>(null);

  useEffect(() => {
    if (latestResult) onFinish(latestResult);
  }, [latestResult]);

  useEffect(() => {
    // get all existing assessments taken by the current user
    getExistingAssessment({
      query: {
        filter: [
          ["=", ["this", "TraitifyAssessment:taken_by"], ["id", userId]],
        ],
        sort: [
          { by: "created_at", dir: "desc" },
          { by: "completed_at", dir: "desc" },
        ],
      },
    })
      .then((result: MappedApiResponse<"getSelfSurveys">) => {
        setResult(
          // @ts-expect-error:
          result.data.find(a => a.assessment?.status === "complete") || null
        );
      })
      .catch(() => {});
  }, []);

  const body_props = useMemo(
    () => ({
      onFetchNewAssessment: set_assessment,
      assessment: assessment || null,
    }),
    [set_assessment, assessment]
  );

  const onFinish_ = useCallback(
    (result: TraitifyAssessment) => {
      analytics.log_event(analytics.EVENTS.strengths_survey_complete);
      onFinish(result);
    },
    [onFinish]
  );
  const strengthsModalBody = makeProfileBuilderModalBody(
    "Identify Strengths",
    ProfileBuilderStrengthsSurvey,
    onFinish_,
    preserveProps(body_props)
  );
  const modalProps = useMemo(
    () => ({
      id: "profile-builder-strengths",
      className: "profile-builder__modal profile-builder__strengths",
      dismissOnClickBackdrop: false,
      showCloseButton: !!latestResult,
      body: strengthsModalBody,
    }),
    [latestResult, strengthsModalBody]
  );

  const [strengthsModal, setStrengthsModalOpen] = useModal(
    modalProps,
    undefined,
    true
  );
  const openModal = useCallback(() => {
    setStrengthsModalOpen(true);
    analytics.log_event(analytics.EVENTS.retake_strengths_survey);
  }, [setStrengthsModalOpen]);

  return (
    <Preact.Fragment>
      <RequestButtonWithIcon
        icon="jump-ahead"
        side="left"
        className="filled gray user-profile__strengths__retake"
        onClick={openModal}
        pending={pending}
        type="button"
      >
        Retake Strengths Assessment
      </RequestButtonWithIcon>
      {strengthsModal}
    </Preact.Fragment>
  );
};
