import * as Preact from "preact";
import { DivWithIcon } from "@thrive-web/ui-components";
import { Mood } from "@thrive-web/ui-api";
import { display_text } from "@thrive-web/ui-utils";

export const PostMood: Preact.FunctionComponent<{
  mood: Mood;
  onClick?: () => void;
}> = ({ mood, onClick }) => {
  return (
    <div className="post__mood">
      <DivWithIcon
        className="pill filled gray static"
        icon={
          <span className="post__mood__emoji">{display_text(mood.symbol)}</span>
        }
        side="left"
        onClick={onClick}
      >
        Feeling <strong>{mood.title}</strong>
      </DivWithIcon>
    </div>
  );
};
