import { getAppStorage } from "@thrive-web/ui-common/src/local-storage";
import * as firebase from "firebase/app";
import "firebase/auth";
import { EVENTS, AppAuthCtx, AppUser, Dispatch } from "@thrive-web/ui-model";
import { awaitTimeout } from "@thrive-web/ui-common";

export class AuthWrapper {
  constructor(config, verifyAuthStateAndRoute) {
    this.firebaseConfig = config;
    this.apiUser = null;
    this.initialized = false;
    // this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.verifyAuthStateAndRoute = verifyAuthStateAndRoute;
  }

  private firebaseConfig: any;
  protected firebaseUser?: firebase.User | null;
  private apiUser: AppUser | null;
  private googleProvider: firebase.auth.GoogleAuthProvider;
  private initialized: boolean;
  private dispatch?: Dispatch;
  private verifyAuthStateAndRoute: (
    auth: AppAuthCtx,
    url: string
  ) => Promise<string | undefined>;
  private loginErrorPromise?: Promise<void>;
  private groupJoinParams?: any;

  // @ts-ignore
  private unsubscribe = () => {
    console.debug(`default unsubscribe function not overwritten`);
  };

  protected set_firebase_user = (r: firebase.User | null) => {
    this.firebaseUser = r;
  };

  private clear_firebase_user = () => {
    if (this.firebaseUser) {
      delete this.firebaseUser;
    }
  };

  private clear_api_user = () => {
    if (this.apiUser) {
      delete this.apiUser;
    }
  };

  private on_auth_changed = async (user?: firebase.User | null) => {
    if (user) {
      this.set_firebase_user(user);
    } else if (this.firebaseUser || this.apiUser) {
      this.clear_firebase_user();
      this.clear_api_user();
    }
    if (!this.initialized) {
      this.initialized = true;
      return;
    }
    this.verifyAuthStateAndRoute(this.getAuthState(), window.location.pathname)
      .then(new_route => {
        this.resolve_login_promise();
        this.dispatch &&
          this.dispatch({
            type: EVENTS.UPDATE_AUTH_CTX,
            payload: {
              ...this.getAuthState(),
              route: new_route as string | undefined,
            },
          });
        this.groupJoinParams = undefined;
      })
      .catch(this.reject_login_promise);
  };

  private on_auth_changed_error = (error: firebase.auth.Error) => {
    console.error(`auth change failed:`, error);
    throw error;
  };

  private resolve_login_promise = () => {};
  private reject_login_promise = (err?) => {};

  private create_login_promise = () => {
    this.loginErrorPromise = new Promise((resolve, reject) => {
      this.resolve_login_promise = () => {
        resolve();
        this.resolve_login_promise = () => {};
      };
      this.reject_login_promise = err => {
        reject(err);
        this.logout();
        this.reject_login_promise = () => {};
      };
    });
  };

  /* public methods */

  // initialize firebase with app config, and register observer func for auth changes
  initialize = (): Promise<AppAuthCtx> =>
    new Promise((resolve, reject) => {
      firebase.initializeApp(this.firebaseConfig.auth);
      // this.googleProvider = new firebase.auth.GoogleAuthProvider();
      this.unsubscribe = firebase.auth().onAuthStateChanged(user => {
        if (this.initialized) {
          return this.on_auth_changed(user);
        }
        this.set_firebase_user(user);
        resolve(this.getAuthState());
        this.initialized = true;
      }, this.on_auth_changed_error);
    });

  // store the dispatcher for updating the global preact context
  registerDispatch = (dispatch: Dispatch) => {
    this.dispatch = dispatch;
  };

  setApiUser = (r: AppUser | null, trigger_on_change: boolean = true) => {
    console.log(`Logged in as:`, r);
    this.apiUser = r;
    trigger_on_change && this.on_auth_changed(this.firebaseUser);

    getAppStorage().then(store => {
      if (!this.apiUser) {
        store.destroy();
        return;
      }
      if (store.user_id && store.user_id !== this.apiUser.id) {
        store.destroy();
      }
      store.initialize(this.apiUser);
    });
  };

  getAuthState = (): AppAuthCtx => {
    const currentUser = firebase.auth().currentUser;
    this.firebaseUser = currentUser;
    return {
      user: this.apiUser,
      firebaseUser: currentUser,
      userProfileBuilderComplete:
        !!this.apiUser?.has_completed_profile &&
        !!this.apiUser?.has_completed_identities,
      hasGroups: !!this.apiUser?.has_group_membership,
      hasGroupInvitation: this.groupJoinParams,
    };
  };

  // get API auth token
  getToken = (force_refresh?: boolean): Promise<string | null> =>
    this.firebaseUser
      ? this.firebaseUser.getIdToken(force_refresh)
      : Promise.resolve(null);

  getFirebaseUserData = (): {
    email: string;
    firebase_uuid: string;
  } | null =>
    this.firebaseUser && this.firebaseUser.email
      ? {
          email: this.firebaseUser.email,
          firebase_uuid: this.firebaseUser.uid,
        }
      : null;

  login = async (
    email: string,
    password: string,
    group_join_params?: any
  ): Promise<firebase.auth.UserCredential> => {
    this.groupJoinParams = group_join_params;
    this.create_login_promise();
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  signInWithGoogle = async (): Promise<firebase.auth.UserCredential> =>
    firebase.auth().signInWithPopup(this.googleProvider);

  logout = async (): Promise<void> => {
    window["zEAuthenticateWithJwt"] = () => {};
    window["zEAuthLogout"] && window["zEAuthLogout"]();
    return firebase
      .auth()
      .signOut()
      .then(() => {});
  };

  // create a firebase user account
  register = async <T>(
    username,
    password,
    // function that creates a user account in our api/db
    then: () => Promise<T>
  ): Promise<T> => {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
      .catch(error => error);
    if (!result.user) {
      return Promise.reject(
        result.code && result.message
          ? result
          : { code: -1, message: "Failed to create user" }
      );
    }

    await awaitTimeout(
      // make sure we have a valid token
      (resolve, reject) => this.refreshToken().then(resolve).catch(reject),
      100
    );

    // try to create an user record in our db
    const t_result = await then().catch(error =>
      // if it fails, delete the new firebase account
      firebase
        .auth()
        .currentUser?.delete()
        .then(() => error)
    );

    if (!t_result.data) {
      return Promise.reject(t_result);
    }

    // send verification email to new firebase account email
    await awaitTimeout(
      (resolve, reject) =>
        result.user
          .sendEmailVerification({
            url: this.firebaseConfig.api.continueUrl,
          })
          .then(resolve)
          .catch(reject),
      100
    );

    return t_result;
  };

  checkEmailVerified = async () => {
    return await this.refreshToken().then(() =>
      firebase
        .auth()
        .currentUser?.reload()
        .then(() => firebase.auth().currentUser?.emailVerified)
    );
  };

  resendVerificationEmail = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      return await user.sendEmailVerification({
        url: this.firebaseConfig.api.continueUrl,
      });
    }
    console.error("No user logged in");
  };

  forgotPassword = async (email): Promise<void> => {
    if (!this.initialized) {
      firebase.initializeApp(this.firebaseConfig.auth);
      this.initialized = true;
    }
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch(r => {
        console.error("sendPasswordResetEmail error:", r);
        throw r;
      });
  };

  getUserWithTempToken = async (email, token): Promise<string> => {
    return firebase
      .auth()
      .verifyPasswordResetCode(token)
      .then((r: string) => {
        console.log(`get_user_with_temp_token result`, r);
        return r;
      })
      .catch(error => {
        console.error(`get_user_with_temp_token error:`, error);
        throw error;
      });
  };

  setPassword = async (id, password, token): Promise<void> => {
    return firebase
      .auth()
      .confirmPasswordReset(token, password)
      .catch(error => {
        console.error(`firebase set_password failed:`, error);
        throw error;
      });
  };

  // re-pull the api user record
  refreshApiUser = (route?: string): Promise<AppUser | null> => {
    const auth_state = this.getAuthState();
    auth_state.user = null;
    return this.verifyAuthStateAndRoute(
      auth_state,
      route || window.location.pathname
    ).then(new_route => {
      this.dispatch &&
        this.dispatch({
          type: EVENTS.UPDATE_AUTH_CTX,
          payload: {
            ...this.getAuthState(),
            route: new_route || route,
          },
        });
      return this.apiUser;
    });
  };

  checkExistingEmail = (email: string): Promise<boolean> => {
    return firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then(result => result.length > 0);
  };

  changeEmailAddress = (new_email: string): Promise<void> => {
    const current_user = firebase.auth().currentUser;
    if (!current_user) {
      return Promise.reject("Not logged in");
    }
    return current_user.updateEmail(new_email);
  };

  changePassword = (cur_pass: string, new_pass: string): Promise<void> => {
    const email = this.firebaseUser?.email || this.apiUser?.email;
    if (!email) {
      return Promise.reject("Not logged in");
    }
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, cur_pass)
      .then(() => {
        const current_user = firebase.auth().currentUser;
        if (!current_user) {
          return Promise.reject(
            "Failed to reauthenticate before changing password"
          );
        }
        return current_user.updatePassword(new_pass);
      });
  };

  refreshToken = async () => await this.getToken(true);

  awaitLoginPromise = () => {
    if (!this.loginErrorPromise) {
      return Promise.reject({
        code: "auth/no-login-promise",
        message: "No login request in progress.",
      });
    }
    return this.loginErrorPromise.finally(() => {
      this.loginErrorPromise = undefined;
    });
  };
}
