import * as Preact from "preact";
import { Community } from "@thrive-web/ui-api";
import { CommunityDetailOuter } from "~/view/components";
import { DefaultPendingView, WithCache } from "@thrive-web/ui-components";

const CommunityDetailLoading: Preact.FunctionComponent<{ data: Community }> = ({
  data: community,
}) => {
  return (
    <CommunityDetailOuter community={community}>
      <DefaultPendingView />
    </CommunityDetailOuter>
  );
};

const CommunityDetailLoadingWithData = WithCache<{}, Community>(
  CommunityDetailLoading,
  DefaultPendingView
);

export const CommunityDetailLoadingPage = (
  id: string
): Preact.FunctionComponent => {
  const Comp: Preact.FunctionComponent = () => (
    <CommunityDetailLoadingWithData cache_key={id} />
  );
  Comp.displayName = "CommunityDetailLoadingPage";
  return Comp;
};
