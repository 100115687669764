import * as Preact from "preact";
import { WriteUser } from "@thrive-web/ui-api";
import { useForm } from "@thrive-web/ui-hooks";
import { useCallback } from "preact/hooks";
import {
  InputWithFormHelpers,
  WithFloatingTitle,
} from "@thrive-web/ui-components";

export const RegisterNameForm: Preact.FunctionComponent<{
  data: WriteUser;
  onFinish: (data: WriteUser) => void;
}> = ({ onFinish, data: { first_name, last_name } }) => {
  const [formData, , onChangeInput] = useForm<WriteUser>({
    first_name,
    last_name,
  });

  const onSubmit = useCallback(
    e => {
      if (formData.first_name?.trim() && formData.last_name?.trim()) {
        e.preventDefault();
        onFinish({
          first_name: formData.first_name.trim(),
          last_name: formData.last_name.trim(),
        });
      }
    },
    [onFinish, formData]
  );

  return (
    <div className="form__section register__form__section" data-part="name">
      <div className="form__section-header login-page__form__title">
        What's your name?
      </div>
      <div className="info">
        Using your real name makes it easier for people to know it’s you.
      </div>
      <form onSubmit={onSubmit}>
        <div className="form__input-row">
          <WithFloatingTitle title="First Name">
            <InputWithFormHelpers
              name="first_name"
              placeholder="First Name"
              required={true}
              onChange={onChangeInput("first_name")}
              autoFocus={true}
              value={formData.first_name}
              submitOnEnter={true}
              controlled={true}
            />
          </WithFloatingTitle>
        </div>
        <div className="form__input-row">
          <WithFloatingTitle title="Last Name">
            <InputWithFormHelpers
              placeholder="Last Name"
              name="last_name"
              required={true}
              onChange={onChangeInput("last_name")}
              value={formData.last_name}
              submitOnEnter={true}
              controlled={true}
            />
          </WithFloatingTitle>
        </div>
        <div className="form__footer">
          <button className="filled" type="submit" onClick={onSubmit}>
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};
