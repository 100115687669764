import { Group } from "@thrive-web/ui-api";
import { analytics } from "@thrive-web/ui-common";
import { ScreenSize } from "@thrive-web/ui-constants";
import { CONTEXTS } from "@thrive-web/ui-model";
import * as Preact from "preact";
import {
  GroupList,
  GroupListLoading,
  GroupDiscover,
  GroupManage,
  GroupSearch,
  GroupSearchBar,
} from "~/view/components";
import {
  GroupCreateModal,
  useFetchGroups,
  PageContent,
  PageHeader,
  LinkWithIcon,
  PageBody,
  useRenderDynamicListWithPagedFetch,
  GROUPS,
  TabLinks,
  asSubroute,
  useSiteHeaderContent,
  usePermCheck,
  NotFoundPageRoute,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useDocumentTitle,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { make_title, maybe_route } from "@thrive-web/ui-utils";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";

// display list of groups that the user is a member of
const GroupsPageBase: Preact.FunctionComponent<RoutePageProps> = () => {
  const user = useAppUser();
  const { list, dispatch } = useContext(GROUPS);
  const fetchGroups = useFetchGroups(user);

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem) => (
      <GroupList
        key="list"
        groups={result}
        loadMoreElem={load_more_elem}
        showLatestActivity={true}
      />
    ),
    [],
    fetchGroups,
    undefined,
    { PendingView: GroupListLoading, limit: 15 }
  );
};

const GroupsPage = asSubroute(GroupsPageBase);

const modal_path = { hasModalPath: "/groups/search" };

export const Groups: Preact.FunctionComponent<RoutePageProps & { q?: string }> =
  ({ url, ...props }) => {
    const window_size = useContext(CONTEXTS.window_size);
    const is_adult = usePermCheck("age", {});

    const links = useMemo<NavLinkSpec[]>(() => {
      const links_: NavLinkSpec[] = [
        {
          href: `/groups`,
          icon: "family",
          text: "Your Groups",
        },
      ];
      return is_adult
        ? [
            ...links_,
            {
              href: `/groups/discover`,
              icon: "globe",
              text: "Discover Groups",
            },
            {
              href: `/groups/manage`,
              icon: "primary",
              text: "Groups You Manage",
            },
          ]
        : links_;
    }, [is_adult]);

    const force_reset_title = useDocumentTitle(() => {
      if (url === "/groups") return make_title(["Your Groups"]);
      if (url === "/groups/discover") return make_title(["Discover Groups"]);
      if (url === "/groups/manage") return make_title(["Groups You Manage"]);
      return make_title(["Search Groups"]);
    }, [props.q, url]);
    const { dispatch } = useContext(GROUPS);

    const [search, set_search] = useStateIfMounted(props.q || "");
    const route_before_search = useRef("");
    const attachSearchBar = useSiteHeaderContent(true);

    const is_searching = url?.startsWith("/groups/search");

    // when we exit the search page, return to the page we were on before
    useEffect(() => {
      if (!!search) {
        if (!is_searching) {
          route_before_search.current = url || "/groups";
        }
        maybe_route(`/groups/search?q=${search}`, is_searching);
      } else if (is_searching) {
        maybe_route(route_before_search.current || "/groups");
      }
    }, [search]);

    // set search if there are query params
    useEffect(() => {
      if (is_searching && props.q && props.q !== search) {
        set_search(props.q);
        return;
      }
      if (is_searching && !search && !props.q) {
        maybe_route("/groups", true);
      }
      if (!props.q) {
        set_search("");
      }
    }, [url, props.q]);

    const on_create_group = useCallback(
      (new_group: Group) => {
        dispatch.add(new_group);
        analytics.log_event(analytics.EVENTS.create_group_saved);
      },
      [dispatch]
    );

    const is_small = window_size <= ScreenSize.md;
    const has_modal_path = is_adult ? modal_path : {};

    const button = (
      <LinkWithIcon
        href="/groups/create"
        className={`filled gray${is_small ? " pill" : " button"}`}
        side="left"
        icon="add"
        onClick={() => {
          analytics.log_event(analytics.EVENTS.create_group);
        }}
      >
        Create a Group
      </LinkWithIcon>
    );

    return (
      <PageContent
        id="groups"
        data-page="groups-list"
        className="list-page group-page"
      >
        <PageHeader title={<h1>Groups</h1>}>
          <div className="page-header__children__flex">
            <TabLinks links={links} extras={is_small ? [button] : undefined} />
            {!is_small && is_adult && button}
          </div>
        </PageHeader>
        <PageBody>
          <GroupsPage path="/groups" key="list" {...has_modal_path} />
          <GroupDiscover
            path="/groups/discover"
            key="discover"
            hasPermission={is_adult}
            {...has_modal_path}
          />
          <GroupManage
            path="/groups/manage"
            key="manage"
            hasPermission={is_adult}
            {...has_modal_path}
          />
          {search && (
            <GroupSearch
              path="/groups/search"
              search={search}
              setSearch={set_search}
              key="search"
              hasPermission={is_adult}
              {...has_modal_path}
            />
          )}
          {is_adult ? (
            <GroupCreateModal
              onClose={force_reset_title}
              onCreate={on_create_group}
            />
          ) : (
            <NotFoundPageRoute path="/groups/create" />
          )}
          {is_adult &&
            attachSearchBar(
              <GroupSearchBar onSubmit={set_search} value={search} />
            )}
        </PageBody>
      </PageContent>
    );
  };
