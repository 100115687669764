import {
  AppStorage,
  getAppStorage,
} from "@thrive-web/ui-common/src/local-storage";
import * as hooks from "preact/hooks";
import { DocBase, MetaTypes } from "@thrive-web/core";
import { RecordType } from "@thrive-web/ui-api";
import { filter_options, get_cache } from "@thrive-web/ui-utils";
import {
  add_item_to,
  get_object_diff,
  pick,
  remove_item_from,
  replace_item_in,
} from "@thrive-web/ui-common";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
// import { useMounted } from ".";
const useMounted = () => {
  const mounted = hooks.useRef<boolean>(true);
  hooks.useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return mounted;
};

// useState, but prevents setState from being called after the component unmounts
export const useStateIfMounted = <T>(initial_value: T) => {
  const mounted = useMounted();
  const [value, setValue] = hooks.useState<T>(initial_value);
  const setter: hooks.StateUpdater<T> = hooks.useCallback(
    new_value => {
      if (mounted.current) {
        setValue(new_value);
      }
    },
    [setValue, mounted]
  );
  return [value, setter] as const;
};

// useState, but also tracks the previous value
export const useStateWithPrevValue = <T>(initial_value: T) => {
  const [value, setValue, value_ref] = useStateRef<T>(initial_value);
  const prev_value = hooks.useRef(initial_value);
  const setter = hooks.useCallback(
    (new_value: T) => {
      prev_value.current = value_ref.current;
      setValue(new_value);
    },
    [setValue, prev_value, value_ref]
  );
  return [value, setter, prev_value] as const;
};

// useState, but also provides a ref to the current state value
export const useStateRef = <T>(initial_value: T) => {
  const [value, setValue] = useStateIfMounted<T>(initial_value);
  const value_ref = hooks.useRef(initial_value);
  const set_value_and_ref = hooks.useCallback(
    (new_value: T) => {
      value_ref.current = new_value;
      setValue(new_value);
    },
    [setValue, value_ref]
  );
  return [value, set_value_and_ref, value_ref] as const;
};

// supports partial updates like setState for class components
export const useStateObject = <State extends object>(
  initial_state: State
): [State, hooks.StateUpdater<Partial<State>>] => {
  const [state, setAllState] = useStateIfMounted(initial_state);
  const setState = hooks.useCallback(
    (new_state: Partial<State>) => {
      setAllState(prev_state => ({ ...prev_state, ...new_state }));
    },
    [setAllState]
  );
  return [state, setState];
};

// returns a sparse copy of `data` that only contains properties that have changed
// from the original value of `data`, along with an array of top-level keys that
// are deleted/missing from `data`.
// also returns a callback to reset the "initial" value of `data`
export const useObjectDiff = <Schema extends object>(
  data: Schema,
  prepare_for_diffing: (data: Schema) => Schema = d => d
): [
  Partial<Schema> | undefined,
  (keyof Schema)[] | undefined,
  hooks.StateUpdater<Schema>
] => {
  const orig_ = hooks.useMemo(() => prepare_for_diffing(data), []);
  const [original, setOriginal, original_ref] = useStateRef(orig_);
  const reset_original = hooks.useCallback(
    (new_data: Schema) => setOriginal(prepare_for_diffing(new_data)),
    [prepare_for_diffing, setOriginal]
  );
  return [
    ...hooks.useMemo(() => {
      const [diff, deleted] = get_object_diff<Schema>(
        original_ref.current,
        prepare_for_diffing(data)
      );
      return [
        diff ? (pick(Object.keys(diff), data) as Partial<Schema>) : undefined,
        deleted,
      ] as const;
    }, [original, data, prepare_for_diffing]),
    reset_original,
  ];
};

// memoizer for text regex filter
export const useTextFilter = <T>(
  text: string,
  items: readonly T[],
  test_item: RegexFilterFunction<T>
) => {
  return hooks.useMemo(
    () => filter_options(text, items, test_item),
    [text, items, test_item]
  );
};

// returns a set of functions to update an array without mutating the original
// includes: add, remove, update, concat, and reset
export const useDynamicListUpdaters = <T>(
  list: readonly T[] | null,
  set_list: hooks.StateUpdater<T[] | null>
) => {
  const list_ref = hooks.useRef<T[] | null>(list?.slice() || null);

  const add = hooks.useCallback(
    (item: T, pos: "start" | "end" = "start") => {
      const new_list = add_item_to(list_ref.current || [], item, pos);
      list_ref.current = new_list;
      set_list(new_list);
    },
    [set_list, list_ref]
  );

  const remove = hooks.useCallback(
    (
      match: (item: T, index?: number) => boolean,
      force_update: boolean = true
    ) => {
      const new_list = remove_item_from(list_ref.current || [], match);
      if (new_list.length !== list_ref.current?.length || force_update) {
        list_ref.current = new_list;
        set_list(new_list);
      }
    },
    [set_list, list_ref]
  );

  const update = hooks.useCallback(
    (
      match: (item: T, index?: number) => boolean,
      new_item: T,
      append?: "start" | "end"
    ) => {
      const new_list = replace_item_in(
        list_ref.current || [],
        match,
        new_item,
        append
      );
      list_ref.current = new_list;
      set_list(new_list);
    },
    [set_list, list_ref]
  );

  const reset = hooks.useCallback(
    (new_list: T[] | readonly T[] | null) => {
      const new_list_ = new_list?.slice() || null;
      list_ref.current = new_list_;
      set_list(new_list_);
    },
    [set_list]
  );

  const concat = hooks.useCallback(
    (new_list: T[] | readonly T[], prepend?: boolean) => {
      const new_list_ = prepend
        ? new_list.concat(list_ref.current || [])
        : (list_ref.current || []).concat(new_list);
      list_ref.current = new_list_;
      set_list(new_list_);
    },
    [set_list, list_ref]
  );

  return [
    hooks.useMemo(
      () => ({ reset, add, remove, update, concat } as const),
      [reset, add, remove, update, concat]
    ),
    list_ref,
  ] as const;
};

// manages an array with mutation functions
export const useDynamicListVariable = <T>(
  initialValue: T[] | readonly T[] | null = []
) => {
  const [list, set_list] = useStateIfMounted<readonly T[] | null>(
    initialValue ? initialValue.slice() : initialValue
  );
  const updaters = useDynamicListUpdaters(list, set_list);

  return [list, ...updaters] as const;
};

// keep the value of a prop around for the given duration
// main use: keeping record data while modal is closing
export const preserveProps = <T>(
  value?: T,
  timeout = MODAL_ANIMATION_DELAY
): T | undefined => {
  const [state, setState] = useStateIfMounted(value);
  const timer = hooks.useRef<NodeJS.Timeout | undefined>();
  hooks.useLayoutEffect(() => {
    if (value) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
      setState(value);
    } else {
      timer.current = setTimeout(() => {
        setState(undefined);
      }, timeout);
    }
  }, [value, setState]);
  return state;
};

// tracks previous value of a prop (or any variable)
export const usePreviousValue = <T>(
  value: T
): preact.RefObject<T | undefined> => {
  const prev_value = hooks.useRef<T>();
  const cur_value = hooks.useRef<T>(value);
  hooks.useEffect(() => {
    prev_value.current = cur_value.current;
    cur_value.current = value;
  }, [value]);
  return prev_value;
};

// index an array of records by id for quick access
export const useRecordIdMap = <T extends keyof MetaTypes>(
  data?: readonly RecordType<T>[] | null
): ObjectOf<RecordType<T>> => {
  return hooks.useMemo(() => {
    if (!data) {
      return {};
    }
    const map: ObjectOf<RecordType<T>> = {};
    data.forEach(acc => {
      map[acc.id] = acc;
    });
    return map;
  }, [data]);
};

// memoize something and return a ref to it
export const useMemoRef = <T>(factory: () => T, inputs: any[]) => {
  const ref = hooks.useRef<T>();
  ref.current = hooks.useMemo(factory, inputs);
  return ref;
};

export const useValueRef = <T>(value: T) => {
  const ref = hooks.useRef<T>(value);
  ref.current = value;
  return ref;
};

// returns a ref to a callback (useful in Effects that shouldn't run when the callback updates)
export const useCallbackRef = <T extends Function>(
  func: T,
  inputs: any[],
  clear_on_unmount: boolean = true
): preact.RefObject<T> => {
  const funcRef = hooks.useRef<T>(func);
  funcRef.current = hooks.useCallback(func, inputs);
  hooks.useEffect(
    () => () => {
      // @ts-expect-error:
      funcRef.current = clear_on_unmount
        ? null
        : () => {
            console.warn(`callback ref called after unmount, this is bad.`);
          };
    },
    []
  );
  return funcRef;
};

// takes id of record and function to get the record
// returns the record, a callback to fetch the record, and a callback to set the record manually
// usage: will try to return a cached copy of the record while fetching a fresh copy
export const useRecordForDetailPage = <T extends RecordType>(
  id: string,
  get_record: () => Promise<DocBase & { data: T }>
) => {
  const [fetched_record, set_record] = useStateIfMounted<T | null>(null);

  const fetch = hooks.useCallback(() => {
    return get_record().then(result => {
      set_record(result.data);
    });
  }, [get_record, fetched_record, set_record]);

  hooks.useEffect(() => {
    fetch();
    return () => {
      set_record(null);
    };
  }, [id]);

  const this_record = hooks.useMemo<T>(
    () => (id === fetched_record?.id ? fetched_record : get_cache(id)),
    [id, fetched_record]
  );

  return [this_record, fetch, set_record] as const;
};

// simulate pagination for a given array (useful for huge arrays of records)
export const useLocalPagination = <T>(items: T[] | null) => {
  return hooks.useCallback(
    (offset: number, limit: number = 20) =>
      Promise.resolve<DocBase & { data: T[] }>({
        data: items ? items.slice(offset, offset + limit) : [],
        meta: items
          ? {
              total_result_count: items.length,
            }
          : undefined,
      }),
    [items]
  );
};

export const useAppStorage = () => {
  const [store, set_store] = useStateIfMounted<AppStorage | undefined>(
    undefined
  );
  hooks.useLayoutEffect(() => {
    getAppStorage().then(storage => {
      set_store(storage);
    });
  }, []);
  return store;
};
