import { moment } from "@thrive-web/ui-common";
import * as Preact from "preact";
import DayPicker from "react-day-picker";
// @ts-expect-error:
import { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import {
  ButtonWithIcon,
  Icon,
  Popover,
  PopoverTriggerProps,
  RenderNavBar,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import { useId, useStateIfMounted } from "@thrive-web/ui-hooks";
import { format_day_range, maybeClassName } from "@thrive-web/ui-utils";

export interface DateRangePickerControlProps extends PopoverTriggerProps {
  value?: { to?: Date; from?: Date } | Date[] | Date;
  onChange: (value: { to: Date; from: Date }) => void;
}

export interface DateRangePickerProps {
  start: Date | undefined;
  end: Date | undefined;
  onChange: (value) => void;
  children: (controlProps: DateRangePickerControlProps) => Preact.VNode | null;
  popoverProps?: Omit<PopoverProps, "triggerComponent" | "show">;
  popoverTriggerOptions?: PopoverTriggerOptions;
  // allow the start/end day to be the same day
  allowSingleDay?: boolean;
  min?: Date;
  max?: Date;
}

const get_range = (d1: Date, d2: Date): DateRange => {
  if (moment(d1).isAfter(moment(d2))) {
    return { from: d2, to: d1 };
  }
  return { from: d1, to: d2 };
};

// if single day is not allowed, clicking on the same day a second time will de-select it
const renderDayNoSingleDay = (day, mods?) => (
  <div className="date-picker__day">
    {mods?.selected ? (
      <Preact.Fragment>
        <Icon name="remove" />
        <span>{day.getDate()}</span>
      </Preact.Fragment>
    ) : (
      day.getDate()
    )}
  </div>
);

const renderDay = day => (
  <div className="date-picker__day">{day.getDate()}</div>
);

export const DateRangePicker: Preact.FunctionComponent<DateRangePickerProps> =
  ({
    onChange,
    children,
    start,
    end,
    popoverProps,
    popoverTriggerOptions = {
      click: true,
      focus: false,
      hover: false,
    },
    allowSingleDay,
    min,
    max = new Date(),
  }) => {
    // popover component id
    const id = useId("datepicker");
    const [open, set_open, trigger_props] = usePopoverTrigger(
      popoverTriggerOptions,
      id
    );
    // the first date selection
    const [clicked, set_clicked] = useStateIfMounted<Date | undefined>(
      undefined
    );
    const [hovered, set_hovered] = useStateIfMounted<Date | undefined>(
      undefined
    );
    // "to" if the hovered date is after the clicked date, meaning the hovered date
    // is the end of the range, or vice versa
    const [edge_hovered, set_edge_hovered] = useStateIfMounted<
      "to" | "from" | undefined
    >(undefined);

    const on_submit = useCallback(
      (val: Partial<DateRange>) => {
        onChange(val);
        set_clicked(undefined);
        set_hovered(undefined);
        set_open(false);
      },
      [onChange, set_open]
    );

    const on_change_value = useCallback(
      (day: Date, mods?, e?) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (mods?.disabled) {
          return;
        }
        // if nothing clicked yet, set this date as one end of the range
        if (!clicked) {
          set_clicked(day);
          // if single day is not allowed and we clicked the same day, de-select it
        } else if (!allowSingleDay && day.getTime() === clicked.getTime()) {
          set_clicked(undefined);
        } else {
          if (day.getTime() > clicked.getTime()) {
            on_submit({ from: clicked, to: day });
          } else {
            on_submit({ from: day, to: clicked });
          }
        }
      },
      [on_submit, clicked]
    );

    // if we've selected one end of the range, preview the other end using the hovered date
    const preview_other_bound = useCallback(
      (day: Date, mods?) => {
        if (mods.disabled || !clicked) {
          return;
        }
        set_hovered(day);
      },
      [!!clicked]
    );

    const selected = useMemo(
      () => clicked || (start && end ? [start, end] : start || end),
      [start, end, clicked]
    );

    // className/style modifiers
    const modifiers = useMemo(() => {
      // in_range = dates between the clicked/hovered dates, or start/end dates
      const in_range = clicked
        ? hovered
          ? get_range(clicked, hovered)
          : edge_hovered
          ? { [edge_hovered]: clicked }
          : undefined
        : { from: start, to: end };
      const edges: any = {};

      // when the range is "before X" or "after X", the background color of the days
      // inside the range fades away as the day gets further away from the selected
      // to/from day
      if (edge_hovered || (!clicked && !!start !== !!end)) {
        for (let i = 1; i < 5; i++) {
          edges[`edge edge_${i}`] = day =>
            moment(day).isSame(
              moment(in_range?.to || in_range?.from).add(
                (!!in_range?.from ? 1 : -1) * i,
                "day"
              ),
              "day"
            ) && day.getTime() < Date.now();
        }
      }
      return {
        highlighted: in_range,
        first: day =>
          in_range?.from && moment(day).isSame(in_range?.from, "day"),
        last: day => in_range?.to && moment(day).isSame(in_range?.to, "day"),
        ...edges,
      };
    }, [clicked, hovered, edge_hovered, start, end]);

    const triggerComponent = useMemo(
      () => children({ ...trigger_props, value: selected, onChange }),
      [trigger_props, selected, onChange]
    );

    const disabled = useMemo(
      () => ({
        before: min,
        after: max,
      }),
      [min, max]
    );

    // text for the "Before X"/"After X" buttons
    const buttons_text = useMemo(
      () =>
        clicked
          ? {
              to: format_day_range({ to: clicked }),
              from: format_day_range({ from: clicked }),
            }
          : {},
      [clicked]
    );

    // clear state when popover closes
    useEffect(() => {
      if (!open) {
        set_clicked(undefined);
        set_hovered(undefined);
      }
    }, [open]);

    const is_selecting = useRef<boolean>(false);
    useLayoutEffect(() => {
      if (!!clicked && !!(hovered || edge_hovered)) {
        is_selecting.current = true;
      }
      if (!clicked) {
        is_selecting.current = false;
      }
    }, [!!clicked, !!hovered, !!edge_hovered]);

    return (
      <Popover
        {...popoverProps}
        className={`date-picker__container${maybeClassName(
          popoverProps?.className
        )}`}
        id={id}
        triggerComponent={triggerComponent}
        show={open}
        mountLocal={true}
      >
        {
          <DayPicker
            className={`date-picker date-picker--range${
              is_selecting.current ? " date-picker--selecting" : ""
            }${
              clicked && !(hovered || edge_hovered)
                ? " date-picker--single-selected"
                : ""
            }`}
            tabIndex={open ? undefined : -1}
            showOutsideDays={true}
            onDayClick={on_change_value}
            selectedDays={selected}
            renderDay={allowSingleDay ? renderDay : renderDayNoSingleDay}
            // @ts-expect-error:
            navbarElement={RenderNavBar}
            disabledDays={disabled}
            modifiers={modifiers}
            onDayMouseEnter={preview_other_bound}
            onDayFocus={preview_other_bound}
          />
        }
        <div
          className="date-picker__footer"
          onMouseEnter={() => set_hovered(undefined)}
          onMouseLeave={() => set_edge_hovered(undefined)}
        >
          {clicked ? (
            <Preact.Fragment>
              <ButtonWithIcon
                icon="checked"
                side="right"
                className="filled pill gray"
                onClick={() => on_submit({ to: clicked, from: undefined })}
                onMouseEnter={() => set_edge_hovered("to")}
              >
                {buttons_text.to}
              </ButtonWithIcon>
              <ButtonWithIcon
                icon="checked"
                side="right"
                className="filled pill gray"
                onClick={() => on_submit({ to: undefined, from: clicked })}
                onMouseEnter={() => set_edge_hovered("from")}
              >
                {buttons_text.from}
              </ButtonWithIcon>
            </Preact.Fragment>
          ) : (
            <div className="date-picker__footer__label">
              Select a date range
            </div>
          )}
        </div>
      </Popover>
    );
  };
