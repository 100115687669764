import * as Preact from "preact";
import authWrapper from "~/auth/auth";
import { MIN_PASSWORD_LENGTH } from "@thrive-web/ui-constants";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  WithFloatingTitle,
  InputWithFormHelpers,
  ErrorMessage,
  RequestButtonWithIcon,
} from "@thrive-web/ui-components";
import { GROUP_INVITE_STORAGE_KEY } from "~/view/components";

export class Login extends Preact.Component<
  {},
  {
    email?: string;
    password?: string;
    pending: boolean;
    error?: DisplayableError;
  }
> {
  constructor(props) {
    super(props);
    this.state = { pending: false };
    const text = localStorage.getItem(GROUP_INVITE_STORAGE_KEY);
    localStorage.removeItem(GROUP_INVITE_STORAGE_KEY);
    if (text) {
      try {
        this.params = JSON.parse(text);
      } catch (e) {}
    }
  }
  params: any;

  onChangeInput = (field: "email" | "password") => e => {
    // @ts-ignore
    this.setState({ [field]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email && password) {
      this.setState({ pending: true }, () =>
        authWrapper
          .login(email, password, this.params)
          .then(user_cred => {
            if (!user_cred.user?.emailVerified) {
              window.location.reload();
            }
            return authWrapper.awaitLoginPromise();
          })
          .catch(error => {
            this.setState({ pending: false, error });
          })
      );
    }
  };

  render() {
    const { email, password, error, pending } = this.state;
    return (
      <div className="login-page">
        <div className="login-page__logo">
          <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
        </div>
        <div className="login-page__form card">
          <div className="login-page__form__title">Welcome to Thread</div>
          <div className="login-page__prompt info">
            Sign in with your Thread Account.
          </div>
          <form id="login" onSubmit={this.onSubmit}>
            <WithFloatingTitle title="Email Address">
              <InputWithFormHelpers
                type="email"
                name="email"
                placeholder="Email Address"
                onChange={this.onChangeInput("email")}
                value={email}
                submitOnEnter={true}
              />
            </WithFloatingTitle>
            <WithFloatingTitle title="Password">
              <InputWithFormHelpers
                placeholder="Password"
                type="password"
                name="password"
                minLength={MIN_PASSWORD_LENGTH}
                onChange={this.onChangeInput("password")}
                value={password}
                submitOnEnter={true}
              />
            </WithFloatingTitle>
            <RequestButtonWithIcon
              icon="logo"
              side="left"
              pending={pending}
              success={false}
              className="login-page__submit filled full-width"
              type="submit"
              successText="Success!"
            >
              Sign In to Thread
            </RequestButtonWithIcon>
            {error &&
            (error.code === 401 ||
              error.code === "auth/user-not-found" ||
              error.code === "auth/wrong-password") ? (
              <ErrorMessage>Invalid email or password</ErrorMessage>
            ) : error ? (
              <ErrorMessage>
                {error.message}
                {error?.http_status ? `(${error.http_status})` : ""}
              </ErrorMessage>
            ) : null}
            <div className="login-page__actions">
              <a
                className="plain-link"
                // @ts-expect-error:
                native
                href="/forgot-password"
              >
                Forgot password?
              </a>
              <a
                className="plain-link"
                // @ts-expect-error:
                native
                href="/register"
              >
                Need an account?
              </a>
            </div>
          </form>
          {/*<div className="login-page__google">
            <div className="login-page__google__prompt">
              Or sign in another way:
            </div>
            <div className="login-page__google__button">
              <ButtonWithIcon
                className="google-signin-button button full-width"
                icon={
                  <img
                    className="google-signin-button__icon"
                    src={`${IMAGES_PREFIX}/google_signin_icon.svg`}
                  />
                }
                side="left"
                onClick={this.onClickGoogleSignIn}
              >
                Sign In With Google
              </ButtonWithIcon>
            </div>
          </div>*/}
        </div>
      </div>
    );
  }
}
