import { ApiMethodParameters } from "@thrive-web/core";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { Community } from "@thrive-web/ui-api";
import {
  asSubroute,
  EventListLoading,
  useRenderDynamicListWithPagedFetch,
  EventList,
  COMMUNITY_PAST_EVENTS,
  with_profile_picture,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import { useApiFetchPaged, useDocumentTitle } from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

const CommunityPastEvents: Preact.FunctionComponent<
  RoutePageProps & { community: Community }
> = ({ community, url }) => {
  const { list, dispatch } = useContext(COMMUNITY_PAST_EVENTS);
  useDocumentTitle(
    () => (community.name ? make_title([community.name, "Past Events"]) : null),
    [community.name]
  );
  const event_params = useMemo<ApiMethodParameters<"GET", "Event">>(
    () => ({
      query: {
        filter: [
          [
            "=",
            ["this", ["/", ["^", "Post:event"], "Post:posted_to"]],
            ["id", community.id],
          ],
          ["<", ["this", "Event:date_time"], new Date().toISOString()],
        ],
        include: ["cover_image", ...with_profile_picture(["has_invitee"])],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
      },
    }),
    [url]
  );
  const fetch_events = useApiFetchPaged("getEvents", event_params);

  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem) => (
      <EventList events={result} loadMoreElem={load_more_elem} />
    ),
    [],
    fetch_events,
    undefined,
    { PendingView: EventListLoading, limit: 10 }
  );

  return (
    <div className="page-tab__section">
      <div className="page-tab__section__title">Past Events</div>
      {content}
    </div>
  );
};

export const CommunityPastEventsPage = asSubroute(CommunityPastEvents);
