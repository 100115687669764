import * as Preact from "preact";
import { useContext, useEffect } from "preact/hooks";

export type ContextSpec<T extends object> = {
  dispatch: Preact.Context<LocalDispatch<T>>;
} & {
  [K in keyof T]: Preact.Context<T[K]>;
};
export type LocalDispatch<T extends object> = <K extends keyof T>(
  key: K,
  value: T[K],
  delay?: number
) => void;

/** manage a local context of a dict */
export class LocalProvider<T extends object> extends Preact.Component<
  { contexts: ContextSpec<T>; initialValues: Partial<T> },
  Partial<T>
> {
  constructor(props) {
    super(props);
    const state: Partial<T> = {};
    Object.keys(props.contexts).forEach(key => {
      state[key] = props.initialValues[key];
    });
    this.state = state;
  }
  mounted: boolean;

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  dispatch: LocalDispatch<T> = <K extends keyof T>(
    key: K,
    value: T[K],
    delay: number = 0
  ) => {
    setTimeout(() => {
      if (this.mounted) {
        // @ts-ignore
        this.setState({ [key]: value });
      }
    }, delay);
  };

  render() {
    return Object.entries(this.props.contexts).reduce(
      (children, [key, { Provider }]: any) => (
        <Provider value={key === "dispatch" ? this.dispatch : this.state[key]}>
          {children}
        </Provider>
      ),
      this.props.children
    );
  }
}

export const passPropsToLocalContext = <T extends object, K extends keyof T>(
  key: K,
  value: T[K],
  dispatch_ctx: Preact.Context<LocalDispatch<T>>
) => {
  const dispatch = useContext(dispatch_ctx);
  useEffect(() => {
    dispatch(key, value);
  }, [key, value]);
};
