import { ENTITIES } from "@thrive-web/core";

export const IMAGES_PREFIX = `/static/images`;
export const HTML_PREFIX = `/static/html`;
export const PLACEHOLDER_AVATAR_URL = `${IMAGES_PREFIX}/icon-avatar.svg`;
export const EVENT_BANNER_PLACEHOLDER_PATH = `${IMAGES_PREFIX}/event-placeholder.jpg`;

export const THREAD_COMM_ID = `${ENTITIES}Community/Thread`;

export const GROUP_PURPOSE_CHAR_LIMIT = 144;

export const RELATIONSHIP_GROUP_MEMBER_LIMIT = 15;

export const MIN_PASSWORD_LENGTH = 6;

export const MODAL_ANIMATION_DELAY = 300; // ms

export const CAROUSEL_ANIMATION_DELAY = 400; // ms

export const PO_EDGE_BUFFER_LG = 16; // px
export const PO_EDGE_BUFFER_SM = 8; // px

export const INPUT_DEBOUNCE_TIME = 100; // ms

export const FACEPILE_DEFAULT_MAX_ITEMS = 4;

export const DEFAULT_PAGE_SIZE = 25;

export const KILOBYTE = Math.pow(2, 10);
export const MEGABYTE = Math.pow(2, 20);
export const MAX_FILE_SIZE = 10 * MEGABYTE;

export const DEFAULT_UI_ERROR = {
  code: "ui/error-in-component",
  message: "Something went wrong. Try refreshing the page.",
};

// todo: This should be imported from the api code, not copy/pasted
export enum InteractionTypes {
  CALL_USER = "call_user",
  TEXT_USER = "text_user",
  EMAIL_USER = "email_user",
  MEET_IN_PERSON = "meet_in_person",
  OTHER = "other",
  TAG_USER_IN_TOUCHPOINT = "tag_user_in_touchpoint",
  COMMENT_ON_POST = "comment_on_post",
  // Used to schedule nudge notifications. More on this later.
  PRELIM_TEXT_USER = "prelim_text_user",
  PRELIM_EMAIL_USER = "prelim_email_user",
}
export enum RSVPResponses {
  GOING = "going",
  MAYBE = "maybe",
  DECLINED = "declined",
}
export enum NotifCustomTypes {
  VIEW_CONNECTION_REQUEST = `view_connection_request`,
  VIEW_TAGGED_IN_POST = `view_tagged_in_post`,
  VIEW_MOOD_UPDATE = `view_mood_update`,
  VIEW_CONNECTION_REQUEST_ACCEPTED = `view_connection_request_accepted`,
  VIEW_UPCOMING_EVENT = `view_upcoming_event`,
  VIEW_NEW_GROUP_MEMBER = `view_new_group_member`,
  VIEW_PRELIMINARY_INTERACTION = `view_preliminary_interaction`,
  VIEW_DEGRADED_CONNECTION_HEALTH = `view_degraded_connection_health`,
  VIEW_REACTION_TO_POST = `view_reaction_to_post`,
  VIEW_COMMENT_ON_POST = `view_comment_on_post`,
  VIEW_CANCELED_EVENT = `view_canceled_event`,
  VIEW_EVENT_INVITE = `view_event_invite`,
  VIEW_RATE_INTERACTION = `view_rate_interaction`,
}

// for comparing screen sizes
export enum ScreenSize {
  xxs = 360,
  xs = 480,
  sm = 768,
  md = 1024,
  lg = 1390,
  xl = 1920,
  xxl = 1921,
}

export enum MediaSizes {
  small = 360,
  medium = 720,
  large = 1080,
  xlarge = 1440,
}

export const THEME_COLORS: string[] = [
  "#7D1212",
  "#FF3300",
  "#FF8800",
  "#62AF0F",
  "#2E8847",
  "#006e6d",
  "#228B9D",
  "#0077BB",
  "#7877A6",
  "#43128C",
  "#85489E",
  "#550D42",
  "#E50080",
  "#EE3366",
  "#939598",
];

export const DEFAULT_GROUP_BACKGROUND_COLOR =
  THEME_COLORS.slice().pop() as string;
