import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { Comment } from "@thrive-web/ui-api";
import {
  useApiCall,
  useApiRequest,
  useAppUser,
  useStateIfMounted,
  useStateRef,
} from "@thrive-web/ui-hooks";
import {
  format_time_passed,
  get_guid_from_iri,
  maybeClassName,
} from "@thrive-web/ui-utils";
import {
  Avatar,
  ButtonWithIcon,
  DropdownMenu,
  EntityLink,
  Icon,
  IS_ADMIN_UI,
  Popover,
  PopoverTriggerProps,
  ReactionButtonSimple,
  RequestButton,
  RequestButtonWithIcon,
  TextAreaWithFormHelpers,
  usePopoverTrigger,
  UserProvider,
} from "@thrive-web/ui-components";

const dropdown_props = {
  defaultBody: true,
  portalClassName: "comment__controls--popover",
};

export const CommentItem: Preact.FunctionComponent<
  {
    data: Comment;
    onUpdate?: (data: Comment, deleted: boolean) => void;
    canInteract: boolean;
  } & MaybeClass
> = ({ data, canInteract, className, onUpdate }) => {
  const self = useAppUser();
  const is_admin_ui = useContext(IS_ADMIN_UI);

  const { body } = data;
  const created_at = useMemo(
    () =>
      data.created_at ? format_time_passed(new Date(data.created_at)) : "",
    [data.created_at]
  );
  const created_by = useContext(UserProvider);

  const [edit_mode, set_edit_mode, edit_mode_ref] = useStateRef(false);
  const toggle_edit = useCallback(
    () => set_edit_mode(!edit_mode_ref.current),
    [set_edit_mode]
  );

  const onSave = useCallback(
    (data: Comment, deleted: boolean) => {
      onUpdate?.(data, deleted);
      setTimeout(() => set_edit_mode(false), 800);
    },
    [onUpdate, set_edit_mode]
  );

  const guid = useMemo(() => get_guid_from_iri(data.id)[0], [data.id]);

  const [open, set_open, trigger_props] = usePopoverTrigger(
    { click: true, focus: false, hover: false },
    `comment-${guid}-delete`
  );
  const open_ref = useRef(open);
  open_ref.current = open;

  const tt_source_ref = useRef<HTMLElement>();
  const tt_container_ref = useRef<HTMLDivElement>(null);
  const tooltip_props = useMemo<Partial<PopoverProps>>(
    () => ({
      getSourceRef: () => tt_source_ref.current,
      getContainerRef: () => tt_container_ref.current,
      defaultBody: true,
      defaultDirection: "top",
    }),
    [tt_source_ref, tt_container_ref]
  );
  const tooltip_source_props = useMemo(() => {
    let tprops: PopoverTriggerProps = {};
    Object.entries(trigger_props).forEach(([key, func]) => {
      if (key === "className") {
        // @ts-expect-error:
        tprops.className = func;
      } else {
        tprops[key] = (...args) => {
          if (open_ref.current) {
            // @ts-expect-error:
            func(...args);
          }
        };
      }
    });
  }, [open_ref, trigger_props]);

  const [delete_comment, { pending, success, error }] = useApiRequest(
    "deleteComment",
    data.id
  );
  const onClickDelete = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      delete_comment().then(() => {
        analytics.log_event(
          analytics.EVENTS.comment_delete,
          undefined,
          undefined,
          true
        );
        setTimeout(() => {
          onUpdate?.(data, true);
          set_open(false);
        }, 800);
      });
    },
    [data, set_open, delete_comment]
  );

  const dropdown_items = useMemo(() => {
    return [
      self && created_by?.id === self?.id ? (
        <ButtonWithIcon
          className="pill filled gray"
          icon="edit"
          side="left"
          onClick={toggle_edit}
        >
          Edit
        </ButtonWithIcon>
      ) : null,
      <ButtonWithIcon
        className="pill filled negative"
        icon="trash"
        side="left"
        onClick={() => set_open(true)}
      >
        Delete
      </ButtonWithIcon>,
    ];
  }, [set_open, toggle_edit, created_by?.id]);

  const button = useMemo(
    () => (
      <Icon
        ref={tt_source_ref}
        {...tooltip_source_props}
        className="with-icon__icon"
        name="more"
      />
    ),
    [tt_source_ref, tooltip_source_props]
  );

  if (!self || !created_by) {
    return null;
  }
  return (
    <div className={`comment${maybeClassName(className)}`}>
      {edit_mode && onUpdate ? (
        <CommentEdit comment={data} onSave={onSave} onCancel={toggle_edit} />
      ) : (
        <Preact.Fragment>
          <div className="comment__content">
            <div className="comment__avatar" data-ignore-link-click="true">
              <Avatar user={created_by} size="sm" forcePlaceholder={true} />
            </div>
            <div
              className="comment__body"
              data-loading-user={!created_by["full_name"]}
            >
              {created_by["full_name"] ? (
                <span
                  className="comment__creator"
                  data-ignore-link-click="true"
                >
                  <EntityLink entity={created_by}>
                    {created_by["full_name"]}
                  </EntityLink>
                </span>
              ) : (
                <span className="comment__creator loading-item__text loading-item__shaded" />
              )}
              <span data-screen-above="sm" className="comment__text">
                {body}
              </span>
              <span className="comment__date">{created_at}</span>
            </div>
            {(canInteract || is_admin_ui) && (
              <div className="comment__controls">
                {canInteract && <ReactionButtonSimple reactedTo={data.id} />}
                {onUpdate && (
                  <div
                    className="comment__controls__more"
                    data-delete-open={`${open}`}
                    ref={tt_container_ref}
                  >
                    <Popover
                      id={`comment-${guid}-delete`}
                      show={open}
                      triggerComponent={
                        <DropdownMenu
                          id={`comment-${guid}-dropdown`}
                          items={dropdown_items}
                          button={button}
                          buttonClassName="dropdown-menu__button with-icon icon-only pill filled transparent"
                          popoverProps={dropdown_props}
                          listClassName="pill-card"
                        />
                      }
                      {...tooltip_props}
                    >
                      <div className="comment__delete-warning">
                        <p>Are you sure you want to delete this comment?</p>
                        <p>
                          <strong>
                            You will not be able to undo this action!
                          </strong>
                        </p>
                        <div className="comment__delete-warning__buttons">
                          <button
                            className="bordered"
                            type="button"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              set_open(false);
                            }}
                          >
                            Cancel
                          </button>
                          <RequestButton
                            className="filled gray"
                            type="button"
                            onClick={onClickDelete}
                            pending={pending}
                            success={success}
                            successText="Success!"
                            error={error}
                            retryText="Retry"
                            showError={true}
                          >
                            Confirm
                          </RequestButton>
                        </div>
                      </div>
                    </Popover>
                  </div>
                )}
              </div>
            )}
          </div>
          <span data-screen-below="md" className="comment__text">
            {body}
          </span>
        </Preact.Fragment>
      )}
    </div>
  );
};

export const CommentEdit: Preact.FunctionComponent<{
  comment: Comment;
  onSave: (data: Comment, deleted: false) => void;
  onCancel: () => void;
}> = ({ onSave, onCancel, comment }) => {
  const [text, set_text] = useStateIfMounted(comment.body);

  const [edit_comment, { pending, success, error }] =
    useApiCall("updateComment");

  const on_submit = useCallback(
    e => {
      if (e.target.checkValidity()) {
        e.preventDefault();
        edit_comment(comment.id, {
          query: { include: ["created_by.User:profile_picture"] },
          body: { data: { attributes: { body: text } } },
        }).then(({ data }) => {
          analytics.log_event(analytics.EVENTS.comment_edit);
          onSave(data, false);
        });
      }
    },
    [edit_comment, text]
  );

  if (!comment.created_by) {
    return null;
  }
  return (
    <div className="comment__content comment__edit">
      <div className="comment__avatar">
        <Avatar user={comment.created_by} size="sm" />
      </div>
      <form onSubmit={on_submit}>
        <TextAreaWithFormHelpers
          onChange={e => set_text(e.target.value)}
          value={text}
          minLength={1}
          controlled={true}
        />
        <div className="comment__edit__buttons">
          <ButtonWithIcon
            className="pill filled gray"
            icon="remove"
            side="left"
            onClick={onCancel}
          >
            Cancel
          </ButtonWithIcon>
          <RequestButtonWithIcon
            className="pill filled gray"
            disabled={!text}
            icon="checked"
            side="left"
            type="submit"
            pending={pending}
            success={success}
            successText="Success!"
            error={error}
            showError={true}
          >
            Save
          </RequestButtonWithIcon>
        </div>
      </form>
    </div>
  );
};
