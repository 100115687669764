import {
  ApiConnectorMethod,
  ApiConnectorMethod_BoundTypeCreateRecord,
  ApiConnectorMethod_BoundTypeGetList,
  ApiConnectorMethod_BoundTypeGetRecord,
  ApiConnectorMethod_BoundTypeMethod,
  ApiConnectorRelationshipMethod,
  ApiConnectorRelationshipMethod_Bound,
  DocWithData,
  HTTPMethod,
  MediaPropertiesOf,
  MetaTypes,
  Types,
} from "@thrive-web/core";
import type { MetaInterface } from "@swymbase/metatype-core";
import { AuthWrapper } from "@thrive-web/ui-auth";
import { ensure_id_is_iri, path_or } from "@thrive-web/ui-common";
import { maybe_cache_users } from "@thrive-web/ui-utils";
import {
  GroupLabel,
  ReportType,
  RequestHeaders,
  RequestOptions,
  WriteUser,
} from "./types";
import { stringify_filter, stringify_query, stringify_sort } from "./utils";

// this directory will have all the code necessary for the ui to make calls to the api
// for now we just have a placeholder class
// todo: a lot of this is temporary

export class ApiConnector {
  constructor(config, authWrapper) {
    this.apiConfig = config.api;
    this.authConfig = config.auth;
    this.token = null;
    this.authWrapper = authWrapper;
  }
  token: string | null;
  authWrapper: AuthWrapper;
  apiConfig: {
    baseUrl: string;
  };
  authConfig: {
    authUserOrigin: string;
  };
  defaultHeaders: RequestHeaders = {
    ["Accept"]: "application/vnd.api+json",
    ["Content-Type"]: "application/vnd.api+json",
  };

  setAuthWrapper = (a: AuthWrapper) => {
    this.authWrapper = a;
  };

  // set the auth token used for all outgoing requests
  setAuthToken = (token: string | null) => {
    if (token !== this.token) {
      console.debug(`Auth token changed`);
    }
    this.token = token;
  };

  constructHeaders = (
    headers: RequestHeaders,
    no_auth?: boolean
  ): RequestHeaders =>
    Object.assign(
      no_auth ? {} : { ["Authorization"]: `Bearer ${this.token}` },
      this.defaultHeaders,
      headers,
      this.authConfig.authUserOrigin
        ? { "X-Thrive-User-Origin": this.authConfig.authUserOrigin }
        : {}
    );

  prepareRequest = (
    method: HTTPMethod,
    body?: any,
    query: any = {},
    headers: RequestHeaders = {}
  ) => {
    let body_data = body;
    if (headers["Content-Type"] === "application/vnd.api+json") {
      body_data = JSON.stringify(body, null, "  ");
    }

    if (body_data && method === "GET") {
      // query["data"] = body_data;
    } else if (!body_data && ["POST", "PUT", "PATCH"].includes(method)) {
      body_data = `{}`;
    }
    if (query && query.filter) {
      query = stringify_filter(query);
    }
    if (query && query.sort) {
      query = stringify_sort(query);
    }
    const query_string = stringify_query(query);

    return { query_string, body_data };
  };

  sendXhr = async (
    method: HTTPMethod,
    endpoint: string,
    body?: any,
    query?: any,
    headers: RequestHeaders = {}
  ) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(
        method,
        `${this.apiConfig.baseUrl}/${endpoint}${query ? `?${query}` : ""}`
      );
      // set headers
      Object.entries(headers).forEach(([name, value]) => {
        xhr.setRequestHeader(name, value);
      });
      xhr.onload = () => {
        // reject promise if status >= 400
        if (xhr.status >= 400) {
          reject({ status: xhr.status, response: JSON.parse(xhr.response) });
        } else {
          let response;
          if (xhr.response) {
            try {
              response = JSON.parse(xhr.response);
              // cache any user records that are present in the response
              maybe_cache_users(response);
            } catch (e) {
              if (xhr.getResponseHeader("Content-Type") === "text/csv") {
                response = xhr.response;
              } else {
                throw e;
              }
            }
            console[method === "GET" ? "debug" : "log"](
              `xhr response: `,
              response,
              `(${method} ${endpoint})`
            );
          }
          resolve(response);
        }
      };
      xhr.onerror = () => {
        console.debug(`xhr after error: `, xhr);
        reject({ status: xhr.status, response: JSON.parse(xhr.response) });
      };

      xhr.send(body);
    });
  };

  request = async <T extends MetaInterface, IsList extends boolean = false>(
    method: HTTPMethod,
    endpoint: string,
    body?: any,
    query: any = {},
    headers: RequestHeaders = {},
    no_auth?: boolean
  ): Promise<DocWithData<T, IsList>> => {
    if (!this.token && !no_auth) {
      return Promise.reject({
        code: "connector/no-auth-token",
        message: "Auth token not present, user is not logged in.",
      });
    }

    headers = this.constructHeaders(headers, no_auth);
    const { query_string, body_data } = this.prepareRequest(
      method,
      body,
      query,
      headers
    );

    return this.sendXhr(
      method,
      endpoint,
      body_data,
      query_string,
      headers
    ).catch(err => {
      if (err.status === 401 && this.token && !no_auth) {
        return this.authWrapper
          .refreshToken()
          .catch(() => {
            window.location.pathname = "/logout";
          })
          .then(token => {
            if (!token) {
              throw err;
            }
            this.token = token;
            headers["Authorization"] = `Bearer ${this.token}`;
            return this.sendXhr(
              method,
              endpoint,
              body_data,
              query_string,
              headers
            ).catch(err_ => {
              if (err_.status === 401) {
                window.location.pathname = "/logout";
              } else {
                throw err;
              }
            });
          });
      } else {
        throw err;
      }
    }) as Promise<DocWithData<T, IsList>>;
  };

  self = async (): Promise<DocWithData<MetaTypes["User"], false>> => {
    return this.request<MetaTypes["User"]>("GET", "self");
  };

  registered = async (): Promise<DocWithData<MetaTypes["User"], false>> => {
    return this.request<MetaTypes["User"]>("GET", "registered", undefined, {
      include: ["profile_picture"],
    });
  };

  // create api user after creating a firebase login and verifying email
  register = async (
    data: Pick<WriteUser, "first_name" | "last_name" | "birth_date" | "email">,
    skeleton_token?: string
  ): Promise<DocWithData<MetaTypes["User"], false>> => {
    const body: DocWithData<MetaTypes["User"], false, true> = {
      data: {
        attributes: data,
      },
    };
    return this.request(
      "POST",
      "register",
      body,
      skeleton_token ? { skeleton_token } : undefined
    );
  };

  feedbackSurvey = async (
    token: string,
    slides?: any
  ): Promise<DocWithData<MetaTypes["TraitifyAssessment"], false>> => {
    return this.request<MetaTypes["TraitifyAssessment"]>(
      slides ? "PATCH" : "GET",
      "feedback-survey",
      slides ? { data: { attributes: { assessment: { slides } } } } : undefined,

      {
        token,
      },
      undefined,
      true
    );
  };

  getReport = async (params: {
    report: ReportType;
    to?: string;
    from?: string;
    label?: GroupLabel;
    status?: boolean;
  }) => {
    return this.request("GET", "admin/reports", undefined, {
      ...params,
      label: params.label?.id,
    });
  };

  // method for calling all `/type` endpoints
  typeMethod: ApiConnectorMethod = async (
    type,
    method,
    id,
    params,
    options?: RequestOptions
  ) => {
    // @ts-ignore
    const { body, query } = params || {};
    const { headers } = options || {};
    const endpoint = `type/${type}${
      id != null
        ? `/${encodeURIComponent(ensure_id_is_iri(id!, type)) as string}`
        : ""
    }`;

    console.debug(`method, endpoint: `, method, endpoint);
    return this.request(method, endpoint, body, query, headers).then(
      response => ({
        ...response,
        deep_includes: query?.include?.filter(s => s.includes(".")) ?? [],
      })
    );
  };

  // method for calling all `/type/{Type}/relationships` endpoints
  relationshipMethod: ApiConnectorRelationshipMethod = async (
    type,
    relationship,
    method,
    id,
    params,
    options?: RequestOptions
  ) => {
    // @ts-ignore
    const { body, query } = params;
    const { headers } = options || {};
    const endpoint = `type/${type}${
      id != null
        ? `/${
            encodeURIComponent(ensure_id_is_iri(id!, type)) as string
          }/relationships/${relationship}`
        : ""
    }`;

    console.debug(`method, endpoint: `, method, endpoint);
    return this.request(method, endpoint, body, query, headers).then(
      response => ({
        ...response,
        deep_includes: query?.include?.filter(s => s.includes(".")) ?? [],
      })
    );
  };

  // get a signed media upload url for a particular property of a record
  getUploadUrl = async <T extends keyof Types>(
    type: T,
    id: string,
    property: MediaPropertiesOf<MetaTypes[T]>,
    mime_type: string
  ): Promise<string | undefined> => {
    try {
      const result = await this.typeMethod(type, "PATCH", id, {
        body: {
          data: {},
          meta: {
            media: {
              [property]: {
                mime_type,
              },
            },
          },
        },
      }).catch(err => {
        console.error(`Error occurred while getting upload url:`, err);
        return;
      });
      return path_or(
        undefined,
        ["meta", "media", `${property}`, "upload_url"],
        result || {}
      );
    } catch (err) {
      console.error(`Error occurred while getting upload url:`, err);
      return;
    }
  };

  // ==================
  // ===== USERS ======
  // ==================

  getUser: ApiConnectorMethod_BoundTypeGetRecord<"User"> = this.typeMethod.bind(
    this,
    "User",
    "GET"
  );

  getUsers: ApiConnectorMethod_BoundTypeGetList<"User"> = this.typeMethod.bind(
    this,
    "User",
    "GET",
    undefined
  );

  updateUser: ApiConnectorMethod_BoundTypeMethod<"User", "PATCH"> =
    this.typeMethod.bind(this, "User", "PATCH");

  createUser: ApiConnectorMethod_BoundTypeMethod<"User", "POST"> =
    this.typeMethod.bind(this, "User", "POST");

  deleteUser: ApiConnectorMethod_BoundTypeMethod<"User", "DELETE"> =
    this.typeMethod.bind(this, "User", "DELETE");

  // ==================
  // ===== GROUPS =====
  // ==================

  getGroups: ApiConnectorMethod_BoundTypeGetList<"Group"> =
    this.typeMethod.bind(this, "Group", "GET", undefined);

  getGroup: ApiConnectorMethod_BoundTypeGetRecord<"Group"> =
    this.typeMethod.bind(this, "Group", "GET");

  createGroup: ApiConnectorMethod_BoundTypeCreateRecord<"Group"> =
    this.typeMethod.bind(this, "Group", "POST", undefined);

  updateGroup: ApiConnectorMethod_BoundTypeMethod<"Group", "PATCH"> =
    this.typeMethod.bind(this, "Group", "PATCH");

  deleteGroup: ApiConnectorMethod_BoundTypeMethod<"Group", "DELETE"> =
    this.typeMethod.bind(this, "Group", "DELETE");

  //  GROUP MEMBERSHIP

  groupJoin: ApiConnectorMethod_BoundTypeCreateRecord<"GroupJoin"> =
    this.typeMethod.bind(this, "GroupJoin", "POST", undefined);

  groupLeave: ApiConnectorMethod_BoundTypeCreateRecord<"GroupLeave"> =
    this.typeMethod.bind(this, "GroupLeave", "POST", undefined);

  addGroupMember: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_member",
    "POST"
  > = this.relationshipMethod.bind(this, "Group", "has_member", "POST");

  removeGroupMember: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_member",
    "DELETE"
  > = this.relationshipMethod.bind(this, "Group", "has_member", "DELETE");

  addGroupAdmin: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_admin",
    "POST"
  > = this.relationshipMethod.bind(this, "Group", "has_admin", "POST");

  removeGroupAdmin: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_admin",
    "DELETE"
  > = this.relationshipMethod.bind(this, "Group", "has_admin", "DELETE");

  getGroupMembers: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_member",
    "GET"
  > = this.relationshipMethod.bind(this, "Group", "has_member", "GET");

  addGroupLabels: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_label",
    "POST"
  > = this.relationshipMethod.bind(this, "Group", "has_label", "POST");

  removeGroupLabels: ApiConnectorRelationshipMethod_Bound<
    "Group",
    "has_label",
    "DELETE"
  > = this.relationshipMethod.bind(this, "Group", "has_label", "DELETE");

  // ==================
  // = GROUPS LABELS ==
  // ==================

  getGroupLabels: ApiConnectorMethod_BoundTypeGetList<"CommunityGroupLabel"> =
    this.typeMethod.bind(this, "CommunityGroupLabel", "GET", undefined);

  getGroupLabel: ApiConnectorMethod_BoundTypeGetRecord<"CommunityGroupLabel"> =
    this.typeMethod.bind(this, "CommunityGroupLabel", "GET");

  createGroupLabel: ApiConnectorMethod_BoundTypeCreateRecord<"CommunityGroupLabel"> =
    this.typeMethod.bind(this, "CommunityGroupLabel", "POST", undefined);

  // ==================
  // = GROUPS INVITES =
  // ==================

  getGroupInvites: ApiConnectorMethod_BoundTypeGetList<"GroupInvitation"> =
    this.typeMethod.bind(this, "GroupInvitation", "GET", undefined);

  createGroupInvite: ApiConnectorMethod_BoundTypeCreateRecord<"GroupInvitation"> =
    this.typeMethod.bind(this, "GroupInvitation", "POST", undefined);

  // =================
  // ===== POSTS =====
  // =================

  getPosts: ApiConnectorMethod_BoundTypeGetList<"Post"> = this.typeMethod.bind(
    this,
    "Post",
    "GET",
    undefined
  );

  getPost: ApiConnectorMethod_BoundTypeGetRecord<"Post"> = this.typeMethod.bind(
    this,
    "Post",
    "GET"
  );

  createPost: ApiConnectorMethod_BoundTypeCreateRecord<"Post"> =
    this.typeMethod.bind(this, "Post", "POST", undefined);

  updatePost: ApiConnectorMethod_BoundTypeMethod<"Post", "PATCH"> =
    this.typeMethod.bind(this, "Post", "PATCH");

  deletePost: ApiConnectorMethod_BoundTypeMethod<"Post", "DELETE"> =
    this.typeMethod.bind(this, "Post", "DELETE");

  // =================
  // == TOUCHPOINTS ==
  // =================

  getTouchpoints: ApiConnectorMethod_BoundTypeGetList<"Touchpoint"> =
    this.typeMethod.bind(this, "Touchpoint", "GET", undefined);

  getTouchpoint: ApiConnectorMethod_BoundTypeGetRecord<"Touchpoint"> =
    this.typeMethod.bind(this, "Touchpoint", "GET");

  createTouchpoint: ApiConnectorMethod_BoundTypeCreateRecord<"Touchpoint"> =
    this.typeMethod.bind(this, "Touchpoint", "POST", undefined);

  updateTouchpoint: ApiConnectorMethod_BoundTypeMethod<"Touchpoint", "PATCH"> =
    this.typeMethod.bind(this, "Touchpoint", "PATCH");

  deleteTouchpoint: ApiConnectorMethod_BoundTypeMethod<"Touchpoint", "DELETE"> =
    this.typeMethod.bind(this, "Touchpoint", "DELETE");

  // =================
  // === EXPENSES ====
  // =================

  getExpenses: ApiConnectorMethod_BoundTypeGetList<"Expense"> =
    this.typeMethod.bind(this, "Expense", "GET", undefined);

  getExpense: ApiConnectorMethod_BoundTypeGetRecord<"Expense"> =
    this.typeMethod.bind(this, "Expense", "GET");

  createExpense: ApiConnectorMethod_BoundTypeCreateRecord<"Expense"> =
    this.typeMethod.bind(this, "Expense", "POST", undefined);

  updateExpense: ApiConnectorMethod_BoundTypeMethod<"Expense", "PATCH"> =
    this.typeMethod.bind(this, "Expense", "PATCH");

  deleteExpense: ApiConnectorMethod_BoundTypeMethod<"Expense", "DELETE"> =
    this.typeMethod.bind(this, "Expense", "DELETE");

  // =================
  // ==== MOODS ===
  // =================

  getMoods: ApiConnectorMethod_BoundTypeGetList<"Mood"> = this.typeMethod.bind(
    this,
    "Mood",
    "GET",
    undefined
  );

  setCapturedMood: ApiConnectorMethod_BoundTypeCreateRecord<"CapturedMood"> =
    this.typeMethod.bind(this, "CapturedMood", "POST", undefined);

  // =================
  // ==== COMMENTS ===
  // =================

  getComments: ApiConnectorMethod_BoundTypeGetList<"Comment"> =
    this.typeMethod.bind(this, "Comment", "GET", undefined);

  createComment: ApiConnectorMethod_BoundTypeCreateRecord<"Comment"> =
    this.typeMethod.bind(this, "Comment", "POST", undefined);

  updateComment: ApiConnectorMethod_BoundTypeMethod<"Comment", "PATCH"> =
    this.typeMethod.bind(this, "Comment", "PATCH");

  deleteComment: ApiConnectorMethod_BoundTypeMethod<"Comment", "DELETE"> =
    this.typeMethod.bind(this, "Comment", "DELETE");

  // =================
  // ===== REACTIONS =====
  // =================

  getReactions: ApiConnectorMethod_BoundTypeGetList<"Reaction"> =
    this.typeMethod.bind(this, "Reaction", "GET", undefined);

  createReaction: ApiConnectorMethod_BoundTypeCreateRecord<"Reaction"> =
    this.typeMethod.bind(this, "Reaction", "POST", undefined);

  deleteReaction: ApiConnectorMethod_BoundTypeMethod<"Reaction", "DELETE"> =
    this.typeMethod.bind(this, "Reaction", "DELETE");

  // =================
  // ===== GOALS =====
  // =================

  getGoal: ApiConnectorMethod_BoundTypeGetRecord<"Goal"> = this.typeMethod.bind(
    this,
    "Goal",
    "GET"
  );

  getGoals: ApiConnectorMethod_BoundTypeGetList<"Goal"> = this.typeMethod.bind(
    this,
    "Goal",
    "GET",
    undefined
  );

  createGoal: ApiConnectorMethod_BoundTypeCreateRecord<"Goal"> =
    this.typeMethod.bind(this, "Goal", "POST", undefined);

  updateGoal: ApiConnectorMethod_BoundTypeMethod<"Goal", "PATCH"> =
    this.typeMethod.bind(this, "Goal", "PATCH");

  deleteGoal: ApiConnectorMethod_BoundTypeMethod<"Goal", "DELETE"> =
    this.typeMethod.bind(this, "Goal", "DELETE");

  // =================
  // == GOAL PRESETS =
  // =================

  getGoalPresets: ApiConnectorMethod_BoundTypeGetList<"GoalPreset"> =
    this.typeMethod.bind(this, "GoalPreset", "GET", undefined);

  // =================
  // ===== GOALS =====
  // =================

  getTodo: ApiConnectorMethod_BoundTypeGetRecord<"Todo"> = this.typeMethod.bind(
    this,
    "Todo",
    "GET"
  );

  getTodos: ApiConnectorMethod_BoundTypeGetList<"Todo"> = this.typeMethod.bind(
    this,
    "Todo",
    "GET",
    undefined
  );

  createTodo: ApiConnectorMethod_BoundTypeCreateRecord<"Todo"> =
    this.typeMethod.bind(this, "Todo", "POST", undefined);

  updateTodo: ApiConnectorMethod_BoundTypeMethod<"Todo", "PATCH"> =
    this.typeMethod.bind(this, "Todo", "PATCH");

  deleteTodo: ApiConnectorMethod_BoundTypeMethod<"Todo", "DELETE"> =
    this.typeMethod.bind(this, "Todo", "DELETE");

  // =================
  // == CONNECTIONS ==
  // =================

  getConnection: ApiConnectorMethod_BoundTypeGetRecord<"Connection"> =
    this.typeMethod.bind(this, "Connection", "GET");

  getConnections: ApiConnectorMethod_BoundTypeGetList<"Connection"> =
    this.typeMethod.bind(this, "Connection", "GET", undefined);

  createConnection: ApiConnectorMethod_BoundTypeCreateRecord<"Connection"> =
    this.typeMethod.bind(this, "Connection", "POST", undefined);

  updateConnection: ApiConnectorMethod_BoundTypeMethod<"Connection", "PATCH"> =
    this.typeMethod.bind(this, "Connection", "PATCH");

  deleteConnection: ApiConnectorMethod_BoundTypeMethod<"Connection", "DELETE"> =
    this.typeMethod.bind(this, "Connection", "DELETE");

  // =================
  // CONNECTION REQUESTS
  // =================

  getConnectionRequests: ApiConnectorMethod_BoundTypeGetList<"ConnectionRequest"> =
    this.typeMethod.bind(this, "ConnectionRequest", "GET", undefined);

  createConnectionRequest: ApiConnectorMethod_BoundTypeCreateRecord<"ConnectionRequest"> =
    this.typeMethod.bind(this, "ConnectionRequest", "POST", undefined);

  acceptConnectionRequest: ApiConnectorMethod_BoundTypeMethod<
    "ConnectionRequest",
    "PATCH"
  > = this.typeMethod.bind(this, "ConnectionRequest", "PATCH");

  declineConnectionRequest: ApiConnectorMethod_BoundTypeMethod<
    "ConnectionRequest",
    "DELETE"
  > = this.typeMethod.bind(this, "ConnectionRequest", "DELETE");

  // =================
  // == COMMUNITIES ==
  // =================

  getCommunities: ApiConnectorMethod_BoundTypeGetList<"Community"> =
    this.typeMethod.bind(this, "Community", "GET", undefined);

  getCommunity: ApiConnectorMethod_BoundTypeGetRecord<"Community"> =
    this.typeMethod.bind(this, "Community", "GET");

  updateCommunity: ApiConnectorMethod_BoundTypeMethod<"Community", "PATCH"> =
    this.typeMethod.bind(this, "Community", "PATCH");

  addCommunityMember: ApiConnectorRelationshipMethod_Bound<
    "Community",
    "has_member",
    "POST"
  > = this.relationshipMethod.bind(this, "Community", "has_member", "POST");

  removeCommunityMember: ApiConnectorRelationshipMethod_Bound<
    "Community",
    "has_member",
    "DELETE"
  > = this.relationshipMethod.bind(this, "Community", "has_member", "DELETE");

  addCommunityGroup: ApiConnectorRelationshipMethod_Bound<
    "Community",
    "has_group",
    "POST"
  > = this.relationshipMethod.bind(this, "Community", "has_group", "POST");

  removeCommunityGroup: ApiConnectorRelationshipMethod_Bound<
    "Community",
    "has_group",
    "DELETE"
  > = this.relationshipMethod.bind(this, "Community", "has_group", "DELETE");

  // =================
  // ==== EVENTS =====
  // =================

  getEvent: ApiConnectorMethod_BoundTypeGetRecord<"Event"> =
    this.typeMethod.bind(this, "Event", "GET");

  getEvents: ApiConnectorMethod_BoundTypeGetList<"Event"> =
    this.typeMethod.bind(this, "Event", "GET", undefined);

  createEvent: ApiConnectorMethod_BoundTypeCreateRecord<"Event"> =
    this.typeMethod.bind(this, "Event", "POST", undefined);

  updateEvent: ApiConnectorMethod_BoundTypeMethod<"Event", "PATCH"> =
    this.typeMethod.bind(this, "Event", "PATCH");

  deleteEvent: ApiConnectorMethod_BoundTypeMethod<"Event", "DELETE"> =
    this.typeMethod.bind(this, "Event", "DELETE");

  createEventInvite: ApiConnectorRelationshipMethod_Bound<
    "Event",
    "has_invitee",
    "POST"
  > = this.relationshipMethod.bind(this, "Event", "has_invitee", "POST");

  // =================
  // ===== RSVPs =====
  // =================

  getEventRSVP: ApiConnectorMethod_BoundTypeGetRecord<"EventRSVP"> =
    this.typeMethod.bind(this, "EventRSVP", "GET");

  getEventRSVPs: ApiConnectorMethod_BoundTypeGetList<"EventRSVP"> =
    this.typeMethod.bind(this, "EventRSVP", "GET", undefined);

  createEventRSVP: ApiConnectorMethod_BoundTypeCreateRecord<"EventRSVP"> =
    this.typeMethod.bind(this, "EventRSVP", "POST", undefined);

  updateEventRSVP: ApiConnectorMethod_BoundTypeMethod<"EventRSVP", "PATCH"> =
    this.typeMethod.bind(this, "EventRSVP", "PATCH");

  deleteEventRSVP: ApiConnectorMethod_BoundTypeMethod<"EventRSVP", "DELETE"> =
    this.typeMethod.bind(this, "EventRSVP", "DELETE");

  // =================
  // ==== ADDRESS ====
  // =================

  getAddress: ApiConnectorMethod_BoundTypeGetRecord<"Address"> =
    this.typeMethod.bind(this, "Address", "GET");

  getAddresses: ApiConnectorMethod_BoundTypeGetList<"Address"> =
    this.typeMethod.bind(this, "Address", "GET", undefined);

  createAddress: ApiConnectorMethod_BoundTypeCreateRecord<"Address"> =
    this.typeMethod.bind(this, "Address", "POST", undefined);

  updateAddress: ApiConnectorMethod_BoundTypeMethod<"Address", "PATCH"> =
    this.typeMethod.bind(this, "Address", "PATCH");

  deleteAddress: ApiConnectorMethod_BoundTypeMethod<"Address", "DELETE"> =
    this.typeMethod.bind(this, "Address", "DELETE");

  // =================
  // == EXPERIENCES ==
  // =================

  getExperiences: ApiConnectorMethod_BoundTypeGetList<"ExperienceCategory"> =
    this.typeMethod.bind(this, "ExperienceCategory", "GET", undefined);

  // =================
  //  CUSTOM IDENTITY
  // =================

  getCustomIdentities: ApiConnectorMethod_BoundTypeGetList<"CustomIdentity"> =
    this.typeMethod.bind(this, "CustomIdentity", "GET", undefined);

  getCustomIdentity: ApiConnectorMethod_BoundTypeGetRecord<"CustomIdentity"> =
    this.typeMethod.bind(this, "CustomIdentity", "GET");

  createCustomIdentity: ApiConnectorMethod_BoundTypeCreateRecord<"CustomIdentity"> =
    this.typeMethod.bind(this, "CustomIdentity", "POST", undefined);

  // =================
  // == INTERACTIONS =
  // =================

  getInteractions: ApiConnectorMethod_BoundTypeGetList<"Interaction"> =
    this.typeMethod.bind(this, "Interaction", "GET", undefined);

  getInteraction: ApiConnectorMethod_BoundTypeGetRecord<"Interaction"> =
    this.typeMethod.bind(this, "Interaction", "GET");

  createInteraction: ApiConnectorMethod_BoundTypeCreateRecord<"Interaction"> =
    this.typeMethod.bind(this, "Interaction", "POST", undefined);

  // =================
  // ==== NOTIFS =====
  // =================

  getNotifications: ApiConnectorMethod_BoundTypeGetList<"Notification"> =
    this.typeMethod.bind(this, "Notification", "GET", undefined);

  updateNotification: ApiConnectorMethod_BoundTypeMethod<
    "Notification",
    "PATCH"
  > = this.typeMethod.bind(this, "Notification", "PATCH");

  // =================
  // ==== SURVEY =====
  // =================

  selfSurveyStart: () => Promise<
    DocWithData<MetaTypes["TraitifyAssessment"], false>
  > = this.typeMethod.bind(this, "TraitifyAssessment", "POST", undefined, {
    body: { data: {} },
  });

  getSelfSurveys: ApiConnectorMethod_BoundTypeGetList<"TraitifyAssessment"> =
    this.typeMethod.bind(this, "TraitifyAssessment", "GET", undefined);

  selfSurveySubmit = async (
    id: string,
    slides: any
  ): Promise<DocWithData<MetaTypes["TraitifyAssessment"], false>> => {
    return this.typeMethod("TraitifyAssessment", "PATCH", id, {
      body: { data: { attributes: { assessment: { slides } } } },
    });
  };

  // =================
  // === FEEDBACK ====
  // =================

  getFeedbackInvitations: ApiConnectorMethod_BoundTypeGetList<"AssessmentFeedbackInvitation"> =
    this.typeMethod.bind(
      this,
      "AssessmentFeedbackInvitation",
      "GET",
      undefined
    );

  createFeedbackInvitation: ApiConnectorMethod_BoundTypeCreateRecord<"AssessmentFeedbackInvitation"> =
    this.typeMethod.bind(
      this,
      "AssessmentFeedbackInvitation",
      "POST",
      undefined
    );

  resendFeedbackInvitation: ApiConnectorMethod_BoundTypeMethod<
    "AssessmentFeedbackInvitation",
    "PATCH"
  > = this.typeMethod.bind(this, "AssessmentFeedbackInvitation", "PATCH");

  // =================
  // == REG INVITE ===
  // =================

  getRegistrationInvitations: ApiConnectorMethod_BoundTypeGetList<"RegistrationInvitation"> =
    this.typeMethod.bind(this, "RegistrationInvitation", "GET", undefined);
  createRegistrationInvitation: ApiConnectorMethod_BoundTypeCreateRecord<"RegistrationInvitation"> =
    this.typeMethod.bind(this, "RegistrationInvitation", "POST", undefined);

  // =================
  // == FULFILLMENT ==
  // =================

  getFulfillmentSurveys: ApiConnectorMethod_BoundTypeGetList<"RelationshipFulfillmentSurvey"> =
    this.typeMethod.bind(
      this,
      "RelationshipFulfillmentSurvey",
      "GET",
      undefined
    );
  createFulfillmentSurvey: ApiConnectorMethod_BoundTypeCreateRecord<"RelationshipFulfillmentSurvey"> =
    this.typeMethod.bind(
      this,
      "RelationshipFulfillmentSurvey",
      "POST",
      undefined
    );

  // =================
  // ==== ZENDESK ====
  // =================

  zendeskGuideLogin = () => this.request("POST", "zendesk/login");

  zendeskSupportLogin = () =>
    this.request(
      "POST",
      "zendesk/support",
      `user_token=${this.token}`,
      undefined,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );
}
