import {
  ButtonWithIcon,
  InputWithFormHelpers,
  WithFloatingTitle,
  Icon,
  Tooltip,
} from "@thrive-web/ui-components";
import * as Preact from "preact";
import { useStateIfMounted } from "@thrive-web/ui-hooks";
import { is_valid_email, is_valid_phone_number } from "@thrive-web/ui-common";
import { useCallback, useMemo, useRef } from "preact/hooks";

const tooltip_props = { defaultOffset: "left" } as const;

export const FeedbackInviteListItem: preact.FunctionComponent<{
  index: string | number;
  value: string;
  onChange?: (index, e) => void;
  success: boolean;
  error?: DisplayableError;
  validity?: string;
}> = ({ index, value, onChange, success, error, validity = "" }) => {
  const [blurred, set_blurred] = useStateIfMounted(false);
  const validity_str = useRef(validity);
  validity_str.current = validity;
  const validate = useCallback((val: string, input?: HTMLInputElement) => {
    if (is_valid_email(val) || is_valid_phone_number(val)) {
      input?.setCustomValidity(validity_str.current);
      return true;
    }
    input?.setCustomValidity(
      "Please enter a valid email address or phone number."
    );
    return false;
  }, []);

  const onChangeInput = useMemo(
    () =>
      onChange && index != null && index !== "" && !success
        ? e => onChange(index, e)
        : () => false,
    [onChange, index]
  );

  return (
    <div className="profile-builder__feedback__item form__input-row">
      <WithFloatingTitle title="Email/Phone Number">
        <InputWithFormHelpers
          value={value}
          onChange={onChangeInput}
          validate={blurred && !!value ? validate : undefined}
          onBlur={blurred || success ? undefined : () => set_blurred(true)}
          readOnly={!onChange || success}
          data-blurred={blurred && value}
          submitOnEnter={false}
          controlled={true}
          placeholder="Email/Phone Number"
          name={`feedback-input-${index}`}
        />
        <div className="profile-builder__feedback__item__icons">
          {onChange && value && !success && (
            <ButtonWithIcon
              icon="remove"
              className="profile-builder__feedback__item__clear non-button filled gray all-gray transparent"
              onClick={onChangeInput}
              value=""
            />
          )}
          {success && (
            <Icon
              name="checked-solid-circle"
              className="profile-builder__feedback__item__success"
            />
          )}
          {error && (
            <Tooltip text={error.message} popoverProps={tooltip_props}>
              <Icon
                name="alert"
                className="profile-builder__feedback__item__error"
              />
            </Tooltip>
          )}
        </div>
      </WithFloatingTitle>
    </div>
  );
};
