import * as Preact from "preact";
import { useEffect } from "preact/hooks";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import { ErrorMessage } from "@thrive-web/ui-components";
import { useApiCall } from "@thrive-web/ui-hooks";

export const Guide: Preact.FunctionComponent = () => {
  const [login, status] = useApiCall("zendeskGuideLogin");
  useEffect(() => {
    login().then(res => {
      // @ts-expect-error:
      window.location.replace(res.meta?.zendesk?.access_url);
    });
  }, []);

  return status.error ? (
    <div className="app-loading">
      <div className="app-loading__failed">
        <h4>{status.error.code}</h4>
        <ErrorMessage>{status.error.message}</ErrorMessage>
      </div>
    </div>
  ) : (
    <div className="app-loading">
      <div className="app-loading__spinner">
        <img src={`${IMAGES_PREFIX}/logo_spinner.gif`} />
      </div>
      <div className="app-loading__status">Authenticating</div>
    </div>
  );
};
