import { MediaPropertiesOf, MetaTypes, Types } from "@thrive-web/core";
import { path_or } from "@thrive-web/ui-common";
import api from "./instance";

export const upload_media = (
  url: string,
  base64_data: string,
  mime: string,
  on_progress?: (progress: number) => void
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!url) {
      console.error(`Cannot upload media; no URL provided`);
      reject({
        code: "media/no-upload-url",
        message: "Cannot upload media; no URL provided",
      });
      return;
    }
    const binary_data = base64_to_binary(base64_data);
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url);
    xhr.setRequestHeader("Content-Type", mime);
    xhr.setRequestHeader("Cache-Control", "max-age=7200");
    xhr.onload = e => {
      console.debug(`file upload xhr completed: `, e);
      resolve();
    };
    xhr.onerror = () => {
      console.error(`upload_media error: `, xhr);
      reject({ status: xhr.status, response: JSON.parse(xhr.response) });
    };
    if (on_progress) {
      xhr.upload.onprogress = e => {
        on_progress(Math.round((e.loaded / e.total) * 100));
      };
    }
    xhr.send(binary_data);
  });

export const base64_to_binary = (uri: string): Uint8Array => {
  const data = uri.replace(/^.*?;base64,/, "");
  const bin_str = atob(data);
  const bin_data = new Uint8Array(new ArrayBuffer(bin_str.length));
  for (let i = 0; i < bin_str.length; i++) {
    bin_data[i] = bin_str.charCodeAt(i);
  }
  return bin_data;
};

// get a signed media upload url for a particular property of a record
export const get_upload_url = async <T extends keyof Types>(
  type: T,
  id: string,
  property: MediaPropertiesOf<MetaTypes[T]>,
  mime_type: string
): Promise<string | undefined> => {
  try {
    const result = await api().then(api_ =>
      api_
        .typeMethod(type, "PATCH", id, {
          body: {
            data: {},
            meta: {
              media: {
                [property]: {
                  mime_type,
                },
              },
            },
          },
        })
        .catch(err => {
          console.error(`Error occurred while getting upload url:`, err);
          return;
        })
    );
    return path_or(
      undefined,
      ["meta", "media", `${property}`, "upload_url"],
      result || {}
    );
  } catch (err) {
    console.error(`Error occurred while getting upload url:`, err);
    return;
  }
};
