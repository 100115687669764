import * as Preact from "preact";
import { AppLink, DropdownMenu } from "@thrive-web/ui-components";
import { useAppUser } from "@thrive-web/ui-hooks";

const popover_props = {
  forceDirection: "bottom",
  forceOffset: "left",
  mountLocal: true,
} as const;
export const NavMenu: Preact.FunctionComponent<{
  nav_items: (Preact.VNode | null)[];
}> = ({ nav_items }) => {
  const user = useAppUser();
  return user ? (
    <DropdownMenu
      className="site-nav__menu"
      items={[
        ...nav_items,
        <div className="site-nav__legal-links site-nav__menu__bottom">
          <AppLink
            className="plain-link"
            href="/privacy-policy"
            blurOnClick={true}
            activePathMatch="/privacy-policy"
            activeClassName="active"
          >
            Privacy Policy
          </AppLink>
          <span>|</span>
          <AppLink
            className="plain-link"
            href="/terms-of-use"
            blurOnClick={true}
            activePathMatch="/terms-of-use"
            activeClassName="active"
          >
            Terms of Use
          </AppLink>
        </div>,
      ]}
      allowLinkEventPropagation={true}
      listClassName="card pill-card"
      popoverProps={popover_props}
      buttonClassName="filled transparent all-gray"
    />
  ) : null;
};
