import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { ApiMethodParameters } from "@thrive-web/core";
import { Group } from "@thrive-web/ui-api";
import {
  comparator,
  count_label,
  remove_item_from,
  replace_item_in,
} from "@thrive-web/ui-common";
import {
  asSubroute,
  DivWithIcon,
  Icon,
  SearchBarWithSuggestions,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useAppStorage,
  useChildRef,
  useDynamicListVariable,
  useMemoRef,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { GroupList, GroupListLoading } from "~/view/components";

export interface RecentSearch {
  value: string;
  time: number;
}

const MONTH = 1000 * 60 * 60 * 24 * 30;
let force_refresh = 0;

// get list of recent search strings for dropdown under search bar
export const useRecentSearches = (
  key: "groups" | "home",
  onSubmit: (text: string) => void
) => {
  const store = useAppStorage();
  const recent_ = store?.get(["recent_searches", key]);
  const [, set_refresh] = useStateIfMounted(0);
  const recent = useMemoRef<RecentSearch[]>(() => {
    if (!recent_ || !Array.isArray(recent_)) {
      return [];
    }
    // get items from the past month, newest first
    return recent_
      .filter(
        item => !!item?.time && item?.value && item.time > Date.now() - MONTH
      )
      .sort(comparator("time", "desc"));
  }, [recent_]);

  const on_submit = useCallback(
    (text: string) => {
      // store search string with current timestamp, replace if it exists
      if (text) {
        store?.set(
          ["recent_searches", key],
          replace_item_in(
            recent.current,
            i => i.value === text,
            { value: text, time: Date.now() },
            "start"
          )
        );
      }
      set_refresh(++force_refresh);
      onSubmit(text);
    },
    [onSubmit, store]
  );

  const on_remove = useCallback(
    (item: string) => {
      if (!item) {
        return;
      }
      store?.set(
        ["recent_searches", key],
        remove_item_from(recent.current, i => i.value === item)
      );
      set_refresh(++force_refresh);
    },
    [store]
  );

  // array of just the search strings
  const recent_str = useMemo(
    () => recent.current.map(i => i.value),
    [recent.current, force_refresh]
  );

  return [recent_str, on_submit, on_remove] as const;
};

export const GroupSearchBar: Preact.FunctionComponent<{
  onSubmit: (str: string) => void;
  value: string;
}> = ({ onSubmit, value }) => {
  const [recent, on_submit, on_remove] = useRecentSearches("groups", onSubmit);
  const [input_ref, get_ref] = useChildRef<HTMLInputElement>();

  const RenderItem = useRenderPropsFunction<OptionsListItemProps<string>>(
    ({ item, onSelect }) => (
      <div className="dropdown-input__item search-bar__suggestions__item">
        <div
          className="dropdown-input__item--default"
          onClick={() => onSelect(item)}
        >
          {item}
        </div>
        <Icon
          name="remove"
          onMouseUpCapture={e => {
            // @ts-expect-error:
            e.alreadySubmitted = true;
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            on_remove(item);
            input_ref.current?.focus();
          }}
        />
      </div>
    ),
    "SearchBarSuggestionItem",
    [on_remove]
  );

  return (
    <SearchBarWithSuggestions
      value={value}
      suggestions={recent}
      onSubmit={on_submit}
      placeholder="Search Groups"
      autoSubmit={false}
      RenderItem={RenderItem}
      getInputRef={get_ref}
    />
  );
};

const GroupSearchBase: Preact.FunctionComponent<
  RoutePageProps & { search: string; setSearch: (str: string) => void }
> = ({ search, setSearch }) => {
  const [list, dispatch] = useDynamicListVariable<Group>(null);
  const params = useMemo<ApiMethodParameters<"GET", "Group", false>>(
    () => ({
      query: {
        filter: [["match", ["this", "Group:name"], search, "i"]],
        include: ["has_admin"],
      },
    }),
    [search]
  );
  const get_groups = useApiFetchPaged("getGroups", params);

  const passthrough = useMemo(
    () => ({
      showCount: (total: number) =>
        !!total && (
          <div className="page-tab__section__title">
            {count_label(total, "result")} for "{search}"
          </div>
        ),
    }),
    [search]
  );

  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem, _, passthru) => (
      <Preact.Fragment>
        {passthru?.showCount(passthru.total)}
        <GroupList key="search" groups={result} loadMoreElem={load_more_elem} />
      </Preact.Fragment>
    ),
    [],
    get_groups,
    passthrough,
    { PendingView: GroupListLoading, limit: 15 }
  );

  return (
    <div className="page-tab__section group-detail__search">
      <div className="breadcrumb">
        <DivWithIcon
          icon="chevron-left"
          side="left"
          className="breadcrumb-item plain-link blue"
          onClick={() => setSearch("")}
        >
          Back
        </DivWithIcon>
      </div>
      {content}
    </div>
  );
};

export const GroupSearch = asSubroute(GroupSearchBase);
