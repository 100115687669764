import * as Preact from "preact";
import { InputWithFormHelpers } from "./inputs";
import { data_attrs } from "@thrive-web/ui-utils";

export class PhoneNumberInput extends InputWithFormHelpers<
  string,
  { keepFormat?: boolean },
  { cur_value?: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      cur_value: this.format(props.value),
    };
  }

  format = (raw?: string): string | undefined => {
    if (!raw) {
      return;
    }

    let str = raw.replace(/[^0-9]/g, "");
    if (str.length < 3) {
      return str;
    }
    str = `${str.slice(0, 3)}-${str.slice(3)}`;
    if (str.length < 7) {
      return str;
    }
    str = `${str.slice(0, 7)}-${str.slice(7)}`;
    return str.length > 12 ? str.slice(0, 12) : str;
  };

  unformat = (str?: string): string | undefined => {
    if (!str) {
      return;
    }
    str = str.replace(/[^0-9]/g, "");
    return str.length > 10 ? str.slice(0, 10) : str;
  };

  onChange = e => {
    const { cur_value } = this.state;
    let raw = e.target.value;
    if (
      cur_value &&
      cur_value.slice(-1) === "-" &&
      cur_value.length > e.target.value.length
    ) {
      raw = raw.slice(0, -1);
    }
    e.target.value =
      (this.props.keepFormat ? this.format(raw) : this.unformat(raw)) ||
      undefined;
    this.onChangeBound(e);
    this.setState(
      {
        cur_value: this.format(raw),
      },
      () =>
        this.updateAttributes({
          value: this.state.cur_value,
          required: this.props.required,
        })
    );
  };

  render() {
    const {
      value,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      ...props
    } = this.props;
    const { cur_value } = this.state;
    return (
      <input
        {...data_attrs({
          value: cur_value,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        value={cur_value || ""}
        {...props}
        pattern="([0-9]{3}-){2}[0-9]{4}"
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}
