import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { ConnectionMapped, ConnectionRequest, User } from "@thrive-web/ui-api";
import { EVENTS } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  add_item_to,
  analytics,
  make_displayable_error,
  type_iri,
} from "@thrive-web/ui-common";
import {
  DynamicListDispatch,
  RemovableListItem,
  RemovableListItemComponentProps,
  RemovableListItemProps,
  RequestButtonWithIcon,
  Tooltip,
  UserListItem,
  PEOPLE_CONNECTIONS,
  PEOPLE_REQUESTS_INCOMING,
  PEOPLE_REQUESTS_OUTGOING,
} from "@thrive-web/ui-components";
import { useApiRequest, useAppUser } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";

export interface ConnectionRequestListItemProps extends MaybeClass {
  direction: "incoming" | "outgoing";
  request: ConnectionRequest;
  style?: Partial<CSSStyleDeclaration>;
  dispatch?: DynamicListDispatch<ConnectionRequest, false, []>;
}
export const ConnectionRequestListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<
    ConnectionRequestListItemProps,
    { data: ConnectionRequest | null }
  >
> = ({ request, direction, className, onRemoveItem, dispatch }) => {
  const onComplete = useCallback(
    (data: { data: ConnectionRequest | null }) =>
      onRemoveItem(data).then(() => dispatch?.remove(r => r.id === request.id)),
    [onRemoveItem, request.id, dispatch]
  );
  return (
    <UserListItem
      user={
        (direction === "incoming" ? request.sender : request.recipient) as User
      }
      className={`people-requests__list__item${maybeClassName(className)}`}
      linkBox="content"
    >
      {direction === "incoming" ? (
        <ConnectionRequestIncomingControls
          request={request}
          onComplete={dispatch ? onComplete : onRemoveItem}
        />
      ) : (
        <ConnectionRequestOutgoingControls
          request={request}
          onComplete={dispatch ? onComplete : onRemoveItem}
        />
      )}
    </UserListItem>
  );
};

type ConnectionRequestListItemWrappedProps = Omit<
  RemovableListItemProps<
    ConnectionRequestListItemProps,
    { data: ConnectionRequest | null }
  >,
  "Component" | "onRemoveItem"
>;

export const ConnectionRequestListItem: Preact.FunctionComponent<ConnectionRequestListItemWrappedProps> =
  props => (
    <RemovableListItem
      delay={1200}
      {...props}
      Component={ConnectionRequestListItemBase}
    />
  );

// accept/ignore buttons
export const ConnectionRequestIncomingControls: Preact.FunctionComponent<{
  request: ConnectionRequest;
  onComplete: (result: { data: ConnectionRequest | null }) => Promise<void>;
}> = ({ request, onComplete }) => {
  const self = useAppUser();
  const dispatch_state = useContext(CONTEXTS.dispatch);
  const req_params = useMemo(
    () => ({
      body: { data: { attributes: { accepted: true } } },
    }),
    []
  );
  const [
    approve,
    {
      pending: approve_pending,
      success: approve_success,
      error: approve_error,
    },
  ] = useApiRequest("acceptConnectionRequest", request.id, req_params);
  const [
    ignore,
    { pending: ignore_pending, success: ignore_success, error: ignore_error },
  ] = useApiRequest("declineConnectionRequest", request.id);
  const { dispatch } = useContext(PEOPLE_REQUESTS_INCOMING);
  const removeItem = useCallback(
    () => dispatch?.remove(c => c.id === request.id),
    [request, dispatch]
  );

  const dispatch_conn = useContext(PEOPLE_CONNECTIONS).dispatch;
  const addTempConn = useCallback(
    result => {
      if (!self) {
        return result;
      }
      const temp_conn: ConnectionMapped = {
        id: "",
        type: type_iri("Connection"),
        // @ts-expect-error:
        users: [request.recipient, request.sender],
        // @ts-expect-error:
        other_user: request.sender,
        connection_request: request,
        created_at: new Date().toISOString(),
      };
      dispatch_conn?.add(temp_conn);
      dispatch_state({
        type: EVENTS.UPDATE_AUTH_USER,
        payload: {
          path: ["has_connection"],
          value: add_item_to(self.has_connection || [], temp_conn),
        },
      });
      return result;
    },
    [request, dispatch_conn]
  );

  const onClickApprove = useCallback(
    () =>
      approve()
        .then(res => {
          analytics.log_event(analytics.EVENTS.connection_request_accepted);
          return res;
        })
        .then(addTempConn)
        .then(onComplete)
        .then(removeItem),
    [approve, addTempConn, onComplete, removeItem]
  );
  const onClickIgnore = useCallback(
    () =>
      ignore()
        .then(res => {
          analytics.log_event(analytics.EVENTS.connection_request_declined);
          return res;
        })
        .then(onComplete)
        .then(removeItem),
    [ignore, onComplete, removeItem]
  );

  return (
    <Preact.Fragment>
      <RequestButtonWithIcon
        className="filled success"
        icon="checked"
        pending={approve_pending}
        success={approve_success}
        successText="Approved!"
        onClick={ignore_pending || ignore_success ? undefined : onClickApprove}
        error={approve_error}
        disabled={ignore_pending || ignore_success}
        showError={true}
        retryText="Retry"
      >
        Approve
      </RequestButtonWithIcon>
      <RequestButtonWithIcon
        className="filled gray"
        icon="remove"
        pending={ignore_pending}
        success={ignore_success}
        successText="Ignored"
        onClick={approve_pending || approve_success ? undefined : onClickIgnore}
        error={ignore_error}
        disabled={approve_pending || approve_success}
        showError={true}
        retryText="Retry"
      >
        Ignore
      </RequestButtonWithIcon>
    </Preact.Fragment>
  );
};

// cancel request button
export const ConnectionRequestOutgoingControls: Preact.FunctionComponent<{
  request: ConnectionRequest;
  onComplete: (data: { data: null }) => void;
}> = ({ request, onComplete }) => {
  const [cancel_request, { pending, success, error }] = useApiRequest(
    "declineConnectionRequest",
    request.id
  );
  const { dispatch } = useContext(PEOPLE_REQUESTS_OUTGOING);
  const removeItem = useCallback(
    () => dispatch.remove(c => c.id === request.id),
    [request, dispatch]
  );

  return (
    <Preact.Fragment>
      <Tooltip
        text={
          error
            ? `An error occurred while cancelling this request.\n${
                make_displayable_error(error).message
              }`
            : ""
        }
        delay={1000}
        disabled={!error || pending}
        defaultDirection="left"
      >
        <RequestButtonWithIcon
          className="filled gray"
          icon="remove"
          pending={pending}
          success={success}
          successText="Success!"
          onClick={() =>
            cancel_request()
              .then(res => {
                analytics.log_event(
                  analytics.EVENTS.connection_request_canceled
                );
                return res;
              })
              .then(onComplete)
              .then(removeItem)
          }
        >
          {error ? "Retry" : "Cancel Request"}
        </RequestButtonWithIcon>
      </Tooltip>
    </Preact.Fragment>
  );
};
