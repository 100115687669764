import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { should_leave } from "@thrive-web/ui-model";
import {
  useCallbackRef,
  useDelayedEvent,
  useFocus,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { closestAncestor, group_updates_allowed } from "@thrive-web/ui-utils";
import {
  ButtonWithIcon,
  DropdownMenu,
  POST_SCOPE_OPTIONS,
  PostCreateModal,
  PostFormBaseProps,
  PostFormPage,
} from "@thrive-web/ui-components";

const POST_CREATE_OVERFLOW_MENU_ID = "post-create-overflow-menu";

const popover_props = {
  defaultDirection: "bottom",
} as const;

export const PostCreatePrompt: Preact.FunctionComponent<
  PostFormBaseProps & {
    allowGroupUpdates?: boolean | null;
    allowScopeChange?: boolean;
  }
> = ({ allowGroupUpdates, ...props }) => {
  const { set_thread_only } = useContext(POST_SCOPE_OPTIONS);
  const [initial_page, set_initial_page] = useStateIfMounted<
    PostFormPage | undefined
  >(undefined);
  const [focused, listeners] = useFocus();

  const [open, setOpen] = useStateIfMounted(false);

  const [log_event, cancel_event] = useDelayedEvent((event: string) => {
    analytics.log_event(event, undefined, undefined, true);
  });

  const onClose = useCallbackRef(() => {
    cancel_event();
    set_thread_only(false);
    set_initial_page(undefined);
  }, [set_initial_page]);
  const dismiss = useCallback(() => {
    if (should_leave()) {
      onClose.current && onClose.current();
      set_thread_only(false);
      setOpen(false);
    }
  }, [setOpen]);

  // flag to open the modal on the next render
  const open_on_update = useRef(false);
  useEffect(() => {
    if (open_on_update.current) {
      open_on_update.current = false;
      setOpen(true);
    }
  });

  const allow_group_update = useMemo(() => {
    if (allowGroupUpdates !== undefined) {
      return allowGroupUpdates;
    }
    return group_updates_allowed(props.defaultScope);
  }, [allowGroupUpdates, props.defaultScope]);

  // creates a callback that will open the post form, starting on the given page
  const open_to_page = useCallback(
    (page: PostFormPage) => e => {
      e.preventDefault();
      e.stopPropagation();
      if (page === "touchpoint" || page === "expense") {
        log_event(
          analytics.EVENTS.action_button[
            page === "touchpoint" ? "group_update" : "reimbursement"
          ]
        );
        set_thread_only(true);
      }
      open_on_update.current = true;
      set_initial_page(page);
    },
    [set_initial_page]
  );

  return (
    <div
      className="card card-stack post__create post__create__prompt"
      onClick={e => {
        if (
          e.target &&
          !closestAncestor(
            e.target as HTMLElement,
            `.popover__trigger.post__controls__widgets__menu`
          )
        ) {
          setOpen(true);
        }
      }}
      data-focus={focused}
    >
      <div className="post__create__main">
        <div className="post__create__input">
          <textarea
            readOnly
            className="card__textarea"
            placeholder="What's up?"
            {...listeners}
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                e.stopPropagation();
                setOpen(true);
              }
            }}
          />
        </div>
      </div>
      <div className="post__controls">
        <div className="post__controls__widgets">
          <ButtonWithIcon
            className="filled gray"
            icon="camera-2"
            // @ts-expect-error:
            onClick={open_to_page("photo")}
          />
          <ButtonWithIcon
            className="filled gray"
            icon="connection-request"
            onClick={open_to_page("tag")}
          />
          <ButtonWithIcon
            className="filled gray"
            icon="mood-share"
            // @ts-expect-error:
            onClick={open_to_page("mood")}
          />
        </div>
        <DropdownMenu
          id={POST_CREATE_OVERFLOW_MENU_ID}
          className="post__controls__widgets__menu"
          items={[
            <ButtonWithIcon
              className="filled gray"
              side="left"
              icon="helping-hands"
              onClick={open_to_page("help")}
            >
              I Need Help
            </ButtonWithIcon>,
            allow_group_update ? (
              <ButtonWithIcon
                icon="amplify"
                side="left"
                className="filled gray"
                onClick={open_to_page("touchpoint")}
              >
                Update your Group
              </ButtonWithIcon>
            ) : null,
            allow_group_update ? (
              <ButtonWithIcon
                icon="expense"
                side="left"
                className="filled gray"
                onClick={open_to_page("expense")}
              >
                Get Reimbursement
              </ButtonWithIcon>
            ) : null,
          ]}
          buttonClassName="filled gray"
          listClassName="card pill-card"
          allowLinkEventPropagation={false}
          closeOnClickItem={true}
          popoverProps={popover_props}
        />
      </div>

      <PostCreateModal
        {...props}
        initialPage={initial_page}
        dismiss={dismiss}
        open={open}
      />
    </div>
  );
};
