import * as Preact from "preact";
import { useCallback, useRef } from "preact/hooks";
import { useId } from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { Popover, usePopoverTrigger } from "@thrive-web/ui-components";

export interface TooltipProps extends Preact.RenderableProps<MaybeClass> {
  id?: string;
  text: string;
  delay?: number;
  animation?: PopoverProps["animation"];
  defaultDirection?: "top" | "bottom" | "left" | "right";
  defaultOffset?: "top" | "bottom" | "left" | "right";
  disabled?: boolean;
  popoverProps?: Omit<PopoverProps, "triggerComponent" | "show" | "className">;
}

export const Tooltip: Preact.FunctionComponent<TooltipProps> = ({
  id,
  text,
  className,
  animation,
  delay = 0,
  defaultDirection,
  defaultOffset,
  disabled,
  children,
  popoverProps,
}) => {
  const source_ref = useRef<HTMLDivElement>();
  const _id = useId(id, "tooltip");
  const [show, , trigger_props] = usePopoverTrigger(
    { click: false, focus: false, hover: true },
    _id,
    delay
  );
  const get_source_ref = useCallback(() => source_ref.current, [source_ref]);
  if (!popoverProps) {
    popoverProps = {};
  }
  if (!popoverProps.getSourceRef && popoverProps.mountLocal) {
    popoverProps.getSourceRef = get_source_ref;
  }
  return (
    <Popover
      id={_id}
      className={`tooltip${maybeClassName(className)}`}
      triggerComponent={
        <div
          {...trigger_props}
          ref={source_ref}
          className={`tooltip__trigger${maybeClassName(
            trigger_props.className
          )}`}
        >
          {children}
        </div>
      }
      show={!disabled && show}
      delay={delay}
      animation={animation}
      defaultDirection={defaultDirection}
      defaultOffset={defaultOffset}
      {...popoverProps}
    >
      {text}
    </Popover>
  );
};
