import * as Preact from "preact";
import { getCurrentUrl } from "preact-router";
import { useContext, useMemo } from "preact/hooks";
import {
  Avatar,
  AppLink,
  Icon,
  ZendeskWidgetButton,
} from "@thrive-web/ui-components";
import { useAppUser } from "@thrive-web/ui-hooks";
import { get_url_for_entity, maybeClassName } from "@thrive-web/ui-utils";
import { AppUser } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";

const USERBAR_LINKS: NavLinkSpec[] = [
  { href: "/logout", text: "Logout", icon: "logout" },
];

export const create_userbar_links = (
  user: AppUser | null,
  className = "site-userbar__item",
  only_show_logout = false,
  notification_menu?: Preact.VNode
) =>
  useMemo(
    () =>
      !user
        ? []
        : [
            <AppLink
              href={get_url_for_entity(user)}
              className={`site-userbar__profile${maybeClassName(className)}`}
              blurOnClick={true}
            >
              <Avatar user={user} size="sm" />
              <span className="site-userbar__name">{user.full_name}</span>
            </AppLink>,
            !only_show_logout
              ? notification_menu || (
                  <AppLink
                    href="/notifications"
                    className={`site-userbar__activity${maybeClassName(
                      className
                    )}`}
                    activeClassName="active"
                    blurOnClick={true}
                  >
                    <span>Notifications</span>
                    <Icon name="notifications" />
                  </AppLink>
                )
              : null,
            !only_show_logout ? (
              <ZendeskWidgetButton className={className} user={user} />
            ) : null,
            ...USERBAR_LINKS.map(meta =>
              !only_show_logout || meta.href === "/logout" ? (
                <AppLink
                  href={meta.href}
                  className={`site-userbar__${meta.href.replace(
                    "/",
                    ""
                  )}${maybeClassName(className)}`}
                  blurOnClick={true}
                >
                  <span>{meta.text}</span>
                  <Icon name={meta.icon} />
                </AppLink>
              ) : null
            ),
          ],
    [user, className]
  );

// right sidebar with notifs, user profile, logout, and support buttons
export const UserSidebar: Preact.FunctionComponent<{
  notificationMenu?: Preact.VNode;
  onlyLogout?: boolean;
}> = ({ notificationMenu, onlyLogout }) => {
  const user = useAppUser();
  const auth = useContext(CONTEXTS.auth);
  const only_show_logout =
    !auth?.userProfileBuilderComplete || !auth?.hasGroups || onlyLogout;
  const [userItem, ...items] = create_userbar_links(
    user,
    undefined,
    only_show_logout,
    notificationMenu
  );
  if (!user || !auth || getCurrentUrl() === "/guide") {
    return null;
  }
  return (
    <div id="site-userbar" className="site-userbar">
      <div className="site-userbar__content">
        <div className="site-userbar__top">{!only_show_logout && userItem}</div>
        <div className="site-userbar__bottom">{items}</div>
      </div>
    </div>
  );
};
