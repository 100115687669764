import * as Preact from "preact";
import { AppUser } from "@thrive-web/ui-model";
import { Icon, open_zendesk_widget } from "@thrive-web/ui-components";
import { maybeClassName } from "@thrive-web/ui-utils";

export const ZendeskWidgetButton: Preact.FunctionComponent<
  MaybeClass & { user: AppUser }
> = ({ className, user }) => (
  <button
    className={`site-userbar__support${maybeClassName(className)}`}
    onClick={() =>
      open_zendesk_widget({
        name: {
          value: user.full_name,
          readOnly: true,
        },
        email: {
          value: user.email,
          readOnly: true,
        },
      })
    }
  >
    <span>Support</span>
    <Icon name="help-solid-solid" />
  </button>
);
