import * as Preact from "preact";
import { class_names, maybeClassName } from "@thrive-web/ui-utils";
import {
  SvgLoadingSpinner,
  Tooltip,
  withIcon,
} from "@thrive-web/ui-components";
import { make_displayable_error } from "@thrive-web/ui-common";

const PENDING_ANIMATION_DURATION = 300;

export class RequestButton<P extends object = {}> extends Preact.Component<
  HTMLButtonProps &
    P & {
      pending: boolean;
      success?: boolean;
      successText?: string | Preact.VNode;
      error?: any;
      retryText?: string;
      showError?: boolean;
      progress?: number;
    },
  {
    animationIsPlaying: boolean;
    cur_pending: boolean;
    width?: number;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      animationIsPlaying: false,
      cur_pending: props.pending,
    };
    this.success_text = Preact.createRef();
    this.content = Preact.createRef();
    this.initial_width_set = false;
  }
  success_text: Preact.RefObject<HTMLDivElement>;
  content: Preact.RefObject<HTMLDivElement>;
  anim_timer: any = null;
  initial_width_set: boolean;

  componentDidMount(): void {
    this.setState({
      width: this.getWidth(),
    });
  }

  componentWillUnmount(): void {
    if (this.anim_timer) {
      clearTimeout(this.anim_timer);
    }
  }

  componentDidUpdate(prevProps) {
    const { cur_pending, animationIsPlaying } = this.state;
    const { pending, progress, children } = this.props;
    // if the status is changing and there's no animation playing
    if (pending !== cur_pending && !animationIsPlaying) {
      // set the next status and play the animation
      this.playAnimation();
    } else if (
      !this.initial_width_set ||
      (pending && progress !== undefined && prevProps.progress === undefined) ||
      ((typeof children === "string" ||
        typeof prevProps.children === "string") &&
        (typeof children !== typeof prevProps.children ||
          children !== prevProps.children))
    ) {
      const width = this.getWidth();
      if (width != null) {
        this.setState({
          width,
        });
      }
    }
  }

  playAnimation = () => {
    const { pending } = this.props;
    const { cur_pending, animationIsPlaying } = this.state;
    if (this.anim_timer) {
      clearTimeout(this.anim_timer);
    }
    // if an animation is in queue and no animation is currently playing
    if (cur_pending !== pending && !animationIsPlaying) {
      this.setState(
        {
          animationIsPlaying: true,
          cur_pending: pending,
          width: this.getWidth() || this.state.width,
        },
        () => {
          this.anim_timer = setTimeout(() =>
            // after the duration of the animation, remove the status from the queue
            // and set it as the current status, then play the next animation
            {
              this.setState(
                { animationIsPlaying: false },
                this.maybePlayNextAnimation
              );
              this.anim_timer = null;
            }, PENDING_ANIMATION_DURATION);
        }
      );
    } else {
      this.anim_timer = setTimeout(() => {
        this.anim_timer = null;
        this.maybePlayNextAnimation();
      }, PENDING_ANIMATION_DURATION);
    }
  };

  maybePlayNextAnimation = () => {
    const { pending } = this.props;
    if (this.state.cur_pending !== pending) {
      this.playAnimation();
    }
  };

  getWidth = () => {
    if (
      this.success_text &&
      this.success_text.current &&
      this.content &&
      this.content.current
    ) {
      this.initial_width_set = true;
      return Math.max(
        this.content.current.offsetWidth,
        this.success_text.current.offsetWidth
      );
    }
  };

  render() {
    const {
      className,
      success,
      successText,
      error,
      retryText = "Retry",
      children,
      pending: _,
      showError = false,
      progress,
      style,
      ...props
    } = this.props;
    const { width, cur_pending } = this.state;

    const button = (
      <button
        {...props}
        className={`${class_names(
          {
            ["--pending"]: cur_pending,
            ["--success"]: success,
            ["--error"]: !!error,
            ["--progress"]: progress !== undefined,
          },
          "request-button"
        )}${maybeClassName(className)}`}
        // @ts-expect-error:
        style={width ? { width: `${width}px`, ...(style || {}) } : style}
      >
        {progress !== undefined && (
          <div
            className="request-button__progress"
            data-done={`${progress === 100}`}
            style={{ width: `${progress}%`, flexBasis: `${progress}%` }}
          />
        )}
        <div className="request-button__content" ref={this.content}>
          {cur_pending && progress !== undefined && progress < 100
            ? "Uploading file..."
            : error
            ? retryText
            : children}
        </div>
        <div className="request-button__pending">
          {progress === undefined || progress === 100 ? (
            <SvgLoadingSpinner />
          ) : (
            "Uploading file..."
          )}
        </div>
        <div className="request-button__success" ref={this.success_text}>
          {successText || children}
        </div>
      </button>
    );
    return showError ? (
      <Tooltip
        className="request-button__error-message"
        text={error ? make_displayable_error(error).message : ""}
        delay={1000}
        disabled={!error}
        defaultDirection="left"
      >
        {button}
      </Tooltip>
    ) : (
      button
    );
  }
}

export class RequestButtonWithIcon extends RequestButton<
  WithIconProps & {
    retryIcon?: FontIconName;
    successIcon?: FontIconName;
  }
> {
  constructor(props) {
    super(props);
    this.ChildrenWithIcon.displayName = "RequestButtonWithIconChildren";
  }
  ChildrenWithIcon = withIcon<Preact.RenderableProps<any>, any>(
    ({ children }) => <Preact.Fragment>{children}</Preact.Fragment>
  );
  render() {
    const {
      className,
      pending,
      success,
      successText,
      children,
      icon,
      side = "left",
      error,
      retryText = "Retry",
      retryIcon = "jump-ahead",
      successIcon = "checked",
      showError = false,
      progress,
      style,
      ...props
    } = this.props;
    const { width, cur_pending } = this.state;
    const { ChildrenWithIcon } = this;
    const icon_only = children == null || children === "";
    const button = (
      <button
        {...props}
        className={`${class_names(
          {
            ["--pending"]: cur_pending,
            ["--success"]: success,
            ["--error"]: !!error,
            ["--progress"]: progress !== undefined,
            ["__with-icon"]: true,
          },
          "request-button"
        )}${icon_only ? " icon-only" : ""}${maybeClassName(className)}`}
        // @ts-expect-error:
        style={width ? { width, ...(style || {}) } : style}
      >
        {progress !== undefined && (
          <div
            className="request-button__progress"
            data-done={`${progress === 100}`}
            style={{ width: `${progress}%`, flexBasis: `${progress}%` }}
          />
        )}
        <div
          className={`request-button__content with-icon${
            icon_only ? " icon-only" : ""
          }`}
          ref={this.content}
        >
          <ChildrenWithIcon icon={error ? retryIcon : icon} side={side}>
            {cur_pending && progress !== undefined && progress < 100
              ? "Uploading file..."
              : error
              ? retryText
              : children}
          </ChildrenWithIcon>
        </div>
        <div className="request-button__pending">
          {progress === undefined || progress === 100 ? (
            <SvgLoadingSpinner />
          ) : (
            "Uploading file..."
          )}
        </div>
        <div
          className={`request-button__success${
            successIcon ? " with-icon" : ""
          }${icon_only ? " icon-only" : ""}`}
          ref={this.success_text}
        >
          {successIcon ? (
            <ChildrenWithIcon icon={successIcon} side={side}>
              {successText || children}
            </ChildrenWithIcon>
          ) : (
            successText || children
          )}
        </div>
      </button>
    );
    return showError ? (
      <Tooltip
        className="request-button__error-message"
        text={error ? make_displayable_error(error).message : ""}
        delay={1000}
        disabled={!error}
        defaultDirection="left"
      >
        {button}
      </Tooltip>
    ) : (
      button
    );
  }
}
