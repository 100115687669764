import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  useAppUser,
  useFirstShown,
  useModal,
  useRouteMatch,
} from "@thrive-web/ui-hooks";
import {
  display_text,
  get_url_for_entity,
  group_role_of,
} from "@thrive-web/ui-utils";
import {
  GroupSidebar,
  GroupDetailLoading,
  GroupMemberLeaveModal,
  GroupDetailJoin,
} from "~/view/components";
import {
  GroupEditModal,
  GroupDeleteModal,
  LocalProvider,
  PageBody,
  PageContent,
  PageHeader,
  TabLinks,
  DynamicListProvider,
  LinkWithIcon,
  GROUP_DETAIL_CONTEXTS,
  GROUP_DETAIL_LIST_CONTEXTS,
  GROUP_GROUP,
  ButtonWithIcon,
} from "@thrive-web/ui-components";

const createGroupTabLinks = (group: Group): NavLinkSpec[] => {
  if (!group || !group.id) {
    return [];
  }
  const url = get_url_for_entity(group);
  const links: NavLinkSpec[] = [
    {
      href: `${url}/activity`,
      icon: "activity",
      text: "Activity",
      activePathMatch: `${url}/activity/:post?/:whatever?`,
    },
    { href: `${url}/members`, icon: "people", text: "Members" },
  ];
  if (group.has_goals) {
    links.push({
      href: `${url}/goals`,
      icon: "goal",
      text: "Goals",
      activePathMatch: `${url}/goals/:goal?/:whatever?`,
    });
    links.push({
      href: `${url}/todos`,
      icon: "checked-broken-circle",
      text: "To-Dos",
      activePathMatch: `${url}/todos/:todo?/:whatever?`,
    });
  }
  links.push({
    href: `${url}/about`,
    icon: "info-solid-circle",
    text: "About",
  });
  return links;
};

export const GroupDetailPageHeader: Preact.FunctionComponent = () => {
  const pathname = useContext(CONTEXTS.location);
  const group = useContext(GROUP_GROUP);
  const dispatch_g = useContext(GROUP_DETAIL_CONTEXTS.dispatch);
  const { dispatch } = useContext(GROUP_DETAIL_LIST_CONTEXTS.members);

  const user = useAppUser();
  const tab_links = useMemo(
    () => (group ? createGroupTabLinks(group) : []),
    [group]
  );
  const role = useMemo(
    () => (user && group ? group_role_of(user, group) : false),
    [user, group]
  );
  const is_admin = role === "has_admin";

  const bodyProps = useMemo(
    () => ({
      // upon joining a public group, add user to members list
      onFinish: () => {
        if (!group || !user) {
          return;
        }
        dispatch_g("group", {
          ...group,
          has_member: [...(group.has_member ?? []), user],
        });
        dispatch.add(user);
      },
    }),
    [user?.id, group?.has_member, dispatch]
  );
  const [modal, set_open] = useModal({
    id: "join-group-modal",
    className: "group-detail__join__modal",
    body: GroupDetailJoin,
    showCloseButton: true,
    bodyProps,
  });

  // extra links in the tab-links dropdown
  const extras = useMemo(() => {
    if (!group || !user || !role) {
      return;
    }
    const extra_links: any[] = [];
    if (
      !window.location.pathname.endsWith("/members") &&
      (is_admin || group.can_members_invite)
    ) {
      // show invite member button
      extra_links.push(
        <LinkWithIcon
          icon="connection-request"
          side="left"
          className="button pill filled gray"
          href={`${get_url_for_entity(group, "/members#invite")}`}
        >
          Invite a Member
        </LinkWithIcon>
      );
    }
    if (is_admin) {
      extra_links.push(<GroupEditModal group={group} />);
    }
    if (!!role && (!is_admin || (group?.has_admin?.length || 0) > 1)) {
      extra_links.push(<GroupMemberLeaveModal group={group} />);
    }
    /*extra_links.push(
      <ButtonWithIcon icon="report" side="left" className="pill filled gray">
        Report This Group
      </ButtonWithIcon>
    );*/
    if (is_admin) {
      extra_links.push(<GroupDeleteModal group={group} />);
    }
    return extra_links;
  }, [group, user, is_admin, pathname, role]);
  if (!user) {
    return null;
  }
  if (!group) {
    return <GroupDetailLoading />;
  }
  return (
    <PageHeader>
      <div className="page-header__title">{display_text(group.name)}</div>
      <div className="page-header__children__flex">
        <TabLinks links={tab_links} extras={extras} />
        {!group.is_private && !role && (
          <ButtonWithIcon
            icon="add-solid-circle"
            side="left"
            className="color-overlay group-detail__join__button"
            style={{
              "--color-overlay-background-color": group.background_color || "",
              backgroundColor: group.background_color,
            }}
            onClick={() => set_open(true)}
          >
            Join this Group
            {modal}
          </ButtonWithIcon>
        )}
      </div>
    </PageHeader>
  );
};

// todo: todos and possibly other data refetches after group fetch finishes
/** header, tab links, initial fetch for group and related records */
export const GroupDetailOuter: Preact.FunctionComponent<{
  group?: Group;
  fetch: () => Promise<void>;
}> = ({ group, fetch, children }) => {
  const user = useAppUser();
  const url = useMemo(() => (group ? get_url_for_entity(group) : ""), [group]);
  const show_sidebar =
    useRouteMatch(`${url}/activity/:post?/:whatever?`) &&
    (!!group?.has_goals || !!group?.has_relationship_management);
  const sidebar_first_shown = useFirstShown(show_sidebar);

  const initial_ctx = useMemo(() => ({ group, fetch }), [group, fetch]);

  if (!user) {
    return null;
  }

  return (
    <PageContent
      id="group-detail-page"
      className={`group-page detail-page${show_sidebar ? " has-sidebar" : ""}`}
    >
      {Object.entries(GROUP_DETAIL_LIST_CONTEXTS).reduce(
        (
          children_,
          [key, spec]: EntryOf<typeof GROUP_DETAIL_LIST_CONTEXTS>
        ) => (
          <DynamicListProvider
            context={spec}
            key={key}
            {...(key === "members"
              ? {
                  initialValue: (group?.has_member || []).filter(
                    m => "email" in m
                  ),
                }
              : {
                  fetch: fetch[key],
                })}
          >
            {children_}
          </DynamicListProvider>
        ),
        <LocalProvider
          contexts={GROUP_DETAIL_CONTEXTS}
          initialValues={initial_ctx}
        >
          <GroupDetailPageHeader />
          <PageBody>
            {children}
            {sidebar_first_shown && (
              <GroupSidebar path="/groups/:id/activity/:post?" />
            )}
          </PageBody>
        </LocalProvider>
      )}
    </PageContent>
  );
};
