import * as Preact from "preact";
import { PureComponent } from "preact/compat";
import { DEFAULT_PAGE_SIZE } from "@thrive-web/ui-constants";
import {
  add_item_to,
  ArrayInsertPosSpecifier,
  make_displayable_error,
  remove_item_from,
  replace_item_in,
} from "@thrive-web/ui-common";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { DocBase } from "@thrive-web/core";
import { RequestStatus } from "@thrive-web/ui-model";
import {
  AsyncRender,
  DefaultErrorView,
  DefaultPendingView,
  PageLoaderProps,
  useAsyncRenderResult,
  AsyncRenderPaged,
  ASYNC_RENDER_PAGED_DEFAULT_PROPS,
} from "@thrive-web/ui-components";

type DynamicListCtxSpecBase<T, Fetch extends boolean, Args extends any[]> = {
  list: T[] | null;
  dispatch: DynamicListDispatch<T, Fetch, Args>;
};

// if fetch is true, the request status is available for context consumers
export type DynamicListCtxSpec<
  T,
  Fetch extends boolean,
  Args extends any[]
> = Fetch extends true
  ? DynamicListCtxSpecBase<T, Fetch, Args> & {
      status: RequestStatus;
    }
  : DynamicListCtxSpecBase<T, Fetch, Args> & { status?: never };

// the dispatcher is available to context consumers for updating the list
// if fetch is true, the "fetch" function is available on the dispatcher
export type DynamicListDispatch<
  T,
  Fetch extends boolean,
  Args extends any[]
> = Pick<
  DynamicListProvider<T, Fetch, Args>,
  Fetch extends true
    ? "add" | "remove" | "update" | "reset" | "concat" | "fetch"
    : "add" | "remove" | "update" | "reset" | "concat"
>;

export const DynamicListDispatchDefault: DynamicListDispatch<
  any,
  boolean,
  any[]
> = {
  add: () => {},
  remove: () => {},
  update: () => {},
  reset: () => {},
  concat: () => {},
  fetch: () => Promise.resolve(),
};

export const DynamicListStatusDefault: RequestStatus = {
  pending: false,
  success: false,
  error: undefined,
};

type DynamicListProviderPropsBase<
  T,
  Fetch extends boolean,
  Args extends any[]
> = {
  context: Preact.Context<DynamicListCtxSpec<T, Fetch, Args>>;
  initialValue?: T[] | readonly T[];
};

// If Fetch is true, we provide a function to fetch the up-to-date value of the list
type DynamicListProviderProps<
  T,
  Fetch extends boolean,
  Args extends any[]
> = Fetch extends true
  ? DynamicListProviderPropsBase<T, Fetch, Args> & {
      fetch: (...args: Args) => Promise<DocBase & { data: T[] | null }>;
    }
  : DynamicListProviderPropsBase<T, Fetch, Args> & { fetch?: never };

/**
 * This class is intended for use with async requests for lists of records
 * that can be edited/added/removed in the ui.
 * This class is not intended to be extended by a subclass.
 * */
export class DynamicListProvider<
  T,
  Fetch extends boolean,
  Args extends any[]
> extends PureComponent<
  DynamicListProviderProps<T, Fetch, Args>,
  {
    pending: boolean;
    success: boolean;
    error?: any;
  }
> {
  constructor(props) {
    super(props);
    this.list = null;
    if (props.initialValue) {
      this.list = props.initialValue as T[];
    }
    this.state = {
      pending: !!props.fetch && !props.initialValue,
      success: false,
    };
    this.value = {
      list: this.list ? this.list.slice() : null,
      dispatch: {
        add: this.add,
        remove: this.remove,
        update: this.update,
        reset: this.reset,
        concat: this.concat,
        fetch: this.fetch,
      },
    } as DynamicListCtxSpec<T, Fetch, Args>;
    if (props.fetch) {
      this.value.status = this.state;
    }
  }
  private mounted: boolean;
  private list: T[] | null;
  private value: DynamicListCtxSpec<T, Fetch, Args>;

  // need to track mounted because the list update methods are typically
  // invoked in callbacks for async requests, and the component may unmount
  // while a request is in flight
  componentDidMount() {
    this.mounted = true;
    const { fetch } = this.props;
    if (fetch) {
      // @ts-ignore
      this.fetchData();
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  private setStateIfMounted: DynamicListProvider<T, Fetch, Args>["setState"] = (
    newState,
    callback?
  ) => {
    if (this.mounted) {
      this.setState(newState, callback);
    }
  };
  private setStatus = (
    status: Partial<RequestStatus>,
    callback?: () => void
  ) => {
    this.value = { ...this.value, status: { ...this.state, ...status } };
    this.setStateIfMounted(status, callback);
  };

  // force an update to the list value
  private refresh = () => {
    this.value = { ...this.value, list: this.list ? this.list.slice() : null };
    if (this.mounted) {
      this.forceUpdate();
    }
  };

  private fetchData = (...args: Args): Promise<void> => {
    const { fetch } = this.props;
    if (!fetch || !this.mounted) {
      return Promise.resolve();
    }
    return new Promise(resolve =>
      this.setStatus(
        {
          pending: true,
          success: false,
        },
        () => {
          fetch(...args)
            .then(result => {
              this.list = result.data;
              this.value = {
                ...this.value,
                list: this.list ? this.list.slice() : null,
              };
              this.setStatus({
                pending: false,
                success: true,
                error: undefined,
              });
              resolve();
            })
            .catch(err => {
              this.setStatus({
                pending: false,
                error: make_displayable_error(err),
              });
              resolve();
            });
        }
      )
    );
  };

  fetch = (...args: Args): Promise<void> => {
    // if request already in flight, do nothing
    if (this.state.pending) {
      return Promise.resolve();
    }
    return this.fetchData(...args);
  };

  // Methods for operating on arrays without mutating the original

  reset = (list: T[] | readonly T[] | null) => {
    this.list = list?.slice() || null;
    this.refresh();
  };

  concat = (list: T[] | readonly T[], prepend?: boolean) => {
    this.list = prepend
      ? list.concat(this.list || [])
      : (this.list || []).concat(list);
    this.refresh();
  };

  add = (item: T, pos: ArrayInsertPosSpecifier<T> = "start") => {
    if (!this.list) {
      return;
    }
    this.list = add_item_to(this.list, item, pos);
    this.refresh();
  };

  remove = (match: (item: T, index?: number) => boolean) => {
    if (!this.list) {
      return;
    }
    this.list = remove_item_from(this.list, match);
    this.refresh();
  };

  update = (
    match: (item: T, index?: number) => boolean,
    new_item: T,
    append_if_missing?: ArrayInsertPosSpecifier<T>
  ) => {
    if (!this.list) {
      return;
    }
    this.list = replace_item_in(this.list, match, new_item, append_if_missing);
    this.refresh();
  };

  render() {
    const { Provider } = this.props.context;
    return (
      // @ts-expect-error:
      <Provider value={this.value}>{this.props.children}</Provider>
    );
  }
}

/**
 * context_spec: spec with contexts for list and dispatcher
 * data: initial data
 *
 * sets the list with the initial data, if provided, and returns
 * the list, context, and request status if applicable
 * */
export const useDynamicList = <
  T extends any,
  Fetch extends boolean,
  Args extends any[]
>(
  context_spec: Preact.Context<DynamicListCtxSpec<T, Fetch, Args>>,
  data?: T[]
) => {
  const { list, dispatch, status } = useContext(context_spec);
  useEffect(() => {
    data && dispatch.reset(data);
  }, [data]);
  return [
    list,
    dispatch,
    status as Fetch extends true ? RequestStatus : undefined,
  ] as [
    T[] | null,
    DynamicListDispatch<T, Fetch, Args>,
    Fetch extends true ? RequestStatus : undefined
  ];
};

/**
 * context_spec: spec with contexts for list and dispatcher
 * fetch: async function to fetch initial data
 *
 * renders a dynamic list with AsyncRender, also returns the list value and dispatcher
 * */
export const useRenderDynamicListWithFetch = <
  T extends any,
  Args extends any[]
>(
  context_spec: Preact.Context<DynamicListCtxSpec<T, false, Args>>,
  render: (result: T[], pending?: boolean) => Preact.VNode | null,
  renderInputs: any[],
  fetch: (...args: Args) => Promise<DocBase & { data: T[] }>,
  ErrorView = DefaultErrorView,
  PendingView = DefaultPendingView,
  keepViewOnUpdate: boolean = true
) => {
  const { dispatch, list } = useContext(context_spec);
  const fetchData = useCallback(
    (...args: Args) =>
      fetch(...args).then(result => {
        dispatch.reset(result.data);
        return result;
      }),
    [fetch, dispatch]
  );
  const children = useCallback(
    () => (!list ? null : render(list)),
    [render, list]
  );
  const initialValue = useMemo(
    () => (list && list.length > 0 ? { data: list } : undefined),
    [list]
  );
  return [
    <AsyncRender
      getPromise={fetchData}
      ErrorView={ErrorView}
      PendingView={PendingView}
      keepViewOnUpdate={keepViewOnUpdate}
      initialValue={initialValue}
    >
      {children}
    </AsyncRender>,
    list,
    dispatch,
  ] as const;
};

export interface DynamicPagedListOptions {
  ErrorView?: Preact.ComponentType<{ error: DisplayableError }>;
  PendingView?: Preact.ComponentType;
  LoadMoreComponent?: Preact.ComponentType<PageLoaderProps>;
  // continue displaying the current view while the request is being resent
  keepViewOnUpdate?: boolean;
  initialOffset?: number;
  limit?: number;
  // do nothing when the promise rejects with the "api/request-already-sent" error
  ignoreResendError?: boolean;
  // resend when the identity of the fetch function changes
  resendOnFuncChange?: boolean;
}

/**
 * list: dynamic list
 * dispatch: dispatcher for `list`
 * render: render function
 * renderInputs: inputs for memoizing render function
 * fetch: async function to fetch data
 * passthroughProps: props to pass through to the render function
 *
 * renders a dynamic list with AsyncRenderPaged, also returns the component ref
 * for the AsyncRenderPaged component
 * */
export const useRenderDynamicListWithPagedFetchRef = <
  T extends any,
  Args extends [number, number?] = [number, number?],
  P extends object = {}
>(
  list: T[] | readonly T[] | null,
  dispatch: DynamicListCtxSpec<T, false, Args>["dispatch"],
  render: (
    result: readonly T[],
    load_more_elem: Preact.VNode | null,
    pending?: boolean,
    passthrough?: P & { total: number; offset: number }
  ) => Preact.VNode | null,
  renderInputs: any[],
  fetch: (...args: Args) => Promise<DocBase & { data: T[] }>,
  passthroughProps?: P,
  options: DynamicPagedListOptions = {}
) => {
  const {
    ErrorView,
    PendingView,
    keepViewOnUpdate = true,
    initialOffset = 0,
    limit = DEFAULT_PAGE_SIZE,
    LoadMoreComponent,
    ignoreResendError,
    resendOnFuncChange,
  } = { ...ASYNC_RENDER_PAGED_DEFAULT_PROPS, ...options };
  const should_reset = useRef(false);
  const comp_ref = useRef<AsyncRenderPaged<T, P>>();
  // "ref of a ref"; deref-ed right before sending a request, and if that request
  // is cancelled, the inner value of that ref is updated, and `cancelled` receives
  // a new ref for the next request.
  const cancelled = useRef({ current: false });
  const fetchData = useCallback(
    (...args: Args) => {
      const this_cancelled = cancelled.current;
      return fetch(...args).then(result => {
        // if cancelled, return a new promise that never resolves
        if (this_cancelled.current) {
          return new Promise<DocBase & { data: T[] }>(() => {});
        }
        // if should reset, replace the entire list
        if (should_reset.current) {
          dispatch.reset(result.data);
          should_reset.current = false;
        } else {
          // otherwise concat to the existing list
          dispatch.concat(result.data);
        }
        return result;
      });
    },
    [fetch, dispatch]
  );
  const children = useCallback(
    (_, load_more, pending, passthrough) =>
      !list ? null : render(list, load_more, pending, passthrough),
    [render, list]
  );
  const initialValue = useMemo<readonly T[] | undefined>(
    () => (list && list.length > 0 ? list : undefined),
    [list]
  );

  // TODO: This may not be right... (if cancelled is not set and reset is,
  //  then the in-flight request will reset the list, and the following req
  // (which will be the first one with the new `fetch`) will concat to the list)
  useLayoutEffect(() => {
    if (resendOnFuncChange) {
      cancelled.current.current = true;
      cancelled.current = { current: false };
    }
    should_reset.current = true;
  }, [fetch]);

  return [
    <AsyncRenderPaged
      ref={comp_ref}
      getPromise={fetchData}
      ErrorView={ErrorView}
      PendingView={PendingView}
      keepViewOnUpdate={keepViewOnUpdate}
      initialValue={initialValue}
      initialOffset={initialOffset}
      limit={limit}
      ignoreResendError={ignoreResendError}
      resendOnFuncChange={resendOnFuncChange}
      passthroughProps={passthroughProps}
      LoadMore={LoadMoreComponent}
    >
      {children}
    </AsyncRenderPaged>,
    comp_ref,
  ] as const;
};

/**
 * list: dynamic list
 * dispatch: dispatcher for `list`
 * render: render function
 * renderInputs: inputs for memoizing render function
 * fetch: async function to fetch data
 * passthroughProps: props to pass through to the render function
 *
 * renders a dynamic list with AsyncRenderPaged
 * */
export const useRenderDynamicListWithPagedFetch = <
  T extends any,
  Args extends [number, number?] = [number, number?],
  P extends object = {}
>(
  list: T[] | readonly T[] | null,
  dispatch: DynamicListCtxSpec<T, false, Args>["dispatch"],
  render: (
    result: readonly T[],
    load_more_elem: Preact.VNode | null,
    pending?: boolean,
    passthrough?: P & { total: number; offset: number }
  ) => Preact.VNode | null,
  renderInputs: any[],
  fetch: (...args: Args) => Promise<DocBase & { data: T[] }>,
  passthroughProps?: P,
  options: DynamicPagedListOptions = {}
) => {
  return useRenderDynamicListWithPagedFetchRef(
    list,
    dispatch,
    render,
    renderInputs,
    fetch,
    passthroughProps,
    options
  )[0];
};

// prepares a useAsyncRenderResult with the given render function and the
// context status/initial value, but does not take on responsibility of invoking
// the fetch method
export const useRenderDynamicList = <T extends any, Args extends any[]>(
  context_spec: Preact.Context<DynamicListCtxSpec<T, true, Args>>,
  render: (result: T[], pending?: boolean) => Preact.VNode | null,
  renderInputs: any[],
  ErrorView = DefaultErrorView,
  PendingView = DefaultPendingView
) => {
  const [list, dispatch, status] = useDynamicList<T, true, Args>(context_spec);
  return [
    useAsyncRenderResult(
      render,
      renderInputs,
      status,
      list,
      true,
      ErrorView,
      PendingView
    ),
    list,
    dispatch,
    status,
  ] as const;
};
