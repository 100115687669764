import * as Preact from "preact";
import { InputWithFormHelpers } from "./inputs";

const value_not_blank = value =>
  value != null && (typeof value !== "number" ? value.length === 0 : true);

export class MultiValueInput<
  T extends string | number,
  V extends any = any
> extends InputWithFormHelpers<T, MultiValueInputProps<V>> {
  constructor(props) {
    super(props);
    if (props.autofocus) {
      this.focus = true;
    }
  }
  focus: boolean = false;

  focusInput = () => {
    const node = this.input.current;
    if (node instanceof HTMLElement) {
      node.focus();
    }
  };

  onFocus = e => {
    const { onFocus } = this.props;
    this.focus = true;
    onFocus?.call(this.input.current, e);
    this.forceUpdate();
  };

  onBlur = e => {
    const { onBlur } = this.props;
    this.focus = false;
    onBlur?.call(this.input.current, e);
    this.forceUpdate();
  };

  onKeyDown = e => {
    const { values, value, onRemoveValue, onKeyDown } = this.props;
    if (
      e.key === "Backspace" &&
      values &&
      values.length > 0 &&
      value_not_blank(value)
    ) {
      onRemoveValue(values[values.length - 1]);
    }
    return onKeyDown?.call(this.input.current, e);
  };

  onRemoveValue = value => () => {
    if (!this.focus) {
      this.focusInput();
    }
    this.props.onRemoveValue(value);
  };

  render() {
    const {
      value,
      values,
      children,
      renderValue,
      onRemoveValue: __,
      validate: _,
      onChangeDebounce,
      submitOnEnter,
      debounceTime,
      inputRef,
      controlled,
      ...props
    } = this.props;
    return (
      <div
        className="mult-val-input input__container"
        data-focus={this.focus}
        data-value={this.value}
        data-empty={this.empty}
        data-valid={this.valid}
        data-dirty={this.dirty}
        data-disabled={props.disabled}
        data-required={props.required}
        data-has-values={!!values && values.length > 0}
        onClick={this.focusInput}
      >
        {values && values.map(opt => renderValue(opt, this.onRemoveValue(opt)))}
        <input
          key="mult-val-input"
          ref={this.input}
          data-value={this.value}
          data-empty={this.empty}
          data-valid={this.valid}
          data-dirty={this.dirty}
          value={value}
          {...props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onKeyDown={this.onKeyDown}
        />
        {children}
      </div>
    );
  }
}
