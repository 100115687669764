import { ApiMethodParameters } from "@thrive-web/core";
import * as Preact from "preact";
import { memo } from "preact/compat";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { ConnectionRequest } from "@thrive-web/ui-api";
import { ConnectionRequestListItem } from "~/view/components";
import {
  asSubroute,
  SearchBar,
  useSiteHeaderContent,
  useRenderDynamicListWithPagedFetch,
  ViewMoreLoader,
  DynamicListDispatch,
  connection_request_search_filter,
  PEOPLE_REQUESTS_INCOMING,
  PEOPLE_REQUESTS_OUTGOING,
  ConnectionListLoading,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useAppUser,
  useDocumentTitle,
  useDynamicListVariable,
  useStateRef,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";

const ConnectionRequestList = (
  dir: "incoming" | "outgoing",
  result: readonly ConnectionRequest[],
  dispatch: DynamicListDispatch<ConnectionRequest, false, []>,
  search?: string,
  load_more_elem?: Preact.VNode | null
) => (
  <ul className="user-list">
    {result.length ? (
      result.map(req => (
        <ConnectionRequestListItem
          key={req.id}
          request={req}
          direction={dir}
          dispatch={dispatch}
        />
      ))
    ) : (
      <div className="people-requests__list__empty">
        {search
          ? `No matches found for "${search}"`
          : `No ${dir} connection requests`}
      </div>
    )}
    {load_more_elem && (
      <li className="user-list__load-more">{load_more_elem}</li>
    )}
  </ul>
);

export const RequestsListSection: Preact.FunctionComponent<{
  search?: string;
  list: readonly ConnectionRequest[] | null;
  dispatch: DynamicListDispatch<ConnectionRequest, false, []>;
  direction: "incoming" | "outgoing";
}> = memo(({ search = "", list, dispatch, direction }) => {
  const user = useAppUser();
  const this_search = useRef(search);
  const search_ref = useValueRef(search);
  const length_ref = useValueRef(list?.length || 0);
  const is_first_req = useRef(true);
  const target_property = direction === "incoming" ? "sender" : "recipient";

  const params = useMemo<
    ApiMethodParameters<"GET", "ConnectionRequest", false>
  >(
    () => ({
      query: {
        filter: connection_request_search_filter(
          user?.id,
          search,
          target_property
        ),
        sort: [{ by: "created_at", dir: "desc" }],
        include: [`${target_property}.User:profile_picture` as const],
      },
    }),
    [user?.id, search, target_property]
  );
  const getRequests_ = useApiFetchPaged("getConnectionRequests", params);
  const getRequests = useCallback(
    (offset: number, limit?: number) =>
      getRequests_(
        is_first_req.current ? offset : length_ref.current,
        limit
      ).then(res => {
        is_first_req.current = false;
        this_search.current = search_ref.current;
        return res;
      }),
    [getRequests_]
  );

  const passthrough = useMemo(
    () => ({ search: search_ref.current, dispatch }),
    [search_ref.current, dispatch]
  );

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (data, load_more_elem, _, passthrough) =>
      ConnectionRequestList(
        direction,
        data,
        passthrough!.dispatch,
        passthrough?.search,
        is_first_req.current ? null : load_more_elem
      ),
    [],
    getRequests,
    passthrough,
    {
      PendingView: ConnectionListLoading,
      limit: 10,
      LoadMoreComponent: ViewMoreLoader,
    }
  );
});

export const RequestsList: Preact.FunctionComponent<RoutePageProps> = () => {
  useDocumentTitle(() => make_title(["Connection Requests"]), []);
  const attachSearchBar = useSiteHeaderContent(true);
  const [search, set_search, search_ref] = useStateRef("");

  const { list: incoming, dispatch: dispatch_in } = useContext(
    PEOPLE_REQUESTS_INCOMING
  );
  const { list: outgoing, dispatch: dispatch_out } = useContext(
    PEOPLE_REQUESTS_OUTGOING
  );
  const [incoming_search, dispatch_in_search] =
    useDynamicListVariable<ConnectionRequest>(null);
  const [outgoing_search, dispatch_out_search] =
    useDynamicListVariable<ConnectionRequest>(null);

  const onSubmit = useCallback((str: string) => {
    str = str.trim();
    if ((!str && !search_ref.current) || str === search_ref.current) {
      return;
    }
    set_search(str);
  }, []);

  return (
    <div className="people-requests page-tab">
      <div className="page-tab__section">
        <div className="page-tab__section__title">Incoming Requests</div>
        <div
          className="page-tab__record-list people-requests__list"
          data-hide={!!search}
        >
          <RequestsListSection
            list={incoming}
            dispatch={dispatch_in}
            direction="incoming"
          />
        </div>
        {!!search && (
          <div className="page-tab__record-list people-requests__list">
            <RequestsListSection
              list={incoming_search}
              dispatch={dispatch_in_search}
              direction="incoming"
              search={search}
            />
          </div>
        )}
      </div>
      <div className="page-tab__section">
        <div className="page-tab__section__title">Your Sent Requests</div>
        <div
          className="page-tab__record-list people-requests__list"
          data-hide={!!search}
        >
          <RequestsListSection
            list={outgoing}
            dispatch={dispatch_out}
            direction="outgoing"
          />
        </div>
        {!!search && (
          <div className="page-tab__record-list people-requests__list">
            <RequestsListSection
              list={outgoing_search}
              dispatch={dispatch_out_search}
              direction="outgoing"
              search={search}
            />
          </div>
        )}
      </div>
      {attachSearchBar(<SearchBar onSubmit={onSubmit} placeholder="Search" />)}
    </div>
  );
};

export const RequestsListPage = asSubroute(RequestsList);
