import * as Preact from "preact";
import { Group, TouchpointAction } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DivWithIcon,
  PillCheckList,
  Tooltip,
  useAsyncRenderResult,
  useTaxonomy,
  PostScopeSelector,
  WithPostScopeCtx,
} from "@thrive-web/ui-components";
import { useCallback, useMemo } from "preact/hooks";
import { join_possible_string_list } from "@thrive-web/ui-utils";

interface ActionsProps {
  group?: Group;
  setGroup?: (group: Group) => void;
  warnOnChangeGroup?: boolean;
  allowScopeChange?: boolean;
  actions: readonly TouchpointAction[];
  onSelectAction: (action: TouchpointAction, selected: boolean) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const GroupTouchpointActions: Preact.FunctionComponent<ActionsProps> = ({
  group,
  setGroup,
  warnOnChangeGroup,
  actions,
  allowScopeChange,
  onSelectAction,
  nextStep,
  prevStep,
}) => {
  const action_options_raw = useTaxonomy("TouchpointAction");
  // map taxonomy to InputOptions
  const action_options = useMemo(
    () =>
      action_options_raw.value
        ? action_options_raw.value.map(o => ({
            value: o.id,
            label: o.title || "",
          }))
        : undefined,
    [action_options_raw]
  );
  const onChangeActions = useCallback(
    (opt: InputOption<string>, checked: boolean) =>
      action_options_raw.map &&
      onSelectAction(action_options_raw.map[opt.value], checked),
    [onSelectAction, action_options_raw.map]
  );

  const passthru_props = useMemo(
    () => ({ values: actions.map(act => act.id) }),
    [actions]
  );

  const stepComplete = !!group && actions.length > 0;

  // display the checklist once the taxonomy is fetched
  const checklist = useAsyncRenderResult<InputOption<string>[]>(
    (result, _, pass_props) => (
      <PillCheckList
        options={result}
        onSelectOption={onChangeActions}
        {...pass_props}
      />
    ),
    [onChangeActions],
    action_options_raw.status,
    action_options,
    true,
    undefined,
    undefined,
    false,
    passthru_props
  );

  return (
    <div className="group-touchpoint__section group-touchpoint__actions">
      <div className="modal-form__section form__section">
        <div className="form__section-header">Group</div>
        {!setGroup || !allowScopeChange ? (
          <DivWithIcon
            icon={
              <div
                className="group__dot"
                style={
                  group?.background_color
                    ? { backgroundColor: group.background_color }
                    : undefined
                }
              />
            }
            side="left"
            className="pill filled gray static"
          >
            {group?.name}
          </DivWithIcon>
        ) : (
          <WithPostScopeCtx threadOnlyInitial={true}>
            <PostScopeSelector
              onChange={setGroup}
              value={group}
              showWarning={warnOnChangeGroup}
              useDropdown={true}
            />
          </WithPostScopeCtx>
        )}
      </div>
      <div className="modal-form__section form__section">
        <div className="form__section-header">What did you do?</div>
        {checklist}
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <button className="filled gray" onClick={prevStep}>
            Cancel
          </button>
        </div>
        <div className="modal__footer__right">
          <Tooltip
            text={`Please select ${join_possible_string_list([
              !group ? "a group" : undefined,
              actions.length === 0 ? "at least one action" : undefined,
            ])}.`}
            disabled={stepComplete}
            defaultDirection="top"
            defaultOffset="left"
          >
            <ButtonWithIcon
              className="filled gray"
              onClick={stepComplete ? nextStep : undefined}
              icon="chevron-right"
              side="right"
            >
              Next
            </ButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
