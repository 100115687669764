import * as Preact from "preact";
import { ButtonWithIcon } from "@thrive-web/ui-components";

export const RegisterFailed: Preact.FunctionComponent<{
  nextPage: () => void;
}> = ({ nextPage }) => (
  <div className="form__section register__form__section" data-part="splash">
    <h4>Something went wrong during account creation.</h4>
    <p>Please try signing up again.</p>
    <ButtonWithIcon
      icon="logo"
      side="left"
      className="filled full-width"
      onClick={() => nextPage()}
    >
      Get Started
    </ButtonWithIcon>
  </div>
);
