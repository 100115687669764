import * as Preact from "preact";
import {
  analytics,
  STORAGE_KEY_FEEDBACK_REFERRAL_ID,
} from "@thrive-web/ui-common";
import {
  useDirtyForm,
  useDocumentTitle,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import { WriteUser } from "@thrive-web/ui-api";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import {
  EmailNotVerified,
  RegisterBirthdayForm,
  RegisterEmailForm,
  RegisterFailed,
  RegisterGetStarted,
  RegisterNameForm,
  RegisterPasswordForm,
} from "~/view/components";
import { ButtonWithIcon, Carousel } from "@thrive-web/ui-components";

let max_step_done = -1;
const log_event_once = was_referred => {
  if (!was_referred) {
    return () => {};
  }
  return new_step => {
    if (new_step > max_step_done) {
      max_step_done = new_step;
      const event = [
        analytics.EVENTS.feedback_survey.signup.clicked_get_started,
        analytics.EVENTS.feedback_survey.signup.entered_name,
        analytics.EVENTS.feedback_survey.signup.entered_birthday,
        analytics.EVENTS.feedback_survey.signup.entered_email,
        analytics.EVENTS.feedback_survey.signup.entered_password,
      ][new_step];
      analytics.log_event(event);
    }
  };
};

export const RegisterForm: Preact.FunctionComponent<{
  token?: string;
  splash?: boolean;
  retry?: boolean;
}> = ({ token, splash, retry }) => {
  useDocumentTitle(() => make_title(["Join the Thread App"]), []);
  const was_referred = useMemo(() => {
    const ref_id = localStorage.getItem(STORAGE_KEY_FEEDBACK_REFERRAL_ID);
    if (ref_id) {
      localStorage.removeItem(STORAGE_KEY_FEEDBACK_REFERRAL_ID);
      analytics.set_user_id(ref_id);
      return true;
    }
  }, []);
  const log_event = useCallback(log_event_once(was_referred), [was_referred]);

  const [user, set_user, user_ref] = useStateRef<WriteUser>({});
  const [page, set_page, page_ref] = useStateRef(splash || retry ? -1 : 0);

  const clearFormDirtyState = useDirtyForm(user, "RegisterForm");

  const incrementPage = useCallback(
    (amt: 1 | -1 = 1) => {
      if (page_ref.current == null || (page_ref.current === 0 && amt === -1)) {
        set_page(0);
      } else {
        log_event(page_ref.current + amt);
        set_page(page_ref.current + amt);
      }
    },
    [set_page, page_ref]
  );

  useEffect(() => {
    if (page === 4) {
      clearFormDirtyState();
    }
  }, [page]);

  const onFinish = useCallback(
    (data: WriteUser) => {
      set_user({ ...user_ref.current, ...data });
      incrementPage();
    },
    [set_user, user_ref]
  );
  const BackButton = useCallback(
    ({ children }) => (
      <ButtonWithIcon
        className="plain-link"
        icon="chevron-left"
        side="left"
        onClick={() => incrementPage(-1)}
      >
        {children}
      </ButtonWithIcon>
    ),
    [incrementPage]
  );

  const pages = useMemo(
    () => ({
      "-1": retry ? (
        <RegisterFailed nextPage={incrementPage} />
      ) : (
        <RegisterGetStarted nextPage={incrementPage} />
      ),
      "0": <RegisterNameForm onFinish={onFinish} data={user} />,
      "1": (
        <RegisterBirthdayForm
          onFinish={onFinish}
          backButton={<BackButton>Name</BackButton>}
          data={user}
        />
      ),
      "2": (
        <RegisterEmailForm
          onFinish={onFinish}
          backButton={<BackButton>Birthday</BackButton>}
          data={user}
        />
      ),
      "3": (
        <RegisterPasswordForm
          onFinish={onFinish}
          backButton={<BackButton>Email Address</BackButton>}
          data={user}
          token={token}
        />
      ),
      "4": <EmailNotVerified email={user.email || ""} />,
    }),
    [onFinish, BackButton, user]
  );

  return (
    <div className="register__form">
      <Carousel
        className="carousel__fade"
        page={
          `${Math.min(
            page,
            Object.keys(pages).length - 1
          )}` as keyof typeof pages
        }
        items={pages}
        transitionTime={400}
        keepPagesMounted={false}
        trackHeight={true}
      />
      {!user.firebase_uuid && (
        <div className="register__form__footer">
          <a href="/login" className="plain-link">
            Already have an account?
          </a>
        </div>
      )}
    </div>
  );
};
