import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { GroupScoreIcon } from "@thrive-web/ui-components";

export const LEVEL_OFFSET = 2;
export const get_group_conn_level = (score: number) =>
  Math.floor(Math.sqrt(score + Math.pow(LEVEL_OFFSET, 2)) - LEVEL_OFFSET);

export const get_points_for_group_conn_level = (level: number) =>
  Math.pow(level + LEVEL_OFFSET, 2) - Math.pow(LEVEL_OFFSET, 2);

export const GROUP_SCORE_ANIM_DURATION = 1200;
export const GROUP_SCORE_ANIM_DELAY = GROUP_SCORE_ANIM_DURATION * 0.7;

export const GroupConnectionScoreStatic: Preact.FunctionComponent<{
  score: number;
}> = ({ score }) => {
  const [level, progress] = useMemo(() => {
    const level = get_group_conn_level(score);
    const level_pts = get_points_for_group_conn_level(level);
    const progress =
      (100 * (score - level_pts)) /
      (get_points_for_group_conn_level(level + 1) - level_pts); // xx.xx%
    return [level, progress];
  }, [score]);

  return (
    <div className="group-score__icon">
      <div className="group-score__icon__text">{level + 1}</div>
      <GroupScoreIcon progress={progress} />
    </div>
  );
};
