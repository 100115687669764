import * as Preact from "preact";
import {
  ButtonWithIcon,
  SelectUserModal,
  Tooltip,
  UserInviteListItem,
  UserSearchFn,
} from "@thrive-web/ui-components";
import { useMemo } from "preact/hooks";
import { useAppUser, useRenderPropsFunction } from "@thrive-web/ui-hooks";
import { User } from "@thrive-web/ui-api";

interface UsersProps {
  isLastPage: boolean;
  getUsers: UserSearchFn;
  selected: readonly User[];
  onSelectUser: (user: User, selected: boolean) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const GroupTouchpointUsers: Preact.FunctionComponent<UsersProps> = ({
  isLastPage,
  getUsers,
  selected,
  onSelectUser,
  nextStep,
  prevStep,
}) => {
  const app_user = useAppUser();
  const tagged_map = useMemo(() => {
    if (!selected) {
      return {};
    }
    const map: any = {};
    selected.forEach(u => {
      map[u.id] = true;
    });
    return map;
  }, [selected]);

  const renderUser = useRenderPropsFunction(
    (user: User) =>
      user.id === app_user?.id ? null : (
        <UserInviteListItem
          user={user}
          button={
            <ButtonWithIcon
              icon={!!tagged_map[user.id] ? "checked" : "add"}
              side={!!tagged_map[user.id] ? "right" : "left"}
              className={`filled gray all-gray icon-only-xs${
                !!tagged_map[user.id] ? " success" : ""
              }`}
              onClick={() => onSelectUser(user, !!tagged_map[user.id])}
            >
              {tagged_map[user.id] ? "Selected" : "Select"}
            </ButtonWithIcon>
          }
        />
      ),
    "GroupTouchpointUser-SearchItem",
    [tagged_map, onSelectUser]
  );

  if (!app_user) {
    return null;
  }

  const stepComplete = selected.length > 0;

  return (
    <div className="group-touchpoint__section group-touchpoint__users">
      <div className="modal-form__section form__section">
        <div className="form__section-header">With Who?</div>
        <SelectUserModal getUsers={getUsers} renderUser={renderUser} />
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <ButtonWithIcon
            className="filled gray"
            onClick={prevStep}
            icon="chevron-left"
            side="left"
          >
            Back
          </ButtonWithIcon>
        </div>
        <div className="modal__footer__right">
          <Tooltip
            text="Please select at least one user."
            disabled={stepComplete}
            defaultDirection="top"
            defaultOffset="left"
          >
            <ButtonWithIcon
              className="filled gray"
              onClick={stepComplete ? nextStep : undefined}
              icon={isLastPage ? "checked" : "chevron-right"}
              side="right"
            >
              {isLastPage ? "Finish" : "Next"}
            </ButtonWithIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
