import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { useId, usePopup, useStateObject } from "@thrive-web/ui-hooks";
import { maybeClassName, class_names } from "@thrive-web/ui-utils";
import {
  KILOBYTE,
  MAX_FILE_SIZE,
  MEGABYTE,
  ScreenSize,
} from "@thrive-web/ui-constants";
import {
  ButtonWithIcon,
  createImageView,
  CompoundButton,
  ImageCrop,
} from "@thrive-web/ui-components";
import { set_dirty } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";

type FileUploadState = {
  file_too_big?: boolean;
  form_dirty?: boolean;
  cur_value?: FileUploadData;
  cropped?: FileUploadData;
};

const abbr_file_size = (size: number) => {
  if (size / KILOBYTE < 1000) {
    return `${Math.round(size / KILOBYTE)}KB`;
  }
  return `${Math.round(size / MEGABYTE)}MB`;
};

export const FileUpload: Preact.FunctionComponent<FileUploadProps> = ({
  value,
  className,
  onChange,
  Button,
  children,
  maxSize = MAX_FILE_SIZE,
  useDirtyForm,
  getUrlPending,
  allowCrop,
  ...props
}) => {
  const hadInitialValue = useMemo(() => !!value, []);
  const [state, setState] = useStateObject<FileUploadState>({});
  const { file_too_big } = state;
  const id = useId(props.id, "file-upload");

  const file_input = useRef<HTMLInputElement>();
  const form = useRef<HTMLFormElement>();

  // read the file data and store it in component state
  const uploadFile = useCallback(
    e => {
      const input_file = e.target.files[0];
      if (!input_file) {
        return;
      }
      if (input_file.size > maxSize) {
        setState({ file_too_big: true });
        file_input.current?.setCustomValidity(
          `The file you selected is too large (${abbr_file_size(
            input_file.size
          )}). (Max file size: ${abbr_file_size(maxSize)})`
        );
        file_input.current?.reportValidity();
        return;
      }

      const reader = new FileReader();

      reader.onload = ev => {
        if (ev.target) {
          const new_value = {
            name: input_file.name,
            data: ev.target["result"] as string,
            mime: input_file.type,
          };
          setState({
            form_dirty: true,
            file_too_big: false,
            cur_value: new_value,
            cropped: undefined,
          });
          useDirtyForm &&
            set_dirty(true, `file-upload-${props.name || props.id}`);
          onChange(new_value);
        }
      };

      reader.readAsDataURL(input_file);
    },
    [setState]
  );

  const onClickButton = useCallback(
    () => file_input.current && file_input.current.click(),
    [file_input]
  );

  const clearInput = useCallback<EventListener>(
    e => {
      e.preventDefault();
      e.stopPropagation();
      form.current && form.current.reset();
      setState({
        form_dirty: hadInitialValue,
        cropped: undefined,
        cur_value: undefined,
        file_too_big: false,
      });
      useDirtyForm &&
        set_dirty(hadInitialValue, `file-upload-${props.name || props.id}`);
      onChange();
    },
    [form, onChange]
  );

  const onChangeCrop = useCallback(
    (cropped_data: FileUploadData) => {
      setState({ cropped: cropped_data });
      onChange(cropped_data);
    },
    [setState]
  );

  useEffect(() => {
    if (file_input.current && !file_too_big) {
      file_input.current.setCustomValidity("");
    }
  }, [file_too_big]);

  const input_vnode = (
    <input
      {...props}
      required={!value}
      id={id}
      ref={file_input}
      type="file"
      tabIndex={-1}
      onChange={uploadFile}
    />
  );

  return (
    <div className={`file-input input__hidden${maybeClassName(className)}`}>
      {props.required ? input_vnode : <form ref={form}>{input_vnode}</form>}
      <Button
        id={`${id}-button`}
        className={class_names({ "--crop": allowCrop }, "file-input__button")}
        clickInput={onClickButton}
        clearInput={props.required ? undefined : clearInput}
        file={value}
        getUrlPending={getUrlPending}
        originalFile={state.cur_value}
      >
        {allowCrop && state.cur_value && (
          <ImageCrop initialImage={state.cur_value} onChange={onChangeCrop} />
        )}
        {children}
        {props.required ? "*" : ""}
      </Button>
    </div>
  );
};

export const DefaultFileUploadButton: Preact.FunctionComponent<
  HTMLButtonProps & FileUploadButtonProps
> = ({
  file,
  name = "Image",
  clickInput,
  clearInput,
  onClick,
  className,
  ...props
}) => {
  const window_size = useContext(CONTEXTS.window_size);
  const [preview, showPreview] = usePopup(createImageView, {
    id: `${props.id}-image-preview`,
    children: file ? <img src={file.data} /> : null,
  });
  const collapse = window_size < ScreenSize.md && !!clearInput;

  if (!file) {
    return (
      <ButtonWithIcon
        {...props}
        side="left"
        icon="image"
        className={className}
        onClick={e => {
          clickInput(e);
          // @ts-ignore
          onClick && onClick(e);
        }}
      />
    );
  }

  return (
    <CompoundButton
      className={className}
      items={[
        <ButtonWithIcon
          {...props}
          className="filled gray"
          side="left"
          icon="image"
          onClick={() => showPreview(true)}
        >
          Preview {name}
          {preview}
        </ButtonWithIcon>,
        <ButtonWithIcon
          className="filled gray"
          side="left"
          icon="edit"
          title={`Change ${name}`}
          onClick={e => {
            clickInput(e);
            // @ts-ignore
            onClick && onClick(e);
          }}
        >
          {collapse ? `Change ${name}` : ""}
        </ButtonWithIcon>,
        clearInput ? (
          <ButtonWithIcon
            className="filled gray"
            side="left"
            title={`Remove ${name}`}
            icon="remove"
            onClick={clearInput}
          >
            {collapse ? `Remove ${name}` : ""}
          </ButtonWithIcon>
        ) : null,
      ]}
      collapse={collapse}
    />
  );
};
