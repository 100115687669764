import * as Preact from "preact";
import {
  validate_time,
  padZeros,
  supportsInputType,
  TIME_PATTERN,
  TIME_PARTS_MAX,
  TIME_PARTS_MIN,
} from "@thrive-web/ui-utils";
import { data_attrs, maybeClassName } from "@thrive-web/ui-utils";
import { InputWithFormHelpers } from "./inputs";
import { FakeDateOrTimeInput } from "./fake-date-or-time-input";

/** Value is a string in the format of a time string, HH:MM */
export class TimeInput<T extends "date" | "time"> extends InputWithFormHelpers<
  string,
  HTMLInputProps & {
    type?: "time";
    min?: string;
    max?: string;
    onClear?: (e) => void;
  }
> {
  constructor(props) {
    super(props);
    this.supportsNativeInput = supportsInputType("time");
  }
  supportsNativeInput: boolean;

  validate = (value: string) => {
    const { min, max } = this.props;
    return validate_time(value, this.input.current || undefined, min, max);
  };

  handleChange = e => {
    this.onChangeBound(e);
    this.updateAttributes({
      value: e.target.value,
      required: this.props.required,
    });
    if (!e.target.value && this.props.onClear) {
      this.props.onClear(e);
    }
  };

  render() {
    const {
      value,
      type,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      onClear,
      ref,
      ...props
    } = this.props;
    return this.supportsNativeInput ? (
      <input
        ref={this.input}
        type="time"
        data-native="true"
        {...data_attrs({
          value: value,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        defaultValue={this.initialValue}
        {...props}
        pattern={TIME_PATTERN}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    ) : (
      <FakeTimeInput
        {...data_attrs({
          native: "false",
          value: value as string,
          empty: this.empty,
          valid: this.valid,
          dirty: this.dirty,
        })}
        value={value}
        {...props}
        pattern={TIME_PATTERN}
        onChangeValue={v => this.handleChange(v)}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

type ThisState = FakeDateOrTimeState<"time">;
export class FakeTimeInput extends FakeDateOrTimeInput<
  "time",
  { minutesStep?: number }
> {
  constructor(props) {
    super(props);
    const state = {
      value: validate_time(props.value) ? this.stringToParts(props.value) : {},
      cur_value: props.value,
    };
    this.state = state;
    ["min", "max"].forEach((k: "min" | "max") => {
      if (props[k]) {
        let val = this.calcMinMax("min", props[k], state);
        if (val !== null) {
          state[k] = val;
        }
      }
    });
    this.state = state;
    this.inputs = {
      hours: Preact.createRef(),
      minutes: Preact.createRef(),
      am: Preact.createRef(),
    };
    this.container = Preact.createRef();
  }
  input: Preact.RefObject<HTMLInputElement>;

  validateParts = (value?: Partial<TimeParts>): boolean => {
    const { min = {}, max = {} } = this.state;
    return validate_time(
      this.partsToString(value || {}),
      this.input.current || undefined,
      this.partsToString(min),
      this.partsToString(max)
    );
  };

  getInputLimits = (name: NumericDateOrTimeParts<"time">) => ({
    min: Object.assign({}, TIME_PARTS_MIN, this.state.min)[name],
    max: Object.assign({}, TIME_PARTS_MAX, this.state.max)[name],
  });

  calcMinMax = <K extends "min" | "max">(
    key: K,
    value: string,
    state: ThisState = this.state
  ): ThisState[K] | null => {
    try {
      if (validate_time(value)) {
        const cur_value = state && state.value ? state.value : this.state.value;
        const { am, hours, minutes } = this.stringToParts(value);
        const new_state: any = { am };
        if (
          (key === "max" && (cur_value.am === am || am === "AM")) ||
          (key === "min" && (cur_value.am === am || am === "PM"))
        ) {
          new_state.hours = hours;
          if (cur_value.hours === hours) {
            new_state.minutes = minutes;
          }
        }
        return new_state;
      } else {
        console.error(
          `Invalid time string used for prop \`${key}\` in FakeTimeInput:`,
          value
        );
        return null;
      }
    } catch {
      console.error(
        `Failed to parse time string used for prop \`${key}\` in FakeTimeInput:`,
        value
      );
    }
    return null;
  };

  partsToString = ({ hours, minutes, am }: SomeTimeParts): string => {
    if (hours == null || minutes == null || !am) {
      return "";
    }
    if (am === "PM" && hours !== 12) {
      hours += 12;
    }
    if (am === "AM" && hours === 12) {
      hours = 0;
    }
    return `${padZeros(hours)}:${padZeros(minutes)}`;
  };

  stringToParts = (value: string): TimeParts => {
    let [hours, minutes] = value.split(":").map(n => parseInt(n));
    let am: "AM" | "PM" = "AM";
    if (hours >= 12) {
      hours -= 12;
      am = "PM";
    }
    if (hours === 0) {
      hours = 12;
    }
    return { hours, minutes, am };
  };

  onChangeAm = (e: KeyboardEvent) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      this.clearValue("am");
    }
    if (e.key === "1" || e.key === "a") {
      this.updateValue({ am: "AM" });
      this.selectText("am");
    } else if (e.key === "2" || e.key === "p") {
      this.selectText("am");
      this.updateValue({ am: "PM" });
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      this.selectText("am");
      this.updateValue({ am: this.state.value.am === "AM" ? "PM" : "AM" });
    } else if (e.key === "ArrowLeft") {
      this.focusInput("minutes");
      this.selectText("minutes");
    } else {
      this.updateValue({ am: this.state.value.am });
      return this.onKeyDown(e);
    }
    e.preventDefault();
    return this.onKeyDown(e);
  };

  render() {
    const {
      value: _,
      type,
      min: __,
      max: ___,
      onChangeValue,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      onChange,
      onKeyDown,
      className,
      ...props
    } = this.props;
    const { value = {}, min, max } = this.state;
    const { hours, minutes, am } = value;
    const hours_limits = this.getInputLimits("hours");
    const minutes_limits = this.getInputLimits("minutes");
    return (
      <div
        // @ts-expect-error:
        ref={this.container}
        className={`fake-datetime-input fake-datetime-input__time${maybeClassName(
          className
        )}`}
      >
        <input
          ref={this.inputs.hours}
          autoComplete="off"
          placeholder="--"
          value={padZeros(hours) || "--"}
          name="hours"
          onKeyDown={this.onKeyDownNumber("hours")}
          {...hours_limits}
          disabled={hours_limits.min === hours_limits.max}
          onChange={() => this.selectText("hours")}
          onClick={() => this.selectText("hours")}
          onFocus={this.onFocus("hours")}
          onBlur={this.onBlur("hours")}
        />
        <span>:</span>
        <input
          ref={this.inputs.minutes}
          autoComplete="off"
          placeholder="--"
          value={padZeros(minutes) || "--"}
          name="minutes"
          onKeyDown={this.onKeyDownNumber("minutes")}
          {...minutes_limits}
          disabled={minutes_limits.min === minutes_limits.max}
          onChange={() => this.selectText("minutes")}
          onClick={() => this.selectText("minutes")}
          onFocus={this.onFocus("minutes")}
          onBlur={this.onBlur("minutes")}
        />
        <input
          ref={this.inputs.am}
          autoComplete="off"
          placeholder="--"
          type="string"
          value={am || "--"}
          name="am"
          disabled={(min && min.am === "PM") || (max && max.am === "AM")}
          onKeyDown={this.onChangeAm}
          onChange={() => this.selectText("am")}
          onClick={() => this.selectText("am")}
          onFocus={this.onFocus("am")}
          onBlur={this.onBlur("am")}
          maxLength={2}
          minLength={2}
        />
        <input
          {...props}
          ref={this.input}
          value={this.partsToString(value)}
          className="datetime__hidden"
          tabIndex={-1}
        />
      </div>
    );
  }
}
