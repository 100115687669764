export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE" as const;
export const UPDATE_AUTH_CTX = "UPDATE_AUTH_CTX" as const;
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER" as const;

export const UPDATE_LOCATION = "UPDATE_LOCATION" as const;

export const SET_APP_MODAL_OPEN = "SET_APP_MODAL_OPEN" as const;
export const SET_APP_MODAL_CONTENT = "SET_APP_MODAL_CONTENT" as const;
export const OPEN_APP_MODAL = "OPEN_APP_MODAL" as const;
export const CLOSE_APP_MODAL = "CLOSE_APP_MODAL" as const;

export const SET_INTERACTION_DATA = "SET_INTERACTION_DATA" as const;

export const SET_HEADER_CONTENT = "SET_HEADER_CONTENT" as const;
