import * as Preact from "preact";
import {
  AsyncRender,
  ButtonWithIcon,
  Carousel,
  CarouselProgress,
  DefaultModalContent,
  Icon,
  useAsyncRender,
} from "@thrive-web/ui-components";
import { add_item_to, path_or } from "@thrive-web/ui-common";
import { useCallback, useMemo, useRef } from "preact/hooks";
import { TraitifyAssessment } from "@thrive-web/ui-api";
import { set_dirty } from "@thrive-web/ui-model";
import {
  preserveProps,
  useModal,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { display_text, maybeClassName } from "@thrive-web/ui-utils";

const CAROUSEL_TRANSITION_TIME = 400;

export class TraitifySurvey<
  R extends boolean | undefined
> extends Preact.Component<
  {
    data: TraitifyAssessment;
    onFinish: (data: R extends true ? any : any[]) => void;
    yesLabel?: string;
    noLabel?: string;
    setProgress?: (progress: [number, number]) => void;
    showResults?: R;
  },
  {
    results: any[];
    page: number;
  }
> {
  constructor(props) {
    super(props);
    const { slides } = props.data.assessment;
    this.state = {
      //todo: temp for testing results
      /*results: slides.slice().map(s => ({
        ...s,
        time_taken: 1,
        response: Math.round(Math.random() * 10) > 5,
      })),
      page: slides.length*/
      results: [],
      page: 0,
    };
    this.createSlides(slides);
  }
  slides: ObjectOf<any>;
  slideStartTime: any;
  inTransition: boolean;

  static defaultProps = {
    yesLabel: "Me",
    noLabel: "Not Me",
  };

  componentDidMount(): void {
    this.slideStartTime = Date.now();
    this.props.setProgress &&
      this.props.setProgress([this.state.page, this.surveySlides().length]);

    if (this.state.page >= this.surveySlides().length) {
      set_dirty(false, "survey");
      if (!this.props.showResults) {
        // @ts-expect-error:
        this.props.onFinish(this.state.results);
      }
    }
  }

  componentDidUpdate(_, prevState): void {
    const slide_count = this.surveySlides().length;
    if (prevState.page !== this.state.page) {
      this.props.setProgress &&
        this.props.setProgress([this.state.page, slide_count]);

      if (this.state.page >= slide_count) {
        set_dirty(false, "survey");
        if (!this.props.showResults) {
          // @ts-expect-error:
          this.props.onFinish(this.state.results);
        }
      }

      setTimeout(() => {
        this.inTransition = false;
        this.slideStartTime = Date.now();
      }, CAROUSEL_TRANSITION_TIME);
    }
  }

  // shortcut to avoid typing that whole path
  surveySlides = () => this.props.data.assessment?.["slides"] || [];

  // create/store the rendered slides
  createSlides = (slides: any[]) => {
    this.slides = {};
    slides.forEach((s, i) => {
      if (i < this.state.page - 1) {
        return;
      }
      const next_image = slides[i + 1]?.image_desktop;
      this.slides[`${i}`] = (
        <TraitifySurveySlide
          slide={s}
          respond={this.respondToSlide}
          loadNextImage={next_image}
        />
      );
    });
    this.slides[`${slides.length}`] = <div />;
  };

  respondToSlide = (answer: boolean) => {
    // prevent clicking during slide transition
    if (this.inTransition) {
      return;
    }
    this.inTransition = true;
    const { page, results } = this.state;
    const slide = this.surveySlides()[page];
    const slide_response = {
      ...slide,
      response: answer,
      time_taken: Date.now() - this.slideStartTime,
    };
    this.setState(
      {
        page: page + 1,
        results: add_item_to(results, slide_response),
      },
      () => results.length === 0 && set_dirty(true, "survey")
    );
  };

  render() {
    const {
      data,
      onFinish,
      children,
      yesLabel,
      noLabel,
      showResults,
      setProgress,
    } = this.props;
    const { page, results } = this.state;
    const slides = this.surveySlides();
    return (
      <div className="traitify-survey">
        {page === this.surveySlides().length && showResults ? null : (
          <div className="traitify-survey__content">
            <div className="traitify-survey__slides">
              <Carousel
                page={`${Math.min(page, slides.length - 1)}`}
                items={this.slides}
                transitionTime={CAROUSEL_TRANSITION_TIME}
              />
            </div>
            {children && (
              <div className="traitify-survey__prompt">{children}</div>
            )}
            {page < slides.length && (
              <div className="traitify-survey__slide__buttons">
                <button
                  className="traitify-survey__button__yes"
                  onClick={() => this.respondToSlide(true)}
                >
                  {yesLabel}
                </button>
                <button
                  className="traitify-survey__button__no"
                  onClick={() => this.respondToSlide(false)}
                >
                  {noLabel}
                </button>
              </div>
            )}
          </div>
        )}
        {page === slides.length && showResults ? (
          <TraitifySurveyResults
            id={data.id}
            responses={results}
            finishStep={onFinish}
          />
        ) : (
          !setProgress && (
            <CarouselProgress
              count={slides.length}
              current={page}
              maxDots={15}
            />
          )
        )}
      </div>
    );
  }
}

export const TraitifySurveySlide: Preact.FunctionComponent<{
  slide: any;
  respond: (answer: boolean) => void;
  // src url of the image on the next slide
  loadNextImage?: string;
}> = ({ slide, loadNextImage }) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className="traitify-survey__slide" ref={ref}>
      <div className="traitify-survey__slide__image">
        <img
          src={slide.image_desktop}
          alt={slide.caption}
          onLoad={() => {
            ref.current && ref.current.setAttribute("data-loaded", "true");
          }}
        />
        {
          // prefetch next slide image for smoother UX
          loadNextImage && <img className="invisible" src={loadNextImage} />
        }
      </div>
      <div className="traitify-survey__slide__caption">
        <span>{slide.caption}</span>
      </div>
    </div>
  );
};

export const TraitifySurveyResults: Preact.FunctionComponent<{
  id: string;
  responses: any[];
  finishStep: (data: any) => void;
}> = ({ id, responses, finishStep }) => {
  const [onSuccess, submitAssessment] = useAsyncRender(
    result => {
      finishStep(result.data);
      return null;
    },
    [finishStep],
    "selfSurveySubmit",
    id,
    responses
  );

  return (
    <div className="traitify-survey__results">
      <AsyncRender getPromise={submitAssessment}>{onSuccess}</AsyncRender>
    </div>
  );
};

export const TraitifySurveyResultIcon: Preact.FunctionComponent<{
  name: string;
  image: string;
  color: string;
}> = ({ name, image, color }) => (
  <div
    className="traitify-survey__result__icon"
    style={{ backgroundColor: color }}
  >
    <Icon name={name.toLowerCase() as FontIconName} />
    {/*<img className="" src={image} />*/}
  </div>
);

// remove the "..." after the result name at the beginning of the description
export const fix_description_text = (desc: string) =>
  desc.replace(/^'(.*)'\.\.\./i, "$1 ");

// renders buttons for the top 3 results with a modal for more details
export const TraitifySurveyResultsButtons: Preact.FunctionComponent<
  MaybeClass & {
    data: TraitifyAssessment;
    showFooter?: boolean;
  }
> = ({ className, showFooter, data }) => {
  const results = useMemo(() => {
    const list = path_or([], ["assessment", "personality_types"], data);
    return list.slice(0, 3).map(({ personality_type }) => ({
      name: personality_type.name,
      description: fix_description_text(personality_type.description),
      image: personality_type.badge.image_small,
      color: `#${personality_type.badge.color_1}`,
    }));
  }, [data]);

  const [_modal_target, set_modal_target] = useStateIfMounted<any>(null);
  const modal_target = preserveProps(_modal_target);

  const descriptionModalBody = useRenderPropsFunction(
    ({ dismiss, closeButton }: ModalBodyProps) =>
      modal_target ? (
        <DefaultModalContent
          title={modal_target.name}
          closeButton={closeButton}
          footer={
            showFooter ? (
              <div className="modal__footer profile-builder__footer">
                <ButtonWithIcon
                  side="right"
                  icon={<Icon name="chevron-right" />}
                  className="filled"
                  onClick={dismiss}
                >
                  Continue
                </ButtonWithIcon>
              </div>
            ) : undefined
          }
        >
          <div className="traitify-survey__results">
            <div className="traitify-survey__results__text">
              <TraitifySurveyResultIcon
                name={modal_target.name}
                image={modal_target.image}
                color={modal_target.color}
              />
              {display_text(modal_target.description)}
            </div>
          </div>
        </DefaultModalContent>
      ) : (
        // @ts-expect-error:
        dismiss() || null
      ),
    "SurveyResultsModalBody",
    [modal_target]
  );

  const onClose = useCallback(() => {
    set_modal_target(null);
  }, [set_modal_target]);

  const [descriptionModal, setDescriptionModalOpen] = useModal(
    {
      id: "profile-builder-strengths-result",
      className: "traitify-survey__results__modal",
      dismissOnClickBackdrop: true,
      body: descriptionModalBody,
    },
    onClose
  );

  const openModal = useCallback(
    result => {
      set_modal_target(result);
      setDescriptionModalOpen(true);
    },
    [set_modal_target, setDescriptionModalOpen]
  );

  return (
    <div
      className={`traitify-survey__result__list${maybeClassName(className)}`}
    >
      {results.map(r => (
        <button
          className="traitify-survey__result__button non-button filled gray all-gray"
          type="button"
          onClick={() => openModal(r)}
        >
          <TraitifySurveyResultIcon
            name={r.name}
            image={r.image}
            color={r.color}
          />
          <div className="traitify-survey__result__name">{r.name}</div>
        </button>
      ))}
      {descriptionModal}
    </div>
  );
};
