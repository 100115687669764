import { ApiMethodParameters, DocBase, FilterSpec } from "@thrive-web/core";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "preact/hooks";
import {
  ApiListMethodCaller,
  Connection,
  ConnectionMapped,
  User,
} from "@thrive-web/ui-api";
import { EVENTS } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { is_id_obj } from "@thrive-web/ui-common";
import {
  useApiFetch,
  useApiMethod,
  useRequest,
  useStateIfMounted,
  useStateRef,
  useValueRef,
} from "@thrive-web/ui-hooks";
import {
  filter_user_is_adult,
  filter_user_not_connected,
  get_cache,
} from "@thrive-web/ui-utils";

export const DEFAULT_USER_FIELDS: readonly Exclude<
  keyof User,
  "id" | "type"
>[] = [
  "first_name",
  "last_name",
  "profile_picture",
  "profile_picture_url",
  "full_name",
  "firebase_uuid",
  "email",
  "birth_date",
  "is_adult",
] as const;

export const with_profile_picture = <T extends string>(
  keys: T[]
): `${T}.User:profile_picture`[] =>
  keys.map(
    key => `${key}.User:profile_picture` as `${T}.User:profile_picture`,
    []
  );

export const connection_coach_filter = (
  user: User | undefined | null
): FilterSpec | undefined => {
  if (!user) {
    return;
  }
  return [
    ["exists", ["this", "Connection:has_coach"]],
    ["=", ["this", "Connection:users"], ["id", user.id]],
  ];
};

// create query filter object given search str
export const connection_search_filter = (
  user: User | undefined | null,
  search: string
): FilterSpec | undefined => {
  if (!user) {
    return;
  }
  let filter_clause: FilterSpec = [
    [
      "=",
      ["this", ["/", "User:has_connection", "Connection:users"]],
      ["id", user.id],
    ],
    [
      "not",
      // workaround
      ["=", ["this", "User:firebase_uuid"], user.firebase_uuid as string],
    ],
  ];

  if (search) {
    filter_clause = [
      ...filter_clause,
      ["match", ["this", "User:full_name"], search, "i"],
    ];
  }

  return filter_clause;
};

// create query filter object given search str and target user
export const connection_request_search_filter = (
  user_id: string | undefined,
  search: string | undefined,
  key: "recipient" | "sender"
): FilterSpec | undefined => {
  if (!user_id || !key) {
    return;
  }
  let filter_clause: FilterSpec = [
    ["=", ["this", "ConnectionRequest:accepted"], false],
    [
      "=",
      [
        "this",
        `ConnectionRequest:${key === "sender" ? "recipient" : "sender"}`,
      ],
      ["id", user_id],
    ],
  ];
  if (search) {
    filter_clause = [
      ...filter_clause,
      [
        "match",
        ["this", ["/", `ConnectionRequest:${key}`, "User:full_name"]],
        search,
        "i",
      ],
    ];
  }
  return filter_clause;
};

export const new_connection_search_filter = (
  user_id: string | undefined,
  search?: string
): FilterSpec | undefined => {
  if (!user_id) {
    return;
  }
  let filter_clause: FilterSpec = [
    filter_user_is_adult(),
    filter_user_not_connected(user_id),
    [
      "not",
      [
        "=",
        [
          "this",
          [
            "|",
            [
              "/",
              ["^", "ConnectionRequest:sender"],
              "ConnectionRequest:recipient",
            ],
            [
              "/",
              ["^", "ConnectionRequest:recipient"],
              "ConnectionRequest:sender",
            ],
          ],
        ],
        ["id", user_id],
      ],
    ],
  ];
  if (search) {
    filter_clause = [
      ["match", ["this", "User:full_name"], search, "i"],
      ...filter_clause,
    ];
  }
  return filter_clause;
};

// for optimization/easy access, adds property for the other user in the connection (not self)
export const map_connection_with_other_user = (
  { has_connection = [], ...other_user }: User,
  self: User
): ConnectionMapped | undefined => {
  const connection = has_connection.find(
    c => (c["users"] || []).some(u => u.id === self.id) as User
  );
  if (!connection) {
    return;
  }

  connection["users"] = [self, other_user];

  return {
    ...connection,
    other_user,
  };
};

export const useMappedConnections = (
  get_connections: ApiListMethodCaller<"getUsers">,
  self: User | null,
  search?: string
): ((
  offset: number,
  limit: number
) => Promise<DocBase & { data: ConnectionMapped[] }>) => {
  return useCallback(
    (offset: number, limit?: number) =>
      get_connections({
        query: {
          filter: connection_search_filter(self, search || ""),
          include: ["has_connection", "profile_picture"],
          sort: [{ by: "created_at", dir: "desc" }],
          include_count: true,
          offset,
          limit,
        },
      }).then(({ data, ...result }) => ({
        ...result,
        data: data
          .map(c => map_connection_with_other_user(c, self as User))
          .filter(c => !!c) as ConnectionMapped[],
      })),
    [get_connections, search, self?.id]
  );
};

const get_self_params: ApiMethodParameters<"GET", "User"> = {
  query: {
    include: ["has_connection", "profile_picture"],
  },
};
// get all of the current user's connections
export const useSelfConnections = (self: User | null) => {
  const dispatch = useContext(CONTEXTS.dispatch);
  const initial_connections = useMemo(() => {
    if (!self?.has_connection || self.has_connection.some(c => is_id_obj(c))) {
      return null;
    }
    return self.has_connection.map((c: Connection) => ({
      ...c,
      other_user: c.users?.find(u => u.id !== self.id),
    })) as ConnectionMapped[];
  }, [self?.id]);
  const [conns, set_conns] = useStateIfMounted<ConnectionMapped[] | null>(
    initial_connections
  );
  const [refresh_conns, set_refresh_conns, refresh_ref] = useStateRef(0);
  const refresh = useCallback(() => {
    set_refresh_conns(refresh_ref.current + 1);
  }, [set_refresh_conns]);

  const get_self = useApiFetch("getUser", self!.id as string, get_self_params);
  useEffect(() => {
    if (refresh_conns === 0) {
      return;
    }
    get_self().then(({ data }) => {
      const mapped_conns =
        (data.has_connection
          ?.map((c: Connection) => {
            const other_user = c.users?.find(u => u.id !== self?.id);
            if (!other_user) {
              return;
            }
            return {
              ...c,
              other_user: is_id_obj(other_user)
                ? get_cache(other_user.id) || other_user
                : other_user,
            };
          })
          .filter(c => !!c) as ConnectionMapped[]) || null;
      set_conns(mapped_conns);
      dispatch({
        type: EVENTS.UPDATE_AUTH_USER,
        payload: { path: ["has_connection"], value: mapped_conns },
      });
    });
  }, [self?.id, refresh_conns]);

  return [conns, refresh] as const;
};

// given the current user and another user, return the connection between the two;
// if we don't have the data locally, fetch it
export const useSelfConnectionWithUser = (
  self: User | null,
  other_user: User | null
) => {
  const [fetched_conn, set_fetched_conn] = useStateIfMounted<
    ConnectionMapped | undefined
  >(undefined);
  const conn_from_local_data = useMemo<ConnectionMapped | undefined>(() => {
    const shared =
      !!other_user && !!self
        ? get_shared_connection_from_user(other_user, self)
        : undefined;
    return shared ? { ...shared, other_user: other_user as User } : undefined;
  }, [self, other_user]);

  const search_conn = useApiMethod("getConnections");
  const get_conn_by_id = useApiMethod("getConnection");

  const req_for = useValueRef(`${self?.id}${other_user?.id}`);

  const get_conn_req = useCallback(
    (id?: string): Promise<ConnectionMapped | undefined> => {
      if (!self || !other_user) {
        return Promise.resolve(undefined);
      }
      if (id) {
        return get_conn_by_id(id, {
          query: {
            include: ["users.User:profile_picture"],
          },
        })
          .then(({ data }) => (data ? { ...data, other_user } : undefined))
          .catch(err => {
            if (err.status === 404) {
              return undefined;
            }
            throw err;
          });
      }
      return search_conn({
        query: {
          filter: [
            ["=", ["this", "Connection:users"], ["id", self!.id as string]],
            [
              "=",
              ["this", "Connection:users"],
              ["id", other_user!.id as string],
            ],
          ],
          include: ["users.User:profile_picture"],
          limit: 1,
        },
      })
        .then(({ data }) =>
          data && data[0] ? { ...data[0], other_user } : undefined
        )
        .catch(err => {
          if (err.status === 404) {
            return undefined;
          }
          throw err;
        });
    },
    [search_conn, get_conn_by_id, self?.id, other_user?.id]
  );

  const [get_conn, status] = useRequest(get_conn_req);

  useLayoutEffect(() => {
    if (self?.id === other_user?.id) {
      return;
    }
    if (!conn_from_local_data || is_id_obj(conn_from_local_data)) {
      get_conn(conn_from_local_data?.id).then(fetched => {
        if (req_for.current === `${self?.id}${other_user?.id}`) {
          set_fetched_conn(fetched);
        }
      });
    }
    return () => {
      set_fetched_conn(undefined);
    };
  }, [other_user?.id, self?.id]);

  return [
    useMemo<ConnectionMapped | undefined>(() => {
      if (fetched_conn) {
        return fetched_conn;
      }
      return conn_from_local_data && !is_id_obj(conn_from_local_data)
        ? conn_from_local_data
        : undefined;
    }, [fetched_conn, conn_from_local_data]),
    status,
  ] as const;
};

export const get_shared_connection_from_user = (
  user?: User,
  other_user?: User
) => {
  if (
    !user ||
    !other_user ||
    (!user.has_connection && !other_user.has_connection)
  ) {
    return undefined;
  }
  return (
    user.has_connection?.find(c =>
      !!c["users"]
        ? !!c["users"].find(u => u.id === other_user.id)
        : other_user.has_connection?.find(s => s.id === c.id)
    ) ||
    other_user.has_connection?.find(c =>
      !!c["users"]
        ? !!c["users"].find(u => u.id === user.id)
        : user.has_connection?.find(s => s.id === c.id)
    )
  );
};
