import { Atom } from "@thi.ng/atom";
import { User } from "@thrive-web/ui-api";

type StoredValue = string | number | boolean | any[] | object | null;

export class AppStorage<S extends object = {}> {
  constructor(key: string, user?: User) {
    this.base_key = key;
    if (user) {
      this.initialize(user);
    }
  }

  // the key to use for all app data in localStorage
  private base_key: string;
  private raw_data: string | null;
  private atom?: Atom<S>;
  user_id?: string;

  initialize = (user: User) => {
    if (this.atom || this.user_id) {
      console.warn(
        `Attempted to initialize AppStorage that is already initialized:`,
        user?.id
      );
      return;
    }
    this.user_id = user.id;
    // get existing data from storage
    this.raw_data = localStorage.getItem(this.base_key);
    let data;
    try {
      data = JSON.parse(this.raw_data || "") || {};
    } catch (e) {
      data = {};
    } finally {
      // initialize the atom, only with the current user's data
      this.atom = new Atom(data[user.id] || {});
      // when updating, only update the current user's data
      this.atom.addWatch("local-storage-sync", (_, __, value) => {
        localStorage.setItem(
          this.base_key,
          JSON.stringify({
            ...data,
            [user.id]: value,
          })
        );
      });
    }
  };

  destroy = () => {
    this.raw_data = null;
    this.atom?.release();
    delete this.atom;
    delete this.user_id;
  };

  get = (key: string | string[]): StoredValue => {
    if (!this.atom || !this.user_id) {
      console.warn(
        `Attempted to access local storage before Atom was initialized:`,
        key
      );
      return null;
    }
    const view = this.atom.addView(key);
    const value = view.deref() as StoredValue;
    view.release();
    return value;
  };

  set = (key: string | string[], value: StoredValue): void => {
    if (!this.atom || !this.user_id) {
      console.warn(
        `Attempted to set local storage before Atom was initialized:`,
        key
      );
      return;
    }

    this.atom.resetIn(key, value);
  };
}

// create a promise that returns the instance of the AppStorage to be used
// throughout the app at runtime
// also create a separate function that takes the AppStorage instance and
// resolves the promise with it
// This allows code from the shared packages and the site-specific code to
// reference the same AppStorage instance at runtime
const [storage_promise, setAppStorageInstance] = ((): [
  Promise<AppStorage>,
  (instance: AppStorage) => void
] => {
  const promise_ref: any = {};
  const storage_promise = new Promise<AppStorage>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject("Took too long to initialize AppStorage instance");
    }, 10000);
    promise_ref.resolve = (instance: AppStorage) => {
      clearTimeout(timer);
      resolve(instance);
    };
  });
  const set_instance = (instance: AppStorage) => {
    promise_ref.resolve(instance);
  };
  return [storage_promise, set_instance];
})();

export { setAppStorageInstance };
export const getAppStorage = () => storage_promise;
